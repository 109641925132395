import { connect } from 'react-redux';
import ServiceWindow from './serviceWindow';
import { getVideoUrl } from '@store/videoSlice/selectors';
import { RootState } from '@store/rootReducer';
import { isOffline } from '@store/serviceSlice/selectors';
import { getNextServiceData, getScheduleItems } from '@store/scheduleSlice/selectors';
import dayjs from 'dayjs';
import { hasServiceBeenFetched } from '@store/uiSlice/selectors';
import { shouldShowError } from '@store/uiSlice/selectors';

interface StateProps {
  isEmpty: boolean;
  url: string | null;
  isDuringService: boolean;
  isLoading: boolean;
  showError: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const nextService = getNextServiceData(state);
  const isDuringService =
    dayjs().isBefore(nextService?.endTime) && dayjs().isAfter(nextService?.scheduleTime);
  const showError = shouldShowError(state);
  return {
    url: getVideoUrl(state),
    isEmpty: isOffline(state) && getScheduleItems(state).length === 0,
    isLoading: !hasServiceBeenFetched(state),
    isDuringService,
    showError,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(ServiceWindow);
