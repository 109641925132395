import React, { FunctionComponent } from 'react';
import { Box, OTitle, Grid, GridItem } from '@lifechurch/react-ion';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import { IMAGE_PREFIX } from '../constants';

const StyledBox = styled(Box)`
  transform: translateY(-50%);
  top: 50%;
  position: relative;
`;

const FourZeroFourPage: FunctionComponent = () => {
  return (
    <>
      <StyledBox data-testid='fourZeroFour'>
        <Grid gridTemplateColumns='repeat(12,1fr)' gridRowGap='1rem'>
          <GridItem gridColumn={['span 12', '1 / span 6']} textAlign={'inline-end'}>
            <Box marginInlineEnd={56}>
              <Imgix
                src={`${IMAGE_PREFIX}/static/404.png`}
                width={299}
                height={400}
                imgixParams={{
                  auto: 'format',
                }}
                htmlAttributes={{
                  'data-testid': 'errorImage',
                }}
              />
            </Box>
          </GridItem>
          <GridItem gridColumn={['span 12', '7 / span 12']}>
            <StyledBox>
              <OTitle marginBlockEnd={5}>Page Not Found.</OTitle>
            </StyledBox>
          </GridItem>
        </Grid>
      </StyledBox>
    </>
  );
};

export default FourZeroFourPage;
