import React, { FC, useContext } from 'react';
import DirectChatIcon from '../navbar/directChatIcon';
import { ThemeContext } from 'styled-components';
import { IconProps, IconSize } from '@lifechurch/react-ion';

interface Props {
  direct: boolean;
  numberOfSubscribers?: number;
  text: string;
  groupChat: boolean;
  hasNewMessages?: boolean;
  selected: boolean;
  expanded: boolean;
  Icon: React.ComponentType<IconProps>;
}

const NavMenuIcon: FC<Props> = ({
  direct,
  selected,
  numberOfSubscribers,
  groupChat,
  text,
  hasNewMessages,
  expanded,
  Icon,
}) => {
  const themeContext = useContext(ThemeContext);

  if (direct) {
    return (
      <DirectChatIcon
        isCurrent={selected}
        nickname={numberOfSubscribers?.toString() || text}
        groupChat={groupChat}
        pulsing={hasNewMessages && !selected}
      />
    );
  }

  return (
    <Icon
      size={expanded ? IconSize.MD : IconSize.XL}
      color={selected ? themeContext.colors.gray100 : themeContext.colors.gray50}
    />
  );
};

export default NavMenuIcon;
