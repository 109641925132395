import React, { FunctionComponent } from 'react';
import { IconProps } from './index';

const RoleIdentifierInvertedIcon: FunctionComponent<IconProps> = () => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='13' height='13' rx='6.5' fill='white' />
    <path
      fill='#2993E5'
      stroke='#2993E5'
      d='M6.50001 3.16802L7.14579 5.15557L7.25805 5.50107H7.62132H9.71116L8.02045 6.72944L7.72655 6.94297L7.83881 7.28846L8.4846 9.27601L6.79389 8.04763L6.5 7.83411L6.20611 8.04763L4.5154 9.27601L5.16119 7.28846L5.27345 6.94297L4.97955 6.72944L3.28884 5.50107H5.37868H5.74195L5.8542 5.15558L6.50001 3.16802Z'
    />
  </svg>
);

export default RoleIdentifierInvertedIcon;
