import React, { useState } from 'react';
import { GivingMoment } from '../../giving';
import { WindowType } from '../../types';
import { Preview } from '../index';
import { Box, IframeModal } from '@lifechurch/react-ion';
export const GivingMomentPreview = ({ text, buttonText, src, windowType, }) => {
    const [isPinned, setIsPinned] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const onClick = () => {
        if (windowType === WindowType.NEW_TAB) {
            window.open(src, '_blank');
        }
        else {
            setModalOpen(true);
        }
        setIsPinned(false);
    };
    return (React.createElement(React.Fragment, null,
        modalOpen && React.createElement(IframeModal, { dismiss: () => setModalOpen(false), src: src }),
        React.createElement(Preview, null,
            React.createElement(Box, { p: isPinned ? 0 : 3, paddingBlockStart: 0, marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0 },
                React.createElement(GivingMoment, { text: text, buttonText: buttonText, isPinned: isPinned, onDismiss: () => setIsPinned(false), onClick: onClick })))));
};
