import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const LeftQuotationIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M4 6C4 4.89543 4.89543 4 6 4H7V2H6C3.79086 2 2 3.79086 2 6V7V10V13H7V7H4V6ZM10 7H13V13H8V10V7V6C8 3.79086 9.79086 2 12 2H13V4H12C10.8954 4 10 4.89543 10 6V7Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M8 5C6.34315 5 5 6.34315 5 8V10H9V17H3V11V10V8C3 5.23858 5.23858 3 8 3H9V5H8ZM17 10H13V8C13 6.34315 14.3431 5 16 5H17V3H16C13.2386 3 11 5.23858 11 8V10V11V17H17V10Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
