import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'index';

export enum DropdownItemType {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  RISK = 'RISK',
}

interface DropdownItemProps {
  variant?: DropdownItemType;
  bold?: boolean;
}

interface DropdownItemColors {
  color: string;
  hoverColor: string;
  bgColor: string;
}

const getColors = (colors: Colors, type: DropdownItemType | undefined): DropdownItemColors => {
  switch (type) {
    case DropdownItemType.PRIMARY:
      return {
        color: colors.action.default,
        hoverColor: colors.action.hover,
        bgColor: colors.action.light,
      };
    case DropdownItemType.RISK:
      return {
        color: colors.error.default,
        hoverColor: colors.error.hover,
        bgColor: colors.error.light,
      };
    default:
      return {
        color: colors.gray100,
        hoverColor: colors.action.hover,
        bgColor: colors.action.light,
      };
  }
};

const BaseDropdownItem = styled.a.attrs<DropdownItemProps>(props => {
  return {
    colors: getColors(props.theme.colors, props.variant),
    variant: props.variant,
  };
})<DropdownItemProps & { colors: DropdownItemColors }>`
  display: flex;
  font-size: 16px;
  line-height: 20px;
  color: ${({ colors }) => colors.color};
  padding: 10px 16px;
  text-decoration: none;
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  cursor: pointer;

  svg {
    margin-inline-start: 2px;
    path {
      fill: ${({ colors }) => colors.color};
    }
  }

  &:hover,
  &:focus {
    color: ${({ colors }) => colors.hoverColor};
    background-color: ${({ colors }) => colors.bgColor};
    text-decoration: none;

    svg path {
      fill: ${({ colors }) => colors.hoverColor};
    }
  }
`;

type Props = React.ComponentProps<typeof BaseDropdownItem>;

export const DropdownItem: FC<Props> = ({
  children,
  variant = DropdownItemType.DEFAULT,
  ...props
}) => {
  return (
    <li>
      <BaseDropdownItem variant={variant} {...props}>
        {children}
      </BaseDropdownItem>
    </li>
  );
};
