import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { IconSize, SlashCircleIcon } from '@lifechurch/react-ion';
import { Notification as NotificationInterface } from '../dux';
import { localizedTimeReference } from '@utils/core';
import styles from '../notification.module.css';
import clsx from 'clsx';

interface Props {
  notification: NotificationInterface;
  isCompact: boolean;
}

export const SubscriberMutedNotification: React.FunctionComponent<Props> = (props: Props) => {
  const {
    notification: { host, subscriber, timestamp },
    isCompact,
  } = props;
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);

  return (
    <div
      className={clsx([styles.wrapper], {
        [styles.compactWrapperMarginInline]: isCompact,
      })}
      data-testid={'notification'}
    >
      <span className={styles.icon} data-testid={'notification-icon'}>
        <SlashCircleIcon size={IconSize.SM} color={theme.colors.gray50} />
      </span>
      <p className={styles.text} data-testid={'notification-message'}>
        <Trans ns='moments' i18nKey='mute.notification_host'>
          <strong>{{ host: host?.nickname || '' }}</strong> muted{' '}
          <strong>{{ guest: subscriber.nickname || '' }}</strong>
        </Trans>
        <span className={styles.timestamp} data-testid='notification-timestamp'>
          {localizedTimeReference(timestamp, t)}
        </span>
      </p>
    </div>
  );
};

export const SubscriberMuted: React.FunctionComponent<Props> = React.memo<Props>(
  SubscriberMutedNotification
);
