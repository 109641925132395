export default [
  {
    //Afrikaans
    code: 'af-ZA',
    locale: 'af-ZA',
    name: 'Afrikaans',
  },
  {
    //Amharic
    code: 'am-ET',
    locale: 'am-ET',
    name: 'አማርኛ',
  },
  {
    //Arabic (Saudi Arabia)
    code: 'ar-SA',
    locale: 'ar-SA',
    name: 'العربية ',
  },
  {
    //Belarusian
    code: 'be-BY',
    locale: 'be-BY',
    name: 'беларуская',
  },
  {
    //Bengali
    code: 'bn-BD',
    locale: 'bn-BD',
    name: 'বাংলা',
  },
  {
    //Bulgarian
    code: 'bg-BG',
    locale: 'bg-BG',
    name: 'български',
  },
  {
    //Catalan
    code: 'ca-ES',
    locale: 'ca-ES',
    name: 'català',
  },
  {
    //Czech
    code: 'cs-CZ',
    locale: 'cs-CZ',
    name: 'čeština',
  },
  {
    //Welsh
    code: 'cy-GB',
    locale: 'cy-GB',
    name: 'Cymraeg',
  },
  {
    //Danish
    code: 'da-DK',
    locale: 'da-DK',
    name: 'Dansk',
  },
  {
    //German
    code: 'de-DE',
    locale: 'de',
    name: 'Deutsch',
  },
  {
    code: 'el-GR',
    locale: 'el',
    name: 'Ελληνικά',
  },
  {
    code: 'en-GB',
    locale: 'en-GB',
    name: 'British English',
  },
  {
    //English - United States
    code: 'en-US',
    locale: 'en',
    name: 'English',
  },
  {
    //Spanish - Spain
    code: 'es-ES',
    locale: 'es-ES',
    name: 'Español (Spain)',
  },
  {
    //Spanish - Latin American
    code: 'es-LA',
    locale: 'es',
    name: 'Español (Latin America)',
  },
  {
    // Farsi
    code: 'fa-IR',
    locale: 'fa-IR',
    name: 'فارسی',
  },
  {
    //Filipino
    code: 'fil-PH',
    locale: 'fil-PH',
    name: 'filipino',
  },
  {
    //finnish
    code: 'fi-FI',
    locale: 'fi-FI',
    name: 'Suomi',
  },
  {
    //French
    code: 'fr-FR',
    locale: 'fr-FR',
    name: 'Français',
  },
  {
    //Hebrew
    code: 'he-IL',
    locale: 'he-IL',
    name: 'עִברִית',
  },
  {
    //Hindi
    code: 'hi-IN',
    locale: 'hi-IN',
    name: 'हिन्दी',
  },
  {
    //Croatian
    code: 'hr-HR',
    locale: 'hr-HR',
    name: 'hrvatski',
  },
  {
    //Haitian Creole
    code: 'ht-HT',
    locale: 'ht-HT',
    name: 'kreyòl ayisyen',
  },
  {
    //Hungarian
    code: 'hu-HU',
    locale: 'hu-HU',
    name: 'Magyar',
  },
  {
    //Indonesian (Indonesia)
    code: 'id-ID',
    locale: 'id-ID',
    name: 'bahasa Indonesia',
  },
  {
    //Igbo
    code: 'ig-NG',
    locale: 'ig-NG',
    name: 'Ìgbò',
  },
  {
    //Icelandic
    code: 'is-IS',
    locale: 'is-IS',
    name: 'Islenska',
  },
  {
    //Italian
    code: 'it-IT',
    locale: 'it',
    name: 'Italiano',
  },
  {
    //Japanese
    code: 'ja-JP',
    locale: 'ja-JP',
    name: '日本語',
  },
  {
    //Khmer
    code: 'km-KH',
    locale: 'km-KH',
    name: 'ខ្មែរ',
  },
  {
    //Kannada
    code: 'kn-IN',
    locale: 'kn-IN',
    name: 'ಕನ್ನಡ',
  },
  {
    //Korean
    code: 'ko-KR',
    locale: 'ko-KR',
    name: '한국어',
  },
  {
    //Lithuanian
    code: 'lt-LT',
    locale: 'lt-LT',
    name: 'lietuvių',
  },
  {
    //Latvian
    code: 'lv-LV',
    locale: 'lv-LV',
    name: 'latviešu valoda',
  },
  {
    //Macedonian
    code: 'mk-MK',
    locale: 'mk-MK',
    name: 'Македонски',
  },
  {
    //Malayalam
    code: 'ml-IN',
    locale: 'ml-IN',
    name: 'മലയാളം',
  },
  {
    //Mongolian
    code: 'mn-MN',
    locale: 'mn-MN',
    name: 'Монгол',
  },
  {
    //Malay (Malaysia)
    code: 'ms-MY',
    locale: 'ms-MY',
    name: 'Bahasa Melayu',
  },
  {
    // Myanmar (Burmese) Zawgyi
    code: 'my-QZ',
    locale: 'my-QZ',
    name: 'ျမန္မာေဇာ္ဂ်ီ',
  },
  {
    // Myanmar (Burmese) Unicode
    code: 'my-MM',
    locale: 'my-MM',
    name: 'မြန်မာယူနီကုတ်',
  },
  {
    //Dutch (Netherlands)
    code: 'nl-NL',
    locale: 'nl-NL',
    name: 'Nederlands',
  },
  {
    //Norwegian
    code: 'no-NO',
    locale: 'no-NO',
    name: 'Norsk',
  },
  {
    //Polish
    code: 'pl-PL',
    locale: 'pl',
    name: 'Polski',
  },
  {
    //Portuguese (Portugal)
    code: 'pt-BR',
    locale: 'pt-BR',
    name: 'Português(Brasil)',
  },
  {
    //Portuguese (Brazil)
    code: 'pt-PT',
    locale: 'pt-PT',
    name: 'Português(Portugal)',
  },
  {
    //Russian
    code: 'ru-RU',
    locale: 'ru-RU',
    name: 'Русский',
  },
  {
    //Romanian
    code: 'ro-RO',
    locale: 'ro-RO',
    name: 'Română',
  },
  {
    //Slovak (Slovakian)
    code: 'sk-SK',
    locale: 'sk-SK',
    name: 'Slovenčina',
  },
  {
    //Slovenian
    code: 'sl-SL',
    locale: 'sl-SL',
    name: 'Slovenščina',
  },
  {
    //Shona
    code: 'sn-ZW',
    locale: 'sn-ZW',
    name: 'chiShona',
  },
  {
    //Albanian
    code: 'sq-AL',
    locale: 'sq-AL',
    name: 'Shqip',
  },
  {
    //Swedish (Sweden)
    code: 'sv-SE',
    locale: 'sv-SV',
    name: 'Svenska',
  },
  {
    //Swahili
    code: 'sw-KE',
    locale: 'sw-KE',
    name: 'Kiswahili',
  },
  {
    //Thai
    code: 'th-TH',
    locale: 'th-TH',
    name: 'ไทย',
  },
  {
    //Turkish
    code: 'tr-TR',
    locale: 'tr',
    name: 'Türkçe',
  },
  {
    //Ukranian
    code: 'uk-UA',
    locale: 'uk-UA',
    name: 'Українська',
  },
  {
    //Tamil (India)
    code: 'ta-IN',
    locale: 'ta-IN',
    name: 'தமிழ்',
  },
  {
    //Urdu
    code: 'ur-PK',
    locale: 'ur-PK',
    name: 'اردو',
  },
  {
    //Vietnamese
    code: 'vi-VN',
    locale: 'vi-VN',
    name: 'Tiếng Việt',
  },
  {
    //Yoruba
    code: 'yo-NG',
    locale: 'yo-NG',
    name: 'Èdè Yorùbá',
  },
  {
    //Chinese - Simplified
    code: 'zh-CN',
    locale: 'zh-cn',
    name: '简体中文',
  },
  {
    //Chinese - Hong Kong
    code: 'zh-HK',
    locale: 'zh-HK',
    name: '廣東話 / 广东话',
  },
  {
    //Chinese - Traditional
    code: 'zh-TW',
    locale: 'zh-tw',
    name: '繁體中文',
  },
  {
    //Zulu
    code: 'zu-ZA',
    locale: 'zu-ZA',
    name: 'isiZulu',
  },
];
