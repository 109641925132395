import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleLeftCircle: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5ZM5.14645 7.85355L8.64645 11.3536L9.35355 10.6464L6.20711 7.5L9.35355 4.35355L8.64645 3.64645L5.14645 7.14645L4.79289 7.5L5.14645 7.85355Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM7.29289 10.7071L11.2929 14.7071L12.7071 13.2929L9.41421 10L12.7071 6.70711L11.2929 5.29289L7.29289 9.29289L6.58579 10L7.29289 10.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM8.29289 12.7071L13.2929 17.7071L14.7071 16.2929L10.4142 12L14.7071 7.70711L13.2929 6.29289L8.29289 11.2929L7.58579 12L8.29289 12.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3ZM10.7929 16.7071L18.2929 24.2071L19.7071 22.7929L12.9142 16L19.7071 9.20711L18.2929 7.79289L10.7929 15.2929L10.0858 16L10.7929 16.7071Z'
          size={size}
          {...rest}
        />
      );
  }
};
