import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CommentSlashIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M2.35355 13.3536L13.3536 2.35355L12.6464 1.64645L1.64645 12.6464L2.35355 13.3536ZM5.12132 12L14 3.12132V12H11.5L9 14.5V12H5.12132ZM9.87868 3L1 11.8787V3H9.87868Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16.2929 2.29289L2.29289 16.2929L3.70711 17.7071L17.7071 3.70711L16.2929 2.29289ZM12 16H6.82843L18 4.82843V16H15L12 19V16ZM2 4H13.1716L2 15.1716V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20.2929 2.29289L2.29289 20.2929L3.70711 21.7071L21.7071 3.70711L20.2929 2.29289ZM14 20H6.82843L22 4.82843V20H18L14 24V20ZM2 4H17.1716L2 19.1716V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M28.2929 2.29289L2.29289 28.2929L3.70711 29.7071L29.7071 3.70711L28.2929 2.29289ZM19 26H8.82843L28.8284 6H30V26H24L19 31V26ZM2 6H23.1716L3.17157 26H2V6Z'
          size={size}
          {...rest}
        />
      );
  }
};
