import { Pane } from '@features/pane/dux';
import { RootState } from '@store/rootReducer';
import { getHostChannel } from '@store/feedSlice/channelSelectors';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';
import { connect } from 'react-redux';
import PaneContent from '@features/pane/paneContent/pageContent';

interface StateProps {
  hostChannel?: string;
  isGuest?: boolean;
}

export interface OwnProps {
  pane: Pane;
}

const mapStateToProps = (state: RootState) => {
  const hostChannel = getHostChannel(state)?.key || '';
  const isGuest = !isHostViewingAsHost(state);

  return {
    hostChannel,
    isGuest,
  };
};

export type Props = StateProps & OwnProps;

export default connect(mapStateToProps, null)(PaneContent);
