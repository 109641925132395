import styled from 'styled-components';
import { OHeaderFive } from '@lifechurch/react-ion';

export const Divider = styled(OHeaderFive)`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.gray50};

  ::before,
  ::after {
    content: '';
    flex: 1;
    border-block-end: 1px solid ${props => props.theme.colors.gray10};
    margin-inline-end: 4px;
    margin-inline-start: 4px;
  }
`;
