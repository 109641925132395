import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  compose,
} from 'styled-system';
import { StyledContext, StyledLabel, InputSize } from './Input';
import { ReadOnlyButton } from './ReadOnlyButton';
import { logical, LogicalProps } from './logical';

type NativeInput = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'size' | 'aria-relevant'
>;
type Resize = 'both' | 'none' | 'vertical' | 'horizontal';

export const TextAreaWrapper = styled.div`
  display: flex;
  position: relative;
`;

export interface TextareaProps
  extends NativeInput,
    BorderProps,
    SpaceProps,
    LogicalProps,
    TypographyProps,
    LayoutProps {
  size?: InputSize;
  label?: string;
  context?: ReactNode;
  valid?: boolean;
  error?: boolean;
  hint?: ReactNode;
  focused?: boolean;
  resize?: Resize;
}

type StyledTextareaProps = {
  valid?: boolean;
  error?: boolean;
  focused?: boolean;
  resize?: Resize;
} & BorderProps &
  SpaceProps &
  LogicalProps &
  TypographyProps &
  LayoutProps;

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  display: block;
  width: 100%;
  border: 1px solid ${(props): string => props.theme.colors.gray30};
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  resize: ${props => props.resize};
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;

  &:disabled {
    background-color: ${(props): string => props.theme.colors.gray10};
    cursor: not-allowed;
  }

  &:focus:not(:read-only) {
    border-color: ${(props): string => props.theme.colors.action.default};
    background-color: white;
  }

  border-color: ${(props): string => {
    if (props.focused) {
      return props.theme.colors.action.default;
    } else if (props.error) {
      return props.theme.colors.error.default;
    } else {
      return props.theme.colors.gray30;
    }
  }};
  ${compose(border, space, logical, typography, layout)}
`;

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      name,
      value = '',
      size = InputSize.MD,
      label,
      placeholder,
      context,
      valid,
      error,
      hint,
      disabled,
      focused,
      required,
      resize = 'both',
      ...props
    },
    ref
  ) => (
    <>
      {label && (
        <StyledLabel
          htmlFor={name}
          size={size}
          fontWeight={2}
          marginBlockEnd={2}
          lineHeight={2}
          required={required}
        >
          {label}
        </StyledLabel>
      )}
      <TextAreaWrapper>
        <StyledTextarea
          ref={ref}
          id={name}
          name={name}
          valid={valid}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          value={value}
          focused={focused}
          fontSize={3}
          lineHeight={2}
          borderRadius={2}
          p={'9px 12px'}
          resize={resize}
          {...props}
        />
        {props.readOnly && <ReadOnlyButton value={value} />}
      </TextAreaWrapper>
      {hint && (
        <StyledContext size={size} color={error ? 'error.default' : 'success.default'}>
          {hint}
        </StyledContext>
      )}
      {context && <StyledContext size={size}>{context}</StyledContext>}
    </>
  )
);

Textarea.displayName = 'TextareaInput';

export { Textarea };
