import dayjs from 'dayjs';
import groupBy from 'lodash.groupby';
import { createSelector } from 'reselect';
import { RootState } from '@store/rootReducer';
import { ScheduleState } from './';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';

const ID = 'schedule';
const local = (state: RootState) => state[ID];

export const getNextServiceData = createSelector<
  RootState,
  ScheduleState,
  ServicesConnectionQuery_currentOrganization_servicesConnection_services
>(local, schedule => schedule.items?.[0] || {});

export const getNextStartTime = createSelector<RootState, ScheduleState, string>(
  local,
  schedule => schedule.items?.[0]?.startTime || ''
);

export const getScheduleGroupedByDay = createSelector<
  RootState,
  ScheduleState,
  { [key: string]: Array<ServicesConnectionQuery_currentOrganization_servicesConnection_services> }
>(local, schedule =>
  groupBy(schedule.items, item => dayjs(item.startTime || item.scheduleTime).format('YYYYMMDD'))
);

export const getScheduleTimezone = createSelector<RootState, ScheduleState, string>(
  local,
  schedule => schedule.timezone
);

export const getScheduleItems = createSelector<
  RootState,
  ScheduleState,
  Array<ServicesConnectionQuery_currentOrganization_servicesConnection_services>
>(local, local => local.items);
