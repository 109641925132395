import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CommentPlusIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M14 3H1V12H9V14.5L11.5 12H14V3ZM8 5H7V7H5V8H7V10H8V8H10V7H8V5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 4H2V16H12V19L15 16H18V4ZM9 9V6H11V9H14V11H11V14H9V11H6V9H9Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22 4H2V20H14V24L18 20H22V4ZM11 11V7H13V11H17V13H13V17H11V13H7V11H11Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M30 6H2V26H19V31L24 26H30V6ZM15 15V10H17V15H22V17H17V22H15V17H10V15H15Z'
          size={size}
          {...rest}
        />
      );
  }
};
