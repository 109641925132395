import styled from 'styled-components';
import { Flex, getInset, OXSmallParagraph } from '@lifechurch/react-ion';
export const WrapperDiv = styled.div `
  display: inline-block;
  width: ${props => (props.isPinned ? '100%' : undefined)};

  &:focus {
    border-radius: 4px;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
`;
export const EngagementWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.buttonAlignment};
  margin-block-start: ${props => (props.isPinned ? '8px' : '4px')};
  padding-inline-start: 8px;
  padding-inline-end: ${props => (props.isPinned ? '27px' : '8px')};
  align-items: flex-start;
`;
const EngagementButton = styled.div `
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  ${getInset('insetBlockStart', '-13px')};
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 999px;
  padding: 2px 6px;
`;
export const ReactionButton = styled(EngagementButton) `
  svg path {
    fill: ${props => props.hasLiked ? props.theme.colors.pink.default : props.theme.colors.gray50};
  }
`;
export const LikeCount = styled(OXSmallParagraph) `
  color: ${props => props.theme.colors.gray50};
  user-select: none;
  font-size: ${(props) => props.theme.fontSizes[2]};
  margin-inline-start: 2px;
`;
export const HeartIconWrapper = styled(Flex) `
  padding: 0 8px;
`;
