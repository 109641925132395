import { ModalType } from '@components/modal/dux';

const getModal = (): ModalType | null => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('modal')) {
    return searchParams.get('modal') as ModalType;
  } else {
    return null;
  }
};

export { getModal };
