"use strict";

exports.__esModule = true;
exports.DEFAULT_VERSION = exports.REGISTER = exports.PURGE = exports.PERSIST = exports.PAUSE = exports.REHYDRATE = exports.FLUSH = exports.KEY_PREFIX = void 0;
var KEY_PREFIX = 'persist:';
exports.KEY_PREFIX = KEY_PREFIX;
var FLUSH = 'persist/FLUSH';
exports.FLUSH = FLUSH;
var REHYDRATE = 'persist/REHYDRATE';
exports.REHYDRATE = REHYDRATE;
var PAUSE = 'persist/PAUSE';
exports.PAUSE = PAUSE;
var PERSIST = 'persist/PERSIST';
exports.PERSIST = PERSIST;
var PURGE = 'persist/PURGE';
exports.PURGE = PURGE;
var REGISTER = 'persist/REGISTER';
exports.REGISTER = REGISTER;
var DEFAULT_VERSION = -1;
exports.DEFAULT_VERSION = DEFAULT_VERSION;