import styled from 'styled-components';
import { getInset } from './logical';

export const InputIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  ${getInset('insetInlineEnd', 0)};
  cursor: pointer;
  height: 40px;
  width: 40px;

  svg {
    display: block;
  }

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.action.hover};
    }
  }
`;
