import styled from 'styled-components';

interface WrapperProps {
  show: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  padding-inline-end: ${props => (props.show ? '0px;' : '8px;')}
  padding-block-start: 2px;
  cursor: pointer;
`;

const AvatarWrapper = styled.div`
  border: 1px solid transparent;
  border-radius: 20px;
  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.white};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.action.hover};
  }
`;

export { AvatarWrapper, Wrapper };
