import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const WarningTriangleIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.50004 1L14.4282 13H0.571838L7.50004 1ZM7.00003 5H8.00003V9H7.00003V5ZM8.00002 10H7.00002V11H8.00002V10Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 1.25L19.0933 17H0.906738L10 1.25ZM9.00001 7H11V12H9.00001V7ZM11 13H9V15H11V13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M11.9998 1.5L23.2582 21H0.741516L11.9998 1.5ZM11 8H13V15H11V8ZM13 16H11V18H13V16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 1.75L31.1555 28H0.844574L16 1.75ZM15 11H17V20H15V11ZM17 22H15V24H17V22Z'
          size={size}
          {...rest}
        />
      );
  }
};
