import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const UserPlus: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M13 5H12V3H10V2H12V0H13V2H15V3H13V5ZM7.5 8C9.433 8 11 6.433 11 4.5C11 4.33024 10.9879 4.1633 10.9646 4H9V1.33682C8.54537 1.12085 8.0368 1 7.5 1C5.567 1 4 2.567 4 4.5C4 6.433 5.567 8 7.5 8ZM4 9C2.34315 9 1 10.3431 1 12V14H14V12C14 10.3431 12.6569 9 11 9H4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 6H16V4H14V2H16V0H18V2H20V4H18V6ZM10 10C12.4853 10 14.5 7.98528 14.5 5.5C14.5 5.331 14.4907 5.16417 14.4725 5H13V2.14584C12.2039 1.43328 11.1525 1 10 1C7.51472 1 5.5 3.01472 5.5 5.5C5.5 7.98528 7.51472 10 10 10ZM6 11C3.79086 11 2 12.7909 2 15V18H18V15C18 12.7909 16.2091 11 14 11H6Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M21 8H19V5H16V3H19V0H21V3H24V5H21V8ZM12 12C15.0376 12 17.5 9.53757 17.5 6.5C17.5 6.33146 17.4924 6.16468 17.4776 6H15V1.88947C14.1372 1.32692 13.1068 1 12 1C8.96243 1 6.5 3.46243 6.5 6.5C6.5 9.53757 8.96243 12 12 12ZM6 14C3.79086 14 2 15.7909 2 18V22H22V18C22 15.7909 20.2091 14 18 14H6Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M28 10H26V6H22V4H26V0H28V4H32V6H28V10ZM16 16C19.866 16 23 12.866 23 9C23 8.66048 22.9758 8.3266 22.9291 8H20V3.25469C18.8662 2.46381 17.4872 2 16 2C12.134 2 9 5.13401 9 9C9 12.866 12.134 16 16 16ZM8 18C5.79086 18 4 19.7909 4 22V29H28V22C28 19.7909 26.2091 18 24 18H8Z'
          size={size}
          {...rest}
        />
      );
  }
};
