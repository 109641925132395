import Imgix from 'react-imgix';
import { IMAGE_PREFIX } from '../../constants';
import { defaultColors, OParagraph } from '@lifechurch/react-ion';
import { NoVideoWrapper } from './styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NoVideo: FC = () => {
  const { t } = useTranslation();

  return (
    <NoVideoWrapper data-testid='no-video-wrapper'>
      <Imgix
        src={`${IMAGE_PREFIX}/static/web-client/media_component_placeholder.png`}
        width={80}
        height={56}
        htmlAttributes={{ alt: t('failed_to_load') }}
      />
      <OParagraph color={defaultColors.gray10} marginBlockStart={4}>
        {t('no_video')}
      </OParagraph>
    </NoVideoWrapper>
  );
};

export default NoVideo;
