import ChangePassword from './changePassword';
import { connect } from 'react-redux';
import {
  cancelChangePassword,
  publishChangePassword,
  openProfileSettings,
  ChangePasswordMeta,
} from './dux';
import { Subscriber } from '../../../types';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { FormikErrors, withFormik } from 'formik';
import { ChangePasswordCodes } from '@io/sagas/changePassword';
import { RootState } from '@store/rootReducer';

export interface State {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const EnhancedForm = withFormik<Props, State>({
  mapPropsToValues: () => ({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }),
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToErrors: ({ meta }) => {
    const errors: FormikErrors<State> = {};

    meta?.errors?.map(error => {
      switch (error.code) {
        case ChangePasswordCodes.PASSWORD_INCORRECT:
          errors.oldPassword = 'change_password.incorrect_password';
          break;
        case ChangePasswordCodes.PASSWORD_INVALID:
          errors.newPassword = 'change_password.invalid_password';
          break;
        case ChangePasswordCodes.CONFIRMATION_DOES_NOT_MATCH:
          errors.confirmNewPassword = 'change_password.passwords_dont_match';
          break;
        default:
          break;
      }
      return errors;
    });

    return errors;
  },
  validate: values => {
    const errors: FormikErrors<State> = {};
    if (values.oldPassword.length === 0) {
      errors.oldPassword = 'change_password.blank_old_password';
    }
    if (values.newPassword.length === 0) {
      errors.newPassword = 'change_password.blank_new_password';
    }
    if (values.confirmNewPassword.length === 0) {
      errors.confirmNewPassword = 'change_password.blank_confirm_password';
    }
    return errors;
  },
  handleSubmit: (values, { props: { publishChangePassword } }) => {
    publishChangePassword(values);
  },
})(ChangePassword);

interface StateProps {
  currentSubscriber: Subscriber;
}

interface OwnProps {
  closeModal: () => void;
  meta?: ChangePasswordMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): OwnProps & StateProps => ({
  currentSubscriber: getCurrentSubscriber(state),
  ...ownProps,
});

interface DispatchProps {
  publishChangePassword: typeof publishChangePassword;
  cancelChangePassword: typeof cancelChangePassword;
  openProfileSettings: typeof openProfileSettings;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      publishChangePassword,
      cancelChangePassword,
      openProfileSettings,
    },
    dispatch
  );

const VisibleChangePassword = connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);

export type Props = OwnProps & StateProps & DispatchProps;
export default VisibleChangePassword;
