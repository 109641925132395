import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Props } from './index';
import { MutedOSmallParagraph } from '../styles';
import {
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  ModalText,
  OParagraph,
} from '@lifechurch/react-ion';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

const MuteSubscriber = ({ closeModal, muteSubscriber, mutedAlert, subscriber }: Props) => {
  const modal = useSelector((state: RootState) => state.ui.modal);
  const defaultOnSuccess = () => {};

  // @ts-expect-error delete modal doesn't have a meta property
  const onSuccess = modal?.meta?.onSuccess ?? defaultOnSuccess;

  const { t } = useTranslation('forms');
  const callMuteSubscriber = () => {
    closeModal();
    muteSubscriber(subscriber.id, false, () => onSuccess());
    mutedAlert(subscriber.nickname || '');
  };
  const callCancel = () => {
    closeModal();
    muteSubscriber(subscriber.id, true, () => onSuccess());
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='muteSubscriber-modal'>
      <ModalText marginBlockEnd={5}>
        <OParagraph marginBlockEnd={2}>
          <Trans ns='forms' i18nKey='mute_subscriber.confirm'>
            Are you sure you want to mute <strong>{{ nickname: subscriber.nickname || '' }}</strong>
            ?
          </Trans>
        </OParagraph>
        <MutedOSmallParagraph>{t('mute_subscriber.all_messages')}</MutedOSmallParagraph>
      </ModalText>
      <ModalButtons>
        <LinkButton
          buttonType={LinkButtonType.SECONDARY}
          onClick={callCancel}
          data-testid='muteSubscriber-cancel'
          marginInlineEnd={5}
        >
          {t('mute_subscriber.cancel')}
        </LinkButton>
        <LinkButton
          buttonType={LinkButtonType.ERROR}
          onClick={callMuteSubscriber}
          data-testid='muteSubscriber-mute'
        >
          {t('mute_subscriber.mute')}
        </LinkButton>
      </ModalButtons>
    </Modal>
  );
};

export default MuteSubscriber;
