import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TimesCircleIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM4.14645 4.85355L6.79289 7.5L4.14645 10.1464L4.85355 10.8536L7.5 8.20711L10.1464 10.8536L10.8536 10.1464L8.20711 7.5L10.8536 4.85355L10.1464 4.14645L7.5 6.79289L4.85355 4.14645L4.14645 4.85355Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM5.29289 6.70711L8.58579 10L5.29289 13.2929L6.70711 14.7071L10 11.4142L13.2929 14.7071L14.7071 13.2929L11.4142 10L14.7071 6.70711L13.2929 5.29289L10 8.58579L6.70711 5.29289L5.29289 6.70711Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM6.29289 7.70711L10.5858 12L6.29289 16.2929L7.70711 17.7071L12 13.4142L16.2929 17.7071L17.7071 16.2929L13.4142 12L17.7071 7.70711L16.2929 6.29289L12 10.5858L7.70711 6.29289L6.29289 7.70711Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM8.79289 10.2071L14.5858 16L8.79289 21.7929L10.2071 23.2071L16 17.4142L21.7929 23.2071L23.2071 21.7929L17.4142 16L23.2071 10.2071L21.7929 8.79289L16 14.5858L10.2071 8.79289L8.79289 10.2071Z'
          size={size}
          {...rest}
        />
      );
  }
};
