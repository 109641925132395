import styled from 'styled-components';
import { Grid } from 'styled-css-grid';
import { LandscapeMobile } from '@utils/responsive';
import { isChromeForIPad } from '@utils/core';

interface MainGridProps {
  chatIsFocused: boolean;
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  height: 100%;
  box-sizing: border-box;
`;

const CellContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  position: relative;
`;

export const FlexCellContainer = styled(CellContainer)`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainGrid = styled(Grid)<MainGridProps>`
  grid-template-columns: 1fr 320px;
  column-gap: 0;
  height: calc(100% - ${props => (props.chatIsFocused && isChromeForIPad() ? '106' : '64')}px);
  grid-template-areas: 'video chat';

  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr 400px;
  }

  @media ${LandscapeMobile} {
    height: 100%;
    grid-template-columns: 1fr 0;
  }
`;

export const SecondaryGrid = styled(Grid)`
  height: calc(100% - 64px);
  grid-template-columns: 1fr;
  column-gap: 0;
  grid-template-areas: 'video';
`;

export { Container, CellContainer, MainGrid };
