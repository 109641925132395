import React, { FunctionComponent } from 'react';
import { Wrapper, Title } from '../styles';
import { MediumUp } from '@utils/responsive';
import { BasePayload } from '../index';

const ScheduleHeader: FunctionComponent<BasePayload> = ({ title }) => (
  <MediumUp>
    <Wrapper data-testid='scheduleHeader' height={48}>
      <Title data-testid='scheduleHeader-title'>{title}</Title>
    </Wrapper>
  </MediumUp>
);

export default ScheduleHeader;
