import { get, Scale, system } from 'styled-system';
import { css } from 'styled-components';

export interface LogicalProps {
  marginBlockStart?: number | number[] | string;
  marginInlineStart?: number | number[] | string;
  marginBlockEnd?: number | number[] | string;
  marginInlineEnd?: number | number[] | string;
  paddingBlockStart?: number | number[] | string;
  paddingInlineStart?: number | number[] | string;
  paddingBlockEnd?: number | number[] | string;
  paddingInlineEnd?: number | number[] | string;
}

const isNumber = (n: number) => typeof n === 'number' && !isNaN(n);

const getValue = (value: any, scale: Scale | undefined) => {
  if (!isNumber(value)) {
    return get(scale, value, value);
  }

  const isNegative = value < 0;
  const absolute = Math.abs(value);
  const newValue = get(scale, absolute, absolute);
  if (!isNumber(newValue)) {
    return isNegative ? '-' + newValue : newValue;
  }
  return newValue * (isNegative ? -1 : 1);
};

export const getInset = (property: string, value: number | number[] | string) => {
  switch (property) {
    case 'insetBlockStart':
      return `top: ${value}`;
    case 'insetBlockEnd':
      return `bottom: ${value}`;
    case 'insetInlineStart':
      return css`
        [dir='ltr'] & {
          left: ${value};
        }
        [dir='rtl'] & {
          right: ${value};
        }
      `;
    case 'insetInlineEnd':
      return css`
        [dir='ltr'] & {
          right: ${value};
        }
        [dir='rtl'] & {
          left: ${value};
        }
      `;
    default:
      return undefined;
  }
};

export const logical = system({
  marginBlockStart: {
    property: 'marginBlockStart',
    transform: getValue,
    scale: 'space',
  },
  marginInlineStart: {
    property: 'marginInlineStart',
    transform: getValue,
    scale: 'space',
  },
  marginBlockEnd: {
    property: 'marginBlockEnd',
    transform: getValue,
    scale: 'space',
  },
  marginInlineEnd: {
    property: 'marginInlineEnd',
    transform: getValue,
    scale: 'space',
  },
  paddingBlockStart: {
    property: 'paddingBlockStart',
    transform: getValue,
    scale: 'space',
  },
  paddingInlineStart: {
    property: 'paddingInlineStart',
    transform: getValue,
    scale: 'space',
  },
  paddingBlockEnd: {
    property: 'paddingBlockEnd',
    transform: getValue,
    scale: 'space',
  },
  paddingInlineEnd: {
    property: 'paddingInlineEnd',
    transform: getValue,
    scale: 'space',
  },
});
