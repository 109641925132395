import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CaretUpIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='M3.5 9L7.5 5L11.5 9H3.5Z' size={size} {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='M5 12L10 7L15 12H5Z' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='M5 15L12 8L19 15H5Z' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='M7 20L16 11L25 20L7 20Z' size={size} {...rest} />;
  }
};
