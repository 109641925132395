import React, { FC, useEffect, useRef, useState } from 'react';
import Actionable from '@components/Actionable';
import { Container, Button, AccessibilityWrapper } from './styles';
import { createReaction, ReactionType } from '../dux';
import { Props } from './index';
import Heart from '../icons/Heart';
import ReactionTrayButton from '../reactionTrayButton';
import ReactionBanner from '../reactionBanner';
import { setReactionBannerStatus } from '../reactions.utils';
import { useTranslation } from 'react-i18next';

const ReactionButtons: FC<Props> = ({
  publishReaction,
  saveReaction,
  subscriberId,
  isChatEnabled,
  openReactionBanner,
  closeReactionBanner,
  isBannerVisible,
  subscriberHasAccount,
}) => {
  const { t } = useTranslation();
  const [canReact, setCanReact] = useState(true);
  const reactionCount = useRef<number>(0);
  const resetReactionInterval = useRef<NodeJS.Timeout | null>(null);
  const resetBannerInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      resetBannerInterval.current && clearTimeout(resetBannerInterval.current as NodeJS.Timeout);
    };
  }, []);

  const resetReactionCount = () => {
    clearTimeout(resetReactionInterval.current as NodeJS.Timeout);

    resetReactionInterval.current = setTimeout(() => {
      reactionCount.current = 0;
    }, 10000);
  };

  const attemptToPublishReaction = (type: ReactionType | string) => {
    reactionCount.current++;

    if (reactionCount.current < 3) {
      publishReaction({ type });
      resetReactionCount();
    } else if (canReact && reactionCount.current >= 3) {
      publishReaction({ type });
      setCanReact(false);
      setTimeout(() => {
        setCanReact(true);
        resetReactionCount();
      }, 5000);
    }
  };

  const handleReaction = (type: ReactionType | string) => {
    const reaction = createReaction({ subscriberId, type });
    saveReaction(reaction);
    attemptToPublishReaction(type);
    handleBanner();
  };

  const handleBanner = () => {
    isBannerVisible
      ? closeReactionBanner()
      : setReactionBannerStatus(subscriberHasAccount).then(() => showBanner());
  };

  //Show the banner after 3 seconds
  const showBanner = () => {
    //Reset the timer if the user sends another reaction within 3 secs of sending one
    resetBannerInterval.current && clearTimeout(resetBannerInterval.current as NodeJS.Timeout);
    resetBannerInterval.current = setTimeout(() => openReactionBanner(), 3000);
  };

  return (
    <Container isChatEnabled={isChatEnabled}>
      <ReactionBanner />
      <Actionable onClick={() => handleReaction(ReactionType.HEART)} keepFocus={true}>
        <AccessibilityWrapper tabIndex={0} aria-label={t('aria.reaction_heart')}>
          <Button data-testid={'reactionButtonHeart'} tabIndex={-1}>
            <Heart />
          </Button>
        </AccessibilityWrapper>
      </Actionable>
      <ReactionTrayButton type={ReactionType.CLAP} handleReaction={handleReaction} />
      <ReactionTrayButton type={ReactionType.RAISED_HANDS} handleReaction={handleReaction} />
    </Container>
  );
};

export default React.memo<Props>(ReactionButtons);
