import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

type ChangeHandler = (
  event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => void;

export interface CommonProps<T> {
  name: keyof T;
  value: T[keyof T];
  onChange: ChangeHandler;
  error: boolean;
  hint: string | undefined;
}

export interface UseForm<T> {
  values: T;
  setValues: Dispatch<SetStateAction<T>>;
  errors: Record<string, string>;
  setErrors: Dispatch<SetStateAction<Record<string, string>>>;
  formSubmitted: boolean;
  formChanged: boolean;
  updateFormChanged: () => void;
  setFormSubmitted: (hasChanged: boolean) => void;
  getCommonProps: (key: keyof T) => CommonProps<T>;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  DirtyFormMessage: string;
}

export const useForm = <T>(initialState: T): UseForm<T> => {
  const [values, setValues] = useState<T>(initialState);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const updateFormChanged = (): void => {
    if (!formChanged) {
      setFormChanged(true);
    }
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    setValues({ ...values, [name]: value });

    updateFormChanged();

    if (Object.prototype.hasOwnProperty.call(errors, name)) {
      const { [name]: stripped, ...newErrors } = errors;
      setErrors(newErrors);
    }
  };

  const getProperty = <T, K extends keyof T>(o: T, property: K): T[K] =>
    o[property];

  const getCommonProps = (key: keyof T, errorKey?: string): CommonProps<T> => ({
    name: key,
    onChange,
    error: key in errors || (errorKey !== undefined && errorKey in errors),
    hint: errors[key as string] || (errorKey && errors[errorKey]),
    value: getProperty(values, key),
  });

  const DirtyFormMessage =
    'You have unsaved changes which will be lost if you leave this page.';

  return {
    values,
    setValues,
    errors,
    setErrors,
    formChanged,
    updateFormChanged,
    formSubmitted,
    setFormSubmitted,
    getCommonProps,
    onChange,
    DirtyFormMessage,
  };
};
