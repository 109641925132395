import { AlertInterface } from '../dux';
import { Dispatch } from 'redux';
import WelcomeAlert from './WelcomeAlert';
import { connect } from 'react-redux';
import { LoginMeta } from '../../modal/login/dux';
import { SignupMeta } from '../../modal/signup/dux';
import { login as loginModal, signupModal } from '../../modal/dux';
import { RootState } from '@store/rootReducer';
import { getIntegrationTypes } from '@store/organizationSlice/selectors';
import { IntegrationType } from '../../../../__generated__/globalTypes';
import { setModal } from '@store/uiSlice';

interface StateProps {
  externalLoginUrl?: string;
}

interface OwnProps {
  alert: AlertInterface;
  dismissAlert: () => void;
}

interface DispatchProps {
  login: (meta: LoginMeta) => void;
  signup: (meta?: SignupMeta) => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  const integrationTypes = getIntegrationTypes(state);

  let externalLoginUrl;
  if (integrationTypes.includes(IntegrationType.OPEN_ID_CONNECT)) {
    externalLoginUrl = '/auth/openid_connect';
  } else if (integrationTypes.includes(IntegrationType.LEGACY_AUTH)) {
    externalLoginUrl = '/auth/legacy';
  }

  return {
    externalLoginUrl,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (meta: LoginMeta) => dispatch(setModal(loginModal(meta))),
  signup: () => dispatch(setModal(signupModal('welcome_alert'))),
});

const VisibleWelcomeAlert = connect(mapStateToProps, mapDispatchToProps)(WelcomeAlert);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleWelcomeAlert;
