import React, { FC, ReactElement, useState } from 'react';
import { ButtonSize, LinkButton, LinkButtonType } from '@lifechurch/react-ion';
import { localizedTimeReference, sanitizeString } from '@utils/core';
import Linkify from 'linkify-react';
import { useTranslation } from 'react-i18next';
import SenderName from '../senderName';
import { Message } from './dux';
import LikeEngagement from '@components/engagement/engagement';
import { ChannelType } from '../../../../../__generated__/globalTypes';
import clsx from 'clsx';
import styles from './messageBody.module.css';

type Props = {
  hasTranslation: boolean;
  isCompact: boolean;
  message: Message;
  hasLiked: boolean;
  sendSeeOriginalTranslationEvent: (messageId: string, originalLanguage: string) => void;
  likeMessage: (message: Message) => void;
};

interface TimeReferenceProps {
  isCompact: boolean;
  timestamp: string;
}

const linkifyOptions = { attributes: { rel: 'noopener noreferrer', target: '_blank' } };
const sanitizeConfig = { ALLOWED_TAGS: [], ALLOWED_ATTR: [] };

const TimeReference: FC<TimeReferenceProps> = ({ timestamp, isCompact }) => {
  const { t } = useTranslation();
  return (
    <span
      data-testid='message-timeWrapper'
      className={clsx([styles.timeReference], {
        [styles.compactTime]: isCompact,
      })}
    >
      {localizedTimeReference(timestamp, t)}
    </span>
  );
};

export function MessageBody({
  hasTranslation,
  isCompact,
  message,
  hasLiked,
  sendSeeOriginalTranslationEvent,
  likeMessage,
}: Props): ReactElement {
  const [showOriginalText, setShowOriginalText] = useState(false);
  const { t } = useTranslation(['common', 'locales']);
  const { id, timestamp, subscriber, text, originalText, lang } = message;
  const displayText = showOriginalText ? originalText : text;

  const onLike = () => {
    if (!hasLiked) {
      likeMessage(message);
    }
  };

  const handleTranslationClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!showOriginalText) sendSeeOriginalTranslationEvent(id, lang);
    setShowOriginalText(!showOriginalText);
  };

  // DOMPurify converts incomplete HTML into escape codes, but we render as text
  const hasSubtext: boolean = hasTranslation || message.channel.type === ChannelType.public;
  const renderText = sanitizeString(displayText, sanitizeConfig)
    .replace('&lt;', '<')
    .replace('&gt;', '>')
    .replace('&amp;', '&');
  return (
    <div
      data-testid='message-textWrapper'
      key={id}
      data-node='text'
      className={clsx([styles.textWrapper], { [styles.compact]: isCompact })}
    >
      <p className={styles.info}>
        <SenderName name={subscriber.nickname || ''} roleIdentifier={subscriber.roleIdentifier} />
        {isCompact ? (
          <>
            <span> </span>
            <Linkify options={linkifyOptions}>{renderText}</Linkify>
          </>
        ) : (
          <>
            <TimeReference isCompact={isCompact} timestamp={timestamp} />
          </>
        )}
      </p>

      {isCompact ? (
        <div className={styles.compactInfo}>
          <TimeReference isCompact={isCompact} timestamp={timestamp} />

          {message.channel.type === ChannelType.public && (
            <LikeEngagement onLike={onLike} hasLiked={hasLiked} likeCount={message.likeCount} />
          )}
          {hasTranslation && (
            <LinkButton
              buttonType={LinkButtonType.SECONDARY}
              onClick={handleTranslationClick}
              data-testid='translate-text-button'
              size={ButtonSize.SM}
              lineHeight='17px'
            >
              {showOriginalText
                ? t('see_translation')
                : t('see_original', { language: t(`locales:${message.lang}`) })}
            </LinkButton>
          )}
        </div>
      ) : (
        <>
          <p className={styles.expandedMessage}>
            <Linkify options={linkifyOptions}>{renderText}</Linkify>
          </p>
          {hasSubtext && (
            <div className={styles.expandedSubText}>
              {message.channel.type === ChannelType.public && (
                <LikeEngagement onLike={onLike} hasLiked={hasLiked} likeCount={message.likeCount} />
              )}
              {hasTranslation && (
                <LinkButton
                  buttonType={LinkButtonType.SECONDARY}
                  onClick={handleTranslationClick}
                  data-testid='translate-text-button'
                  size={ButtonSize.SM}
                  lineHeight='17px'
                >
                  {showOriginalText
                    ? t('see_translation')
                    : t('see_original', { language: t(`locales:${message.lang}`) })}
                </LinkButton>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
