import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowUpRightIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M6 3H11H12V4V9H11V4.70711L3.70711 12H3V11.2929L10.2929 4H6V3Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
