import React, { FC } from 'react';
import { Props } from './index';
import { Container, Info, Message, PrayerWrapper } from './style';
import { Link } from '@features/serviceInfo/serviceMeta/styles';
import { defaultColors, OParagraph } from '@lifechurch/react-ion';
import { Trans, useTranslation } from 'react-i18next';
import PrayerButton from '@components/PrayerButton';
import Media from '@features/media';

const Empty: FC<Props> = ({ url, organization, isGuest }) => {
  const { t } = useTranslation();
  return (
    <Container data-testid='empty'>
      {url && <Media url={url} />}
      <Message isGuest={isGuest} hasMedia={!!url}>
        <Info isGuest={isGuest} hasMedia={!!url}>
          <OParagraph color={defaultColors.gray10} marginBlockEnd={4}>
            {t('no_upcoming_service.part_1')}
          </OParagraph>
          {organization.website && (
            <OParagraph color={defaultColors.gray10} marginBlockEnd={3}>
              {t('no_upcoming_service.part_2')}{' '}
              <Link href={organization.website} target='_blank' rel='noopener noreferrer'>
                {organization.website}
              </Link>{' '}
              <Trans i18nKey='no_upcoming_service.part_3'>
                to get connected with {{ church: organization.name }}.
              </Trans>
            </OParagraph>
          )}
        </Info>
        <PrayerWrapper>
          <PrayerButton />
        </PrayerWrapper>
      </Message>
    </Container>
  );
};

export default Empty;
