import { connect } from 'react-redux';
import NavMenu from './navMenu';
import {
  getDirectChannels,
  getPlaceholderChannels,
  getHostChannel,
} from '@store/feedSlice/channelSelectors';
import { PaneType, Pane, BasePanes } from '../pane/dux';
import { isNavMenuExpanded, getPane, isModalOpen, isSideMenuOpen } from '@store/uiSlice/selectors';
import { hasPermissions, getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { Subscriber } from '../../types';
import { Dispatch } from 'redux';
import { createNavbarChatItem, createNavbarItem, NavbarItem } from '../navbar/selectors';
import { isChatEnabled, getCurrentService, isOnline } from '@store/serviceSlice/selectors';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { toggleNavMenuExpanded, setPaneToType, setPaneToChat, openSideMenu } from '@store/uiSlice';
import { RootState } from '@store/rootReducer';

interface StateProps {
  isOpen: boolean;
  organizationName: string;
  directChannels: Array<NavbarItem>;
  currentPane: Pane;
  expanded: boolean;
  hostChannel?: NavbarItem;
  isChatEnabled: boolean;
  hasNotes: boolean;
  currentSubscriber: Subscriber;
  serviceOnline: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const direct = getDirectChannels(state);
  const placeholder = getPlaceholderChannels(state);
  const currentSubscriber = getCurrentSubscriber(state);
  const currentService = getCurrentService(state);
  const nextService = getNextServiceData(state);
  const currentPane = getPane(state);
  const directChannels = [...direct, ...placeholder].map(channel =>
    createNavbarChatItem(channel, currentPane, currentSubscriber)
  );

  const hostChannel = hasPermissions(state, ['feed.host.read', 'feed.host.write'])
    ? createNavbarItem(PaneType.HOST_CHAT, currentPane, getHostChannel(state))
    : undefined;
  const chatEnabled = isChatEnabled(state);
  const hasNotes = !!currentService.content.notes;
  const serviceOnline = isOnline(state);

  return {
    isOpen: isModalOpen(state) || isSideMenuOpen(state),
    organizationName: state.organization.name,
    directChannels,
    currentPane,
    expanded: isNavMenuExpanded(state),
    hostChannel,
    isChatEnabled: chatEnabled || (nextService?.content?.features?.publicChat ?? false),
    hasNotes,
    currentSubscriber,
    serviceOnline,
  };
};

interface DispatchProps {
  openMenu: () => void;
  setPaneToChat: (channelKey: string) => void;
  setPaneToType: (type: BasePanes) => void;
  toggleExpanded: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openMenu: () => dispatch(openSideMenu()),
  toggleExpanded: () => dispatch(toggleNavMenuExpanded()),
  setPaneToChat: (channelKey: string) => dispatch(setPaneToChat(channelKey)),
  setPaneToType: (type: BasePanes) => dispatch(setPaneToType(type)),
});

const VisibleNavMenu = connect(mapStateToProps, mapDispatchToProps)(NavMenu);

export type Props = StateProps & DispatchProps;
export default VisibleNavMenu;
