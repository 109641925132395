import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import Empty from './empty';
import { CurrentState_currentOrganization } from '@io/__generated__/CurrentState';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';

interface StateProps {
  url: string | null;
  organization: CurrentState_currentOrganization;
  isGuest: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const { offlineContent } = getCurrentOrganization(state);

  return {
    url: offlineContent?.url || null,
    organization: state.organization,
    isGuest: !isHostViewingAsHost(state),
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Empty);
