import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Merge } from '../../types';
import {
  CurrentState_currentService,
  CurrentState_currentService_content,
} from '@io/__generated__/CurrentState';

export interface LiveService {
  id: string;
  momentSchedulingEnabled: boolean;
}

type InitialState = Merge<
  Omit<CurrentState_currentService, 'sequence' | 'feed'>,
  {
    content: CurrentState_currentService_content;
    momentSchedulingEnabled: boolean;
  }
>;

export const initialState: InitialState = {
  id: '',
  scheduleTime: '',
  startTime: '',
  endTime: '',
  momentSchedulingEnabled: true,
  content: {
    id: '',
    title: '',
    features: {
      publicChat: false,
      livePrayer: false,
    },
    hasVideo: false,
    video: null,
    hostInfo: '',
    notes: '',
    videoStartTime: '',
  },
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setService(_state, action: PayloadAction<InitialState>) {
      return action.payload;
    },
    liveServiceUpdate(state, action: PayloadAction<LiveService>) {
      if (state.id === action.payload.id) {
        return {
          ...state,
          ...action.payload,
        };
      }
    },
  },
});

export const queryCurrentService = createAction('service/queryCurrentService');

export const { setService, liveServiceUpdate } = serviceSlice.actions;
export type ServiceState = ReturnType<typeof serviceSlice.reducer>;
export default serviceSlice.reducer;
