import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  compose,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import { InputSize, StyledContext, StyledLabel } from './Input';
import { getInset, logical, LogicalProps } from './logical';

// There are conflicting TS types, so we need to omit some to pass typechecking
type NativeSelect = Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size' | 'aria-relevant'>;

export interface SelectProps
  extends NativeSelect,
    BorderProps,
    SpaceProps,
    LogicalProps,
    TypographyProps {
  size?: InputSize;
  label?: string;
  context?: ReactNode;
  valid?: boolean;
  error?: boolean;
  hint?: ReactNode;
}

type BaseSelectProps = { valid?: boolean; error?: boolean; selectSize: InputSize } & BorderProps &
  SpaceProps &
  LogicalProps &
  TypographyProps;

const selectSizeVariant = variant({
  prop: 'selectSize',
  variants: {
    sm: {
      fontSize: 2,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 32,
      paddingBlockStart: 7,
      paddingBlockEnd: 7,
      borderRadius: 2,
    },
    md: {
      fontSize: 3,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 35,
      paddingBlockStart: 9,
      paddingBlockEnd: 9,
      borderRadius: 2,
    },
    lg: {
      fontSize: 4,
      lineHeight: 3,
      paddingInlineStart: 12,
      paddingInlineEnd: 40,
      paddingBlockStart: 11,
      paddingBlockEnd: 12,
      borderRadius: 2,
    },
  },
});

const BaseSelect = styled.select<BaseSelectProps>`
  display: block;
  width: 100%;
  border: 1px solid ${(props): string => props.theme.colors.gray30};
  outline: none;
  background-color: ${(props): string => props.theme.colors.white};
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  color: ${props => props.theme.colors.gray100};

  &:focus,
  &:focus-within {
    border-color: ${(props): string => props.theme.colors.action.default};
  }

  &:disabled {
    background-color: ${(props): string => props.theme.colors.gray10};
    cursor: not-allowed;
  }

  border-color: ${(props): string => {
    if (props.valid) {
      return props.theme.colors.success.default;
    } else if (props.error) {
      return props.theme.colors.error.default;
    } else {
      return props.theme.colors.gray30;
    }
  }};
  ${compose(border, space, logical, typography, selectSizeVariant)}
`;

BaseSelect.defaultProps = {
  selectSize: InputSize.MD,
};

interface SelectWrapperProps {
  size: InputSize;
  withLabel: boolean;
}

const borderSizing = (size: InputSize) => {
  switch (size) {
    case InputSize.SM:
      return 4;
    case InputSize.MD:
      return 5;
    case InputSize.LG:
      return 7;
    default:
      return 5;
  }
};

const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-inline-start: ${(props): number => borderSizing(props.size)}px solid transparent;
    border-inline-end: ${(props): number => borderSizing(props.size)}px solid transparent;
    border-block-start: ${(props): number => borderSizing(props.size)}px solid
      ${(props): string => props.theme.colors.gray50};
    ${props => {
      switch (props.size) {
        case InputSize.SM:
          return props.withLabel
            ? getInset('insetBlockStart', '33px')
            : getInset('insetBlockStart', '14px');
        case InputSize.MD:
          return props.withLabel
            ? getInset('insetBlockStart', '37px')
            : getInset('insetBlockStart', '18px');
        case InputSize.LG:
          return props.withLabel
            ? getInset('insetBlockStart', '47px')
            : getInset('insetBlockStart', '24px');
        default:
          return getInset('insetBlockStart', '39px');
      }
    }};
    ${getInset('insetInlineEnd', '14px')};
    position: absolute;
    pointer-events: none;
  }
`;

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      value = '',
      size = InputSize.MD,
      label,
      context,
      valid,
      error,
      hint,
      disabled,
      required,
      ...props
    }: SelectProps,
    ref
  ) => (
    <SelectWrapper size={size} withLabel={!!label}>
      {label && (
        <StyledLabel
          htmlFor={name}
          size={size}
          fontWeight={2}
          marginBlockEnd={2}
          lineHeight={2}
          required={required}
        >
          {label}
        </StyledLabel>
      )}
      <BaseSelect
        ref={ref}
        id={name}
        selectSize={size}
        name={name}
        valid={valid}
        error={error}
        disabled={disabled}
        required={required}
        value={value}
        {...props}
      />
      {hint && (
        <StyledContext size={size} color={error ? 'error.default' : 'success.default'}>
          {hint}
        </StyledContext>
      )}
      {context && <StyledContext size={size}>{context}</StyledContext>}
    </SelectWrapper>
  )
);
Select.displayName = 'ForwardedSelect';

export { Select };
