import React from 'react';
import * as Sentry from '@sentry/browser';
import { InviteMoment as BaseInviteMoment } from '@churchonline/moments';
import { useTranslation } from 'react-i18next';
import { MomentActionType, MomentType } from '../../../../../../__generated__/globalTypes';
import { inviteMomentShareSheet } from '../../../../../components/modal/dux';
import { Props } from './';
import { isMacOS } from '@utils/core';

const InviteMoment: React.FunctionComponent<Props> = ({
  moment,
  onDismiss,
  isPinned,
  setModal,
  submitMomentAction,
}) => {
  const { t } = useTranslation('moments');
  const text =
    moment.momentTemplate.options.text &&
    moment.momentTemplate.options.text !== 'Invite someone to church online.'
      ? moment.momentTemplate.options.text
      : t('invite.text');

  const buttonText =
    moment.momentTemplate.options.buttonText &&
    moment.momentTemplate.options.buttonText !== 'Invite'
      ? moment.momentTemplate.options.buttonText
      : t('invite.button');

  const shouldUseNativeShareSheet = typeof navigator?.share !== 'undefined' && !isMacOS();

  const handleClick = (): void => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.INVITE,
      momentActionType: MomentActionType.SHARE,
    });

    if (shouldUseNativeShareSheet) {
      const shareObj = {
        url: window.location.href,
      };

      navigator
        .share(shareObj)
        .then(() => {
          // do nothing
        })
        .catch((e: Error) => {
          const error = e.toString();
          // It will reject immediately if the data parameter
          // is not correctly specified, and will also reject
          // if the user cancels sharing. (MDN Navigator.share())
          if (error.includes('AbortError')) {
            return;
          }

          if (error.includes('TypeError')) {
            // I include the shareObj, because TypeError indicates a data parameter error.
            // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-425974249
            Sentry.withScope(scope => {
              scope.setExtra('shareObject', shareObj);
              Sentry.captureException(e);
            });
          }
        });
    } else {
      setModal(inviteMomentShareSheet());
    }
  };

  return (
    <BaseInviteMoment
      text={text}
      buttonText={buttonText}
      onClick={handleClick}
      onDismiss={onDismiss}
      isPinned={isPinned}
    />
  );
};

export default InviteMoment;
