import React, { FunctionComponent, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Pixels from '../../lib/pixels';
import { useTranslation } from 'react-i18next';
import {
  CurrentState_currentOrganization_integrations_Osano,
  CurrentState_currentOrganization_integrations_Segment,
} from '@io/__generated__/CurrentState';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

interface Props {
  faviconUrl?: string | null;
  language: string;
  name: string;
}

const Meta: FunctionComponent<Props> = ({ faviconUrl, language, name }) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir(language);

  const segmentIntegration = useSelector<
    RootState,
    CurrentState_currentOrganization_integrations_Segment | undefined
  >(
    state =>
      state.organization.integrations.find(
        integration => integration.__typename === 'Segment'
      ) as CurrentState_currentOrganization_integrations_Segment
  );

  const osanoIntegration = useSelector<
    RootState,
    CurrentState_currentOrganization_integrations_Osano
  >(
    state =>
      state.organization.integrations.find(
        integration => integration.__typename === 'Osano'
      ) as CurrentState_currentOrganization_integrations_Osano
  );

  const osanoSrc = useMemo(() => {
    if (osanoIntegration?.config?.accountId && osanoIntegration?.config?.configId) {
      return `https://cmp.osano.com/${osanoIntegration.config.accountId}/${osanoIntegration.config.configId}/osano.js`;
    }
    return null;
  }, [osanoIntegration]);

  const segmentSrc = useMemo(() => {
    if (!segmentIntegration?.config?.segmentKey) {
      return null;
    }

    return (
      segmentIntegration.config.segmentDomain ??
      `https://cdn.segment.com/analytics.js/v1/${segmentIntegration.config.segmentKey}/analytics.min.js`
    );
  }, [segmentIntegration]);

  return (
    <>
      <Helmet>
        <html lang={language} dir={direction} />
        <title>{name}</title>
        {faviconUrl && (
          <>
            <link rel='icon' href={faviconUrl + '?h=32&w=32'} sizes='32x32' />
            <link rel='icon' href={faviconUrl + '?h=128&w=128'} sizes='128x128' />
            <link rel='icon' href={faviconUrl + '?h=192&w=192'} sizes='192x192' />
            <link rel='icon' href={faviconUrl + '?h=196&w=196'} sizes='196x196' />
            <link rel='apple-touch-icon' href={faviconUrl + '?h=152&w=152'} sizes='152x152' />
            <link rel='apple-touch-icon' href={faviconUrl + '?h=167&w=167'} sizes='167x167' />
            <link rel='apple-touch-icon' href={faviconUrl + '?h=180&w=180'} sizes='180x180' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='msapplication-square70x70logo' content={faviconUrl + '?h=70&w=70'} />
            <meta name='msapplication-square150x150logo' content={faviconUrl + '?h=150&w=150'} />
            <meta name='msapplication-TileImage' content={faviconUrl + '?h=270&w=270'} />
            <meta name='msapplication-config' content='none' />
          </>
        )}
        {segmentIntegration && (
          <script type='text/javascript'>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${segmentSrc}";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentIntegration.config.segmentKey}";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("${segmentIntegration.config.segmentKey}");
          analytics.page();
          }}();
        `}</script>
        )}
        {osanoIntegration && osanoSrc && <script type='text/javascript' src={osanoSrc}></script>}
      </Helmet>
      <Pixels />
    </>
  );
};

export { Meta };
