import { SagaIterator } from '@redux-saga/core';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import { all, call, delay, put, select, take } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import {
  ChannelType,
  MessageActionInput,
  MomentActionInput,
  MomentTriggerType,
} from '../../../__generated__/globalTypes';
import { directChatAlert, prayerAlert } from '@components/Alert/dux';
import { AudioType, playAudio } from '../../lib/audio';
import {
  getChannelByKey,
  getChannels,
  getReactionChannel,
} from '@store/feedSlice/channelSelectors';
import {
  addChannel as addChannelAction,
  addChannelGroup as addChannelGroupAction,
  ChannelGroup,
  deleteMoment as deleteMomentAction,
  momentsAdapter,
  publishReaction as publishReactionAction,
  removeChannel as removeChannelAction,
  removeChannelGroup as removeChannelGroupAction,
  saveChannel,
  saveMessage,
  saveMoment,
  saveNotification,
  savePrayerRequest,
  savePrivateChannelInvite,
  saveReaction,
  setChannelGroups as setChannelGroupsAction,
  setChannels as setChannelsAction,
  setGroupLoading,
  setLoading,
  setMessageLikeCount as setMessageLikeCountAction,
  setMessageLikeCount,
  setPubnubKeys,
  submitMessageAction as submitMessageActionAction,
  submitMomentAction as submitMomentActionAction,
  typingInChannel as typingInChannelAction,
  updateSubscribersTyping,
} from '@store/feedSlice';
import { NotificationType } from '@features/feed/objects/notification/dux';
import {
  LiveObjectType,
  publishLiveObject as publishLiveObjectAction,
} from '../../pubnub/liveObject';
import { postMoment as postMomentAction } from '@features/moments/dux';
import { Pane, PaneType } from '@features/pane/dux';
import {
  LoadHistory,
  OnObject,
  onObject as onObjectAction,
  OnSignal,
  PubnubPublishFailed,
} from '../../pubnub/dux';
import { createReaction } from '@features/reactions/dux';
import { liveServiceUpdate } from '@store/serviceSlice';
import { isMomentSchedulingEnabled } from '@store/serviceSlice/selectors';
import { setSubscriber } from '@store/subscriberSlice';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { PublicChannel as Channel, PublicSubscriber, Subscriber } from '../../types';
import { getCurrentLanguage, getPane } from '@store/uiSlice/selectors';
import { CurrentState_currentSubscriber } from '../__generated__/CurrentState';
import PubnubClient from '../pubnubClient';
import queries from '../queries';
import {
  deleteMomentMetric,
  messageAction,
  momentAction,
  momentSubmission,
  momentView,
  postMoment as postMomentMetric,
  publishMessage as publishMessageMetric,
  reaction as reactionMetric,
} from './metrics';
import { addLanguageToChannels } from './translation';
import { RootState } from '@store/rootReducer';
import { Message } from '../../pubnub/types';

function* setPubnubClient(): SagaIterator<void> {
  const [currentSubscriber, pubnubKeys] = yield all([
    take(setSubscriber.type),
    take(setPubnubKeys.type),
  ]);

  PubnubClient.config({
    publishKey: pubnubKeys.payload.publishKey,
    subscribeKey: pubnubKeys.payload.subscribeKey,
    authKey: currentSubscriber.payload.pubnubAuthKey,
    uuid: currentSubscriber.payload.id,
    restore: true,
    presenceTimeout: 60,
  });

  PubnubClient.addListener();
}

function* addChannel(action: ReturnType<typeof addChannelAction>): SagaIterator {
  yield call(PubnubClient.subscribe, [action.payload.key]);
  yield call(PubnubClient.history, action.payload);
}

function* addChannelGroup(action: ReturnType<typeof addChannelGroupAction>): SagaIterator {
  yield call(PubnubClient.subscribeToGroups, [action.payload.key]);
}

export const loadPublicChat = async (publicChannel: Channel | undefined): Promise<void> => {
  if (publicChannel) {
    await PubnubClient.history(publicChannel);
  }
};

function* setChannels(action: ReturnType<typeof setChannelsAction>) {
  const language: string = yield select(getCurrentLanguage);
  const { channels, channelGroups } = action.payload;
  const channelKeys = channels.map(channel => channel.key);

  PubnubClient.subscribe(channelKeys);

  yield call(
    addLanguageToChannels,
    language,
    channelKeys.filter(key => key.startsWith('chat'))
  );

  // load command channel first to handle deleted messages
  const commandChannel =
    channels.find(channel => channel.type === ChannelType.command) || channels[0]; // default to first channel
  PubnubClient.history(commandChannel);
  yield call(
    loadPublicChat,
    channels.find(channel => channel.type === ChannelType.public)
  );
  const otherChannels = channels.filter(
    channel =>
      channel.type !== ChannelType.command &&
      channel.type !== ChannelType.reaction &&
      channel.type !== ChannelType.public
  );
  otherChannels.forEach(channel => PubnubClient.history(channel, 50));
  yield put(setChannelGroupsAction(channelGroups));
}

function* handleMessageAction(message: Message) {
  yield put(
    setMessageLikeCountAction({
      messageId: message.message.data.id,
      likeCount: message.message.data.like,
      channelKey: message.message.data.channel.key,
    })
  );
}

function* callListChannels(channelGroup: ChannelGroup): SagaIterator<void> {
  // @ts-expect-error
  const channels = yield new Promise(resolve => {
    PubnubClient.listChannels(channelGroup.key, async (status, response) => {
      if (status.error) {
        Sentry.withScope(scope => {
          scope.setContext('Pubnub', {
            category: status.category,
            operation: status.operation,
            statusCode: status.statusCode,
            errorData: status.errorData,
          });
          Sentry.captureMessage('Pubnub Error with listChannels.');
        });
        return;
      }

      if (channelGroup.type === ChannelType.message_action) {
        const messageActionChannels = response.channels.filter(channel =>
          channel.startsWith('message_action')
        );

        const res = await PubnubClient.fetchMessages(messageActionChannels);
        const resolvedValue = res.response ? Object.values(res.response.channels) : [];
        resolve(resolvedValue);
      } else {
        response.channels.forEach(
          channel =>
            new Promise(resolve => {
              if (
                channelGroup.type !== ChannelType.moment ||
                (channelGroup.type === ChannelType.moment && channel.startsWith('moment.'))
              ) {
                PubnubClient.history(
                  {
                    key: channel,
                    id: '',
                    name: '',
                    type: channelGroup.type,
                    direct: false,
                    group: false,
                  },
                  1,
                  true
                ).then(() => resolve([]));
              }
            })
        );
      }

      resolve(response.channels);
    });
  });

  if (channelGroup.type === ChannelType.message_action) {
    yield all(channels.map((channel: Message[]) => handleMessageAction(channel[0])));
  }
}

function* setChannelGroups(action: ReturnType<typeof setChannelGroupsAction>) {
  PubnubClient.subscribeToGroups(action.payload.map(channelGroup => channelGroup.key));

  yield all(
    action.payload.map(channelGroup => {
      return call(callListChannels, channelGroup);
    })
  );

  // This gives Redux a second to flush the SAVE_MOMENT's
  yield delay(2000);

  yield all(
    action.payload.map(channelGroup => {
      return put(
        setGroupLoading({
          channelKey: channelGroup.key,
          loading: false,
        })
      );
    })
  );
}

// eslint-disable-next-line require-yield
function* subscribeToChannel(channel: Channel) {
  const language: string = yield select(getCurrentLanguage);

  PubnubClient.subscribe([channel.key]);

  yield call(addLanguageToChannels, language, [channel.key]);

  const delayHistoryChannelTypes = [ChannelType.moment, ChannelType.direct, ChannelType.prayer];
  if (delayHistoryChannelTypes.includes(channel.type)) {
    yield delay(1000);
  }
  PubnubClient.history(channel);
}

//eslint-disable-next-line require-yield
function* typingInChannel(action: ReturnType<typeof typingInChannelAction>) {
  const channelKey = action.payload.channelKey;
  const isTyping = action.payload.isTyping;
  PubnubClient.signal(channelKey, isTyping ? '1' : '0');
}

// eslint-disable-next-line require-yield
function* unsubscribeFromChannel(key: string) {
  PubnubClient.unsubscribe({ channels: [key] });
}

function* handlePubnubErrors(action: PubnubPublishFailed) {
  yield call([Sentry, Sentry.captureException], action.payload);
}

function* publishLiveObject(action: ReturnType<typeof publishLiveObjectAction>) {
  PubnubClient.publish(action.payload);
  if (action.payload.channel && action.payload.type === LiveObjectType.MESSAGE) {
    yield call(publishMessageMetric, action.payload.channel.id, action.payload.id);
  }
}

function* publishReaction(action: ReturnType<typeof publishReactionAction>) {
  const reactionChannel: Channel = yield select(getReactionChannel);
  if (reactionChannel) {
    PubnubClient.signal(reactionChannel.key, action.payload.type);
    yield call(reactionMetric, action.payload.type);
  }
}

function* loadHistory(action: LoadHistory) {
  const channel: Channel = action.payload.channel;
  const messages = action.payload.messages;

  // Don't load history for reactions
  if (channel.type === ChannelType.reaction) {
    return;
  }

  yield put(
    setLoading({
      channelKey: channel.key,
      loading: true,
    })
  );
  yield all(
    messages.map(message =>
      put(
        onObjectAction(
          {
            channel: channel.key,
            message: message.entry,
            subscription: '',
            timetoken: '',
            publisher: '',
            actualChannel: '',
            subscribedChannel: '',
          },
          true
        )
      )
    )
  );
  yield put(
    setLoading({
      channelKey: channel.key,
      loading: false,
    })
  );
}

function* onObject(action: OnObject) {
  const state: RootState = yield select();
  const {
    payload: { message: object },
  } = action;
  const isLoading = getChannels(state)[action.payload.channel]?.loading || false;

  if (action.payload.channel !== object.channel.key) {
    return;
  }
  // remove when backend is fixed
  if (isLoading && object.type === LiveObjectType.CHANNEL) {
    return;
  }

  switch (object.type) {
    case LiveObjectType.MESSAGE: {
      // return if delete doesn't come from the server
      if (object.channel?.type !== ChannelType.command && object.data?.deleted) {
        return;
      }

      yield put(saveMessage(object));

      const currentPane: Pane = yield select(getPane);
      const isFocused =
        currentPane?.type === PaneType.CHAT &&
        currentPane.meta.channelKey === object.data.channel.key;
      const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
      const isCurrentSubscriber = object.data?.subscriber?.id === currentSubscriber?.id;
      const shouldPlayAudio = !isFocused && !isCurrentSubscriber;
      // Check if current channel is not focused or it's another type of pane
      // Make sure it's not the current subscriber
      if (shouldPlayAudio) {
        // Play a sound on the initial direct chat message
        const channel = getChannels(state)[object.data.channel.key];
        const isFirstMessage = channel?.messages?.ids.length === 0;
        if (object.data.channel.type === ChannelType.direct && isFirstMessage && !isLoading) {
          playAudio(AudioType.INITIAL_DIRECT_CHAT);
        } else if (!isLoading && object.data.channel.direct) {
          playAudio(AudioType.NEW_MESSAGE);
        }
      }
      break;
    }
    case LiveObjectType.PRAYER_REQUEST:
      yield put(savePrayerRequest(object));
      if (object.data.open && !isLoading) {
        playAudio(AudioType.PRAYER_REQUEST);
      }
      break;
    case LiveObjectType.NOTIFICATION: {
      if (object.channel?.type !== ChannelType.public) {
        const {
          data: { type, subscriber },
        } = object;
        yield put(saveNotification(object));

        const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
        const isCurrentSubscriber = subscriber.id === currentSubscriber.id;
        const shouldPlayAudio = !isLoading && !isCurrentSubscriber;
        if (shouldPlayAudio) {
          if (type === NotificationType.JOINED_CHANNEL) {
            playAudio(AudioType.JOINED_CHANNEL);
          } else if (type === NotificationType.LEFT_CHANNEL) {
            playAudio(AudioType.LEFT_CHANNEL);
          }
        }
      }
      break;
    }
    case LiveObjectType.SUBSCRIBER:
      return;
    case LiveObjectType.CHANNEL: {
      const {
        data: { key, type, subscribers },
      } = object as {
        data: { key: string; type: ChannelType; subscribers: Array<PublicSubscriber> };
      };
      const pane: Pane = yield select(getPane);
      const isChatPaneFocused =
        pane.type === PaneType.CHAT && pane.meta?.channelKey === object.data.key;
      const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
      const otherSubscriber = subscribers.find(
        subscriber => subscriber.id !== currentSubscriber.id
      );

      if (!getChannels(state)[key]) {
        yield call(subscribeToChannel, object.data);
        if (type === ChannelType.direct && !isChatPaneFocused) {
          yield put(
            directChatAlert(
              {
                name: otherSubscriber?.nickname ?? '',
                roleIdentifier: otherSubscriber?.roleIdentifier ?? undefined,
              },
              key
            )
          );
        }
      }

      // Send alert if it's a prayer and the pane isn't in focus
      if (type === ChannelType.prayer && !isChatPaneFocused) {
        yield put(
          prayerAlert(
            {
              name: otherSubscriber?.nickname ?? '',
              roleIdentifier: otherSubscriber?.roleIdentifier ?? undefined,
            },
            key
          )
        );
      }

      yield put(
        saveChannel({
          ...object.data,
        })
      );
      break;
    }
    case LiveObjectType.MOMENT: {
      const fiveMinutesBeforePageLoad = new Date(window.PAGE_LOAD_TIME.getTime() - 5 * 60000);
      if (object.data.canceled === true && action.meta.fromHistory) break;
      if (dayjs(object.data.postTime).isBefore(fiveMinutesBeforePageLoad)) break;
      if (object.data.trigger === MomentTriggerType.SCHEDULER) {
        const displayTime = dayjs(object.data.postTime);
        const now = dayjs();

        if (displayTime.isAfter(now)) {
          const delayTime = displayTime.diff(now, 'millisecond');
          yield delay(delayTime);
        }
      }

      const isSchedulingEnabled: boolean = yield select(isMomentSchedulingEnabled);
      const channelExists = !!state.feed.channels[object.data.channel.key];

      if (
        channelExists &&
        (object.data.trigger !== MomentTriggerType.SCHEDULER ||
          (object.data.trigger === MomentTriggerType.SCHEDULER && isSchedulingEnabled))
      ) {
        yield put(
          saveMoment(
            { ...object, data: { ...object.data, type: object.data.momentTemplate.type } },
            { isChatEnabled: state.service.content.features?.publicChat || false }
          )
        );

        const momentExists = momentsAdapter
          .getSelectors()
          .selectById(state.feed.channels[object.data.channel.key]?.moments, object.data.id);

        if (!momentExists) {
          yield call(momentView, object.data.id);
        }
      }
      break;
    }
    case LiveObjectType.PRIVATE_CHANNEL_INVITE: {
      yield put(savePrivateChannelInvite(object));
      if (!isLoading) {
        playAudio(AudioType.PRIVATE_CHANNEL_INVITE);
      }
      break;
    }
    case LiveObjectType.SERVICE: {
      yield put(liveServiceUpdate(object.data));
      break;
    }
    case LiveObjectType.MESSAGE_ACTION_AGGREGATE: {
      yield put(
        setMessageLikeCount({
          messageId: object.data.id,
          channelKey: object.data.channel.key,
          likeCount: object.data.like,
        })
      );
    }
  }
}

function* onSignal(action: OnSignal) {
  const { message, publisher, channel, timetoken } = action.payload;
  const currentSubscriber: CurrentState_currentSubscriber = yield select(getCurrentSubscriber);
  if (publisher !== currentSubscriber.id) {
    const formattedTimetoken = Number.parseInt(timetoken) / 10000;

    if (channel.startsWith('reaction')) {
      const reaction = createReaction({
        subscriberId: publisher,
        type: message,
        channelKey: channel,
      });

      yield put(saveReaction(reaction));
    } else {
      yield put(
        updateSubscribersTyping({
          isTyping: message === '1',
          channelKey: channel,
          subscriberId: publisher,
          timetoken: formattedTimetoken,
        })
      );
    }
  }
}

function* postMoment(action: ReturnType<typeof postMomentAction>) {
  try {
    const { payload } = action;

    yield call([queries, queries.saveMomentInstance], payload);
    yield call(postMomentMetric, payload.id);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* removeChannel(action: ReturnType<typeof removeChannelAction>) {
  yield call(PubnubClient.unsubscribe, { channels: [action.payload.key] });
}

function* removeChannelGroup(action: ReturnType<typeof removeChannelGroupAction>) {
  yield call(PubnubClient.unsubscribe, { channelGroups: [action.payload.key] });
}

function* submitMomentAction(action: ReturnType<typeof submitMomentActionAction>) {
  try {
    const {
      payload: { momentInstanceId, momentActionType, momentType },
    } = action;
    const submissionData: MomentActionInput = {
      id: uuidv4(),
      momentInstanceId,
      actionType: momentActionType,
    };

    yield call([queries, queries.submitMomentAction], submissionData);
    yield call(momentSubmission, momentInstanceId, momentType);
    yield call(
      momentAction,
      submissionData.id,
      submissionData.momentInstanceId,
      submissionData.actionType
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* deleteMoment(action: ReturnType<typeof deleteMomentAction>) {
  try {
    const { momentInstanceId } = action.payload;
    yield put(deleteMomentAction(action.payload));
    yield call([queries, queries.deleteMomentInstance], { id: momentInstanceId });
    yield call(deleteMomentMetric, momentInstanceId);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* submitMessageAction(action: ReturnType<typeof submitMessageActionAction>) {
  try {
    const {
      payload: { messageId, channelKey, messageActionType },
    } = action;
    const channel: Channel = yield select(getChannelByKey, channelKey);
    if (channel) {
      const submissionData: MessageActionInput = {
        id: uuidv4(),
        messageId: messageId,
        channelId: channel.id,
        type: messageActionType,
      };

      yield call([queries, queries.submitMessageAction], submissionData);
      yield call(messageAction, submissionData.id, submissionData.messageId, submissionData.type);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export {
  addChannel,
  addChannelGroup,
  callListChannels,
  deleteMoment,
  handlePubnubErrors,
  loadHistory,
  onObject,
  onSignal,
  postMoment,
  publishLiveObject,
  publishReaction,
  removeChannel,
  removeChannelGroup,
  setChannelGroups,
  setChannels,
  setPubnubClient,
  submitMomentAction,
  submitMessageAction,
  subscribeToChannel,
  typingInChannel,
  unsubscribeFromChannel,
};
