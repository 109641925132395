import { connect } from 'react-redux';
import NavHeader from './navHeader';
import { RootState } from '@store/rootReducer';
import { getPublicChannelOccupancy } from '@store/feedSlice/channelSelectors';
import { isOffline } from '@store/serviceSlice/selectors';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';

interface StateProps {
  logo: string | null;
  headerBackgroundColor: string;
  headerTextColor: string;
  organizationName: string;
  channelOccupancy: number;
  hasLinks: boolean;
  isOffline?: boolean;
  isGuest: boolean;
  hideViewershipMetric: boolean;
}

interface OwnProps {
  largeUpGuest?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => ({
  logo: state.organization.appearance.logo || null,
  headerBackgroundColor: state.organization.appearance.headerBackgroundColor,
  headerTextColor: state.organization.appearance.headerTextColor,
  hideViewershipMetric: state.organization.appearance.hideViewershipMetric,
  organizationName: state.organization.name,
  channelOccupancy: getPublicChannelOccupancy(state),
  hasLinks: state.organization.links.length > 0,
  isOffline: isOffline(state),
  isGuest: !isHostViewingAsHost(state),
  ...ownProps,
});

const VisibleNavHeader = connect(mapStateToProps)(NavHeader);

export type Props = StateProps & OwnProps;
export default VisibleNavHeader;
