import styled from 'styled-components';
import { LandscapeMobile } from '@utils/responsive';
import { getInset } from '@lifechurch/react-ion';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.overlay50};
  gap: 8px;
  padding: 3.5px 8px;
  position: absolute;
  ${getInset('insetBlockStart', '4px')};
  ${getInset('insetInlineEnd', '4px')};
  z-index: 1;
  height: 24px;
  border-radius: 2px;
  pointer-events: none;

  @media ${LandscapeMobile} {
    display: none;
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13.44px;
  min-width: 30px;
  span {
    color: ${props => props.theme.colors.white};
    text-align: start;
    min-width: 34px;
  }

  svg {
    margin-inline-end: 2px;
    path,
    &:hover path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const ViewerCount = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  font-size: 13.44px;

  svg {
    margin-inline-end: 2px;
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export { Wrapper, CountdownWrapper, ViewerCount };
