import { setSubscriber } from '@store/subscriberSlice';

export function setSegmentUser(action: ReturnType<typeof setSubscriber>): void {
  const { payload: currentSubscriber } = action;

  window.analytics?.identify({
    subscriber_id: currentSubscriber.id,
    email: currentSubscriber.email,
  });
}
