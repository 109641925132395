import React, { FC } from 'react';
import { defaultColors, OParagraph } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';
import { Wrapper } from './styles';
import Display from '@components/DateTime';
import Imgix from 'react-imgix';
import TabHeader from '../../../paneHeader/tabHeader';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { IMAGE_PREFIX } from '../../../../../constants';

dayjs.extend(isToday);

interface Props {
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
}

const Offline: FC<Props> = ({ nextService }) => {
  const { t } = useTranslation();
  const isToday = nextService?.startTime && dayjs(nextService?.startTime).isToday();

  const isChatEnabled = nextService?.content?.features?.publicChat;
  const title = t(isChatEnabled ? 'public_chat' : 'feed').toUpperCase();

  if (!nextService?.id) {
    return (
      <>
        <TabHeader title={title} />
        <Wrapper data-testid='offline-chat-no-service'>
          <Imgix
            src={
              isChatEnabled
                ? `${IMAGE_PREFIX}/static/placeholders/chat_bubbles.png`
                : `${IMAGE_PREFIX}/static/placeholders/feed.png`
            }
            width={160}
            height={160}
          />
        </Wrapper>
      </>
    );
  }

  if (isToday) {
    return (
      <>
        <TabHeader title={title} />
        <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE} altComponentName={title}>
          <Wrapper data-testid='offline-chat'>
            {isChatEnabled && (
              <>
                <Imgix
                  src={`${IMAGE_PREFIX}/static/host-tools/chat_bubbles.png`}
                  width={160}
                  height={160}
                />
                <OParagraph color={defaultColors.gray50}>
                  {t('public_chat_offline_chat')}{' '}
                  <Display value={nextService?.startTime} format='LT' />
                </OParagraph>
              </>
            )}
            {!isChatEnabled && (
              <>
                <Imgix
                  src={`${IMAGE_PREFIX}/static/host-tools/feed_boxes.png`}
                  width={160}
                  height={160}
                />
                <OParagraph color={defaultColors.gray50}>
                  {t('public_chat_offline_feed')}{' '}
                  <Display value={nextService?.startTime} format='LT' />
                </OParagraph>
              </>
            )}
          </Wrapper>
        </ErrorBoundary>
      </>
    );
  }

  return (
    <>
      <TabHeader title={title} />
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE} altComponentName={title}>
        <Wrapper data-testid='offline-chat'>
          <Imgix
            src={`${IMAGE_PREFIX}/static/host-tools/chat_bubbles.png`}
            width={160}
            height={160}
          />
        </Wrapper>
      </ErrorBoundary>
    </>
  );
};

export default Offline;
