import React from 'react';
import Imgix from 'react-imgix';
import AvatarOverlay from './avatarOverlay';
const ImageAvatar = ({ disabled, isActionable, nickname, size, tabIndex, url, }) => (React.createElement(React.Fragment, null,
    isActionable && React.createElement(AvatarOverlay, { size: size }),
    React.createElement(Imgix, { htmlAttributes: {
            'data-testid': `avatar-${url}`,
            tabIndex: tabIndex,
            role: 'img',
            'aria-label': nickname,
            style: {
                opacity: disabled ? 0.5 : 1,
                flexShrink: 0,
            },
        }, imgixParams: {
            auto: 'format',
            mask: 'ellipse',
            fit: 'crop',
            crop: 'faces,entropy',
            w: size,
            h: size,
            sat: disabled && -100,
        }, width: size, height: size, src: url })));
export default React.memo(ImageAvatar);
