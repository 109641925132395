import styled, { css } from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

interface BackgroundProps {
  showReactions?: boolean;
  chatIsMultiline?: boolean;
}

const getInputWidth = (showReactions = false) => {
  if (!showReactions) {
    return '100%';
  }
  return 'calc(100% - 144px)';
};

const Background = styled.div<BackgroundProps>`
  width: ${props => getInputWidth(props.showReactions)};
  &:focus-within {
    width: 100%;
    transition: width ${props => (props.chatIsMultiline ? '100ms' : '250ms')} ease-out;
  }
  flex-shrink: 0;
`;

interface IconButtonProps {
  hidden: boolean;
  grayed: boolean;
}

const IconButton = styled.button<IconButtonProps>`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  border-radius: 24px;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background: ${props =>
    props.grayed ? props.theme.colors.gray10 : props.theme.colors.action.default};
  min-width: 32px;
  height: 32px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: ${props => (props.grayed ? props.theme.colors.gray10 : '#4facf4')};
  }
`;

interface WrapperProps {
  focused?: boolean;
  isiOSSafariVersion15: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 2px;
  padding-inline-end: 3px;
  border-radius: 20px;
  position: relative;
  display: flex;
  touch-action: none;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.gray5};
  border: 1px solid transparent;
  ${props =>
    props.focused &&
    css`
      border: 1px solid ${props => props.theme.colors.action.hover};
      background-color: rgb(255, 255, 255);
    `}
  ${props =>
    props.focused &&
    props.isiOSSafariVersion15 &&
    css`
      margin-block-end: 50px;
    `}
  flex-shrink: 0;
`;

interface TypingIndicatorProps {
  isCompact: boolean;
}

const TypingIndicator = styled.p<TypingIndicatorProps>`
  line-height: 1.25;
  overflow-wrap: break-word;
  word-break: break-word;
  display: inline;
  font-size: 10.72px;
  color: ${(props): string => props.theme.colors.gray50};
  padding-inline-start: ${(props): string => (props.isCompact ? '40px' : '52px')};

  svg {
    display: inline-block;
    vertical-align: initial;
  }
`;

interface InputProps {
  height: number;
}

export const MIN_CHAT_INPUT_HEIGHT = 38;
export const MAX_CHAT_INPUT_HEIGHT = 126;

const Input = styled.textarea<InputProps>`
  border: none;
  color: ${props => props.theme.colors.gray100};
  min-height: ${MIN_CHAT_INPUT_HEIGHT}px;
  max-height: ${MAX_CHAT_INPUT_HEIGHT}px;
  height: ${props => props.height}px;
  transition: height 100ms ease-in-out;
  width: 100%;
  flex: 1;
  background-color: transparent;
  outline: none;
  padding: 10px 8px;
  line-height: 1.25;
  -webkit-tap-highlight-color: transparent;
  font-size: ${props => props.theme.fontSizes[3]};
  resize: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.colors.gray50};
  }
`;

const ActionableWrapper = styled.div`
  position: absolute;
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineEnd', 0)};
  margin-block-start: 0;
  margin-block-end: 3px;
  margin-inline-start: 0;
  margin-inline-end: 3px;
`;

const ActionableColumn = styled.div`
  width: 32px;
`;

export {
  Background,
  Wrapper,
  IconButton,
  TypingIndicator,
  ActionableWrapper,
  ActionableColumn,
  Input,
};
