import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Display from '@components/DateTime';
import { Container, NextServiceHeader, NextServiceTime, NextServiceTitle, Title } from './styles';
import { Alignment, Props } from './index';
import { Flex } from '@lifechurch/react-ion';
import styled from 'styled-components';
import ResizeButton from './resizeButton';
import { MediumPlusUpQuery } from '@utils/responsive';
import { useMediaQuery } from 'react-responsive';

const StyledFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const ServiceMeta: FC<Props> = ({
  alignment = Alignment.START,
  expandHostVideo,
  hasMedia,
  isGuest,
  isLargeHostVideo,
  isOnline,
  nextService,
  sendResizeHostVideoMetric,
  shrinkHostVideo,
  subscriberId,
  timezone,
  title,
}) => {
  const { t } = useTranslation();
  const isToday = nextService?.startTime && dayjs().isSame(nextService?.startTime, 'day');
  const isDuringService =
    (dayjs().isBefore(nextService?.endTime) && dayjs().isAfter(nextService?.scheduleTime)) ||
    isOnline;
  const mediumPlusUp = useMediaQuery({ query: MediumPlusUpQuery });

  // ONLINE - after schedule start time
  if (isDuringService) {
    return (
      <>
        <Title isGuest={isGuest} data-testid='videoTitle'>
          {title}
        </Title>
        {mediumPlusUp && !isGuest && (
          <ResizeButton
            expandHostVideo={expandHostVideo}
            isLargeHostVideo={isLargeHostVideo}
            sendResizeHostVideoMetric={sendResizeHostVideoMetric}
            shrinkHostVideo={shrinkHostVideo}
            subscriberId={subscriberId}
          />
        )}
      </>
    );
  }

  // OFFLINE & ONLINE - until schedule start time of a service
  return (
    <Container hasMedia={hasMedia} alignment={alignment} tabIndex={0}>
      <StyledFlex>
        <NextServiceHeader hasMedia={hasMedia}>{t('next_service')}</NextServiceHeader>
        {mediumPlusUp && hasMedia && !isGuest && (
          <ResizeButton
            expandHostVideo={expandHostVideo}
            isLargeHostVideo={isLargeHostVideo}
            sendResizeHostVideoMetric={sendResizeHostVideoMetric}
            shrinkHostVideo={shrinkHostVideo}
            subscriberId={subscriberId}
          />
        )}
      </StyledFlex>
      <NextServiceTitle isGuest={isGuest} hasMedia={hasMedia} alignment={alignment}>
        {nextService.content?.title}
      </NextServiceTitle>
      <NextServiceTime isGuest={isGuest} hasMedia={hasMedia}>
        {nextService.scheduleTime && (
          <Display
            value={nextService.scheduleTime}
            format={isToday ? `[${t('time.today')}], LT` : 'dddd, MMMM D, LT'}
            timezone={timezone}
          />
        )}
      </NextServiceTime>
    </Container>
  );
};

export default ServiceMeta;
