import React from 'react';
import { Avatar, Subscriber } from '../Avatar/Avatar';
import { AvatarSizeType } from '../Avatar/types';
import { AvatarContainer } from './AvatarContainer';
import { Container } from './Container';

type Props = { subscribers: Subscriber[] };

function FourHostLayout({ subscribers }: Props) {
  return (
    <Container height='60px' width='150px'>
      <AvatarContainer top='4px' left='0px' size={AvatarSizeType.XSMALL}>
        <Avatar size={AvatarSizeType.XSMALL} subscriber={subscribers[0]} />
      </AvatarContainer>

      <AvatarContainer top='28px' left='15px' size={AvatarSizeType.SMALL}>
        <Avatar size={AvatarSizeType.SMALL} subscriber={subscribers[1]} />
      </AvatarContainer>

      <AvatarContainer top='0px' left='50px' size={AvatarSizeType.LARGE}>
        <Avatar size={AvatarSizeType.LARGE} subscriber={subscribers[2]} />
      </AvatarContainer>

      <AvatarContainer top='16px' left='110px' size={AvatarSizeType.MEDIUM}>
        <Avatar size={AvatarSizeType.MEDIUM} subscriber={subscribers[3]} />
      </AvatarContainer>
    </Container>
  );
}

export { FourHostLayout };
