import React, { FunctionComponent } from 'react';
import { Props, State } from './index';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Input,
  Modal,
  ModalButtons,
  ModalSize,
  OHeaderThree,
  PrimaryButton,
  Textarea,
} from '@lifechurch/react-ion';
import { FormikProps } from 'formik';

const RequestPrayer: FunctionComponent<FormikProps<State> & Props> = props => {
  const { handleSubmit, handleChange, values, errors } = props;
  const { t } = useTranslation('forms');

  const handleClose = () => {
    const { cancelRequestPrayer, closeModal } = props;
    cancelRequestPrayer();
    closeModal();
  };

  return (
    <Modal
      dismiss={handleClose}
      size={ModalSize.NARROW}
      testId='requestPrayer-modal'
      dismissTestId={'modal-dismiss'}
    >
      <OHeaderThree marginBlockEnd={5}>{t('request_prayer.title')}</OHeaderThree>
      <form onSubmit={handleSubmit}>
        <Box marginBlockEnd={5}>
          <Input
            name='name'
            type='text'
            label={t('request_prayer.name')}
            onChange={handleChange}
            value={values.name}
            error={!!errors.name}
            hint={errors.name && t(errors.name)}
            required
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            name='email'
            type='email'
            label={t('request_prayer.email')}
            onChange={handleChange}
            value={values.email}
            error={!!errors.email}
            hint={errors.email && t(errors.email)}
            required
          />
        </Box>
        <Box marginBlockEnd={6}>
          <Textarea
            name='message'
            label={t('request_prayer.message')}
            onChange={handleChange}
            value={values.message}
            required
            rows={5}
            error={!!errors.message}
            hint={errors.message && t(errors.message)}
          />
        </Box>
        <ModalButtons>
          <PrimaryButton onClick={handleSubmit} type='submit'>
            {t('request_prayer.submit')}
          </PrimaryButton>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default RequestPrayer;
