import { ModalType } from '../dux';

export interface ProfileSettings {
  type: ModalType.PROFILE_SETTINGS;
  meta?: ProfileSettingsMeta;
}

export interface ProfileSettingsMeta {
  nicknameUnavailable?: boolean;
}

export const profileSettings = (meta?: ProfileSettingsMeta): ProfileSettings => ({
  type: ModalType.PROFILE_SETTINGS,
  meta,
});
