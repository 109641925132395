import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Merge } from '../../types';
import {
  CurrentState_currentService_sequence,
  CurrentState_currentService_sequence_steps,
} from '@io/__generated__/CurrentState';
import {
  ServiceAtQuery_serviceAt,
  ServiceAtQuery_serviceAt_content,
} from '@io/__generated__/ServiceAtQuery';

type ContentWithoutVideo = Omit<ServiceAtQuery_serviceAt_content, 'video'>;
export type StepData = Merge<
  Omit<ServiceAtQuery_serviceAt, 'sequence' | 'feed'>,
  { content: ContentWithoutVideo | null }
>;

export interface StepWithData extends CurrentState_currentService_sequence_steps {
  data?: StepData;
}

type InitialState = Merge<CurrentState_currentService_sequence, { steps: Array<StepWithData> }>;

export const initialState: InitialState = {
  serverTime: 0,
  steps: [],
};

const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    setSequence(_state, action: PayloadAction<InitialState>) {
      return action.payload;
    },
    popStep(state) {
      state.steps = state.steps.slice(1);
    },
    setStepData(state, action: PayloadAction<StepData>) {
      if (state.steps[0]) {
        state.steps[0].data = action.payload;
      }
    },
  },
});

export const startSequence = createAction('sequence/startSequence');

export const { setSequence, popStep, setStepData } = sequenceSlice.actions;
export type SequenceState = ReturnType<typeof sequenceSlice.reducer>;
export default sequenceSlice.reducer;
