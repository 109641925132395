import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { CI_COMMIT_SHA, ENV } from '../constants';
import { store } from '@store/store';
import { setLanguage } from '@store/uiSlice';

i18n
  .use(I18NextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      ns: ['common', 'forms', 'moments', 'notifications', 'links', 'locales', 'roleIdentifiers'],
      defaultNS: 'common',
      load: 'currentOnly',
      fallbackLng: {
        af: ['af-ZA', 'en-US'],
        be: ['be-BY', 'en-US'],
        bg: ['bg-BG', 'en-US'],
        bn: ['bn-BD', 'en-US'],
        am: ['am-ET', 'en-US'],
        ar: ['ar-SA', 'en-US'],
        ca: ['ca-ES', 'ca-ES'],
        cs: ['cs-CZ', 'en-US'],
        cy: ['cy-GB', 'en-US'],
        da: ['da-DK', 'en-US'],
        de: ['de-DE', 'en-US'],
        el: ['el-GR', 'en-US'],
        en: ['en-US'],
        'en-GB': ['en-GB', 'en-US'],
        es: ['es-LA', 'en-US'],
        ess: ['es-ES', 'en-US'],
        fa: ['fa-IR', 'en-US'],
        fi: ['fi-FI', 'en-US'],
        fil: ['fil-PH', 'en-US'],
        fr: ['fr-FR', 'en-US'],
        he: ['he-IL', 'en-US'],
        hi: ['hi-IN', 'en-US'],
        hr: ['hr-HR', 'en-US'],
        ht: ['ht-HT', 'en-US'],
        hu: ['hu-HU', 'en-US'],
        id: ['id-ID', 'en-US'],
        ig: ['ig-NG', 'en-US'],
        is: ['is-IS', 'en-US'],
        it: ['it-IT', 'en-US'],
        ja: ['ja-JP', 'en-US'],
        km: ['km-KH', 'en-US'],
        kn: ['kn-IN', 'en-US'],
        ko: ['ko-KR', 'en-US'],
        lt: ['lt-LT', 'en-US'],
        lv: ['lv-LV', 'en-US'],
        mk: ['mk-MK', 'en-US'],
        ml: ['ml-IN', 'en-us'],
        mn: ['mn-MN', 'en-US'],
        ms: ['ms-MY', 'en-US'],
        myz: ['my-QZ', 'en-US'],
        my: ['my-MM', 'en-US'],
        ni: ['nl-NL', 'en-US'],
        nb: ['no-NO', 'en-US'],
        pl: ['pl-PL', 'en-US'],
        pt: ['pt-BR', 'en-US'],
        pp: ['pt-PT', 'en-US'],
        ro: ['ro-RO', 'en-US'],
        ru: ['ru-RU', 'en-US'],
        sk: ['sk-SK', 'en-US'],
        sl: ['sl-SL', 'en-US'],
        sn: ['sn-ZW', 'en-US'],
        sq: ['sq-AL', 'en-us'],
        sv: ['sv-SE', 'en-US'],
        sw: ['sw-KE', 'en-US'],
        ta: ['ta-IN', 'en-US'],
        th: ['th-TH', 'en-US'],
        tr: ['tr-TR', 'en-US'],
        uk: ['uk-UA', 'en-US'],
        ur: ['ur-PK', 'en-US'],
        vi: ['vi-VN', 'en-US'],
        yo: ['yo-NG', 'en-US'],
        zhcn: ['zh-CN', 'en-US'],
        zhhk: ['zh-HK', 'en-US'],
        zhtw: ['zh-TW', 'en-US'],
        zu: ['zu-ZA', 'en-US'],
        default: ['en-US'],
      },
      debug: false, // Clutters console; set this to true if you need debugging information
      interpolation: {
        escapeValue: false, // React already escapes by default
      },
      backend: {
        loadPath: `${ENV === 'development' ? '' : '/locales'}/{{lng}}/{{ns}}.json`,
        crossDomain: true,
        queryStringParams: { v: CI_COMMIT_SHA as string },
      },
    },
    () => store.dispatch(setLanguage({ newLanguage: i18n.language }))
  );

export { i18n };
