import React from 'react';
import CTAMoment from '../cta';
import { ButtonSize, CommentIcon, defaultColors, Flex, IconSize, OParagraph, PrimaryButton, SecondaryButton, } from '@lifechurch/react-ion';
const PrayerMoment = ({ buttonText, isPinned = false, onClick, onDismiss, tabIndex = 0, text, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(CTAMoment, { onDismiss: onDismiss, isPinned: isPinned, fuse: isPinned, ariaLabel: text },
            React.createElement(Flex, { flexDirection: 'column', width: '100%', alignItems: 'center' },
                React.createElement(OParagraph, { marginBlockEnd: 3, textAlign: 'center', p: '0 16px' }, text),
                isPinned ? (React.createElement(PrimaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick, tabIndex: tabIndex },
                    React.createElement(Flex, { marginInlineEnd: 1 },
                        React.createElement(CommentIcon, { size: IconSize.SM, color: defaultColors.white })),
                    buttonText)) : (React.createElement(SecondaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick, tabIndex: tabIndex },
                    React.createElement(Flex, { marginInlineEnd: 1 },
                        React.createElement(CommentIcon, { size: IconSize.SM, color: defaultColors.action.default })),
                    buttonText))))));
};
export { PrayerMoment };
