import React, { FunctionComponent } from 'react';
import { ViewerCount, Wrapper } from './styles';
import { Props } from './index';
import Countdown from './countdown';
import { EyeIcon } from '@lifechurch/react-ion';

const MediaHeader: FunctionComponent<Props> = ({
  endTime,
  channelOccupancy,
  isGuest,
  hideViewershipMetric,
  isOffline,
}) => {
  // Don't render a header if there's not a service running.
  if (isOffline) {
    return null;
  }

  // Only show the countdown if the user is not a guest.
  const shouldShowCountdown = endTime && !isGuest;

  // Disable the viewership metric if:
  // - the channel occupancy is 0.
  // OR both:
  // - the user is a guest
  // - the hideViewershipMetric option is set for the organization
  const shouldShowViewershipMetric = channelOccupancy !== 0 && !(isGuest && hideViewershipMetric);

  const shouldShowMediaHeader = shouldShowCountdown || shouldShowViewershipMetric;

  return (
    <>
      {shouldShowMediaHeader && (
        <Wrapper data-testid='mediaHeader'>
          {shouldShowCountdown && <Countdown date={endTime as string} />}

          {shouldShowViewershipMetric && (
            <ViewerCount data-testid='mediaHeader-occupancy'>
              <EyeIcon />
              {channelOccupancy}
            </ViewerCount>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default React.memo<Props>(MediaHeader);
