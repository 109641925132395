import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import Video from '../video';
import { Props } from './index';
import { IMAGE_PREFIX } from '../../constants';
import MediaHeader from './mediaHeader';
import { Image, ImageContainer, ImageWrapper } from './styles';

const Media: FunctionComponent<Props> = ({ url, isOffline, offlineContent, nextService }) => {
  const isDuringService =
    dayjs().isBefore(nextService?.endTime) && dayjs().isAfter(nextService?.scheduleTime);

  // @ts-expect-error
  if (!isOffline && !url?.startsWith(IMAGE_PREFIX)) {
    return <Video url={url} />;
  }

  if (offlineContent?.__typename === 'OfflineVideo') {
    return <Video url={offlineContent.url} />;
  }

  if (offlineContent?.__typename === 'OfflineImage' && !!offlineContent?.url) {
    return (
      <ImageWrapper>
        <ImageContainer isDuringService={isDuringService}>
          <MediaHeader />
          <Image data-testid='offlineImage' url={offlineContent?.url} />
        </ImageContainer>
      </ImageWrapper>
    );
  }

  return null;
};

export default React.memo<Props>(Media);
