import { createSelector } from 'reselect';
import { RootState } from '@store/rootReducer';
import { OrganizationState } from './';
import { CurrentState_currentOrganization_integrations } from '@io/__generated__/CurrentState';
import { IntegrationType } from '../../../__generated__/globalTypes';

const ID = 'organization';
const local = (state: RootState) => state[ID];

export const getCurrentOrganization = createSelector<
  RootState,
  OrganizationState,
  OrganizationState
>(local, local => local);

export const getIntegrations = createSelector<
  RootState,
  OrganizationState,
  Array<CurrentState_currentOrganization_integrations>
>(local, local => local.integrations);

export const getIntegrationTypes = createSelector<
  RootState,
  OrganizationState,
  Array<IntegrationType>
>(local, local =>
  local.integrations.length > 0
    ? [...new Set(local.integrations.map(integration => integration.type))]
    : []
);
