import { PublicChannel } from '../../../../types';
import {
  MomentTriggerType,
  MomentType,
  SalvationMomentFollowUpType,
  WindowType,
} from '../../../../../__generated__/globalTypes';
import { SalvationMomentLabels, SymbolProps } from '@churchonline/moments';
import { LiveObjectType, MomentLiveObject } from '../../../../pubnub/liveObject';

interface BaseMoment {
  id: string;
  channel: PublicChannel;
  postTime: string;
  trigger: MomentTriggerType;
  likes: number;
  count: number;
  deleted?: boolean;
  canceled?: boolean;
}

export interface ConnectMoment extends BaseMoment {
  type: MomentType.CONNECT;
  momentTemplate: {
    type: MomentType.CONNECT;
    options: {
      url: string;
      text: string;
      windowType: WindowType;
    };
  };
}

export interface GivingMoment extends BaseMoment {
  type: MomentType.GIVING;
  momentTemplate: {
    type: MomentType.GIVING;
    options: {
      src: string;
      text: string;
      windowType: WindowType;
    };
  };
}

export interface ImageMoment extends BaseMoment {
  type: MomentType.IMAGE;
  momentTemplate: {
    type: MomentType.IMAGE;
    options: {
      altText: string;
      imageUrl: string;
      shareText: string;
      title: string;
    };
  };
}

export interface InviteMoment extends BaseMoment {
  type: MomentType.INVITE;
  momentTemplate: {
    type: MomentType.INVITE;
    options: {
      text: string;
      buttonText: string;
    };
  };
}

export interface PrayerMoment extends BaseMoment {
  type: MomentType.PRAYER;
  momentTemplate: {
    type: MomentType.PRAYER;
    options: {
      text: string;
      buttonText: string;
    };
  };
}

export interface QuoteMoment extends BaseMoment {
  type: MomentType.QUOTE;
  momentTemplate: {
    type: MomentType.QUOTE;
    options: {
      attribution: string | null;
      text: string;
    };
  };
}

export interface ResponseMoment extends BaseMoment {
  type: MomentType.RESPONSE;
  momentTemplate: {
    type: MomentType.RESPONSE;
    options: {
      text: string;
      buttonText: string;
      symbol: SymbolProps;
    };
  };
}

export interface SalvationMoment extends BaseMoment {
  type: MomentType.SALVATION;
  momentTemplate: {
    type: MomentType.SALVATION;
    options: {
      salvationMomentFollowUpType: SalvationMomentFollowUpType;
      url: string | null;
      labels: SalvationMomentLabels | null;
    };
  };
}

export interface SignupMoment extends BaseMoment {
  type: MomentType.SIGNUP;
  momentTemplate: {
    type: MomentType.SIGNUP;
    options: {
      url: string;
      text: string;
      buttonText: string;
    };
  };
}

export type Moment =
  | ConnectMoment
  | GivingMoment
  | ImageMoment
  | InviteMoment
  | PrayerMoment
  | QuoteMoment
  | ResponseMoment
  | SalvationMoment
  | SignupMoment;

export interface MomentSubmission {
  id: string;
  momentId: string;
  momentType: MomentType;
  organizationId: string;
  channel: PublicChannel;
}

export const newMomentLiveObject = (moment: Moment): MomentLiveObject => {
  return {
    type: LiveObjectType.MOMENT,
    id: moment.id,
    timestamp: moment.postTime,
    channel: moment.channel,
    data: moment,
  };
};
