import React, {
  FunctionComponent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Props } from './index';
import { useTranslation } from 'react-i18next';
import { AccessibileLink, LinksWrapper, ScreenReaderText } from './styles';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { CurrentState_currentOrganization_links } from '@io/__generated__/CurrentState';
import { defaultColors, Dropdown, DropdownItem } from '@lifechurch/react-ion';
import Ellipses from '@components/icons/ellipses';

const NavHeaderLinks: FunctionComponent<Props> = ({
  links,
  navigationLinkCase,
  headerTextColor,
}) => {
  const { t } = useTranslation('links');
  const [primaryLinks, setPrimaryLinks] = useState(links);
  const [moreLinks, setMoreLinks] = useState<Array<CurrentState_currentOrganization_links>>([]);
  const [linkWidths, setLinkWidths] = useState<Array<number>>([]);
  const [outerRef, outerWidth] = useResizeObserver<HTMLDivElement>();

  const doesItFit = () => {
    let sum = 200; // give some wiggle room
    let endIndex = 0;

    linkWidths.some((width, index) => {
      endIndex = index;
      if (sum + width > outerWidth) {
        return true;
      }
      sum += width;
    });

    const newPrimaryLinks = links.slice(0, endIndex + 1);
    setPrimaryLinks(newPrimaryLinks);

    let newMoreLinks: Array<CurrentState_currentOrganization_links> = [];
    if (newPrimaryLinks.length !== links.length) {
      newMoreLinks = links.slice(endIndex + 1);
    }
    setMoreLinks(newMoreLinks);
  };

  useLayoutEffect(() => {
    if (outerRef.current) {
      // link width + margin
      setLinkWidths(
        Array.from(outerRef.current.children).map(item => item.getBoundingClientRect().width + 24)
      );
    }
  }, []);

  useEffect(() => {
    doesItFit();
  }, [outerWidth, links]);

  const setAttributeAndFocus = (element: HTMLElement) => {
    element.setAttribute('tabIndex', '0');
    element.focus();
  };

  const handleFocus = useCallback(() => {
    outerRef.current?.focus();
  }, [outerRef]);

  const focusFirstChild = () => {
    if (outerRef.current) {
      const childElement = outerRef.current.children[0] as HTMLElement;
      setAttributeAndFocus(childElement);
    }
  };

  const onKeyDownHandler: KeyboardEventHandler<HTMLLinkElement> = e => {
    if (e.shiftKey && e.altKey && e.code === 'KeyE') {
      e.preventDefault();
      e.target === outerRef.current ? focusFirstChild() : handleFocus();
    } else {
      let element;
      const wrapperElement = outerRef.current as HTMLElement;
      const target = e.target as HTMLElement;
      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          if (target.nextSibling) {
            element = target.nextSibling as HTMLElement;
            target.setAttribute('tabIndex', '-1');
            setAttributeAndFocus(element);
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          if (target.previousSibling && target !== wrapperElement) {
            element = target.previousSibling as HTMLElement;
            target.setAttribute('tabIndex', '-1');
            setAttributeAndFocus(element);
          }
          break;
        case 'Tab':
          for (let i = 0; i < wrapperElement?.children.length; i++) {
            const childElement = wrapperElement.children[i] as HTMLElement;
            childElement.setAttribute('tabIndex', '-1');
          }
          break;
        default:
          return null;
      }
    }
  };

  return (
    <LinksWrapper
      aria-label={t('aria.external_links')}
      color={headerTextColor}
      data-testid='navHeaderLinks'
      linkTextCase={navigationLinkCase}
      onKeyDown={onKeyDownHandler}
      ref={outerRef}
      tabIndex={0}
    >
      {primaryLinks.map(link => (
        <AccessibileLink
          key={link.id}
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
          tabIndex={-1}
          data-testid='external-link'
        >
          {t(link.key, link.label)}
          <ScreenReaderText>{t('opens_in_new_tab')}</ScreenReaderText>
        </AccessibileLink>
      ))}
      {moreLinks.length > 0 && (
        <Dropdown
          data-testid='popper'
          popperContent={moreLinks.map(link => (
            <DropdownItem
              key={link.id}
              href={link.url}
              target='_blank'
              rel='noopener noreferrer'
              tabIndex={0}
            >
              {t(link.key, link.label)}
              <ScreenReaderText>{t('opens_in_new_tab')}</ScreenReaderText>
            </DropdownItem>
          ))}
          referenceContent={
            <div data-testid='moreLinks' aria-label={t('aria.more_links')}>
              <Ellipses
                className={
                  headerTextColor === defaultColors.gray100
                    ? 'text-gray-50 hover:text-gray-100'
                    : 'text-gray-50 hover:text-white'
                }
              />
            </div>
          }
          popperOptions={{
            placement: 'bottom-start',
            modifiers: [],
          }}
        />
      )}
    </LinksWrapper>
  );
};

export default React.memo(NavHeaderLinks);
