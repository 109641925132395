import styled, { css, keyframes } from 'styled-components';
import { Flex, getInset, TertiaryButton } from '@lifechurch/react-ion';
const fadeIn = keyframes `
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;
export const Modal = styled(Flex) `
  animation: ${fadeIn} 500ms ease-out;
  position: fixed;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  min-height: 100vh;
  z-index: 1000;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  padding-block-start: 48px;
  padding-block-end: 16px;
  padding-inline-start: 48px;
  padding-inline-end: 48px;
  flex-direction: column;
  justify-content: center;
`;
export const DismissButton = styled.button `
  position: absolute;
  background: none;
  border: none;
  outline: none;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  padding: 0;
  margin-block-start: 11px;
  margin-inline-end: 11px;
  z-index: 1001;

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.white};
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
`;
export const LikeCount = styled(Flex) `
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.2);
  color: ${props => (props.hasLiked ? props.theme.colors.white : undefined)};
  margin: ${props => (props.hasLiked ? '0' : '4px')};
`;
export const LikeButton = styled(TertiaryButton) `
  padding: 0;
  &:hover {
    color: ${props => props.theme.colors.action.default};
    svg path {
      fill: ${props => props.theme.colors.action.default};
    }
  }

  &:hover ${LikeCount} {
    background-color: ${props => props.theme.colors.action.light};
  }

  ${props => props.hasLiked &&
    css `
      pointer-events: none;
      border: none;
      ${LikeCount} {
        background-color: transparent;
      }
    `};
`;
export const HeartIconWrapper = styled(Flex) `
  padding: ${props => (props.hasLiked ? '0' : props.likeCount > 0 ? '0 12px' : '10px 16px')};
`;
export const MetaButtonWrapper = styled(Flex) `
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block-start: 8px;
`;
export const ImageWrapper = styled(Flex) `
  flex-direction: row;
  justify-content: center;
  overflow: hidden;

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
`;
export const DownloadButton = styled(TertiaryButton) `
  text-decoration: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 8px;
  margin-inline-end: 0;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  padding-block-start: 10px;
  padding-block-end: 11px;
  border-radius: 999px;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
`;
