import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const MagnifyingGlassIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M12 8.5C12 10.433 10.433 12 8.5 12C6.567 12 5 10.433 5 8.5C5 6.567 6.567 5 8.5 5C10.433 5 12 6.567 12 8.5ZM11.6175 13.0317C10.7315 13.6424 9.6575 14 8.5 14C5.46243 14 3 11.5376 3 8.5C3 5.46243 5.46243 3 8.5 3C11.5376 3 14 5.46243 14 8.5C14 9.6575 13.6424 10.7315 13.0317 11.6175L17.2071 15.7929L15.7929 17.2071L11.6175 13.0317Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5ZM15.0491 16.4633C13.7873 17.4274 12.2105 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.2105 17.4274 13.7873 16.4633 15.0491L21.2071 19.7929L19.7929 21.2071L15.0491 16.4633Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
