import styled, { css, keyframes } from 'styled-components';
const expand = keyframes `
  0% {  height: 0px; width: 0px; opacity: 1 };
  40% { opacity: 1 };
  100% { height: 600px; width: 600px; opacity: 0};
`;
const rise = keyframes `
  0% { opacity: 1 };
  53% { opacity: 1 };
  100% { opacity: 0; transform: translate(-50%, -1500%)};
`;
export const AnimatedCircle = styled.div `
  animation: ${props => props.animating
    ? css `
          ${expand} 1.25s linear
        `
    : undefined};
  position: absolute;
  top: ${props => props.animatedCircleCoords.y}px;
  left: ${props => props.animatedCircleCoords.x}px;
  border-radius: 1000px;
  background-color: ${props => props.theme.colors.action.default};
  transform: translate(-50%, -50%);
  z-index: 999;
`;
export const AnimatedRaisedHandContainer = styled.div `
  animation: ${props => props.animating
    ? css `
          ${rise} 1.5s linear
        `
    : undefined};
  position: ${props => (props.animating ? 'fixed' : 'absolute')};
  top: ${props => props.animatedHandCoords.y}px;
  left: ${props => props.animatedHandCoords.x}px;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: ${props => (props.animating ? 999 : -1)};
`;
