import React, { FunctionComponent, useEffect } from 'react';
import Chat from '../chat';
import Tab from '../tab';
import { PaneType } from '../../dux';
import { Props } from './index';
import OfflineChat from '../chat/offline';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import Reaction from '../../../reactions/reaction';
import SelfReaction from '../../../reactions/SelfReaction';

const Event: FunctionComponent<Props> = ({
  isOffline,
  channelKey,
  isGuest,
  reactions,
  subscriber,
  guestMediumUp = false,
  pane,
  paneId,
  sendPaneViewMetricEvent,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (pane) sendPaneViewMetricEvent(paneId ? paneId : 1, pane.type, channelKey);
  }, [pane]);

  const offline = isOffline;

  if (offline) {
    return isGuest ? (
      <Tab type={PaneType.SCHEDULE} guestMediumUp={guestMediumUp} isGuest={isGuest} />
    ) : (
      <OfflineChat />
    );
  } else {
    return (
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE} altComponentName={t('chat')}>
        <Chat channelKey={channelKey} guestMediumUp={guestMediumUp} isGuest={isGuest} />
        <div style={{ position: 'relative', width: '100%' }}>
          <Wrapper>
            {reactions.map(reaction =>
              reaction.subscriberId === subscriber.id ? (
                <SelfReaction key={reaction.id} reaction={reaction} />
              ) : (
                <Reaction key={reaction.id} reaction={reaction} />
              )
            )}
          </Wrapper>
        </div>
      </ErrorBoundary>
    );
  }
};

export default React.memo<Props>(Event);
