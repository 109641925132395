import { all, takeEvery, takeLatest, takeLeading, throttle } from 'redux-saga/effects';
import { queryCurrentService } from '@store/serviceSlice';
import {
  ACCEPT_INVITE_TO_CHANNEL,
  JOIN_CHANNEL,
  LEAVE_CHANNEL,
  requestChannel as requestChannelAction,
  REQUEST_INVITE_TO_CHANNEL,
  REQUEST_PLACEHOLDER_CHANNEL,
  TYPING_IN_CHANNEL,
  acceptLivePrayer as acceptLivePrayerAction,
  addChannel as addChannelAction,
  addChannelGroup as addChannelGroupAction,
  publishReaction as publishReactionAction,
  removeChannel as removeChannelAction,
  removeChannelGroup as removeChannelGroupAction,
  requestLivePrayer as requestLivePrayerAction,
  setChannelGroups as setChannelGroupsAction,
  setChannels as setChannelsAction,
  submitMomentAction as submitMomentActionAction,
  submitMessageAction as submitMessageActionAction,
} from '@store/feedSlice';
import { basicAuthLogin, handleExternalAuth, loginSuccess } from '@store/subscriberSlice';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { currentService } from './sagas/currentService';
import { basicAuth, checkAuth, guestAuth, handleAuth, init, logout } from './sagas/auth';
import {
  acceptInviteToChannel,
  acceptLivePrayer,
  joinChannel,
  leaveChannel,
  requestChannel,
  requestInviteToChannel,
  requestLivePrayer,
  requestPlaceholderChannel,
} from './sagas/directChat';
import { deleteMessage, muteSubscriber, unmuteSubscriber } from './sagas/moderation';
import {
  deleteSelf,
  requestPasswordReset,
  revertSubscriber,
  saveSubscriber,
  signUpCancel,
  signUpError,
  updateGuestNickname,
  uploadAvatar,
} from './sagas/subscriber';
import {
  addChannel,
  addChannelGroup,
  handlePubnubErrors,
  loadHistory,
  onObject,
  onSignal,
  postMoment,
  publishLiveObject,
  publishReaction,
  removeChannel,
  removeChannelGroup,
  setChannelGroups,
  setChannels,
  setPubnubClient,
  submitMomentAction,
  submitMessageAction,
  typingInChannel,
  deleteMoment,
} from './sagas/pubnub';
import {
  deleteSelf as deleteSelfAction,
  muteSubscriber as muteSubscriberAction,
  publishRequestPasswordReset as publishRequestPasswordResetAction,
  saveSubscriber as saveSubscriberAction,
  setSubscriber as setSubscriberAction,
  unmuteSubscriber as unmuteSubscriberAction,
  updateGuestNickname as updateGuestNicknameAction,
  uploadAvatar as uploadAvatarAction,
  revertSubscriber as revertSubscriberAction,
} from '@store/subscriberSlice';
import { DELETE_MESSAGE } from '@features/feed/objects/message/dux';
import { LOAD_HISTORY, ON_OBJECT, ON_SIGNAL, PUBNUB_PUBLISH_FAILED } from '../pubnub/dux';
import { publishLiveObject as publishLiveObjectAction } from '../pubnub/liveObject';
import {
  postMoment as postMomentAction,
  salvationMomentConnect as salvationMomentConnectAction,
  salvationMomentFollowUpDismiss as salvationMomentFollowUpDismissAction,
} from '@features/moments/dux';
import {
  signUpCancel as signUpCancelAction,
  signUpError as signUpErrorAction,
  signUpSuccess as signUpSuccessAction,
} from '@components/modal/signup/dux';
import { startSequence } from './sagas/sequence';
import { startSequence as startSequenceAction } from '@store/sequenceSlice';
import { CANCEL_REQUEST_PRAYER, PUBLISH_REQUEST_PRAYER } from '@components/modal/requestPrayer/dux';
import { requestPrayer } from './sagas/requestPrayer';
import {
  seeOriginalTranslation as seeOriginalTranslationMetric,
  enterFullscreen as enterFullscreenMetric,
  offlinePrayerRequest as offlinePrayerRequestMetric,
  salvationMomentConnect as salvationMomentConnectMetric,
  salvationMomentFollowUpDismiss as salvationMomentFollowUpDismissMetric,
  sendWebVitalMetric,
  sendPaneViewMetric,
  sendNotesEditedMetric,
  sendNotesExportedMetric,
  sendResizeHostVideoMetric,
} from './sagas/metrics';
import { sendIntegrationsEvent, setIntegrationsUser } from './sagas/integrations';
import {
  heartbeat,
  setLanguage as SetLanguageAction,
  sendResizeHostVideoMetric as sendResizeHostVideoMetricAction,
  sendPaneViewMetric as sendPaneViewMetricAction,
  sendSeeOriginalTranslationMetric,
  sendWebVitalMetric as sendWebVitalMetricAction,
} from '@store/uiSlice';
import {
  sendNotesEditedMetric as sendNotesEditedMetricAction,
  sendNotesExportedMetric as sendNotesExportedMetricAction,
} from '@features/notes/actions';
import { setLanguage } from './sagas/translation';
import { SagaIterator } from '@redux-saga/core';
import { publishChangePassword } from '@components/modal/changePassword/dux';
import { changePassword } from './sagas/changePassword';
import { TOGGLE_MOMENTS_AUTOPOST } from '@features/pane/types/tab/dux';
import { toggleMomentsAutopost } from './sagas/momentsAutopost';
import { resetApp } from '@store/rootReducer';
import { sendFullscreenMetricsEvent } from '@store/videoSlice';
import { deleteMomentRequest } from '@components/modal/deleteMoment/dux';

const takeEventsForIntegrations = [
  heartbeat.type,
  submitMomentActionAction.type,
  submitMessageActionAction.type,
  requestLivePrayerAction.type,
  publishLiveObjectAction.type,
  signUpSuccessAction.type,
  loginSuccess.type,
];

function* rootSaga(): SagaIterator {
  yield all([
    takeLeading('*', setPubnubClient),
    takeEvery(sendSeeOriginalTranslationMetric.type, seeOriginalTranslationMetric),
    takeEvery(ACCEPT_INVITE_TO_CHANNEL, acceptInviteToChannel),
    takeEvery(acceptLivePrayerAction.type, acceptLivePrayer),
    takeEvery(addChannelAction.type, addChannel),
    takeEvery(addChannelGroupAction.type, addChannelGroup),
    takeEvery(basicAuthLogin.type, basicAuth),
    takeEvery(CANCEL_REQUEST_PRAYER, offlinePrayerRequestMetric, null, true),
    takeEvery(DELETE_MESSAGE, deleteMessage),
    takeEvery(sendFullscreenMetricsEvent.type, enterFullscreenMetric),
    takeEvery(deleteSelfAction.type, deleteSelf),
    takeEvery(handleExternalAuth.type, handleAuth),
    takeEvery(JOIN_CHANNEL, joinChannel),
    takeEvery(LOAD_HISTORY, loadHistory),
    takeEvery(LEAVE_CHANNEL, leaveChannel),
    takeEvery(muteSubscriberAction.type, muteSubscriber),
    takeEvery(unmuteSubscriberAction.type, unmuteSubscriber),
    takeEvery(ON_OBJECT, onObject),
    takeEvery(ON_SIGNAL, onSignal),
    takeEvery(postMomentAction.type, postMoment),
    takeEvery(publishLiveObjectAction.type, publishLiveObject),
    takeEvery(PUBNUB_PUBLISH_FAILED, handlePubnubErrors),
    takeEvery(publishRequestPasswordResetAction.type, requestPasswordReset),
    takeEvery(PUBLISH_REQUEST_PRAYER, requestPrayer),
    takeEvery(publishChangePassword.type, changePassword),
    takeEvery(publishReactionAction.type, publishReaction),
    takeEvery(queryCurrentService.type, currentService),
    takeEvery(REHYDRATE, init),
    takeEvery(removeChannelAction.type, removeChannel),
    takeEvery(removeChannelGroupAction.type, removeChannelGroup),
    takeEvery(requestChannelAction.type, requestChannel),
    takeEvery(REQUEST_INVITE_TO_CHANNEL, requestInviteToChannel),
    takeEvery(requestLivePrayerAction.type, requestLivePrayer),
    takeEvery(REQUEST_PLACEHOLDER_CHANNEL, requestPlaceholderChannel),
    takeEvery(revertSubscriberAction.type, revertSubscriber),
    takeEvery(resetApp.type, logout),
    takeEvery(salvationMomentConnectAction.type, salvationMomentConnectMetric),
    takeEvery(salvationMomentFollowUpDismissAction.type, salvationMomentFollowUpDismissMetric),
    takeEvery(saveSubscriberAction.type, saveSubscriber),
    takeEvery(setChannelsAction.type, setChannels),
    takeEvery(setChannelGroupsAction.type, setChannelGroups),
    takeEvery(SetLanguageAction.type, setLanguage),
    takeEvery(setSubscriberAction.type, setIntegrationsUser),
    takeEvery(signUpCancelAction.type, signUpCancel),
    takeEvery(signUpErrorAction.type, signUpError),
    takeLatest(startSequenceAction.type, startSequence),
    takeEvery(submitMomentActionAction.type, submitMomentAction),
    takeEvery(submitMessageActionAction.type, submitMessageAction),
    takeEvery(deleteMomentRequest.type, deleteMoment),
    takeEvery(takeEventsForIntegrations, sendIntegrationsEvent),
    throttle(5000, TYPING_IN_CHANNEL, typingInChannel),
    takeEvery(updateGuestNicknameAction.type, updateGuestNickname),
    takeEvery(uploadAvatarAction.type, uploadAvatar),
    takeEvery(TOGGLE_MOMENTS_AUTOPOST, toggleMomentsAutopost),
    takeEvery(sendWebVitalMetricAction.type, sendWebVitalMetric),
    takeEvery(sendPaneViewMetricAction.type, sendPaneViewMetric),
    takeEvery(sendResizeHostVideoMetricAction.type, sendResizeHostVideoMetric),
    takeEvery(sendNotesEditedMetricAction.type, sendNotesEditedMetric),
    takeEvery(sendNotesExportedMetricAction.type, sendNotesExportedMetric),
  ]);
}

export {
  acceptInviteToChannel,
  basicAuth,
  checkAuth,
  deleteMessage,
  deleteSelf,
  guestAuth,
  handleAuth,
  init,
  leaveChannel,
  logout,
  muteSubscriber,
  unmuteSubscriber,
  postMoment,
  requestChannel,
  requestInviteToChannel,
  requestPasswordReset,
  requestPlaceholderChannel,
  requestPrayer,
  rootSaga,
  saveSubscriber,
  setLanguage,
  signUpCancel,
  signUpError,
  submitMomentAction,
  submitMessageAction,
  updateGuestNickname,
  uploadAvatar,
};
