import React, { FunctionComponent, KeyboardEventHandler, useCallback } from 'react';
import { Avatar, AvatarSizeType } from '@churchonline/core';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { LinkButton, LinkButtonType, PrimaryButton, SecondaryButton } from '@lifechurch/react-ion';
import { subscriberToPublicSubscriber } from '@store/subscriberSlice/selectors';
import { Small } from '@utils/responsive';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
// @ts-expect-error
import ReactTouchEvents from 'react-touch-events';
import { RoleType } from '../../../__generated__/globalTypes';
import { Props } from './index';
import LanguageSelector from './languageSelector';
import {
  EventTitle,
  LinkWrapper,
  LoginWrapper,
  Menu,
  Nickname,
  OrganizationTitle,
  Overlay,
  PoweredByLink,
  Profile,
  ProfileActions,
  ScreenReaderText,
  SpacedLink,
} from './styles';
import TextModeToggle from './textModeToggle';

const Links: FunctionComponent<Pick<Props, 'isOpen' | 'links' | 'linkTextCase'>> = ({
  isOpen,
  links,
  linkTextCase,
}) => {
  const { t } = useTranslation('links');
  return (
    <>
      {links.map(link => (
        <SpacedLink
          href={link.url}
          key={link.id}
          linkTextCase={linkTextCase}
          rel='noopener'
          tabIndex={isOpen ? 0 : -1}
          target='_blank'
        >
          {t(link.key, link.label)}
          <ScreenReaderText>Opens in new tab</ScreenReaderText>
        </SpacedLink>
      ))}
    </>
  );
};

const SideMenu: FunctionComponent<Props> = ({
  close,
  currentLanguage,
  currentSubscriber,
  externalLoginUrl,
  isAuthenticated,
  hostViewingAsHost,
  isOpen,
  languageOptions,
  links,
  linkTextCase,
  login,
  logout,
  onSwipe,
  openProfileModal,
  organizationName,
  serviceTitle,
  setLanguage,
  signup,
  osanoEnabled,
}) => {
  const { t } = useTranslation();

  const handleLogin = () => {
    close();
    if (externalLoginUrl) {
      window.location.href = externalLoginUrl;
    } else {
      login({ error: null });
    }
  };

  const handleSignup = () => {
    close();
    if (externalLoginUrl) {
      window.location.href = externalLoginUrl;
    } else {
      signup();
    }
  };

  const handleProfileKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      openProfileModal();
    }
  };

  const handleLogoutKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      logout();
    }
  };

  const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      close();
    }
  };

  const hasAdminLinkPermissions = currentSubscriber.role
    ? [RoleType.ADMIN, RoleType.OWNER, RoleType.GLOBAL].includes(currentSubscriber.role.type)
    : false;

  const subscriber = subscriberToPublicSubscriber(currentSubscriber);
  const avatar: string | undefined = subscriber.avatar ?? undefined;
  const nickname = subscriber.nickname ?? '';
  const id = subscriber.id ?? undefined;
  const osanoMode = typeof window === 'undefined' ? 'debug' : window.Osano?.cm.mode;

  const CookiePreferences =
    osanoEnabled && osanoMode !== 'debug' ? (
      <SpacedLink
        linkTextCase={linkTextCase}
        onClick={() => window.Osano?.cm.showDrawer('osano-cm-dom-info-dialog-open')}
        style={{ textAlign: 'center' }}
      >
        {t('cookie_preferences')}
      </SpacedLink>
    ) : null;

  return (
    <>
      <Overlay data-testid='sideMenu-overlay' onClick={close} visible={isOpen} />
      <ReactTouchEvents onSwipe={onSwipe}>
        <FocusTrap active={isOpen} focusTrapOptions={{ allowOutsideClick: true }}>
          <Menu
            data-testid='side-menu'
            onKeyDown={handleMenuKeyDown}
            onTransitionEnd={useCallback(
              event => {
                if (event.target && !isOpen) {
                  event.target.scrollTop = 0;
                }
              },
              [isOpen]
            )}
            open={isOpen}
          >
            <ErrorBoundary
              altComponentName={t('side_menu')}
              displayType={ErrorBoundaryDisplayType.SIDE_MENU}
            >
              <OrganizationTitle data-testid='organization-title'>
                {organizationName}
              </OrganizationTitle>
              <EventTitle data-testid='event-title'>{serviceTitle}</EventTitle>
              {isAuthenticated && (
                <Profile data-testid='profile'>
                  <Avatar
                    size={AvatarSizeType.LARGE}
                    subscriber={{ id, avatar, nickname }}
                    testId='avatar'
                  />
                  <Nickname>{currentSubscriber.nickname}</Nickname>
                  <ProfileActions>
                    <LinkButton
                      buttonType={LinkButtonType.SECONDARY}
                      data-testid='openProfile'
                      marginInlineEnd={4}
                      onClick={openProfileModal}
                      onKeyDown={handleProfileKeyDown}
                      tabIndex={isOpen ? 0 : -1}
                      role='button'
                    >
                      {t('profile')}
                    </LinkButton>
                    <LinkButton
                      buttonType={LinkButtonType.SECONDARY}
                      data-testid='logout'
                      marginInlineStart={4}
                      onClick={logout}
                      onKeyDown={handleLogoutKeyDown}
                      tabIndex={isOpen ? 0 : -1}
                      role='button'
                    >
                      {t('log_out')}
                    </LinkButton>
                  </ProfileActions>
                </Profile>
              )}
              {!isAuthenticated && (
                <LoginWrapper>
                  <SecondaryButton
                    data-testid='side-menu-login'
                    marginInlineEnd={3}
                    onClick={handleLogin}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    {t('log_in')}
                  </SecondaryButton>
                  <PrimaryButton
                    data-testid='side-menu-signup'
                    marginInlineStart={3}
                    onClick={handleSignup}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    {t('sign_up')}
                  </PrimaryButton>
                </LoginWrapper>
              )}
              {hostViewingAsHost ? (
                <>
                  <LinkWrapper data-testid='linksWrapper'>
                    <Links links={links} linkTextCase={linkTextCase} isOpen={isOpen} />
                    <hr />
                    {hasAdminLinkPermissions && (
                      <SpacedLink
                        data-testid='admin-link'
                        href={`${window.location.origin}/admin`}
                        linkTextCase={linkTextCase}
                        tabIndex={isOpen ? 0 : -1}
                      >
                        {t('go_to_admin')}
                      </SpacedLink>
                    )}
                    <SpacedLink
                      data-testid='feedback'
                      href='https://lifechurch.formstack.com/forms/partner_portal_feedback'
                      linkTextCase={linkTextCase}
                      rel='noopener'
                      tabIndex={isOpen ? 0 : -1}
                      target='_blank'
                    >
                      {t('give_feedback')}
                      <ScreenReaderText>Opens in new tab</ScreenReaderText>
                    </SpacedLink>
                  </LinkWrapper>
                </>
              ) : (
                <Small>
                  <LinkWrapper data-testid='linksWrapper'>
                    <Links links={links} linkTextCase={linkTextCase} isOpen={isOpen} />
                  </LinkWrapper>
                </Small>
              )}

              {CookiePreferences}

              <LanguageSelector
                currentLanguage={currentLanguage}
                isOpen={isOpen}
                languageOptions={languageOptions}
                setLanguage={setLanguage}
              />
              <TextModeToggle isOpen={isOpen} />
              <PoweredByLink
                aria-label={t('aria.church_online_platform')}
                data-testid='poweredByLogo'
                href='https://churchonlineplatform.com/'
                rel='noopener'
                tabIndex={isOpen ? 0 : -1}
                target='_blank'
              />
            </ErrorBoundary>
          </Menu>
        </FocusTrap>
      </ReactTouchEvents>
    </>
  );
};

export default React.memo<Props>(SideMenu);
