import React from 'react';
import { ConnectMoment } from '../../connect';
import { Preview } from '../index';
import { Box, IframeModal } from '@lifechurch/react-ion';
import { WindowType } from '../../types';
export const ConnectMomentPreview = ({ text, buttonText, url, windowType, }) => {
    const [isPinned, setIsPinned] = React.useState(true);
    const [iframeOpen, setIframeOpen] = React.useState(false);
    const onClick = () => {
        if (windowType === WindowType.NEW_TAB) {
            window.open(url, '_blank');
        }
        else {
            setIframeOpen(true);
        }
    };
    return (React.createElement(Preview, null,
        iframeOpen && React.createElement(IframeModal, { src: url, dismiss: () => setIframeOpen(false) }),
        React.createElement(Box, { p: isPinned ? 0 : 3, paddingBlockStart: 0, marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0 },
            React.createElement(ConnectMoment, { text: text, buttonText: buttonText, isPinned: isPinned, onDismiss: () => setIsPinned(false), onClick: onClick }))));
};
