import styled from 'styled-components';
import { AvatarSizeType } from './types';

interface IconPropsType {
  size: AvatarSizeType;
}

const getFontSize = (props: IconPropsType): string => {
  switch (props.size) {
    case AvatarSizeType.XSMALL:
      return '0.67rem';
    case AvatarSizeType.SMALL:
      return '0.84rem';
    case AvatarSizeType.MEDIUM:
      return '1rem';
    case AvatarSizeType.LARGE:
      return '1.6rem';
    case AvatarSizeType.XLARGE:
      return '2rem';
    case AvatarSizeType.XXLARGE:
      return '1.95rem';
    case AvatarSizeType.XXXLARGE:
      return '2.44rem';
  }
};

interface ImageProps extends IconPropsType {
  isActionable?: boolean;
}

export const AvatarWrapper = styled.div<{ size: number }>`
  border-radius: ${({ size }) => size / 2}px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
`;

const Icon = styled.div`
  border-radius: 50%;
  height: ${(props: IconPropsType) => props.size}px;
  width: ${(props: IconPropsType) => props.size}px;
  line-height: ${(props: IconPropsType) => props.size}px;
  color: ${props => props.theme.colors.white};
  font-size: ${(props: IconPropsType) => getFontSize(props)};
  text-align: center;
  font-weight: bold;
  user-select: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    box-shadow: 0 0 0 ${(props: ImageProps) => (props.isActionable ? `1px` : `0`)}
      ${props => props.theme.colors.white} inset;
    border: ${(props: ImageProps) => (props.isActionable ? `1px` : `0`)} solid
      ${props => props.theme.colors.action.hover};
  }
`;

const Overlay = styled(Icon)`
  border: 1px solid transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;

  &:active {
    background: ${props => props.theme.colors.black};
    opacity: 0.1;
  }
  &:hover {
    background-color: ${props => props.theme.colors.whiteTransparent20};
    box-shadow: ${props => props.theme.shadows[2]};
  }
`;

export { Icon, Overlay };
