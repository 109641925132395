import styled from 'styled-components';
import {
  LandscapeMobile,
  LargeUpQuery,
  MediumUpQuery,
  PortraitKeyboardOpen,
  SmallQuery,
} from '@utils/responsive';
import { isAndroid } from '@utils/core';

interface ContainerProps {
  hasMedia: boolean;
  isGuest?: boolean;
}

export const LiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.black};
  padding: 16px;

  @media ${SmallQuery} {
    display: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.colors.black};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  body {
    background-color: ${props => props.theme.colors.black};
  }

  @media ${SmallQuery} {
    padding: ${props => !props.hasMedia && '32px 16px'};
  }

  @media ${MediumUpQuery} {
    flex-direction: ${props => props.hasMedia && 'column'};
    align-items: ${props => props.hasMedia && 'flex-start'};
    justify-content: ${props => props.hasMedia && 'flex-start'};
    padding: ${props => (props.hasMedia ? '16px' : props.isGuest ? '64px 16px' : '16px')};
  }

  @media ${LargeUpQuery} {
    height: ${props => (props.hasMedia ? 'auto' : '100%')};
  }

  // @media ${LandscapeMobile} {
  //   height: 100%;
  // }

  @media ${PortraitKeyboardOpen} {
    display: ${isAndroid() ? 'none' : 'initial'};
  }
`;

interface DefaultProps {
  hasMedia: boolean;
  isGuest?: boolean;
  isOffline?: boolean;
}

export const ServiceMetaWrapper = styled.div<DefaultProps>`
  width: 100%;
  margin-block-end: ${props => (props.isGuest && !props.hasMedia ? '16px' : '8px')};

  @media ${MediumUpQuery} {
    max-width: ${props => !props.hasMedia && props.isGuest && '460px'};
  }
`;

export const Info = styled.div<DefaultProps>`
  width: 100%;
  display: flex;
  flex-direction: ${props => !props.hasMedia && 'column'};
  align-items: ${props => !props.hasMedia && 'center'};

  @media ${MediumUpQuery} {
    justify-content: space-between;
    flex-grow: ${props => props.hasMedia && '1'};
  }
`;

export const PrayerWrapper = styled.div<DefaultProps>`
  padding: ${props => (props.hasMedia ? '8px 16px' : '0px')};

  @media ${SmallQuery} {
    // Don't show in preChat when there is no offline video because it's in the chat already
    display: ${props => !props.hasMedia && !props.isOffline && 'none'};
  }

  @media ${MediumUpQuery} {
    padding: 0px;
    justify-self: ${props => props.hasMedia && 'flex-end'};
    align-self: ${props => props.hasMedia && 'flex-end'};
  }
`;

export const CountDownWrapper = styled.div<DefaultProps>`
  width: ${props => !props.isGuest && '100%'};

  @media ${SmallQuery} {
    margin-block-end: ${props => !props.hasMedia && props.isGuest && '16px'};
  }

  @media ${MediumUpQuery} {
    margin-block-end: ${props => !props.hasMedia && props.isGuest && '40px'};
  }
`;
