import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const DownloadIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7 8.79289L4.35355 6.14645L3.64645 6.85355L7.14645 10.3536L7.5 10.7071L7.85355 10.3536L11.3536 6.85355L10.6464 6.14645L8 8.79289V2H7V8.79289ZM2 12.5V10H3V12H12V10H13V12.5V13H12.5H2.5H2V12.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M11 9.58579L13.2929 7.29289L14.7071 8.70711L10.7071 12.7071L10 13.4142L9.29289 12.7071L5.29289 8.70711L6.70711 7.29289L9 9.58579V3.00005H11V9.58579ZM3 13H5V15H15V13H17V15V17H3V15V13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M13 12.5858L16.2929 9.29289L17.7071 10.7071L12.7071 15.7071L12 16.4142L11.2929 15.7071L6.29289 10.7071L7.70711 9.29289L11 12.5858V3.00005H13V12.5858ZM3 16H5V19H19V16H21V19V21H19H5H3V19V16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M17 17.5858L21.2929 13.2929L22.7071 14.7071L16.7071 20.7071L16 21.4142L15.2929 20.7071L9.29289 14.7071L10.7071 13.2929L15 17.5858V4.00005H17V17.5858ZM4 27V21H6V26H26V21H28V27V28H27H5H4V27Z'
          size={size}
          {...rest}
        />
      );
  }
};
