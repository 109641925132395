import CountDown from './CountDown';

import { connect } from 'react-redux';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { RootState } from '@store/rootReducer';

export enum Color {
  LIGHT = 'Light',
  DARK = 'Dark',
}

export enum Mode {
  COMPACT = 'compact',
  NORMAL = 'normal',
}

export enum Width {
  FIXED = 'fixed',
  FLUID = 'fluid',
}

interface StateProps {
  time: string | null;
}

interface OwnProps {
  color: Color;
  mode: Mode;
  width: Width;
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps & OwnProps => {
  const nextService = getNextServiceData(state);
  const hasUpcomingService = nextService && Object.keys(nextService).length > 0;

  return {
    time: hasUpcomingService ? nextService.scheduleTime : null,
    mode: props.mode ?? Mode.NORMAL,
    color: props.color ?? Color.DARK,
    width: props.width ?? Width.FIXED,
  };
};

const VisibleCountDown = connect(mapStateToProps)(CountDown);

export type Props = StateProps & OwnProps;
export default VisibleCountDown;
