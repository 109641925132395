import React, { createRef, FC, useCallback, useContext, useEffect, useState } from 'react';
import FeedObject from './objects';
import Moment from './objects/moment';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Props } from './index';
import { TextMode } from '../../../__generated__/globalTypes';
import { IconSize, Spinner } from '@lifechurch/react-ion';
import { useTheme } from 'styled-components';
import { useInterval } from '@churchonline/hooks';
import { FeedBoundaryContext } from '../../context/FeedBoundaryContext';
import { HEADER_HEIGHT } from '@features/pane/paneHeader/styles';
import styles from './feed.module.css';
import clsx from 'clsx';

const Feed: FC<Props> = ({
  channelKey,
  objects,
  textMode,
  setSawLastMessageAt,
  anchoredMoment,
  anchoredMomentSubmitted,
  isDirect,
  isGuest,
  latestObjectId,
  isLoading,
}) => {
  const wrapperRef = createRef<HTMLDivElement>();
  const momentWrapper = createRef<HTMLDivElement>();
  const listRef = createRef<HTMLDivElement>();
  const [atBottom, updateAtBottom] = useState(true);
  const [showNewMessageButton, updateShowNewMessageButton] = useState(false);
  const trayOpened = objects.some(object => object?.trayOpen);
  const { setFeedHeight, setTopBoundary } = useContext(FeedBoundaryContext);
  const [offsetHeight, setOffsetHeight] = useState<string>('122px');

  useEffect(() => {
    if (momentWrapper.current) {
      setOffsetHeight(momentWrapper.current.clientHeight + 66 + 'px');
    } else {
      setOffsetHeight('66px');
    }
  });

  useEffect(() => {
    if (wrapperRef.current) {
      const { top, bottom } = wrapperRef.current.getBoundingClientRect();
      setTopBoundary(top + HEADER_HEIGHT);
      setFeedHeight(bottom - top);
    }
  }, [wrapperRef]);

  // Refresh the feed every 60 seconds to change timestamps
  const [forceUpdate, setForceUpdate] = useState(0);
  useInterval(() => {
    setForceUpdate(value => value + 1);
  }, 60000);

  const scrollTo = (position: number) => {
    if (wrapperRef.current && listRef.current) {
      wrapperRef.current.scrollTop =
        listRef.current.scrollHeight -
        (Math.floor(wrapperRef.current.getBoundingClientRect().height) + position);
    }
  };

  const checkScrollPosition = () => {
    if (wrapperRef.current) {
      const { current: wrapper } = wrapperRef;
      if (
        wrapper.scrollHeight - (wrapper.getBoundingClientRect().height + wrapper.scrollTop) <=
        20
      ) {
        updateAtBottom(true);
        updateShowNewMessageButton(false);
        setSawLastMessageAt(channelKey, new Date().toISOString());
      } else {
        updateAtBottom(false);
      }
    }
  };

  const handleScroll = useCallback(debounce(checkScrollPosition, 100), [wrapperRef]);

  const scrollToBottom = () => {
    scrollTo(0);
    updateShowNewMessageButton(false);
    setSawLastMessageAt(channelKey, new Date().toISOString());
  };

  useEffect(() => {
    if (objects.length > 0) {
      if (atBottom) {
        scrollToBottom();
      } else {
        updateShowNewMessageButton(true);
      }
    }
  }, [latestObjectId]);

  // when tray opened, scroll to bottom if at bottom
  useEffect(() => {
    if (atBottom) {
      scrollToBottom();
    }
  }, [trayOpened, anchoredMoment]);

  useEffect(() => {
    if (atBottom) {
      setSawLastMessageAt(channelKey, new Date().toISOString());
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  const { t } = useTranslation('common');
  const theme = useTheme();
  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <Spinner size={IconSize.XL} color={theme.colors.gray50} />
      </div>
    );
  }
  return (
    <>
      <div data-testid='feed' ref={wrapperRef} onScroll={handleScroll} className={styles.wrapper}>
        <div className={styles.listContainer}>
          <div data-testid='feed-objectList' ref={listRef} className={styles.objectList}>
            {objects.map((object, index) => (
              <div
                key={`${object.id}:${forceUpdate}`}
                className={clsx('pbs-0', {
                  'pbs-12': index === 0 && isDirect,
                  'sm:pbs-12': index === 0 && (!isGuest || isDirect),
                })}
              >
                <FeedObject
                  channel={channelKey}
                  metaData={object}
                  isCompact={textMode === TextMode.COMPACT}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {anchoredMoment && (
        <div ref={momentWrapper}>
          <Moment
            channel={channelKey}
            momentId={anchoredMoment.id}
            hasSubmitted={anchoredMomentSubmitted}
            isAnchored={true}
          />
        </div>
      )}
      {showNewMessageButton && (
        <div
          data-testid='feed-newMessages'
          className={styles.newMessageButtonWrapper}
          style={{
            bottom: offsetHeight || '128px',
          }}
        >
          <button
            className={'btn btn-primary on-dark btn-sm cursor-default'}
            onClick={scrollToBottom}
          >
            {t('new_messages')}
          </button>
        </div>
      )}
    </>
  );
};

export default React.memo<Props>(Feed);
