import Large from './large';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { isLargeHostVideo } from '@store/uiSlice/selectors';

interface StateProps {
  largeHostVideo: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const largeHostVideo = isLargeHostVideo(state);

  return {
    largeHostVideo,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Large);
