import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CommentOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M2 4H13V11H2V4ZM1 3H2H13H14V4V11V12H13H11.5L9 14.5V12H2H1V11V4V3Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M4 6H16V14H4V6ZM2 4H4H16H18V6V14V16H16H15L12 19V16H4H2V14V6V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M4 6H20V18H4V6ZM2 4H4H20H22V6V18V20H20H18L14 24V20H4H2V18V6V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M4 8H28V24H4V8ZM2 6H4H28H30V8V24V26H28H24L19 31V26H4H2V24V8V6Z'
          size={size}
          {...rest}
        />
      );
  }
};
