export enum PaneType {
  AUDIENCE = 'AUDIENCE',
  BIBLE = 'BIBLE',
  CHAT = 'CHAT',
  HOST_CHAT = 'HOST_CHAT',
  HOST_INFO = 'HOST_INFO',
  NOTES = 'NOTES',
  MOMENTS = 'MOMENTS',
  SCHEDULE = 'SCHEDULE',
  SERVICE = 'SERVICE',
  ERROR = 'ERROR',
}

export enum ErrorCode {
  FIVE_HUNDRED = 500,
  FOUR_ZERO_FOUR = 404,
}

interface ErrorMeta {
  code: ErrorCode;
}

interface ChatMeta {
  channelKey: string;
}

export type BasePanes = Exclude<PaneType, PaneType.CHAT | PaneType.ERROR>;

export interface BasePane {
  type: BasePanes;
}

export interface ChatPane {
  type: PaneType.CHAT;
  meta: ChatMeta;
}

interface ErrorPane {
  type: PaneType.ERROR;
  meta: ErrorMeta;
}

export type Pane = ChatPane | ErrorPane | BasePane;
