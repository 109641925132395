import { ApolloQueryResult, useQuery } from '@apollo/client';
import {
  AudienceTab,
  AudienceTab_currentService_feed_PublicChannel,
  AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node,
  AudienceTab_currentService_feed_PublicChannel_subscribers_pageInfo,
  AudienceTabVariables,
} from '@io/__generated__/AudienceTab';
import { audienceTab } from '@io/queries';
import { FILTERS } from '@features/audience/components/filter';
import { ChannelType, SubscriberType } from '../../../__generated__/globalTypes';
import useSessionStorage from '@hooks/useSessionStorage';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '@hooks/useDebounce';
import { useSelector } from 'react-redux';
import { isOffline as isOfflineSelector } from '@store/serviceSlice/selectors';
import { getRequestedRefetches } from '../../store/uiSlice/selectors';
import { useAppDispatch } from '@hooks/redux/hooks';
import { PaneType } from '@features/pane/dux';
import { RootState } from '@store/rootReducer';
import { clearRequestedRefetch, RefetchQueryType } from '@store/uiSlice';

export const ALL_USER_TYPES = [
  SubscriberType.USER,
  SubscriberType.ALIAS_GUEST,
  SubscriberType.HOST,
  SubscriberType.ADMIN,
  SubscriberType.OWNER,
];

export enum LOADING {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export const PAGE_SIZE = 15;
export const NUM_OF_PAGES = 4;
const POLL_INTERVAL = 30000;

interface UseAudienceTabReturn {
  filter: FILTERS;
  loadNext: () => void;
  loadPrevious: () => void;
  loadFirst: () => void;
  loading: LOADING | null;
  newSubscribers: boolean;
  /**
   * Tells whether the "New Subscribers" text should be appended
   * to the scroll up button.
   *
   * @param {boolean} newSubscribers - Whether there are new subscribers
   */
  setNewSubscribers: (newSubscribers: boolean) => void;
  refetch: (
    variables?: Partial<AudienceTabVariables> | undefined
  ) => Promise<ApolloQueryResult<AudienceTab>>;
  search: string | null;
  setFilter: (filter: FILTERS) => void;
  setSearch: (search: string | null) => void;
  subscribers: (
    | AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node
    | null
    | undefined
  )[];
  subscribersLoading: boolean;
  pageInfo: AudienceTab_currentService_feed_PublicChannel_subscribers_pageInfo | undefined;
  currentEdgeSubscriber:
    | AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node
    | null
    | undefined;
  totalConnectedCount: number;
}

type SubscriberNode =
  | AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node
  | null
  | undefined;

const useAudienceTab = (): UseAudienceTabReturn => {
  const audiencePaneFilter = useSelector((state: RootState) => state.ui.audiencePaneFilter);
  const [search, setSearch] = useSessionStorage<string | null>('audience:search', null);
  const [filter, setFilter] = useSessionStorage<FILTERS>('audience:filter', FILTERS.NONE);
  const dispatch = useAppDispatch();
  const isOffline = useSelector(isOfflineSelector);
  const currentPane = useSelector((state: RootState) => state.ui.pane);
  const refetchRequested = useSelector(getRequestedRefetches).some(
    refetch => refetch.query === RefetchQueryType.AUDIENCE_TAB
  );
  const [loading, setLoading] = useState<LOADING | null>(null);
  const currentEdgeSubscriber = useRef<SubscriberNode>(null);
  const [currentTotalCount, setCurrentTotalCount] = useState<number | null>(null);
  const [newSubscribers, setNewSubscribers] = useState<boolean>(false);

  useEffect(() => {
    if (audiencePaneFilter) setFilter(audiencePaneFilter);
  }, [audiencePaneFilter]);

  const debouncedSearch = useDebounce(search, 500);

  const {
    data,
    refetch,
    loading: subscribersLoading,
    startPolling,
    stopPolling,
  } = useQuery<AudienceTab, AudienceTabVariables>(audienceTab, {
    variables: {
      filter: {
        subscriberTypeIn:
          filter === FILTERS.HOSTS
            ? [SubscriberType.HOST, SubscriberType.ADMIN, SubscriberType.OWNER]
            : ALL_USER_TYPES,
        muted: filter === FILTERS.MUTED ? true : null,
        search: debouncedSearch,
      },
      first: PAGE_SIZE * NUM_OF_PAGES,
    },
    skip: isOffline,
    fetchPolicy: 'no-cache',
  });

  const publicChannel = data?.currentService?.feed?.find(
    x => x.type === ChannelType.public
  ) as AudienceTab_currentService_feed_PublicChannel;
  const edges = publicChannel?.subscribers?.edges || [];
  const totalCount = publicChannel?.subscribers?.totalCount ?? null;
  const pageInfo = publicChannel?.subscribers?.pageInfo;
  const subscribers = edges.map(edge => edge?.node) || [];
  const CURRENT_PAGE_SIZE = Math.round(edges.length / NUM_OF_PAGES);

  useEffect(() => {
    if (currentTotalCount != null && totalCount != null && currentTotalCount < totalCount) {
      setNewSubscribers(true);
    }

    setCurrentTotalCount(totalCount);
  }, [totalCount, currentTotalCount, newSubscribers]);

  useEffect(() => {
    stopPolling();
    startPolling(POLL_INTERVAL);
  }, [filter, debouncedSearch, startPolling, stopPolling, setNewSubscribers]);

  useEffect(() => {
    if (refetchRequested === true && currentPane.type === PaneType.AUDIENCE) {
      const timeout = Math.floor(Math.random() * 1000);

      // Adds a random delay to prevent simultaneous requests
      setTimeout(() => {
        refetch();
        dispatch(clearRequestedRefetch(RefetchQueryType.AUDIENCE_TAB));
      }, timeout);
    }
    return clearTimeout();
  }, [refetchRequested]);

  const loadPrevious = async () => {
    if (pageInfo?.hasPreviousPage && loading === null) {
      setLoading(LOADING.TOP);
      currentEdgeSubscriber.current = edges[0]?.node;
      // Get end of page 1 to load the previous two pages
      const edge = edges[CURRENT_PAGE_SIZE * 2 - 1];

      stopPolling();
      await refetch({
        last: PAGE_SIZE * NUM_OF_PAGES,
        before: edge?.cursor,
        first: null,
        after: null,
      });
      startPolling(POLL_INTERVAL);

      setLoading(null);
    }
  };

  const loadNext = async () => {
    if (pageInfo?.hasNextPage && loading === null) {
      setLoading(LOADING.BOTTOM);
      currentEdgeSubscriber.current = edges[edges.length - 1]?.node;
      // Get start of page 3 to load the next two pages
      const edge = edges[edges.length - 1 - CURRENT_PAGE_SIZE * 2];

      stopPolling();
      await refetch({
        first: PAGE_SIZE * NUM_OF_PAGES,
        after: edge?.cursor,
        last: null,
        before: null,
      });
      startPolling(POLL_INTERVAL);

      setLoading(null);
    }
  };

  const loadFirst = async () => {
    setNewSubscribers(false);
    setLoading(LOADING.TOP);
    stopPolling();
    await refetch({
      last: null,
      before: null,
      first: PAGE_SIZE * NUM_OF_PAGES,
      after: null,
    });
    startPolling(POLL_INTERVAL);
    setLoading(null);
  };

  return {
    filter,
    loadNext,
    loadPrevious,
    loadFirst,
    loading,
    refetch,
    search,
    setFilter,
    setNewSubscribers,
    setSearch,
    subscribers,
    subscribersLoading,
    pageInfo,
    currentEdgeSubscriber: currentEdgeSubscriber.current,
    newSubscribers,
    totalConnectedCount: publicChannel?.subscribers?.totalConnected || 0,
  };
};

export default useAudienceTab;
