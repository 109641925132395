import styled, { css } from 'styled-components';
import { Color, Mode, Width } from './';
import { OHeaderThree } from '@lifechurch/react-ion';

interface Props {
  color?: Color;
  mode?: Mode;
  width?: Width;
}

const CountDownContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: ${props => props.width === Width.FLUID && '100%'};
`;

const TimeBoxContainer = styled.div<Props>`
  flex: ${props => props.width === Width.FLUID && '1'};
  text-align: center;
  padding: 4px;
  margin-inline-end: 4px;
  border-radius: 4px;
  height: ${props => (props.mode === Mode.COMPACT ? '40px' : 'auto')};
  min-height: ${props => (props.mode === Mode.COMPACT ? 'none' : '64px')};
  width: ${props => props.width === Width.FIXED && '96px'};

  &:nth-last-child(1) {
    margin-inline-end: 0;
  }

  background: ${props =>
    props.color === Color.LIGHT ? props.theme.colors.gray5 : props.theme.colors.gray130};
`;

const CountDownNumber = styled(OHeaderThree)<Props>`
  line-height: ${props => (props.mode === Mode.COMPACT ? '17px' : '25px')};
  font-weight: ${props => props.theme.fontWeights[3]};
  font-size: ${props =>
    props.mode === Mode.COMPACT ? props.theme.fontSizes[3] : props.theme.fontSizes[5]};
  margin-block-start: ${props => (props.mode === Mode.COMPACT ? '2px' : '11px')};

  color: ${props =>
    props.color === Color.LIGHT ? props.theme.colors.gray100 : props.theme.colors.white};
`;

const TypeText = styled.p<Props>`
  line-height: 1.25;
  font-size: ${props => props.theme.fontSizes[1]};
  color: ${props =>
    props.color === Color.LIGHT ? props.theme.colors.gray80 : props.theme.colors.gray50};
  ${props =>
    props.mode === Mode.COMPACT &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export { CountDownContainer, TimeBoxContainer, CountDownNumber, TypeText };
