import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, ButtonSize, IconSize, OSmallParagraph, TwitterLogoIcon, } from '@lifechurch/react-ion';
import { ShareButtonContainer } from '../share-button-container';
const colorTwitterBlue = '#1DA1F2';
const TwitterButton = styled(PrimaryButton) `
  background-color: ${colorTwitterBlue};

  &:hover {
    background-color: ${colorTwitterBlue};
  }
`;
const TwitterShareButton = ({ content, url = null, size = ButtonSize.SM, padding = '8px !important', iconSize = IconSize.XL, imageShareUrl = null, }, props) => {
    const shareTwitter = () => {
        const siteUrl = url ? `&url=${url}` : null;
        let shareUrl;
        if (content) {
            shareUrl = `https://twitter.com/intent/tweet?text=${content}${siteUrl}`;
        }
        if (imageShareUrl) {
            shareUrl = `https://twitter.com/intent/tweet?url=${imageShareUrl}`;
        }
        window.open(shareUrl, '_blank');
    };
    return (React.createElement(ShareButtonContainer, { flexDirection: 'column', alignItems: 'center' },
        React.createElement(TwitterButton, { "aria-label": 'Twitter', "data-testid": 'twitter', onClick: content || imageShareUrl ? shareTwitter : null, padding: padding, size: size, ...props },
            React.createElement(TwitterLogoIcon, { size: iconSize, color: 'white' })),
        React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Twitter")));
};
export { TwitterShareButton };
