import React, { FunctionComponent } from 'react';
import { Flex, OHeaderFive, Switch, SwitchSizeType } from '@lifechurch/react-ion';
import styled from 'styled-components';
import { BasePayload } from '../index';
import { Title, Wrapper } from '../styles';

export interface Payload extends BasePayload {
  momentSchedulingEnabled: boolean;
  showAutoPostToggle: boolean;
  toggleMomentScheduling: () => void;
}

const StyledWrapper = styled(Wrapper)`
  justify-content: space-between;
  padding-inline-end: 15px;
`;

const MomentHeader: FunctionComponent<Payload> = ({
  momentSchedulingEnabled,
  showAutoPostToggle,
  title,
  toggleMomentScheduling,
}) => (
  <StyledWrapper data-testid='tabHeader' height={48}>
    <Title data-testid='tabHeader-title'>{title}</Title>
    {showAutoPostToggle ? (
      <Flex alignItems='center'>
        <OHeaderFive color='gray50' marginInlineEnd={3}>
          Automatically Post
        </OHeaderFive>
        <Switch
          action={toggleMomentScheduling}
          ariaLabel={`Automatically post moment toggle. Toggle is currently ${
            momentSchedulingEnabled ? 'on' : 'off'
          }`}
          className={'tabbable'}
          id='autoPostMomentToggle'
          on={momentSchedulingEnabled}
          size={SwitchSizeType.SM}
          tabIndex={-1}
        />
      </Flex>
    ) : null}
  </StyledWrapper>
);

export default MomentHeader;
