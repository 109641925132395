import React, { FC } from 'react';
import { CommentIcon, getOpenNetworkTheme, IconSize } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = { count: number };

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.action.light};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.action.default};
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: center;
  margin-left: 3px;
  padding: 2px 4px;

  span {
    margin-left: 2px;
  }
`;

export const PrayerBadge: FC<Props> = ({ count }) => {
  const { t } = useTranslation('common');
  const { colors } = getOpenNetworkTheme();

  if (count === 0) return null;

  const title = t('active_prayers', { count });

  return (
    <Container title={title} data-testid='prayer-badge'>
      <CommentIcon color={colors.action.default} size={IconSize.SM} /> <span>{count}</span>
    </Container>
  );
};
