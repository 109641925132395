import React, { FC } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const LanguageIcon: FC<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M6 1H8V2H13V4H10.8139C10.0511 7.09587 7.84386 10.4529 3.48564 12.8742L2.51436 11.1258C6.16235 9.09918 8.00884 6.41814 8.74054 4H5.25947C5.50502 4.81151 5.87613 5.65264 6.39874 6.4887C6.02094 7.01743 5.57053 7.54447 5.03772 8.05812C4.11361 6.72091 3.51536 5.33613 3.18614 4H1V2H6V1ZM15.2111 7.995L19.0661 18H16.6361L16.0211 16.305H11.7311L11.1011 18H8.67109L12.5411 7.995H15.2111ZM12.3161 14.43H15.4361L13.8761 10.125L12.3161 14.43ZM9.11127 9.52584C9.53292 9.8707 9.9915 10.2059 10.4893 10.5282L9.75404 12.4292C9.02065 11.9771 8.35462 11.4974 7.75194 10.997C8.25146 10.5205 8.70382 10.0286 9.11127 9.52584Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
