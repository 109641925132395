import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

interface Provider {
  topBoundary: number | null;
  feedHeight: number;
  setFeedHeight: Dispatch<SetStateAction<number>>;
  setTopBoundary: Dispatch<SetStateAction<number | null>>;
}

export const FeedBoundaryContext = createContext<Provider>({} as Provider);

export const FeedBoundaryProvider: FC = ({ children }) => {
  const [topBoundary, setTopBoundary] = useState<number | null>(null);
  const [feedHeight, setFeedHeight] = useState<number>(500);

  return (
    <FeedBoundaryContext.Provider
      value={{ feedHeight, setFeedHeight, setTopBoundary, topBoundary }}
    >
      {children}
    </FeedBoundaryContext.Provider>
  );
};
