import React, { ChangeEvent, ReactNode, forwardRef, KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import { InputSize } from './Input';
import { LinkButton, LinkButtonType } from './LinkButton';
import { OParagraph } from './Typography';
import { Box } from './Box';
import { getInset } from './logical';

interface InputFieldProps {
  error: boolean;
  valid: boolean;
  rightMargin: number;
  fieldSize: InputSize;
}

interface InputProps {
  size: InputSize;
}

interface InputButtonProps {
  fieldSize: InputSize;
}

interface InputLabelProps {
  size: InputSize;
  required: boolean;
}

interface ContextProps {
  size: InputSize;
  error?: boolean;
  valid?: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

const InputField = styled.input<InputFieldProps>`
  color: ${props => props.theme.colors.gray100};
  caret-color: ${props => props.theme.colors.gray100};
  flex-grow: 2;
  border: 0;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  border-block-end: 1px solid
    ${props => {
      if (props.error) {
        return props.theme.colors.error.default;
      } else if (props.valid) {
        return props.theme.colors.success.default;
      } else {
        return props.theme.colors.gray30;
      }
    }};

  padding-block-start: ${props => (props.fieldSize === InputSize.SM ? '18px' : '21px')};
  padding-inline-end: ${props => props.rightMargin}px;
  padding-block-end: ${props => (props.fieldSize === InputSize.SM ? '9px' : '8px')};
  padding-inline-start: 0;
  border-radius: 0;
  font-size: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.fontSizes[3] : props.theme.fontSizes[4]};
  line-height: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.lineHeights[2] : props.theme.lineHeights[3]};
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
    border-block-end: 1px solid ${props => props.theme.colors.action.default};
  }
`;

export const InputLabel = styled.label<InputLabelProps>`
  position: absolute;
  pointer-events: none;
  color: ${props => props.theme.colors.gray50};
  transition: 0.1s ease-out;
  font-size: ${props =>
    props.size === InputSize.SM ? props.theme.fontSizes[3] : props.theme.fontSizes[4]};
  line-height: ${props =>
    props.size === InputSize.SM ? props.theme.lineHeights[2] : props.theme.lineHeights[3]};
  ${props =>
    props.size === InputSize.SM
      ? getInset('insetBlockStart', '18px')
      : getInset('insetBlockStart', '21px')};

  &[required]::after {
    content: '*';
  }

  ${InputField}:focus ~ &,
  ${InputField}:not([value='']) ~ & {
    font-size: ${props =>
      props.size === InputSize.SM ? props.theme.fontSizes[2] : props.theme.fontSizes[3]};
    ${getInset('insetBlockStart', 0)}
  }
`;

const StyledLinkButton = styled(LinkButton)<InputButtonProps>`
  position: absolute;
  flex: 1;
  ${getInset('insetInlineEnd', 0)}
  ${props =>
    props.fieldSize === InputSize.SM
      ? getInset('insetBlockStart', '18px')
      : getInset('insetBlockStart', '21px')};
  font-size: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.fontSizes[3] : props.theme.fontSizes[4]};
  line-height: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.lineHeights[2] : props.theme.lineHeights[3]};
`;

const HelperText = styled.div<InputProps>`
  position: absolute;
  flex: 1;
  ${getInset('insetInlineEnd', 0)}
  ${props =>
    props.size === InputSize.SM
      ? getInset('insetBlockStart', '18px')
      : getInset('insetBlockStart', '21px')};
  color: ${props => props.theme.colors.gray50};
  font-size: ${props => (props.size === InputSize.SM ? '16px' : '20px')};
  line-height: ${props => (props.size === InputSize.SM ? '20px' : '31px')};
`;

export const Context = styled(OParagraph)<ContextProps>`
  font-size: ${props =>
    props.size === InputSize.SM ? props.theme.fontSizes[1] : props.theme.fontSizes[2]};
  color: ${props => {
    if (props.error) {
      return props.theme.colors.error.default;
    } else if (props.valid) {
      return props.theme.colors.success.default;
    } else {
      return props.theme.colors.gray50;
    }
  }};
`;

interface Button {
  ariaLabel?: string;
  onClick: () => void;
  text: string;
}

// There are conflicting TS types, so we need to omit some to pass typechecking
type NativeInput = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'aria-relevant'>;

export interface FancyInputProps extends NativeInput {
  label: string;
  size?: InputSize;
  name?: string;
  value?: string;
  error?: boolean;
  valid?: boolean;
  hint?: ReactNode;
  context?: ReactNode;
  helperText?: string;
  rightInputMargin?: number;
  required?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  button?: Button;
}

const FancyInput = forwardRef<HTMLInputElement, FancyInputProps>(
  (
    {
      size = InputSize.SM,
      name = 'fancyInput',
      value = '',
      label,
      onChange,
      button,
      error = false,
      valid = false,
      hint,
      context,
      required = false,
      rightInputMargin = 0,
      helperText,
      ...props
    },
    ref
  ) => {
    const onButtonKeyDown: KeyboardEventHandler<HTMLAnchorElement> = e => {
      if (e.key === 'Enter') {
        button?.onClick();
      }
    };

    return (
      <InputWrapper size={size}>
        <InputFieldWrapper>
          <InputField
            ref={ref}
            name={name}
            id={name}
            fieldSize={size}
            value={value}
            onChange={onChange}
            rightMargin={rightInputMargin}
            error={error}
            valid={valid}
            required={required}
            {...props}
          />
          <InputLabel htmlFor={name} size={size} required={required}>
            {label}
          </InputLabel>
          {helperText && <HelperText size={size}>{helperText}</HelperText>}
          {button && helperText === undefined && (
            <StyledLinkButton
              onClick={button.onClick}
              onKeyDown={onButtonKeyDown}
              fieldSize={size}
              buttonType={LinkButtonType.SECONDARY}
              tabIndex={0}
              aria-label={button.ariaLabel ?? ''}
            >
              {button.text}
            </StyledLinkButton>
          )}
        </InputFieldWrapper>
        {(hint || context) && (
          <Box marginBlockStart={2}>
            {hint && (
              <Context size={size} error={error} valid={valid}>
                {hint}
              </Context>
            )}
            {context && <Context size={size}>{context}</Context>}
          </Box>
        )}
      </InputWrapper>
    );
  }
);

FancyInput.displayName = 'ForwardedFancyInput';

export { FancyInput };
