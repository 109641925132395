import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CaretDownIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='M11.5 6L7.5 10L3.5 6L11.5 6Z' size={size} {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='M15 8L10 13L5 8H15Z' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='M19 9L12 16L5 9H19Z' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='M25 12L16 21L7 12L25 12Z' size={size} {...rest} />;
  }
};
