import styled from 'styled-components';

const Container = styled.div`
  max-width: 300px;
  position: absolute;
  bottom: 52px;
  right: 3px;
  background-color: ${(props): string => props.theme.colors.action.default};
  padding-block-start: 12px;
  padding-block-end: 12px;
  padding-inline-start: 16px;
  padding-inline-end: 12px;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows[1]};
  display: flex;

  //Banner triangle
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 9px solid ${(props): string => props.theme.colors.action.default};
    bottom: -7px;
    right: 45px;
  }
`;

const Text = styled.span`
  color: ${(props): string => props.theme.colors.white};
  font-weight: ${(props): number => props.theme.fontWeights[1]};
  line-height: 20px;
  font-size: ${props => props.theme.fontSizes[3]};
`;

const CloseButton = styled.button`
  padding: 0;
  display: inline-flex;
  background-color: transparent;
  border: none;
  height: fit-content;
  opacity: 0.5;
  cursor: pointer;

  svg {
    transition: all 0.09s ease-in-out;
  }

  &:active svg {
    transform: scale(0.8);
  }

  &:focus {
    outline: none;
  }
`;

export { Container, Text, CloseButton };
