import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SelfReaction from './SelfReaction';
import { removeReaction } from '@store/feedSlice';
import { Reaction as ReactionInterface } from '../dux';
import { RootState } from '@store/rootReducer';
import { getCurrentSubscriber } from '@store/feedSlice/chatSelectors';

interface OwnProps {
  reaction: ReactionInterface;
}

interface StateProps {
  subscriberId: string;
}

interface DispatchProps {
  removeReaction: (id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeReaction: id => dispatch(removeReaction({ id })),
});

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  subscriberId: getCurrentSubscriber(state).id,
  ...props,
});

const VisibleReaction = connect(mapStateToProps, mapDispatchToProps)(SelfReaction);

export type Props = OwnProps & DispatchProps & StateProps;
export default VisibleReaction;
