import React from 'react';
import { Avatar, Subscriber } from '../Avatar/Avatar';
import { AvatarSizeType } from '../Avatar/types';
import { AvatarContainer } from './AvatarContainer';
import { Container } from './Container';

type Props = { subscribers: Subscriber[] };

function ThreeHostLayout({ subscribers }: Props) {
  return (
    <Container height='82px' width='105px'>
      <AvatarContainer top='26px' left='0px' size={AvatarSizeType.LARGE}>
        <Avatar size={AvatarSizeType.LARGE} subscriber={subscribers[0]} />
      </AvatarContainer>

      <AvatarContainer top='0px' left='48px' size={AvatarSizeType.SMALL}>
        <Avatar size={AvatarSizeType.SMALL} subscriber={subscribers[1]} />
      </AvatarContainer>

      <AvatarContainer top='35px' left='65px' size={AvatarSizeType.MEDIUM}>
        <Avatar size={AvatarSizeType.MEDIUM} subscriber={subscribers[2]} />
      </AvatarContainer>
    </Container>
  );
}

export { ThreeHostLayout };
