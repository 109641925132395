import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const DuplicateIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M2 1H12V2H2V12H1V2V1H2ZM13 4H4V13H13V4ZM4 3H3V4V13V14H4H13H14V13V4V3H13H4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M3 2H2V3V14H4V4H14V2H3ZM8 8H16V16H8V8ZM6 6H8H16H18V8V16V18H16H8H6V16V8V6Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M4 3H3V4V17H5V5H17V3H4ZM9 9H19V19H9V9ZM7 7H9H19H21V9V19V21H19H9H7V19V9V7Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M4 3H3V4V25H5V5H25V3H4ZM9 9H27V27H9V9ZM7 7H9H27H29V9V27V29H27H9H7V27V9V7Z'
          size={size}
          {...rest}
        />
      );
  }
};
