import styled from 'styled-components';
import { Box, Flex, OXSmallParagraph } from '@lifechurch/react-ion';

interface BaseProps {
  connected: boolean;
}

export const Name = styled(Box)<BaseProps>`
  margin-inline-start: ${props => props.theme.space[3]}px;
  margin-inline-end: ${props => props.theme.space[2]}px;

  font-weight: bold;
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[2]};
  color: ${(props): string =>
    props.connected ? props.theme.colors.gray100 : props.theme.colors.gray50};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Me = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[2]};
  color: ${(props): string => props.theme.colors.gray50};
  margin-inline-start: 4px;
`;

export const SubscriberRowItem = styled(Flex)<{ isSelected: boolean; connected: boolean }>`
  align-items: start;
  background-color: ${props => props.isSelected && props.theme.colors.gray5};
  padding: ${props => props.theme.space[3]}px;
  border-radius: ${props => props.theme.radii[2]}px;

  &:hover {
    background-color: ${props => (props.connected ? props.theme.colors.gray5 : 'transparent')};
  }

  &:focus {
    outline: none;
    background-color: ${props => (props.connected ? props.theme.colors.gray5 : 'transparent')};
  }
`;

export const RoleLabel = styled(Box)<BaseProps>`
  background-color: ${props =>
    props.connected ? props.theme.colors.gray50 : props.theme.colors.gray30};
  border-radius: 7px;
  padding: 1px 4px;
  text-transform: capitalize;
  flex-shrink: 0;

  p {
    color: white;
  }
`;

export const JoinedAt = styled(OXSmallParagraph)`
  flex-shrink: 0;
  padding-inline-start: 6px;
  font-size: ${props => props.theme.fontSizes[1]};
  line-height: ${props => props.theme.lineHeights[2]};
  color: ${(props): string => props.theme.colors.gray50};
`;
