import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, Wrapper } from '../styles';
import { BasePayload } from '../index';
import { HostCount } from './HostCount';

const Title = styled.div`
  color: ${props => props.theme.colors.gray100};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

const HostChatHeader: FunctionComponent<BasePayload> = ({ title }) => (
  <Wrapper data-testid='hostChatHeader' height={HEADER_HEIGHT}>
    <Title data-testid='hostChatHeader-title'>{title}</Title>
    <HostCount />
  </Wrapper>
);

export default HostChatHeader;
