import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const DocumentOIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M4 2H11V11H9V13H4V2ZM11 13L10 14H4H3V13V2V1H4H11H12V2V12L11 13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M6 4H14V13H11V16H6V4ZM13 18L16 15V4V2H14H6H4V4V16V18H6H13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M6 4H18V16H14V20H6V4ZM16 22L20 18V4V2H18H6H4V4V20V22H6H16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M8 4H24V23H19V28H8V4ZM21 30L26 25V4V2H24H8H6V4V28V30H8H21Z'
          size={size}
          {...rest}
        />
      );
  }
};
