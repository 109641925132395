import React, { FC } from 'react';
import Countdown, { CountdownProps } from 'react-countdown';

import TimeBox from './components/TimeBox';
import { CountDownContainer } from './styles';
import { Color, Mode, Props, Width } from './';

interface CountdownRendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  props: CountdownProps;
  color: Color;
  mode: Mode;
  width: Width;
}

const CountdownRenderer: FC<CountdownRendererProps> = ({
  days,
  hours,
  minutes,
  seconds,
  color,
  mode,
  width,
}) => {
  const settings = { color, mode, width };
  return (
    <CountDownContainer data-testid='countDownContainer' width={width}>
      <TimeBox increment={days} type={'days'} settings={settings} />
      <TimeBox increment={hours} type={'hours'} settings={settings} />
      <TimeBox increment={minutes} type={'minutes'} settings={settings} />
      {days ? null : <TimeBox increment={seconds} type={'seconds'} settings={settings} />}
    </CountDownContainer>
  );
};

const CountDown: FC<Props> = ({ time, ...rest }) => {
  if (!time) return null;

  return (
    <Countdown
      key={time}
      date={time}
      renderer={params => <CountdownRenderer {...params} {...rest} />}
    />
  );
};

export default CountDown;
