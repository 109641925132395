import React, { FC } from 'react';
import { Props } from './index';
import {
  Container,
  CountDownWrapper,
  Info,
  LiveContainer,
  PrayerWrapper,
  ServiceMetaWrapper,
} from './styles';
import PrayerButton from '@components/PrayerButton';
import ServiceMeta, { Alignment } from './serviceMeta';
import CountDown, { Color, Mode, Width } from '@components/CountDown';
import { useMediaQuery } from 'react-responsive';
import { SmallQuery } from '@utils/responsive';
import { Box } from '@lifechurch/react-ion';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import Cookie from 'js-cookie';

const ServiceInfo: FC<Props> = ({ hasMedia, isGuest, hasPrayer, isDuringService, isOffline }) => {
  const isSmall = useMediaQuery({ query: SmallQuery });
  const isCompact = hasMedia || !isGuest || isSmall;
  const isPreviewService = Cookie.get('preview_service') ? true : false;

  // OFFLINE - on small screens with media show offline prayer button
  if (isSmall && hasMedia) {
    return (
      <Container hasMedia={hasMedia}>
        <ErrorBoundary displayType={ErrorBoundaryDisplayType.VIDEO_META}>
          {hasPrayer && (
            <Box m={hasMedia && isGuest ? '8px 16px' : 0}>
              <PrayerButton />
            </Box>
          )}
        </ErrorBoundary>
      </Container>
    );
  }

  // ONLINE - after schedule start time
  if (isDuringService || isPreviewService) {
    return (
      <LiveContainer>
        <ErrorBoundary displayType={ErrorBoundaryDisplayType.VIDEO_META}>
          <ServiceMeta hasMedia={hasMedia} />
          <PrayerButton />
        </ErrorBoundary>
      </LiveContainer>
    );
  }

  // OFFLINE - with media shown medium up, without media shown on all sizes
  return (
    <Container data-testid='serviceInfo-offline' hasMedia={hasMedia} isGuest={isGuest}>
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.VIDEO_META}>
        <ServiceMetaWrapper hasMedia={hasMedia} isGuest={isGuest}>
          <ServiceMeta
            hasMedia={hasMedia}
            alignment={isGuest && !hasMedia ? Alignment.CENTER : undefined}
          />
        </ServiceMetaWrapper>
        <Info hasMedia={hasMedia}>
          <CountDownWrapper hasMedia={hasMedia} isGuest={isGuest} data-testid='count-down-wrapper'>
            <CountDown
              mode={isCompact ? Mode.COMPACT : Mode.NORMAL}
              width={!isGuest && !isSmall ? Width.FLUID : Width.FIXED}
              color={Color.DARK}
            />
          </CountDownWrapper>
          <PrayerWrapper isOffline={isOffline} hasMedia={hasMedia}>
            <PrayerButton />
          </PrayerWrapper>
        </Info>
      </ErrorBoundary>
    </Container>
  );
};

export default ServiceInfo;
