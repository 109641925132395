import { Box, defaultColors, Fuse, IconSize, getInset, TimesIcon } from '@lifechurch/react-ion';
import React from 'react';
import styled from 'styled-components';
const IconWrapper = styled.div `
  position: absolute;
  ${getInset('insetBlockStart', '8px')};
  ${getInset('insetInlineStart', '8px')};
`;
const DismissButton = styled(Box) `
  &:hover {
    svg circle,
    svg line {
      stroke: ${props => props.theme.colors.gray100};
    }
  }
  right: 0;
  [dir='rtl'] &&& {
    right: unset;
    left: 0;
  }
`;
const Header = ({ showDismiss, icon, fuse, onDismiss }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(IconWrapper, null, icon),
        showDismiss && (React.createElement(DismissButton, { "data-testid": 'dismissButton', position: 'absolute', top: 0, padding: 'inherit', lineHeight: 0, onClick: onDismiss, marginBlockStart: fuse ? '-4px' : '0', marginInlineEnd: fuse ? '-4px' : '0' }, fuse ? (React.createElement(Fuse, { duration: 20e3, onEnded: onDismiss, color: defaultColors.gray50, size: IconSize.SM, dismissible: true })) : (React.createElement(TimesIcon, { size: IconSize.SM, hoverColor: defaultColors.gray100 }))))));
};
export default Header;
