import Chat from './chat';
import { connect } from 'react-redux';
import {
  getChannelByKey,
  getMomentChannelGroup,
  getPublicChannel,
  pendingPrayer,
  pendingPrivateChannelInvite,
} from '@store/feedSlice/channelSelectors';
import { Channel, requestInviteToChannel } from '@store/feedSlice';
import { hasPermissions } from '@store/subscriberSlice/selectors';
import { leaveChat as leaveChatModal } from '@components/modal/dux';
import { getOtherSubscribersNames } from '@store/feedSlice/chatSelectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { isChatEnabled } from '@store/serviceSlice/selectors';
import { ChannelType } from '../../../../../__generated__/globalTypes';
import { sendPaneViewMetric, setModal } from '@store/uiSlice';
import Pubnub from 'pubnub';
import { Pane as IPane, PaneType } from '../../dux';
import { getPane } from '@store/uiSlice/selectors';

interface StateProps {
  channel: Channel;
  otherSubscribersNickname: string;
  pendingPrayer: boolean;
  pendingInvite: boolean;
  hostViewingAsHost: boolean;
  isChatEnabled: boolean;
  isLoading: boolean;
  isConnected: boolean;
  connectionStatusChangedAt?: string;
  pane: IPane;
}

interface OwnProps {
  channelKey: string;
  isGuest: boolean;
  guestMediumUp?: boolean;
  paneId?: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const { channelKey } = ownProps;
  const channel = getChannelByKey(state, channelKey) || {};
  const otherSubscribersNickname = getOtherSubscribersNames(state, channelKey)?.join(', ') || '';
  const hostViewingAsHost = hasPermissions(state, ['feed.host.read', 'feed.host.write']);
  const publicChannel = getPublicChannel(state);
  const momentGroupChannel = getMomentChannelGroup(state);
  const pane = getPane(state);
  const isLoading =
    channel?.type === ChannelType.public
      ? publicChannel?.loading ||
        momentGroupChannel?.loading ||
        !publicChannel ||
        !momentGroupChannel
      : !!channel?.loading;

  const isConnected =
    channel.connectionStatus === Pubnub.CATEGORIES.PNConnectedCategory ||
    channel.connectionStatus === Pubnub.CATEGORIES.PNNetworkUpCategory ||
    channel.connectionStatus === Pubnub.CATEGORIES.PNReconnectedCategory;
  const connectionStatusChangedAt = channel.statusChangedAt;

  return {
    channel,
    otherSubscribersNickname,
    hostViewingAsHost,
    pendingPrayer: pendingPrayer(state, channelKey),
    pendingInvite: pendingPrivateChannelInvite(state, channelKey),
    isChatEnabled: channel.type === 'public' ? isChatEnabled(state) : true,
    isLoading,
    isConnected: isConnected,
    connectionStatusChangedAt: connectionStatusChangedAt,
    pane,
    ...ownProps,
  };
};

interface DispatchProps {
  leaveChannel: () => void;
  inviteHost: (id: string) => void;
  sendPaneViewMetricEvent: (pane_id: number, pane_type: PaneType, channel_id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  leaveChannel: () => dispatch(setModal(leaveChatModal())),
  inviteHost: (id: string) => dispatch(requestInviteToChannel(id)),
  sendPaneViewMetricEvent: (pane_id, pane_type, channel_id) =>
    dispatch(sendPaneViewMetric({ pane_id, pane_type, channel_id })),
});

const VisibleChat = connect(mapStateToProps, mapDispatchToProps)(Chat);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleChat;
