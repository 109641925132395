import { RequestPrayerInput } from '../../../../__generated__/globalTypes';
import { Action, ActionWithPayload } from '../../../types';
import { ModalType } from '../dux';
import { v4 as uuidv4 } from 'uuid';

export const PUBLISH_REQUEST_PRAYER = 'PUBLISH_REQUEST_PRAYER';
export const CANCEL_REQUEST_PRAYER = 'CANCEL_PRAYER_REQUEST';

// Modal
export interface RequestPrayer {
  type: ModalType.REQUEST_PRAYER;
}

export const requestPrayer = (): RequestPrayer => ({
  type: ModalType.REQUEST_PRAYER,
});

// Redux
export type PublishRequestPrayer = ActionWithPayload<
  typeof PUBLISH_REQUEST_PRAYER,
  RequestPrayerInput
>;

export const publishRequestPrayer = (
  input: Omit<RequestPrayerInput, 'id'>
): PublishRequestPrayer => ({
  type: PUBLISH_REQUEST_PRAYER,
  payload: {
    ...input,
    id: uuidv4(),
  },
});

type CancelRequestPrayer = Action<typeof CANCEL_REQUEST_PRAYER>;

export const cancelRequestPrayer = (): CancelRequestPrayer => ({
  type: CANCEL_REQUEST_PRAYER,
});
