import React from 'react';
import { Avatar, Subscriber } from '../Avatar/Avatar';
import { AvatarSizeType } from '../Avatar/types';
import { AvatarContainer } from './AvatarContainer';
import { Container } from './Container';

type Props = { subscribers: Subscriber[] };

function FiveHostLayout({ subscribers }: Props) {
  return (
    <Container height='64px' width='188px'>
      <AvatarContainer top='33px' left='0px' size={AvatarSizeType.XSMALL}>
        <Avatar size={AvatarSizeType.XSMALL} subscriber={subscribers[0]} />
      </AvatarContainer>

      <AvatarContainer top='0px' left='18px' size={AvatarSizeType.SMALL}>
        <Avatar size={AvatarSizeType.SMALL} subscriber={subscribers[1]} />
      </AvatarContainer>

      <AvatarContainer top='7px' left='53px' size={AvatarSizeType.LARGE}>
        <Avatar size={AvatarSizeType.LARGE} subscriber={subscribers[2]} />
      </AvatarContainer>

      <AvatarContainer top='24px' left='115px' size={AvatarSizeType.MEDIUM}>
        <Avatar size={AvatarSizeType.MEDIUM} subscriber={subscribers[3]} />
      </AvatarContainer>

      <AvatarContainer top='6px' left='156px' size={AvatarSizeType.SMALL}>
        <Avatar size={AvatarSizeType.SMALL} subscriber={subscribers[4]} />
      </AvatarContainer>
    </Container>
  );
}

export { FiveHostLayout };
