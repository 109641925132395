import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { validEmail, isValidNickname } from '@utils/core';
import { Props } from './index';
import styled from 'styled-components';
import { FieldErrorType } from '../../../types';
import {
  Box,
  Checkbox,
  defaultColors,
  FacebookButton,
  FacebookLogoIcon,
  FancyInput,
  Flex,
  IconSize,
  LinkButton,
  Modal,
  ModalSize,
  OHeaderThree,
  OParagraph,
  PrimaryButton,
} from '@lifechurch/react-ion';
import { EmailConsentType } from '../../../../__generated__/globalTypes';
import { Divider } from '../login/styles';
import PasswordStrength from '../../PasswordStrength';
import { AvatarWrapper, ScreenReaderText } from './styles';
import TemporaryAvatar from './temporaryAvatar';
import { v4 as uuidv4 } from 'uuid';

type SignupState = {
  email: string;
  password: string;
  nickname: string;
  emailConsent: boolean;
  acceptTermsAndPrivacy: boolean;
  avatar?: string;
};

const StyledOParagraph = styled(OParagraph)`
  justify-self: flex-start;
  color: ${props => props.theme.colors.gray50};
`;

const InlineLink = styled.a`
  color: ${props => props.theme.colors.gray100};
  text-decoration: underline;
`;

const isValidEmail = (email: string): boolean => email !== '' && validEmail(email);

const Signup: FunctionComponent<Props> = ({
  closeModal,
  error,
  currentSubscriber,
  nicknameUnavailable,
  emailUnavailable,
  saveSubscriber,
  imageTooLargeError,
  updateSettingsError,
  signUpCancel,
  signUpError,
  login,
  organization,
  hasFacebookLogin = false,
  signUpSource,
}) => {
  const { t } = useTranslation(['forms', 'common']);
  const { termsUrl, privacyUrl } = organization;
  const nicknameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const termsAndPrivacyRef = useRef<HTMLInputElement>(null);

  const emailConsent =
    organization.emailConsent === EmailConsentType.ALL ||
    (currentSubscriber.gdpr && organization.emailConsent === EmailConsentType.EU) ||
    false;

  const [signUpId] = useState<string>(uuidv4());

  const dismissModal = () => {
    signUpCancel(signUpId);
    closeModal();
  };

  const [values, setValues] = useState<SignupState>({
    email: '',
    password: '',
    nickname: currentSubscriber.nickname || '',
    emailConsent: !emailConsent,
    acceptTermsAndPrivacy: false,
    avatar: '',
  });

  const [errors, setErrors] = useState({
    emailBlank: false,
    passwordBlank: false,
    emailInvalid: emailUnavailable,
    nicknameInvalid: false,
    nicknameLong: false,
    nicknameShort: false,
    failedToAcceptTerms: false,
  });

  const [temporaryAvatar, setTemporaryAvatar] = useState<string | undefined>('');

  const handleFacebookLogin = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `${window.location.origin}/auth/facebook`;
  };

  const onChange = (event: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    switch (name) {
      case 'email':
        setErrors({ ...errors, emailBlank: false, emailInvalid: false });
        break;
      case 'password':
        setErrors({ ...errors, passwordBlank: false });
        break;
      case 'nickname':
        setErrors({
          ...errors,
          nicknameInvalid: value.trim() === '',
          nicknameLong: value.trim() === '',
          nicknameShort: value.trim() === '',
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    if (name === 'acceptTermsAndPrivacy') {
      setErrors({ ...errors, failedToAcceptTerms: false });
    }
    setValues({ ...values, [name]: checked });
  };

  const goSaveSubscriber = () => {
    saveSubscriber({
      id: currentSubscriber.id,
      input: {
        id: currentSubscriber.id,
        nickname: values.nickname.trim(),
        email: values.email,
        password: values.password,
        emailConsent: values.emailConsent,
        avatar: values.avatar,
      },
      signUpId,
      signUpSource,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password, nickname, acceptTermsAndPrivacy } = values;
    const validInput = isValidEmail(email) && password.length >= 8 && isValidNickname(nickname);
    if (validInput && (acceptTermsAndPrivacy || (!termsUrl && !privacyUrl))) {
      goSaveSubscriber();
    } else {
      handleErrorSubmit();
    }
  };

  const handleErrorSubmit = () => {
    const { email, password, nickname, acceptTermsAndPrivacy } = values;

    setErrors({
      ...errors,
      emailBlank: email === '',
      emailInvalid: !isValidEmail(email),
      passwordBlank: password.length < 8,
      nicknameInvalid: !isValidNickname(nickname),
      nicknameLong: nickname.trim().length > 30,
      nicknameShort: nickname.trim().length < 3,
      failedToAcceptTerms: !acceptTermsAndPrivacy,
    });

    let nicknameError: FieldErrorType | null = null;
    let emailError: FieldErrorType | null = null;
    let passwordError: FieldErrorType | null = null;

    if (nickname.trim() === '') {
      nicknameError = FieldErrorType.BLANK;
    } else if (!isValidNickname(nickname)) {
      nicknameError = FieldErrorType.INVALID;
    }
    if (email === '') {
      emailError = FieldErrorType.BLANK;
    } else if (!validEmail(email)) {
      emailError = FieldErrorType.INVALID;
    }
    if (password === '') {
      passwordError = FieldErrorType.BLANK;
    } else if (password.length < 8) {
      passwordError = FieldErrorType.INVALID;
    }

    if (!isValidNickname(nickname)) {
      if (nicknameRef.current) {
        nicknameRef.current.focus();
      }
    } else if (email === '' || !validEmail(email)) {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    } else if (password === '' || password.length < 8) {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    } else if (!acceptTermsAndPrivacy) {
      if (termsAndPrivacyRef.current) {
        termsAndPrivacyRef.current.focus();
      }
    }

    signUpError(signUpId, nicknameError, emailError, passwordError, !acceptTermsAndPrivacy);
  };

  useEffect(() => {
    if (nicknameUnavailable) {
      if (nicknameRef.current) {
        nicknameRef.current.focus();
      }
    } else if (emailUnavailable) {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }
  }, [emailUnavailable, nicknameUnavailable]);

  const openLoginModal = () => {
    signUpCancel(signUpId);
    login();
  };

  const handleLoginKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      openLoginModal();
    }
  };

  const avatarPicked = (newAvatar?: string) => {
    setValues({ ...values, avatar: newAvatar });
    setTemporaryAvatar(newAvatar);
  };

  const getNicknameAriaLabel = () => {
    if (nicknameUnavailable) {
      return t('signup.unavailable_chat_name');
    }
    if (errors.nicknameInvalid) {
      if (errors.nicknameShort) {
        return t('signup.short_chat_name');
      }
      if (errors.nicknameLong) {
        return t('signup.long_chat_name');
      }
    }
    return t('signup.chat_name') + t('signup.required');
  };

  const getEmailAriaLabel = () => {
    if (emailUnavailable) {
      return t('signup.unavailable_email');
    }
    if (errors.emailBlank) {
      return t('signup.blank_email');
    }
    if (errors.emailInvalid) {
      return t('signup.invalid_email');
    }
    return t('signup.email') + t('signup.required');
  };

  const nickname: string = currentSubscriber.nickname ?? '';
  const id: string = currentSubscriber.id ?? undefined;

  return (
    <Modal
      dismiss={dismissModal}
      size={ModalSize.NARROW}
      testId='signup-modal'
      ariaLabel='Sign Up Modal'
    >
      <OHeaderThree marginBlockEnd={5}>{t('signup.title')}</OHeaderThree>
      {hasFacebookLogin && (
        <>
          <FacebookButton
            onClick={handleFacebookLogin}
            data-testid='login-facebook'
            marginInlineEnd={5}
            marginBlockEnd={5}
          >
            <Flex alignItems='center' marginBlockStart={2} marginBlockEnd={2}>
              <Flex alignItems='center' marginInlineEnd={3}>
                <FacebookLogoIcon size={IconSize.XL} color={defaultColors.white} />
              </Flex>
              {t('login.facebook')}
            </Flex>
          </FacebookButton>
          <Divider marginBlockEnd={5}>{t('login.or_divider')}</Divider>
        </>
      )}
      <AvatarWrapper>
        <TemporaryAvatar
          subscriber={{ id, avatar: temporaryAvatar, nickname }}
          onImageUpload={avatarPicked}
          imageTooLargeError={imageTooLargeError}
          updateSettingsError={updateSettingsError}
        />
      </AvatarWrapper>
      <form onSubmit={handleSubmit}>
        <Box marginBlockEnd={5}>
          <FancyInput
            aria-invalid={nicknameUnavailable || errors.nicknameInvalid ? true : false}
            aria-label={getNicknameAriaLabel()}
            data-testid='signup-nicknameField'
            error={errors.nicknameInvalid || nicknameUnavailable || error}
            hint={(() => {
              if (nicknameUnavailable) {
                return t('signup.unavailable_chat_name');
              }
              if (errors.nicknameInvalid) {
                if (errors.nicknameShort) {
                  return t('signup.short_chat_name');
                }
                if (errors.nicknameLong) {
                  return t('signup.long_chat_name');
                }
              }
              return null;
            })()}
            id='nickname'
            label={t('signup.chat_name')}
            name='nickname'
            onChange={onChange}
            ref={nicknameRef}
            required
            type='text'
            value={values.nickname}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <FancyInput
            type='email'
            name='email'
            id='email'
            data-testid='signup-emailField'
            value={values.email}
            required
            label={t('signup.email')}
            onChange={onChange}
            ref={emailRef}
            aria-label={getEmailAriaLabel()}
            aria-invalid={
              emailUnavailable || errors.emailBlank || errors.emailInvalid ? true : false
            }
            error={errors.emailBlank || errors.emailInvalid || emailUnavailable || error}
            hint={(() => {
              if (emailUnavailable) {
                return t('signup.unavailable_email');
              }
              if (errors.emailBlank) {
                return t('signup.blank_email');
              }
              if (errors.emailInvalid) {
                return t('signup.invalid_email');
              }
              return null;
            })()}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <FancyInput
            type='password'
            name='password'
            id='password'
            data-testid='signup-passwordField'
            value={values.password}
            required
            label={t('signup.password')}
            onChange={onChange}
            ref={passwordRef}
            aria-label={
              errors.passwordBlank
                ? t('password_strength.password_short')
                : t('signup.password') + t('signup.required')
            }
            aria-invalid={errors.passwordBlank ? true : false}
            error={errors.passwordBlank || error}
          />
          <PasswordStrength password={values.password} />
        </Box>
        {emailConsent && (
          <Box marginBlockEnd={5}>
            <Checkbox
              name='emailConsent'
              data-testid='signup-emailConsent'
              onChange={onCheckboxChange}
            >
              <Trans ns='forms' i18nKey='singup.gdpr'>
                {{ churchName: organization.name }} can send me occasional emails.
              </Trans>
            </Checkbox>
          </Box>
        )}
        {(termsUrl || privacyUrl) && (
          <Checkbox
            name='acceptTermsAndPrivacy'
            data-tid='signup-acceptTermsAndPrivacy'
            onChange={onCheckboxChange}
            ref={termsAndPrivacyRef}
            data-testid='privacyTermsCheckBox'
            error={errors.failedToAcceptTerms}
            hint={errors.failedToAcceptTerms ? t('signup.must_accept_tos_pp') : null}
          >
            <>
              <Trans i18nKey='signup.tos_and_pp'>
                I agree to the{' '}
                {termsUrl && (
                  <InlineLink href={termsUrl} target='_blank' rel='noopener noreferrer'>
                    Terms of Service
                  </InlineLink>
                )}
                {termsUrl && privacyUrl && ' and '}
                {privacyUrl && (
                  <InlineLink href={privacyUrl} target='_blank' rel='noopener noreferrer'>
                    Privacy Policy
                  </InlineLink>
                )}
                .*
              </Trans>
              <ScreenReaderText>{t('signup.required')}</ScreenReaderText>
            </>
          </Checkbox>
        )}
        <Flex alignItems='center' justifyContent='space-between' style={{ marginBlockStart: 6 }}>
          <StyledOParagraph>* {t('signup.required')}</StyledOParagraph>
          <Flex>
            <LinkButton
              onClick={openLoginModal}
              onKeyDown={handleLoginKeyDown}
              marginInlineEnd={5}
              tabIndex={0}
            >
              {t('common:log_in')}
            </LinkButton>
            <PrimaryButton onClick={handleSubmit} type='submit'>
              {t('signup.sign_up')}
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export default Signup;
