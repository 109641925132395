import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, ButtonSize, IconSize, OSmallParagraph, FacebookLogoIcon, } from '@lifechurch/react-ion';
import { ShareButtonContainer } from '../share-button-container';
const colorFbBlue = '#1877f2';
const FacebookButton = styled(PrimaryButton) `
  background-color: ${colorFbBlue};

  &:hover {
    background-color: ${colorFbBlue};
  }
`;
const FacebookShareButton = ({ url, size = ButtonSize.SM, padding = '8px !important', iconSize = IconSize.XL }, props) => {
    // TODO: make sure this is the correct/preferred implementation of sharing to Facebook
    const shareFacebook = () => {
        const shareUrl = `https://www.facebook.com/sharer.php?u=${url}`;
        window.open(shareUrl, '_blank');
    };
    return (React.createElement(ShareButtonContainer, { flexDirection: 'column', alignItems: 'center' },
        React.createElement(FacebookButton, { "aria-label": 'Facebook', "data-testid": 'facebook', onClick: url ? shareFacebook : null, padding: padding, size: size, ...props },
            React.createElement(FacebookLogoIcon, { size: iconSize, color: 'white' })),
        React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Facebook")));
};
export { FacebookShareButton };
