import Xlarge from './xlarge';
import { connect } from 'react-redux';
import { getHostChannel } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';
import { shouldShowVideo } from '@store/serviceSlice/selectors';
import { isLargeHostVideo } from '@store/uiSlice/selectors';

interface StateProps {
  hasVideo: boolean;
  hostChannel: string;
  largeHostVideo: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const hostChannel = getHostChannel(state)?.key || '';
  const hasVideo = shouldShowVideo(state);
  const largeHostVideo = isLargeHostVideo(state);

  return {
    hasVideo,
    hostChannel,
    largeHostVideo,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Xlarge);
