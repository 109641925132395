import { connect } from 'react-redux';
import { leaveChannel, Channel } from '@store/feedSlice';
import { getCurrentChannel } from '@store/feedSlice/channelSelectors';
import { getOtherSubscribers, hasOtherSubscribers } from '@store/feedSlice/chatSelectors';
import LeaveChat from './leaveChat';
import { PublicSubscriber } from '../../../types';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface StateProps {
  otherSubscriber: PublicSubscriber;
  hasOtherSubscribers: boolean;
  channel: Channel;
}

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const channel = getCurrentChannel(state);
  return {
    otherSubscriber: getOtherSubscribers(state, channel?.key)[0],
    hasOtherSubscribers: hasOtherSubscribers(state, channel?.key),
    channel,
    ...ownProps,
  };
};

interface DispatchProps {
  leaveChannel: (channel: Channel) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  leaveChannel: (channel: Channel) => dispatch(leaveChannel(channel)),
});

const VisibleLeaveChat = connect(mapStateToProps, mapDispatchToProps)(LeaveChat);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleLeaveChat;
