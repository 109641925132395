import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { ComboButtonItem, BaseComboButtonProps } from './ComboButtonItem';

type ComboButtonProps = PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

const ComboButton = ({ children, ...rest }: ComboButtonProps): ReactElement => {
  return <Container {...rest}>{children}</Container>;
};

const Container = styled.div`
  ${ComboButtonItem}:first-child {
    border-radius: 50px 0 0 50px;
    margin-inline-end: 1px;

    [dir='rtl'] & {
      border-radius: 0 50px 50px 0;
    }

    &:focus {
      &:before {
        background: none;
        border: 1px solid #fff;
        border-radius: 50px 0 0 50px;
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        pointer-events: none;

        [dir='rtl'] & {
          border-radius: 0 50px 50px 0;
        }
      }
    }
  }
  ${ComboButtonItem}:last-child {
    border-radius: 0 50px 50px 0;
    margin-inline-start: 1px;

    [dir='rtl'] & {
      border-radius: 50px 0 0 50px;
    }

    &:focus {
      &:before {
        background: none;
        border: 1px solid #fff;
        border-radius: 0 50px 50px 0;
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        pointer-events: none;

        [dir='rtl'] & {
          border-radius: 50px 0 0 50px;
        }
      }
    }
  }
`;

export { ComboButton, BaseComboButtonProps };

ComboButton.Item = ComboButtonItem;
