import { connect } from 'react-redux';
import ChopContainer from './App';
import { setModalFromType, ModalType } from '@components/modal/dux';
import { getCurrentSubscriber, hasPermissions } from '@store/subscriberSlice/selectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { shouldShowVideo } from '@store/serviceSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { OrganizationStatus } from '../../__generated__/globalTypes';
import { handleExternalAuth } from '@store/subscriberSlice';
import { AuthStatus } from '@io/auth';
import { AlertSubtype, setAlertFromUrl } from '@components/Alert/dux';
import { setModal } from '@store/uiSlice';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';

interface StateProps {
  organizationName: string;
  organizationStatus: OrganizationStatus;
  organizationExists: boolean;
  organizationFavicon: string | null;
  hasVideo: boolean;
  hostViewingAsHost: boolean;
  currentSubscriberId: string;
}

const mapStateToProps = (state: RootState): StateProps => {
  const organization = getCurrentOrganization(state);
  const hasVideo = shouldShowVideo(state);
  const organizationExists = state.ui.serviceFetched ? organization.id !== '' : true;
  const currentSubscriberId = getCurrentSubscriber(state).id;

  return {
    organizationExists,
    organizationName: organization.name,
    organizationStatus: organization.status,
    organizationFavicon: organization.appearance.favicon,
    hasVideo,
    hostViewingAsHost:
      hasPermissions(state, ['feed.host.read', 'feed.host.write']) && isHostViewingAsHost(state),
    currentSubscriberId,
  };
};

interface DispatchProps {
  setModalFromUrl: (modal: ModalType) => void;
  setAlertFromUrl: (alert: AlertSubtype) => void;
  handleAuth: (authStatus: AuthStatus, currentSubscriberId: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setModalFromUrl: (modal: ModalType) => dispatch(setModal(setModalFromType(modal))),
  setAlertFromUrl: (alert: AlertSubtype) => dispatch(setAlertFromUrl(alert)),
  handleAuth: (authStatus: AuthStatus, currentSubscriberId: string) =>
    dispatch(handleExternalAuth({ status: authStatus }, { currentSubscriberId })),
});

export type Props = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChopContainer);
