import { all, select, call, put, delay } from 'redux-saga/effects';
import { setStepData, popStep, StepData } from '@store/sequenceSlice';
import {
  hasSequence,
  getNextFetchTime,
  getNextTransitionTime,
  getServerTime,
  getNextData,
} from '@store/sequenceSlice/selectors';
import { popSchedule, setSchedule } from '@store/scheduleSlice';
import { getNextStartTime } from '@store/scheduleSlice/selectors';
import queries from '../queries';
import { serviceAt, sequence } from './currentService';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import { FetchResult } from '@apollo/client';
import { ServicesConnectionQuery } from '../__generated__/ServicesConnectionQuery';
import { ServiceAtQuery } from '../__generated__/ServiceAtQuery';
import { SequenceQuery } from '../__generated__/SequenceQuery';

export const getDelayTime = (
  time: string | number | Date,
  _serverTimeSeconds: string | number | Date
): number => {
  return dayjs(time).diff(dayjs(), 'millisecond');
};

export function* getNextService() {
  try {
    yield put(popSchedule());
    const startTime: string = yield select(getNextStartTime);
    if (startTime === '') {
      const result: FetchResult<ServicesConnectionQuery> = yield call([queries, queries.schedule]);
      const services = result.data?.currentOrganization?.servicesConnection?.services;
      if (services) {
        const currentTime = dayjs();
        const futureScheduleServices = services.filter(service => {
          if (service?.endTime || service?.scheduleTime) {
            return dayjs(service?.endTime || service?.scheduleTime).isAfter(currentTime);
          } else {
            Sentry.withScope(scope => {
              if (service.id) {
                scope.setTag('service_id', service.id);
              }
              Sentry.captureMessage(
                'Scheduled service has either, startTime, endTime or scheduleTime as null'
              );
            });
            return false;
          }
        });

        yield put(setSchedule(futureScheduleServices));
      }
    } else {
      const result: FetchResult<SequenceQuery> = yield call([queries, queries.sequence], startTime);
      if (result.data?.serviceAt) {
        yield call(sequence, result.data?.serviceAt.sequence);
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* checkForSequence() {
  const queryNewSequence: boolean = yield select(hasSequence);
  if (!queryNewSequence) {
    yield call(getNextService);
  }
}

export function* fetchNextState() {
  try {
    const fetchTime: string | undefined = yield select(getNextFetchTime);
    const serverTime: string | undefined = yield select(getServerTime);
    if (fetchTime && serverTime) {
      const delayTime = getDelayTime(fetchTime, serverTime);
      yield delay(delayTime);
      const transitionTime: string | undefined = yield select(getNextTransitionTime);
      if (transitionTime) {
        const results: FetchResult<ServiceAtQuery> = yield call(
          [queries, queries.serviceAtTime],
          transitionTime
        );
        if (results.data?.serviceAt) {
          yield put(setStepData(results.data.serviceAt));
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* transitionSequence() {
  try {
    const transitionTime: string | undefined = yield select(getNextTransitionTime);
    const serverTime: string = yield select(getServerTime);
    if (transitionTime && serverTime) {
      const delayTime = getDelayTime(transitionTime, serverTime);
      yield delay(delayTime);
      let data: StepData | null = yield select(getNextData);
      if (data === undefined) {
        const result: FetchResult<ServiceAtQuery> = yield call(
          [queries, queries.serviceAtTime],
          transitionTime
        );
        if (result.data?.serviceAt) {
          data = result.data.serviceAt;
        }
      }
      // @ts-expect-error
      yield call(serviceAt, data);
      yield put(popStep());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* startSequence() {
  // t0 and t1 are used for preventing a fast iterating loop.
  let t0;
  let t1;
  do {
    t0 = performance.now();
    yield call(checkForSequence);
    yield all([call(fetchNextState), call(transitionSequence)]);
    t1 = performance.now();
  } while (t1 - t0 > 3000);
}
