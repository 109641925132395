import { connect } from 'react-redux';
import MediaHeader from './mediaHeader';
import { RootState } from '@store/rootReducer';
import { getCurrentService } from '@store/serviceSlice/selectors';
import { getPublicChannelOccupancy } from '@store/feedSlice/channelSelectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { isOffline } from '@store/serviceSlice/selectors';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';

interface StateProps {
  endTime: string | null;
  channelOccupancy: number;
  isGuest: boolean;
  hideViewershipMetric: boolean;
  isOffline: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const currentService = getCurrentService(state);
  const channelOccupancy = getPublicChannelOccupancy(state);
  const isGuest = !isHostViewingAsHost(state);
  const { appearance } = getCurrentOrganization(state);
  const hideViewershipMetric = appearance?.hideViewershipMetric ?? false;

  return {
    endTime: currentService?.endTime || null,
    channelOccupancy,
    isGuest,
    hideViewershipMetric,
    isOffline: isOffline(state),
  };
};

const VisibleMediaHeader = connect(mapStateToProps)(MediaHeader);

export type Props = StateProps;
export default VisibleMediaHeader;
