export enum AuthStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  NEW = 'new',
}

const getAuthStatus = (): AuthStatus | null => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('auth-status')) {
    // @ts-expect-error - string can't index typeof AuthStatus
    return AuthStatus[searchParams.get('auth-status')?.toUpperCase() || ''];
  } else {
    return null;
  }
};

export { getAuthStatus };
