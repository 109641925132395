import { RootState } from '@store/rootReducer';
import { isReactionBannerVisible } from '@store/uiSlice/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ReactionBanner from './reactionBanner';
import { closeReactionBanner } from '@store/uiSlice';

interface StateProps {
  isVisible: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isVisible: isReactionBannerVisible(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeReactionBanner }, dispatch);

const VisibleReactionBanner = connect(mapStateToProps, mapDispatchToProps)(ReactionBanner);

export type Props = StateProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleReactionBanner;
