import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray5};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: 24px;
  padding-block-end: 16px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  border-radius: 4px 4px 0px 0px;

  input {
    margin-block-start: 16px;
    margin-block-end: 8px;
  }
`;

export const FieldWrapper = styled.div`
  padding-block-start: 16px;
  padding-block-end: 24px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  @media screen and (min-width: 768px) {
    padding: 24px;
  }
`;

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
