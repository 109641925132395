import { SpaceProps } from 'styled-system';
import { LogicalProps } from '../logical';

export interface IconProps extends SpaceProps, LogicalProps {
  ariaLabelledBy?: string;
  color?: string;
  hoverColor?: string;
  size?: IconSize;
}

export enum IconSize {
  SM = 15,
  MD = 20,
  LG = 24,
  XL = 32,
}
