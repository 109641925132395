import React, { forwardRef, ReactNode, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import {
  border,
  BorderProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  display,
  DisplayProps,
  compose,
  variant,
} from 'styled-system';
import { InputWrapper, Suffix, Prefix } from './Affix';
import { OSmallParagraph, OXSmallParagraph } from './Typography';
import { ReadOnlyButton } from './ReadOnlyButton';
import { InputIconWrapper } from './InputIconWrapper';
import { logical, LogicalProps } from './logical';

export enum InputSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

// There are conflicting TS types, so we need to omit some to pass typechecking
type NativeInput = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'aria-relevant'>;

export interface InputProps
  extends NativeInput,
    BorderProps,
    SpaceProps,
    LogicalProps,
    TypographyProps {
  size?: InputSize;
  label?: string;
  context?: ReactNode;
  valid?: boolean;
  error?: boolean;
  hint?: ReactNode;
  focused?: boolean;
  prefix?: string;
  suffix?: string;
  hasCopy?: boolean;
  icon?: ReactNode;
}

type BaseInputProps = {
  valid?: boolean;
  error?: boolean;
  focused?: boolean;
  inputSize: InputSize;
  prefix?: string;
  suffix?: string;
  iconPadding?: boolean;
} & BorderProps &
  SpaceProps &
  LogicalProps &
  TypographyProps;

const inputSizeVariant = variant({
  prop: 'inputSize',
  variants: {
    sm: {
      fontSize: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 5,
      paddingBlockEnd: 5,
      minHeight: '32px',
    },
    md: {
      fontSize: 3,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 7,
      paddingBlockEnd: 7,
      minHeight: '40px',
    },
    lg: {
      fontSize: 4,
      paddingInlineStart: 16,
      paddingInlineEnd: 16,
      paddingBlockStart: 11,
      paddingBlockEnd: 12,
      minHeight: '56px',
    },
  },
});

export const BaseInput = styled.input<BaseInputProps>`
  display: block;
  width: 100%;
  border: 1px solid ${(props): string => props.theme.colors.gray30};
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  overflow: hidden;
  margin: 0;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;

  &:disabled {
    background-color: ${(props): string => props.theme.colors.gray10};
    cursor: not-allowed;
    -moz-appearance: textfield;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-moz-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &:focus:not(:read-only) {
    border-color: ${(props): string => props.theme.colors.action.default};
    background-color: white;
  }

  border-color: ${(props): string => {
    if (props.focused) {
      return props.theme.colors.action.default;
    } else if (props.error) {
      return props.theme.colors.error.default;
    } else if (props.valid) {
      return props.theme.colors.success.default;
    } else {
      return props.theme.colors.gray30;
    }
  }};
  ${compose(border, space, logical, typography, inputSizeVariant)};

  ${props =>
    props.iconPadding &&
    css`
      padding-inline-end: 42px;
    `}

  border-radius: 4px;
  ${props =>
    props.prefix &&
    css`
      border-top-left-radius: ${props => props.theme.radii[0]};
      border-bottom-left-radius: ${props => props.theme.radii[0]};
      [dir='rtl'] &&& {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: ${props => props.theme.radii[0]};
        border-bottom-right-radius: ${props => props.theme.radii[0]};
      }
    `}
  ${props =>
    props.suffix &&
    css`
      border-top-right-radius: ${props => props.theme.radii[0]};
      border-bottom-right-radius: ${props => props.theme.radii[0]};
      [dir='rtl'] &&& {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
        border-top-left-radius: ${props => props.theme.radii[0]};
        border-bottom-left-radius: ${props => props.theme.radii[0]};
      }
    `}
`;

BaseInput.defaultProps = {
  inputSize: InputSize.MD,
  type: 'text',
};

type StyledLabelProps = { size: InputSize; required?: boolean } & BorderProps &
  SpaceProps &
  LogicalProps &
  TypographyProps &
  DisplayProps;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props): string =>
    props.size === InputSize.LG ? props.theme.fontSizes[3] : props.theme.fontSizes[2]};
  display: inline-block;
  &[required]::after {
    content: '*';
  }
  ${compose(border, space, logical, typography, display)}
`;

interface StyledContextProps {
  size: InputSize;
  color?: string;
  children: ReactNode;
}

export const StyledContext: FunctionComponent<StyledContextProps> = ({
  size,
  children,
  color = 'gray50',
}) => {
  const ContextWrapper = size === InputSize.LG ? OSmallParagraph : OXSmallParagraph;
  return (
    <ContextWrapper color={color} marginBlockStart={2}>
      {children}
    </ContextWrapper>
  );
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      value = '',
      size = InputSize.MD,
      type = 'text',
      label,
      placeholder,
      context,
      valid,
      error,
      hint,
      disabled,
      focused,
      required,
      icon,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <StyledLabel
            htmlFor={name}
            size={size}
            fontWeight={2}
            marginBlockEnd={1}
            lineHeight={2}
            required={required}
          >
            {label}
          </StyledLabel>
        )}
        <InputWrapper>
          {props.prefix && <Prefix inputSize={size}>{props.prefix}</Prefix>}
          <BaseInput
            ref={ref}
            id={name}
            inputSize={size}
            name={name}
            type={type}
            placeholder={placeholder}
            valid={valid}
            error={error}
            disabled={disabled}
            required={required}
            value={value}
            iconPadding={props.hasCopy || !!icon}
            focused={focused}
            {...props}
          />
          {props.suffix && <Suffix inputSize={size}>{props.suffix}</Suffix>}
          {props.readOnly && props.hasCopy && <ReadOnlyButton value={value} />}
          {icon && <InputIconWrapper>{icon}</InputIconWrapper>}
        </InputWrapper>
        {hint && (
          <StyledContext size={size} color={error ? 'error.default' : 'success.default'}>
            {hint}
          </StyledContext>
        )}
        {context && <StyledContext size={size}>{context}</StyledContext>}
      </>
    );
  }
);

Input.displayName = 'ForwardedInput';

export { Input };
