import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangePasswordMutation_changePassword_errors } from '@io/__generated__/ChangePasswordMutation';
import { AuthError } from '@io/sagas/auth';
import { changePassword } from '@components/modal/changePassword/dux';
import { BasePanes, ErrorCode, Pane, PaneType } from '@features/pane/dux';
import {
  errorAlert,
  infoAlert,
  signupInAlert,
  textModeAlert,
  AlertInterface,
  AlertSubtype,
  successAlert,
} from '@components/Alert/dux';
import {
  acceptInvite as acceptInviteModal,
  login as loginModal,
  Modal,
  ModalType,
  profileSettings as profileSettingsModal,
  selectChatName as selectChatNameModal,
  signupModal,
} from '../../components/modal/dux';
import { saveSubscriberFailed, saveSubscriberSuccess } from '@store/subscriberSlice';
import defaultLocals from '../../../locales/defaultLocals';
import { checkForDuplicate } from './ui.utils';
import { Metric } from 'web-vitals';
import { FILTERS } from '@features/audience/components/filter';

export interface LanguageOption {
  code: string;
  locale: string;
  name: string;
}

export type GeoData = {
  country_code: string | null;
  region: string | null;
  city: string | null;
  latitude: number | null;
  longitude: number | null;
};

interface InitialState {
  alerts: AlertInterface[];
  audiencePaneFilter: FILTERS | null;
  currentLanguage: string;
  geo: GeoData;
  isHeartbeatStarted: boolean;
  languageOptions: Array<LanguageOption>;
  largeHostVideo: boolean;
  modal?: Modal;
  nav: {
    navMenuExpanded: boolean;
    navbarIndex: number;
  };
  pane: Pane;
  previousModal?: Modal;
  reactionBanner: { isVisible: boolean };
  requestedRefetches: RefetchRequestInterface[];
  serviceFetched: boolean;
  showError: boolean;
  sideMenuOpen: boolean;
  viewingAsUser: boolean | undefined;
}

export enum RefetchQueryType {
  AUDIENCE_TAB = 'AudienceTab',
}

export interface RefetchRequestInterface {
  query: RefetchQueryType;
  requestedAt: string;
}

interface SetLanguage {
  newLanguage: string;
  oldLanguage?: string;
}

export const initialState: InitialState = {
  alerts: [],
  audiencePaneFilter: null,
  currentLanguage: 'en-US',
  geo: {
    country_code: null,
    region: null,
    city: null,
    latitude: null,
    longitude: null,
  },
  isHeartbeatStarted: false,
  languageOptions: defaultLocals,
  largeHostVideo: false,
  modal: undefined,
  nav: {
    navbarIndex: 0,
    navMenuExpanded: true,
  },
  pane: {
    type: PaneType.SERVICE as PaneType.SERVICE,
  },
  previousModal: undefined,
  reactionBanner: {
    isVisible: false,
  },
  requestedRefetches: [],
  serviceFetched: false,
  showError: false,
  sideMenuOpen: false,
  viewingAsUser: undefined,
};

interface ClearAlertByMeta {
  key: string;
  value: string;
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    basicAuthLogin(state) {
      state.modal = loginModal();
    },
    basicAuthLoginFailed(state, action: PayloadAction<AuthError>) {
      state.modal = loginModal({ error: action.payload });
    },
    changePasswordFailed(
      state,
      action: PayloadAction<ChangePasswordMutation_changePassword_errors[]>
    ) {
      state.modal = changePassword({ errors: action.payload });
    },
    clearAlert(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter(alert => alert.uniqueKey !== action.payload);
    },
    clearAlertByMetaValue(state, action: PayloadAction<ClearAlertByMeta>) {
      state.alerts = state.alerts.filter(
        alert => !alert?.meta || alert?.meta[action.payload.key] !== action.payload.value
      );
    },
    clearAlertBySubtype(state, action: PayloadAction<AlertSubtype>) {
      state.alerts = state.alerts.filter(alert => alert.subtype !== action.payload);
    },
    clearModal(state) {
      state.modal = undefined;
      state.previousModal = undefined;
    },
    clearRequestedRefetch(state, action: PayloadAction<RefetchQueryType>) {
      state.requestedRefetches = state.requestedRefetches.filter(
        refetch => refetch.query !== action.payload
      );
    },
    closeReactionBanner(state) {
      state.reactionBanner.isVisible = false;
    },
    closeSideMenu(state) {
      state.sideMenuOpen = false;
    },
    expandHostVideo(state) {
      state.largeHostVideo = true;
    },
    openReactionBanner(state) {
      state.reactionBanner.isVisible = true;
    },
    openSideMenu(state) {
      state.sideMenuOpen = true;
    },
    pushAlert(state, action: PayloadAction<AlertInterface>) {
      state.alerts.unshift(action.payload);
    },
    setAudiencePaneFilter(state, action: PayloadAction<FILTERS | null>) {
      state.audiencePaneFilter = action.payload;
    },
    setGeoData(state, action: PayloadAction<GeoData>) {
      state.geo = action.payload;
    },
    setLanguage(state, action: PayloadAction<SetLanguage>) {
      state.currentLanguage = action.payload.newLanguage;
    },
    setModal(state, action: PayloadAction<Modal>) {
      state.previousModal = state.modal;
      state.modal = action.payload;
    },
    setNavbarIndex(state, action: PayloadAction<number>) {
      state.nav = {
        ...state.nav,
        navbarIndex: action.payload,
      };
    },
    setPane(state, action: PayloadAction<Pane>) {
      state.pane = action.payload;
    },
    setRequestedRefetches(state, action: PayloadAction<RefetchRequestInterface>) {
      const refetchIndex = state.requestedRefetches.findIndex(
        refetch => refetch.query === action.payload.query
      );
      refetchIndex >= 0
        ? (state.requestedRefetches[refetchIndex] = action.payload)
        : state.requestedRefetches.push(action.payload);
    },
    setServiceFetched(state) {
      state.serviceFetched = true;
    },
    shrinkHostVideo(state) {
      state.largeHostVideo = false;
    },
    startHeartbeat(state) {
      state.isHeartbeatStarted = true;
    },
    toggleNavMenuExpanded(state) {
      state.nav.navMenuExpanded = !state.nav.navMenuExpanded;
    },
    updateSignupEmailAvailability(state) {
      const signUpSource =
        state.modal?.type === ModalType.SIGNUP ? state.modal.signUpSource : 'unknown';
      state.modal = signupModal(signUpSource, { emailUnavailable: false });
    },
    updateSignupNicknameAvailability(state) {
      const signUpSource =
        state.modal?.type === ModalType.SIGNUP ? state.modal.signUpSource : 'unknown';
      state.modal = signupModal(signUpSource, { nicknameUnavailable: false });
    },
    viewingAsUser(state, action: PayloadAction<boolean>) {
      state.viewingAsUser = action.payload;
    },
    setShowError(state, action: PayloadAction<boolean>) {
      state.showError = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(saveSubscriberSuccess, (state, action) => {
        // if we saved the subscriber because they just signed up
        // hide the signup model and show the signup banner
        if (action.meta?.guestNickname || action.meta?.keepSubmissionFormAlive) {
          return;
        }

        if (
          state.modal?.type === ModalType.SIGNUP ||
          state.modal?.type === ModalType.ACCEPT_INVITE
        ) {
          state.modal = undefined;
          state.previousModal = undefined;
          state.alerts.unshift(signupInAlert().payload);
          return;
        }

        if (state.modal?.type === ModalType.RESET_PASSWORD) {
          state.modal = undefined;
          state.previousModal = undefined;
          state.alerts.unshift(successAlert('change_password_success').payload);
          return;
        }

        const textModeUpdated = action.payload.preferences?.textMode;
        const SkinToneUpdated = action.payload.preferences?.skinTone;
        if (textModeUpdated) {
          state.alerts.unshift(textModeAlert(textModeUpdated).payload);
        } else if (!SkinToneUpdated) {
          state.alerts.unshift(infoAlert('update_settings_success').payload);
        }
      })
      .addCase(saveSubscriberFailed, (state, action) => {
        // If a modal is open and the error is that the nickname or email are not unique
        if (state.modal) {
          const nicknameUnavailable = checkForDuplicate('nickname', action.payload);
          const emailUnavailable = checkForDuplicate('email', action.payload);

          if (nicknameUnavailable || emailUnavailable) {
            let newModal: Modal;

            if (state.modal?.type === ModalType.SELECT_CHAT_NAME) {
              newModal = selectChatNameModal(state.modal.subtype, {
                ...state.modal.meta,
                nicknameUnavailable,
              });
            } else if (state.modal?.type === ModalType.PROFILE_SETTINGS) {
              newModal = profileSettingsModal({ nicknameUnavailable });
              if (nicknameUnavailable) {
                state.alerts.unshift(errorAlert('update_settings_error').payload);
              }
            } else if (state.modal?.type === ModalType.ACCEPT_INVITE) {
              newModal = acceptInviteModal({
                nicknameUnavailable,
                emailUnavailable,
              });
            } else {
              const signUpSource =
                state.modal?.type === ModalType.SIGNUP ? state.modal.signUpSource : 'unknown';
              newModal = signupModal(signUpSource, {
                nicknameUnavailable,
                emailUnavailable,
              });
            }
            state.modal = newModal;
            return;
          } else {
            state.alerts.unshift(
              errorAlert(
                state.modal.type === ModalType.SIGNUP ? 'signup_error' : 'update_settings_error'
              ).payload
            );
            return;
          }
        }

        state.alerts.unshift(errorAlert('update_settings_error').payload);
      });
  },
});

export const setPaneToChat = (channelKey: string) =>
  setPane({
    type: PaneType.CHAT,
    meta: {
      channelKey,
    },
  });

export const setPaneToType = (type: BasePanes) =>
  setPane({
    type,
  });

export const setPaneToError = (code: ErrorCode) =>
  setPane({
    type: PaneType.ERROR,
    meta: {
      code,
    },
  });

export const heartbeat = createAction<number>('ui/heartbeat');

export const sendSeeOriginalTranslationMetric = createAction<{
  messageId: string;
  originalLanguage: string;
}>('ui/seeOriginalTranslation');

export const sendWebVitalMetric =
  createAction<Pick<Metric, 'id' | 'name' | 'value'>>('ui/sendWebVital');

export const sendPaneViewMetric = createAction<{
  pane_id: number;
  pane_type: PaneType;
  channel_id: string;
}>('ui/sendPaneViewMetric');

export const sendResizeHostVideoMetric = createAction<{
  subscriber_id: string;
  video_size: 'regular' | 'large';
}>('ui/sendResizeHostVideoMetric');

export const {
  basicAuthLogin,
  basicAuthLoginFailed,
  changePasswordFailed,
  clearAlert,
  clearAlertByMetaValue,
  clearAlertBySubtype,
  clearModal,
  clearRequestedRefetch,
  closeReactionBanner,
  closeSideMenu,
  expandHostVideo,
  openReactionBanner,
  openSideMenu,
  pushAlert,
  setAudiencePaneFilter,
  setGeoData,
  setLanguage,
  setModal,
  setNavbarIndex,
  setPane,
  setRequestedRefetches,
  setServiceFetched,
  shrinkHostVideo,
  startHeartbeat,
  toggleNavMenuExpanded,
  updateSignupEmailAvailability,
  updateSignupNicknameAvailability,
  viewingAsUser,
  setShowError,
} = uiSlice.actions;

export type UIState = ReturnType<typeof uiSlice.reducer>;
export default uiSlice.reducer;
