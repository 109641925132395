import React, { FunctionComponent } from 'react';
import { DirectChatAvatar } from '@components/DirectChatAvatar';
import { getFirstInitial } from '@utils/core';
import { CommentIcon, defaultColors } from '@lifechurch/react-ion';

export const emptyNickname = '?';

interface Props {
  isCurrent: boolean;
  nickname: string;
  groupChat?: boolean;
  pulsing?: boolean;
}

const DirectChatIcon: FunctionComponent<Props> = ({ isCurrent, nickname, groupChat, pulsing }) => {
  if (nickname === emptyNickname) {
    return <CommentIcon color={isCurrent ? defaultColors.gray100 : defaultColors.gray50} />;
  }

  return (
    <>
      <DirectChatAvatar
        pulsing={pulsing}
        isCurrent={isCurrent}
        nickname={nickname}
        groupChat={groupChat}
      >
        {getFirstInitial(nickname)}
      </DirectChatAvatar>
    </>
  );
};
export default React.memo(DirectChatIcon);
