import styled, { css, keyframes } from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

interface Props {
  time: number;
  startScale: number;
  insetInlineEnd: number;
}

const moveFade = (props: Props) =>
  keyframes`
  from {
    opacity: 1;
    transform: scale(${props.startScale}) translate(0px, 0px)
  }
`;

export const Wrapper = styled.div<Props>`
  animation: ${props => props.time}s ${moveFade} ease-out;
  width: 20px;
  height: 20px;
  position: absolute;
  ${getInset('insetBlockStart', '-25px')};
  ${props => css`
    [dir='rtl'] &&& {
      left: ${props.insetInlineEnd}px;
    }
    [dir='ltr'] &&& {
      right: ${props.insetInlineEnd}px;
    }
  `}
  animation-play-state: running;
`;
