import React, { useState } from 'react';
import CTAMoment from '../cta';
import { Box, Flex, OParagraph } from '@lifechurch/react-ion';
import { CTAButton } from '../components/button';
import { Pulse } from './pulse';
import { ResponseCount } from './count';
import { Symbol } from './symbol';
import styled from 'styled-components';
const TextWrapper = styled(OParagraph) `
  word-break: break-word;
`;
const ResponseMoment = ({ onDismiss, text, buttonText, symbol, onResponse, count, isPinned: defaultIsPinned = false, hasSubmitted: defaultHasSubmitted, tabIndex = 0, }) => {
    const hasCount = count > 0;
    const [hasSubmitted, setHasSubmitted] = useState(defaultHasSubmitted);
    const [isPinned, setIsPinned] = useState(defaultIsPinned);
    const [pulse, setPulse] = useState(false);
    const handleResponse = () => {
        setHasSubmitted(true);
        setPulse(true);
        onResponse();
    };
    const handleDismiss = () => {
        setIsPinned(false);
        onDismiss();
    };
    return (React.createElement(Box, { position: 'relative' },
        React.createElement(CTAMoment, { onDismiss: handleDismiss, isPinned: isPinned, fuse: !hasSubmitted, ariaLabel: text },
            React.createElement(Flex, { flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, padding: '0 24px', textAlign: 'center' },
                pulse && React.createElement(Pulse, { onAnimationEnd: () => setPulse(false) }),
                React.createElement(TextWrapper, { marginBlockEnd: 3 }, text),
                React.createElement(CTAButton, { onClick: handleResponse, isPinned: isPinned, hasSubmitted: hasSubmitted, marginInlineStart: hasCount ? 2 : undefined, marginInlineEnd: hasCount ? 2 : undefined, paddingInlineEnd: hasCount ? 0 : 5, paddingBlockStart: 0, paddingBlockEnd: 0, tabIndex: tabIndex },
                    React.createElement(OParagraph, { fontSize: 3, flexShrink: 0, width: '24px', textAlign: 'start', marginBlockStart: '5px', marginBlockEnd: '5px', fontFamily: '"Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"', role: 'img', "aria-label": symbol.name }, symbol.native),
                    !hasSubmitted && (React.createElement(Box, { as: 'span', textAlign: 'start', marginBlockStart: '5px', marginBlockEnd: '5px' }, buttonText)),
                    count > 0 && (React.createElement(ResponseCount, { count: count, hasSubmitted: hasSubmitted, isPinned: isPinned }))))),
        pulse && (React.createElement(Symbol, { role: 'img', "aria-label": symbol.name }, symbol.native))));
};
export { ResponseMoment };
