import React, { FunctionComponent, KeyboardEventHandler } from 'react';
import { Props, State } from './index';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconSize,
  Input,
  Modal,
  ModalButtons,
  ModalSize,
  OHeaderThree,
  PrimaryButton,
  AngleLeftCircle,
  OParagraph,
  FormErrors,
} from '@lifechurch/react-ion';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import PasswordStrength from '../../PasswordStrength';

const FormBackButton = styled(Box)`
  display: flex;
  justify-content: flex-start;
  max-width: 80px;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }
`;

const StyledBackText = styled(OParagraph)`
  color: ${props => props.theme.colors.gray50};
`;

const ChangePassword: FunctionComponent<FormikProps<State> & Props> = ({
  openProfileSettings,
  ...props
}) => {
  const { handleSubmit, handleChange, values, errors } = props;
  const { t } = useTranslation('forms');

  const handleClose = () => {
    const { cancelChangePassword, closeModal } = props;
    cancelChangePassword();
    closeModal();
  };

  const handleBackKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      openProfileSettings();
    }
  };

  const errorMessages = Object.keys(errors).reduce((obj, key) => {
    return {
      ...obj,
      // @ts-expect-error
      [errors[key]]: t(errors[key]),
    };
  }, {});

  return (
    <Modal
      dismiss={handleClose}
      size={ModalSize.NARROW}
      testId='changePassword-modal'
      dismissTestId={'modal-dismiss'}
    >
      <OHeaderThree marginBlockEnd={5}>{t('change_password.title')}</OHeaderThree>
      <FormBackButton
        marginBlockEnd={5}
        onClick={openProfileSettings}
        tabIndex={0}
        onKeyDown={handleBackKeyDown}
        role='button'
      >
        <AngleLeftCircle size={IconSize.MD} />
        <StyledBackText marginInlineStart={1}>{t('change_password.back')}</StyledBackText>
      </FormBackButton>
      {Object.keys(errors).length > 0 && (
        <Box marginBlockEnd={5}>
          <FormErrors errors={errorMessages} />
        </Box>
      )}
      <form onSubmit={handleSubmit}>
        <Box marginBlockEnd={5}>
          <Input
            name='oldPassword'
            type='password'
            label={t('change_password.current_password')}
            onChange={handleChange}
            value={values.oldPassword}
            error={!!errors.oldPassword}
            hint={errors.oldPassword && t(errors.oldPassword)}
            required
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            name='newPassword'
            type='password'
            data-testid='newPassword-input'
            label={t('change_password.new_password')}
            onChange={handleChange}
            value={values.newPassword}
            error={!!errors.newPassword}
            hint={errors.newPassword && t(errors.newPassword)}
            required
          />
          <PasswordStrength password={values.newPassword} />
        </Box>
        <Box marginBlockEnd={6}>
          <Input
            name='confirmNewPassword'
            type='password'
            label={t('change_password.confirm_new_password')}
            onChange={handleChange}
            value={values.confirmNewPassword}
            required
            error={!!errors.confirmNewPassword}
            hint={errors.confirmNewPassword && t(errors.confirmNewPassword)}
          />
        </Box>
        <ModalButtons>
          <PrimaryButton onClick={handleSubmit} type='submit'>
            {t('change_password.save')}
          </PrimaryButton>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default ChangePassword;
