import styled from 'styled-components';
import { grid, GridProps } from 'styled-system';
import { Box, BoxProps } from './Box';
import { FunctionComponent } from 'react';

export type GridType = GridProps & BoxProps;

export const GridItem: FunctionComponent<GridType> = styled(Box)<GridType>`
  ${grid}
`;

export const Grid: FunctionComponent<GridType> = styled(GridItem)<GridType>`
  display: grid;
`;
