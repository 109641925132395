import React, { FunctionComponent } from 'react';
import { IframeModal as BaseIframeModal } from '@lifechurch/react-ion';

interface Props {
  closeModal: () => void;
  src: string;
}

const IframeModal: FunctionComponent<Props> = ({ src, closeModal }) => (
  <BaseIframeModal dismiss={closeModal} src={src} />
);

export default IframeModal;
