import React, { FunctionComponent, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { QuoteMoment as QuoteMomentComponent } from '@churchonline/moments';
import { Props } from './index';
import { MomentActionType, MomentType } from '../../../../../../__generated__/globalTypes';
import { quoteMomentShareSheet } from '../../../../../components/modal/dux';
import { isMacOS } from '@utils/core';

const QuoteMoment: FunctionComponent<Props> = ({
  organizationName,
  submitMomentAction,
  moment,
  setModal,
  hasLiked: defaultHasLiked = false,
  isPinned,
  onDismiss,
}) => {
  const [hasLiked, setHasLiked] = useState<boolean>(defaultHasLiked);

  const { text } = moment.momentTemplate.options;
  const { attribution } = moment.momentTemplate.options;

  const shouldUseNativeShareSheet = typeof navigator?.share !== 'undefined' && !isMacOS();

  const handleShare = (): void => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.QUOTE,
      momentActionType: MomentActionType.SHARE,
    });

    if (shouldUseNativeShareSheet) {
      const shareObj = {
        title: `Check out this quote from ${organizationName}`,
        text: attribution ? `"${text}" —${attribution}` : `"${text}"`,
        url: window.location.href,
      };

      navigator
        .share(shareObj)
        .then(() => {
          // do nothing
        })
        .catch((e: Error) => {
          const error = e.toString();
          // It will reject immediately if the data parameter
          // is not correctly specified, and will also reject
          // if the user cancels sharing. (MDN Navigator.share())
          if (error.includes('AbortError')) {
            return;
          }

          if (error.includes('TypeError')) {
            // I include the shareObj, because TypeError indicates a data parameter error.
            // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-425974249
            Sentry.withScope(scope => {
              scope.setExtra('shareObject', shareObj);
              Sentry.captureException(e);
            });
          }
        });
    } else {
      setModal(quoteMomentShareSheet(text, attribution));
    }
  };

  const handleLike = (): void => {
    if (!hasLiked) {
      submitMomentAction({
        channelKey: moment.channel.key,
        momentInstanceId: moment.id,
        momentType: MomentType.QUOTE,
        momentActionType: MomentActionType.LIKE,
      });
    }
    setHasLiked(true);
  };

  return (
    <>
      <QuoteMomentComponent
        text={text}
        attribution={attribution || ''}
        onShare={handleShare}
        onLike={handleLike}
        likeCount={moment.likes}
        hasLiked={hasLiked}
        isPinned={isPinned}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default QuoteMoment;
