import { connect } from 'react-redux';
import RevertChangeEmail from './revertChangeEmail';
import { revertSubscriber } from '@store/subscriberSlice';
import { Subscriber } from '../../../types';
import { SubscriberFieldType } from '../../../../__generated__/globalTypes';
import { setModal } from '@store/uiSlice';
import { changePassword } from '../dux';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface OwnProps {
  closeModal: () => void;
}

interface StateProps {
  subscriber: Subscriber;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  subscriber: getCurrentSubscriber(state),
  ...ownProps,
});

interface DispatchProps {
  revertSubscriber: () => void;
  openChangePassword: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  revertSubscriber: () =>
    dispatch(revertSubscriber({ input: { fields: [SubscriberFieldType.EMAIL] } })),
  openChangePassword: () => dispatch(setModal(changePassword())),
});

const VisibleRevertChangeEmail = connect(mapStateToProps, mapDispatchToProps)(RevertChangeEmail);

export type Props = OwnProps & DispatchProps & StateProps;
export default VisibleRevertChangeEmail;
