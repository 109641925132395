import { connect } from 'react-redux';
import { Modal as ModalInterface } from './dux';
import { getModal } from '@store/uiSlice/selectors';
import Modal from './modal';
import { RootState } from '@store/rootReducer';
import { clearModal } from '@store/uiSlice';
import { Dispatch } from 'redux';

interface StateProps {
  modal?: ModalInterface;
}

const mapStateToProps = (state: RootState) => ({
  modal: getModal(state),
});

interface DispatchProps {
  closeModal: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(clearModal()),
});

const VisibleModal = connect(mapStateToProps, mapDispatchToProps)(Modal);

export type Props = StateProps & DispatchProps;
export default VisibleModal;
