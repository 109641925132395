import { connect } from 'react-redux';

import { RootState } from '@store/rootReducer';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';
import Offline from './offline';

interface StateProps {
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
}

const mapStateToProps = (state: RootState): StateProps => {
  const nextService = getNextServiceData(state);

  return {
    nextService,
  };
};

const VisibleOfflineChat = connect(mapStateToProps)(Offline);

export type Props = StateProps;
export default VisibleOfflineChat;
