import Navbar from './navbar';
import { connect } from 'react-redux';
import { getCurrentSubscriber, hasPermissions } from '@store/subscriberSlice/selectors';
import { BasePanes, PaneType } from '../pane/dux';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { getNextServiceData, getScheduleItems } from '@store/scheduleSlice/selectors';
import { isChatEnabled, isOnline } from '@store/serviceSlice/selectors';
import { getPane, isHostViewingAsHost } from '@store/uiSlice/selectors';
import {
  getDirectChannels,
  getHostChannel,
  getPlaceholderChannels,
} from '@store/feedSlice/channelSelectors';
import {
  createNavbarChatItem,
  createNavbarItem,
  createNavbarServiceItem,
  NavbarItem,
} from './selectors';
import {
  clearAlertByMetaValue,
  setNavbarIndex,
  setPaneToChat,
  setPaneToType,
} from '@store/uiSlice';

interface StateProps {
  items: Array<NavbarItem>;
  navbarIndex: number;
}

interface OwnProps {
  mediumGuest?: boolean;
}

type State = StateProps & OwnProps;

const mapStateToProps = (state: RootState, ownProps: OwnProps): State => {
  const { navbarIndex } = state.ui.nav;
  const schedule = getScheduleItems(state);
  const direct = getDirectChannels(state);
  const placeholder = getPlaceholderChannels(state);
  const currentPane = getPane(state);
  const hostChannel = getHostChannel(state);
  const currentSubscriber = getCurrentSubscriber(state);
  const directChannels = [...direct, ...placeholder].map(channel =>
    createNavbarChatItem(channel, currentPane, currentSubscriber)
  );
  const hostViewingAsHost =
    hasPermissions(state, ['feed.host.read', 'feed.host.write']) && isHostViewingAsHost(state);
  const hasSchedule = schedule.length > (isOnline(state) ? 1 : 0);
  const chatEnabled = isChatEnabled(state);
  const nextService = getNextServiceData(state);
  const hasChatEnabled = chatEnabled || (nextService?.content?.features?.publicChat ?? false);
  const navbarServiceItemLabel = hasChatEnabled ? 'chat' : 'feed';

  const items: Array<NavbarItem> = [
    ...(currentPane.type !== PaneType.ERROR && (isOnline(state) || hostViewingAsHost)
      ? [createNavbarServiceItem(currentPane, navbarServiceItemLabel)]
      : []),
    ...directChannels,
    ...(hostViewingAsHost
      ? [
          createNavbarItem(PaneType.HOST_CHAT, currentPane, hostChannel),
          createNavbarItem(PaneType.AUDIENCE, currentPane, undefined, { label: 'audience.title' }),
          createNavbarItem(PaneType.HOST_INFO, currentPane),
          createNavbarItem(PaneType.MOMENTS, currentPane),
        ]
      : []),
    createNavbarItem(PaneType.NOTES, currentPane),
    createNavbarItem(PaneType.BIBLE, currentPane),
    ...(hostViewingAsHost || hasSchedule ? [createNavbarItem(PaneType.SCHEDULE, currentPane)] : []),
  ];

  return {
    items,
    navbarIndex,
    ...ownProps,
  };
};

interface DispatchProps {
  setPaneToChat: (channelKey: string) => void;
  setPaneToType: (type: BasePanes) => void;
  setNavbarIndex: (index: number) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setPaneToChat: (channelKey: string) => {
    dispatch(setPaneToChat(channelKey));
    dispatch(clearAlertByMetaValue({ key: 'channelKey', value: channelKey }));
  },
  setPaneToType: (type: BasePanes) => {
    dispatch(setPaneToType(type));
  },
  setNavbarIndex: (index: number) => dispatch(setNavbarIndex(index)),
});

const VisibleNavbar = connect(mapStateToProps, mapDispatchToProps)(Navbar);

export type Props = State & DispatchProps;
export default VisibleNavbar;
