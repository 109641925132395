import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { Manager, Reference, Popper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { InfoCircleOIcon, IconSize } from './icons';
import { getDefaultTheme } from './theme';

interface TooltipWrapperProps {
  success?: boolean;
}

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  background: ${(props): string =>
    props.success ? props.theme.colors.success.light : props.theme.colors.white};
  box-shadow: ${(props): string => props.theme.shadows[1]};
  border-radius: 2px;
  padding: 4px 8px;
  max-width: 400px;
  z-index: 9999;
  text-align: center;
  line-height: ${(props): number => props.theme.lineHeights[2]};

  &&&,
  div,
  p {
    font-size: ${(props): string => props.theme.fontSizes[2]};
    color: ${(props): string =>
      props.success ? props.theme.colors.success.dark : props.theme.colors.gray100};
    font-weight: normal;
  }

  p {
    margin: 8px 0;
  }

  p:first-child {
    margin-block-start: 0;
  }

  p:last-child {
    margin-block-end: 0;
  }
`;

const IconButton = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
`;

export interface TooltipProps {
  /**
   * Text that appears when the user hovers over the icon
   */
  content: string;
  /**
   * Placement of the popper
   */
  placement?: Placement;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Size of the icon
   */
  size?: IconSize;
}
/**
 * Tooltip component for providing tips to users
 *
 *
 * ###When To Use
 *
 * - When you need to provide a discrete tip to a user
 *
 */
export const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = ({
  content,
  placement = 'bottom',
  children,
  color = getDefaultTheme().colors.gray50,
  size = IconSize.SM,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const toggleShowTooltip = (): void => setShowTooltip(!showTooltip);
  const handleSetShowTooltip = (): void => setShowTooltip(true);
  const handleSetHideTooltip = (): void => setShowTooltip(false);

  return (
    <Manager>
      <Reference>
        {({ ref: referenceRef }) => (
          <IconButton
            type='button'
            id='tooltip-icon'
            ref={referenceRef}
            onMouseEnter={handleSetShowTooltip}
            onMouseLeave={handleSetHideTooltip}
            onClick={toggleShowTooltip}
          >
            {children ? children : <InfoCircleOIcon size={size} color={color} />}
          </IconButton>
        )}
      </Reference>
      {showTooltip && (
        <Popper placement={placement}>
          {({ placement, ref: popperRef, style }) => (
            <TooltipWrapper
              ref={popperRef}
              style={style}
              data-placement={placement}
              data-testid='tooltip-content'
            >
              {content}
            </TooltipWrapper>
          )}
        </Popper>
      )}
    </Manager>
  );
};
