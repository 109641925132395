import React, { FC } from 'react';
import { TimesCircleIcon } from '@lifechurch/react-ion';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: inherit;
  outline: inherit;
  tab-index: 0;
`;

const ClearInput: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('forms');
  return (
    <Button onClick={onClick} aria-label={t('audience.clear')} data-testid='clear-input'>
      <TimesCircleIcon />
    </Button>
  );
};

export default ClearInput;
