import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Message from './message';
import { submitMessageAction } from '@store/feedSlice';
import { Message as MessageInterface, deleteMessage } from './dux';
import { muteSubscriber as muteSubscriberModal } from '../../../../components/modal/dux';
import { messageLiked } from '@store/feedSlice/channelSelectors';
import {
  hasPermissions,
  getCurrentSubscriberAsPublicSubscriber,
} from '@store/subscriberSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { saveChannel, newPlaceholderChannel, saveMessage } from '@store/feedSlice';
import { FeedObjectMetaData } from '../dux';
import { getMessage } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';
import { PublicSubscriber as Subscriber } from '../../../../types';
import { LiveObjectType } from '../../../../pubnub/liveObject';
import { ChannelType, MessageActionType } from '../../../../../__generated__/globalTypes';
import { getCurrentLanguage } from '@store/uiSlice/selectors';
import { setModal, setPaneToChat, sendSeeOriginalTranslationMetric } from '@store/uiSlice';

interface StateProps {
  message?: MessageInterface;
  currentSubscriber: Subscriber;
  chatPermissions: boolean;
  moderationPermissions: boolean;
  organizationId: string;
  fromSelf: boolean;
  hasLiked: boolean;
  currentLanguage: string;
}

interface OwnProps {
  channel: string;
  metaData: FeedObjectMetaData;
  isCompact: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channel, metaData } = ownProps;
  const currentSubscriber = getCurrentSubscriberAsPublicSubscriber(state);
  const message = getMessage(state, channel, metaData.id);
  const fromSelf = message?.subscriber.id === currentSubscriber.id;
  const chatPermissions =
    hasPermissions(state, ['feed.direct.save']) &&
    (message?.channel.type === ChannelType.public || message?.channel.type === ChannelType.host);
  const moderationPermissions =
    hasPermissions(state, ['profile.subscriber.mute', 'feed.message.delete'], true) &&
    message?.channel.type === ChannelType.public;
  const hasLiked = Boolean(message && messageLiked(state, message.id));
  return {
    message,
    chatPermissions,
    moderationPermissions,
    currentSubscriber,
    hasLiked,
    organizationId: getCurrentOrganization(state).id,
    fromSelf,
    currentLanguage: getCurrentLanguage(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  muteSubscriber: (subscriber: Subscriber) =>
    dispatch(setModal(muteSubscriberModal({ subscriber }))),
  sendSeeOriginalTranslationEvent: (messageId: string, originalLanguage: string) =>
    dispatch(sendSeeOriginalTranslationMetric({ messageId, originalLanguage })),
  directChat: (self: Subscriber, recipient: Subscriber, organizationId: string) => {
    const placeholderChannel = newPlaceholderChannel(
      [self, recipient],
      ChannelType.direct,
      organizationId
    );
    dispatch(saveChannel(placeholderChannel));
    dispatch(setPaneToChat(placeholderChannel.key));
  },
  deleteMessage: (message: MessageInterface) => {
    dispatch(deleteMessage(message));
    dispatch(
      saveMessage({
        data: { ...message, deleted: true },
        type: LiveObjectType.MESSAGE,
        id: message.id,
        timestamp: message.timestamp,
        channel: message.channel,
      })
    );
  },
  likeMessage: (message: MessageInterface) => {
    dispatch(
      submitMessageAction({
        messageId: message.id,
        channelKey: message.channel.key,
        messageActionType: MessageActionType.LIKE,
      })
    );
  },
});

const VisibleMessage = connect(mapStateToProps, mapDispatchToProps)(Message);

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleMessage;
