import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { CheckIcon, CommentPlusIcon, IconSize } from '@lifechurch/react-ion';
import styles from '../prayerRequest/prayerRequest.module.css';
import { localizedTimeReference } from '@utils/core';
import SenderName from '../senderName';
import { Props } from './index';
import clsx from 'clsx';

const PrivateChannelInvite: React.FunctionComponent<Props> = (props: Props) => {
  const { privateChannelInvite, isCompact, alreadyInChat, acceptInvite } = props;

  const { t } = useTranslation();
  const { t: nt } = useTranslation('notifications');
  const theme = useContext(ThemeContext);

  if (!privateChannelInvite) {
    return null;
  }

  const { id, requestedAt, pending, accepterSubscriberId, requesterSubscriberId, channel } =
    privateChannelInvite;

  const subscribers = 'subscribers' in channel ? channel.subscribers : [];

  const requester = subscribers?.find(subscriber => subscriber.id === requesterSubscriberId);
  const accepter =
    accepterSubscriberId && subscribers?.find(subscriber => subscriber.id === accepterSubscriberId);
  const attender = subscribers?.find(subscriber => subscriber.roleIdentifier === null);

  const getAcceptedText = () => (
    <>
      <CheckIcon size={IconSize.SM} color={theme.colors.gray50} />
      <SenderName
        roleIdentifier={(accepter && accepter?.roleIdentifier) || null}
        name={(accepter && accepter?.nickname) || ''}
      />{' '}
      {nt('private_channel_invite.accepted')}
    </>
  );

  const callAcceptInvite = () => acceptInvite(id);

  return (
    <div
      data-testid='privateChannelInvite'
      className={clsx(
        pending ? [styles.actionableWrapper] : [styles.notificationWrapper],
        isCompact ? 'pis-3' : 'pis-6'
      )}
    >
      <div className='flex flex-grow'>
        <div className={styles.icon}>
          <CommentPlusIcon
            size={IconSize.SM}
            color={pending ? theme.colors.action.default : theme.colors.gray50}
          />
        </div>
        <div className='flex-grow'>
          <div>
            <p className='text-sm leading-tight'>
              <SenderName
                roleIdentifier={requester?.roleIdentifier}
                name={requester?.nickname || ''}
                active={pending}
              />{' '}
              <span data-testid='privateChannelInvite-message'>
                {subscribers && subscribers.length > 2 ? (
                  <Trans
                    ns='notifications'
                    i18nKey='private_channel_invite.request_with_multiple'
                    data-testid='privateChannelInvite-text-multiple'
                  >
                    requested someone join a chat with{' '}
                    <strong>{{ nickname: attender?.nickname || '' }}</strong> and others.
                  </Trans>
                ) : (
                  <Trans
                    ns='notifications'
                    i18nKey='private_channel_invite.request'
                    data-testid='privateChannelInvite-text'
                  >
                    requested someone join a chat with{' '}
                    <strong>{{ nickname: attender?.nickname || '' }}</strong>.
                  </Trans>
                )}
              </span>
              <span className='text-gray-50' data-testid='privateChannelInvite-requestedTimestamp'>
                {' '}
                {localizedTimeReference(requestedAt, t)}
              </span>
            </p>
          </div>
          <p
            className={clsx([styles.acceptedText], {
              hidden: pending,
            })}
            data-testid='privateChannelInvite-accepted'
          >
            {getAcceptedText()}
          </p>
        </div>
      </div>
      {pending && !alreadyInChat && (
        <button
          className={clsx(styles.acceptBtn, 'whitespace-nowrap min-w-[72px] mis-4')}
          onClick={callAcceptInvite}
          data-testid='privateChannelInvite-accept'
        >
          {nt('private_channel_invite.join_chat')}
        </button>
      )}
      {pending && alreadyInChat && (
        <p className={styles.pendingText}>{nt('actionable.pending', { ns: 'moments' })}</p>
      )}
    </div>
  );
};

export default React.memo<Props>(PrivateChannelInvite);
