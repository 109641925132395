import React, { useState, useEffect, FormEvent, ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';
import {
  FancyInput,
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  OHeaderThree,
  PrimaryButton,
  ModalText,
} from '@lifechurch/react-ion';
import { isValidNickname } from '@utils/core';
import { AvatarWrapper } from './styles';
import { Avatar, AvatarSizeType } from '@churchonline/core';
import { SelectChatNameSubtype } from './dux';

type SelectChatNameState = {
  nickname: string;
  nicknameInvalid: boolean;
  nicknameLong: boolean;
  nicknameShort: boolean;
  nicknameUnavailable: boolean;
};

const SelectChatName: FC<Props> = ({
  closeModal,
  updateAndDoAction,
  openLogin,
  subtype,
  subscriberId,
  nicknameUnavailable,
  meta,
}) => {
  const { t } = useTranslation('forms');

  const [values, setValues] = useState<SelectChatNameState>({
    nickname: '',
    nicknameInvalid: false,
    nicknameLong: false,
    nicknameShort: false,
    nicknameUnavailable: nicknameUnavailable,
  });

  useEffect(() => {
    setValues({ ...values, nicknameUnavailable: nicknameUnavailable });
  }, [nicknameUnavailable]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues({
      ...values,
      [name]: value,
      nicknameInvalid: false,
      nicknameLong: false,
      nicknameShort: false,
      nicknameUnavailable: false,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const nickname = values.nickname.trim();
    if (isValidNickname(nickname)) {
      switch (subtype) {
        case SelectChatNameSubtype.CHAT:
          return updateAndDoAction(subscriberId, nickname, 'chat');
        case SelectChatNameSubtype.PRAYER:
          return updateAndDoAction(subscriberId, nickname, 'prayer', meta?.momentId);
      }
    } else {
      setValues({
        ...values,
        nickname: nickname,
        nicknameInvalid: !isValidNickname(nickname),
        nicknameLong: nickname.length > 30,
        nicknameShort: nickname.length < 3,
        nicknameUnavailable: false,
      });
    }
  };

  const getConfirmTextFromSubtype = (subtype: SelectChatNameSubtype) => {
    switch (subtype) {
      case SelectChatNameSubtype.CHAT:
        return 'select_chat_name.post';
      case SelectChatNameSubtype.PRAYER:
        return 'select_chat_name.continue';
    }
  };

  const confirmText: string = getConfirmTextFromSubtype(subtype);
  const avatar: string | undefined = undefined;
  const nickname: string = values.nickname.trim();
  const initial: string = nickname[0];

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='selectChatName-modal'>
      <ModalText>
        <OHeaderThree marginBlockEnd={2}>{t('select_chat_name.title')}</OHeaderThree>
      </ModalText>
      <form onSubmit={handleSubmit}>
        <AvatarWrapper>
          <Avatar
            subscriber={{ id: subscriberId, avatar, nickname: initial }}
            size={AvatarSizeType.LARGE}
            testId='avatar'
          />
        </AvatarWrapper>
        <FancyInput
          type='text'
          name='nickname'
          id='nickname'
          data-testid='selectChatName-nicknameField'
          value={values.nickname}
          label={t('select_chat_name.chat_name')}
          onChange={onChange}
          error={values.nicknameInvalid || values.nicknameUnavailable}
          hint={(() => {
            if (values.nicknameInvalid) {
              if (values.nicknameShort) {
                return t('select_chat_name.short_chat_name_error');
              }
              if (values.nicknameLong) {
                return t('select_chat_name.long_chat_name_error');
              }
            }

            if (values.nicknameUnavailable) {
              return t('select_chat_name.unavailable_error');
            }
            return null;
          })()}
        />
        <ModalButtons marginBlockStart={6}>
          <LinkButton
            buttonType={LinkButtonType.PRIMARY}
            onClick={openLogin}
            data-testid='selectChatName-login'
            marginInlineEnd={5}
          >
            {t('login.title')}
          </LinkButton>
          <PrimaryButton onClick={handleSubmit} type='submit' disabled={nickname.length < 3}>
            {t(confirmText)}
          </PrimaryButton>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default SelectChatName;
