import React, {
  ChangeEventHandler,
  FunctionComponent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Props } from './index';
import { AudienceRoot, Search, SearchInput } from './styles';
import { Flex } from '@lifechurch/react-ion';
import Filter, { FILTERS } from './components/filter';
import PaneHeader, { PaneHeaderType } from '@features/pane/paneHeader';
import SubscriberRow from '@features/audience/components/subscriberRow';
import BiDirectionalScroll from '@features/audience/components/biDirectionalScroll';
import Loading from './components/loading';
import useAudienceTab, {
  LOADING,
  NUM_OF_PAGES,
  PAGE_SIZE,
} from '@features/audience/useAudienceTab';
import AudienceOffline from '@features/audience/components/audienceOffline';
import { isSafariOriOS } from '@utils/core/platform';
import { useTranslation } from 'react-i18next';
import { ROW_HEIGHT } from '@features/audience/constants';
import ScrollToTop from '@features/audience/components/ScrollToTop';
import AdditionalGuests from '@features/audience/components/additionalGuests';
import ClearInput from '@features/audience/components/clearInput';

enum LockScroll {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
const MAX_HEIGHT = PAGE_SIZE * (NUM_OF_PAGES - 0.5) * ROW_HEIGHT;

const Audience: FunctionComponent<Props> = ({ isOffline }) => {
  const {
    currentEdgeSubscriber,
    filter,
    loadFirst,
    loadNext,
    loadPrevious,
    loading,
    newSubscribers,
    setNewSubscribers,
    pageInfo,
    refetch,
    search,
    setFilter,
    setSearch,
    subscribers,
    totalConnectedCount,
  } = useAudienceTab();
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const [lockScroll, setLockScroll] = useState<LockScroll | null>(null);
  const [selectedSubscriber, setSelectedSubscriber] = useState<string | null>(null);
  const showAdditionalGuests =
    loading === null && !pageInfo?.hasNextPage && filter === FILTERS.NONE && !search;

  const position = useRef<number | null>(null);
  const { t } = useTranslation();
  const { t: tForms } = useTranslation('forms');

  useLayoutEffect(() => {
    if (lockScroll && loading == null) {
      const isScrollingDown = lockScroll === LockScroll.BOTTOM;

      // Locks scrolling down on Safari and iOS devices due to inertia scrolling
      if (isSafariOriOS && isScrollingDown) {
        const subscriberElement = document.querySelector(
          `[data-id="${currentEdgeSubscriber?.id}"]`
        );
        subscriberElement?.scrollIntoView(false);
      } else {
        // Lock scrolling up when the content is inserted above
        // Current Edge Subscriber will be the top subscriber before the new rows are inserted.
        const index = subscribers.findIndex(
          subscriber => subscriber?.id === currentEdgeSubscriber?.id
        );
        position.current = index * ROW_HEIGHT;
      }

      setLockScroll(null);
    }
  }, [lockScroll, subscribers, loading]);

  const handleSetScrollPosition = () => {
    position.current = null;
  };

  const handleScroll = (scrollTop: number) => {
    // Show scroll top when you are three rows down or not on the first page.
    const shouldShowScrollToTop = scrollTop >= ROW_HEIGHT * 3 || pageInfo?.hasPreviousPage;
    if (shouldShowScrollToTop && !showScrollToTop) {
      setShowScrollToTop(true);
    } else if (!shouldShowScrollToTop && showScrollToTop) {
      setNewSubscribers(false);
      setShowScrollToTop(false);
    }
  };

  const handleScrollToTop = async () => {
    // Scroll to top and load first 3 pages
    position.current = 0;
    setNewSubscribers(false);
    setShowScrollToTop(false);
    await loadFirst();
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleFilterChange = (filter: FILTERS) => {
    setFilter(filter);
  };

  const handleReachBottom = async () => {
    if (loading == null) {
      await loadNext();
      if (pageInfo?.hasNextPage && isSafariOriOS) {
        setLockScroll(LockScroll.BOTTOM);
      }
    }
  };

  const handleReachTop = async () => {
    if (loading == null) {
      await loadPrevious();
      if (pageInfo?.hasPreviousPage) {
        setLockScroll(LockScroll.TOP);
      }
    }
  };

  const handleSelect = (id: string) => {
    setSelectedSubscriber(currentId => (currentId === id ? null : id));
  };

  const handleClear = () => {
    setSearch(null);
  };

  if (isOffline) {
    return (
      <>
        <PaneHeader
          header={{
            type: PaneHeaderType.DEFAULT,
            payload: { title: t('audience.title').toUpperCase() },
          }}
        />
        <AudienceOffline />
      </>
    );
  }

  return (
    <>
      <PaneHeader
        header={{
          type: PaneHeaderType.DEFAULT,
          payload: { title: t('audience.title').toUpperCase() },
        }}
      />
      <AudienceRoot>
        <Flex mx={5} marginBlockEnd={3}>
          <Search hasFilter={filter !== FILTERS.NONE}>
            <SearchInput
              onChange={handleSearchChange}
              placeholder={tForms('audience.search')}
              tabIndex={0}
              value={search || ''}
              icon={search && <ClearInput onClick={handleClear} />}
            />
          </Search>
          <Filter filter={filter} onChange={handleFilterChange} />
        </Flex>
        <BiDirectionalScroll
          onReachBottom={handleReachBottom}
          onReachTop={handleReachTop}
          onScroll={handleScroll}
          onSetScrollPosition={handleSetScrollPosition}
          position={position.current}
          style={{ maxHeight: MAX_HEIGHT }}
        >
          {showScrollToTop && (
            <ScrollToTop newSubscribers={newSubscribers} onClick={handleScrollToTop} />
          )}
          {loading === LOADING.TOP && <Loading />}
          {subscribers.map(subscriber => (
            <SubscriberRow
              isSelected={subscriber?.id === selectedSubscriber}
              key={`${subscriber?.id}-${subscriber?.stats?.channels?.prayer}`}
              onSelect={handleSelect}
              refetch={refetch}
              subscriber={subscriber}
              setSelectedSubscriber={setSelectedSubscriber}
            />
          ))}
          {loading === LOADING.BOTTOM && <Loading />}
          {showAdditionalGuests && <AdditionalGuests totalCount={totalConnectedCount} />}
        </BiDirectionalScroll>
      </AudienceRoot>
    </>
  );
};

export default React.memo<Props>(Audience);
