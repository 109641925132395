import styled from 'styled-components';
import { ButtonSize, PrimaryButton, PrimaryOnDarkButton, SecondaryButton, } from '@lifechurch/react-ion';
import React from 'react';
const ResponsePrimaryButton = styled(PrimaryButton) `
  z-index: 1;
  border-radius: 16px;
`;
const ResponsePrimaryOnDarkButton = styled(PrimaryOnDarkButton) `
  z-index: 1;
  border-radius: 16px;
  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.action.hover};
  }

  &:disabled {
    background-color: white;
    cursor: default;
    box-shadow: none;
  }
`;
const ResponseSecondaryButton = styled(SecondaryButton) `
  z-index: 1;
  border-radius: 16px;
  &:hover:not(:disabled) {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.action.hover};
    color: white;

    div:last-child {
      background-color: ${props => props.theme.colors.whiteTransparent20};
    }
  }

  &:disabled {
    background-color: white;
    cursor: default;
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.gray10};
  }
`;
export const CTAButton = ({ children, hasSubmitted, isPinned, onClick, tabIndex, ...props }) => {
    let Button;
    if (isPinned && !hasSubmitted) {
        Button = ResponsePrimaryButton;
    }
    else if (isPinned && hasSubmitted) {
        Button = ResponsePrimaryOnDarkButton;
    }
    else {
        Button = ResponseSecondaryButton;
    }
    return (React.createElement(Button, { size: ButtonSize.SM, onClick: onClick, disabled: hasSubmitted, tabIndex: tabIndex, ...props }, children));
};
