import { FeedObjectMetaData } from '@features/feed/objects/dux';
import { Message } from '@features/feed/objects/message/dux';
import { Moment } from '@features/feed/objects/moment/dux';
import { Notification } from '@features/feed/objects/notification/dux';
import { PrivateChannelInvite } from '@features/feed/objects/privateChannelInvite/dux';
import { Reaction, ReactionType } from '@features/reactions/dux';
import { CurrentState_pubnubKeys } from '@io/__generated__/CurrentState';
import { JoinChannelMutationVariables } from '@io/__generated__/JoinChannelMutation';
import { RequestChannelQueryVariables } from '@io/__generated__/RequestChannelQuery';
import { createAction, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/rootReducer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import sortedIndexBy from 'lodash.sortedindexby';
import Pubnub, { PresenceEvent } from 'pubnub';
import { v4 as uuidv4 } from 'uuid';
import {
  ChannelType,
  MessageActionType,
  MomentActionType,
  MomentType,
} from '../../../__generated__/globalTypes';
import {
  LiveObjectType,
  MessageLiveObject,
  MomentLiveObject,
  NotificationLiveObject,
  PrayerRequestLiveObject,
  PrivateChannelInviteLiveObject,
} from '../../pubnub/liveObject';
import { ActionWithPayload, PublicChannel, PublicSubscriber } from '../../types';
import { getMomentChannelGroup } from './channelSelectors';

dayjs.extend(utc);

export interface ClientChannel extends PublicChannel {
  anchoredMoment?: string;
  placeholderChannel?: boolean;
  chatMessage?: string;
  loading?: boolean;
  subscribers?: Array<PublicSubscriber>;
  occupancy?: number;
}

export const messagesAdapter = createEntityAdapter<Message>();
export const prayerRequestsAdapter = createEntityAdapter<PrayerRequest>();
export const privateChannelInvitesAdapter = createEntityAdapter<PrivateChannelInvite>();
export const notificationsAdapter = createEntityAdapter<Notification>();
export const momentsAdapter = createEntityAdapter<Moment>();

export interface ChannelDefaults {
  messages: ReturnType<typeof messagesAdapter.getInitialState>;
  notifications: ReturnType<typeof notificationsAdapter.getInitialState>;
  moments: ReturnType<typeof momentsAdapter.getInitialState>;
  prayerRequests: ReturnType<typeof prayerRequestsAdapter.getInitialState>;
  privateChannelInvites: ReturnType<typeof privateChannelInvitesAdapter.getInitialState>;
  objectOrder: Array<FeedObjectMetaData>;
  subscribersTyping: { id: string; timetoken: number }[];
  sawLastMessageAt?: string;
  connectionStatus?: keyof Pubnub.Categories;
  statusChangedAt?: string;
}

export type Channel = ClientChannel & ChannelDefaults;

export interface ChannelGroup {
  id: string;
  key: string;
  type: ChannelType;
  loading: boolean;
}

export interface LoadingArgs {
  channelKey: string;
  loading: boolean;
}

export interface PrayerRequest {
  id: string;
  channel: PublicChannel;
  subscriber?: PublicSubscriber;
  host?: PublicSubscriber;
  open: boolean;
  raisedHand?: boolean;
  timestamp: string;
  requestedTimestamp: string;
  acceptedTimestamp?: string;
}

export interface SaveChannelMessageArgs {
  channelKey: string;
  message: string;
}

export interface RequestPlaceholderChannelArgs {
  text: string;
  subscriber: PublicSubscriber;
  lang: string;
  channel: ClientChannel;
}

export interface TypingInChannelArgs {
  isTyping: boolean;
  channelKey: string;
}

export interface SetSawLastMessageAtArgs {
  channelKey: string;
  timestamp: string;
}

export interface SubmitMomentActionArgs {
  channelKey: string;
  momentType: MomentType;
  momentInstanceId: string;
  momentActionType: MomentActionType;
}

export interface SubmitMessageActionArgs {
  channelKey: string;
  messageId: string;
  messageActionType: MessageActionType;
}

export interface DeleteMomentActionArgs {
  channelKey: string;
  momentInstanceId: string;
}

interface UpdateSubscribersTypingArgs {
  isTyping: boolean;
  channelKey: string;
  subscriberId: string;
  timetoken: number;
}

interface SetConnectionStatusPayload {
  connectionStatus: keyof Pubnub.Categories & string;
  statusChangedAt: string;
  allChannels?: boolean;
  channelKeys: string[];
}

interface SetMessageLikeCountPayload {
  messageId: string;
  likeCount: number;
  channelKey: string;
}

export const REQUEST_PLACEHOLDER_CHANNEL = 'REQUEST_PLACEHOLDER_CHANNEL';
export const LEAVE_CHANNEL = 'LEAVE_CHANNEL';
export const REQUEST_INVITE_TO_CHANNEL = 'REQUEST_INVITE_TO_CHANNEL';
export const ACCEPT_INVITE_TO_CHANNEL = 'ACCEPT_INVITE_TO_CHANNEL';
export const JOIN_CHANNEL = 'JOIN_CHANNEL';
export const TYPING_IN_CHANNEL = 'TYPING_IN_CHANNEL';

export type RequestPlaceholderChannel = ActionWithPayload<
  typeof REQUEST_PLACEHOLDER_CHANNEL,
  RequestPlaceholderChannelArgs
>;

export type LeaveChannel = ActionWithPayload<typeof LEAVE_CHANNEL, Channel>;
export type RequestInviteToChannel = ActionWithPayload<typeof REQUEST_INVITE_TO_CHANNEL, string>;
export type AcceptInviteToChannel = ActionWithPayload<typeof ACCEPT_INVITE_TO_CHANNEL, string>;
export type JoinChannel = ActionWithPayload<typeof JOIN_CHANNEL, JoinChannelMutationVariables>;
export type TypingInChannel = ActionWithPayload<typeof TYPING_IN_CHANNEL, TypingInChannelArgs>;

export interface FeedState {
  pubnubKeys: CurrentState_pubnubKeys;
  channels: Record<string, Channel>;
  channelGroups: Record<string, ChannelGroup>;
  focusedChannel: string;
  reactions: Array<Reaction>;
  momentSubmissions: Array<string>;
  momentLikes: Array<string>;
  messageLikes: Array<string>;
  persistExpiresAt: string;
}

export const requestChannel = createAction<RequestChannelQueryVariables>('feed/requestChannel');

export const requestPlaceholderChannel = (
  text: string,
  subscriber: PublicSubscriber,
  lang: string,
  channel: ClientChannel
): RequestPlaceholderChannel => ({
  type: REQUEST_PLACEHOLDER_CHANNEL,
  payload: {
    text,
    subscriber,
    lang,
    channel,
  },
});

export const joinChannel = (id: string): JoinChannel => ({
  type: JOIN_CHANNEL,
  payload: {
    id,
  },
});

export const leaveChannel = (payload: Channel): LeaveChannel => ({
  type: LEAVE_CHANNEL,
  payload,
});

export const requestInviteToChannel = (payload: string): RequestInviteToChannel => ({
  type: REQUEST_INVITE_TO_CHANNEL,
  payload,
});

export const acceptInviteToChannel = (payload: string): AcceptInviteToChannel => ({
  type: ACCEPT_INVITE_TO_CHANNEL,
  payload,
});

export const typingInChannel = (isTyping: boolean, channelKey: string): TypingInChannel => ({
  type: TYPING_IN_CHANNEL,
  payload: {
    isTyping,
    channelKey,
  },
});

export const acceptLivePrayer = createAction<PrayerRequest>('feed/acceptLivePrayer');

interface RequestLivePrayerPayload {
  momentId?: string;
}

interface RequestLivePrayerMeta {
  raisedHand?: boolean;
}

export const requestLivePrayer = createAction(
  'feed/requestLivePrayer',
  function prepare(payload: RequestLivePrayerPayload = {}, meta: RequestLivePrayerMeta = {}) {
    return {
      payload,
      meta,
    };
  }
);

export const newPlaceholderChannel = (
  subscribers: Array<PublicSubscriber>,
  type: ChannelType,
  organizationId: string
): ClientChannel => {
  const id = uuidv4();
  const key = `chat.${type}.${organizationId}:${id}`;
  return {
    id,
    key,
    name: 'Placeholder',
    type,
    direct: true,
    group: false,
    placeholderChannel: true,
    subscribers,
  };
};

export const initialState: FeedState = {
  pubnubKeys: {
    publishKey: '',
    subscribeKey: '',
  },
  channels: {},
  channelGroups: {},
  focusedChannel: '',
  reactions: [],
  momentSubmissions: [],
  momentLikes: [],
  messageLikes: [],
  persistExpiresAt: dayjs().add(24, 'hour').toISOString(),
};

export const defaultChannelProps = {
  messages: messagesAdapter.getInitialState(),
  notifications: notificationsAdapter.getInitialState(),
  moments: momentsAdapter.getInitialState(),
  prayerRequests: prayerRequestsAdapter.getInitialState(),
  privateChannelInvites: privateChannelInvitesAdapter.getInitialState(),
  objectOrder: [],
  sawLastMessageAt: new Date().toISOString(),
  subscribersTyping: [],
};

const addToObjectOrder = (
  objectOrder: FeedObjectMetaData[],
  newItem: FeedObjectMetaData
): [FeedObjectMetaData[], FeedObjectMetaData[]] => {
  const removedItems: FeedObjectMetaData[] = [];
  objectOrder.push(newItem);
  while (objectOrder.length > 150) {
    const removedItem = objectOrder.shift();
    if (removedItem) {
      removedItems.push(removedItem);
    }
  }
  return [objectOrder, removedItems];
};

const cleanEntities = (channel: Channel, itemsToRemove: FeedObjectMetaData[]): Channel => {
  itemsToRemove.forEach(item => {
    switch (item.type) {
      case LiveObjectType.MESSAGE: {
        messagesAdapter.removeOne(channel.messages, item.id);
        break;
      }
      case LiveObjectType.MOMENT: {
        momentsAdapter.removeOne(channel.moments, item.id);
        break;
      }
      case LiveObjectType.NOTIFICATION: {
        notificationsAdapter.removeOne(channel.notifications, item.id);
        break;
      }
    }
  });

  return channel;
};

export const getInsertIndex = (
  objects: Array<FeedObjectMetaData>,
  object: FeedObjectMetaData
): number => {
  return sortedIndexBy(objects, object, (object: FeedObjectMetaData) => {
    switch (object.type) {
      case LiveObjectType.MOMENT:
        return object.postTime;
      default:
        return object.timestamp;
    }
  });
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    addChannel(state, action: PayloadAction<Channel>) {
      if (!state.channels[action.payload.key]) {
        state.channels[action.payload.key] = action.payload;
      }
    },

    addChannelGroup(state, action: PayloadAction<ChannelGroup>) {
      if (!state.channelGroups[action.payload.key]) {
        state.channelGroups[action.payload.key] = action.payload;
      }
    },

    dismissAnchoredMoment(state, action: PayloadAction<{ key: string }>) {
      if (state.channels[action.payload.key]?.anchoredMoment) {
        const feedObject = {
          id: state.channels[action.payload.key].anchoredMoment!,
          type: LiveObjectType.MOMENT,
        };

        state.channels[action.payload.key].objectOrder.push(feedObject);
        state.channels[action.payload.key].anchoredMoment = undefined;
      }
    },

    instantiatePlaceholderChannel(state, action: PayloadAction<ClientChannel>) {
      state.channels[action.payload.key] = {
        ...state.channels[action.payload.key],
        ...action.payload,
        placeholderChannel: false,
      };
    },

    onPresence(state, action: PayloadAction<PresenceEvent>) {
      const { channel, occupancy } = action.payload;
      if (state.channels[channel]) {
        state.channels[channel].occupancy = occupancy;
      }
    },

    popSubscribersTyping(state, action: PayloadAction<{ channelKey: string }>) {
      const { channelKey } = action.payload;

      if (state.channels[channelKey]) {
        state.channels[channelKey].subscribersTyping = state.channels[
          channelKey
        ].subscribersTyping.filter(subscriber => {
          const timetoken = dayjs(subscriber.timetoken);
          return timetoken.diff(dayjs(), 'second') > -10;
        });
      }
    },

    removeChannel(state, action: PayloadAction<{ key: string }>) {
      delete state.channels[action.payload.key];
    },

    removeChannelGroup(state, action: PayloadAction<{ key: string }>) {
      delete state.channelGroups[action.payload.key];
    },

    removeReaction(state, action: PayloadAction<{ id: string }>) {
      state.reactions = state.reactions.filter(reaction => reaction.id !== action.payload.id);
    },

    saveChannel(state, action: PayloadAction<ClientChannel>) {
      state.channels[action.payload.key] = {
        ...defaultChannelProps,
        ...state.channels[action.payload.key],
        ...action.payload,
      };
    },

    saveChannelMessage(state, action: PayloadAction<SaveChannelMessageArgs>) {
      const { channelKey, message } = action.payload;
      if (state.channels[channelKey]) {
        state.channels[channelKey].chatMessage = message;
      }
    },

    saveMessage(state, action: PayloadAction<MessageLiveObject>) {
      const {
        data: { channel },
      } = action.payload;
      const { key } = channel;

      if (state.channels[key] !== undefined) {
        const exists = messagesAdapter
          .getSelectors()
          .selectById(state.channels[key].messages, action.payload.data.id);

        state.channels[key].messages = messagesAdapter.upsertOne(state.channels[key].messages, {
          ...action.payload.data,
          deleted: exists?.deleted || action.payload.data.deleted,
        });

        if (!exists) {
          const [newObjectOrder, itemsToRemove] = addToObjectOrder(
            state.channels[key].objectOrder,
            {
              id: action.payload.data.id,
              type: LiveObjectType.MESSAGE,
              timestamp: action.payload.data.timestamp,
            }
          );

          state.channels[key].objectOrder = newObjectOrder;

          if (itemsToRemove.length > 0) {
            state.channels[key] = cleanEntities(state.channels[key], itemsToRemove);
          }
        }
      }
    },
    saveMoment: {
      reducer(state, action: PayloadAction<MomentLiveObject, string, { isChatEnabled: boolean }>) {
        const { data } = action.payload;
        const { isChatEnabled } = action.meta;
        const channelKey = data.channel.key;
        if (state.channels[channelKey]) {
          const momentExists = momentsAdapter
            .getSelectors()
            .selectById(state.channels[channelKey].moments, data.id);

          momentsAdapter.upsertOne(state.channels[channelKey].moments, data);

          const isInObjectOrder = state.channels[channelKey].objectOrder.find(
            object => object.id === data.id
          );
          const momentsChannelGroup = getMomentChannelGroup({ feed: state } as RootState);

          if (isChatEnabled && !momentsChannelGroup?.loading) {
            const existingAnchoredMoment = state.channels[channelKey].anchoredMoment;
            if (!momentExists) {
              if (existingAnchoredMoment) {
                const momentObject: FeedObjectMetaData = {
                  id: existingAnchoredMoment,
                  type: LiveObjectType.MOMENT,
                  postTime: action.payload.data.postTime,
                };
                const index = getInsertIndex(state.channels[channelKey].objectOrder, momentObject);
                state.channels[channelKey].objectOrder.splice(index, 0, momentObject);
              }
              state.channels[channelKey].anchoredMoment = data.id;
            }
          } else if (!isInObjectOrder) {
            const momentObject: FeedObjectMetaData = {
              id: data.id,
              type: LiveObjectType.MOMENT,
              postTime: action.payload.data.postTime,
            };
            const index = getInsertIndex(state.channels[channelKey].objectOrder, momentObject);
            state.channels[channelKey].objectOrder.splice(index, 0, momentObject);
          }
        }
      },
      prepare(payload: MomentLiveObject, meta: { isChatEnabled: boolean }) {
        return {
          payload,
          meta,
        };
      },
    },

    saveNotification(state, action: PayloadAction<NotificationLiveObject>) {
      const { key } = action.payload.data.channel;
      if (state.channels[key] !== undefined) {
        const exists = notificationsAdapter
          .getSelectors()
          .selectById(state.channels[key].notifications, action.payload.data.id);

        state.channels[key].notifications = notificationsAdapter.upsertOne(
          state.channels[key].notifications,
          action.payload.data
        );

        if (!exists) {
          state.channels[key].objectOrder.push({
            id: action.payload.data.id,
            type: LiveObjectType.NOTIFICATION,
            timestamp: action.payload.data.timestamp,
          });
        }
      }
    },

    savePrayerRequest(state, action: PayloadAction<PrayerRequestLiveObject>) {
      const { key } = action.payload.channel;
      if (state.channels[key] !== undefined) {
        const exists = prayerRequestsAdapter
          .getSelectors()
          .selectById(state.channels[key].prayerRequests, action.payload.data.id);

        state.channels[key].prayerRequests = prayerRequestsAdapter.upsertOne(
          state.channels[key].prayerRequests,
          action.payload.data
        );

        if (!exists) {
          state.channels[key].objectOrder.push({
            id: action.payload.data.id,
            type: LiveObjectType.PRAYER_REQUEST,
            timestamp: action.payload.data.timestamp,
          });
        }
      }
    },

    savePrivateChannelInvite(state, action: PayloadAction<PrivateChannelInviteLiveObject>) {
      const { key } = action.payload.channel;
      if (state.channels[key] !== undefined) {
        const exists = privateChannelInvitesAdapter
          .getSelectors()
          .selectById(state.channels[key].privateChannelInvites, action.payload.data.id);

        state.channels[key].privateChannelInvites = privateChannelInvitesAdapter.upsertOne(
          state.channels[key].privateChannelInvites,
          action.payload.data
        );

        if (!exists) {
          state.channels[key].objectOrder.push({
            id: action.payload.data.id,
            type: LiveObjectType.PRIVATE_CHANNEL_INVITE,
            timestamp: action.payload.data.requestedAt,
          });
        }
      }
    },

    saveReaction(state, action: PayloadAction<Reaction>) {
      state.reactions.push(action.payload);
    },

    setChannels(
      state,
      action: PayloadAction<{ channels: Channel[]; channelGroups: ChannelGroup[] }>
    ) {
      state.channels = action.payload.channels.reduce((result, currentValue) => {
        result[currentValue.key] = currentValue;
        return result;
      }, {} as Record<string, Channel>);
    },

    setChannelGroups(state, action: PayloadAction<ChannelGroup[]>) {
      state.channelGroups = action.payload.reduce((result, currentValue) => {
        result[currentValue.key] = currentValue;
        return result;
      }, {} as Record<string, ChannelGroup>);
    },

    setChatFocus(state, action: PayloadAction<{ channelKey: string }>) {
      const { channelKey } = action.payload;
      state.focusedChannel = channelKey;
    },

    setGroupLoading(state, action: PayloadAction<LoadingArgs>) {
      const { channelKey, loading } = action.payload;
      if (state.channelGroups[channelKey]) {
        state.channelGroups[channelKey].loading = loading;
      }
    },

    setLoading(state, action: PayloadAction<LoadingArgs>) {
      const { channelKey, loading } = action.payload;
      if (state.channels[channelKey]) {
        state.channels[channelKey].loading = loading;
      }
    },

    setPubnubKeys(state, action: PayloadAction<CurrentState_pubnubKeys>) {
      state.pubnubKeys = action.payload;
    },

    setSawLastMessageAt(state, action: PayloadAction<SetSawLastMessageAtArgs>) {
      const { channelKey, timestamp } = action.payload;
      if (state.channels[channelKey]) {
        state.channels[channelKey].sawLastMessageAt = timestamp;
      }
    },

    deleteMoment(state, action: PayloadAction<DeleteMomentActionArgs>) {
      const { channelKey, momentInstanceId } = action.payload;
      const momentExists = momentsAdapter
        .getSelectors()
        .selectById(state.channels[channelKey].moments, momentInstanceId);
      if (momentExists) {
        momentsAdapter.updateOne(state.channels[channelKey].moments, {
          id: momentInstanceId,
          changes: {
            deleted: true,
          },
        });
      }
    },

    submitMomentAction(state, action: PayloadAction<SubmitMomentActionArgs>) {
      const { channelKey, momentActionType, momentInstanceId } = action.payload;
      const momentExists = momentsAdapter
        .getSelectors()
        .selectById(state.channels[channelKey].moments, momentInstanceId);

      if (momentExists) {
        if (momentActionType === MomentActionType.LIKE) {
          momentsAdapter.updateOne(state.channels[channelKey].moments, {
            id: momentInstanceId,
            changes: {
              likes: momentExists.likes + 1,
            },
          });
          state.momentLikes = [...new Set([...state.momentLikes, momentInstanceId])];
        }
        if (momentActionType === MomentActionType.SUBMIT) {
          momentsAdapter.updateOne(state.channels[channelKey].moments, {
            id: momentInstanceId,
            changes: {
              count: momentExists.count + 1,
            },
          });
          state.momentSubmissions = [...new Set([...state.momentSubmissions, momentInstanceId])];
        }
      }
    },

    submitMessageAction(state, action: PayloadAction<SubmitMessageActionArgs>) {
      const { channelKey, messageActionType, messageId } = action.payload;
      const messageExists = messagesAdapter
        .getSelectors()
        .selectById(state.channels[channelKey].messages, messageId);

      if (messageExists) {
        if (messageActionType === MessageActionType.LIKE) {
          messagesAdapter.updateOne(state.channels[channelKey].messages, {
            id: messageId,
            changes: {
              likeCount: messageExists.likeCount + 1,
            },
          });
          state.messageLikes = [...new Set([...state.messageLikes, messageId])];
        }
      }
    },

    setMessageLikeCount(state, action: PayloadAction<SetMessageLikeCountPayload>) {
      const { likeCount, messageId, channelKey } = action.payload;

      if (state.channels[channelKey]) {
        messagesAdapter.updateOne(state.channels[channelKey].messages, {
          id: messageId,
          changes: {
            likeCount: likeCount,
          },
        });
      }
    },

    updateSubscribersTyping(state, action: PayloadAction<UpdateSubscribersTypingArgs>) {
      const { channelKey, isTyping, subscriberId, timetoken } = action.payload;
      if (state.channels[channelKey]) {
        const updatedSubscriberTyping = state.channels[channelKey].subscribersTyping.filter(
          item => item.id !== subscriberId
        );
        if (isTyping) {
          updatedSubscriberTyping.push({ id: subscriberId, timetoken });
        }
        state.channels[channelKey].subscribersTyping = updatedSubscriberTyping;
      }
    },

    setConnectionStatus(state, action: PayloadAction<SetConnectionStatusPayload>) {
      const channels = action.payload.allChannels
        ? Object.keys(state.channels)
        : action.payload.channelKeys;
      channels.forEach(channelKey => {
        if (state.channels[channelKey]) {
          state.channels[channelKey].connectionStatus = action.payload.connectionStatus;
          state.channels[channelKey].statusChangedAt = action.payload.statusChangedAt;
        }
      });
    },
  },
});

export const publishReaction =
  createAction<{ type: ReactionType | string }>('feed/publishReaction');

export const {
  addChannel,
  addChannelGroup,
  deleteMoment,
  dismissAnchoredMoment,
  instantiatePlaceholderChannel,
  onPresence,
  popSubscribersTyping,
  removeChannel,
  removeChannelGroup,
  removeReaction,
  saveChannel,
  saveChannelMessage,
  saveMessage,
  saveMoment,
  saveNotification,
  savePrayerRequest,
  savePrivateChannelInvite,
  saveReaction,
  setChannelGroups,
  setChannels,
  setChatFocus,
  setConnectionStatus,
  setGroupLoading,
  setLoading,
  setPubnubKeys,
  setMessageLikeCount,
  setSawLastMessageAt,
  submitMomentAction,
  submitMessageAction,
  updateSubscribersTyping,
} = feedSlice.actions;

export default feedSlice.reducer;
