import Pane from './pane';
import { BasePanes, Pane as IPane, PaneType } from './dux';
import { connect } from 'react-redux';
import {
  getCurrentChannel,
  getHostChannel,
  getPublicChannel,
} from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';
import { getPane } from '@store/uiSlice/selectors';
import { setPaneToType, sendPaneViewMetric } from '@store/uiSlice';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';
import { Dispatch } from 'redux';

interface StateProps {
  currentChannelId: string;
  hostChannel: string;
  isGuest: boolean;
  navbarIndex: number;
  pane: IPane;
  publicChannelId: string | undefined;
}

type State = StateProps;

const mapStateToProps = (state: RootState): State => {
  const { navbarIndex } = state.ui.nav;
  const pane = getPane(state);
  const currentChannelId = getCurrentChannel(state)?.id;
  const publicChannelId = getPublicChannel(state)?.id;
  const isGuest = !isHostViewingAsHost(state);
  const hostChannel = !isGuest ? getHostChannel(state)?.key || '' : '';

  return {
    currentChannelId,
    hostChannel,
    isGuest,
    navbarIndex,
    pane,
    publicChannelId,
  };
};

interface DispatchProps {
  setPaneToType: (type: BasePanes) => void;
  sendPaneViewMetricEvent: (pane_id: number, pane_type: PaneType, channel_id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setPaneToType: (type: BasePanes) => dispatch(setPaneToType(type)),
  sendPaneViewMetricEvent: (pane_id, pane_type, channel_id) =>
    dispatch(sendPaneViewMetric({ pane_id, pane_type, channel_id })),
});

export type Props = State & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Pane);
