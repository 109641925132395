import React, { FunctionComponent } from 'react';
import { Icon } from './styles';
import { AvatarSizeType } from './types';
import { IconSize } from '../icons';
import { Spinner } from '../Spinner';
import styled from 'styled-components';

interface Props {
  size: AvatarSizeType;
}

const AvatarLoaderIcon = styled(Icon)`
  background: rgba(45, 45, 46, 0.5);
  position: absolute;
`;

const AvatarLoader: FunctionComponent<Props> = ({ size }) => (
  <AvatarLoaderIcon size={size}>
    <Spinner size={IconSize.MD} />
  </AvatarLoaderIcon>
);

export default AvatarLoader;
