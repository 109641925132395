import { OXSmallParagraph } from '@lifechurch/react-ion';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-start: 4px;
  color: ${props => props.theme.colors.gray50};
`;

const StrengthText = styled(OXSmallParagraph)`
  color: ${props => {
    if (props.length < 8) return props.theme.colors.error.default;
    switch (props.strength) {
      case 0:
        return props.theme.colors.warning.default;
      case 1:
        return props.theme.colors.success.default;
      case 2:
        return props.theme.colors.success.default;
      default:
        return props.theme.colors.error.default;
    }
  }};
  margin-inline-start: 4px;
`;

const StrengthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { Wrapper, StrengthText, StrengthWrapper };
