import styled from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

interface HorizontalLineProps {
  overLimit: boolean;
}

export const HorizontalLine = styled.div<HorizontalLineProps>`
  border-block-end: 1px solid
    ${props => (props.overLimit ? props.theme.colors.error.default : props.theme.colors.gray50)};
  width: 16px;
  margin: auto;
`;

interface WrapperProps {
  overLimit: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  ${getInset('insetBlockEnd', '39px')};
  text-align: center;
  width: 22px;
  margin-inline-start: 5px;
  color: ${props =>
    props.overLimit ? props.theme.colors.error.default : props.theme.colors.gray50};
`;
