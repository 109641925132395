import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const OpenBookIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M1 3H14V12H9L7.5 13.5L6 12H1V3ZM5 4H2V5H5V4ZM2 6H7V7H2V6ZM7 8H2V9H7V8ZM2 10H7V11H2V10ZM13 6H8V7H13V6ZM8 8H13V9H8V8ZM13 10H8V11H13V10Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 4H2V16H8.5L10 17.5L11.5 16H18V4ZM9 6H4V8H9V6ZM4 9H9V11H4V9ZM9 12H4V14H9V12ZM16 6H11V8H16V6ZM11 9H16V11H11V9ZM16 12H11V14H16V12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M30 6H2V26H14L16 28L18 26H30V6ZM15 9H4V11H15V9ZM4 13H15V15H4V13ZM15 17H4V19H15V17ZM4 21H15V23H4V21ZM28 9H17V11H28V9ZM17 13H28V15H17V13ZM28 17H17V19H28V17ZM17 21H28V23H17V21Z'
          size={size}
          {...rest}
        />
      );
  }
};
