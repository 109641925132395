import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const QuestionCircleIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 13V15H9V13H11ZM9 8C9 7.44772 9.44772 7 10 7C10.5523 7 11 7.44772 11 8C11 8.55228 10.5523 9 10 9H9V10V12H11V10.8293C12.1652 10.4175 13 9.30622 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8H9Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16ZM17 20V22H15V20H17ZM14.5 13.5C14.5 12.6716 15.1716 12 16 12C16.8284 12 17.5 12.6716 17.5 13.5C17.5 14.3284 16.8284 15 16 15H15V16V18H17V16.8551C18.4457 16.4248 19.5 15.0855 19.5 13.5C19.5 11.567 17.933 10 16 10C14.067 10 12.5 11.567 12.5 13.5H14.5Z'
          size={size}
          {...rest}
        />
      );
  }
};
