import React, { FunctionComponent } from 'react';
import { Box } from './Box';

export interface JSONViewerProps {
  data: Record<string, unknown>;
}

const JSONViewer: FunctionComponent<JSONViewerProps> = ({ data }) => {
  return (
    <Box
      as='pre'
      fontFamily='"Lucida Console", Monaco, monospace !important'
      fontSize={2}
      lineHeight={2}
      color='white'
      backgroundColor='gray100'
      paddingLeft={4}
      paddingRight={4}
      paddingBlockStart={'10px'}
      paddingBlockEnd={'10px'}
      style={{ direction: 'ltr' }}
    >
      {JSON.stringify(data, undefined, 2)}
    </Box>
  );
};

export { JSONViewer };
