const ENV = process.env.ENV;
const METRICS_HOST = process.env.METRICS_HOST;
const IMAGE_PREFIX = process.env.IMAGE_PREFIX;
const PLATFORM_GA_TRACKING_ID = process.env.PLATFORM_GA_TRACKING_ID;
const PLATFORM_GA_4_TRACKING_ID = process.env.PLATFORM_GA_4_TRACKING_ID;
const CI_COMMIT_SHA = process.env.CI_COMMIT_SHA;

const NEW_RELIC_CONFIG = {
  accountID: process.env.NEW_RELIC_ACCOUNT_ID,
  trustKey: process.env.NEW_RELIC_TRUST_KEY,
  agentID: process.env.NEW_RELIC_AGENT_ID,
  licenseKey: process.env.NEW_RELIC_LICENSE_KEY,
  applicationID: process.env.NEW_RELIC_APPLICATION_ID,
};

export {
  ENV,
  METRICS_HOST,
  IMAGE_PREFIX,
  PLATFORM_GA_TRACKING_ID,
  PLATFORM_GA_4_TRACKING_ID,
  CI_COMMIT_SHA,
  NEW_RELIC_CONFIG,
};
