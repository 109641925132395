import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ClockIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM8 3.00005V7.29294L9.85355 9.1465L9.14645 9.8536L7.14645 7.8536L7 7.70716V7.50005V3.00005H8Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58173 2 2 5.58172 2 10C2 14.4183 5.58173 18 10 18ZM11 4V9.58578L13.2071 11.7929L11.7929 13.2071L9.29291 10.7071L9 10.4142V10V4H11Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 22C17.5228 22 22 17.5229 22 12C22 6.47715 17.5228 2 12 2C6.47717 2 2 6.47715 2 12C2 17.5229 6.47717 22 12 22ZM13 4V11.5858L16.2071 14.7929L14.7929 16.2071L11.2929 12.7071L11 12.4142V12V4H13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.82031 3 3 8.8203 3 16C3 23.1797 8.82031 29 16 29ZM17 6V15.5858L21.7071 20.2929L20.2929 21.7071L15.2929 16.7071L15 16.4142V16V6H17Z'
          size={size}
          {...rest}
        />
      );
  }
};
