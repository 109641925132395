import React from 'react';
import Message from './message';
import PrayerRequest from './prayerRequest';
import Notification from './notification';
import Moment from './moment';
import PrivateChannelInvite from './privateChannelInvite';
import { LiveObjectType } from '../../../pubnub/liveObject';
import { FeedObjectMetaData } from './dux';

interface Props {
  channel: string;
  metaData: FeedObjectMetaData;
  isCompact: boolean;
}

const FeedObject: React.FunctionComponent<Props> = ({ channel, metaData, isCompact }) => {
  switch (metaData.type) {
    case LiveObjectType.MESSAGE:
      return <Message channel={channel} metaData={metaData} isCompact={isCompact} />;
    case LiveObjectType.MOMENT:
      return <Moment channel={channel} momentId={metaData.id} isAnchored={false} />;
    case LiveObjectType.PRAYER_REQUEST:
      return <PrayerRequest channel={channel} isCompact={isCompact} metaData={metaData} />;
    case LiveObjectType.NOTIFICATION:
      return <Notification channel={channel} isCompact={isCompact} metaData={metaData} />;
    case LiveObjectType.PRIVATE_CHANNEL_INVITE:
      return <PrivateChannelInvite channel={channel} isCompact={isCompact} metaData={metaData} />;
    default:
      return null;
  }
};

export default React.memo<Props>(FeedObject);
