import React, { ReactNode, ChangeEvent, FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { getInset } from './logical';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    pointer-events: none;
    content: '';
    width: 0;
    height: 0;
    border-inline-start: 5px solid transparent;
    border-inline-end: 5px solid transparent;
    border-block-start: 5px solid ${props => props.theme.colors.gray50};
    position: absolute;
    ${getInset('insetInlineEnd', '12px')}
  }

  &:hover {
    &:after {
      border-block-start: 5px solid ${props => props.theme.colors.gray100};
    }
  }
`;

const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  pointer-events: none;
  ${getInset('insetInlineStart', '7px')}

  svg {
    path {
      fill: ${props => props.theme.colors.gray50};
    }
  }

  ${Wrapper}:hover & {
    svg {
      path {
        fill: ${props => props.theme.colors.gray100};
      }
    }
  }
`;

const Select = styled.select`
  padding-inline-start: 32px;
  padding-inline-end: 28px;
  margin: 0 auto;
  border: 1px solid ${props => props.theme.colors.gray30};
  font-size: ${props => props.theme.fontSizes[3]};
  background-color: ${props => props.theme.colors.white};
  appearance: none;
  border-radius: 16px;
  display: inline-block;
  color: ${props => props.theme.colors.gray50};
  height: 32px;
  width: 100%;
  outline: none;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;

  &:hover {
    border: 1px solid ${props => props.theme.colors.gray10};
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray100};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    border: 1px solid transparent;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.gray30};
  }
`;

// There are conflicting TS types, so we need to omit some to pass typechecking
type NativeSelect = Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size' | 'aria-relevant'>;

interface IconSelectorProps extends NativeSelect {
  icon: ReactNode;
  value: string;
  name?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const IconSelect: FunctionComponent<IconSelectorProps> = ({
  icon,
  value,
  onChange,
  name = 'customIcon-select',
  children,
  ...props
}: PropsWithChildren<IconSelectorProps>) => (
  <Wrapper>
    <IconWrapper>{icon}</IconWrapper>
    <Select onChange={onChange} value={value} data-testid={name} {...props}>
      {children}
    </Select>
  </Wrapper>
);
