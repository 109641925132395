import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CalendarIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M3 2H4V3H11V2H12V3H14V12H1V3H3V2ZM4 9V11H2V9H4ZM7 9H5V11H7V9ZM8 9H10V11H8V9ZM13 8V6H11V8H13ZM8 6H10V8H8V6ZM7 6H5V8H7V6Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M4 3H6V4H14V3H16V4H18V16H2V4H4V3ZM14 8H16V10H14V8ZM6 12H4V14H6V12ZM7.33002 12H9.33002V14H7.33002V12ZM12.66 12H10.66V14H12.66V12ZM7.33002 8H9.33002V10H7.33002V8ZM12.66 8H10.66V10H12.66V8Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M6 3H4V4H2V20H22V4H20V3H18V4H6V3ZM16.5 8H14.5V10H16.5V8ZM18 8H20V10H18V8ZM9.5 8H7.5V10H9.5V8ZM11 8H13V10H11V8ZM6 12H4V14H6V12ZM14.5 12H16.5V14H14.5V12ZM20 12H18V14H20V12ZM7.5 12H9.5V14H7.5V12ZM13 12H11V14H13V12ZM4 16H6V18H4V16ZM16.5 16H14.5V18H16.5V16ZM7.5 16H9.5V18H7.5V16ZM13 16H11V18H13V16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M8 4H5V6H2V26H30V6H27V4H24V6H8V4ZM12.25 16H9.25V19H12.25V16ZM9.25 21H12.25V24H9.25V21ZM17.5 11H14.5V14H17.5V11ZM14.5 16H17.5V19H14.5V16ZM17.5 21H14.5V24H17.5V21ZM25 11H28V14H25V11ZM28 16H25V19H28V16ZM19.75 11H22.75V14H19.75V11ZM22.75 16H19.75V19H22.75V16ZM19.75 21H22.75V24H19.75V21ZM7 16H4V19H7V16ZM4 21H7V24H4V21ZM12.25 11H9.25V14H12.25V11Z'
          size={size}
          {...rest}
        />
      );
  }
};
