import React, { useMemo } from 'react';
import { Wrapper } from './styles';
import Heart from '../icons/Heart';

import Clap from '../icons/Clap';

import RaisedHands from '../icons/RaisedHands';
import { Props } from './index';
import { ReactionType } from '../dux';

function renderReaction(reactionType: ReactionType, skinTone: number): React.ReactElement {
  switch (reactionType) {
    case ReactionType.HEART:
      return <Heart />;

    case ReactionType.CLAP:
      return <Clap tone={skinTone} />;

    case ReactionType.RAISED_HANDS:
      return <RaisedHands tone={skinTone} />;
  }
}

const Reaction: React.FunctionComponent<Props> = ({ reaction, removeReaction }) => {
  const [reactionType, tone = '1'] = reaction.type.split('::') as [ReactionType, string];
  const skinTone = parseInt(tone);
  const { time, startScale, endScale, translateX, translateY, insetInlineEnd } = useMemo(
    () => ({
      time: Number((Math.random() * 0.5 + 3.1).toFixed(1)),
      startScale: Number((Math.random() * 0.2 + 1.1).toFixed(1)),
      endScale: Number((Math.random() * 0.2 + 0.8).toFixed(1)),
      translateX: Math.floor(Math.random() * 24) - 12,
      translateY: Math.floor(Math.random() * 60) - 400,
      insetInlineEnd: Math.floor(Math.random() * 16) + 20,
    }),
    []
  );
  return (
    <Wrapper
      time={time}
      startScale={startScale}
      style={{
        opacity: 0,
        transform: `scale(${endScale}) translate(${translateX}px, ${translateY}px)`,
      }}
      insetInlineEnd={insetInlineEnd}
      data-testid='reaction'
      onAnimationEnd={() => removeReaction(reaction.id)}
    >
      {renderReaction(reactionType, skinTone)}
    </Wrapper>
  );
};

export default React.memo<Props>(Reaction);
