import React, { FC, KeyboardEventHandler, useMemo } from 'react';
import { Badge, BadgeType, Box, Flex } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import { Me, Name, SubscriberRowItem, JoinedAt } from './styles';
import { Avatar, AvatarSizeType } from '@churchonline/core';
import Role from './role';
import { Props } from './index';
import ActionMenu from '@features/audience/components/subscriberRow/actionMenu';
import { useTheme } from 'styled-components';
import Display from '@components/DateTime';
import { PrayerBadge } from './prayerBadge';
import { PublicSubscriber } from '../../../../types';
import { AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node } from '@io/__generated__/AudienceTab';
import { filterProfanity } from '@utils/censor';

export const edgeToPublicSubscriber = (
  subscriber?: AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node | null
): PublicSubscriber | null => {
  if (!subscriber) return null;

  return {
    id: subscriber.id,
    avatar: subscriber.avatar,
    roleIdentifier: subscriber.roleIdentifier,
    nickname: subscriber.nickname,
  };
};

const SubscriberRow: FC<Props> = ({
  subscriber,
  currentSubscriber,
  onSelect,
  isSelected,
  directChat,
  muteSubscriber,
  unmuteSubscriber,
  organizationId,
  setSelectedSubscriber,
  refetch,
}) => {
  const connected = subscriber?.stats?.connected || false;
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { roleIdentifier, muted } = subscriber || {};
  const isMe = subscriber?.id === currentSubscriber.id;
  const showActionMenu = isSelected && !isMe && connected;
  const publicSubscriber = useMemo(() => edgeToPublicSubscriber(subscriber), [subscriber]);
  const nickname = filterProfanity(subscriber?.nickname);

  const handleClick = () => {
    if (connected) {
      onSelect(subscriber?.id || '');
    }
  };

  const handleKeyPress: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter' && connected) {
      onSelect(subscriber?.id || '');
    }
  };

  const handleChat = () => {
    if (publicSubscriber) {
      directChat(publicSubscriber, currentSubscriber, organizationId);
      handleClick();
    }
  };

  const handleMute = () => {
    if (publicSubscriber) {
      muteSubscriber(publicSubscriber, refetch);
      handleClick();
    }
  };

  const handleUnmute = () => {
    if (publicSubscriber) {
      unmuteSubscriber(publicSubscriber, async () => await refetch());
      setSelectedSubscriber(null);
    }
  };

  return (
    <Box px={3}>
      <SubscriberRowItem
        data-testid='subscriber-row-item'
        data-id={subscriber?.id}
        onClick={handleClick}
        tabIndex={0}
        isSelected={isSelected}
        connected={connected}
        onKeyPress={handleKeyPress}
      >
        <Flex alignItems='center'>
          <Avatar
            subscriber={{
              avatar: subscriber?.avatar ?? '',
              nickname: subscriber?.nickname ?? '',
              id: subscriber?.id,
            }}
            size={AvatarSizeType.SMALL}
            testId='avatar'
            disabled={!connected}
          />
        </Flex>
        <Box>
          <Flex alignItems='center' justifyContent='start'>
            <Name connected={connected}>{nickname}</Name>
            <Role roleIdentifier={roleIdentifier} connected={connected} />
            {isMe && <Me>({t('me')})</Me>}
            {muted && (
              <Box marginInlineStart={2} wordBreak='normal'>
                <Badge
                  py='0'
                  px={theme.space[1]}
                  fontSize={theme.fontSizes[1]}
                  type={BadgeType.ERROR}
                >
                  {t('muted')}
                </Badge>
              </Box>
            )}
            {connected && <PrayerBadge count={subscriber?.stats?.channels?.prayer ?? 0} />}
          </Flex>
          <Flex alignItems='center' justifyContent='start'>
            <JoinedAt>
              Joined <Display value={subscriber?.stats?.connectedAt} format='LT' />
            </JoinedAt>
          </Flex>
        </Box>
      </SubscriberRowItem>
      {showActionMenu && (
        <ActionMenu
          onChat={handleChat}
          onMute={handleMute}
          onUnmute={handleUnmute}
          isMuted={muted || false}
        />
      )}
    </Box>
  );
};

export default React.memo(SubscriberRow, (prevProps, nextProps) => {
  return (
    prevProps.subscriber?.id === nextProps.subscriber?.id &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.subscriber?.muted === nextProps.subscriber?.muted &&
    prevProps.subscriber?.stats.connected === nextProps.subscriber?.stats.connected &&
    prevProps.subscriber?.stats.connectedAt === nextProps.subscriber?.stats.connectedAt
  );
});
