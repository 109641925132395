import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, ButtonSize, LinkButton, LinkButtonType } from '@lifechurch/react-ion';
import { AvatarWrapper, FileInputLabel, FileInputText } from '../styles';
import { Avatar, AvatarSizeType } from '@churchonline/core';
import { FileInput } from '../../profileSettings/styles';
import { Props } from './index';
import { useTranslation } from 'react-i18next';

const UploadAvatar: FC<Props> = ({
  nickname,
  currentSubscriber,
  uploadAvatar,
  promptRemoveAvatar,
  imageTooLargeError,
}) => {
  const { t } = useTranslation('forms');
  const { avatar } = currentSubscriber;
  const hasAvatar = avatar?.indexOf('missing.png') === -1 && !!avatar;
  const [avatarLoading, setAvatarLoading] = useState<boolean>(false);

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;
    if (files && files[0]) {
      const file = files[0];
      // Max file size of 10 MB
      if (file.size / 1000000 > 10) {
        imageTooLargeError();
      } else {
        const formData = new FormData();
        formData.append('avatar', file);
        uploadAvatar(currentSubscriber.id, formData);
        setAvatarLoading(true);
      }
    }
  };

  useEffect(() => {
    setAvatarLoading(false);
  }, [currentSubscriber.avatar]);

  return (
    <Box marginBlockStart={3}>
      <FileInputLabel htmlFor='avatar'>{t('profile.avatar')}</FileInputLabel>
      <AvatarWrapper>
        <Avatar
          subscriber={{
            id: currentSubscriber.id,
            nickname: nickname.trim().charAt(0) || currentSubscriber.nickname || '',
            avatar: avatar || '',
          }}
          size={AvatarSizeType.MEDIUM}
          testId='avatar'
          loading={avatarLoading}
        />
        <FileInput
          type='file'
          id='avatar'
          data-testid='avatar-upload'
          onChange={handleAvatarUpload}
        />
        {hasAvatar ? (
          <>
            <FileInputText htmlFor='avatar'>{t('profile.photo_change')}</FileInputText>
            <LinkButton
              onClick={promptRemoveAvatar}
              marginInlineStart={5}
              buttonType={LinkButtonType.ERROR}
              size={ButtonSize.SM}
              data-testid='removeAvatar'
            >
              {t('profile.photo_remove')}
            </LinkButton>
          </>
        ) : (
          <FileInputText htmlFor='avatar'>{t('profile.photo_upload')}</FileInputText>
        )}
      </AvatarWrapper>
    </Box>
  );
};

export default UploadAvatar;
