import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInterval } from '@churchonline/hooks';
import Actionable from '@components/Actionable';
import Alerts from '@components/Alert';
import TypingIndicatorIcon from '@components/icons/tpyingIndicator';
import UpArrow from '@components/icons/upArrow';
import { defaultColors } from '@lifechurch/react-ion';
import { isiOSSafariVersion15 } from '@utils/core/platform';
import { MediumPlusUpQuery } from '@utils/responsive';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  ChannelType,
  RoleIdentifierType,
  TextMode,
} from '../../../../../../../__generated__/globalTypes';
import CharacterCount, { CHARACTER_LIMIT } from './CharacterCount';
import { Props } from './index';
import InputAvatar from './InputAvatar';
import { useSpamDetection } from './spamDetection';
import {
  ActionableColumn,
  ActionableWrapper,
  Background,
  IconButton,
  Input,
  MIN_CHAT_INPUT_HEIGHT,
  TypingIndicator,
  Wrapper,
} from './styles';

const signalChannelTypes = [ChannelType.direct, ChannelType.prayer, ChannelType.host];
const isiOS15 = isiOSSafariVersion15;

const ChatInputBox: FC<Props> = props => {
  const {
    channelKey,
    closeBanner,
    currentChannel,
    currentPlaceholder,
    currentSubscriber,
    focused,
    hasRole,
    hasSso,
    isBannerVisible,
    isSignedIn,
    message,
    openProfileModal,
    openSsoValuePropModal,
    popSubscribersTyping,
    publishMessage,
    rapidMessageError,
    requestChannelAndPublish,
    saveMessage,
    setChatFocus,
    setNickname,
    showReactions,
    signup,
    textMode,
    translateLanguage,
    typingInChannel,
    viewingAsUser,
  } = props;

  const { type, subscribers, subscribersTyping = [] } = currentChannel;

  const timerRef = useRef<NodeJS.Timeout>();
  const isCompact = textMode === TextMode.COMPACT;
  const inputRefContainer = useRef<HTMLTextAreaElement>(null);
  const isMediumPlusOrUp = useMediaQuery({ query: MediumPlusUpQuery });

  const shouldSendSignal =
    signalChannelTypes.includes(type) &&
    currentSubscriber.nickname !== '' &&
    !currentChannel.placeholderChannel;

  const { t } = useTranslation();
  const { isSpam, wantToSendMessage } = useSpamDetection();

  const [chatHeight, setChatHeight] = useState(MIN_CHAT_INPUT_HEIGHT);

  const visibilityHandler = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      inputRefContainer.current?.blur();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
    };
  }, [visibilityHandler]);

  const resetChatHeight = () => {
    setChatHeight(MIN_CHAT_INPUT_HEIGHT);
  };

  const nicknamesOfSubscribersTyping: string[] =
    subscribers
      ?.filter(
        ({ id, nickname }) =>
          subscribersTyping?.find(subscriber => subscriber.id === id) && !!nickname
      )
      .map(({ nickname }) => nickname as string) ?? [];

  const clearTypingInChannel = () => {
    if (shouldSendSignal) {
      typingInChannel(false, channelKey);
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const restartTypingTimer = () => {
    typingInChannel(true, channelKey);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      clearTypingInChannel();
    }, 10000);
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return clearTypingInChannel;
  }, []);

  // Cleans up people who may have been typing, but closed their browser
  useInterval(
    () => {
      if (subscribersTyping) {
        popSubscribersTyping(channelKey);
      }
    },
    shouldSendSignal ? 12000 : null
  );

  if (!currentChannel) return null;

  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage === '') {
      saveMessage(channelKey, '');
    } else if (currentChannel?.placeholderChannel) {
      requestChannelAndPublish(
        trimmedMessage,
        currentSubscriber,
        translateLanguage,
        currentChannel
      );
      saveMessage(channelKey, '');
    } else if (currentSubscriber.nickname !== '') {
      wantToSendMessage();
      if (currentChannel.type === 'public' && !hasRole && isSpam) {
        rapidMessageError();
        saveMessage(channelKey, trimmedMessage);
      } else if (trimmedMessage.length <= CHARACTER_LIMIT) {
        const subscriberWithoutRole = {
          ...currentSubscriber,
          roleIdentifier: {
            label: '',
            key: '',
            type: RoleIdentifierType.TEXT,
          },
        };
        if (viewingAsUser) {
          publishMessage(trimmedMessage, subscriberWithoutRole, translateLanguage, currentChannel);
          saveMessage(channelKey, '');
        } else {
          publishMessage(trimmedMessage, currentSubscriber, translateLanguage, currentChannel);
          saveMessage(channelKey, '');
        }
      }
    } else if (trimmedMessage.length <= CHARACTER_LIMIT) {
      if (inputRefContainer.current) {
        inputRefContainer.current.blur();
      }
      saveMessage(channelKey, trimmedMessage);
      setNickname();
    }
    clearTypingInChannel();
    if (trimmedMessage.length <= CHARACTER_LIMIT) resetChatHeight();
  };

  const measureInputScrollHeight = (newMessageValue?: string) => {
    if (inputRefContainer.current) {
      const height = inputRefContainer.current.scrollHeight;

      // Focusing adds 2px of border, so account for that here.
      if (height > MIN_CHAT_INPUT_HEIGHT + 2 && !!newMessageValue) {
        setChatHeight(height - 4);
      } else if (chatHeight !== MIN_CHAT_INPUT_HEIGHT) {
        resetChatHeight();
      }
    }
  };

  const onTextEntered = (event: ChangeEvent<HTMLTextAreaElement>) => {
    saveMessage(channelKey, event.target.value);

    if (shouldSendSignal) {
      if (event.target.value.trim().length === 0) {
        clearTypingInChannel();
      } else {
        restartTypingTimer();
      }
    }

    if (cursorIsAtEndOfMessage()) {
      measureInputScrollHeight(event.target.value);
    }
  };

  const onKeyPressed = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && message.length > 0) {
      event.preventDefault();
      sendMessage();
    }
  };

  const onFocus = () => {
    setChatFocus(channelKey);
    measureInputScrollHeight(message);
    isBannerVisible && closeBanner();
  };

  const onBlur = () => {
    setChatFocus('');
    resetChatHeight();
  };

  const formatTypingIndicatorText = () => {
    const count = nicknamesOfSubscribersTyping.length > 3 ? 3 : nicknamesOfSubscribersTyping.length;

    return t(`typing_indicator_${count}`, {
      nickname: nicknamesOfSubscribersTyping[0],
      nicknameTwo: nicknamesOfSubscribersTyping[1],
    });
  };

  const shouldShowIndicator = () => {
    if (nicknamesOfSubscribersTyping.length > 0) return true;
    return type === ChannelType.host && subscribersTyping.length > 0;
  };

  const cursorIsAtEndOfMessage = () => {
    const { current } = inputRefContainer;
    return current && current.value.length === current.selectionEnd;
  };

  return (
    <Background
      chatIsMultiline={chatHeight > MIN_CHAT_INPUT_HEIGHT}
      data-testid='chat'
      showReactions={showReactions}
    >
      {shouldShowIndicator() && (
        <TypingIndicator data-testid='typing-indicator' isCompact={isCompact}>
          <TypingIndicatorIcon /> {formatTypingIndicatorText()}
        </TypingIndicator>
      )}
      <Alerts data-testid='chatAlert' containerId='chat' />
      <Wrapper focused={focused} isiOSSafariVersion15={isiOS15}>
        <InputAvatar
          closeReactionBanner={closeBanner}
          hasSso={hasSso}
          isSignedIn={isSignedIn}
          openProfileModal={openProfileModal}
          openSignUpModal={signup}
          openSsoValuePropModal={openSsoValuePropModal}
          show={currentChannel.type === ChannelType.public || !isMediumPlusOrUp}
          subscriber={currentSubscriber}
        />
        <Input
          aria-label={t('aria.chat_message')}
          autoComplete='off'
          data-testid='chat-input'
          height={chatHeight}
          maxLength={1000}
          onBlur={onBlur}
          onChange={onTextEntered}
          onFocus={onFocus}
          onKeyPress={onKeyPressed}
          placeholder={currentPlaceholder}
          ref={inputRefContainer}
          rows={1}
          value={message}
        />
        <ActionableColumn>
          <CharacterCount count={message.length} show={chatHeight > MIN_CHAT_INPUT_HEIGHT} />
          <ActionableWrapper>
            <Actionable keepFocus={true} onClick={sendMessage}>
              <IconButton
                data-testid='chat-submit-button'
                grayed={message.length > CHARACTER_LIMIT}
                hidden={!message}
              >
                <UpArrow
                  size={24}
                  color={message.length > CHARACTER_LIMIT ? defaultColors.gray50 : 'white'}
                />
              </IconButton>
            </Actionable>
          </ActionableWrapper>
        </ActionableColumn>
      </Wrapper>
    </Background>
  );
};

export default ChatInputBox;
