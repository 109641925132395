import { ModalType } from '../dux';

export interface IframeModal {
  type: ModalType.IFRAME;
  src: string;
}

export const iframeModal = (src: string): IframeModal => ({
  type: ModalType.IFRAME,
  src,
});
