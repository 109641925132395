import React, { FunctionComponent } from 'react';
import { CurrentState_currentOrganization_integrations_Gloo_config } from '@io/__generated__/CurrentState';

interface Props {
  config: CurrentState_currentOrganization_integrations_Gloo_config;
}

const Gloo: FunctionComponent<Props> = ({ config }) => {
  return (
    <img
      alt='Gloo'
      style={{ display: 'none' }}
      src={`https://di.rlcdn.com/${config?.glooId}.gif`}
    />
  );
};

export default Gloo;
