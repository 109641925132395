import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowUpIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M3.14648 6.64645L7.50004 2.29289L11.8536 6.64645L11.1465 7.35355L8.00004 4.20711L8.00004 12L7.00004 12L7.00004 4.20711L3.85359 7.35355L3.14648 6.64645Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M3.29297 8.29289L10.0001 1.58579L16.7072 8.29289L15.293 9.70711L11.0001 5.41421V18H9.00008V5.41421L4.70718 9.70711L3.29297 8.29289Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M3.29297 10.2929L12.0001 1.58579L20.7072 10.2929L19.293 11.7071L13.0001 5.41421L13.0001 22H11.0001V5.41421L4.70718 11.7071L3.29297 10.2929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M5.29297 12.2929L16.0001 1.58579L26.7072 12.2929L25.293 13.7071L17.0001 5.41422V30H15.0001L15.0001 5.41422L6.70718 13.7071L5.29297 12.2929Z'
          size={size}
          {...rest}
        />
      );
  }
};
