import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const HeartIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M6.94806 3.02739L6.9663 3.04576L7.49905 3.57851L8.05026 3.0273C9.42013 1.65748 11.6412 1.65758 13.011 3.02743C14.3809 4.39729 14.3809 6.61827 13.011 7.98813L12.9899 8.00912L7.49914 13.5L1.98724 7.98811C0.617432 6.61825 0.617516 4.39724 1.98736 3.02739C3.35722 1.65753 5.5782 1.65753 6.94806 3.02739Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M9.28354 4.33538L10 5.05183L10.7164 4.33545C12.497 2.55486 15.384 2.55481 17.1645 4.33541C18.9451 6.116 18.9451 9.00291 17.1645 10.7835L17.1579 10.7902L10 17.948L2.84593 10.7939L2.83544 10.7835C1.05485 9.00289 1.05485 6.11598 2.83544 4.33538C4.61604 2.55479 7.50295 2.55479 9.28354 4.33538Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20.8503 12.6149L20.8404 12.6249L12 21.4652L3.17291 12.6381L3.14966 12.615C0.950113 10.4154 0.950113 6.84926 3.14966 4.64972C5.34921 2.45017 8.91538 2.45017 11.1149 4.64972L12 5.53469L12.885 4.64975C15.0845 2.4502 18.6508 2.45011 20.8503 4.64966C23.0499 6.84921 23.0499 10.4154 20.8503 12.6149Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M14.7778 6.27799C11.7403 3.24057 6.81557 3.24065 3.77811 6.27811C0.74063 9.31559 0.740629 14.2403 3.77811 17.2778L3.8032 17.3028L15.9998 29.4998L28.1977 17.3019L28.2219 17.2778C31.2594 14.2404 31.2594 9.31562 28.2219 6.27814C25.1845 3.24065 20.2597 3.24065 17.2222 6.27814L16 7.50024L14.7778 6.27799Z'
          size={size}
          {...rest}
        />
      );
  }
};
