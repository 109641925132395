import React from 'react';
import { OHeaderThree, Modal, ModalSize, ModalText } from '@lifechurch/react-ion';
const ShareSheet = ({ open, handleModalClose, children }) => (React.createElement(Modal, { open: open, dismiss: handleModalClose, includePadding: true, dismissible: true, size: ModalSize.NARROW },
    React.createElement(ModalText, null,
        React.createElement(OHeaderThree, null, "Share")),
    children));
export { ShareSheet };
export { ImageMomentShareSheet, getImageShareUrl } from './image-moment';
export { InviteMomentShareSheet } from './invite-moment';
export { QuoteMomentShareSheet } from './quote-moment';
