import React, { FunctionComponent } from 'react';
import { ImageMoment as BaseImageMoment, getImageShareUrl } from '@churchonline/moments';
import { MomentActionType, MomentType } from '../../../../../../__generated__/globalTypes';
import { Props } from './index';
import { imageMomentShareSheet } from '../../../../../components/modal/dux';
import { imageMomentFocusView } from '../../../../../components/modal/dux';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { isMacOS } from '@utils/core';

interface ShareObj {
  title: string;
  text: string;
  url: string;
}

export const webShareAPI = (shareObj: ShareObj): void => {
  navigator
    .share(shareObj)
    .then(() => {})
    .catch((e: Error) => {
      const error = e.toString();
      // It will reject immediately if the data parameter
      // is not correctly specified, and will also reject
      // if the user cancels sharing. (MDN Navigator.share())
      if (error.includes('AbortError')) {
        return;
      }

      if (error.includes('TypeError')) {
        // I include the shareObj, because TypeError indicates a data parameter error.
        // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-425974249
        Sentry.withScope(scope => {
          scope.setExtra('shareObject', shareObj);
          Sentry.captureException(e);
        });
      }
    });
};

const ImageMoment: FunctionComponent<Props> = ({
  moment,
  hasLiked,
  isPinned,
  onDismiss,
  setModal,
  submitMomentAction,
  serviceTitle,
}) => {
  const { altText, imageUrl, shareText } = moment.momentTemplate.options;
  const { t } = useTranslation('moments');
  const likeButtonLabel = t('Like');
  const shareButtonLabel = t('Share');

  const shouldUseNativeShareSheet = typeof navigator?.share !== 'undefined' && !isMacOS();

  const handleShare = (): void => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.IMAGE,
      momentActionType: MomentActionType.SHARE,
    });

    if (shouldUseNativeShareSheet) {
      webShareAPI({
        title: serviceTitle,
        text: shareText,
        url: getImageShareUrl(imageUrl, serviceTitle, shareText),
      });
    } else {
      setModal(imageMomentShareSheet(altText, imageUrl, shareText, serviceTitle));
    }
  };

  const handleLike = (): void => {
    if (!hasLiked) {
      submitMomentAction({
        channelKey: moment.channel.key,
        momentInstanceId: moment.id,
        momentType: MomentType.IMAGE,
        momentActionType: MomentActionType.LIKE,
      });
    }
  };

  const handleImageClick = (): void => {
    setModal(imageMomentFocusView({ meta: { moment } }));
  };

  return (
    <BaseImageMoment
      altText={altText}
      hasLiked={hasLiked}
      image={imageUrl}
      isPinned={isPinned}
      likeButtonLabel={likeButtonLabel}
      likeCount={moment.likes}
      onDismiss={onDismiss}
      onImageClick={handleImageClick}
      onLike={handleLike}
      onShare={handleShare}
      shareButtonLabel={shareButtonLabel}
    />
  );
};

export default ImageMoment;
