import React, { KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';
import {
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  OParagraph,
  ModalSize,
  ModalText,
} from '@lifechurch/react-ion';

const DeleteAccount = ({ closeModal, deleteAccount, openProfileSettings }: Props) => {
  const { t } = useTranslation('forms');

  const handleCancelKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      openProfileSettings();
    }
  };

  const handleRemoveAccountKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      deleteAccount();
    }
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='deleteAccount-modal'>
      <ModalText>
        <OParagraph marginBlockEnd={5} data-testid='deleteAccount-confirmText'>
          {t('delete_account.message')}
        </OParagraph>
      </ModalText>
      <ModalButtons>
        <LinkButton
          buttonType={LinkButtonType.SECONDARY}
          onClick={openProfileSettings}
          data-testid='deleteAccount-cancel'
          marginInlineEnd={5}
          onKeyDown={handleCancelKeyDown}
          tabIndex={0}
          role='button'
        >
          {t('delete_account.cancel')}
        </LinkButton>
        <LinkButton
          buttonType={LinkButtonType.ERROR}
          onClick={deleteAccount}
          data-testid='deleteAccount-confirm'
          onKeyDown={handleRemoveAccountKeyDown}
          tabIndex={0}
          role='button'
        >
          {t('delete_account.confirm')}
        </LinkButton>
      </ModalButtons>
    </Modal>
  );
};

export default DeleteAccount;
