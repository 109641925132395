/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChannelType {
  command = "command",
  direct = "direct",
  host = "host",
  message_action = "message_action",
  moment = "moment",
  personal = "personal",
  prayer = "prayer",
  public = "public",
  reaction = "reaction",
}

export enum EmailConsentType {
  ALL = "ALL",
  EU = "EU",
  OFF = "OFF",
}

export enum IntegrationType {
  FACEBOOK_APP = "FACEBOOK_APP",
  FACEBOOK_PIXEL = "FACEBOOK_PIXEL",
  FRONT = "FRONT",
  GLOO = "GLOO",
  GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
  GOOGLE_ANALYTICS_4 = "GOOGLE_ANALYTICS_4",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  GOOGLE_TAG_MANAGER = "GOOGLE_TAG_MANAGER",
  HUBSPOT = "HUBSPOT",
  LEGACY_AUTH = "LEGACY_AUTH",
  OPEN_ID_CONNECT = "OPEN_ID_CONNECT",
  OSANO = "OSANO",
  SEGMENT = "SEGMENT",
  WEBHOOKS_INTEGRATION = "WEBHOOKS_INTEGRATION",
}

export enum MessageActionType {
  LIKE = "LIKE",
}

export enum MomentActionType {
  LIKE = "LIKE",
  SHARE = "SHARE",
  SUBMIT = "SUBMIT",
}

export enum MomentTriggerType {
  SCHEDULER = "SCHEDULER",
  USER = "USER",
}

export enum MomentType {
  CONNECT = "CONNECT",
  GIVING = "GIVING",
  IMAGE = "IMAGE",
  INVITE = "INVITE",
  PRAYER = "PRAYER",
  QUOTE = "QUOTE",
  RESPONSE = "RESPONSE",
  SALVATION = "SALVATION",
  SIGNUP = "SIGNUP",
}

export enum NavigationLinkOption {
  TITLE = "TITLE",
  UPPER = "UPPER",
}

export enum OfflineContentType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum OrganizationStatus {
  APPROVED = "APPROVED",
  DEACTIVATED = "DEACTIVATED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum RoleIdentifierType {
  SYMBOL = "SYMBOL",
  TEXT = "TEXT",
}

export enum RoleType {
  ADMIN = "ADMIN",
  GLOBAL = "GLOBAL",
  HOST = "HOST",
  OWNER = "OWNER",
  SERVICE = "SERVICE",
}

export enum SalvationMomentFollowUpType {
  NONE = "NONE",
  REQUEST_PRAYER = "REQUEST_PRAYER",
  URL_MODAL = "URL_MODAL",
  URL_NEW_TAB = "URL_NEW_TAB",
}

/**
 * The type of sequence event
 */
export enum SequenceQueryType {
  FEED = "FEED",
  SERVICE = "SERVICE",
  VIDEO = "VIDEO",
}

export enum SubscriberFieldType {
  EMAIL = "EMAIL",
}

export enum SubscriberType {
  ADMIN = "ADMIN",
  ALIAS_GUEST = "ALIAS_GUEST",
  ANONYMOUS_GUEST = "ANONYMOUS_GUEST",
  GLOBAL = "GLOBAL",
  GUEST = "GUEST",
  HOST = "HOST",
  OWNER = "OWNER",
  SERVICE = "SERVICE",
  USER = "USER",
}

export enum TextMode {
  COMFORTABLE = "COMFORTABLE",
  COMPACT = "COMPACT",
}

/**
 * The type of video
 */
export enum VideoType {
  LIVE = "LIVE",
  NONE = "NONE",
  OFFLINE = "OFFLINE",
  SIMULATED = "SIMULATED",
}

export enum WindowType {
  IFRAME = "IFRAME",
  NEW_TAB = "NEW_TAB",
}

/**
 * Attributes for accepting an invite
 */
export interface AcceptInviteInput {
  subscriberId: string;
}

/**
 * Attributes for creating or updating areas of ministry.
 */
export interface AreaOfMinistriesInput {
  id: string;
  key: string;
  label: string;
}

/**
 * Attributes for changing a password
 */
export interface ChangePasswordInput {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;
}

/**
 * Return value for Channel Input Request.
 */
export interface ChannelInput {
  id: string;
  meta?: ChannelInputMeta | null;
  serviceId: string;
  type: ChannelType;
}

/**
 * Meta data for request channel.
 */
export interface ChannelInputMeta {
  raisedHand?: boolean | null;
}

/**
 * Attributes for deleting a Moment Instance.
 */
export interface DeleteMomentInstanceInput {
  id: string;
}

export interface IntegrationSearchFilter {
  OR?: IntegrationSearchFilter[] | null;
  enabled?: boolean | null;
  typeEq?: IntegrationType | null;
  typeIn?: IntegrationType[] | null;
}

/**
 * Input fields for a MessageAction
 */
export interface MessageActionInput {
  channelId: string;
  id: string;
  messageId: string;
  type: MessageActionType;
}

/**
 * The channel object on for a message.
 */
export interface MessageChannelInput {
  direct: boolean;
  id: string;
  key: string;
  name: string;
  type: ChannelType;
}

/**
 * The channel object on for a message.
 */
export interface MessageInput {
  channel: MessageChannelInput;
  deleted: boolean;
  id: string;
  lang: string;
  subscriberId: string;
  text: string;
  timestamp: any;
  translations: MessageTranslationInput[];
}

/**
 * A translation of a message into another language.
 */
export interface MessageTranslationInput {
  languageCode: string;
  text: string;
}

/**
 * Attributes for creating or updating a Moment Action.
 */
export interface MomentActionInput {
  actionType: MomentActionType;
  id: string;
  momentInstanceId: string;
}

/**
 * Attributes for creating or updating a Moment Instance.
 */
export interface MomentInstanceInput {
  deleteJob?: boolean | null;
  id: string;
  momentTemplateId: string;
  postTime?: any | null;
  serviceId: string;
  trigger?: MomentTriggerType | null;
}

/**
 * Attributes for requesting prayer.
 */
export interface RequestPrayerInput {
  email: string;
  id: string;
  message: string;
  name: string;
}

/**
 * Input for reverting subscriber fields back to their previous values.
 */
export interface RevertSubscriberInput {
  fields: SubscriberFieldType[];
}

/**
 * Default attributes
 */
export interface SubscriberDefaultsInput {
  avatar?: string | null;
  nickname?: string | null;
}

/**
 * Attributes for creating or updating a user.
 */
export interface SubscriberInput {
  areaOfMinistries?: AreaOfMinistriesInput[] | null;
  avatar?: string | null;
  city?: string | null;
  countryCode?: string | null;
  countryName?: string | null;
  defaults?: SubscriberDefaultsInput | null;
  email?: string | null;
  emailConsent?: boolean | null;
  firstName?: string | null;
  gdpr?: boolean | null;
  id?: string | null;
  ip?: string | null;
  language?: string | null;
  lastName?: string | null;
  muted?: boolean | null;
  nickname?: string | null;
  password?: string | null;
  preferences?: SubscriberPreferencesInput | null;
  region?: string | null;
  roleId?: string | null;
  roleIdentifierId?: string | null;
}

/**
 * Attributes for creating or updating preferences.
 */
export interface SubscriberPreferencesInput {
  skinTone?: number | null;
  textMode?: TextMode | null;
}

export interface SubscribersFilter {
  channelId?: string | null;
  channelType?: string | null;
  muted?: boolean | null;
  organizationId?: string | null;
  search?: string | null;
  subscriberTypeIn?: SubscriberType[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
