import styled from 'styled-components';
import { OParagraph, OSmallParagraph } from '@lifechurch/react-ion';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
`;

const Title = styled(OParagraph)`
  font-weight: 700;
  margin-block-start: 16px;
`;

const SupportText = styled(OSmallParagraph)`
  margin-block-end: 16px;
`;

export { Wrapper, Title, SupportText };
