import { useEffect } from 'react';
import { getCLS, getFCP, getFID, getLCP, getTTFB, ReportHandler } from 'web-vitals';

const useWebVitals = (onReport: ReportHandler): void => {
  useEffect(() => {
    getCLS(onReport);
    getFCP(onReport);
    getFID(onReport);
    getLCP(onReport);
    getTTFB(onReport);
  }, [onReport]);
};

export { useWebVitals };
