import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// @ts-expect-error
import createExpirationTransform from 'redux-persist-transform-expire';
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import { initialState } from '@store/uiSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import reducer from '@store/rootReducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import PubnubClient from '@io/pubnubClient';
import { rootSaga } from '@io/saga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['organization', 'ui', 'feed'],
  transforms: [
    createWhitelistFilter('ui', ['languageOptions', 'largeHostVideo']),
    createWhitelistFilter('feed', [
      'persistExpiresAt',
      'momentSubmissions',
      'momentLikes',
      'messageLikes',
    ]),
    createBlacklistFilter('organization', ['offlinePrayerRecipients']),
    createExpirationTransform({
      initialState,
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const customizedMiddleware = getDefaultMiddleware({
  thunk: false,
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
}).concat(sagaMiddleware);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

PubnubClient.setDispatch(store.dispatch);
sagaMiddleware.run(rootSaga);
