import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PlaceholderText, PlaceholderWrapper } from '../styles';
import { CommentIcon, IconSize } from '@lifechurch/react-ion';

interface Props {
  otherSubscribersNickname: string;
  pendingPrayer: boolean;
  hostViewingAsHost: boolean;
}

const ChatPlaceholder: FC<Props> = ({
  otherSubscribersNickname,
  pendingPrayer,
  hostViewingAsHost,
}) => {
  const { t } = useTranslation('common');

  return (
    <PlaceholderWrapper>
      <CommentIcon size={IconSize.XL} />
      {pendingPrayer && !hostViewingAsHost ? (
        <PlaceholderText>{t('prayer_pending')}</PlaceholderText>
      ) : (
        <PlaceholderText>
          <Trans i18nKey='channel_pending'>
            Send a message to start chatting with{' '}
            <strong>{{ nickname: otherSubscribersNickname }}</strong>.
          </Trans>
        </PlaceholderText>
      )}
    </PlaceholderWrapper>
  );
};

export default ChatPlaceholder;
