import { PrayerMoment as PrayerMomentInterface } from '../dux';
import VisiblePrayerMoment from './prayerMoment';
import { PublicSubscriber as Subscriber } from '../../../../../types';
import { isLivePrayerEnabled } from '@store/serviceSlice/selectors';
import { getCurrentSubscriberAsPublicSubscriber } from '@store/subscriberSlice/selectors';
import { connect } from 'react-redux';
import { requestLivePrayer, submitMomentAction } from '@store/feedSlice';
import { bindActionCreators, Dispatch } from 'redux';
import { setModal } from '@store/uiSlice';
import { RootState } from '@store/rootReducer';

interface StateProps {
  currentSubscriber: Subscriber;
  isLivePrayerEnabled: boolean;
}

interface OwnProps {
  moment: PrayerMomentInterface;
  onDismiss: () => void;
  isPinned: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const livePrayerEnabled = isLivePrayerEnabled(state);

  return {
    currentSubscriber: getCurrentSubscriberAsPublicSubscriber(state),
    isLivePrayerEnabled: livePrayerEnabled,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      submitMomentAction,
      requestLivePrayer,
      setModal,
    },
    dispatch
  );

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(VisiblePrayerMoment);
