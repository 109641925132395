import { ButtonSize, LinkButton } from '@lifechurch/react-ion';
import React, { FC, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { EngagementWrapper, ReactionButton, LikeCount, HeartAnimation } from './styles';
import LikeMessageIcon from './likeMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  hasLiked?: boolean;
  likeCount?: number;
  onLike: () => void;
}

const LikeEngagement: FC<Props> = ({ hasLiked = false, likeCount = 0, onLike }) => {
  const { colors } = useContext(ThemeContext);
  const [showAnimation, setShowAnimation] = useState(false);
  const handleOnClick = () => {
    if (!hasLiked) {
      setShowAnimation(true);
    }
    onLike();
  };
  const { t } = useTranslation();

  return (
    <EngagementWrapper onClick={handleOnClick}>
      {!hasLiked && (
        <LinkButton
          buttonType='SECONDARY'
          hasLiked={hasLiked}
          marginInlineEnd={'4px'}
          size={ButtonSize.SM}
          lineHeight='17px'
        >
          {t('like')}
        </LinkButton>
      )}
      {showAnimation && (
        <HeartAnimation
          data-testid={'heart-animation-icon'}
          onAnimationEnd={() => setShowAnimation(false)}
        >
          <LikeMessageIcon width={13} height={11} color={colors.pink.default} />
        </HeartAnimation>
      )}
      {likeCount > 0 && (
        <ReactionButton data-testid='heart-icon-button' hasLiked={hasLiked}>
          <LikeMessageIcon
            width={13}
            height={11}
            color={hasLiked ? colors.pink.default : colors.gray50}
          />
          <LikeCount>{likeCount} </LikeCount>
        </ReactionButton>
      )}
    </EngagementWrapper>
  );
};

export default LikeEngagement;
