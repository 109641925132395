import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  compose,
} from 'styled-system';
import { ButtonSize, SecondaryButton } from './Button';
import { InputSize, StyledLabel } from './Input';
import { OXSmallParagraph } from './Typography';
import { Spinner } from './Spinner';
import { ErrorCircleIcon, IconSize } from './icons';
import { getDefaultTheme } from './theme';
import { logical, LogicalProps } from './logical';

export enum UploaderState {
  NoImage = 'noimage',
  Loading = 'loading',
  Error = 'error',
  Image = 'image',
}

interface UploaderProps {
  /**
   * A description of the uploader's job
   */
  title?: string;
  /**
   * Occupies the position of the file name before it uploads
   */
  placeholderText?: string;
  /**
   * The text within the button
   */
  buttonText?: string;
  /**
   * A description of what the uploaded file will be used for and the recommended file size
   */
  context?: ReactNode;
  /**
   * A callback to handle file upload
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  /**
   * removes the file that was uploaded
   */
  onRemove: () => void;
  /**
   * The current state of the file
   */
  state: UploaderState;
  /**
   * Text that displays if there is an error while uploading
   */
  hint?: ReactNode;
  /**
   *  The width of the image
   */
  imageWidth?: number;
  /**
   * The height of the image
   */
  imageHeight?: number;
  /**
   * Allows for a preview of the uploaded file
   */
  imageUrl?: string;
  /**
   * attribute specifies the name of an input element
   */
  name?: string;
}

type BaseProps = BorderProps & SpaceProps & LogicalProps & TypographyProps;

interface ImageBoxProps extends BorderProps, SpaceProps, LogicalProps, TypographyProps {
  imageWidth: number;
  imageHeight: number;
  state: UploaderState;
  url: string;
  hint: ReactNode;
}

interface ImageProps extends BorderProps, SpaceProps, LogicalProps, TypographyProps {
  url: string;
}

interface ImageBoxStyleProps extends BorderProps, SpaceProps, LogicalProps, TypographyProps {
  imageWidth: number;
  imageHeight: number;
  state: UploaderState;
  url: string;
}

interface ButtonProps extends BorderProps, SpaceProps, LogicalProps, TypographyProps {
  state: UploaderState;
  buttonText: string;
  placeholderText: string;
  removeImage: () => void;
  chooseImage: () => void;
}

const UploaderWrapper = styled.div`
  width: 362px;
`;

const ImageBoxStyle = styled.div<Omit<ImageBoxStyleProps, 'url'>>`
  box-sizing: border-box;
  width: ${props => props.imageWidth}px;
  height: ${props => props.imageHeight}px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.state === UploaderState.Error
        ? props.theme.colors.error.default
        : props.theme.colors.gray30};
  /* Use flex box to make Image full height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${compose(border, space, logical, typography)}
`;

const Image = styled.div.attrs({
  // For some reason the background-size property only has effect when put inline
  style: { backgroundSize: 'contain' },
})<ImageProps>`
  flex-grow: 1;
  align-self: stretch;
  background: url('${props => props.url}') no-repeat center;
`;

const RemoveButton = styled.div<BaseProps>`
  cursor: pointer;
  display: inline-block;
  color: ${props => props.theme.colors.gray50};

  :hover {
    color: ${props => props.theme.colors.error.default};
    text-decoration: underline;
  }

  ${compose(border, space, logical, typography)}
`;

const BlockStyledLabel = styled(StyledLabel)`
  display: block;
`;

const FileInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`;

const Context = styled(OXSmallParagraph)`
  color: ${props => props.theme.colors.gray50};
`;

const ContextError = styled(Context)`
  color: ${props => props.theme.colors.error.default};
  margin-block-start: ${props => props.theme.space[1]}px;
`;

const ImageBox: FunctionComponent<ImageBoxProps> = ({
  imageWidth,
  imageHeight,
  state,
  url,
  hint,
}: ImageBoxProps) => {
  if (state === UploaderState.NoImage) {
    return null;
  } else {
    return (
      <>
        <ImageBoxStyle imageWidth={imageWidth} imageHeight={imageHeight} state={state}>
          {state === UploaderState.Error && (
            <ErrorCircleIcon color={getDefaultTheme().colors.error.default} />
          )}
          {state === UploaderState.Loading && (
            <Spinner size={IconSize.MD} color={getDefaultTheme().colors.gray30} />
          )}
          {state === UploaderState.Image && <Image url={url} />}
        </ImageBoxStyle>
        {state === UploaderState.Error && <ContextError>{hint}</ContextError>}
      </>
    );
  }
};

const Button: FunctionComponent<ButtonProps> = ({
  state,
  buttonText,
  chooseImage,
  removeImage,
  placeholderText,
}: ButtonProps) => {
  if (state === UploaderState.Image) {
    return (
      <RemoveButton
        marginBlockStart={4}
        marginBlockEnd={4}
        fontWeight={2}
        fontSize={3}
        lineHeight={3}
        onClick={removeImage}
      >
        {buttonText}
      </RemoveButton>
    );
  } else if (state === UploaderState.Loading) {
    return (
      <BlockStyledLabel
        style={{ color: getDefaultTheme().colors.gray50 }}
        marginBlockStart={4}
        marginBlockEnd={4}
        fontSize={3}
        lineHeight={3}
        size={InputSize.MD}
      >
        {buttonText}
      </BlockStyledLabel>
    );
  } else {
    return (
      <>
        <SecondaryButton
          type='button'
          marginBlockStart={state === UploaderState.NoImage ? 0 : 8}
          marginBlockEnd={4}
          size={ButtonSize.SM}
          onClick={chooseImage}
        >
          {buttonText}
        </SecondaryButton>
        <StyledLabel marginInlineStart={4} size={InputSize.MD}>
          {placeholderText}
        </StyledLabel>
      </>
    );
  }
};
/**
 * Uploader component for uploading a file.
 *
 *
 * ###When To Use
 *
 * - When you need to allow a user to upload a file
 *
 */
export const Uploader: FunctionComponent<UploaderProps> = ({
  title = '',
  placeholderText = 'No file chosen',
  buttonText = 'Choose File',
  context = '',
  onChange,
  onRemove,
  state,
  hint = '',
  imageWidth = 200,
  imageHeight = 100,
  imageUrl = '',
  name = 'uploadImage',
}: UploaderProps) => {
  const imageUploadInput = React.createRef<HTMLInputElement>();

  const chooseImageClick = (): void => {
    if (imageUploadInput && imageUploadInput.current) {
      imageUploadInput.current.click();
    }
  };

  if (state === UploaderState.NoImage && imageUploadInput.current) {
    imageUploadInput.current.value = '';
  }

  return (
    <UploaderWrapper>
      <BlockStyledLabel fontWeight={2} lineHeight={3} marginBlockEnd={2} size={InputSize.MD}>
        {title}
      </BlockStyledLabel>
      <ImageBox
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        state={state}
        url={imageUrl}
        hint={hint}
      />
      <Button
        state={state}
        chooseImage={chooseImageClick}
        removeImage={onRemove}
        buttonText={buttonText}
        placeholderText={placeholderText}
      />
      <Context>{context}</Context>
      <FileInput
        name={name}
        ref={imageUploadInput}
        onChange={onChange}
        type='file'
        data-testid={name}
      />
    </UploaderWrapper>
  );
};
