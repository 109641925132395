import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseAlert } from '../BaseAlert';
import { Props } from './index';
import { ActionableAlertCallToAction, IconWrapper, RoleTag, Spacer } from '../styles';
import { Box, OParagraph } from '@lifechurch/react-ion';
import RoleIdentifierInvertedIcon from '../../icons/roleIdentifierInverted';
import { RoleIdentifierType } from '../../../../__generated__/globalTypes';

const DirectChatAlert: FC<Props> = ({ alert, dismissAlert, setPaneToChat }) => {
  const { t } = useTranslation('');
  const { t: nt } = useTranslation('notifications');

  const onClick = () => {
    if (alert?.meta?.channelKey) {
      dismissAlert();
      setPaneToChat(alert.meta.channelKey);
    }
  };

  if (!alert?.variables?.name) {
    return null;
  }

  const { name, role, roleIdentifierKey, roleIdentifierLabel, roleIdentifierType } =
    alert?.variables || {};
  const label = roleIdentifierLabel ? roleIdentifierLabel : role;

  return (
    <BaseAlert
      uniqueKey={alert.uniqueKey}
      type={alert.type}
      onDismiss={dismissAlert}
      fuseDisabled={alert.fuseDisabled}
      fuseTimer={alert.fuseTimer}
    >
      <Box flexGrow={1}>
        <OParagraph>
          <strong>{name}</strong>
          {!roleIdentifierType && <Spacer data-testid='spacer' />}
          {roleIdentifierType === RoleIdentifierType.SYMBOL && (
            <IconWrapper data-testid='icon'>
              <RoleIdentifierInvertedIcon />
            </IconWrapper>
          )}
          {roleIdentifierType === RoleIdentifierType.TEXT && (
            <RoleTag>{t([roleIdentifierKey, label])}</RoleTag>
          )}
          {nt('sent_message')}
        </OParagraph>
      </Box>
      <ActionableAlertCallToAction onClick={onClick} whiteSpace='no-wrap'>
        <Trans i18nKey='chat'>Chat</Trans>
      </ActionableAlertCallToAction>
    </BaseAlert>
  );
};

export default DirectChatAlert;
