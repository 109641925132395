import React, { FC, Fragment } from 'react';
import { Props } from './index';
import Clap from '../icons/Clap';
import RaisedHands from '../icons/RaisedHands';
import { Wrapper, Divider, Button } from './styles';
import { ReactionType } from '../dux';

const ReactionTray: FC<Props> = ({ type, changeSkinTone }) => {
  const TONES = [1, 2, 3, 4, 5, 6];
  if (type === ReactionType.RAISED_HANDS) {
    return (
      <Wrapper type={type} data-testid='reactionTrayRaisedHands'>
        {TONES.map(tone => (
          <Fragment key={`raisedHandsBtn-${tone}`}>
            <Button
              data-testid={tone === 1 && 'raisedHands-defaultBtn'}
              onClick={() => changeSkinTone(tone)}
            >
              <RaisedHands tone={tone} />
            </Button>
            {tone === 1 && <Divider />}
          </Fragment>
        ))}
      </Wrapper>
    );
  }
  if (type === ReactionType.CLAP) {
    return (
      <Wrapper type={type} data-testid='reactionTrayClap'>
        {TONES.map(tone => (
          <Fragment key={`clapBtn-${tone}`}>
            <Button
              data-testid={tone === 1 && 'clap-defaultBtn'}
              onClick={() => changeSkinTone(tone)}
            >
              <Clap tone={tone} />
            </Button>
            {tone === 1 && <Divider />}
          </Fragment>
        ))}
      </Wrapper>
    );
  }

  return null;
};

export default React.memo<Props>(ReactionTray);
