import React, { FunctionComponent } from 'react';
import Gloo from './gloo';
import { Props } from './index';

const Pixels: FunctionComponent<Props> = ({ integrations }) => {
  if (integrations.length === 0) return null;
  return (
    <>
      {integrations.map(integration => {
        switch (integration.__typename) {
          case 'Gloo':
            return <Gloo key={integration.type} config={integration.config} />;
        }
      })}
    </>
  );
};

export default Pixels;
