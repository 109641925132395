import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { validEmail } from '@utils/core';
import { Props } from './index';
import {
  Box,
  defaultColors,
  FacebookButton,
  FacebookLogoIcon,
  FancyInput,
  Flex,
  GridItem,
  IconSize,
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  OHeaderThree,
  PrimaryButton,
} from '@lifechurch/react-ion';
import { Divider } from './styles';
import LoginErrors from './loginErrors';

type LoginState = {
  email: string;
  password: string;
  emailBlank: boolean;
  passwordBlank: boolean;
  emailInvalid: boolean;
};

const Login = ({
  closeModal,
  basicAuthLogin,
  resetPassword,
  error,
  currentSubscriberId,
  signup,
  hasFacebookLogin,
}: Props) => {
  const { t } = useTranslation('forms');
  const [values, setValues] = useState<LoginState>({
    email: '',
    password: '',
    emailBlank: false,
    passwordBlank: false,
    emailInvalid: false,
  });

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const hasOtherProviders = hasFacebookLogin;

  const onChange = (event: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name === 'email') {
      setValues({ ...values, [name]: value, ['emailBlank']: false, ['emailInvalid']: false });
    } else {
      setValues({ ...values, [name]: value, ['passwordBlank']: false });
    }
  };

  const handleLogin = (event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (values.email !== '' && values.password !== '' && validEmail(values.email)) {
      basicAuthLogin(values.email, values.password, currentSubscriberId);
    } else {
      setValues({
        ...values,
        emailBlank: values.email === '',
        emailInvalid: !validEmail(values.email) && values.email !== '',
        passwordBlank: values.password === '',
      });
    }
    if (values.email === '' || (!validEmail(values.email) && values.email !== '')) {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    } else if (values.password === '') {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
  };

  const handleFacebookLogin = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `${window.location.origin}/auth/facebook`;
  };

  const handleSignupKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      signup();
    }
  };

  return (
    <Modal
      dismiss={closeModal}
      size={ModalSize.NARROW}
      testId='login-modal'
      ariaLabel='Login Modal'
    >
      <OHeaderThree marginBlockEnd={5}>{t('login.title')}</OHeaderThree>
      {error && (
        <LoginErrors error={error} email={values.email} otherProviders={hasOtherProviders} />
      )}
      {hasFacebookLogin && (
        <>
          <FacebookButton
            onClick={handleFacebookLogin}
            data-testid='login-facebook'
            marginInlineEnd={5}
            marginBlockEnd={5}
          >
            <Flex alignItems='center' marginBlockStart={2} marginBlockEnd={2}>
              <Flex alignItems='center' marginInlineEnd={3}>
                <FacebookLogoIcon size={IconSize.XL} color={defaultColors.white} />
              </Flex>
              {t('login.facebook')}
            </Flex>
          </FacebookButton>
          <Divider>{t('login.or_divider')}</Divider>
        </>
      )}
      <form onSubmit={handleLogin}>
        <Box marginBlockStart={5} marginBlockEnd={5}>
          <FancyInput
            type='email'
            name='email'
            id='email'
            data-testid='login-emailField'
            value={values.email}
            label={t('login.email')}
            onChange={onChange}
            ref={emailRef}
            aria-label={
              values.emailBlank
                ? t('login.blank_email')
                : values.emailInvalid
                ? t('login.invalid_email')
                : t('login.email')
            }
            aria-invalid={values.emailBlank || values.emailInvalid ? true : false}
            error={values.emailBlank || values.emailInvalid || !!error}
            hint={(() => {
              if (values.emailBlank) {
                return t('login.blank_email');
              }
              if (values.emailInvalid) {
                return t('login.invalid_email');
              }
              return null;
            })()}
          />
        </Box>
        <Box marginBlockStart={5} marginBlockEnd={5}>
          <FancyInput
            type='password'
            name='password'
            id='password'
            data-testid='login-passwordField'
            value={values.password}
            label={t('login.password')}
            onChange={onChange}
            ref={passwordRef}
            aria-label={values.passwordBlank ? t('login.blank_password') : t('login.password')}
            aria-invalid={values.passwordBlank ? true : false}
            error={values.passwordBlank || !!error}
            hint={(() => {
              if (values.passwordBlank) {
                return t('login.blank_password');
              }
              return null;
            })()}
          />
        </Box>

        <GridItem marginBlockStart={3}>
          <Flex justifyContent='space-between'>
            <LinkButton
              marginInlineEnd={5}
              buttonType={LinkButtonType.SECONDARY}
              onClick={resetPassword}
              tabIndex={0}
              onKeyPress={resetPassword}
            >
              {t('login.forgot')}
            </LinkButton>
          </Flex>
        </GridItem>

        <ModalButtons marginBlockStart={6}>
          <LinkButton
            buttonType={LinkButtonType.SECONDARY}
            onClick={signup}
            onKeyDown={handleSignupKeyDown}
            data-testid='login-signUp'
            marginInlineEnd={5}
            tabIndex={0}
          >
            {t('login.sign_up')}
          </LinkButton>
          <PrimaryButton onClick={handleLogin} type='submit'>
            {t('login.submit')}
          </PrimaryButton>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default Login;
