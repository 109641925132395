import React, { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { PublicSubscriber, Subscriber } from '../../../../types';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import SubscriberRow from './subscriberRow';
import {
  AudienceTab,
  AudienceTabVariables,
  AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node,
} from '@io/__generated__/AudienceTab';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { setModal, setPaneToChat } from '@store/uiSlice';
import { muteSubscriber as muteSubscriberModal } from '@components/modal/muteSubscriber/dux';
import { newPlaceholderChannel, saveChannel } from '@store/feedSlice';
import { ChannelType } from '../../../../../__generated__/globalTypes';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { unmuteSubscriber as unmuteSubscriberAction } from '@store/subscriberSlice';
import { ApolloQueryResult } from '@apollo/client';

interface StateProps {
  currentSubscriber: Subscriber;
  organizationId: string;
}

interface OwnProps {
  subscriber:
    | AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node
    | null
    | undefined;
  currentSubscriber: Subscriber;
  onSelect: (id: string) => void;
  isSelected: boolean;
  setSelectedSubscriber: React.Dispatch<SetStateAction<string | null>>;
  refetch: (
    variables?: Partial<AudienceTabVariables> | undefined
  ) => Promise<ApolloQueryResult<AudienceTab>>;
}

const mapStateToProps = (state: RootState): StateProps => {
  const currentSubscriber = getCurrentSubscriber(state);

  return {
    currentSubscriber,
    organizationId: getCurrentOrganization(state).id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  muteSubscriber: (subscriber: PublicSubscriber, onSuccess: VoidFunction) =>
    dispatch(setModal(muteSubscriberModal({ subscriber, onSuccess }))),
  unmuteSubscriber: (subscriber: PublicSubscriber, onSuccess: VoidFunction) => {
    return dispatch(
      unmuteSubscriberAction({
        subscriberId: subscriber.id,
        subscriberName: subscriber.nickname ?? '',
        onSuccess,
      })
    );
  },
  directChat: (self: PublicSubscriber, recipient: PublicSubscriber, organizationId: string) => {
    const placeholderChannel = newPlaceholderChannel(
      [self, recipient],
      ChannelType.direct,
      organizationId
    );
    dispatch(saveChannel(placeholderChannel));
    dispatch(setPaneToChat(placeholderChannel.key));
  },
});

const VisibleSubscriberRow = connect(mapStateToProps, mapDispatchToProps)(SubscriberRow);

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleSubscriberRow;
