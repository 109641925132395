import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const RightQuotationIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M11 9C11 10.1046 10.1046 11 9 11H8V13H9C11.2091 13 13 11.2091 13 9V8V5L13 2H8L8 8L11 8V9ZM5 8L2 8L2 2L7 2L7 5V8V9C7 11.2091 5.20914 13 3 13H2V11H3C4.10457 11 5 10.1046 5 9V8Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M12 15C13.6569 15 15 13.6569 15 12V10H11L11 3H17V9V10V12C17 14.7614 14.7614 17 12 17H11V15H12ZM3 10L7 10V12C7 13.6569 5.65685 15 4 15H3V17H4C6.76142 17 9 14.7614 9 12V10V9L9 3H3L3 10Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
