import { call, put } from 'redux-saga/effects';
import { AuthProvider } from '../../types';
import { currentService } from './currentService';
import { errorAlert, loggedInAlert, signupInAlert } from '@components/Alert/dux';
import { get, HttpResponse, post } from '../API';
import Cookie from 'js-cookie';
import { login as loginEvent, signUp } from './metrics';
import { isObject, snakeCase } from '@utils/core';
import { basicAuthLogin, handleExternalAuth, loginSuccess } from '@store/subscriberSlice';
import { AuthStatus } from '../auth';
import { v4 as uuidv4 } from 'uuid';
import { setAuthProvider } from '@store/subscriberSlice';
import { basicAuthLoginFailed, clearModal, setShowError } from '@store/uiSlice';
import { signUpSuccess } from '@components/modal/signup/dux';
import { sendIntegrationsEvent } from '@io/sagas/integrations';

export interface AuthError {
  code: string;
  message: string;
}

export enum AuthCodes {
  LOGIN_FAILED = 'auth-001',
  PROVIDER_DISABLED = 'auth-007',
}

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  errors: Array<AuthError>;
}

function* guestAuth() {
  try {
    const { parsedBody }: HttpResponse<AuthResponse> = yield call(post, '/auth/guest');
    if (parsedBody && parsedBody?.errors?.length === 0) {
      yield call(currentService);
    } else {
      throw new Error(parsedBody?.errors[0].message);
    }
  } catch (error) {
    yield put(setShowError(true));
    yield put(errorAlert('error'));
  }
}

function* checkAuth() {
  try {
    const { parsedBody }: HttpResponse<{ success: boolean; provider: string }> = yield call(
      get,
      '/auth/check'
    );
    if (parsedBody?.success) {
      const { provider } = parsedBody;
      if (provider) {
        // @ts-expect-error
        yield put(setAuthProvider(AuthProvider[snakeCase(provider)]));
      }
      yield call(currentService);
    } else {
      yield call(guestAuth);
    }
  } catch (error) {
    yield call(guestAuth);
  }
}

function* init() {
  try {
    yield call(checkAuth);
  } catch (error) {
    yield call(guestAuth);
  }
}

function* basicAuth(action: ReturnType<typeof basicAuthLogin>) {
  try {
    const { email, password } = action.payload;
    const { parsedBody }: HttpResponse<AuthResponse> = yield call(post, '/auth/basic', {
      email,
      password,
    });

    if (parsedBody && parsedBody?.errors?.length === 0) {
      yield put(loginSuccess());
      yield call(loginEvent, action);
      yield put(clearModal());
      yield put(loggedInAlert());
      yield call(currentService);
    } else {
      yield put(
        basicAuthLoginFailed(
          parsedBody?.errors[0] || {
            code: AuthCodes.LOGIN_FAILED,
            message: 'Login Failed - Invalid Email or Password',
          }
        )
      );
    }
  } catch (error) {
    if (isObject(error) && error.parsedBody?.errors?.length > 0) {
      yield put(basicAuthLoginFailed(error.parsedBody.errors[0]));
    } else {
      yield put(
        basicAuthLoginFailed({
          code: AuthCodes.LOGIN_FAILED,
          message: isObject(error) && error.message ? error.message : '',
        })
      );
    }
  }
}

function* handleAuth(action: ReturnType<typeof handleExternalAuth>) {
  const {
    payload: { status },
  } = action;
  switch (status) {
    case AuthStatus.SUCCESS:
      yield put(loginSuccess());
      yield call(loginEvent, action);
      yield put(loggedInAlert());
      yield call(currentService);
      break;
    case AuthStatus.NEW:
      yield put(signupInAlert());
      yield call(currentService);
      yield call(sendIntegrationsEvent, signUpSuccess());
      yield call(signUp, uuidv4(), 'external_signup');
      break;
    case AuthStatus.ERROR:
      yield put(errorAlert('custom_auth_login_error'));
      break;
  }
}

function* logout() {
  Cookie.remove('refresh_token');
  Cookie.remove('access_token');

  try {
    yield call(post, '/auth/logout');
    yield call(guestAuth);
    Cookie.set('SESSIONID', uuidv4());
  } catch (error) {
    yield call(guestAuth);
  }
}

export { init, basicAuth, guestAuth, checkAuth, logout, handleAuth };
