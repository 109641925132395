import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TwitterLogoIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 5.03931C17.4119 5.30082 16.7785 5.47738 16.1142 5.55572C16.7928 5.14965 17.3126 4.50635 17.5575 3.74055C16.9241 4.11683 16.2212 4.38938 15.4731 4.53724C14.875 3.89835 14.0221 3.5 13.0775 3.5C11.2646 3.5 9.79476 4.96979 9.79476 6.78276C9.79476 7.03986 9.82345 7.29034 9.87972 7.5309C7.152 7.39407 4.73324 6.08648 3.11338 4.10138C2.8309 4.58579 2.66979 5.14966 2.66979 5.75104C2.66979 6.88979 3.2491 7.89504 4.12966 8.48318C3.59117 8.46663 3.08579 8.31876 2.64331 8.07269C2.64221 8.08703 2.64221 8.10028 2.64221 8.11463C2.64221 9.7047 3.77435 11.031 5.27614 11.3334C5.00028 11.4084 4.71007 11.4481 4.41104 11.4481C4.19917 11.4481 3.99393 11.4272 3.7931 11.3896C4.21131 12.6928 5.4229 13.6418 6.85959 13.6694C5.73628 14.5488 4.32055 15.0741 2.78345 15.0741C2.51862 15.0741 2.2571 15.0586 2 15.0277C3.45324 15.959 5.17793 16.503 7.03173 16.503C13.0698 16.503 16.3713 11.5011 16.3713 7.16346C16.3713 7.02111 16.368 6.87986 16.3625 6.73862C17.0036 6.27627 17.5597 5.69807 18 5.03931Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22 5.82413C21.2648 6.15103 20.4731 6.37172 19.6428 6.46965C20.491 5.96207 21.1407 5.15793 21.4469 4.20069C20.6552 4.67104 19.7766 5.01173 18.8414 5.19655C18.0938 4.39793 17.0276 3.9 15.8469 3.9C13.5807 3.9 11.7434 5.73724 11.7434 8.00345C11.7434 8.32483 11.7793 8.63793 11.8497 8.93862C8.44 8.76759 5.41655 7.1331 3.39172 4.65172C3.03862 5.25724 2.83724 5.96207 2.83724 6.71379C2.83724 8.13724 3.56138 9.3938 4.66207 10.129C3.98897 10.1083 3.35724 9.92344 2.80414 9.61586C2.80276 9.63379 2.80276 9.65035 2.80276 9.66828C2.80276 11.6559 4.21793 13.3138 6.09517 13.6917C5.75035 13.7855 5.38759 13.8352 5.0138 13.8352C4.74897 13.8352 4.49241 13.809 4.24138 13.7621C4.76414 15.391 6.27862 16.5772 8.07448 16.6117C6.67034 17.711 4.90069 18.3676 2.97931 18.3676C2.64827 18.3676 2.32138 18.3483 2 18.3097C3.81655 19.4738 5.97242 20.1538 8.28966 20.1538C15.8372 20.1538 19.9641 13.9014 19.9641 8.47932C19.9641 8.30139 19.96 8.12482 19.9531 7.94827C20.7545 7.37034 21.4497 6.64758 22 5.82413Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M30 7.29379C28.9708 7.75144 27.8623 8.06041 26.6999 8.19752C27.8874 7.48689 28.797 6.36111 29.2257 5.02097C28.1172 5.67945 26.8872 6.15642 25.5779 6.41518C24.5313 5.29711 23.0386 4.6 21.3857 4.6C18.213 4.6 15.6408 7.17214 15.6408 10.3448C15.6408 10.7948 15.691 11.2331 15.7895 11.6541C11.016 11.4146 6.78317 9.12634 3.94841 5.65241C3.45407 6.50013 3.17213 7.4869 3.17213 8.53931C3.17213 10.5321 4.18593 12.2913 5.7269 13.3206C4.78455 13.2916 3.90014 13.0328 3.12579 12.6022C3.12386 12.6273 3.12386 12.6505 3.12386 12.6756C3.12386 15.4582 5.10511 17.7793 7.73324 18.3084C7.25049 18.4397 6.74262 18.5092 6.21931 18.5092C5.84855 18.5092 5.48938 18.4725 5.13793 18.4069C5.86979 20.6874 7.99007 22.3481 10.5043 22.3964C8.53848 23.9355 6.06096 24.8546 3.37103 24.8546C2.90758 24.8546 2.44993 24.8276 2 24.7735C4.54317 26.4033 7.56138 27.3553 10.8055 27.3553C21.3721 27.3553 27.1498 18.6019 27.1498 11.011C27.1498 10.7619 27.144 10.5148 27.1343 10.2676C28.2563 9.45848 29.2295 8.44661 30 7.29379Z'
          size={size}
          {...rest}
        />
      );
  }
};
