import { ModalType } from '../dux';
import { AuthError } from '@io/sagas/auth';

export interface LoginMeta {
  error: AuthError | null;
}

export interface Login {
  type: ModalType.LOGIN;
  meta?: LoginMeta;
}

export const login = (meta?: LoginMeta): Login => ({
  type: ModalType.LOGIN,
  meta,
});
