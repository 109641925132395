export const isSafari = (): boolean => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isiOS = (): boolean =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  ) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
export const isSafariOriOS = isSafari() || isiOS();
export const isVersion15 = (): boolean => navigator.userAgent.includes('Version/15');
export const isiOSSafariVersion15 = isSafari() && isiOS() && isVersion15();
