import Audience from './audience';
import { connect } from 'react-redux';
import { isOffline } from '@store/serviceSlice/selectors';
import { RootState } from '@store/rootReducer';

interface StateProps {
  isOffline: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  isOffline: isOffline(state),
});

const VisibleAudience = connect(mapStateToProps)(Audience);

export type Props = StateProps;
export default VisibleAudience;
