import React, { FC } from 'react';

const CircleHeart: FC = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#E83665' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2414 10.4139H15.2413C13.356 8.52867 10.2993 8.52868 8.414 10.414C6.52867 12.2993 6.52867 15.356 8.414 17.2414L8.42957 17.2569L9.17263 17.9999L9.17251 18.0001L15.9999 24.8275L23.571 17.2563L23.586 17.2414C25.4713 15.356 25.4713 12.2993 23.586 10.414C21.7007 8.52868 18.6439 8.52868 16.7586 10.414L16.7585 10.4141L16.7585 10.4141L16 11.1725L15.2414 10.4139Z'
        fill='white'
      />
    </svg>
  );
};

export default CircleHeart;
