import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Wrapper, StrengthText, StrengthWrapper } from './styles';
import { usePasswordStrength } from '@churchonline/hooks';
import { OXSmallParagraph } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';

interface Props {
  password: string;
}

const PasswordStrength: FunctionComponent<Props> = ({ password }) => {
  const { t } = useTranslation('forms');
  const [{ length = 0, id }, updatePassword] = usePasswordStrength();
  const [strengthText, setStrengthText] = useReducer((_state: string, action: number | null) => {
    switch (action) {
      case -1:
        return t('password_strength.very_weak');
      case 0:
        return t('password_strength.weak');
      case 1:
        return t('password_strength.good');
      case 2:
        return t('password_strength.strong');
      default:
        return '';
    }
  }, '');

  useEffect(() => {
    updatePassword(password);
  }, [password, updatePassword]);

  useEffect(() => {
    if (!length || length < 8) {
      return setStrengthText(-1);
    }
    setStrengthText(id);
  }, [id, length]);

  return (
    <Wrapper>
      <OXSmallParagraph>{t('password_strength.password_short')}</OXSmallParagraph>
      <StrengthWrapper>
        {length && length > 0 && (
          <>
            <OXSmallParagraph>{t('password_strength.strength') + ': '}</OXSmallParagraph>
            <StrengthText strength={id} length={length}>
              {strengthText}
            </StrengthText>
          </>
        )}
      </StrengthWrapper>
    </Wrapper>
  );
};

export default React.memo(PasswordStrength);
