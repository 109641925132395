import styled from 'styled-components';
import {
  border,
  BorderProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import { ButtonSize } from './Button';
import { logical, LogicalProps } from './logical';

export enum LinkButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  ERROR = 'ERROR',
}

type BaseLinkButtonProps = SpaceProps &
  TypographyProps &
  BorderProps &
  LogicalProps &
  LayoutProps & {
    size?: ButtonSize;
    buttonType?: LinkButtonType;
    withIcon?: boolean;
    disabled?: boolean;
  };

const linkButtonSizeVariant = variant({
  prop: 'size',
  variants: {
    xs: {
      fontSize: 1,
      fontWeight: 2,
      lineHeight: 2,
    },
    sm: {
      fontSize: 2,
      fontWeight: 2,
      lineHeight: 2,
    },
    md: {
      fontSize: 3,
      fontWeight: 2,
      lineHeight: 2,
    },
    lg: {
      fontSize: 4,
      lineHeight: 2,
    },
    xl: {
      fontSize: 5,
      lineHeight: 2,
    },
  },
});

const linkButtonTypeVariant = variant({
  prop: 'buttonType',
  variants: {
    PRIMARY: {
      color: 'action.default',
      'svg path': {
        fill: 'action.default',
      },
      '&:hover, &:focus': {
        color: 'action.hover',
        'svg path': {
          fill: 'action.hover',
        },
      },
    },
    SECONDARY: {
      color: 'gray50',
      'svg path': {
        fill: 'gray50',
      },
      '&:hover, &:focus': {
        color: 'action.hover',
        'svg path': {
          fill: 'action.hover',
        },
      },
    },
    ERROR: {
      color: 'error.default',
      'svg path': {
        fill: 'error.default',
      },
      '&:hover, &:focus': {
        color: 'error.hover',
        'svg path': {
          fill: 'error.hover',
        },
      },
    },
  },
});

export const LinkButton = styled.a<BaseLinkButtonProps>`
  display: inline-flex;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;

  &:hover,
  &:focus {
    text-decoration: ${props => {
      if (props.disabled) {
        return 'none';
      }
      if (props.withIcon) {
        return 'none';
      }
      return 'underline';
    }};
  }
  ${compose(
    space,
    typography,
    border,
    logical,
    layout,
    linkButtonSizeVariant,
    linkButtonTypeVariant
  )}

  &,
  &:hover,
  &:focus {
    color: ${props => (props.disabled ? props.theme.colors.gray50 : null)};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  svg path,
  &:hover svg path,
  &:focus svg path {
    fill: ${props => (props.disabled ? props.theme.colors.gray50 : null)};
  }
`;

LinkButton.defaultProps = {
  size: ButtonSize.MD,
  buttonType: LinkButtonType.PRIMARY,
};
