import React, { FC } from 'react';
import { ScrollUpButton } from '@features/audience/styles';
import { ArrowUpIcon, defaultColors, IconSize, OSmallParagraph } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';

interface Props {
  newSubscribers: boolean;
  onClick: () => void;
}

const ScrollToTop: FC<Props> = ({ newSubscribers, onClick }) => {
  const { t } = useTranslation();

  return (
    <ScrollUpButton onClick={onClick} newSubscribers={newSubscribers}>
      <ArrowUpIcon size={IconSize.SM} color={defaultColors.action.default} />
      {newSubscribers && (
        <OSmallParagraph color={defaultColors.action.default}>
          {t('audience.new_viewers')}
        </OSmallParagraph>
      )}
    </ScrollUpButton>
  );
};

export default ScrollToTop;
