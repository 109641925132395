import { SelectChatName, selectChatName } from './selectChatName/dux';
import { DeleteAccount, deleteAccount } from './deleteAccount/dux';
import { LeaveChat, leaveChat } from './leaveChat/dux';
import { Login, login } from './login/dux';
import { MuteSubscriber, muteSubscriber } from './muteSubscriber/dux';
import { ProfileSettings, profileSettings } from './profileSettings/dux';
import { RemoveAvatar, removeAvatar } from './removeAvatar/dux';
import { RequestPasswordReset, requestPasswordReset } from './requestPasswordReset/dux';
import { ResetPassword, resetPassword } from './resetPassword/dux';
import { AcceptInvite, acceptInvite } from './acceptInvite/dux';
import { SignupModal, signupModal } from './signup/dux';
import { RequestPrayer, requestPrayer } from './requestPrayer/dux';
import { IframeModal, iframeModal } from './iframe/dux';
import { QuoteMomentShareSheet, quoteMomentShareSheet } from './quoteMomentShareSheet/dux';
import { InviteMomentShareSheet, inviteMomentShareSheet } from './inviteMomentShareSheet/dux';
import { ImageMomentShareSheet, imageMomentShareSheet } from './imageMomentShareSheet/dux';
import { ChangePassword, changePassword } from './changePassword/dux';
import { ssoValueProp, SsoValueProp } from './ssoValueProp/dux';
import { RevertChangeEmail, revertChangeEmail } from './revertChangeEmail/dux';
import { ImageMoment, imageMomentFocusView } from './imageMomentModal/dux';
import { DeleteMoment } from '@components/modal/deleteMoment/dux';

export enum ModalType {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_MOMENT = 'DELETE_MOMENT',
  IFRAME = 'IFRAME',
  IMAGE_MOMENT = 'IMAGE_MOMENT',
  IMAGE_MOMENT_SHARE_SHEET = 'IMAGE_MOMENT_SHARE_SHEET',
  INVITE_MOMENT_SHARE_SHEET = 'INVITE_MOMENT_SHARE_SHEET',
  LEAVE_CHAT = 'LEAVE_CHAT',
  LOGIN = 'LOGIN',
  MUTE_SUBSCRIBER = 'MUTE_SUBSCRIBER',
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  QUOTE_MOMENT_SHARE_SHEET = 'QUOTE_MOMENT_SHARE_SHEET',
  REMOVE_AVATAR = 'REMOVE_AVATAR',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  REQUEST_PRAYER = 'REQUEST_PRAYER',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REVERT_EMAIL_CHANGE = 'REVERT_EMAIL_CHANGE',
  SELECT_CHAT_NAME = 'SELECT_CHAT_NAME',
  SIGNUP = 'SIGNUP',
  SSO_VALUE_PROP = 'SSO_VALUE_PROP',
}

export type Modal =
  | AcceptInvite
  | ChangePassword
  | DeleteAccount
  | DeleteMoment
  | IframeModal
  | ImageMoment
  | ImageMomentShareSheet
  | InviteMomentShareSheet
  | LeaveChat
  | Login
  | MuteSubscriber
  | ProfileSettings
  | QuoteMomentShareSheet
  | RemoveAvatar
  | RequestPasswordReset
  | RequestPrayer
  | ResetPassword
  | RevertChangeEmail
  | SelectChatName
  | SignupModal
  | SsoValueProp;

export const setModalFromType = (type: ModalType): Modal => {
  switch (type) {
    case ModalType.ACCEPT_INVITE:
      return acceptInvite();
    case ModalType.PROFILE_SETTINGS:
      return profileSettings();
    case ModalType.RESET_PASSWORD:
      return resetPassword();
    case ModalType.SIGNUP:
      return signupModal('unknown');
    case ModalType.REVERT_EMAIL_CHANGE:
      return revertChangeEmail();
    default:
      return profileSettings();
  }
};

export {
  acceptInvite,
  changePassword,
  deleteAccount,
  iframeModal,
  imageMomentFocusView,
  imageMomentShareSheet,
  inviteMomentShareSheet,
  leaveChat,
  login,
  muteSubscriber,
  profileSettings,
  quoteMomentShareSheet,
  removeAvatar,
  requestPasswordReset,
  requestPrayer,
  resetPassword,
  selectChatName,
  signupModal,
  ssoValueProp,
};
