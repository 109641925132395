import React, { FunctionComponent } from 'react';
import InitialAvatar from './InitialAvatar';
import ImageAvatar from './ImageAvatar';
import AvatarLoader from './AvatarLoader';
import GuestAvatar from './GuestAvatar';
import { AvatarWrapper } from './styles';
import { AvatarSizeType } from './types';

export type Subscriber = { avatar?: string; nickname?: string; id?: string };

export interface AvatarProps {
  disabled?: boolean;
  isActionable?: boolean;
  loading?: boolean;
  size: AvatarSizeType;
  subscriber: { avatar?: string; nickname?: string; id?: string };
  tabIndex?: number;
  testId?: string;
}

export const Avatar: FunctionComponent<AvatarProps> = ({
  disabled = false,
  isActionable = false,
  loading = false,
  size = AvatarSizeType.MEDIUM,
  subscriber,
  tabIndex = -1,
  testId = 'avatar',
}) => {
  if (subscriber.avatar && subscriber.avatar.indexOf('missing.png') === -1) {
    return (
      <AvatarWrapper size={size}>
        {loading && <AvatarLoader size={size} />}
        <ImageAvatar
          data-testid={testId}
          disabled={disabled}
          isActionable={isActionable}
          nickname={subscriber.nickname}
          size={size}
          tabIndex={tabIndex}
          url={subscriber.avatar}
        />
      </AvatarWrapper>
    );
  } else if (subscriber.nickname && subscriber.id) {
    return (
      <AvatarWrapper size={size}>
        {loading && <AvatarLoader size={size} />}
        <InitialAvatar
          data-testid={testId}
          disabled={disabled}
          isActionable={isActionable}
          nickname={subscriber.nickname || ''}
          subscriberId={subscriber.id}
          size={size}
          tabIndex={tabIndex}
        />
      </AvatarWrapper>
    );
  } else {
    return (
      <AvatarWrapper size={size}>
        {loading && <AvatarLoader size={size} />}
        <GuestAvatar isActionable={isActionable} size={size} tabIndex={tabIndex} />
      </AvatarWrapper>
    );
  }
};
