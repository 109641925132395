import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const SmilingFaceIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return null;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM7.75 9.5C8.44036 9.5 9 8.94036 9 8.25C9 7.55964 8.44036 7 7.75 7C7.05964 7 6.5 7.55964 6.5 8.25C6.5 8.94036 7.05964 9.5 7.75 9.5ZM13.5 8.25C13.5 8.94036 12.9404 9.5 12.25 9.5C11.5596 9.5 11 8.94036 11 8.25C11 7.55964 11.5596 7 12.25 7C12.9404 7 13.5 7.55964 13.5 8.25ZM10 12C10.7403 12 11.3866 11.5978 11.7325 11H13.874C13.43 12.7252 11.8639 14 10 14C8.13618 14 6.57008 12.7252 6.12604 11H8.26758C8.61339 11.5978 9.25974 12 10 12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return null;
    case IconSize.XL:
      return null;
  }
};
