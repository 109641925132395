import { getInset, OParagraph } from '@lifechurch/react-ion';
import styled from 'styled-components';

interface WrapperProps {
  isGuest: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.95);
  height: 40px;
  width: auto;
  position: absolute;
  ${getInset('insetBlockStart', '4px')};
  ${getInset('insetInlineEnd', '4px')};
  z-index: 2;
  padding: 0 1rem;
  border-radius: 2px;

  @media (min-width: 640px) {
    height: ${props => (props.isGuest ? '40px' : '48px')};
    width: ${props => (props.isGuest ? 'auto' : '100%')};
    ${props =>
      props.isGuest ? getInset('insetBlockStart', '4px') : getInset('insetBlockStart', 0)};
    ${props => (props.isGuest ? getInset('insetInlineEnd', '4px') : getInset('insetInlineEnd', 0))};
    border-radius: ${props => (props.isGuest ? '2px' : '0')};
  }
`;

const Title = styled(OParagraph)`
  color: ${props => props.theme.colors.gray100};
  font-weight: 600;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-inline-end: 16px;
`;

export { Wrapper, Title };
