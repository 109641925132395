import React, { FunctionComponent } from 'react';
import { Overlay } from './styles';
import { AvatarSizeType } from './types';

interface Props {
  size: AvatarSizeType;
}

const AvatarLoader: FunctionComponent<Props> = ({ size }) => <Overlay size={size} />;

export default AvatarLoader;
