import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const EnterFullscreenIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M11.9999 2H15.9999H17.9999V4V8H15.9999V5.41434L11.7071 9.70711L10.2929 8.29289L14.5858 4H11.9999V2ZM9.70711 11.7071L5.41421 16H8V18H4H2V16V12H4V14.5858L8.29289 10.2929L9.70711 11.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M15.9999 2H19.9999H21.9999V4V8H19.9999V5.41434L14.2071 11.2071L12.7929 9.79289L18.5858 4H15.9999V2ZM11.2071 14.2071L5.41421 20H8V22H4H2V20V16H4V18.5858L9.79289 12.7929L11.2071 14.2071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
