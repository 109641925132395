import React, { PropsWithChildren, useState, useEffect } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { Box } from './Box';
import { Flex } from './Flex';
import { TimesIcon, IconSize } from './icons';
import { Fuse } from './Fuse';
import { defaultColors } from './theme';

interface BaseAlertProps extends AlertProps {
  backgroundColor: string;
  textColor?: string;
  onClick?: () => void;
}

interface AlertProps {
  /**
   * A unique identifier for an instance of an Alert.
   */
  uniqueKey?: string;
  /**
   * A callback to handle the dismissal of an Alert.
   */
  onDismiss?: () => void;
  /**
   * Cancels the fuse if it is currently running.
   */
  fuseDisabled?: boolean;
  /**
   * Duration of fuse timer.
   */
  fuseTimer?: number;
  /**
   * Disables dismissal of Alert.
   */
  dismissDisabled?: boolean;
  /**
   * Disable dismissal animation.
   */
  animationDisabled?: boolean;
}

const defaultProps = {
  fuseTimer: 5000,
  fuseDisabled: false,
  dismissAnimationTimer: 250,
  dismissDisabled: false,
  animationDisabled: false,
};

export const DismissButton = styled.button`
  opacity: 0.5;
  width: 48px;
  height: 48px;
  border: none;
  background: none;
  outline: none;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  bottom: 0;

  &:hover {
    opacity: 1;
  }

  &:focus {
    box-shadow: inset 0 0 0 3px ${props => props.theme.colors.action.hover};
    opacity: 1;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const heightSlideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: 48px;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0%);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1; 
  }
  to {
    opacity: 0;
  }
`;

const TextWrapper = styled(Flex)`
  white-space: pre-wrap;
`;

interface AnimatedAlertProps {
  startDismissAnimation: boolean;
  dismissAnimationTimer: number;
  animationDisabled?: boolean;
}

export const AnimatedAlert = styled(Flex)<AnimatedAlertProps>`
  animation: ${props => (props.animationDisabled ? '0' : props.dismissAnimationTimer)}s
    ${props => (props.startDismissAnimation ? slideUp : slideDown)} linear;
`;

interface HeightAnimationProps {
  dismissAnimationTimer: number;
  animationDisabled?: boolean;
}

export const HeightAnimation = styled(Flex)<HeightAnimationProps>`
  animation: ${props => (props.animationDisabled ? '0' : props.dismissAnimationTimer)}s
    ${heightSlideDown} linear;

  @media (max-width: 639px) {
    margin: ${props => props.theme.space[2]}px;
  }
`;

export const BaseAlert: React.FunctionComponent<BaseAlertProps> = ({
  uniqueKey,
  children,
  backgroundColor,
  textColor,
  onDismiss,
  fuseDisabled = defaultProps.fuseDisabled,
  fuseTimer = defaultProps.fuseTimer,
  dismissDisabled = defaultProps.dismissDisabled,
  animationDisabled = defaultProps.animationDisabled,
  onClick,
}: PropsWithChildren<BaseAlertProps>) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [opacity, setOpacity] = useState<number>(0);
  const [startDismissAnimation, setStartDismissAnimation] = useState<boolean>(false);
  const [timeoutIds, setTimeoutIds] = useState<number[]>([]);

  const dismissAlert = () => {
    timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
    setStartDismissAnimation(true);

    window.setTimeout(() => {
      setIsOpen(false);
      if (onDismiss) onDismiss();
    }, defaultProps.dismissAnimationTimer);
  };

  useEffect(() => {
    if (!isOpen) return;

    requestAnimationFrame(() => {
      setOpacity(1);
    });

    if (!fuseDisabled) {
      const timeoutId = window.setTimeout(() => {
        dismissAlert();
      }, fuseTimer);
      setTimeoutIds([...timeoutIds, timeoutId]);
    }
  }, [isOpen]);

  const renderAlert = () => {
    if (!isOpen) return null;

    return (
      <HeightAnimation
        dismissAnimationTimer={defaultProps.dismissAnimationTimer / 1000}
        animationDisabled={animationDisabled}
      >
        <AnimatedAlert
          key={uniqueKey}
          alignSelf='center'
          flexDirection='column'
          width='100%'
          startDismissAnimation={startDismissAnimation}
          dismissAnimationTimer={defaultProps.dismissAnimationTimer / 1000}
          animationDisabled={animationDisabled}
          opacity={opacity}
        >
          <Flex
            fontSize={3}
            flexDirection='row'
            alignItems='flex-start'
            alignSelf='flex-end'
            width='100%'
            minHeight='48px'
            marginBlockStart={[0, 2]}
            maxWidth='100%'
            borderRadius={[2]}
            boxShadow={2}
            backgroundColor={backgroundColor}
            opacity={opacity}
            role='alertdialog'
          >
            {!dismissDisabled && (
              <DismissButton onClick={dismissAlert} aria-label='close alert'>
                {fuseDisabled ? (
                  <TimesIcon color={textColor || defaultColors.white} />
                ) : (
                  <Box lineHeight={0}>
                    <Fuse
                      size={IconSize.MD}
                      color={textColor || defaultColors.white}
                      duration={fuseTimer}
                      dismissible={true}
                    />
                  </Box>
                )}
              </DismissButton>
            )}
            <TextWrapper
              paddingBlockStart={'14px'}
              paddingBlockEnd={'14px'}
              paddingInlineStart={dismissDisabled ? 16 : undefined}
              paddingInlineEnd={16}
              lineHeight={2}
              color={textColor || 'white'}
              width={'100%'}
              onClick={() => onClick && onClick()}
            >
              {children}
            </TextWrapper>
          </Flex>
        </AnimatedAlert>
      </HeightAnimation>
    );
  };

  return renderAlert();
};

export const PrimaryAlert: React.FunctionComponent<AlertProps> = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <BaseAlert
      backgroundColor={theme.colors.action.default}
      textColor={defaultColors.white}
      {...props}
    >
      {children}
    </BaseAlert>
  );
};

export const InfoAlert: React.FunctionComponent<AlertProps> = ({
  children,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <BaseAlert backgroundColor={defaultColors.white} textColor={defaultColors.gray100} {...props}>
    {children}
  </BaseAlert>
);

/**
 * Alert component for feedback.
 *
 *
 * ###When To Use
 *
 * - When you need to show alert messages to users.
 * - When you need a persistent static container which is closable by user actions.
 */
export const SuccessAlert: React.FunctionComponent<AlertProps> = ({
  children,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <BaseAlert backgroundColor={defaultColors.success.default} {...props}>
    {children}
  </BaseAlert>
);

export const ErrorAlert: React.FunctionComponent<AlertProps> = ({
  children,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <BaseAlert backgroundColor={defaultColors.error.default} {...props}>
    {children}
  </BaseAlert>
);

export const InfoDarkAlert: React.FunctionComponent<AlertProps> = ({
  children,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <BaseAlert backgroundColor={defaultColors.gray100} {...props}>
    {children}
  </BaseAlert>
);
