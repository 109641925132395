import styled from 'styled-components';
import {
  LandscapeMobile,
  LargeUpQuery,
  MediumPlusQuery,
  MediumQuery,
  PortraitKeyboardOpenLarge,
  PortraitKeyboardOpenMedium,
  PortraitKeyboardOpenSmall,
  PortraitKeyboardOpenXLarge,
} from '@utils/responsive';
import { getInset } from '@lifechurch/react-ion';
import { FC } from 'react';

const Container: FC = ({ children }) => {
  return <div className='relative bg-black lg:flex-1'>{children}</div>;
};

interface VideoWrapperProps {
  isDuringService: boolean;
}

const VideoWrapper = styled.div<VideoWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: auto; // Reset width
  height: auto; // Reset height

  @media ${MediumQuery} {
    max-width: 460px;
  }

  @media ${MediumPlusQuery} {
    max-width: 640px;
  }

  @media ${LargeUpQuery} {
    width: 100%;
    height: 100%;
    // formula calc ((16/9)*100) - ([height of elements] * (16/9))
    max-width: ${props => (props.isDuringService ? 'calc(177vh - 238px)' : 'calc(177vh - 347px)')};
  }

  @media ${LandscapeMobile} {
    padding-block-start: 0;
    height: 100%;
    max-width: 177vh;
  }
`;

interface VideoProps {
  largeHostVideo: boolean;
  isXLargeUp: boolean;
}

export const VideoInnerWrapper = styled.div<VideoProps>`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding-block-end: ${props => (props.largeHostVideo && props.isXLargeUp ? '33.75%' : '56.25%')};

  @media ${PortraitKeyboardOpenSmall} {
    padding-block-end: 22.25%;
  }
  @media ${PortraitKeyboardOpenMedium} {
    padding-block-end: 32.25%;
  }
  @media ${PortraitKeyboardOpenLarge} {
    padding-block-end: 40.25%;
  }
  @media ${PortraitKeyboardOpenXLarge} {
    padding-block-end: 42.25%;
  }
`;

const UnmuteButtonWrapper = styled.div`
  position: absolute;
  ${getInset('insetBlockEnd', '8px')};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  text-align: center;
  z-index: 1;
  pointer-events: none;
`;

const NoVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineStart', 0)};
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.black};

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

export { Container, NoVideoWrapper, UnmuteButtonWrapper, VideoWrapper };
