import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Flex } from './Flex';
import { FlexProps } from 'styled-system';
import styled from 'styled-components';

const AlertWrapper = styled(Flex)`
  right: 4px;
  @media (max-width: 639px) {
    right: 0;
  }
  [dir='rtl'] &&& {
    right: unset;
    left: 4px;
    @media (max-width: 639px) {
      left: 0;
    }
  }
`;

/**
 * Responsive container that places Alerts correctly in the application.
 */
export const AlertContainer: FunctionComponent<PropsWithChildren<FlexProps>> = ({ children }) => {
  return (
    <AlertWrapper
      flexDirection='column'
      position='absolute'
      zIndex={9999}
      top={0}
      maxWidth={['100%', '400px']}
      width='100%'
      height='auto'
    >
      {children}
    </AlertWrapper>
  );
};
