import React from 'react';
import styled from 'styled-components';
import { ButtonSize, DownloadIcon, IconSize, OSmallParagraph, PrimaryButton, } from '@lifechurch/react-ion';
import { ShareButtonContainer } from '../share-button-container';
const DownloadButton = styled(PrimaryButton).attrs({ type: undefined }) `
  background-color: ${props => props.theme.colors.gray50};
  padding: ${props => props.padding};
  border-radius: 50%;

  &:hover {
    background-color: ${props => props.theme.colors.gray50};
  }
`;
const DownloadShareButton = ({ fileURL, iconSize = IconSize.XL, padding = '8px !important', size = ButtonSize.SM }, props) => {
    const url = new URL(fileURL);
    url.searchParams.set('dl', '');
    return (React.createElement(ShareButtonContainer, { flexDirection: 'column' },
        React.createElement(DownloadButton, { "aria-label": 'Download', as: 'a', href: url.href, download: true, "data-testid": 'download', padding: padding, size: size, ...props },
            React.createElement(DownloadIcon, { size: iconSize, color: 'white' })),
        React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Download")));
};
export { DownloadShareButton };
