import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const StarIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 0.199951L9.15248 5.28575H14.5L10.1738 8.42894L11.8262 13.5147L7.5 10.3715L3.17376 13.5147L4.82624 8.42894L0.5 5.28575H5.84752L7.5 0.199951Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 0.0100098L12.2426 6.91216H19.5L13.6287 11.1779L15.8713 18.0801L10 13.8143L4.12868 18.0801L6.37132 11.1779L0.5 6.91216H7.75735L10 0.0100098Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 0L14.8328 8.40325H24L16.5836 13.5967L19.4164 22L12 16.8065L4.58359 22L7.41641 13.5967L0 8.40325H9.16718L12 0Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 0L19.6 11.2616H31.25L21.825 18.2217L25.425 29.4833L16 22.5233L6.57498 29.4833L10.175 18.2217L0.75 11.2616H12.4L16 0Z'
          size={size}
          {...rest}
        />
      );
  }
};
