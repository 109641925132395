import React from 'react';
import styled from 'styled-components';

type CircleProps = {
  background: string;
  gradient?: boolean;
  height: number;
  left: number;
  opacity?: number;
  top: number;
};

const Container = styled.div`
  height: 62px;
  position: relative;
  width: 158px;
`;

const Circle = styled.div<CircleProps>`
  background: ${({ background, gradient }) =>
    gradient
      ? `linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1.56%, rgba(255, 255, 255, 0) 98.44%), ${background}`
      : background};
  border-radius: ${({ height }) => height / 2}px;
  height: ${({ height }) => height}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};
  position: absolute;
  top: ${({ top }) => top}px;
  width: ${({ height }) => height}px;
`;

function BallConfetti() {
  const ORANGE = '#ff9000';
  const GREY = '#9f9fa0';

  return (
    <Container>
      <Circle height={8} left={0} top={42.37} opacity={0.2} background={GREY} />
      <Circle height={20} left={6.5} top={16.12} opacity={0.3} background={ORANGE} gradient />
      <Circle height={29} left={22.5} top={33} opacity={0.7} background={ORANGE} gradient />
      <Circle height={21} left={72.5} top={26.37} opacity={0.3} background={ORANGE} gradient />
      <Circle height={40} left={47.5} top={0} background={ORANGE} gradient />
      <Circle height={12} left={101} top={6} opacity={0.2} background={GREY} />
      <Circle height={32} left={98} top={22.37} opacity={0.8} background={ORANGE} gradient />
      <Circle height={18} left={129} top={11} opacity={0.2} background={ORANGE} gradient />
      <Circle height={8} left={150} top={33.37} opacity={0.2} background={GREY} />
    </Container>
  );
}

export { BallConfetti };
