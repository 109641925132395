import ReactHubSpot from '../../../lib/ReactHubSpot';
import { CurrentState_currentOrganization_integrations_HubSpot } from '@io/__generated__/CurrentState';
import { setSubscriber } from '@store/subscriberSlice';

export function initHubSpot(integration: CurrentState_currentOrganization_integrations_HubSpot) {
  ReactHubSpot.init(integration.config.trackingCodeId);
}

export function setHubSpotUser(action: ReturnType<typeof setSubscriber>) {
  const { payload: currentSubscriber } = action;
  ReactHubSpot.identify({
    id: currentSubscriber.id,
    email: currentSubscriber.email,
  });

  ReactHubSpot.setTrackConsent(currentSubscriber.emailConsent ?? false);
  ReactHubSpot.pageView('/');
}
