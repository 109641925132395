import React, { FC } from 'react';
import { NotificationType } from './dux';
import { JoinedChannel, LeftChannel, SubscriberMuted } from './notifications';
import { Props } from './index';

const Notification: FC<Props> = (props: Props) => {
  const { notification, isCompact } = props;

  if (!notification) return null;

  switch (notification.type) {
    case NotificationType.JOINED_CHANNEL:
      return <JoinedChannel notification={notification} isCompact={isCompact} />;
    case NotificationType.LEFT_CHANNEL:
      return <LeftChannel notification={notification} isCompact={isCompact} />;
    case NotificationType.SUBSCRIBER_MUTED:
      return <SubscriberMuted notification={notification} isCompact={isCompact} />;
    default:
      return null;
  }
};

export default React.memo<Props>(Notification);
