import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoleIdentifier } from '../../../types';
import RoleIdentifierIcon from '../../../components/icons/roleIdentifier';
import { RoleIdentifierType } from '../../../../__generated__/globalTypes';
import styles from './senderName.module.css';

interface LabelProps {
  name?: string | null;
  roleIdentifier?: RoleIdentifier | null;
  active?: boolean;
}

const SenderName: React.FunctionComponent<LabelProps> = ({ name, roleIdentifier }) => {
  const { t } = useTranslation('roleIdentifiers');

  if (!name) return null;

  return (
    <>
      <span className={styles.name}>{name}</span>
      {roleIdentifier?.type === RoleIdentifierType.SYMBOL && (
        <span className={styles.iconWrapper}>
          <RoleIdentifierIcon />
        </span>
      )}
      {roleIdentifier?.type === RoleIdentifierType.TEXT && roleIdentifier?.key && (
        <span data-testid='senderName-label' className={styles.roleLabel}>
          {t(roleIdentifier.key, roleIdentifier.label || undefined)}
        </span>
      )}
    </>
  );
};

export default React.memo(SenderName);
