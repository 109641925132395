import React from 'react';
import CTAMoment from '../cta';
import { Flex, OParagraph, PrimaryButton, SecondaryButton, ButtonSize, } from '@lifechurch/react-ion';
import Imgix from 'react-imgix';
const SignupMoment = ({ url, text, buttonText = 'Sign Up', isPinned = false, onDismiss, onClick, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(CTAMoment, { onDismiss: onDismiss, isPinned: isPinned, ariaLabel: text, fuse: true },
            React.createElement(Flex, { flexDirection: 'column', width: '100%', alignItems: 'center' },
                React.createElement(Imgix, { src: url, width: 116, height: 48, htmlAttributes: {
                        'data-testid': `avatar-${url}`,
                        tabIndex: 0,
                        role: 'img',
                        'aria-label': `profile-photo-examples`,
                    } }),
                React.createElement(OParagraph, { marginBlockEnd: 3, textAlign: 'center', paddingBlockStart: 3, paddingBlockEnd: 2, paddingInlineStart: 5, paddingInlineEnd: 5 }, text),
                isPinned ? (React.createElement(PrimaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick }, buttonText)) : (React.createElement(SecondaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick }, buttonText))))));
};
export { SignupMoment };
