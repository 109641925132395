import { detect } from 'detect-browser';

enum BrowserEventString {
  chrome = 'fullscreenchange',
  edge = 'msfullscreenchange',
  firefox = 'mozfullscreenchange',
  ie = 'msfullscreenchange',
  safari = 'webkitfullscreenchange',
}

enum MobileBrowserEventString {
  chrome = 'webkitfullscreenchange',
  safari = 'webkitfullscreenchange', // wishful thinking at this point, iOS Safari doesn't support the Fullscreen API
}

const DEFAULT_EVENT_STRING = 'fullscreenchange';

/**
 * Each browser requires a specific event name to listen for on the fullscreen event.
 * This function takes a browser name and supplies the appropriate event string.
 * @returns the specific event to listen for, depending on the browser supplied
 */
const getListenerStringForBrowser = ():
  | BrowserEventString
  | MobileBrowserEventString
  | 'fullscreenchange' => {
  const browser = detect();
  if (!browser) {
    return DEFAULT_EVENT_STRING;
  } else if (browser.os === 'Android OS' || browser.os === 'iOS') {
    // @ts-expect-error
    return MobileBrowserEventString[browser.name] ?? DEFAULT_EVENT_STRING;
  } else {
    // @ts-expect-error
    return BrowserEventString[browser.name] ?? DEFAULT_EVENT_STRING;
  }
};

const EVENT_STRING = getListenerStringForBrowser();

/**
 * Adds a fullscreen listener to the document, and sends an
 * event to the metrics engine when the user enters fullscreen.
 */
export const addFullscreenEventListener = (
  sendFullscreenEvent: (fullscreen: boolean) => void
): void => {
  document.addEventListener(EVENT_STRING, () => {
    if (
      document.fullscreenElement ||
      // @ts-expect-error - webkitFullscreenElement doesn't exist on Document type
      document.webkitFullscreenElement ||
      // @ts-expect-error - msFullscreenElement doesn't exist on Document type
      document.msFullscreenElement
    ) {
      sendFullscreenEvent(true);
    } else {
      sendFullscreenEvent(false);
    }
  });
};

export const removeFullscreenEventListener = (): void => {
  document.removeEventListener(EVENT_STRING, () => {});
};
