import QuoteMomentShareSheet from './quoteMomentShareSheet';
import { connect } from 'react-redux';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';

interface StateProps {
  organizationName: string;
}

interface OwnProps {
  text: string;
  attribution: string | null;
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  organizationName: getCurrentOrganization(state).name,
  ...ownProps,
});

const VisibleQuoteMomentShareSheet = connect(mapStateToProps)(QuoteMomentShareSheet);

export type Props = StateProps & OwnProps;
export default VisibleQuoteMomentShareSheet;
