import React, { FC } from 'react';

interface Props {
  onAnimationEnd?: () => void;
}

const Heart: FC<Props> = ({ onAnimationEnd }) => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onAnimationEnd={onAnimationEnd}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9611 8.73629H15.961C13.3793 6.15453 9.19326 6.15455 6.6114 8.73638C4.02954 11.3183 4.02954 15.5042 6.6114 18.0861L6.63272 18.1074L7.6503 19.1249L7.65014 19.1251L16.9998 28.4748L27.368 18.1066L27.3886 18.0861C29.9705 15.5042 29.9705 11.3183 27.3886 8.73638C24.8068 6.15455 20.6207 6.15455 18.0389 8.73638L18.0388 8.73655L18.0387 8.73646L17 9.77516L15.9611 8.73629Z'
        fill='#E83665'
      />
    </svg>
  );
};

export default Heart;
