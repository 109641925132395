import styled from 'styled-components';
import { LargeUpQuery } from '@utils/responsive';
import { Box } from '@lifechurch/react-ion';

interface DefaultProps {
  isGuest?: boolean;
  hasMedia?: boolean;
}

export const Container = styled.div`
  background-color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const Message = styled.div<DefaultProps>`
  padding: ${props => props.isGuest && '43px'} 16px;
  text-align: ${props => (props.isGuest ? 'center' : 'start')};
  display: flex;
  margin: auto;
  width: 100%;
  flex-direction: column;
  align-items: ${props => (props.isGuest ? 'center' : 'flex-start')};
  justify-content: space-evenly;
  max-width: calc(177vh - 347px);

  @media ${LargeUpQuery} {
    flex-direction: ${props => (props.hasMedia ? 'row' : 'column')};
  }
`;

export const Info = styled(Box)<DefaultProps>`
  flex-grow: 1;
  margin-block-end: ${props => (props.isGuest ? (props.hasMedia ? '40px' : '32px') : '0')};

  @media ${LargeUpQuery} {
    margin-block-end: ${props => props.isGuest && props.hasMedia && '0'};
  }
`;

export const PrayerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
