import Moment from './moment';
import { connect } from 'react-redux';
import { Moment as MomentInterface } from './dux';
import { FeedObjectMetaData } from '../dux';
import { getMomentById, momentLiked, momentSubmitted } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { DeleteMomentActionArgs, dismissAnchoredMoment } from '@store/feedSlice';
import { hasPermissions } from '@store/subscriberSlice/selectors';
import { setModal } from '@store/uiSlice';
import { deleteMoment } from '@components/modal/deleteMoment/dux';

interface StateProps {
  moment?: MomentInterface;
  hasSubmitted?: boolean;
  hasLiked?: boolean;
  moderationPermissions: boolean;
}

interface OwnProps {
  momentId: string;
  channel: string;
  metaData?: FeedObjectMetaData;
  isAnchored: boolean;
  hasSubmitted?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channel, momentId } = ownProps;
  const moment = getMomentById(state, channel, momentId);
  const hasSubmitted = Boolean(moment && momentSubmitted(state, moment.id));
  const hasLiked = Boolean(moment && momentLiked(state, moment.id));

  return {
    moment,
    hasSubmitted,
    hasLiked,
    moderationPermissions: hasPermissions(state, ['event.moment.delete']) || false,
    ...ownProps,
  };
};

interface DispatchProps {
  dismissAnchoredMoment: (channelKey: string) => void;
  deleteMoment: (momentInstance: DeleteMomentActionArgs) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dismissAnchoredMoment: key => dispatch(dismissAnchoredMoment({ key })),
  deleteMoment: momentInstance => {
    dispatch(setModal(deleteMoment(momentInstance)));
  },
});

const VisibleMoment = connect(mapStateToProps, mapDispatchToProps)(Moment);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleMoment;
