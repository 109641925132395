import React, { FC } from 'react';
import Media from '@features/media';
import ServiceInfo from '@features/serviceInfo';
import { Props } from './index';
import Empty from './empty';
import Error from './error';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';

const ServiceWindow: FC<Props> = ({ isEmpty, url, isDuringService, isLoading, showError }) => {
  if (showError) return <Error />;
  if (isLoading) return <div className='flex-1 bg-black' data-testid='service-window-loading' />;
  if (isEmpty) return <Empty />;

  return (
    <>
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.NONE}>
        <Media url={url} />
      </ErrorBoundary>
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.VIDEO_META}>
        <ServiceInfo hasMedia={!!url} isDuringService={isDuringService} />
      </ErrorBoundary>
    </>
  );
};

export default ServiceWindow;
