import { Trans, useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { BaseAlert } from '../BaseAlert';
import { Props } from './index';
import { Box } from '@lifechurch/react-ion';
import { IconWrapper, RoleTag, ActionableAlertCallToAction, Spacer } from '../styles';
import RoleIdentifierInvertedIcon from '../../icons/roleIdentifierInverted';
import { RoleIdentifierType } from '../../../../__generated__/globalTypes';

const PrayerAlert: FC<Props> = ({ alert, dismissAlert, setPaneToChat }) => {
  const { t } = useTranslation('');
  const onClick = () => {
    if (alert?.meta?.channelKey) {
      dismissAlert();
      setPaneToChat(alert.meta.channelKey);
    }
  };

  if (!alert?.variables?.name) {
    return null;
  }

  const { name, role, roleIdentifierKey, roleIdentifierLabel, roleIdentifierType } =
    alert?.variables || {};
  const label = roleIdentifierLabel ? roleIdentifierLabel : role;

  return (
    <BaseAlert
      fuseDisabled={alert.fuseDisabled}
      uniqueKey={alert.uniqueKey}
      type={alert.type}
      onDismiss={dismissAlert}
    >
      <Box>
        <Trans ns='notifications' i18nKey={alert.key}>
          <strong>{{ name }}</strong>
          {!roleIdentifierType && <Spacer data-testid='spacer' />}
          {roleIdentifierType === RoleIdentifierType.SYMBOL && (
            <IconWrapper data-testid='icon'>
              <RoleIdentifierInvertedIcon />
            </IconWrapper>
          )}
          {roleIdentifierType === RoleIdentifierType.TEXT && (
            <RoleTag>{t([roleIdentifierKey, label])}</RoleTag>
          )}
          has received your request and is ready to pray.
        </Trans>
      </Box>
      <ActionableAlertCallToAction onClick={onClick} whiteSpace='no-wrap'>
        <Trans ns='notification' i18nKey='request_prayer_cta'>
          Pray Now
        </Trans>
      </ActionableAlertCallToAction>
    </BaseAlert>
  );
};

export default PrayerAlert;
