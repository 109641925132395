import React, { FC, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';
import {
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  OParagraph,
  ModalText,
} from '@lifechurch/react-ion';
import { ModalType } from '../dux';

const RemoveAvatar: FC<Props> = ({ subscriberId, removeAvatar, modal, openModal }) => {
  const { t } = useTranslation('forms');

  const modalType = modal?.type ?? ModalType.PROFILE_SETTINGS;

  const handleRemoveAvatar = () => removeAvatar(subscriberId, modalType);
  const handleOpenModal = () => openModal(modalType);

  const handleBackKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      handleOpenModal();
    }
  };

  const handleRemoveAvatarKeyDown: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      handleRemoveAvatar();
    }
  };

  return (
    <Modal dismiss={handleOpenModal} size={ModalSize.NARROW} testId='removeAvatar-modal'>
      <ModalText>
        <OParagraph marginBlockEnd={5}>{t('remove_avatar.message')}</OParagraph>
      </ModalText>
      <ModalButtons>
        <LinkButton
          buttonType={LinkButtonType.SECONDARY}
          onClick={handleOpenModal}
          marginInlineEnd={5}
          onKeyDown={handleBackKeyDown}
          tabIndex={0}
          role='button'
        >
          {t('remove_avatar.cancel')}
        </LinkButton>
        <LinkButton
          buttonType={LinkButtonType.ERROR}
          onClick={handleRemoveAvatar}
          onKeyDown={handleRemoveAvatarKeyDown}
          tabIndex={0}
          role='button'
        >
          {t('remove_avatar.confirm')}
        </LinkButton>
      </ModalButtons>
    </Modal>
  );
};

export default RemoveAvatar;
