import { useEffect, useMemo } from 'react';

interface MutationObserverProps {
  target?: HTMLElement | undefined;
  options: MutationObserverInit;
  callback: MutationCallback;
}

export const useMutationObserver = ({
  target = document.documentElement,
  options,
  callback,
}: MutationObserverProps): void => {
  const observer = useMemo(() => new MutationObserver(callback), [callback]);
  useEffect(() => {
    if (!target) {
      return;
    }
    observer.observe(target, options);
    return () => {
      observer.disconnect();
    };
  }, [target, options, observer]);
};
