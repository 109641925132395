import React, { KeyboardEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { Box } from './Box';
import { getInset } from './logical';

export enum SwitchSizeType {
  XS = 'xsmall',
  SM = 'small',
  MD = 'medium',
}

interface ToggleProps {
  isOn: boolean;
  size: SwitchSizeType;
  disabled: boolean;
}

interface HiddenCheckboxProps {
  switchSize: SwitchSizeType;
}

interface ContentProps {
  isOn: boolean;
  size: SwitchSizeType;
}

const Toggle = styled.label<ToggleProps>`
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  display: block;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  transition: 0.3s;
  background-color: ${(props): string =>
    props.isOn ? props.theme.colors.action.default : props.theme.colors.gray50};
  width: ${(props): string => {
    switch (props.size) {
      case SwitchSizeType.XS:
        return '31px';
      case SwitchSizeType.SM:
        return '64px';
      case SwitchSizeType.MD:
        return '74px';
      default:
        return '64px';
    }
  }};
  height: ${(props): string => {
    switch (props.size) {
      case SwitchSizeType.XS:
        return '16px';
      case SwitchSizeType.SM:
        return '24px';
      case SwitchSizeType.MD:
        return '32px';
      default:
        return '24px';
    }
  }};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &::after {
    background-color: ${(props): string => props.theme.colors.white};
    position: absolute;
    content: '';
    transition: 0.3s;
    ${getInset('insetInlineStart', '1px')};
    ${getInset('insetBlockStart', '1px')};
    border-radius: ${(props): string => (props.size === SwitchSizeType.XS ? '12px' : '18px')};
    width: ${(props): string => {
      switch (props.size) {
        case SwitchSizeType.XS:
          return '14px';
        case SwitchSizeType.SM:
          return '22px';
        case SwitchSizeType.MD:
          return '30px';
        default:
          return '22px';
      }
    }};
    height: ${(props): string => {
      switch (props.size) {
        case SwitchSizeType.XS:
          return '14px';
        case SwitchSizeType.SM:
          return '22px';
        case SwitchSizeType.MD:
          return '30px';
        default:
          return '22px';
      }
    }};
  }
`;

const HiddenCheckbox = styled.input<HiddenCheckboxProps>`
  visibility: hidden;
  position: absolute;
  ${getInset('insetInlineStart', '-9999px')};

  &:checked + ${/* sc-selector */ Toggle}::after {
    transform: ${(props): string => {
      switch (props.switchSize) {
        case SwitchSizeType.XS:
          return 'translateX(15px)';
        case SwitchSizeType.SM:
          return 'translateX(40px)';
        case SwitchSizeType.MD:
          return 'translateX(42px)';
        default:
          return 'translateX(40px)';
      }
    }};
  }

  [dir='rtl'] &&& {
    &:checked + ${/* sc-selector */ Toggle}::after {
      transform: ${(props): string => {
        switch (props.switchSize) {
          case SwitchSizeType.XS:
            return 'translateX(-15px)';
          case SwitchSizeType.SM:
            return 'translateX(-40px)';
          case SwitchSizeType.MD:
            return 'translateX(-42px)';
          default:
            return 'translateX(-40px)';
        }
      }};
    }
  }
`;

const Content = styled.p<ContentProps>`
  position: absolute;
  user-select: none;
  color: ${(props): string => props.theme.colors.white};
  font-size: ${(props): string =>
    props.size === SwitchSizeType.SM ? props.theme.fontSizes[2] : props.theme.fontSizes[3]};
  line-height: ${(props): number =>
    props.size === SwitchSizeType.SM ? props.theme.lineHeights[1] : props.theme.lineHeights[2]}rem;
  ${props =>
    props.size === SwitchSizeType.SM
      ? getInset('insetBlockStart', '4px')
      : getInset('insetBlockStart', '6px')};
  ${props => {
    if (props.size === SwitchSizeType.SM) {
      return css`
        ${props.isOn ? 'left: 8px; right: unset;' : 'left: unset; right: 8px;'}
        [dir='rtl'] &&& {
          ${props.isOn ? 'left: unset; right: 8px;' : 'left: 8px; right: unset;'}
        }
      `;
    } else {
      return css`
        ${props.isOn ? 'left: 12px; right: unset;' : 'left: unset; right: 12px;'}
        [dir='rtl'] &&& {
          ${props.isOn ? 'left: unset; right: 12px;' : 'left: 12px; right: unset;'}
        }
      `;
    }
  }}
`;

interface SwitchProps {
  /**
   * A callback function that's called when the switch is toggled
   */
  action: () => void;
  /**
   * Used as the accessibility label
   */
  ariaLabel?: string;
  /**
   * Specifies the class name
   */
  className?: string;
  /**
   * Determines whether or not a user can toggle the switch
   */
  disabled?: boolean;
  /**
   * Specifies an unique id
   */
  id?: string;
  /**
   * Determines whether the switch starts in the on or off position
   */
  on: boolean;
  /**
   * The size of the switch
   */
  size?: SwitchSizeType;
  /**
   * Indicates that the switch can be focused, and where it participates in sequential keyboard navigation
   */
  tabIndex?: number;
  /**
   * Customize toggle label.
   */
  label?: { on: string; off: string };
}
/**
 * The Switch component is a visual toggle between two states -- on and off
 *
 *
 * ###When To Use
 *
 * - Allow users to adjust settings
 *
 */
export const Switch: React.FunctionComponent<SwitchProps> = ({
  action,
  ariaLabel,
  className,
  disabled = false,
  id = 'switch',
  on,
  size = SwitchSizeType.MD,
  tabIndex = 0,
  label = {
    on: 'On',
    off: 'Off',
  },
}: SwitchProps) => {
  const handleOnKeyDown: KeyboardEventHandler<HTMLLabelElement> = e => {
    if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
      action();
    }
  };

  return (
    <Box>
      <HiddenCheckbox
        data-testid='switch'
        type='checkbox'
        checked={on}
        onChange={action}
        id={id}
        switchSize={size}
        disabled={disabled}
        aria-checked={on}
      />
      <Toggle
        aria-label={ariaLabel}
        className={className}
        data-testid='switch-label'
        disabled={disabled}
        htmlFor={id}
        isOn={on}
        onKeyDown={handleOnKeyDown}
        role='switch'
        size={size}
        tabIndex={tabIndex}
      >
        {on && size !== SwitchSizeType.XS && (
          <Content size={size} isOn={on}>
            {label.on}
          </Content>
        )}
        {!on && size !== SwitchSizeType.XS && (
          <Content size={size} isOn={on}>
            {label.off}
          </Content>
        )}
      </Toggle>
    </Box>
  );
};
