import styled, { css, keyframes } from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

const slideUp = (y: number) => keyframes`
  to {
    transform: translateY(${y}px);
  }
`;

const explode = (r: number) => keyframes`
  from {
    r: 16px;
  }
  to {
    r: ${r}px;
    opacity: 0;
  }
`;

const fadeOut = keyframes`
  to {
    opacity: 0;
  }
`;

const joinGroup = (startY: number, endY: number) => keyframes`
  from {
    opacity: 1;
    transform: translateY(${startY}px); 
  }
  to {
    transform: translateY(${endY}px);
    opacity: 0;
  }
`;

interface WrapperProps {
  firstStageEndingPosition: number;
  firstStageDuration: number;
  explodeRadius: number;
  secondStageEndingPosition: number;
  secondStageDuration: number;
  insetInlineEnd: number;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  ${props => css`
    [dir='rtl'] &&& {
      left: ${props.insetInlineEnd}px;
    }
    [dir='ltr'] &&& {
      right: ${props.insetInlineEnd}px;
    }
  `}
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  ${getInset('insetBlockStart', '-25px')};
  svg:nth-of-type(1) {
    overflow: visible;
    animation: ${props => props.firstStageDuration}ms
      ${props => slideUp(props.firstStageEndingPosition)} cubic-bezier(0.5, 0.8, 0.6, 1);
    animation-fill-mode: forwards;
    animation-play-state: running;
    position: absolute;
    circle {
      animation: 700ms ${props => explode(props.explodeRadius)} cubic-bezier(0.2, 0.6, 0.6, 1)
        ${props => props.firstStageDuration}ms;
      animation-fill-mode: forwards;
    }
    path {
      animation: 700ms ${fadeOut} cubic-bezier(0.2, 0.6, 0.6, 1)
        ${props => props.firstStageDuration}ms;
      animation-fill-mode: forwards;
    }
  }
  svg:nth-of-type(2) {
    opacity: 0;
    animation: ${props => props.secondStageDuration}ms
      ${props => joinGroup(props.firstStageEndingPosition, props.secondStageEndingPosition)}
      ease-out ${props => props.firstStageDuration}ms;
    animation-play-state: running;
  }
`;
