import React, { FC, useContext } from 'react';
import { EnterFullscreenIcon, ExitFullscreenIcon, IconSize } from '@lifechurch/react-ion';
import { ThemeContext } from 'styled-components';

interface Props {
  expandHostVideo: () => void;
  isLargeHostVideo: boolean;
  sendResizeHostVideoMetric: (subscriber_id: string, video_size: 'regular' | 'large') => void;
  shrinkHostVideo: () => void;
  subscriberId: string;
}

const ResizeButton: FC<Props> = ({
  expandHostVideo,
  isLargeHostVideo,
  sendResizeHostVideoMetric,
  shrinkHostVideo,
  subscriberId,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <button
      data-testid={'resize-video'}
      onClick={() => {
        if (isLargeHostVideo) {
          shrinkHostVideo();
          sendResizeHostVideoMetric(subscriberId, 'regular');
        } else {
          expandHostVideo();
          sendResizeHostVideoMetric(subscriberId, 'large');
        }
      }}
    >
      {isLargeHostVideo ? (
        <ExitFullscreenIcon color={theme.colors.white} size={IconSize.MD} />
      ) : (
        <EnterFullscreenIcon color={theme.colors.white} size={IconSize.MD} />
      )}
    </button>
  );
};

export default ResizeButton;
