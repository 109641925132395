import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { DuplicateIcon } from './icons';
import { TooltipWrapper } from './Tooltip';
import { usePopper } from 'react-popper';
import copy from 'copy-to-clipboard';
import { getInset } from './logical';

interface ReadOnlyProps {
  value: string | number | readonly string[];
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  ${getInset('insetInlineEnd', 0)};
  cursor: pointer;
  height: 40px;
  width: 40px;

  svg {
    display: block;
  }

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.action.hover};
    }
  }
`;

export const ReadOnlyButton: FunctionComponent<ReadOnlyProps> = ({ value }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'left' });

  const handleShowTooltip = () => setShowTooltip(true);
  const handleHideTooltip = () => {
    setShowTooltip(false);
    setHasCopied(false);
  };

  const handleClick = () => {
    copy(value.toString());
    setHasCopied(true);
  };

  return (
    <>
      <Wrapper
        onClick={handleClick}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        ref={setReferenceElement}
      >
        <DuplicateIcon />
      </Wrapper>
      {showTooltip && (
        <TooltipWrapper
          ref={setPopperElement}
          success={hasCopied}
          style={styles.popper}
          {...attributes.popper}
        >
          {hasCopied ? 'Copied' : 'Copy'}
        </TooltipWrapper>
      )}
    </>
  );
};
