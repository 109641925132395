import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleUpIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.50004 5.95707L3.35359 10.1035L2.64648 9.39641L7.50004 4.54286L12.3536 9.39641L11.6465 10.1035L7.50004 5.95707Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M3.29297 12.2928L10.0001 5.58571L16.7072 12.2928L15.293 13.707L10.0001 8.41414L4.70718 13.707L3.29297 12.2928Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M3.29297 15.2928L12.0001 6.58571L20.7072 15.2928L19.293 16.707L12.0001 9.41414L4.70718 16.707L3.29297 15.2928Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M4.79297 20.7928L16.5001 9.08571L28.2072 20.7928L26.793 22.207L16.5001 11.9141L6.20718 22.207L4.79297 20.7928Z'
          size={size}
          {...rest}
        />
      );
  }
};
