import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { DeleteMomentActionArgs } from '@store/feedSlice';
import { ModalType } from '../dux';

// explicity setting the type on this variable to make the it play nicely with the
// other modal action/payloads that weren't created with an RTK action creator
export const deleteMoment: ActionCreatorWithPayload<
  DeleteMomentActionArgs,
  ModalType.DELETE_MOMENT
> = createAction(ModalType.DELETE_MOMENT);

export const deleteMomentRequest = createAction<DeleteMomentActionArgs>('DELETE_MOMENT_REQUEST');

export interface DeleteMoment {
  type: ModalType.DELETE_MOMENT;
  payload: DeleteMomentActionArgs;
}
