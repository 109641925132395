import React, { FunctionComponent } from 'react';
import SideMenu from '@features/sideMenu';
import Pane from '@features/pane';
import styled from 'styled-components';
import NavMenu from '@features/navMenu';
import Service from '@features/pane/types/service';
import { Container, CellContainer } from './styles';
import { Grid, Cell } from 'styled-css-grid';
import { PaneWrapper, PaneContentWrapper } from '@features/pane/styles';
import ServiceWindow from '../../ServiceWindow';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { Props } from './index';

// For some reason the height: 100% doesn't take the height: calc() into account
const GridCell = styled(Cell)`
  height: initial;
`;

const Large: FunctionComponent<Props> = ({ largeHostVideo }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.SIDE_MENU}
        altComponentName={t('side_menu')}
      >
        <SideMenu />
      </ErrorBoundary>
      <Grid
        columns={'auto 1fr 1fr'}
        rows={'auto 1fr'}
        areas={
          largeHostVideo
            ? ['menu video video', 'menu chat public']
            : ['menu video public', 'menu chat public']
        }
        height='100%'
        columnGap='0'
      >
        <GridCell area='menu'>
          <ErrorBoundary>
            <NavMenu />
          </ErrorBoundary>
        </GridCell>
        <GridCell area='video'>
          <CellContainer topCell>
            <ServiceWindow />
          </CellContainer>
        </GridCell>
        <GridCell area='chat'>
          <CellContainer bottomCell>
            <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE}>
              <Pane />
            </ErrorBoundary>
          </CellContainer>
        </GridCell>
        <GridCell area='public'>
          <CellContainer
            staticCell={largeHostVideo ? false : true}
            bottomCell={largeHostVideo ? true : false}
          >
            <PaneWrapper>
              <PaneContentWrapper>
                <Service isGuest={false} paneId={2} />
              </PaneContentWrapper>
            </PaneWrapper>
          </CellContainer>
        </GridCell>
      </Grid>
    </Container>
  );
};

export default React.memo(Large);
