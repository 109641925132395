import styled, { css } from 'styled-components';
import { LandscapeMobile, LargeUpQuery, MediumQuery, MediumPlusQuery } from '@utils/responsive';
import { getInset } from '@lifechurch/react-ion';

interface WrapperProps {
  isGuest?: boolean;
}

interface ContentWrapperProps {
  isGuest?: boolean;
}

const PaneWrapper = styled.div<WrapperProps>`
  flex: 1;
  height: 100%;
  border-block-start: 0;
  position: relative;
  @media ${LandscapeMobile} {
    display: none;
  }

  ${props =>
    props.isGuest &&
    css`
      @media ${LargeUpQuery} {
        height: calc(100% - 32px);
        border-block-start: '1px solid ' + props.theme.colors.gray5;
      }
    `}
`;

const PaneContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  margin: 0 auto;
  max-width: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  z-index: 999;

  @media ${MediumQuery} {
    max-width: ${props => (props.isGuest ? '476px' : 'inherit')};
  }

  @media ${MediumPlusQuery} {
    max-width: ${props => (props.isGuest ? '640px' : 'inherit')};
  }
`;

export { PaneWrapper, PaneContentWrapper };
