import styled from 'styled-components';

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const FileInputLabel = styled.label`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[2]};
  font-weight: ${props => props.theme.fontWeights[2]};
  color: ${props => props.theme.colors.gray50};
  cursor: pointer;
  padding: 1px 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.action.hover};
  }
  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;
