import React, { FC, useContext } from 'react';
import { Props } from './index';
import { Container, Text, CloseButton } from './styles';
import { TimesIcon, IconSize } from '@lifechurch/react-ion';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LargeUp, MediumPlusDown } from '@utils/responsive';

const ReactionBanner: FC<Props> = ({ isVisible, closeReactionBanner }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  if (isVisible) {
    return (
      <Container data-testid='reaction_banner'>
        <LargeUp>
          <Text tabIndex={0}>{t('reaction_banner_large')}</Text>
        </LargeUp>
        <MediumPlusDown>
          <Text tabIndex={0}>{t('reaction_banner')}</Text>
        </MediumPlusDown>
        <CloseButton
          data-testid='reaction_banner-close'
          aria-label={t('aria.reaction_banner_close')}
          onClick={() => closeReactionBanner()}
        >
          <TimesIcon color={theme.colors.white} size={IconSize.MD} />
        </CloseButton>
      </Container>
    );
  }
  return null;
};

export default ReactionBanner;
