import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const StarSlashIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 0.200195L8.77001 4.10887L4.60814 8.27073L0.5 5.28599H5.84752L7.5 0.200195ZM4.75635 12.3652L11.8355 5.28599H14.5L10.1738 8.42919L11.8262 13.515L7.5 10.3718L4.75635 12.3652ZM2.35355 13.3536L13.3536 2.35355L12.6464 1.64645L1.64645 12.6464L2.35355 13.3536Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 0.00976562L11.7563 5.41523L6.15271 11.0189L0.5 6.91192H7.75735L10 0.00976562ZM6.39562 16.4328L15.9165 6.91192H19.5L13.6287 11.1777L15.8713 18.0798L10 13.8141L6.39562 16.4328ZM3.70711 17.7071L17.7071 3.70711L16.2929 2.29289L2.29289 16.2929L3.70711 17.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 0L14.3123 6.85926L7.33584 13.8357L7.41641 13.5967L0 8.40325H9.16718L12 0ZM5.40045 21.428L18.4252 8.40325H24L16.5836 13.5967L19.4164 22L12 16.8065L5.40045 21.428ZM3.70711 21.7071L21.7071 3.70711L20.2929 2.29289L2.29289 20.2929L3.70711 21.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 0L19.1906 9.98094L9.81093 19.3606L10.175 18.2217L0.75 11.2616H12.4L16 0ZM7.15288 27.6755L23.5668 11.2616H31.25L21.825 18.2217L25.425 29.4833L16 22.5233L6.57498 29.4833L7.15288 27.6755ZM28.2929 2.29289L2.29289 28.2929L3.70711 29.7071L29.7071 3.70711L28.2929 2.29289Z'
          size={size}
          {...rest}
        />
      );
  }
};
