import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const HeartOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M6.25664 3.75031L6.24096 3.7345C6.24056 3.7341 6.24015 3.7337 6.23975 3.7333C5.26032 2.75517 3.6734 2.75557 2.69447 3.7345C1.71512 4.71385 1.71509 6.30169 2.69435 7.28101C2.69435 7.28101 2.69434 7.281 2.69435 7.28101L7.49913 12.0858L12.2857 7.29907L12.3039 7.28103C12.3044 7.28057 12.3048 7.28011 12.3053 7.27964C13.2833 6.3002 13.2828 4.71341 12.3039 3.73454C11.3246 2.75518 9.73668 2.75515 8.75737 3.73441C8.75737 3.73442 8.75737 3.73441 8.75737 3.73441L7.49905 4.99273L6.25664 3.75031ZM13.011 7.98814L12.9899 8.00912L7.49914 13.5L1.98724 7.98812C0.617432 6.61825 0.617516 4.39724 1.98736 3.0274C3.35112 1.66363 5.55844 1.65756 6.92972 3.00918C6.93585 3.01522 6.94197 3.0213 6.94807 3.0274L6.96626 3.04573L7.49905 3.57852L8.05026 3.02731C9.42013 1.65748 11.6412 1.65758 13.011 3.02743C14.3809 4.39729 14.3809 6.61828 13.011 7.98814Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M9.27557 7.16224L7.86933 5.7496C7.86885 5.74912 7.86838 5.74865 7.86791 5.74818C6.86825 4.75005 5.24873 4.75053 4.24966 5.7496C3.25042 6.74884 3.25011 8.36873 4.24874 9.36835C4.24905 9.36865 4.24935 9.36896 4.24966 9.36927L4.26014 9.37971L6.3846 11.5042L9.99996 15.1197L13.6197 11.4999L15.7451 9.37453L15.7503 9.36929C16.7499 8.36975 16.7499 6.74917 15.7503 5.74962C14.7508 4.75008 13.1302 4.75008 12.1307 5.74962L12.1255 5.75474L10 7.88025L9.2788 7.15903L9.27557 7.16224ZM9.96455 5.01636L10 5.05183L10.7163 4.33557M11.0556 4.02823C11.0556 4.02827 11.0557 4.0282 11.0556 4.02823C10.9388 4.12392 10.8253 4.22653 10.7163 4.33557C10.7162 4.3356 10.7163 4.33555 10.7163 4.33557M11.0556 4.02823C12.8466 2.56161 15.4931 2.66393 17.1645 4.33541C18.9429 6.11377 18.9451 8.99568 17.1712 10.7768C17.169 10.779 17.1668 10.7813 17.1645 10.7835L17.1579 10.7902L16.4481 11.4999L10 17.948L3.57828 11.5264L3.55184 11.5L3.52548 11.4735L2.84593 10.7939L2.83544 10.7835C2.79278 10.7408 2.75113 10.6975 2.71051 10.6536C2.70981 10.6528 2.7091 10.6521 2.7084 10.6513C1.05591 8.86279 1.09826 6.07257 2.83544 4.33538C4.61604 2.55479 7.50295 2.55479 9.28354 4.33538L9.96455 5.01636'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M19.4361 11.2007C20.8546 9.78222 20.8546 7.48237 19.4361 6.06388C18.0177 4.64542 15.7177 4.64542 14.2992 6.06396L12.0001 8.36301L9.70082 6.06403C8.28232 4.64554 5.98237 4.64543 4.56387 6.06393C3.14599 7.48182 3.14538 9.78028 4.56203 11.1989C4.56265 11.1995 4.56326 11.2002 4.56387 11.2008L4.58713 11.2239L12 18.6368L19.4361 11.2007ZM12 21.4652L3.17291 12.6381L3.14966 12.615C0.950113 10.4154 0.950113 6.84926 3.14966 4.64972C5.34921 2.45017 8.91538 2.45017 11.1149 4.64972L12 5.53469L12.885 4.64975C15.0845 2.4502 18.6508 2.45011 20.8503 4.64966C23.0499 6.84921 23.0499 10.4154 20.8503 12.6149L20.8404 12.6249L12 21.4652Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M14.7718 9.10045L13.3635 7.69227C11.1071 5.43589 7.44874 5.43591 5.19232 7.69233C2.93637 9.94828 2.93589 13.6056 5.19088 15.8621C5.19136 15.8626 5.19184 15.8631 5.19233 15.8636L5.21742 15.8886L7.8279 18.4991L15.9998 26.6714L26.7866 15.8847L26.8077 15.8636C26.8082 15.8632 26.8087 15.8627 26.8091 15.8622C29.0642 13.6057 29.0637 9.94832 26.8077 7.69235C24.5513 5.43592 20.8929 5.43591 18.6365 7.69235L18.6354 7.69342L16 10.3287L14.7718 9.10045ZM17.222 6.27824C20.2595 3.24076 25.1845 3.24065 28.2219 6.27814C31.2594 9.31562 31.2594 14.2403 28.2219 17.2778L28.1977 17.3019L15.9998 29.4998L5.00016 18.5001L3.8032 17.3028L3.77811 17.2778C0.740629 14.2403 0.74063 9.31559 3.77811 6.27811C6.81557 3.24065 11.7402 3.24063 14.7777 6.27804L16 7.50024L17.222 6.27824Z'
          size={size}
          {...rest}
        />
      );
  }
};
