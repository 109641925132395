import React, { createRef, FunctionComponent, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import { ButtonSize, PrimaryOnDarkButton } from '@lifechurch/react-ion';
import { UnmuteButtonWrapper } from '../styles';
import { useTranslation } from 'react-i18next';

interface Props {
  url?: string | null;
  videoStartTime?: string | undefined;
  onPause: () => void;
  onPlay: () => void;
  isVideoPlaying: boolean;
}

enum UnmutingStates {
  PLAY_DEFAULT, // try autoplaying with sound (the ideal case)
  PLAY_DEFAULT_PLAYED, // we got an onPlay when we were in PLAY_DEFAULT state. Maybe it's starting.
  STOPPING, // stop playback (because autoplaying failed)
  PLAY_MUTED, // play while muted, and show the "Tap to unmute" button.
  DONE, // either we've succeeded, or else we have given up.
}

const SimulatedLivePlayer: FunctionComponent<Props> = ({
  url,
  onPlay,
  onPause,
  videoStartTime,
  isVideoPlaying,
}) => {
  const ref = createRef<ReactPlayer>();
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const [unmutingState, setUnmutingState] = useState(UnmutingStates.PLAY_DEFAULT);
  const [volume, setVolume] = useState<number>(-1.0); // A negative value means to not set a volume level. Positive values will be set as the actual level.
  const [isReady, setIsReady] = useState(false);

  const attemptToPlayMuted = () => {
    if (isReady) {
      setVolume(0.0);
      setUnmutingState(UnmutingStates.STOPPING);
      setTimeout(() => {
        // we need to give it time to stop, before we start it up again.
        setUnmutingState(UnmutingStates.PLAY_MUTED);
      }, 100);
    }
  };

  // when the URL changes, reset the state machine
  useEffect(() => {
    if (unmutingState !== UnmutingStates.PLAY_DEFAULT) {
      setUnmutingState(UnmutingStates.PLAY_DEFAULT);
    }
  }, [url]);

  // if we stay in PLAY_DEFAULT too long, it's not working. Try muting.
  useEffect(() => {
    if (unmutingState !== UnmutingStates.PLAY_DEFAULT) return;
    const timer = setTimeout(() => {
      attemptToPlayMuted();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [unmutingState]);

  // if we're in PLAY_DEFAULT_PLAYED long enough, then declare success.
  useEffect(() => {
    if (unmutingState !== UnmutingStates.PLAY_DEFAULT_PLAYED) return;
    const timer = setTimeout(() => {
      setUnmutingState(UnmutingStates.DONE);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [unmutingState]);

  const onError = () => {
    if (unmutingState === UnmutingStates.PLAY_DEFAULT) {
      attemptToPlayMuted();
    }
  };

  const onPlayHook = () => {
    if (unmutingState === UnmutingStates.PLAY_DEFAULT) {
      setUnmutingState(UnmutingStates.PLAY_DEFAULT_PLAYED);
    }
    onPlay();
  };

  const onPauseHook = () => {
    if (
      unmutingState === UnmutingStates.PLAY_DEFAULT ||
      unmutingState === UnmutingStates.PLAY_DEFAULT_PLAYED
    ) {
      attemptToPlayMuted();
    }
    onPause();
  };

  const onUnmute = () => {
    if (!isReady) {
      return; // bail if the video isn't ready
    }

    setVolume(0.5);
    if (
      unmutingState === UnmutingStates.PLAY_DEFAULT ||
      unmutingState === UnmutingStates.PLAY_DEFAULT_PLAYED
    ) {
      setUnmutingState(UnmutingStates.STOPPING);
      setTimeout(() => {
        // we need to give it time to stop, before we start it up again.
        setUnmutingState(UnmutingStates.DONE);
      }, 100);
    } else {
      setUnmutingState(UnmutingStates.DONE);
    }
  };

  const onReady = () => {
    setIsReady(true);
    getNSync();
  };

  const syncTime = () => dayjs().diff(dayjs(videoStartTime), 'second');

  const getNSync = () => {
    if (videoStartTime) {
      ref.current?.seekTo(syncTime());
    }
  };

  useEffect(() => {
    if (ref.current && videoStartTime && isVideoPlaying) {
      getNSync();
    }
  }, [isVideoPlaying, videoStartTime]);

  if (!url) {
    return null;
  }

  return (
    <>
      {unmutingState === UnmutingStates.PLAY_MUTED && (
        <UnmuteButtonWrapper>
          <PrimaryOnDarkButton
            onClick={onUnmute}
            size={ButtonSize.SM}
            style={{ pointerEvents: 'auto' }}
          >
            {t('tap_to_unmute')}
          </PrimaryOnDarkButton>
        </UnmuteButtonWrapper>
      )}
      <ReactPlayer
        ref={ref}
        data-testid='simulatedLivePlayer'
        url={url}
        style={{
          position: 'absolute',
          top: 0,
          left: !isRTL ? 0 : undefined,
          right: isRTL ? 0 : undefined,
        }}
        width='100%'
        height='100%'
        frameBorder='0'
        playing={unmutingState !== UnmutingStates.STOPPING}
        volume={volume >= 0 ? volume : undefined}
        muted={unmutingState === UnmutingStates.PLAY_MUTED}
        controls
        playsinline
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,
              disablekb: 1,
              modestbranding: 1,
              rel: 0,
              showinfo: 1, // This moves the volume control to the bottom bar on iOS (why? I dunno)
            },
          },
          vimeo: {
            playerOptions: {
              autoplay: true,
              portrait: false,
              title: false,
            },
          },
          wistia: {
            options: {
              silentAutoPlay: 'allow',
              playbar: false,
            },
          },
        }}
        onReady={onReady} // Sometimes just onReady isn't enough, so we get *NSync onStart as well
        onStart={getNSync}
        onPlay={onPlayHook}
        onPause={onPauseHook}
        onSeek={onPauseHook} // Fixes an issue with Vimeo where seeking would not sync
        onError={onError}
      />
    </>
  );
};

export default React.memo(SimulatedLivePlayer);
