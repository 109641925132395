import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Box, ButtonSize, HeartIcon, HeartOIcon, IconSize, TertiaryButton, TimesCircleIcon, } from '@lifechurch/react-ion';
import Imgix from 'react-imgix';
import { DismissButton, DownloadButton, HeartIconWrapper, ImageWrapper, LikeButton, LikeCount, MetaButtonWrapper, Modal, } from './imageModalStyles';
const FALLBACK_IMAGE = 'https://chop.imgix.net/static/placeholders/image_moment_empty_glyph.png';
export const ImageModal = ({ altText, downloadButtonLabel, handleModalClose, hasLiked, image, likeCount = 0, onLike, onShare, open, shareButtonLabel, }) => {
    const { colors } = useContext(ThemeContext);
    if (!open)
        return null;
    const digits = likeCount.toString().length;
    let likeCountSize = 32;
    if (digits > 1) {
        likeCountSize = 10 * digits + 12;
    }
    const handleShare = () => {
        handleModalClose();
        onShare();
    };
    const closeModal = e => {
        if (e.key === 'Enter') {
            handleModalClose();
        }
    };
    const downloadUrl = new URL(image);
    downloadUrl.searchParams.append('dl', `${altText}.${image.split('.').pop()}`);
    return (React.createElement(Modal, null,
        React.createElement(DismissButton, { onClick: handleModalClose, onKeyDown: closeModal, "aria-label": 'Close', type: 'button' },
            React.createElement(TimesCircleIcon, { color: colors.gray30, size: IconSize.XL })),
        React.createElement(ImageWrapper, { tabIndex: 0, role: 'img', "aria-label": altText },
            React.createElement(Imgix, { src: image || FALLBACK_IMAGE, htmlAttributes: {
                    style: {
                        height: 'auto',
                        objectFit: 'contain',
                    },
                    alt: altText,
                    title: altText,
                }, imgixParams: {
                    auto: 'format',
                } })),
        React.createElement(Box, null,
            React.createElement(MetaButtonWrapper, null,
                React.createElement(LikeButton, { size: ButtonSize.MD, onClick: onLike, hasLiked: hasLiked, likeCount: likeCount, "data-testid": 'like', "aria-label": 'Like' },
                    React.createElement(HeartIconWrapper, { likeCount: likeCount, hasLiked: hasLiked }, hasLiked ? (React.createElement(HeartIcon, { color: colors.pink.default, size: IconSize.MD })) : (React.createElement(HeartOIcon, { color: colors.white, size: IconSize.MD }))),
                    likeCount > 0 && (React.createElement(LikeCount, { hasLiked: hasLiked, style: { transition: 'min-width 200ms ease-in-out' }, minWidth: likeCountSize, height: 32, fontWeight: 1 }, likeCount))),
                React.createElement(TertiaryButton, { size: ButtonSize.MD, marginBlockStart: 0, marginBlockEnd: 0, marginInlineStart: '8px', marginInlineEnd: 0, onClick: handleShare }, shareButtonLabel),
                React.createElement(DownloadButton, { as: 'a', href: downloadUrl, size: ButtonSize.MD }, downloadButtonLabel)))));
};
