import React from 'react';
import { Dot } from './styles';

type PipPropsType = {
  hasActions: boolean;
};

const Pip = ({ hasActions }: PipPropsType) => <Dot data-testid='pip-dot' hasActions={hasActions} />;

export default Pip;
