import React, { FunctionComponent } from 'react';
import NavHeader from '@features/navHeader';
import NavBar from '@features/navbar';
import SideMenu from '@features/sideMenu';
import Pane from '@features/pane';
import { Container } from './styles';
import { Props } from './index';
import ServiceWindow from './ServiceWindow';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const BaseLayout: FunctionComponent<Props> = ({
  chatIsFocused,
  hasUpcomingServices,
  isOffline,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.SIDE_MENU}
        altComponentName={t('side_menu')}
      >
        <SideMenu />
      </ErrorBoundary>
      <Container chatIsFocused={chatIsFocused} id={'publicchat'}>
        <NavHeader />
        {!hasUpcomingServices && isOffline ? (
          <ServiceWindow />
        ) : (
          <>
            <ServiceWindow />
            <ErrorBoundary>
              <NavBar />
            </ErrorBoundary>
            <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE}>
              <Pane />
            </ErrorBoundary>
          </>
        )}
      </Container>
    </>
  );
};

export default React.memo(BaseLayout);
