import { PublicSubscriber } from '../../types';
import { Pane, PaneType } from '../pane/dux';
import { Channel } from '@store/feedSlice';

export interface NavbarItem {
  type: PaneType;
  label: string;
  isCurrent: boolean;
  hasActions?: boolean;
  hasNewMessages?: boolean;
  hasSubscribersTyping?: boolean;
  otherSubscribersNickname?: Array<string>;
  channelKey?: string;
}
const hasSubscribersTyping = (channel: Channel): boolean => {
  if (channel?.subscribersTyping?.length > 0) {
    return true;
  }
  return false;
};

const hasNewMessages = (channel: Channel): boolean => {
  if (channel?.sawLastMessageAt && channel.objectOrder[channel.objectOrder.length - 1]?.timestamp) {
    return (
      // @ts-expect-error
      channel.sawLastMessageAt < channel.objectOrder[channel.objectOrder.length - 1]?.timestamp
    );
  } else {
    return false;
  }
};

const hasActions = (channel: Channel): boolean => {
  let result = false;
  Object.keys(channel.prayerRequests).forEach(id => {
    // @ts-expect-error
    if (channel.prayerRequests[id].open) {
      result = true;
    }
  });
  Object.keys(channel.privateChannelInvites).map(id => {
    // @ts-expect-error
    if (channel.privateChannelInvites[id].pending) {
      result = true;
    }
  });
  return result;
};

export const createNavbarItem = (
  type: PaneType,
  currentPane: Pane,
  hostChannel?: Channel,
  navbarItem?: Partial<NavbarItem>
): NavbarItem => ({
  type,
  label: type.toLowerCase(),
  isCurrent: currentPane.type === type,
  hasNewMessages: type === PaneType.HOST_CHAT && hostChannel && hasNewMessages(hostChannel),
  hasActions: type === PaneType.HOST_CHAT && hostChannel && hasActions(hostChannel),
  hasSubscribersTyping: hostChannel && hasSubscribersTyping(hostChannel),
  ...navbarItem,
});

export const createNavbarServiceItem = (currentPane: Pane, label: string): NavbarItem => ({
  type: PaneType.SERVICE,
  label,
  isCurrent: currentPane.type === PaneType.SERVICE,
  hasNewMessages: false,
  hasActions: false,
});

const getOtherSubscriberNames = (
  channel: Channel,
  currentSubscriber: PublicSubscriber
): Array<string> => {
  const subscribers = channel?.subscribers
    ?.filter(subscriber => subscriber?.id !== currentSubscriber?.id && subscriber?.id !== '')
    .map(subscriber => subscriber.nickname || '');
  return subscribers && subscribers.length > 0 ? subscribers : ['?'];
};

export const createNavbarChatItem = (
  channel: Channel,
  currentPane: Pane,
  currentSubscriber: PublicSubscriber
): NavbarItem => ({
  type: PaneType.CHAT,
  channelKey: channel.key,
  label: PaneType.CHAT.toLowerCase(),
  isCurrent:
    currentPane.type === PaneType.CHAT ? currentPane.meta.channelKey === channel.key : false,
  hasNewMessages: hasNewMessages(channel),
  hasSubscribersTyping: hasSubscribersTyping(channel),
  otherSubscribersNickname: getOtherSubscriberNames(channel, currentSubscriber),
});
