import Pubnub, {
  HistoryMessage,
  MessageEvent,
  PresenceEvent,
  PubnubConfig,
  PubnubStatus,
  StatusEvent,
  SignalEvent,
} from 'pubnub';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { AnyAction, Dispatch } from 'redux';
import {
  loadHistory as loadHistoryAction,
  onObject as onObjectAction,
  onSignal as onSignalAction,
} from '../pubnub/dux';
import { PublicChannel as Channel } from '../types';
import {
  onPresence as onPresenceAction,
  setConnectionStatus as setConnectionStatusAction,
} from '@store/feedSlice';
import { RefetchQueryType, setRequestedRefetches } from '@store/uiSlice';
import { MomentSubmission } from '@features/feed/objects/moment/dux';
import { LiveObject } from '../pubnub/liveObject';

let _instance: Pubnub | null = null;
let _dispatch: Dispatch | null = null;

const onObject = (message: MessageEvent): void => {
  if (_dispatch) {
    _dispatch(onObjectAction(message, false));
  }
};

const onPresence = (message: PresenceEvent): void => {
  if (_dispatch) {
    _dispatch(onPresenceAction(message));
  }
};

const onSignal = (message: SignalEvent): void => {
  if (_dispatch) {
    _dispatch(onSignalAction(message));

    if (message.message === 'graphql::AudienceTab') {
      _dispatch(
        setRequestedRefetches({
          query: RefetchQueryType.AUDIENCE_TAB,
          requestedAt: new Date().toISOString(),
        })
      );
    }
  }
};

const reportToSentry = (message: string, status: PubnubStatus) => {
  Sentry.withScope(scope => {
    scope.setExtra('data', {
      category: status.category,
      operation: status.operation,
      statusCode: status.statusCode,
      errorData: JSON.stringify(status.errorData),
    });
    if (status.operation) {
      scope.setTag('PubNub Operation', status.operation);
    }
    if (status.category) {
      scope.setTag('PubNub Category', status.category);
    }
    Sentry.captureMessage(message, Severity.Error);
  });
};

const onStatus = (status: StatusEvent): void => {
  const statuses = [
    Pubnub.CATEGORIES.PNNetworkIssuesCategory,
    Pubnub.CATEGORIES.PNAccessDeniedCategory,
    Pubnub.CATEGORIES.PNTimeoutCategory,
    Pubnub.CATEGORIES.PNUnknownCategory,
  ];

  if (status.affectedChannels) {
    if (_dispatch) {
      _dispatch(
        setConnectionStatusAction({
          connectionStatus: status.category as keyof Pubnub.Categories,
          statusChangedAt: new Date().toISOString(),
          channelKeys: status.affectedChannels,
        })
      );
    }
  } else if (
    status.category === Pubnub.CATEGORIES.PNNetworkDownCategory ||
    status.category === Pubnub.CATEGORIES.PNNetworkUpCategory
  ) {
    if (_dispatch) {
      _dispatch(
        setConnectionStatusAction({
          connectionStatus: status.category as keyof Pubnub.Categories,
          statusChangedAt: new Date().toISOString(),
          allChannels: true,
          channelKeys: [],
        })
      );
    }
  }

  if (statuses.includes(status.category)) {
    Sentry.withScope(scope => {
      scope.setExtra('data', {
        category: status.category,
        operation: status.operation,
        affectedChannels: JSON.stringify(status.affectedChannels || []),
        subscribedChannels: status.subscribedChannels,
        affectedChannelGroups: status.affectedChannelGroups,
      });
      if (status.operation) {
        scope.setTag('PubNub Operation', status.operation);
      }
      if (status.category) {
        scope.setTag('PubNub Category', status.category);
      }
      Sentry.captureMessage('PubNub error onStatus.', Severity.Error);
    });
  }
};

const loadHistory = (channel: Channel, messages: Array<HistoryMessage>): void => {
  if (_dispatch) {
    _dispatch(loadHistoryAction({ channel, messages }));
  }
};

const PubnubClient = {
  config: (config: PubnubConfig) => (_instance = new Pubnub(config)),
  configured: () => _instance !== null,
  setDispatch: (dispatch: Dispatch) => (_dispatch = dispatch),
  dispatch: (action: AnyAction) => _dispatch && _dispatch(action),
  signal: async (channelKey: string, message: string): Promise<void> => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    if (!channelKey || !message) {
      return;
    }

    await _instance.signal({
      channel: channelKey,
      message,
    });
  },
  publish: (object: LiveObject) => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }
    _instance.publish({
      message: object,
      channel: object.channel.key,
      meta: {
        applicationDomain: window.location.host,
      },
    });
  },
  subscribe: (channelKeys: Array<string>): void => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    if (channelKeys.length === 0) {
      return;
    }

    _instance.subscribe({
      channels: channelKeys,
      withPresence: true,
    });
  },
  subscribeToGroups: (channelGroupKeys: Array<string>): void => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    if (channelGroupKeys.length === 0) {
      return;
    }

    _instance.subscribe({
      channelGroups: channelGroupKeys,
    });
  },
  unsubscribe: (params: Pubnub.UnsubscribeParameters): void => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }
    _instance.unsubscribe(params);
  },
  addListener: () => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }
    _instance.addListener({
      message: onObject,
      presence: onPresence,
      signal: onSignal,
      status: onStatus,
    });
  },
  listChannels: (
    channelGroupKey: string,
    callback: (status: Pubnub.PubnubStatus, response: Pubnub.ListChannelsResponse) => void
  ) => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    _instance.channelGroups.listChannels({ channelGroup: channelGroupKey }, callback);
  },
  history: (
    channel: Channel,
    loadingLimit = 100,
    updateFeedState = true
  ): Promise<{ status: Pubnub.PubnubStatus; response: Pubnub.HistoryResponse }> => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    Sentry.addBreadcrumb({
      category: 'LOAD_HISTORY',
      message: `Loading history for Channel Key: ${channel.key}`,
      data: {
        id: channel.id,
        key: channel.key,
        type: channel.type,
      },
    });

    return new Promise(resolve => {
      _instance?.history(
        {
          channel: channel.key,
          count: loadingLimit,
        },
        (status, response) => {
          if (!status?.error) {
            if (updateFeedState && channel) {
              loadHistory(channel, response.messages);
            }
            resolve({ status, response });
          } else {
            reportToSentry('PubNub Error with history.', status);
            if (_dispatch) {
              _dispatch(
                setConnectionStatusAction({
                  connectionStatus: status.category as keyof Pubnub.Categories,
                  statusChangedAt: new Date().toISOString(),
                  channelKeys: [channel.key],
                })
              );
            }
            resolve({ status, response });
          }
        }
      );
    });
  },
  fetchMessages: (
    channels: string[],
    loadingLimit = 1
  ): Promise<{ status: Pubnub.PubnubStatus; response: Pubnub.FetchMessagesResponse }> => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }

    return new Promise(resolve => {
      _instance?.fetchMessages(
        {
          channels,
          count: loadingLimit,
        },
        (status, response) => {
          resolve({ status, response });
        }
      );
    });
  },
  fire: (channel: string, message: MomentSubmission) => {
    if (!_instance) {
      throw new Error(
        'Pubnub Client has not been initialized, please call PubnubClient.config() first.'
      );
    }
    _instance.fire(
      {
        channel,
        message,
      },
      status => {
        if (status.error) {
          reportToSentry('Pubnub Error with fire.', status);
        }
      }
    );
  },
};

Object.freeze(PubnubClient);
export default PubnubClient;
