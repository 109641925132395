import styled from 'styled-components';

export interface PaginationButtonProps {
  /**
   * Controls whether the Pagination Button should be highlighted, indicating the current page.
   * @type boolean
   */
  active?: boolean;
  /**
   * Controls the appearance of the Pagination button.
   */
  pill?: boolean;
  activeTextColor?: string;
  activeBackgroundColor?: string;
}

export const PaginationButton = styled.a<PaginationButtonProps>`
  display: inline-block;
  background-color: ${props =>
    props.active ? props.activeBackgroundColor || props.theme.colors.gray80 : 'transparent'};
  color: ${props =>
    props.active ? props.activeTextColor || props.theme.colors.white : props.theme.colors.gray50};
  border-radius: 16px;
  line-height: 32px;
  min-width: 32px;
  font-size: ${props => props.theme.fontSizes[2]};
  text-align: center;
  padding: ${props => (props.pill ? `0 ${props.theme.space[5]}px` : 0)};

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: ${props => props.theme.colors.action.light};
    color: ${props => props.theme.colors.action.hover};
  }
`;
