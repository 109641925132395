import React, { FunctionComponent } from 'react';
import { Icon } from './styles';
import { AvatarSizeType } from './types';
import AvatarOverlay from './AvatarOverlay';

interface Props {
  isActionable: boolean;
  size: AvatarSizeType;
  tabIndex: number;
}

const GuestAvatar: FunctionComponent<Props> = ({ isActionable, size, tabIndex }) => (
  <>
    {isActionable && <AvatarOverlay size={size} />}
    <Icon
      data-testid={`avatar-guest`}
      isActionable={isActionable}
      role='img'
      size={size}
      style={{ backgroundColor: 'gray' }}
      tabIndex={tabIndex}
    >
      <svg
        width={size * 0.8}
        height={size * 0.8}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity='0.5'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.31999 15.761V14.44C2.31999 12.7832 3.66314 11.44 5.31999 11.44H14.68C16.3368 11.44 17.68 12.7832 17.68 14.44V15.761C15.9285 18.0922 13.1403 19.6 10 19.6C6.85964 19.6 4.07147 18.0922 2.31999 15.761ZM13.84 5.68003C13.84 7.8008 12.1208 9.52003 9.99999 9.52003C7.87922 9.52003 6.15999 7.8008 6.15999 5.68003C6.15999 3.55925 7.87922 1.84003 9.99999 1.84003C12.1208 1.84003 13.84 3.55925 13.84 5.68003Z'
            fill='white'
          />
        </g>
      </svg>
    </Icon>
  </>
);

export default React.memo(GuestAvatar);
