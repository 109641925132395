import React from 'react';
import CTAMoment from '../cta';
import { Flex, OParagraph, PrimaryButton, SecondaryButton, ButtonSize, } from '@lifechurch/react-ion';
const GivingMoment = ({ buttonText = 'Give', isPinned = false, onClick, onDismiss, tabIndex = 0, text, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(CTAMoment, { onDismiss: onDismiss, isPinned: isPinned, ariaLabel: text, fuse: true },
            React.createElement(Flex, { flexDirection: 'column', width: '100%', alignItems: 'center' },
                React.createElement(OParagraph, { marginBlockEnd: 3, textAlign: 'center', p: '0 16px' }, text),
                isPinned ? (React.createElement(PrimaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick, tabIndex: tabIndex }, buttonText)) : (React.createElement(SecondaryButton, { size: ButtonSize.SM, width: 'fit-content', marginBlockEnd: 2, onClick: onClick, tabIndex: tabIndex }, buttonText))))));
};
export { GivingMoment };
