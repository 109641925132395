import { createSelector } from 'reselect';
import { RootState } from '@store/rootReducer';
import { SequenceState, StepData, StepWithData } from './';

const ID = 'sequence';
const local = (state: RootState) => state[ID];

const getSteps = createSelector<RootState, SequenceState, Array<StepWithData>>(
  local,
  sequence => sequence.steps
);

export const getServerTime = createSelector<RootState, SequenceState, number>(
  local,
  sequence => sequence.serverTime
);

export const getNextStep = createSelector<RootState, Array<StepWithData>, StepWithData>(
  getSteps,
  steps => steps && steps[0]
);

export const getNextFetchTime = createSelector<RootState, Array<StepWithData>, string>(
  getSteps,
  steps => steps?.[0]?.fetchTime || null
);

export const getNextTransitionTime = createSelector<RootState, Array<StepWithData>, string | null>(
  getSteps,
  steps => (steps && steps[0] ? steps[0].transitionTime : null)
);

export const hasSequence = createSelector<RootState, Array<StepWithData>, boolean>(
  getSteps,
  steps => steps.length > 0
);

export const getNextData = createSelector<RootState, StepWithData, StepData | null>(
  getNextStep,
  step => step?.data || null
);
