import styled, { keyframes } from 'styled-components';
const pulse = keyframes `
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 200px rgba(0, 0, 0, 0); 
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;
export const Pulse = styled.div `
  position: absolute;
  background-color: ${({ theme }) => theme.colors.whiteTransparent20};
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 1px;
  animation: ${pulse} 1500ms ease-out;
`;
