import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrayerRequest from './prayerRequest';
import { hasPermissions } from '@store/subscriberSlice/selectors';
import { FeedObjectMetaData } from '../dux';
import { getPrayerRequestById } from '@store/feedSlice/channelSelectors'; // TO DO
import { RootState } from '@store/rootReducer';
import { PrayerRequest as PrayerRequestInterface, acceptLivePrayer } from '@store/feedSlice';

interface StateProps {
  prayerRequest?: PrayerRequestInterface;
  acceptPrayerPermissions: boolean;
}

interface OwnProps {
  channel: string;
  metaData: FeedObjectMetaData;
  isCompact: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channel, metaData } = ownProps;
  return {
    prayerRequest: getPrayerRequestById(state, channel, metaData.id),
    acceptPrayerPermissions: hasPermissions(state, ['feed.prayer.accept']),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  acceptLivePrayer: (prayerRequest: PrayerRequestInterface) =>
    dispatch(acceptLivePrayer(prayerRequest)),
});

const VisiblePrayerRequest = connect(mapStateToProps, mapDispatchToProps)(PrayerRequest);

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisiblePrayerRequest;
