import React, { FC, PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from './Flex';
import { Box } from './Box';

type RadioGroupProps = PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

interface RadioGroupItemProps {
  name?: string;
  isLoading?: boolean;
  disabled?: boolean;
  checked?: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

type RadioGroupItemStyleProps = Omit<RadioGroupItemProps, 'name' | 'value' | 'onChange'>;

const RadioGroup = ({ children, ...rest }: RadioGroupProps): ReactElement => {
  return (
    <Flex flexDirection='column' {...rest}>
      {children}
    </Flex>
  );
};

const RadioGroupItem: FC<PropsWithChildren<RadioGroupItemProps>> = ({
  name,
  isLoading,
  disabled,
  checked,
  value,
  onChange,
  children,
  ...rest
}) => {
  return (
    <Container
      bg='gray5'
      marginBottom='3px'
      disabled={disabled}
      isLoading={isLoading}
      checked={checked}
      {...rest}
    >
      <Flex alignItems='center'>
        <RadioButton
          type='radio'
          id={name}
          name={name}
          value={value}
          disabled={isLoading || disabled}
          onChange={onChange}
          checked={checked}
        />
        <StyledLabel htmlFor={name}>{children}</StyledLabel>
      </Flex>
    </Container>
  );
};

const Container = styled(Box)<RadioGroupItemStyleProps & { checked?: boolean }>`
  background-color: ${props =>
    props.checked && !props.isLoading && props.theme.colors.action.light};
  position: relative;
  &:hover {
    background-color: ${props =>
      (!props.isLoading || !props.disabled) && props.theme.colors.action.light};
  }

  ${props =>
    (props.isLoading || props.disabled) &&
    css`
      cursor: not-allowed;
      &:before {
        background: ${props => props.theme.colors.gray5};
        opacity: 0.5;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}
`;

const StyledLabel = styled.label`
  width: 100%;
`;

const RadioButton = styled.input`
  margin-inline-start: 16px;
  margin-inline-end: 16px;
`;

export { RadioGroup, RadioGroupProps, RadioGroupItemProps };

RadioGroup.Item = RadioGroupItem;
