import queries from '../queries';
import * as Sentry from '@sentry/browser';
import { call, put } from 'redux-saga/effects';
import { DeleteMessage } from '@features/feed/objects/message/dux';
import {
  muteSubscriber as muteSubscriberAction,
  unmuteSubscriber as unmuteSubscriberAction,
} from '@store/subscriberSlice';
import {
  muteSubscriber as muteSubscriberMetric,
  unmuteSubscriber as unmuteSubscriberMetric,
  deleteMessage as deleteMessageMetric,
} from './metrics';
import { MessageInput } from '../../../__generated__/globalTypes';
import { FetchResult } from '@apollo/client';
import { MuteSubscriberMutation } from '../__generated__/MuteSubscriberMutation';
import { DeleteMessageMutation } from '../__generated__/DeleteMessageMutation';
import { unmutedAlert } from '@components/Alert/dux';
import { UnmuteSubscriberMutation } from '../__generated__/UnmuteSubscriberMutation';

function* muteSubscriber(action: ReturnType<typeof muteSubscriberAction>) {
  const { canceled, onSuccess, subscriberId } = action.payload;
  yield call(muteSubscriberMetric, subscriberId, canceled);
  try {
    if (!canceled) {
      const result: FetchResult<MuteSubscriberMutation> = yield call(
        [queries, queries.muteSubscriber],
        subscriberId
      );
      if (!result.data?.muteSubscriber.success) {
        Sentry.captureMessage('Server returned false for muteSubscriber');
      } else {
        onSuccess();
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* unmuteSubscriber(action: ReturnType<typeof unmuteSubscriberAction>) {
  const { subscriberId, subscriberName, onSuccess } = action.payload;
  yield call(unmuteSubscriberMetric, subscriberId);
  try {
    const result: FetchResult<UnmuteSubscriberMutation> = yield call(
      [queries, queries.unmuteSubscriber],
      subscriberId
    );

    if (!result.data?.unmuteSubscriber.success) {
      Sentry.captureMessage('Server returned false for unmuteSubscriber');
    } else {
      // since the unmute functionality on the backend is async, this timeout is added to ensure
      // that the mutation is completed before requesting the updated subscriber
      setTimeout(() => onSuccess(), 2000);
      yield put(unmutedAlert(subscriberName));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* deleteMessage(action: DeleteMessage) {
  const { payload } = action;
  const input: MessageInput = {
    id: payload.id,
    channel: {
      id: payload.channel.id,
      key: payload.channel.key,
      name: payload.channel.name,
      type: payload.channel.type,
      direct: payload.channel.direct,
    },
    lang: payload.lang,
    text: payload.text,
    translations: payload.translations || [],
    deleted: payload.deleted,
    subscriberId: payload.subscriber.id,
    timestamp: payload.timestamp,
  };
  try {
    const result: FetchResult<DeleteMessageMutation> = yield call(
      [queries, queries.deleteMessage],
      input
    );
    yield call(deleteMessageMetric, input.id, input.subscriberId, input.channel.type);
    if (!result.data?.deleteMessage.success) {
      Sentry.captureException(result.data?.deleteMessage.errors[0]);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export { muteSubscriber, deleteMessage, unmuteSubscriber };
