import React from 'react';

const TextSizeSmall = () => (
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.7968 12L7.658 4.00934H5.90892L2.77016 12H4.3635L4.95052 10.4666H8.6164L9.20342 12H10.7968ZM8.22106 9.23262H5.34586L6.78346 5.42298L8.22106 9.23262ZM16.4256 12V8.1664C16.4256 6.609 15.2995 6.0699 14.0176 6.0699C13.0951 6.0699 12.2565 6.3694 11.5857 7.00434L12.1008 7.87888C12.604 7.3877 13.167 7.1481 13.8139 7.1481C14.6166 7.1481 15.1677 7.55542 15.1677 8.2263V9.08886C14.7484 8.59768 14.0775 8.3461 13.2988 8.3461C12.3524 8.3461 11.2862 8.89718 11.2862 10.227C11.2862 11.5088 12.3524 12.1438 13.2988 12.1438C14.0655 12.1438 14.7364 11.8682 15.1677 11.377V12H16.4256ZM13.79 11.2812C13.0832 11.2812 12.556 10.8739 12.556 10.2509C12.556 9.61598 13.0832 9.20866 13.79 9.20866C14.3411 9.20866 14.8802 9.41232 15.1677 9.80766V10.6822C14.8802 11.0775 14.3411 11.2812 13.79 11.2812Z'
      fill='#9F9FA0'
    />
  </svg>
);

export default TextSizeSmall;
