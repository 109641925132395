import { connect } from 'react-redux';
import { requestPrayer, selectChatName } from '../modal/dux';
import { getCurrentSubscriberAsPublicSubscriber } from '@store/subscriberSlice/selectors';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';
import PrayerButton from './PrayerButton';
import { RootState } from '@store/rootReducer';
import { PublicSubscriber as Subscriber } from '../../types';
import { isLivePrayerEnabled, isOffline, shouldShowVideo } from '@store/serviceSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { setModal } from '@store/uiSlice';
import { SelectChatNameSubtype } from '../modal/selectChatName/dux';
import { requestLivePrayer } from '@store/feedSlice';
import { Dispatch } from 'redux';

interface StateProps {
  currentSubscriber: Subscriber;
  isLive: boolean;
  hasOfflinePrayerRecipients: boolean;
  isLivePrayerEnabled: boolean;
  isGuest: boolean;
}

interface OwnProps {
  inChat?: boolean;
  large?: boolean;
  momentId?: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const offline = isOffline(state);
  const livePrayerEnabled = isLivePrayerEnabled(state);
  const showingVideo = shouldShowVideo(state);
  const hasOfflinePrayerRecipients =
    getCurrentOrganization(state).offlinePrayerRecipients.length > 0;

  return {
    isGuest: !isHostViewingAsHost(state),
    currentSubscriber: getCurrentSubscriberAsPublicSubscriber(state),
    isLivePrayerEnabled: livePrayerEnabled,
    isLive: !offline && showingVideo,
    hasOfflinePrayerRecipients,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNickname: (momentId?: string) =>
    dispatch(setModal(selectChatName(SelectChatNameSubtype.PRAYER, { momentId }))),
  requestLivePrayer: ({ momentId }: { momentId?: string }) =>
    dispatch(requestLivePrayer({ momentId })),
  requestOfflinePrayer: () => dispatch(setModal(requestPrayer())),
});

const VisibleLivePrayerButton = connect(mapStateToProps, mapDispatchToProps)(PrayerButton);

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleLivePrayerButton;
