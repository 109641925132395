import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleRightIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M9.04293 7.50004L4.89648 3.35359L5.60359 2.64648L10.4571 7.50004L5.60359 12.3536L4.89648 11.6465L9.04293 7.50004Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M7.70718 3.29297L14.4143 10.0001L7.70718 16.7072L6.29297 15.293L11.5859 10.0001L6.29297 4.70718L7.70718 3.29297Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M8.70718 3.29297L17.4143 12.0001L8.70718 20.7072L7.29297 19.293L14.5859 12.0001L7.29297 4.70718L8.70718 3.29297Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M11.7072 4.29297L23.4143 16.0001L11.7072 27.7072L10.293 26.293L20.5859 16.0001L10.293 5.70718L11.7072 4.29297Z'
          size={size}
          {...rest}
        />
      );
  }
};
