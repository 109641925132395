import React, { FunctionComponent, useContext } from 'react';
import {
  IconButton,
  InnerWrapper,
  NavMenuAdminLink,
  NavMenuBody,
  NavMenuBodySection,
  NavMenuChurchName,
  NavMenuFooter,
  NavMenuHeader,
  NavMenuIconWrapper,
  Wrapper,
} from './styles';
import Actionable from '@components/Actionable';
import { LargeDown, MediumDown, MediumUp } from '@utils/responsive';
import { useTranslation } from 'react-i18next';
import { Props } from './index';
import { PaneType } from '../pane/dux';
import { ThemeContext } from 'styled-components';
import { RoleType } from '../../../__generated__/globalTypes';
import {
  CalendarIcon,
  EyeIcon,
  InfoCircleIcon,
  OpenBookIcon,
  StarIcon,
  CommentStarIcon,
  CommentIcon,
  BarsIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  TextDocumentIcon,
} from '@lifechurch/react-ion';
import NavMenuItem from '@features/navMenu/navMenuItem';

interface NavMenuButtonProps {
  onClick: () => void;
  isOpen: boolean;
  expanded: boolean;
}

const NavMenuHamburger: FunctionComponent<NavMenuButtonProps> = ({ onClick, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Actionable onClick={isOpen ? () => {} : onClick}>
      <IconButton aria-label={t('aria.menu')} aria-haspopup={true}>
        <BarsIcon />
      </IconButton>
    </Actionable>
  );
};

interface NavMenuArrowButtonProps {
  onClick: () => void;
  expanded: boolean;
}

const NavMenuMinifyArrow: FunctionComponent<NavMenuArrowButtonProps> = ({ onClick, expanded }) => {
  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <IconButton
      onClick={onClick}
      aria-label={expanded ? t('aria.collapse_sidebar') : t('aria.expand_sidebar')}
    >
      {expanded &&
        (isRTL ? (
          <ArrowRightIcon color={theme.colors.gray50} />
        ) : (
          <ArrowLeftIcon color={theme.colors.gray50} />
        ))}
      {!expanded &&
        (isRTL ? (
          <ArrowLeftIcon color={theme.colors.gray50} />
        ) : (
          <ArrowRightIcon color={theme.colors.gray50} />
        ))}
    </IconButton>
  );
};

const NavMenu: FunctionComponent<Props> = ({
  organizationName,
  directChannels,
  hostChannel,
  isOpen,
  currentPane,
  expanded,
  openMenu,
  toggleExpanded,
  setPaneToChat,
  setPaneToType,
  isChatEnabled,
  currentSubscriber,
}) => {
  const { t } = useTranslation();
  const hasAdminLinkPermissions = currentSubscriber.role
    ? [RoleType.ADMIN, RoleType.OWNER, RoleType.GLOBAL].includes(currentSubscriber.role.type)
    : false;

  return (
    <Wrapper expanded={expanded}>
      <InnerWrapper expanded={expanded}>
        <NavMenuHeader data-testid='navMenu-Header'>
          <NavMenuIconWrapper expanded={expanded} data-testid='navMenu-Hamburger'>
            <NavMenuHamburger onClick={openMenu} expanded={expanded} isOpen={isOpen} />
          </NavMenuIconWrapper>
          {expanded && <NavMenuChurchName>{organizationName}</NavMenuChurchName>}
        </NavMenuHeader>
        {expanded && hasAdminLinkPermissions && (
          <NavMenuAdminLink href={`${window.location.origin}/admin`}>
            {t('go_to_admin')}
          </NavMenuAdminLink>
        )}
        <NavMenuBody>
          {directChannels.length > 0 && (
            <NavMenuBodySection data-testid='navMenu-direct'>
              {directChannels.map(channel => (
                <NavMenuItem
                  Icon={CommentIcon}
                  key={channel.channelKey}
                  direct={true}
                  expanded={expanded}
                  selected={channel.isCurrent}
                  text={channel.otherSubscribersNickname?.join(', ') || ''}
                  hasActions={channel.hasActions}
                  hasNewMessages={channel.hasNewMessages}
                  hasSubscribersTyping={channel.hasSubscribersTyping}
                  onClick={() => channel && setPaneToChat(channel.channelKey || '')}
                  numberOfSubscribers={
                    channel.otherSubscribersNickname && channel.otherSubscribersNickname?.length > 1
                      ? channel.otherSubscribersNickname.length
                      : undefined
                  }
                />
              ))}
            </NavMenuBodySection>
          )}
          <NavMenuBodySection data-testid='navMenu-links'>
            {!hostChannel && (
              <MediumUp>
                <NavMenuItem
                  Icon={CommentIcon}
                  text={t(isChatEnabled ? 'public_chat' : 'feed')}
                  expanded={expanded}
                  selected={currentPane.type === PaneType.SERVICE}
                  onClick={() => setPaneToType(PaneType.SERVICE)}
                />
              </MediumUp>
            )}
            {hostChannel && (
              <>
                <MediumDown>
                  <NavMenuItem
                    Icon={CommentIcon}
                    text={t(isChatEnabled ? 'public_chat' : 'feed')}
                    expanded={expanded}
                    selected={currentPane.type === PaneType.SERVICE}
                    onClick={() => setPaneToType(PaneType.SERVICE)}
                  />
                </MediumDown>
                <LargeDown>
                  <NavMenuItem
                    Icon={CommentStarIcon}
                    text={t('host_chat')}
                    expanded={expanded}
                    selected={hostChannel.isCurrent}
                    showPip={hostChannel.hasActions || hostChannel.hasNewMessages}
                    hasActions={hostChannel.hasActions}
                    hasNewMessages={hostChannel.hasNewMessages}
                    hasSubscribersTyping={hostChannel.hasSubscribersTyping}
                    onClick={() => setPaneToType(PaneType.HOST_CHAT)}
                  />
                </LargeDown>
                <NavMenuItem
                  Icon={EyeIcon}
                  text={t('audience.title')}
                  expanded={expanded}
                  selected={currentPane.type === PaneType.AUDIENCE}
                  onClick={() => setPaneToType(PaneType.AUDIENCE)}
                />
                <NavMenuItem
                  Icon={InfoCircleIcon}
                  text={t('host_info')}
                  expanded={expanded}
                  selected={currentPane.type === PaneType.HOST_INFO}
                  onClick={() => setPaneToType(PaneType.HOST_INFO)}
                />
                <NavMenuItem
                  Icon={StarIcon}
                  text={t('moments')}
                  expanded={expanded}
                  selected={currentPane.type === PaneType.MOMENTS}
                  onClick={() => setPaneToType(PaneType.MOMENTS)}
                />
              </>
            )}
            <NavMenuItem
              Icon={TextDocumentIcon}
              text={t('notes')}
              expanded={expanded}
              selected={currentPane.type === PaneType.NOTES}
              onClick={() => setPaneToType(PaneType.NOTES)}
            />
            <NavMenuItem
              Icon={OpenBookIcon}
              text={t('bible')}
              expanded={expanded}
              selected={currentPane.type === PaneType.BIBLE}
              onClick={() => setPaneToType(PaneType.BIBLE)}
            />
            <NavMenuItem
              Icon={CalendarIcon}
              text={t('schedule')}
              expanded={expanded}
              selected={currentPane.type === PaneType.SCHEDULE}
              onClick={() => setPaneToType(PaneType.SCHEDULE)}
            />
          </NavMenuBodySection>
        </NavMenuBody>
        <NavMenuFooter data-testid='nav-menu-footer'>
          <NavMenuIconWrapper expanded={expanded}>
            <NavMenuMinifyArrow onClick={toggleExpanded} expanded={expanded} />
          </NavMenuIconWrapper>
        </NavMenuFooter>
      </InnerWrapper>
    </Wrapper>
  );
};

export default React.memo(NavMenu);
