import { RevertSubscriberInput } from '../../../../__generated__/globalTypes';
import { ActionWithPayload } from '../../../types';
import { ModalType } from '../dux';

export const REVERT_EMAIL_CHANGE = 'REVERT_EMAIL_CHANGE';

export interface RevertChangeEmail {
  type: ModalType.REVERT_EMAIL_CHANGE;
}

export const revertChangeEmail = (): RevertChangeEmail => ({
  type: ModalType.REVERT_EMAIL_CHANGE,
});

export type PublishRevertChangeEmail = ActionWithPayload<
  typeof REVERT_EMAIL_CHANGE,
  RevertSubscriberInput
>;

export const publishRevertChangeEmail = (
  input: Omit<RevertSubscriberInput, 'input'>
): PublishRevertChangeEmail => ({
  type: REVERT_EMAIL_CHANGE,
  payload: {
    ...input,
  },
});
