import RequestPrayer from './requestPrayer';
import { connect } from 'react-redux';
import { cancelRequestPrayer, publishRequestPrayer } from './dux';
import { Subscriber } from '../../../types';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { FormikErrors, withFormik } from 'formik';
import { validEmail } from '@utils/core';
import { RootState } from '@store/rootReducer';

const EnhancedForm = withFormik<Props, State>({
  mapPropsToValues: ({ currentSubscriber: { firstName, lastName, email } }) => ({
    name: firstName ? `${firstName} ${lastName}`.trim() : '',
    email: email || '',
    message: '',
  }),
  validate: values => {
    const errors: FormikErrors<State> = {};
    if (values.name.length === 0) {
      errors.name = 'request_prayer.blank_name';
    }
    if (values.email.length === 0) {
      errors.email = 'request_prayer.blank_email';
    } else if (!validEmail(values.email)) {
      errors.email = 'request_prayer.invalid_email';
    }
    if (values.message.length === 0) {
      errors.message = 'request_prayer.blank_message';
    }
    return errors;
  },
  handleSubmit: (values, { props: { publishRequestPrayer } }) => {
    publishRequestPrayer(values);
  },
  displayName: 'RequestPrayer',
  validateOnChange: false,
})(RequestPrayer);

interface StateProps {
  currentSubscriber: Subscriber;
}

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): OwnProps & StateProps => ({
  currentSubscriber: getCurrentSubscriber(state),
  ...ownProps,
});

interface DispatchProps {
  publishRequestPrayer: typeof publishRequestPrayer;
  cancelRequestPrayer: typeof cancelRequestPrayer;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ publishRequestPrayer, cancelRequestPrayer }, dispatch);

const VisibleRequestPrayer = connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);

export type Props = OwnProps & StateProps & DispatchProps;
export default VisibleRequestPrayer;
export interface State {
  name: string;
  email: string;
  message: string;
}
