import { RootState } from '@store/rootReducer';
import { createSelector } from 'reselect';
import { currentServiceHasVideo, getCurrentService, isOnline } from '@store/serviceSlice/selectors';
import { ServiceState } from '@store/serviceSlice';
import { hasServiceBeenFetched } from '@store/uiSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { CurrentState_currentOrganization } from '@io/__generated__/CurrentState';
import { VideoState } from '@store/videoSlice/index';
import dayjs from 'dayjs';

const ID = 'video';
const local = (state: RootState) => state[ID];

const getVideo = (state: RootState): VideoState => local(state);

const videoStartTime = createSelector<RootState, ServiceState, string | undefined>(
  getCurrentService,
  service => service?.content.videoStartTime
);

const showLiveVideo = createSelector<
  RootState,
  VideoState,
  boolean,
  boolean,
  boolean,
  string | undefined,
  boolean
>(
  [local, hasServiceBeenFetched, isOnline, currentServiceHasVideo, videoStartTime],
  (video, serviceFetched, online, serviceHasVideo, videoStartTime) => {
    if (serviceHasVideo) {
      return serviceFetched && online && !!video.url;
    }
    // No Video State
    return serviceFetched && online && !!videoStartTime && dayjs().isAfter(videoStartTime);
  }
);

const getVideoUrl = createSelector<
  RootState,
  VideoState,
  boolean,
  CurrentState_currentOrganization,
  string | null
>([local, showLiveVideo, getCurrentOrganization], (video, isLiveVideo, organization) => {
  if (isLiveVideo) {
    return video.url ? transformUrl(video.url) : null;
  }
  // offlineContent.url can return video or image url
  return organization?.offlineContent?.url ?? null;
});

export const transformUrl = (url: string): string => {
  const urlYoutube =
    /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
  const urlVimeo = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  const urlWistia = /https?:\/\/(?:[\w-]+\.)?wistia\.com\/(?:[\w-]+\/)+(\w+)/;
  if (url.match(urlYoutube)) {
    url = url.replace(urlYoutube, '//www.youtube.com/embed/$1');
  } else if (url.match(urlVimeo)) {
    url = url.replace(urlVimeo, '//player.vimeo.com/video/$2');
  } else if (url.match(urlWistia)) {
    url = url.replace(urlWistia, '//fast.wistia.com/medias/$1');
  }
  return url;
};

export { getVideo, videoStartTime, showLiveVideo, getVideoUrl };
