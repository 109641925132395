import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  overflow,
  OverflowProps,
  compose,
  system,
} from 'styled-system';
import { logical, LogicalProps } from './logical';
import * as CSS from 'csstype';

export interface TextTransformProps {
  textTransform?: CSS.Property.TextTransform;
}

const textTransform = system({
  textTransform: true,
});

export interface CursorProps {
  cursor?: string;
}

const cursor = system({
  cursor: true,
});

const BoxStyles = compose(
  space,
  layout,
  typography,
  color,
  flexbox,
  textTransform,
  border,
  position,
  shadow,
  cursor,
  overflow,
  logical
);

export type BoxProps = SpaceProps &
  ColorProps &
  FlexboxProps &
  LayoutProps &
  TypographyProps &
  BorderProps &
  PositionProps &
  TextTransformProps &
  ShadowProps &
  CursorProps &
  OverflowProps &
  LogicalProps &
  React.HTMLAttributes<HTMLDivElement> & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  };

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${BoxStyles}
`;
