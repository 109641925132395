import React, { FC } from 'react';
import { Container, Message } from './style';
import { defaultColors, OParagraph } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import { Link } from '@features/serviceInfo/serviceMeta/styles';
import Imgix from 'react-imgix';
import { IMAGE_PREFIX } from '../../../../constants';

const Error: FC = () => {
  const { t } = useTranslation();
  return (
    <Container data-testid='error'>
      <Message>
        <Imgix
          src={`${IMAGE_PREFIX}/static/web-client/component_error_block.png`}
          width={64}
          height={64}
        />
        <OParagraph color={defaultColors.gray30} marginBlockEnd={4}>
          {t('something_went_wrong')}
        </OParagraph>
        <OParagraph color={defaultColors.gray10} marginBlockEnd={4}>
          <Link
            style={{ color: defaultColors.action.default }}
            onClick={() => window.location.reload()}
            href={''}
            rel='noopener noreferrer'
          >
            {t('refresh')}
          </Link>
        </OParagraph>
      </Message>
    </Container>
  );
};

export default Error;
