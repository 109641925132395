import VisibleConnectMoment from './connectMoment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ConnectMoment as ConnectMomentInterface } from '../dux';
import { submitMomentAction } from '@store/feedSlice';
import { setModal } from '@store/uiSlice';

interface OwnProps {
  moment: ConnectMomentInterface;
  onDismiss: () => void;
  isPinned: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setModal, submitMomentAction }, dispatch);

export type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;
export default connect(null, mapDispatchToProps)(VisibleConnectMoment);
