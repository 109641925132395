import React from 'react';

const TextSizeLarge = () => (
  <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.9963 13L7.07153 3.00834H4.88445L0.959688 13H2.95203L3.68605 11.0826H8.26993L9.00395 13H10.9963ZM7.77559 9.53962H4.18039L5.97799 4.77598L7.77559 9.53962ZM18.0347 13V8.2064C18.0347 6.259 16.6265 5.5849 15.0237 5.5849C13.8702 5.5849 12.8216 5.9594 11.9827 6.75334L12.6269 7.84688C13.256 7.2327 13.9601 6.9331 14.769 6.9331C15.7727 6.9331 16.4618 7.44242 16.4618 8.2813V9.35986C15.9375 8.74568 15.0986 8.4311 14.1249 8.4311C12.9415 8.4311 11.6082 9.12018 11.6082 10.783C11.6082 12.3858 12.9415 13.1798 14.1249 13.1798C15.0836 13.1798 15.9225 12.8352 16.4618 12.221V13H18.0347ZM14.7391 12.1012C13.8552 12.1012 13.1961 11.5919 13.1961 10.8129C13.1961 10.019 13.8552 9.50966 14.7391 9.50966C15.4281 9.50966 16.1022 9.76432 16.4618 10.2587V11.3522C16.1022 11.8465 15.4281 12.1012 14.7391 12.1012Z'
      fill='#9F9FA0'
    />
  </svg>
);

export default TextSizeLarge;
