import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getInset } from './logical';

interface Element {
  text: string;
  id: string;
}

interface TabbarProps {
  /**
   * An array of objects {TabName: TabId}
   */
  elements: Element[];
  /**
   * The tab that is currently active
   */
  activeId: string;
  /**
   * The color of the active tab indicator
   */
  activeColor: string;
  /**
   * A callback function that is called when a user clicks on a tab
   */
  onClick: (elementId: string) => void;
}

interface ActiveTabProps {
  color: string;
}

const TabbarWrapper = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes[3]};
  line-height: ${props => props.theme.lineHeights[2]}rem;
  text-transform: uppercase;
  div:first-child {
    padding-inline-start: 0;
  }
`;

const ActiveTab = styled.div<ActiveTabProps>`
  font-weight: ${props => props.theme.fontWeights[2]};
  padding-block-start: 0;
  padding-block-end: 3px;
  padding-inline-start: ${props => props.theme.space[4]}px;
  padding-inline-end: ${props => props.theme.space[4]}px;
  cursor: pointer;
  color: ${props => props.theme.colors.gray100};
  position: relative;
  &:after {
    content: '';
    height: 2px;
    width: 100%;
    background: ${props => props.color};
    display: block;
    position: relative;
    ${getInset('insetBlockStart', '3px')}
  }
  &:focus,
  &:hover {
    outline: none;
  }
`;

const InactiveTab = styled.div`
  font-weight: ${props => props.theme.fontWeights[2]};
  padding-block-start: 0;
  padding-block-end: 3px;
  padding-inline-start: ${props => props.theme.space[4]}px;
  padding-inline-end: ${props => props.theme.space[4]}px;
  cursor: pointer;
  color: ${props => props.theme.colors.gray50};
  &:focus,
  &:hover {
    color: ${props => props.theme.colors.gray100};
    outline: none;
  }
`;
/**
 * The Tabbar component displays a horizontal row of tabs
 *
 *
 * ###When To Use
 *
 * - Allow users to quickly switch among different sections of the app
 *
 */
const Tabbar: FunctionComponent<TabbarProps> = ({
  elements,
  activeId,
  activeColor,
  onClick,
}: TabbarProps) => {
  return (
    <TabbarWrapper>
      {elements.map(element =>
        element.id === activeId ? (
          <ActiveTab
            tabIndex={0}
            key={element.id}
            aria-label={element.text}
            onClick={() => onClick(element.id)}
            color={activeColor}
          >
            {element.text}
          </ActiveTab>
        ) : (
          <InactiveTab
            tabIndex={0}
            key={element.id}
            aria-label={element.text}
            onClick={() => onClick(element.id)}
          >
            {element.text}
          </InactiveTab>
        )
      )}
    </TabbarWrapper>
  );
};

export { Tabbar };
