import styled, { css } from 'styled-components';
import { SecondaryButton } from '@lifechurch/react-ion';
export const LikePinnedButton = styled(SecondaryButton) `
  border-radius: 16px;
  cursor: default;
  padding: 0;
  height: 32px;

  ${props => props.hasLiked &&
    css `
      background-color: white;
      border: 1px solid white;
      pointer-events: none;
    `};
`;
