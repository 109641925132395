export type HubSpotEvent = Array<string | Record<string, unknown>>;

let initialized = false;
const init = (trackingCodeId: string): void => {
  if (!initialized) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `//js.hs-scripts.com/${trackingCodeId}.js`;
    document.head.appendChild(script);
    initialized = true;
  }
};

const internalHsq = () => {
  if (!window._hsq) {
    console.warn('HubSpot Tracking Script has not been loaded yet.');
  }
  return (window._hsq = window._hsq || []);
};

const pageView = (path: string): void => {
  internalHsq().push(['setPath', path]);
};

const identify = (user: Record<string, unknown>): void => {
  internalHsq().push(['identify', user]);
};

const setTrackConsent = (track: boolean): void => {
  internalHsq().push(['doNotTrack', { track }]);
};

const trackEvent = (id: string, value?: number): void => {
  internalHsq().push([
    'trackEvent',
    {
      id,
      value,
    },
  ]);
};

export default {
  init,
  pageView,
  identify,
  setTrackConsent,
  trackEvent,
};
