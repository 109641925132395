import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleRightCircle: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM9.85355 7.14645L6.35355 3.64645L5.64645 4.35355L8.79289 7.5L5.64645 10.6464L6.35355 11.3536L9.85355 7.85355L10.2071 7.5L9.85355 7.14645Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM12.7071 9.29289L8.70711 5.29289L7.29289 6.70711L10.5858 10L7.29289 13.2929L8.70711 14.7071L12.7071 10.7071L13.4142 10L12.7071 9.29289Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.7071 11.2929L10.7071 6.29289L9.29289 7.70711L13.5858 12L9.29289 16.2929L10.7071 17.7071L15.7071 12.7071L16.4142 12L15.7071 11.2929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM21.2071 15.2929L13.7071 7.79289L12.2929 9.20711L19.0858 16L12.2929 22.7929L13.7071 24.2071L21.2071 16.7071L21.9142 16L21.2071 15.2929Z'
          size={size}
          {...rest}
        />
      );
  }
};
