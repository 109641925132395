import VisibleInviteMoment from './inviteMoment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { InviteMoment as InviteMomentInterface } from '../dux';
import { submitMomentAction } from '@store/feedSlice';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';
import { setModal } from '@store/uiSlice';

interface StateProps {
  organizationName: string;
}

interface OwnProps {
  moment: InviteMomentInterface;
  onDismiss: () => void;
  isPinned: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => ({
  organizationName: getCurrentOrganization(state).name,
  ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setModal, submitMomentAction }, dispatch);

export type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisibleInviteMoment);
