import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { getPublicChannelOccupancy } from '@store/feedSlice/channelSelectors';
import AdditionalGuests from '@features/audience/components/additionalGuests/additionalGuests';

interface OwnProps {
  totalCount: number;
}

interface StateProps {
  count?: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const channelOccupancy = getPublicChannelOccupancy(state);

  return {
    count: channelOccupancy - ownProps.totalCount,
  };
};

const VisibleAdditionalGuests = connect(mapStateToProps)(AdditionalGuests);

export type Props = StateProps & OwnProps;
export default VisibleAdditionalGuests;
