import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const LinkIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M12.1213 13.5356L8.58578 17.0711C7.02368 18.6332 4.49102 18.6332 2.92892 17.0711C1.36683 15.509 1.36682 12.9763 2.92892 11.4142L6.46445 7.87872L7.87867 9.29293L4.34313 12.8285C3.56209 13.6095 3.56209 14.8758 4.34314 15.6569C5.12418 16.4379 6.39051 16.4379 7.17156 15.6569L10.7071 12.1214L12.1213 13.5356ZM7.87867 6.4645L11.4142 2.92897C12.9763 1.36687 15.509 1.36687 17.0711 2.92897C18.6332 4.49106 18.6332 7.02372 17.0711 8.58582L13.5355 12.1214L12.1213 10.7071L15.6568 7.17161C16.4379 6.39056 16.4379 5.12423 15.6568 4.34318C14.8758 3.56213 13.6095 3.56213 12.8284 4.34318L9.29288 7.87871L7.87867 6.4645ZM12.3222 6.26349L6.26354 12.3222L7.67776 13.7364L13.7364 7.6777L12.3222 6.26349Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M3.56497 21.9498C1.61235 23.9024 1.61235 27.0682 3.56497 29.0208L4.97918 30.435C6.9318 32.3877 10.0976 32.3877 12.0502 30.435L16.2929 26.1924C17.7427 24.7426 18.116 22.624 17.4129 20.8297L15.7527 22.4899C15.7983 23.3123 15.5069 24.1499 14.8787 24.7782L10.636 29.0208C9.46446 30.1924 7.56497 30.1924 6.3934 29.0208L4.97918 27.6066C3.80761 26.435 3.80761 24.5355 4.97918 23.364L9.22182 19.1213C9.85009 18.4931 10.6877 18.2017 11.5101 18.2473L13.1703 16.5871C11.376 15.884 9.2574 16.2573 7.80761 17.7071L3.56497 21.9498ZM26.1924 16.2929C24.7426 17.7427 22.624 18.116 20.8297 17.4129L22.4899 15.7527C23.3123 15.7983 24.1499 15.507 24.7782 14.8787L29.0208 10.636C30.1924 9.46448 30.1924 7.56498 29.0208 6.39341L27.6066 4.97919C26.435 3.80762 24.5355 3.80762 23.364 4.97919L19.1213 9.22183C18.4931 9.8501 18.2017 10.6877 18.2473 11.5101L16.5871 13.1703C15.884 11.376 16.2573 9.25741 17.7071 7.80762L21.9497 3.56498C23.9024 1.61236 27.0682 1.61236 29.0208 3.56498L30.435 4.97919C32.3876 6.93182 32.3876 10.0976 30.435 12.0503L26.1924 16.2929ZM21.2426 11.3432L11.3431 21.2426L12.7574 22.6569L22.6569 12.7574L21.2426 11.3432Z'
          size={size}
          {...rest}
        />
      );
  }
};
