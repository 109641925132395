import { connect } from 'react-redux';
import { Subscriber } from '../../../types';
import { Dispatch } from 'redux';
import { saveSubscriber, SaveSubscriberArgs } from '@store/subscriberSlice';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { CurrentState_currentOrganization } from '@io/__generated__/CurrentState';
import { FormikErrors, withFormik } from 'formik';
import { validEmail } from '@utils/core';
import { v4 } from 'uuid';
import AcceptInvite from './acceptInvite';
import { AcceptInviteMeta } from './dux';
import { EmailConsentType } from '../../../../__generated__/globalTypes';
import { RootState } from '@store/rootReducer';

export interface State {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  nickname: string;
  emailConsent: boolean;
  acceptTermsAndPrivacy: boolean;
  organization: CurrentState_currentOrganization;
  currentSubscriber: Subscriber;
  closeModal: () => void;
}

const ConnectedAcceptInvite = withFormik<Props, State>({
  mapPropsToErrors: ({ meta }) => {
    const errors: FormikErrors<State> = {};

    if (meta?.emailUnavailable) {
      errors.email = 'accept_invite.unavailable_email';
    }

    if (meta?.nicknameUnavailable) {
      errors.nickname = 'accept_invite.unavailable_nickname';
    }
    return errors;
  },
  mapPropsToValues: ({ currentSubscriber, organization, closeModal }) => ({
    email: currentSubscriber.email ?? '',
    password: '',
    firstName: '',
    lastName: '',
    avatar: currentSubscriber.avatar || '',
    nickname: currentSubscriber.nickname || '',
    emailConsent: !(
      organization.emailConsent === EmailConsentType.ALL ||
      (currentSubscriber.gdpr && organization.emailConsent === EmailConsentType.EU)
    ),
    acceptTermsAndPrivacy: false,
    organization,
    currentSubscriber,
    closeModal,
  }),
  enableReinitialize: true,
  validateOnChange: false,
  validate: values => {
    const errors: FormikErrors<State> = {};

    if (values.email === '') {
      errors.email = 'accept_invite.blank_email';
    } else if (!validEmail(values.email)) {
      errors.email = 'accept_invite.invalid_email';
    }

    if (values.nickname === '') {
      errors.nickname = 'accept_invite.unavailable_nickname';
    } else if (values.nickname.trim().length < 3) {
      errors.nickname = 'accept_invite.short_nickname';
    } else if (values.nickname.trim().length > 30) {
      errors.nickname = 'accept_invite.long_nickname';
    }

    if (values.password.length < 8) {
      errors.password = 'accept_invite.password_short';
    }

    if (
      (values.organization.termsUrl || values.organization.privacyUrl) &&
      !values.acceptTermsAndPrivacy
    ) {
      errors.acceptTermsAndPrivacy = 'accept_invite.must_accept_tos_pp';
    }

    return errors;
  },
  handleSubmit: async (values, { props }) => {
    props.saveSubscriber({
      id: props.currentSubscriber.id,
      input: {
        id: props.currentSubscriber.id,
        nickname: values.nickname.trim(),
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        avatar: values.avatar || props.currentSubscriber.avatar,
        emailConsent: values.emailConsent,
      },
      signUpId: v4(),
      signUpSource: 'accept_invite',
    });
  },
})(AcceptInvite);

interface StateProps {
  currentSubscriber: Subscriber;
  organization: CurrentState_currentOrganization;
}

interface OwnProps {
  closeModal: () => void;
  meta?: AcceptInviteMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  return {
    currentSubscriber: state.subscriber.currentSubscriber,
    organization: getCurrentOrganization(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSubscriber: (payload: SaveSubscriberArgs) =>
    dispatch(saveSubscriber(payload, { acceptedInvite: true })),
});

export type Props = StateProps & ReturnType<typeof mapDispatchToProps> & OwnProps;
const VisibleAcceptInvite = connect(mapStateToProps, mapDispatchToProps)(ConnectedAcceptInvite);

export default VisibleAcceptInvite;
