import React, { FunctionComponent } from 'react';
import { ImageModal, getImageShareUrl } from '@churchonline/moments';
import { Props } from './index';
import { MomentActionType, MomentType } from '../../../../__generated__/globalTypes';
import { imageMomentShareSheet } from '../imageMomentShareSheet/dux';
import { useTranslation } from 'react-i18next';
import { webShareAPI } from '@features/feed/objects/moment/imageMoment/imageMoment';
import { isMacOS } from '@utils/core';

const Modal: FunctionComponent<Props> = ({
  clearModal,
  hasLiked,
  submitMomentAction,
  moment,
  serviceTitle,
  setModal,
}) => {
  const { t } = useTranslation('moments');
  const { imageUrl, altText, shareText } = moment.momentTemplate.options;

  const shouldUseNativeShareSheet = typeof navigator?.share !== 'undefined' && !isMacOS();

  const handleLike = (): void => {
    if (!hasLiked) {
      submitMomentAction({
        channelKey: moment.channel.key,
        momentInstanceId: moment.id,
        momentType: MomentType.IMAGE,
        momentActionType: MomentActionType.LIKE,
      });
    }
  };

  const handleShare = (): void => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.IMAGE,
      momentActionType: MomentActionType.SHARE,
    });

    if (shouldUseNativeShareSheet) {
      webShareAPI({
        title: serviceTitle,
        text: shareText,
        url: getImageShareUrl(imageUrl, serviceTitle, shareText),
      });
    } else {
      setModal(imageMomentShareSheet(altText, imageUrl, shareText, serviceTitle));
    }
  };

  return (
    <ImageModal
      altText={altText}
      downloadButtonLabel={t('Download')}
      handleModalClose={clearModal}
      hasLiked={hasLiked}
      image={imageUrl}
      likeCount={moment.likes}
      onLike={handleLike}
      onShare={handleShare}
      open
      shareButtonLabel={t('Share')}
    />
  );
};

export default Modal;
