import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowRightIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M8.35355 3.14648L12.7071 7.50004L8.35355 11.8536L7.64645 11.1465L10.7929 8.00004H3V7.00004H10.7929L7.64645 3.85359L8.35355 3.14648Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M11.7071 3.29297L18.4142 10.0001L11.7071 16.7072L10.2929 15.293L14.5858 11.0001H2V9.00008H14.5858L10.2929 4.70718L11.7071 3.29297Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M13.7071 3.29297L22.4142 12.0001L13.7071 20.7072L12.2929 19.293L18.5858 13.0001H2V11.0001H18.5858L12.2929 4.70718L13.7071 3.29297Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M19.7071 5.29297L30.4142 16.0001L19.7071 26.7072L18.2929 25.293L26.5858 17.0001H2V15.0001H26.5858L18.2929 6.70718L19.7071 5.29297Z'
          size={size}
          {...rest}
        />
      );
  }
};
