import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconProps } from './index';

const StyledCircle = styled.circle`
  fill: #9f9fa0;
  transform-origin: 50% 50%;
  animation: ball-beat 1.1s 0s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:nth-child(2) {
    animation-delay: 0.3s !important;
  }
  &:nth-child(3) {
    animation-delay: 0.6s !important;
  }

  @keyframes ball-beat {
    0% {
      opacity: 0.7;
    }
    33.33% {
      opacity: 0.4;
    }
    66.67% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TypingIndicatorIcoon: FunctionComponent<IconProps> = () => {
  return (
    <svg
      id='typing_bubble'
      data-name='typing bubble'
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='7'
      viewBox='0 0 15 7'
    >
      <g>
        <StyledCircle cx='2' cy='3.5' r='2' />
        <StyledCircle cx='7.5' cy='3.5' r='2' />
        <StyledCircle cx='13' cy='3.5' r='2' />
      </g>
    </svg>
  );
};

export default React.memo(TypingIndicatorIcoon);
