import React, { useEffect, useState } from 'react';
import { ResponseMoment } from '../../response';
import { Preview } from '../index';
import { Box } from '@lifechurch/react-ion';
import styled from 'styled-components';
const defaultText = 'Your text goes here.';
const defaultButtonText = 'Button Text';
const defaultSymbol = {
    id: 'hand',
    name: 'Raised Hand',
    unified: '',
    native: '✋',
};
const Wrapper = styled(Box) `
  opacity: ${props => (props.isPlaceholder ? 0.5 : 1.0)};
`;
export const ResponseMomentPreview = ({ text, buttonText, symbol, count: initialCount = 0, }) => {
    const [isPinned, setIsPinned] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [count, setCount] = useState(initialCount);
    const handleResponse = () => {
        setCount(count + 1);
        setHasSubmitted(true);
    };
    useEffect(() => {
        setCount(initialCount);
    }, [initialCount]);
    return (React.createElement(Preview, null,
        React.createElement(Wrapper, { marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0, p: isPinned ? 0 : 3, paddingBlockStart: 0, isPlaceholder: !text && !buttonText },
            React.createElement(ResponseMoment, { text: text || defaultText, buttonText: buttonText || defaultButtonText, isPinned: isPinned, onDismiss: () => setIsPinned(false), onResponse: handleResponse, symbol: symbol || defaultSymbol, hasSubmitted: hasSubmitted, count: count }))));
};
