import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrivateChannelInvite from './privateChannelInvite';
import { FeedObjectMetaData } from '../dux';
import { getPrivateChannelInviteById } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';
import { PrivateChannelInvite as PrivateChannelInviteInterface } from './dux';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { acceptInviteToChannel } from '@store/feedSlice';

interface StateProps {
  privateChannelInvite?: PrivateChannelInviteInterface;
  alreadyInChat: boolean;
}

interface OwnProps {
  channel: string;
  metaData: FeedObjectMetaData;
  isCompact: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channel, metaData } = ownProps;
  const privateChannelInvite = getPrivateChannelInviteById(state, channel, metaData.id);
  const subscribers =
    privateChannelInvite && 'subscribers' in privateChannelInvite.channel
      ? privateChannelInvite.channel.subscribers
      : [];
  return {
    privateChannelInvite,
    alreadyInChat:
      subscribers?.some(subscriber => subscriber.id === getCurrentSubscriber(state).id) || false,
    ...ownProps,
  };
};

interface DispatchProps {
  acceptInvite: (id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  acceptInvite: (id: string) => dispatch(acceptInviteToChannel(id)),
});

const VisiblePrivateChannelInvite = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateChannelInvite);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisiblePrivateChannelInvite;
