import createDOMPurify from 'dompurify';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { TFunction } from 'i18next';
import { post } from '@io/API';
import { ENV } from '../../constants';

dayjs.extend(utc);

const isObject = (input: unknown): input is Record<string, any> => typeof input === 'object';

const getFirstInitial = (name: string): string => name.charAt(0).toUpperCase();

const validEmail = (email: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const isValidNickname = (nickname: string): boolean =>
  nickname !== '' && nickname.trim().length >= 3 && nickname.trim().length <= 30;

function avatarImageExists(subscriberId: string): Promise<boolean> {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };
    image.src = `https://chop-v3-media.s3.amazonaws.com/users/avatars/${subscriberId}/thumb/photo.jpg`;
  });
}

function localizedTimeReference(timestamp: string, t: TFunction): string | null {
  if (!timestamp) return null;

  const timeDiff = dayjs().diff(dayjs(timestamp));

  const minMs = 60000;
  const hourMs = 60000 * 59;
  const dayMs = 60000 * 60 * 23;

  if (timeDiff < minMs) {
    return t('time.now');
  } else if (timeDiff < hourMs) {
    return `${Math.round(timeDiff / minMs)}${t('time.minuteAbbr')}`;
  } else if (timeDiff < dayMs) {
    return `${Math.round(timeDiff / hourMs)}${t('time.hourAbbr')}`;
  }
  return `${Math.round(timeDiff / dayMs)}${t('time.dayAbbr')}`;
}

const avatarColors = [
  '41,147,229',
  '76,178,57',
  '255,144,0',
  '118,74,255',
  '9,193,161',
  '255,186,12',
  '197,210,26',
  '255,96,47',
  '233,65,124',
  '165,61,235',
  '58,200,115',
  '235,61,218',
  '233,214,47',
  '152,208,31',
];

const djb2Hash = (str: string): number => {
  let hash = 5381;
  const charList = Array.from(str);
  charList.forEach(char => {
    const charCode = char.charCodeAt(0);
    hash = (hash << 5) + hash + charCode;
  });
  return hash;
};

const getAvatarColor = (
  nickname: string,
  opacity?: number,
  pending?: boolean,
  groupChat?: boolean
) => {
  const op = opacity === undefined ? '1.0' : opacity;
  if (pending || groupChat) {
    return `rgba(64, 64, 65, ${op})`;
  } else {
    const numberDigest = djb2Hash(nickname);
    const numberOfColors = avatarColors.length;
    const index = Math.abs(numberDigest % numberOfColors);
    const color = avatarColors[index];
    return `rgba(${color}, ${op})`;
  }
};

const isEmpty = (string: string) => !string || string.length === 0;

const DOMPurify = createDOMPurify();

const sanitizeConfig = {
  ALLOWED_TAGS: ['a'],
  ALLOWED_ATTR: ['href'],
};

interface SanitizeVariables {
  (
    string: string,
    config?: {
      ALLOWED_TAGS?: string[];
      ALLOWED_ATTR?: string[];
      ADD_ATTR?: string[];
      ADD_TAGS?: string[];
    }
  ): string;
}

const sanitizeString: SanitizeVariables = (string: string, config = sanitizeConfig) =>
  DOMPurify.sanitize(string, config);

// Default ISO8601 Format
const getMessageTimestampFormat = (timestamp: string = dayjs().format()) => {
  const date = dayjs(timestamp);
  return dayjs().isSame(date, 'day') ? 'LT' : 'LT, MMM D';
};

const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

const isAndroid = () =>
  !!navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('android') > -1;

const isChromeForIPad = () =>
  navigator.userAgent.match(/CriOS/gm) !== null && navigator.userAgent.match(/iPad/gm) !== null;

const isMacOS = () => navigator.userAgent.match(/Macintosh/gm) !== null;

const isClient = typeof window === 'object';

export const getOrCreateElement = (id: string): HTMLElement => {
  let element = document.getElementById(id);
  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', id);
    if (document.body) {
      document.body.appendChild(element);
    }
  }
  return element;
};

export const getDefaultFontSize = (): number =>
  Number(
    window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)?.[0] || 16
  );

export const isDevelopment = () => ENV === 'development';
export const isProduction = () => ENV === 'production';

export const deleteAllCookies = async (): Promise<void> => {
  Cookies.remove('legacy_token');
  await post('/auth/logout');
};

export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};

export async function sha256(message: string) {
  // encode as UTF-8 and has the message
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert bytes to hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const snakeCase = (string: string): string => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toUpperCase())
    .join('_');
};

export {
  isObject,
  getFirstInitial,
  getAvatarColor,
  avatarImageExists,
  isEmpty,
  sanitizeString,
  getMessageTimestampFormat,
  isMobileDevice,
  isAndroid,
  isClient,
  validEmail,
  isValidNickname,
  capitalize,
  isChromeForIPad,
  isMacOS,
  snakeCase,
  localizedTimeReference,
};
