import React, { FunctionComponent, useEffect } from 'react';
import { PaneContentWrapper, PaneWrapper } from './styles';
import { MediumPlusUpQuery, MediumUp, Small, XlargeUpQuery } from '@utils/responsive';
import { isEmpty } from '@utils/core';
import { PaneType } from './dux';
import { Props } from './index';
import { useMediaQuery } from 'react-responsive';
import PaneContent from '@features/pane/paneContent';

const Pane: FunctionComponent<Props> = ({
  currentChannelId,
  hostChannel,
  isGuest,
  pane,
  publicChannelId,
  sendPaneViewMetricEvent,
  setPaneToType,
}) => {
  const isMediaPlusUp = useMediaQuery({ query: MediumPlusUpQuery });
  const isXlargeUp = useMediaQuery({ query: XlargeUpQuery });

  // Prevent two of the same panes
  useEffect(() => {
    if (
      [PaneType.AUDIENCE, PaneType.HOST_CHAT, PaneType.MOMENTS, PaneType.HOST_INFO].includes(
        pane.type
      ) &&
      isGuest
    ) {
      setPaneToType(PaneType.SERVICE);
    } else if (
      isMediaPlusUp &&
      pane.type === PaneType.SERVICE &&
      !isEmpty(hostChannel) &&
      !isGuest
    ) {
      setPaneToType(PaneType.HOST_CHAT);
    } else if (
      !isGuest &&
      isXlargeUp &&
      (pane.type === PaneType.SERVICE || pane.type === PaneType.HOST_CHAT)
    ) {
      setPaneToType(PaneType.AUDIENCE);
    }
  });

  const getChannelId = (paneType: PaneType) => {
    switch (paneType) {
      case PaneType.SERVICE:
        return publicChannelId;
      case PaneType.HOST_CHAT:
        return hostChannel;
      default:
        return currentChannelId;
    }
  };

  useEffect(() => {
    const channelId = getChannelId(pane.type) || '';
    sendPaneViewMetricEvent(1, pane.type, channelId);
  }, [pane]);

  return (
    <PaneWrapper data-testid='pane' isGuest={isGuest}>
      <Small>
        <PaneContentWrapper>
          <PaneContent pane={pane} />
        </PaneContentWrapper>
      </Small>
      <MediumUp>
        <PaneContentWrapper isGuest={isGuest}>
          <PaneContent pane={pane} />
        </PaneContentWrapper>
      </MediumUp>
    </PaneWrapper>
  );
};

export default React.memo(Pane);
