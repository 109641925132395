import {
  Grid,
  GridItem,
  Modal,
  ModalSize,
  OHeaderThree,
  OParagraph,
  PrimaryButton,
} from '@lifechurch/react-ion';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';

const SsoValueProp: FunctionComponent<Props> = ({ externalLoginUrl, closeModal }: Props) => {
  const { t } = useTranslation(['forms', 'common']);

  const onSignUpClick = () => {
    window.location.href = externalLoginUrl ?? '';
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='ssoValueProp-modal'>
      <OHeaderThree marginBlockEnd={2}>{t('forms:sso_value_prop.title')}</OHeaderThree>
      <OParagraph marginBlockEnd={6}>{t('forms:sso_value_prop.description')}</OParagraph>
      <Grid width='100%'>
        <GridItem justifySelf='end'>
          <PrimaryButton onClick={onSignUpClick}>{t('common:sign_up')}</PrimaryButton>
        </GridItem>
      </Grid>
    </Modal>
  );
};

export default SsoValueProp;
