import React, { FC } from 'react';
import AcceptInvite from './acceptInvite';
import ChangePassword from './changePassword';
import DeleteAccount from './deleteAccount';
import DeleteMoment from './deleteMoment/deleteMoment';
import { ModalType } from './dux';
import IframeModal from './iframe';
import ImageMomentModal from './imageMomentModal';
import ImageMomentShareSheet from './imageMomentShareSheet';
import { Props } from './index';
import InviteMomentShareSheet from './inviteMomentShareSheet';
import LeaveChat from './leaveChat';
import LogIn from './login';
import MuteSubscriber from './muteSubscriber';
import ProfileSettings from './profileSettings';
import QuoteMomentShareSheet from './quoteMomentShareSheet';
import RemoveAvatar from './removeAvatar';
import RequestPasswordReset from './requestPasswordReset';
import RequestPrayer from './requestPrayer';
import ResetPassword from './resetPassword';
import RevertChangeEmail from './revertChangeEmail';
import SelectChatName from './selectChatName';
import Signup from './signup';
import SsoValueProp from './ssoValueProp';

const Modal: FC<Props> = ({ modal, closeModal }) => {
  if (modal) {
    switch (modal.type) {
      case ModalType.LEAVE_CHAT:
        return <LeaveChat closeModal={closeModal} />;
      case ModalType.DELETE_MOMENT:
        return <DeleteMoment moment={modal.payload} />;
      case ModalType.MUTE_SUBSCRIBER:
        return <MuteSubscriber closeModal={closeModal} meta={modal.meta} />;
      case ModalType.PROFILE_SETTINGS:
        return <ProfileSettings closeModal={closeModal} />;
      case ModalType.SELECT_CHAT_NAME:
        return <SelectChatName closeModal={closeModal} subtype={modal.subtype} meta={modal.meta} />;
      case ModalType.LOGIN:
        return <LogIn closeModal={closeModal} meta={modal.meta} />;
      case ModalType.SIGNUP:
        return (
          <Signup closeModal={closeModal} signUpSource={modal.signUpSource} meta={modal.meta} />
        );
      case ModalType.RESET_PASSWORD:
        return <ResetPassword closeModal={closeModal} />;
      case ModalType.ACCEPT_INVITE:
        return <AcceptInvite closeModal={closeModal} meta={modal.meta} />;
      case ModalType.REQUEST_PASSWORD_RESET:
        return <RequestPasswordReset closeModal={closeModal} />;
      case ModalType.REVERT_EMAIL_CHANGE:
        return <RevertChangeEmail closeModal={closeModal} />;
      case ModalType.REMOVE_AVATAR:
        return <RemoveAvatar closeModal={closeModal} />;
      case ModalType.DELETE_ACCOUNT:
        return <DeleteAccount closeModal={closeModal} />;
      case ModalType.REQUEST_PRAYER:
        return <RequestPrayer closeModal={closeModal} />;
      case ModalType.CHANGE_PASSWORD:
        return <ChangePassword closeModal={closeModal} meta={modal.meta} />;
      case ModalType.IFRAME:
        return <IframeModal closeModal={closeModal} src={modal.src} />;
      case ModalType.QUOTE_MOMENT_SHARE_SHEET:
        return (
          <QuoteMomentShareSheet
            text={modal.text}
            attribution={modal.attribution}
            closeModal={closeModal}
          />
        );
      case ModalType.INVITE_MOMENT_SHARE_SHEET:
        return <InviteMomentShareSheet closeModal={closeModal} />;
      case ModalType.IMAGE_MOMENT_SHARE_SHEET:
        return (
          <ImageMomentShareSheet
            altText={modal.altText}
            imageUrl={modal.imageUrl}
            shareText={modal.shareText}
            closeModal={closeModal}
            title={modal.title}
          />
        );
      case ModalType.IMAGE_MOMENT:
        return <ImageMomentModal moment={modal.meta.moment} />;
      case ModalType.SSO_VALUE_PROP:
        return <SsoValueProp closeModal={closeModal} />;
      default:
        return null;
    }
  }
  return null;
};

export default Modal;
