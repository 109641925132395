import React, { useRef, FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import Actionable from '@components/Actionable';
import TabOverflowIcon from '@components/icons/tabOverflow';
import NavbarPip from './navbarPip';
import NavbarItem from './navbarItem';
import {
  InvertedTabOverflowWrapper,
  Navbar as NavbarStyle,
  NavbarWrapper,
  TabOverflow,
  TabOverflowWrapper,
} from './styles';
import { useScroll } from '@hooks/useScroll';
import { Props } from './index';
import { PaneType } from '../pane/dux';
import { useTranslation } from 'react-i18next';
import { NavbarItem as INavbarItem } from './selectors';
import {
  MediumQuery,
  MediumPlusQuery,
  XlargeQuery,
  XXlargeQuery,
  LargeUpQuery,
} from '@utils/responsive';
import { useMediaQuery } from 'react-responsive';
import { emptyNickname } from './directChatIcon';

const Navbar: FunctionComponent<Props> = ({
  items,
  navbarIndex,
  setPaneToChat,
  setPaneToType,
  setNavbarIndex,
  mediumGuest = false,
}) => {
  const { t } = useTranslation();
  const wrapper = useRef<HTMLDivElement>(null);
  const itemWithActions = useRef<HTMLDivElement>(null);
  const itemsHaveActions = items.filter(item => item.hasActions).length > 0;
  const isMedium = useMediaQuery({ query: MediumQuery });
  const isMediumPlus = useMediaQuery({ query: MediumPlusQuery });
  const isLargePlus = useMediaQuery({ query: LargeUpQuery });
  const isXlarge = useMediaQuery({ query: XlargeQuery });
  const isXXlarge = useMediaQuery({ query: XXlargeQuery });
  const [{ showLeftIndicator, showRightIndicator }, setIndicators] = useState({
    showLeftIndicator: false,
    showRightIndicator: false,
  });

  const handleItemClick = (event: MouseEvent<HTMLButtonElement>, item: INavbarItem): void => {
    const { index } = event.currentTarget.dataset;
    if (index) {
      const newIndex = parseInt(index);
      if (navbarIndex !== newIndex) {
        setNavbarIndex(newIndex);
      }
    }

    switch (item.type) {
      case PaneType.CHAT:
        if (item.channelKey) {
          setPaneToChat(item.channelKey);
        }
        break;
      case PaneType.ERROR:
        break;
      default:
        setPaneToType(item.type);
    }
  };

  const { current: scrollArea } = wrapper;
  const { scrollLeft } = useScroll(wrapper);

  // Scroll enough so that the scroll-snap kicks in
  const handleScrollLeft = () => {
    if (scrollArea) {
      scrollArea.scrollLeft = scrollArea.scrollLeft - 50;
    }
  };

  const handleScrollRight = () => {
    if (scrollArea) {
      scrollArea.scrollLeft = scrollArea.scrollLeft + 50;
    }
  };

  const showLeftPip =
    itemWithActions.current && itemWithActions.current.getBoundingClientRect().left < 0;
  const showRightPip =
    itemWithActions.current &&
    itemWithActions.current.getBoundingClientRect().left > window.innerWidth;

  const itemLabel = (item: INavbarItem) => {
    switch (item.type) {
      case PaneType.CHAT:
        return item.otherSubscribersNickname?.toString() || emptyNickname;
      default:
        return t(item.label);
    }
  };

  useEffect(() => {
    let navBarWidth = window.innerWidth;
    if (isXXlarge) {
      navBarWidth = 400;
    } else if (isLargePlus || isXlarge) {
      navBarWidth = 320;
    } else if (isMediumPlus) {
      navBarWidth = 640;
    } else if (isMedium) {
      navBarWidth = 460;
    }

    setIndicators({
      showLeftIndicator: scrollLeft > 0,
      showRightIndicator:
        !!scrollArea &&
        scrollLeft < scrollArea.scrollWidth &&
        scrollArea.scrollWidth - scrollLeft !== navBarWidth,
    });
  }, [
    scrollLeft,
    scrollArea?.scrollWidth,
    isMedium,
    isMediumPlus,
    isLargePlus,
    isXlarge,
    isXXlarge,
  ]);

  return (
    <NavbarWrapper role='tablist'>
      <NavbarStyle ref={wrapper} data-testid='navbar'>
        {showLeftIndicator && (
          <Actionable onClick={handleScrollLeft}>
            <InvertedTabOverflowWrapper>
              <TabOverflow>
                <TabOverflowIcon />
              </TabOverflow>
              {showLeftPip && <NavbarPip hasActions={itemsHaveActions} />}
            </InvertedTabOverflowWrapper>
          </Actionable>
        )}

        {items.map((item, index) => (
          <NavbarItem
            key={index}
            index={index}
            item={item}
            mediumGuest={mediumGuest}
            handleItemClick={event => handleItemClick(event, item)}
          >
            {itemLabel(item)}
          </NavbarItem>
        ))}

        {showRightIndicator && (
          <Actionable onClick={handleScrollRight}>
            <TabOverflowWrapper>
              <TabOverflowIcon />
              {showRightPip && <NavbarPip hasActions={itemsHaveActions} />}
            </TabOverflowWrapper>
          </Actionable>
        )}
      </NavbarStyle>
    </NavbarWrapper>
  );
};

export default React.memo(Navbar);
