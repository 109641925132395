import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentState_currentOrganization } from '@io/__generated__/CurrentState';
import {
  EmailConsentType,
  NavigationLinkOption,
  OrganizationStatus,
} from '../../../__generated__/globalTypes';

export const initialState: CurrentState_currentOrganization = {
  id: '',
  name: '',
  website: '',
  appearance: {
    favicon: '',
    logo: '',
    headerBackgroundColor: '',
    hideViewershipMetric: false,
    headerTextColor: '',
    navigationLinkCase: NavigationLinkOption.TITLE,
  },
  integrations: [],
  links: [],
  termsUrl: null,
  privacyUrl: null,
  emailConsent: EmailConsentType.OFF,
  offlineContent: null,
  offlinePrayerRecipients: [],
  status: OrganizationStatus.PENDING,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization(state, action: PayloadAction<CurrentState_currentOrganization>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setOrganization } = organizationSlice.actions;
export type OrganizationState = ReturnType<typeof organizationSlice.reducer>;
export default organizationSlice.reducer;
