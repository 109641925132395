import { defaultColors, OParagraph } from '@lifechurch/react-ion';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Imgix from 'react-imgix';
import { IMAGE_PREFIX } from '../../../../constants';
import { NoAudience } from './styles';
import { Props } from './index';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const AudienceOffline: FC<Props> = ({ nextService }) => {
  const { t } = useTranslation();
  const isToday = nextService?.startTime && dayjs(nextService?.startTime).isToday();
  const nextServiceStartTime = dayjs(nextService?.startTime).format('LT');

  return (
    <NoAudience data-testid='audience-empty'>
      <Imgix
        src={`${IMAGE_PREFIX}/static/placeholders/audience.png`}
        width={160}
        height={160}
        htmlAttributes={{ alt: t('audience.empty') }}
      />
      {isToday && (
        <OParagraph color={defaultColors.gray50}>
          {t('audience.offline', { time: nextServiceStartTime })}
        </OParagraph>
      )}
    </NoAudience>
  );
};

export default AudienceOffline;
