import React, { FunctionComponent } from 'react';
import { AvatarSizeType } from './types';
import Imgix from 'react-imgix';
import AvatarOverlay from './AvatarOverlay';

interface Props {
  isActionable: boolean;
  nickname?: string;
  size: AvatarSizeType;
  tabIndex: number;
  url: string;
  disabled?: boolean;
}

const ImageAvatar: FunctionComponent<Props> = ({
  disabled,
  isActionable,
  nickname,
  size,
  tabIndex,
  url,
}) => (
  <>
    {isActionable && <AvatarOverlay size={size} />}
    <Imgix
      htmlAttributes={{
        'data-testid': `avatar-${url}`,
        tabIndex: tabIndex,
        role: 'img',
        'aria-label': nickname,
        style: {
          opacity: disabled ? 0.5 : 1,
          flexShrink: 0,
        },
      }}
      imgixParams={{
        auto: 'format',
        mask: 'ellipse',
        fit: 'crop',
        crop: 'faces,entropy',
        w: size,
        h: size,
        sat: disabled && -100,
      }}
      width={size}
      height={size}
      src={url}
    />
  </>
);

export default React.memo(ImageAvatar);
