import React, { FunctionComponent } from 'react';
import { ImageMomentShareSheet as ShareSheet } from '@churchonline/moments';
import { Props } from './index';

const ImageMomentShareSheet: FunctionComponent<Props> = ({
  altText,
  imageUrl,
  shareText,
  closeModal,
  title,
}) => {
  return (
    <ShareSheet
      open={true}
      handleModalClose={closeModal}
      image={imageUrl}
      shareText={shareText}
      altText={altText || ''}
      title={title}
    />
  );
};

export default ImageMomentShareSheet;
