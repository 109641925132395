import styled from 'styled-components';
import { LargeUpQuery } from '@utils/responsive';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const Message = styled.div`
  padding: 43px;
  text-align: center;
  display: flex;
  margin: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: calc(177vh - 347px);
  gap: 5px;

  @media ${LargeUpQuery} {
    flex-direction: 'column';
  }
`;
