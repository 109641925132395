import styled from 'styled-components';

interface ButtonWrapperProps {
  isDropdownOpen: boolean;
}

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  line-height: 0;

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;
