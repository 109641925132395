import React, { PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';

type TableProps = PropsWithChildren<React.HTMLAttributes<HTMLTableElement>>;

const Table = ({ children, ...rest }: TableProps): ReactElement => {
  return (
    <table cellSpacing={0} {...rest}>
      {children}
    </table>
  );
};

const THead = styled.thead`
  text-align: left;
  margin-block-start: 4px;

  th {
    font-weight: ${props => props.theme.fontWeights[2]};
    color: ${props => props.theme.colors.gray100};
    font-size: ${props => props.theme.fontSizes[2]};
    line-height: ${props => props.theme.lineHeights[2]};
    padding-inline-start: 8px;
  }
`;

const TBody = styled.tbody`
  &:before {
    line-height: 0.25;
    content: '\\200C';
    display: block;
  }

  td {
    padding: 4px 8px;
    color: ${props => props.theme.colors.gray80};
    font-size: ${props => props.theme.fontSizes[2]};
    line-height: ${props => props.theme.lineHeights[2]};
  }

  tr {
    td:first-child {
      color: ${props => props.theme.colors.gray100};
    }
  }

  tr:nth-child(odd) {
    background-color: ${props => props.theme.colors.gray5};

    td:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      [dir='rtl'] &&& {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    td:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      [dir='rtl'] &&& {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }
  }
`;

interface TRProps {
  actionable?: boolean;
}

const TR = styled.tr<TRProps>`
  ${props =>
    props.actionable &&
    css`
      &&&:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.action.light};
        td {
          color: ${props => props.theme.colors.action.hover};
        }

        svg {
          path {
            fill: ${props => props.theme.colors.action.hover};
          }
        }
      }
    `}
`;

export { Table };

Table.THead = THead;
Table.TBody = TBody;
Table.TR = TR;
