import { call, put } from 'redux-saga/effects';
import { FetchResult } from '@apollo/client';
import queries from '../queries';
import * as Sentry from '@sentry/browser';
import { changePasswordFailed, clearModal } from '@store/uiSlice';
import { successAlert } from '@components/Alert/dux';
import { publishChangePassword } from '@components/modal/changePassword/dux';
import { ChangePasswordMutation } from '../__generated__/ChangePasswordMutation';
import { ChangePasswordMutation_changePassword_errors } from '@io/__generated__/ChangePasswordMutation';

export enum ChangePasswordCodes {
  PASSWORD_INCORRECT = 'auth-009',
  CONFIRMATION_DOES_NOT_MATCH = 'auth-010',
  PASSWORD_INVALID = 'auth-011',
}

function* changePassword(action: ReturnType<typeof publishChangePassword>) {
  try {
    const result: FetchResult<ChangePasswordMutation> = yield call(
      [queries, queries.changePassword],
      action.payload
    );
    if (result.data?.changePassword.success) {
      yield put(clearModal());
      yield put(successAlert('change_password_success'));
    }
    if (result?.data?.changePassword?.errors.length) {
      throw result?.data?.changePassword?.errors;
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(changePasswordFailed(error as ChangePasswordMutation_changePassword_errors[]));
  }
}

export { changePassword };
