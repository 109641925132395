import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TrashOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16.5 5H17V3H12V2H8V3H3V5H3.5L4.5 18H15.5L16.5 5ZM13.6479 16L14.4941 5H5.50591L6.35206 16H13.6479Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20 5H21V3H14V2H10V3H3V5H4L5 22H19L20 5ZM17.1142 20L17.9965 5H6.00346L6.88581 20H17.1142Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M19 2H13V4H4V6H5.61538L7 30H25L26.3846 6H28V4H19V2ZM8.88794 28L7.61871 6H24.3813L23.1121 28H8.88794Z'
          size={size}
          {...rest}
        />
      );
  }
};
