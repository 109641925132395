import Display from './Display';
import { connect } from 'react-redux';
import { getCurrentLanguage, getLanguages } from '@store/uiSlice/selectors';
import { RootState } from '@store/rootReducer';
import { LanguageOption } from '@store/uiSlice';

interface OwnProps {
  value: string;
  format: string;
  timezone?: string;
}

interface StateProps {
  currentLanguage: string;
  languageOptions: Array<LanguageOption>;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => ({
  value: ownProps.value,
  format: ownProps.format,
  timezone: ownProps.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'utc',
  currentLanguage: getCurrentLanguage(state),
  languageOptions: getLanguages(state),
});

const VisibleDateDiplay = connect(mapStateToProps)(Display);

export type Props = StateProps & OwnProps;
export default VisibleDateDiplay;
