import Feed from './feed';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  getAnchoredMoment,
  getObjectOrder,
  momentSubmitted,
} from '@store/feedSlice/channelSelectors';
import { setSawLastMessageAt } from '@store/feedSlice';
import { PublicSubscriber as Subscriber } from '../../types';
import {
  getCurrentSubscriberAsPublicSubscriber,
  getTextMode,
} from '@store/subscriberSlice/selectors';
import { FeedObjectMetaData } from './objects/dux';
import { Moment } from './objects/moment/dux';
import { TextMode } from '../../../__generated__/globalTypes';
import { RootState } from '@store/rootReducer';

interface StateProps {
  objects: Array<FeedObjectMetaData>;
  latestObjectId?: string;
  subscriber: Subscriber;
  textMode: TextMode;
  anchoredMoment?: Moment;
  anchoredMomentSubmitted?: boolean;
}

interface OwnProps {
  channelKey: string;
  isDirect: boolean;
  isGuest: boolean;
  isLoading: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channelKey } = ownProps;
  const anchoredMoment = getAnchoredMoment(state, channelKey);
  const anchoredMomentSubmitted = anchoredMoment && momentSubmitted(state, anchoredMoment.id);
  const objects = getObjectOrder(state, channelKey);

  return {
    objects,
    latestObjectId: objects[objects.length - 1]?.id,
    subscriber: getCurrentSubscriberAsPublicSubscriber(state),
    textMode: getTextMode(state),
    anchoredMoment,
    anchoredMomentSubmitted,
    ...ownProps,
  };
};

interface DispatchProps {
  setSawLastMessageAt: (channelKey: string, timestamp: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setSawLastMessageAt: (channelKey, timestamp) =>
    dispatch(setSawLastMessageAt({ channelKey, timestamp })),
});

const VisibleFeed = connect(mapStateToProps, mapDispatchToProps)(Feed);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleFeed;
