import * as Sentry from '@sentry/browser';
import { CI_COMMIT_SHA, ENV } from '../constants';

Sentry.init({
  dsn: 'https://217df2642acc4e02ac5d06b01ceb20c3@o299700.ingest.sentry.io/1779451',
  environment: ENV,
  release: CI_COMMIT_SHA,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: false,
    }),
  ],
  ignoreErrors: [
    'NetworkError when attempting to fetch resource',
    'The request timed out',
    'Failed to fetch',
    'The Internet connection appears to be offline',
    'timeout',
  ],
  denyUrls: [
    /.*player\.twitch\.tv\/js.*/i,
    /.*embed-fastly\.wistia\.com.*/i,
    /.*fast\.wistia\.com.*/i,
    /.*connect\.facebook\.net.*/i,
    /.*player\.vimeo\.com.*/i,
    /.*cdn\.embedly\.com.*/i,
  ],
});
