import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import ConnectMoment from './connectMoment';
import GivingMoment from './givingMoment';
import ImageMoment from './imageMoment';
import InviteMoment from './inviteMoment';
import PrayerMoment from './prayerMoment';
import QuoteMoment from './quoteMoment';
import ResponseMoment from './responseMoment';
import SalvationMoment from './salvationMoment';
import SignupMoment from './signupMoment';
import { Props } from './index';
import { MomentType } from '../../../../../__generated__/globalTypes';
import FeedObjectMenu, { MESSAGE_MENU_MAX_HEIGHT } from '../feedObjectMenu';
import { Box } from '@lifechurch/react-ion';
import { FeedBoundaryContext } from '../../../../context/FeedBoundaryContext';
import clsx from 'clsx';
import styles from './moment.module.css';

const Moment: FunctionComponent<Props> = ({
  moment,
  isAnchored,
  hasSubmitted,
  hasLiked,
  dismissAnchoredMoment,
  deleteMoment,
  moderationPermissions,
}) => {
  const { topBoundary } = useContext(FeedBoundaryContext);
  const [menuPlacement, setMenuPlacement] = useState<'top' | 'auto'>('top');
  const wrapperRef = useRef<HTMLDivElement>(null);

  if (!moment || moment.deleted) return null;

  const handleDismiss = () => {
    dismissAnchoredMoment(moment.channel.key);
  };

  const handleDelete = () => {
    deleteMoment({ momentInstanceId: moment.id, channelKey: moment.channel.key });
  };

  const onShowMessageMenu = () => {
    const topBorderPositionOfMessage = wrapperRef.current
      ? wrapperRef.current?.getBoundingClientRect().top - MESSAGE_MENU_MAX_HEIGHT
      : null;

    const menuGoesBelow =
      !!topBorderPositionOfMessage && !!topBoundary && topBorderPositionOfMessage < topBoundary;

    setMenuPlacement(menuGoesBelow ? 'auto' : 'top');
  };

  const renderMoment = () => {
    switch (moment.type) {
      case MomentType.CONNECT:
        return <ConnectMoment moment={moment} onDismiss={handleDismiss} isPinned={isAnchored} />;
      case MomentType.GIVING:
        return <GivingMoment moment={moment} onDismiss={handleDismiss} isPinned={isAnchored} />;
      case MomentType.IMAGE:
        return (
          <ImageMoment
            moment={moment}
            hasLiked={hasLiked}
            isPinned={isAnchored}
            onDismiss={handleDismiss}
          />
        );
      case MomentType.INVITE:
        return <InviteMoment moment={moment} onDismiss={handleDismiss} isPinned={isAnchored} />;
      case MomentType.PRAYER:
        return <PrayerMoment moment={moment} onDismiss={handleDismiss} isPinned={isAnchored} />;
      case MomentType.QUOTE:
        return (
          <QuoteMoment
            moment={moment}
            hasLiked={hasLiked}
            isPinned={isAnchored}
            onDismiss={handleDismiss}
          />
        );
      case MomentType.RESPONSE:
        return (
          <ResponseMoment
            moment={moment}
            hasSubmitted={!!hasSubmitted}
            isPinned={isAnchored}
            onDismiss={handleDismiss}
          />
        );
      case MomentType.SALVATION:
        return (
          <SalvationMoment
            hasSubmitted={!!hasSubmitted}
            isPinned={isAnchored}
            onDismiss={handleDismiss}
            moment={moment}
          />
        );
      case MomentType.SIGNUP:
        return <SignupMoment moment={moment} onDismiss={handleDismiss} isPinned={isAnchored} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={clsx([styles.wrapper], {
        [styles.anchored]: isAnchored,
      })}
    >
      <Box width='100%'>{renderMoment()}</Box>
      {moderationPermissions && (
        <FeedObjectMenu
          deleteMoment={handleDelete}
          moderationPermissions={moderationPermissions}
          isMoment
          showMessageMenu={onShowMessageMenu}
          menuPlacement={menuPlacement}
        />
      )}
    </div>
  );
};

export default Moment;
