import React from 'react';
import { BallConfetti } from '../BallConfetti';
import { TwoHostLayout } from './TwoHostLayout';
import { ThreeHostLayout } from './ThreeHostLayout';
import { FourHostLayout } from './FourHostLayout';
import { FiveHostLayout } from './FiveHostLayout';

export interface Host {
  nickname: string;
  avatar: string;
}

export type HostsPreviewProps = {
  subscribers: Host[];
};

/**
 * This component was built to be used in the `<ChatPaneModal />`. It takes an array of hosts and
 * displays their avatars. The hosts should be formatted like so: `{ nickname: string; avatar:
 * string }`. There are specific arrangements that correspond to the number of hosts provided,
 * starting at 1 and going through 5. If there no hosts passed, the component returns null. If
 * there are more than 5, the layout used for 5 hosts is used, and the avatars for the first five
 * hosts passed to the component are shown.
 */
export function HostsPreview({ subscribers }: HostsPreviewProps) {
  if (subscribers.length <= 1) return <BallConfetti />;
  if (subscribers.length === 2) return <TwoHostLayout subscribers={subscribers} />;
  if (subscribers.length === 3) return <ThreeHostLayout subscribers={subscribers} />;
  if (subscribers.length === 4) return <FourHostLayout subscribers={subscribers} />;
  if (subscribers.length === 5) return <FiveHostLayout subscribers={subscribers} />;
  if (subscribers.length > 5) return <FiveHostLayout subscribers={subscribers} />;
  return null;
}
