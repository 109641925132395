import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CommentIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='M14 3H1V12H9V14.5L11.5 12H14V3Z' size={size} {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='M18 4H2V16H12V19L15 16H18V4Z' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='M22 4H2V20H14V24L18 20H22V4Z' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='M30 6H2V26H19V31L24 26H30V6Z' size={size} {...rest} />;
  }
};
