import React, { FC } from 'react';
import { defaultColors, Flex, IconSize, Spinner } from '@lifechurch/react-ion';

const Loading: FC = () => {
  return (
    <Flex alignItems='center' justifyContent='center' height={40}>
      <Spinner color={defaultColors.gray50} size={IconSize.LG} />
    </Flex>
  );
};

export default Loading;
