import React, { FunctionComponent } from 'react';
import { IconProps } from '@components/icons';
import Actionable from '@components/Actionable';
import {
  NavMenuButton,
  NavMenuIconWrapper,
  NavMenuTextWrapper,
  PipWrapper,
  TextWrapper,
  TypingIndicatorIconContainer,
  TypingIndicatorIconContainerCompact,
} from '@features/navMenu/styles';
import NavMenuIcon from '@features/navMenu/navMenuIcon';
import TypingIndicatorIcon from '@components/icons/tpyingIndicator';
import Pip from '@components/Pip';

export interface NavMenuItemProps {
  direct?: boolean;
  expanded?: boolean;
  hasActions?: boolean;
  hasNewMessages?: boolean;
  hasSubscribersTyping?: boolean;
  Icon: React.ComponentType<IconProps>;
  numberOfSubscribers?: number;
  onClick: () => void;
  selected: boolean;
  showPip?: boolean; // Host Chat is the only item with pip
  text: string;
}

const NavMenuItem: FunctionComponent<NavMenuItemProps> = ({
  direct = false,
  expanded = false,
  hasActions = false,
  hasNewMessages = false,
  hasSubscribersTyping,
  Icon,
  onClick,
  numberOfSubscribers,
  selected = false,
  showPip = false,
  text,
}) => {
  const groupChat = !!numberOfSubscribers;

  return (
    <Actionable onClick={onClick}>
      <NavMenuButton text={text} groupChat={groupChat}>
        <NavMenuIconWrapper expanded={expanded} data-testid={`nav-menu-${text}`}>
          <>
            <NavMenuIcon
              direct={direct}
              text={text}
              groupChat={groupChat}
              selected={selected}
              expanded={expanded}
              hasNewMessages={hasNewMessages}
              Icon={Icon}
            />
            {hasSubscribersTyping && !expanded && (
              <TypingIndicatorIconContainerCompact>
                <TypingIndicatorIcon />
              </TypingIndicatorIconContainerCompact>
            )}
          </>
        </NavMenuIconWrapper>
        {expanded && (
          <NavMenuTextWrapper
            selected={selected}
            uppercase={!direct}
            pipPresent={hasActions || hasNewMessages}
          >
            <TextWrapper>{text}</TextWrapper>
            {hasSubscribersTyping && !selected && (
              <TypingIndicatorIconContainer>
                <TypingIndicatorIcon />
              </TypingIndicatorIconContainer>
            )}
          </NavMenuTextWrapper>
        )}
        {showPip && (
          <PipWrapper expanded={expanded}>
            <Pip hasActions={hasActions} />
          </PipWrapper>
        )}
      </NavMenuButton>
    </Actionable>
  );
};

export default NavMenuItem;
