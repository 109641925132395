import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowDownRightIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M12 6V11V12H11H6V11H10.2929L3 3.70711V3H3.70711L11 10.2929V6H12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
