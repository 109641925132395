import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  TimesIcon,
  WarningTriangleIcon,
  InfoCircleIcon,
  ErrorCircleIcon,
  CheckCircleIcon,
} from './icons';
import { Box } from './Box';
import { defaultColors } from './theme';
import { SpaceProps } from 'styled-system';
import { getInset, LogicalProps } from './logical';

export enum FormBannerType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Information = 'information',
}

export interface FormBannerProps extends SpaceProps, LogicalProps {
  children: ReactNode;
  type: FormBannerType;
  onDismiss?: () => void;
  withIcon?: boolean;
  customIcon?: ReactNode;
}

interface FormBannerContentProps {
  type: FormBannerType;
  dismissable: boolean;
}

interface IconProps {
  type: FormBannerType;
}

const FormBannerContent = styled(Box)<FormBannerContentProps>`
  color: ${props => props.theme.colors[props.type].dark};
  margin-block-start: auto;
  margin-block-end: auto;
  margin-inline-start: 0;
  margin-inline-end: ${props => (props.dismissable ? '28px' : '0')};
  a {
    color: ${props => props.theme.colors[props.type].dark};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors[props.type].dark};
      text-decoration: underline;
    }
    &:focus {
      color: ${props => props.theme.colors[props.type].dark};
      text-decoration: underline;
    }
  }
`;

const DismissButton = styled.button`
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  opacity: 0.5;
  width: 40px;
  height: 44px;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 4px;
  border: none;
  background: none;
  outline: none;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;

const Icon: FunctionComponent<IconProps> = ({ type }) => {
  switch (type) {
    case FormBannerType.Error:
      return <ErrorCircleIcon color={defaultColors.error.default} />;
    case FormBannerType.Warning:
      return <WarningTriangleIcon color={defaultColors.warning.default} />;
    case FormBannerType.Success:
      return <CheckCircleIcon color={defaultColors.success.default} />;
    case FormBannerType.Information:
      return <InfoCircleIcon color={defaultColors.information.default} />;
    default:
      return null;
  }
};

const color = (type: string): string => {
  switch (type) {
    case FormBannerType.Error:
      return defaultColors.error.default;
    case FormBannerType.Warning:
      return defaultColors.warning.default;
    case FormBannerType.Success:
      return defaultColors.success.default;
    case FormBannerType.Information:
      return defaultColors.information.default;
    default:
      return '';
  }
};

export const FormBanner: FunctionComponent<FormBannerProps> = ({
  type,
  children,
  onDismiss,
  withIcon = false,
  customIcon: CustomIcon,
  ...props
}: FormBannerProps) => (
  <Box
    maxWidth={'640px'}
    borderRadius={2}
    backgroundColor={`${type}.light`}
    display={'flex'}
    flexDirection={'row'}
    justifyContent={'flex-start'}
    p={4}
    position={'relative'}
    {...props}
  >
    {withIcon && (
      <Box paddingInlineEnd={3}>
        <Box display={'flex'} alignItems={'center'}>
          {CustomIcon ? CustomIcon : <Icon type={type} />}
        </Box>
      </Box>
    )}
    <FormBannerContent type={type} dismissable={onDismiss !== undefined}>
      {children}
    </FormBannerContent>
    {onDismiss && (
      <DismissButton onClick={onDismiss}>
        <TimesIcon color={color(type)} />
      </DismissButton>
    )}
  </Box>
);
