import { LikePinnedButton } from '../likePinnedButton';
import { ButtonSize, HeartIcon, HeartOIcon, IconSize, SecondaryButton, } from '@lifechurch/react-ion';
import { PinnedLikeCount } from '../pinnedLikeCount';
import React, { useContext } from 'react';
import { EngagementWrapper, HeartIconWrapper } from './styles';
import { ThemeContext } from 'styled-components';
const PinnedEngagement = ({ buttonAlignment = 'flex-start', hasLiked = false, likeCount = 0, onLike, onShare, shareButtonLabel = 'Share', }) => {
    const { colors } = useContext(ThemeContext);
    return (React.createElement(EngagementWrapper, { buttonAlignment: buttonAlignment, isPinned: true },
        React.createElement(LikePinnedButton, { hasLiked: hasLiked, onClick: onLike, "data-testid": 'pinned-like', "aria-label": 'Like' },
            React.createElement(HeartIconWrapper, null, hasLiked ? (React.createElement(HeartIcon, { color: colors.pink.default, size: IconSize.MD })) : (React.createElement(HeartOIcon, { color: colors.action.default, size: IconSize.MD }))),
            React.createElement(PinnedLikeCount, { count: likeCount, hasLiked: hasLiked }, likeCount)),
        React.createElement(SecondaryButton, { size: ButtonSize.SM, marginBlockStart: 0, marginBlockEnd: 0, marginInlineStart: '8px', marginInlineEnd: 0, onClick: onShare, "data-testid": 'pinned-share' }, shareButtonLabel)));
};
export default PinnedEngagement;
