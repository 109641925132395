import React from 'react';
import { SignupMoment as BaseSignupMoment } from '@churchonline/moments';
import { Props } from './';
import { signupModal } from '@components/modal/dux';
import { AlertSubtype } from '@components/Alert/dux';

const SignupMoment: React.FunctionComponent<Props> = ({
  moment,
  onDismiss,
  isPinned,
  setModal,
  clearAlertBySubtype,
  externalLoginUrl,
}) => {
  const handleClick = (): void => {
    if (externalLoginUrl) {
      window.location.href = externalLoginUrl;
    } else {
      setModal(signupModal('signup_moment'));
      clearAlertBySubtype(AlertSubtype.WELCOME);
    }
  };

  return (
    <BaseSignupMoment
      url={moment.momentTemplate.options.url}
      text={moment.momentTemplate.options.text}
      buttonText={moment.momentTemplate.options.buttonText}
      onClick={handleClick}
      onDismiss={onDismiss}
      isPinned={isPinned}
    />
  );
};

export default SignupMoment;
