import React, { createRef, useRef } from 'react';
import Imgix from 'react-imgix';
import { Box, Flex, defaultColors, InfoCircleIcon, OHeaderThree, TimesIcon, IconSize, ButtonSize, FacebookLogoIcon, TwitterLogoIcon, DownloadIcon, OSmallParagraph, } from '@lifechurch/react-ion';
import { DismissButton, DownloadButton, FacebookButton, InfoBox, Modal, ModalWrapper, TwitterButton, UnavailableForPreview, } from './ImagePreviewModalStyles';
export const ImagePreviewModal = ({ dismiss, open, image, isPlaceholder, altText, }) => {
    const modalRef = createRef();
    const unavailable = useRef(null);
    const showUnavailable = () => {
        if (unavailable && unavailable.current) {
            unavailable.current.style.display = 'flex';
        }
    };
    if (!open)
        return null;
    return (React.createElement(ModalWrapper, { "data-testid": 'modal-wrapper' },
        React.createElement(Modal, { ref: modalRef, "data-testid": 'image-preview-modal' },
            React.createElement(DismissButton, { onClick: dismiss, type: 'button', "data-testid": 'image-preview-dismiss-id' },
                React.createElement(TimesIcon, { color: defaultColors.gray50 })),
            React.createElement(Box, { paddingInlineEnd: 7 },
                React.createElement(OHeaderThree, { paddingBlockEnd: 5 }, "Share")),
            React.createElement(Box, null,
                React.createElement(Box, { marginBlockEnd: 5 },
                    React.createElement(Flex, { position: 'relative', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'gray5', border: `1px solid ${defaultColors.gray10}`, borderRadius: 4 },
                        React.createElement(Imgix, { src: isPlaceholder
                                ? 'https://chop.imgix.net/static/placeholders/image_moment_empty_glyph.png'
                                : image, height: isPlaceholder ? 64 : 100, htmlAttributes: {
                                style: {
                                    position: 'relative',
                                    margin: isPlaceholder ? '38px' : undefined,
                                },
                                alt: altText,
                                title: altText,
                            }, imgixParams: {
                                auto: 'format',
                                fit: 'clip',
                            } }))),
                React.createElement(Flex, { flexDirection: 'column' },
                    React.createElement(Flex, { flexDirection: 'row', justifyContent: 'center' },
                        React.createElement(Flex, { flexDirection: 'column', alignItems: 'center', margin: '0 4px' },
                            React.createElement(FacebookButton, { padding: '8px !important', size: ButtonSize.SM, onClick: showUnavailable, "data-testid": 'facebook-button' },
                                React.createElement(FacebookLogoIcon, { size: IconSize.XL, color: 'white' })),
                            React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Facebook")),
                        React.createElement(Flex, { flexDirection: 'column', alignItems: 'center', margin: '0 4px' },
                            React.createElement(TwitterButton, { padding: '8px !important', size: ButtonSize.SM, onClick: showUnavailable, "data-testid": 'twitter-button' },
                                React.createElement(TwitterLogoIcon, { size: IconSize.XL, color: 'white' })),
                            React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Twitter")),
                        React.createElement(Flex, { flexDirection: 'column', alignItems: 'center', margin: '0 4px' },
                            React.createElement(DownloadButton, { padding: '8px !important', size: ButtonSize.SM, onClick: showUnavailable, "data-testid": 'download-button' },
                                React.createElement(DownloadIcon, { size: IconSize.XL, color: 'white' })),
                            React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Download"))),
                    React.createElement(UnavailableForPreview, { id: 'unavailable', ref: unavailable, "data-testid": 'unavailable' }, "Unavailable For Preview")))),
        React.createElement(InfoBox, { marginBlockStart: 3, ref: modalRef, "data-testid": 'testId' },
            React.createElement(Box, null,
                React.createElement(InfoCircleIcon, { size: IconSize.SM, color: defaultColors.gray100 })),
            React.createElement(Box, { marginInlineStart: 3 }, "If supported, the device\u2019s native sharing method will be utilized."))));
};
