import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ShareIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.85355 1.64645L7.5 1.29289L7.14645 1.64645L3.64645 5.14645L4.35355 5.85355L7 3.20711V10H8V3.20711L10.6464 5.85355L11.3536 5.14645L7.85355 1.64645ZM2 12.5V10H3V12H12V10H13V12.5V13H12.5H2.5H2V12.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 2.58582L10.7071 3.29292L14.7071 7.29292L13.2929 8.70714L11 6.41424V13H9V6.41424L6.70711 8.70714L5.29289 7.29292L9.29289 3.29292L10 2.58582ZM3 13H5V15H15V13H17V15V17H3V15V13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 2.58582L12.7071 3.29292L17.7071 8.29292L16.2929 9.70714L13 6.41424V16H11V6.41424L7.70711 9.70714L6.29289 8.29292L11.2929 3.29292L12 2.58582ZM3 16H5V19H19V16H21V19V21H19H5H3V19V16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 3.58582L16.7071 4.29292L22.7071 10.2929L21.2929 11.7071L17 7.41424V21H15V7.41424L10.7071 11.7071L9.29289 10.2929L15.2929 4.29292L16 3.58582ZM4 27V21H6V26H26V21H28V27V28H27H5H4V27Z'
          size={size}
          {...rest}
        />
      );
  }
};
