import { ModalType } from '../dux';

interface RemoveAvatarInterface {
  modal: ModalType;
}

export interface RemoveAvatar {
  type: ModalType.REMOVE_AVATAR;
  payload: RemoveAvatarInterface;
}

export const removeAvatar = (payload: RemoveAvatarInterface): RemoveAvatar => ({
  type: ModalType.REMOVE_AVATAR,
  payload,
});
