import { resetApp, RootState } from '@store/rootReducer';
import { saveSubscriber, uploadAvatar } from '@store/subscriberSlice';
import { getAuthProvider, getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { clearModal, setModal } from '@store/uiSlice';
import { deleteAllCookies } from '@utils/core';
import { connect } from 'react-redux';
import { SubscriberInput } from '../../../../__generated__/globalTypes';
import { AuthProvider, Subscriber } from '../../../types';
import { errorAlert, infoAlert } from '../../Alert/dux';
import { changePassword } from '../changePassword/dux';
import { deleteAccount, Modal, ModalType, removeAvatar } from '../dux';
import { ProfileSettingsMeta } from './dux';
import ProfileSettings from './profileSettings';
import { Dispatch } from 'redux';

interface StateProps {
  subscriber: Subscriber;
  provider: AuthProvider | null;
  globalModal: Modal | undefined;
}

interface OwnProps {
  closeModal: () => void;
  meta?: ProfileSettingsMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  subscriber: getCurrentSubscriber(state),
  provider: getAuthProvider(state),
  globalModal: state.ui.modal,
  ...ownProps,
});

interface DispatchProps {
  saveSubscriber: (id: string, input: SubscriberInput, successCallback?: () => void) => void;
  uploadAvatar: (id: string, formData: FormData) => void;
  promptRemoveAvatar: () => void;
  promptChangePassword: () => void;
  promptDeleteAccount: () => void;
  logout: () => void;
  imageTooLargeError: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSubscriber: (id: string, input: SubscriberInput) => {
    dispatch(
      saveSubscriber({ id, input }, { profileSettingsUpdated: true }, () => {
        dispatch(clearModal());
      })
    );
  },
  uploadAvatar: (id: string, formData: FormData) => dispatch(uploadAvatar({ id, formData })),
  promptRemoveAvatar: () => dispatch(setModal(removeAvatar({ modal: ModalType.PROFILE_SETTINGS }))),
  promptChangePassword: () => dispatch(setModal(changePassword())),
  promptDeleteAccount: () => dispatch(setModal(deleteAccount())),
  logout: async () => {
    await deleteAllCookies();
    dispatch(resetApp());
    dispatch(infoAlert('logged_out'));
  },
  imageTooLargeError: () => dispatch(errorAlert('max_file_size')),
});

const VisibleProfileSettings = connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleProfileSettings;
