import React, { ReactElement, useState } from 'react';
import { FILTERS } from '@features/audience/components/filter';
import { PaneType } from '@features/pane/dux';
import { useAppDispatch } from '@hooks/redux/hooks';
import { useHostCount } from '@hooks/useHostCount';
import { ButtonSize, IconSize, SecondaryButton, UsersIcon } from '@lifechurch/react-ion';
import { isOffline } from '@store/serviceSlice/selectors';
import { setAudiencePaneFilter, setPaneToType } from '@store/uiSlice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

interface Props {
  mobile?: boolean;
}
interface ButtonProps extends Props {
  hovering: boolean;
}

const Button = styled(SecondaryButton)<ButtonProps>`
  cursor: pointer;
  padding: ${({ mobile }) => (mobile ? '7px 10px' : undefined)};
  position: ${({ mobile }) => (mobile ? 'absolute' : undefined)};
  right: ${({ mobile }) => (mobile ? '10px' : undefined)};
  top: ${({ mobile }) => (mobile ? '10px' : undefined)};
  z-index: 99;
  background-color: rgba(255, 255, 255, 1);

  &:hover {
    background-color: ${({ theme }) => theme.colors.action.default};
  }
`;

const NumberText = styled.span<{ hovering: boolean }>`
  color: ${({ theme, hovering }) => {
    if (hovering) return '#ffffff';
    return theme.colors.action.default;
  }};
  fontsize: 17px;
  margin-left: 3px;
`;

const SHADOW = '0px 2px 6px 0px rgba(0, 0, 0, 0.25)';
const determineShadow = (mobile: boolean, hovering: boolean) => {
  if (mobile) return SHADOW;
  else if (!mobile && hovering) return SHADOW;
  else return 'none';
};

export function HostCount({ mobile = false }: Props): ReactElement | null {
  const [hovering, setHovering] = useState(false);
  const offline = useSelector(isOffline);
  const dispatch = useAppDispatch();
  const { numberOfHosts } = useHostCount();

  const handleClick = () => {
    dispatch(setPaneToType(PaneType.AUDIENCE));
    dispatch(setAudiencePaneFilter(FILTERS.HOSTS));
  };

  const handleMouseEnter = () => setHovering(true);
  const handleMouseLeave = () => setHovering(false);

  if (offline) return null;

  return (
    <Button
      size={ButtonSize.SM}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      hovering={hovering}
      mobile={mobile}
      style={{
        border: mobile ? 'none' : undefined,
        boxShadow: determineShadow(mobile, hovering),
      }}
    >
      <UsersIcon size={IconSize.MD} color={hovering ? '#ffffff' : '#2893e5'} />
      <NumberText hovering={hovering}>{numberOfHosts}</NumberText>
    </Button>
  );
}
