import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const FacebookLogoIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 13.993 4.92547 17.3027 8.75 17.9028V12.3125H6.71875V10H8.75V8.2375C8.75 6.2325 9.94438 5.125 11.7717 5.125C12.647 5.125 13.5625 5.28125 13.5625 5.28125V7.25H12.5538C11.56 7.25 11.25 7.86672 11.25 8.49937V10H13.4688L13.1141 12.3125H11.25V17.9028C15.0745 17.3027 18 13.993 18 10Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 16.9912 5.65684 21.1283 10.4375 21.8785V14.8906H7.89844V12H10.4375V9.79688C10.4375 7.29063 11.9305 5.90625 14.2146 5.90625C15.3088 5.90625 16.4531 6.10156 16.4531 6.10156V8.5625H15.1922C13.95 8.5625 13.5625 9.3334 13.5625 10.1242V12H16.3359L15.8926 14.8906H13.5625V21.8785C18.3432 21.1283 22 16.9912 22 12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M29 16C29 8.82029 23.1797 3 16 3C8.82029 3 3 8.82029 3 16C3 22.4886 7.75389 27.8668 13.9688 28.8421V19.7578H10.668V16H13.9688V13.1359C13.9688 9.87781 15.9096 8.07812 18.879 8.07812C20.3014 8.07812 21.7891 8.33203 21.7891 8.33203V11.5312H20.1498C18.535 11.5312 18.0312 12.5334 18.0312 13.5615V16H21.6367L21.0604 19.7578H18.0312V28.8421C24.2461 27.8668 29 22.4886 29 16Z'
          size={size}
          {...rest}
        />
      );
  }
};
