import { connect } from 'react-redux';
import { popSubscribersTyping, setChatFocus } from '@store/feedSlice';
import { getPlaceholder } from '@store/feedSlice/chatSelectors';
import {
  getTranslateLanguage,
  getChannelByKey,
  getChatMessage,
  getChannelAsPublicChannel,
} from '@store/feedSlice/channelSelectors';
import { newMessageLiveObject } from '@features/feed/objects/message/dux';
import { publishLiveObject } from '../../../../../../pubnub/liveObject';
import ChatInputBox from './ChatInputBox';
import {
  getCurrentSubscriber,
  getCurrentSubscriberAsPublicSubscriber,
  getTextMode,
} from '@store/subscriberSlice/selectors';
import { selectChatName, ssoValueProp } from '@components/modal/dux';
import {
  typingInChannel,
  saveChannelMessage,
  saveMessage,
  requestPlaceholderChannel,
  Channel,
} from '@store/feedSlice';
import { PublicSubscriber } from '../../../../../../types';
import { Dispatch } from 'redux';
import { closeSideMenu, setModal, closeReactionBanner } from '@store/uiSlice';
import { isReactionBannerVisible } from '@store/uiSlice/selectors';
import { profileSettings as profileSettingsModal, signupModal } from '@components/modal/dux';
import { rapidMessageAlert } from '@components/Alert/dux';
import { IntegrationType, TextMode } from '../../../../../../../__generated__/globalTypes';
import { getIntegrationTypes } from '@store/organizationSlice/selectors';
import { SelectChatNameSubtype } from '@components/modal/selectChatName/dux';
import { RootState } from '@store/rootReducer';

interface StateProps {
  focused: boolean;
  currentPlaceholder: string;
  currentSubscriber: PublicSubscriber;
  hasRole: boolean;
  isSignedIn: boolean;
  currentChannel: Channel;
  textMode: TextMode;
  translateLanguage: string;
  message: string;
  hasSso: boolean;
  isBannerVisible: boolean;
  viewingAsUser: boolean | undefined;
}

interface OwnProps {
  channelKey: string;
  showReactions: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  // TODO: Don't send the entire channel object as a prop, only the pieces we need. This
  // could lead to 1,000's of chat messages being sent as part of the prop and re-rendering
  // every time a chat message comes in
  const integrationTypes = getIntegrationTypes(state);
  const hasSso = integrationTypes.includes(
    IntegrationType.OPEN_ID_CONNECT || integrationTypes.includes(IntegrationType.LEGACY_AUTH)
  );
  const viewingAsUser = state.ui.viewingAsUser;

  return {
    ...ownProps,
    focused: state.feed.focusedChannel === ownProps.channelKey,
    currentPlaceholder: getPlaceholder(state, ownProps.channelKey),
    currentSubscriber: getCurrentSubscriberAsPublicSubscriber(state),
    hasRole: getCurrentSubscriber(state).role ? true : false,
    isSignedIn:
      getCurrentSubscriber(state).email != null && getCurrentSubscriber(state).email !== '',
    currentChannel: getChannelByKey(state, ownProps.channelKey) ?? {},
    textMode: getTextMode(state),
    translateLanguage: getTranslateLanguage(state),
    message: getChatMessage(state, ownProps.channelKey),
    hasSso: hasSso,
    isBannerVisible: isReactionBannerVisible(state),
    viewingAsUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setChatFocus: (channel: string) => dispatch(setChatFocus({ channelKey: channel })),
  publishMessage: (text: string, subscriber: PublicSubscriber, lang: string, channel: Channel) => {
    const object = newMessageLiveObject(text, subscriber, lang, getChannelAsPublicChannel(channel));
    dispatch(saveMessage(object));
    dispatch(publishLiveObject(object));
  },
  requestChannelAndPublish: (
    text: string,
    subscriber: PublicSubscriber,
    lang: string,
    channel: Channel
  ) => dispatch(requestPlaceholderChannel(text, subscriber, lang, channel)),
  setNickname: () => dispatch(setModal(selectChatName(SelectChatNameSubtype.CHAT))),
  typingInChannel: (typing: boolean, channelKey: string) => {
    if (channelKey) {
      dispatch(typingInChannel(typing, channelKey));
    }
  },
  saveMessage: (channelKey: string, message: string) =>
    dispatch(saveChannelMessage({ channelKey, message })),
  popSubscribersTyping: (channelKey: string) => dispatch(popSubscribersTyping({ channelKey })),
  signup: () => {
    dispatch(setModal(signupModal('chat_input_avatar')));
  },
  openProfileModal: () => {
    dispatch(closeSideMenu());
    dispatch(setModal(profileSettingsModal()));
  },
  openSsoValuePropModal: () => dispatch(setModal(ssoValueProp())),
  rapidMessageError: () =>
    dispatch(rapidMessageAlert('Please wait to send another message. ', 4000)),
  closeBanner: () => dispatch(closeReactionBanner()),
});

const VisibleChat = connect(mapStateToProps, mapDispatchToProps)(ChatInputBox);
export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleChat;
