import React, { FC, useEffect, useState } from 'react';
import {
  ChatInputPlaceholder,
  ChatInputs,
  ChatInputSkeleton,
  ChatInputSkeletonWrapper,
  ReactionSkeleton,
} from '../styles';
import ChatInputBox from './ChatInputBox';
import ReactionButtons from '../../../../reactions/reactionButtons';
import { Channel } from '@store/feedSlice';
import { ChannelType } from '../../../../../../__generated__/globalTypes';
import ConnectionLost from './ConnectionLost';

export interface Props {
  hostViewingAsHost: boolean;
  isChatEnabled: boolean;
  pendingPrayer: boolean;
  channel: Channel;
  isLoading: boolean;
  isConnected: boolean;
  connectionStatusChangedAt?: string;
}

const ChatInput: FC<Props> = ({
  isChatEnabled,
  pendingPrayer,
  channel,
  hostViewingAsHost,
  isLoading,
  isConnected,
  connectionStatusChangedAt,
}) => {
  const { type, key } = channel;
  const isPublic = type === ChannelType.public;
  const [showConnectionError, setShowConnectionError] = useState(false);

  useEffect(() => {
    setShowConnectionError(false);

    const timer = setTimeout(() => {
      if (connectionStatusChangedAt) {
        const statusChangedDate = new Date(Date.parse(connectionStatusChangedAt));
        const now = new Date();
        if (Math.abs(now.getTime() - statusChangedDate.getTime()) >= 5) {
          setShowConnectionError(true);
        }
      }
    }, 5001);

    return () => {
      clearTimeout(timer);
    };
  }, [connectionStatusChangedAt]);

  if ((pendingPrayer && !hostViewingAsHost) || isLoading) {
    return (
      <ChatInputs>
        <ChatInputSkeletonWrapper isPublic={isPublic}>
          <ChatInputSkeleton />
        </ChatInputSkeletonWrapper>
        {isPublic && <ReactionSkeleton />}
      </ChatInputs>
    );
  }

  if (!isConnected && showConnectionError) {
    return (
      <>
        <ConnectionLost />
        <ChatInputs>
          {isChatEnabled ? (
            <ChatInputSkeletonWrapper isPublic={isPublic}>
              <ChatInputSkeleton />
            </ChatInputSkeletonWrapper>
          ) : (
            <ChatInputPlaceholder />
          )}
          {isPublic && <ReactionSkeleton />}
        </ChatInputs>
      </>
    );
  }

  return (
    <ChatInputs>
      {isChatEnabled ? (
        <ChatInputBox channelKey={key} showReactions={isPublic} />
      ) : (
        <ChatInputPlaceholder />
      )}
      {isPublic && <ReactionButtons />}
    </ChatInputs>
  );
};

export default ChatInput;
