import React from 'react';
import { Icon } from './styles';
import { IconSize, Spinner } from '@lifechurch/react-ion';
import styled from 'styled-components';
const AvatarLoaderIcon = styled(Icon) `
  background: rgba(45, 45, 46, 0.5);
  position: absolute;
`;
const AvatarLoader = ({ size }) => (React.createElement(AvatarLoaderIcon, { size: size },
    React.createElement(Spinner, { size: IconSize.MD })));
export default AvatarLoader;
