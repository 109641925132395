import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const UndoIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M1.14646 4.14648L3.64646 1.64648L4.35357 2.35359L2.70712 4.00004H10C12.2092 4.00004 14 5.7909 14 8.00004C14 10.2092 12.2092 12 10 12V11C11.6569 11 13 9.65689 13 8.00004C13 6.34318 11.6569 5.00004 10 5.00004H2.70712L4.35357 6.64648L3.64646 7.35359L1.14646 4.85359L0.792908 4.50004L1.14646 4.14648ZM10 11H5V12H10V11Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M5.41421 8.00008L7.20711 9.79297L5.79289 11.2072L2.29289 7.70718L1.58578 7.00008L2.29289 6.29297L5.79289 2.79297L7.20711 4.20718L5.41421 6.00008H13.5C16.2614 6.00008 18.5 8.23865 18.5 11.0001C18.5 13.7615 16.2614 16.0001 13.5 16.0001L8 16V14L13.5 14.0001C15.1569 14.0001 16.5 12.6569 16.5 11.0001C16.5 9.34322 15.1569 8.00008 13.5 8.00008H5.41421Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
