import ImageMomentShareSheet from './imageMomentShareSheet';

interface OwnProps {
  altText: string | null;
  imageUrl: string;
  shareText: string | null;
  closeModal: () => void;
  title: string;
}

const VisibleImageMomentShareSheet = ImageMomentShareSheet;

export type Props = OwnProps;
export default VisibleImageMomentShareSheet;
