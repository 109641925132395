import React, { FunctionComponent } from 'react';
import SideMenu from '@features/sideMenu';
import NavHeader from '@features/navHeader';
import NavBar from '@features/navbar';
import Pane from '@features/pane';
import { Container, CellContainer, MainGrid, FlexCellContainer, SecondaryGrid } from './styles';
import { Cell } from 'styled-css-grid';
import { Props } from './index';
import ServiceWindow from '../../ServiceWindow';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const LargeUp: FunctionComponent<Props> = ({ hasUpcomingServices, isOffline, chatIsFocused }) => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.SIDE_MENU}
        altComponentName={t('side_menu')}
      >
        <SideMenu />
      </ErrorBoundary>
      <Container>
        <NavHeader />
        {!hasUpcomingServices && isOffline ? (
          <SecondaryGrid>
            <Cell area='video'>
              <FlexCellContainer>
                <ServiceWindow />
              </FlexCellContainer>
            </Cell>
          </SecondaryGrid>
        ) : (
          <MainGrid chatIsFocused={chatIsFocused}>
            <Cell area='video'>
              <FlexCellContainer>
                <ServiceWindow />
              </FlexCellContainer>
            </Cell>
            <Cell area='chat'>
              <CellContainer id='publicchat'>
                <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE}>
                  <Pane />
                </ErrorBoundary>
                <ErrorBoundary>
                  <NavBar />
                </ErrorBoundary>
              </CellContainer>
            </Cell>
          </MainGrid>
        )}
      </Container>
    </>
  );
};

export default React.memo(LargeUp);
