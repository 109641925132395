import { Flex, LinkButton, PrimaryButton } from '@lifechurch/react-ion';
import React, { FC, KeyboardEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseAlert } from '../BaseAlert';
import { Props } from './index';
import useSessionStorage from '@hooks/useSessionStorage';

const WelcomeAlert: FC<Props> = ({ alert, dismissAlert, externalLoginUrl, login, signup }) => {
  const { t } = useTranslation('');
  const [viewedWelcomeAlert, setViewedWelcomeAlert] = useSessionStorage(
    'viewedWelcomeAlert',
    false
  );

  const shouldShowAlert = useRef(!viewedWelcomeAlert);

  useEffect(() => {
    setViewedWelcomeAlert(true);
  }, [setViewedWelcomeAlert]);

  const handleLogin = () => {
    if (externalLoginUrl) {
      window.location.href = externalLoginUrl;
    } else {
      login({ error: null });
      dismissAlert();
    }
  };

  const handleSignup = () => {
    if (externalLoginUrl) {
      window.location.href = externalLoginUrl;
    } else {
      signup();
      dismissAlert();
    }
  };

  const enterHandler: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  if (!alert?.variables?.currentOrganizationName) {
    return null;
  }

  const currentOrganizationName = alert.variables.currentOrganizationName;

  const handleDismiss = () => {
    setViewedWelcomeAlert(true);
    dismissAlert();
  };

  if (!shouldShowAlert.current) return null;

  return (
    <BaseAlert
      uniqueKey={alert.uniqueKey}
      type={alert.type}
      onDismiss={handleDismiss}
      fuseDisabled={alert.fuseDisabled}
      fuseTimer={alert.fuseTimer}
    >
      <Flex flexDirection='column'>
        <div style={{ marginBlockEnd: 4 }}>{t('welcome_banner', { currentOrganizationName })}</div>
        <div>
          <PrimaryButton
            onClick={handleSignup}
            data-testid='welcome-alert-login'
            marginInlineEnd={5}
          >
            {t('sign_up')}
          </PrimaryButton>
          <LinkButton
            onClick={handleLogin}
            onKeyPress={enterHandler}
            data-testid='welcome-alert-signup'
            tabIndex={0}
            role='button'
          >
            {t('log_in')}
          </LinkButton>
        </div>
      </Flex>
    </BaseAlert>
  );
};

export default WelcomeAlert;
