import { PublicSubscriber as Subscriber, PublicChannel as Channel } from '../types';
import { Message } from '@features/feed/objects/message/dux';
import { Notification } from '@features/feed/objects/notification/dux';
import { Moment } from '@features/feed/objects/moment/dux';
import { PrivateChannelInvite } from '@features/feed/objects/privateChannelInvite/dux';
import { LiveService } from '@store/serviceSlice';
import { PrayerRequest } from '@store/feedSlice';
import { createAction } from '@reduxjs/toolkit';

export enum LiveObjectType {
  MESSAGE = 'MESSAGE',
  PRAYER_REQUEST = 'PRAYER_REQUEST',
  NOTIFICATION = 'NOTIFICATION',
  SUBSCRIBER = 'SUBSCRIBER',
  CHANNEL = 'CHANNEL',
  MOMENT = 'MOMENT',
  REACTION = 'REACTION',
  PRIVATE_CHANNEL_INVITE = 'PRIVATE_CHANNEL_INVITE',
  SERVICE = 'SERVICE',
  MESSAGE_ACTION_AGGREGATE = 'MESSAGE_ACTION_AGGREGATE',
}

interface BaseLiveObject {
  id: string;
  channel: Channel;
  timestamp: string;
}

export interface MessageLiveObject extends BaseLiveObject {
  type: LiveObjectType.MESSAGE;
  data: Message;
}

export interface PrayerRequestLiveObject extends BaseLiveObject {
  type: LiveObjectType.PRAYER_REQUEST;
  data: PrayerRequest;
}

export interface NotificationLiveObject extends BaseLiveObject {
  type: LiveObjectType.NOTIFICATION;
  data: Notification;
}

export interface SubscriberLiveObject extends BaseLiveObject {
  type: LiveObjectType.SUBSCRIBER;
  data: Subscriber;
}

export interface ChannelLiveObject extends BaseLiveObject {
  type: LiveObjectType.CHANNEL;
  data: Channel;
}

export interface MomentLiveObject extends BaseLiveObject {
  type: LiveObjectType.MOMENT;
  data: Moment;
}

export interface PrivateChannelInviteLiveObject extends BaseLiveObject {
  type: LiveObjectType.PRIVATE_CHANNEL_INVITE;
  data: PrivateChannelInvite;
}

export interface ServiceLiveObject extends BaseLiveObject {
  type: LiveObjectType.SERVICE;
  data: LiveService;
}

export type LiveObject =
  | ChannelLiveObject
  | MessageLiveObject
  | MomentLiveObject
  | NotificationLiveObject
  | PrayerRequestLiveObject
  | SubscriberLiveObject
  | ServiceLiveObject
  | PrivateChannelInviteLiveObject;

// Action Creators
export const onLiveObject = createAction<LiveObject>('feed/onLiveObject');
export const publishLiveObject = createAction<LiveObject>('feed/publishLiveObject');
