import { ActionWithPayload } from '../../../../types';

export const TOGGLE_MOMENTS_AUTOPOST = 'TOGGLE_MOMENTS_AUTOPOST';

export type ToggleMomentsAutopost = ActionWithPayload<typeof TOGGLE_MOMENTS_AUTOPOST, boolean>;

// Action Creators
export const toggleMomentsAutopost = (payload: boolean): ToggleMomentsAutopost => ({
  type: TOGGLE_MOMENTS_AUTOPOST,
  payload,
});
