import React, { FunctionComponent } from 'react';
import { Icon } from './styles';
import { getFirstInitial, getAvatarColor } from './utils';
import { AvatarSizeType } from './types';
import AvatarOverlay from './AvatarOverlay';
import { useTheme } from 'styled-components';

interface Props {
  disabled: boolean;
  isActionable: boolean;
  nickname: string;
  size: AvatarSizeType;
  tabIndex: number;
  subscriberId: string;
}

const InitialAvatar: FunctionComponent<Props> = ({
  disabled,
  isActionable,
  nickname,
  size,
  tabIndex,
  subscriberId,
}) => {
  const theme = useTheme();
  return (
    <>
      {isActionable && <AvatarOverlay size={size} />}
      <Icon
        data-testid={`avatar-${nickname}`}
        isActionable={isActionable}
        role='img'
        size={size}
        style={{ backgroundColor: disabled ? theme.colors.gray30 : getAvatarColor(subscriberId) }}
        tabIndex={tabIndex}
      >
        {getFirstInitial(nickname)}
      </Icon>
    </>
  );
};

export default React.memo(InitialAvatar);
