import styled from 'styled-components';
import { Flex } from '@lifechurch/react-ion';
export const QuoteWrapper = styled(Flex) `
  position: relative;
  flex-direction: row;
  padding: 16px;
  background-color: ${props => props.theme.colors.gray5};
  border: ${props => `1px solid ${props.theme.colors.gray10}`};
  border-radius: 1px;

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
`;
