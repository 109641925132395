import styled, { keyframes } from 'styled-components';
const slideUp = keyframes `
  0% {
    opacity: 1;
    transform: translateY(-40px);
  }
  
  100% {
    opacity: 0;
    transform: translateY(-240px);
  }
`;
export const Symbol = styled.div `
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[6]};
  animation: ${slideUp} 1500ms ease-out;
`;
