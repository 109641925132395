import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CheckIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M13.3536 3.85353L5.5 11.7071L1.64645 7.85353L2.35355 7.14642L5.5 10.2929L12.6464 3.14642L13.3536 3.85353Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M17.7071 5.70712L8 15.4142L2.29289 9.70712L3.70711 8.29291L8 12.5858L16.2929 4.29291L17.7071 5.70712Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M21.7071 6.70712L9.5 18.9142L2.29289 11.7071L3.70711 10.2929L9.5 16.0858L20.2929 5.29291L21.7071 6.70712Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M28.7071 8.70712L12.5 24.9142L3.29289 15.7071L4.70711 14.2929L12.5 22.0858L27.2929 7.29291L28.7071 8.70712Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
  }
};
