import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ClosedLockIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 4C8.34315 4 7 5.34315 7 7V8H13V7C13 5.34315 11.6569 4 10 4ZM5 7V8H3V18H17V8H15V7C15 4.23858 12.7614 2 10 2C7.23858 2 5 4.23858 5 7ZM10 13.5C10.8284 13.5 11.5 12.8284 11.5 12C11.5 11.1716 10.8284 10.5 10 10.5C9.17157 10.5 8.5 11.1716 8.5 12C8.5 12.8284 9.17157 13.5 10 13.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8V9H8V8ZM6 9V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V9H21V22H3V9H6ZM13 15.7324C13.5978 15.3866 14 14.7403 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 14.7403 10.4022 15.3866 11 15.7324V18H13V15.7324Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M9 11C9 7.13401 12.134 4 16 4C19.866 4 23 7.13401 23 11V13H9V11ZM7 13H4V29H28V13H25V11C25 6.02944 20.9706 2 16 2C11.0294 2 7 6.02944 7 11V13ZM17 21.292C17.883 20.9062 18.5 20.0252 18.5 19C18.5 17.6193 17.3807 16.5 16 16.5C14.6193 16.5 13.5 17.6193 13.5 19C13.5 20.0252 14.117 20.9062 15 21.292V25H17V21.292Z'
          size={size}
          {...rest}
        />
      );
  }
};
