import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import {
  CurrentState_currentOrganization_integrations,
  CurrentState_currentOrganization_integrations_FacebookPixel,
} from '@io/__generated__/CurrentState';
import { Subscriber } from '../../../types';
import { getIntegrations } from '@store/organizationSlice/selectors';
import { select } from '@redux-saga/core/effects';
import { setSubscriber as setSubscriberAction } from '@store/subscriberSlice';

export function initFacebookPixel(
  currentSubscriber: Subscriber,
  integration: CurrentState_currentOrganization_integrations_FacebookPixel
) {
  const advancedMatching = {
    uid: currentSubscriber.id,
    em: currentSubscriber.email || '',
  };

  const options = {
    autoConfig: true,
    debug: false,
  };

  if (currentSubscriber.emailConsent === false) {
    // ReactPixel.revokeConsent(); - https://github.com/zsajjad/react-facebook-pixel/issues/40#issuecomment-623149686
    ReactPixel.fbq('consent', 'revoke');
  }

  ReactPixel.init(
    integration.config.pixelId,
    advancedMatching as unknown as AdvancedMatching,
    options
  );

  ReactPixel.pageView();
}

export function* setFacebookPixelUser(action: ReturnType<typeof setSubscriberAction>) {
  const integrations: Array<CurrentState_currentOrganization_integrations> = yield select(
    getIntegrations
  );
  const integration = integrations.find(
    integration => integration.__typename === 'FacebookPixel'
  ) as CurrentState_currentOrganization_integrations_FacebookPixel | undefined;

  const advancedMatching = {
    uid: action.payload.id,
    em: action.payload.email || '',
  };

  if (integration?.config?.pixelId) {
    ReactPixel.fbq('setUserProperties', integration.config.pixelId, advancedMatching);
    if (action.payload.emailConsent === true) {
      // ReactPixel.grantConsent(); - https://github.com/zsajjad/react-facebook-pixel/issues/40#issuecomment-623149686
      ReactPixel.fbq('consent', 'grant');
    } else {
      // ReactPixel.revokeConsent(); - https://github.com/zsajjad/react-facebook-pixel/issues/40#issuecomment-623149686
      ReactPixel.fbq('consent', 'revoke');
    }
  }
}
