interface TimezoneData {
  value: string;
  display: string;
  utcOffset: number;
}

export const useTimezoneData = (): Record<string, Array<TimezoneData>> => ({
  Africa: [
    {
      value: 'Africa/Abidjan',
      display: 'Abidjan',
      utcOffset: 0,
    },
    {
      value: 'Africa/Accra',
      display: 'Accra',
      utcOffset: 0,
    },
    {
      value: 'Africa/Nairobi',
      display: 'Nairobi',
      utcOffset: 180,
    },
    {
      value: 'Africa/Algiers',
      display: 'Algiers',
      utcOffset: 60,
    },
    {
      value: 'Africa/Lagos',
      display: 'Lagos',
      utcOffset: 60,
    },
    {
      value: 'Africa/Bissau',
      display: 'Bissau',
      utcOffset: 0,
    },
    {
      value: 'Africa/Maputo',
      display: 'Maputo',
      utcOffset: 120,
    },
    {
      value: 'Africa/Cairo',
      display: 'Cairo',
      utcOffset: 120,
    },
    {
      value: 'Africa/Casablanca',
      display: 'Casablanca',
      utcOffset: 60,
    },
    {
      value: 'Africa/Ceuta',
      display: 'Ceuta',
      utcOffset: 60,
    },
    {
      value: 'Africa/El_Aaiun',
      display: 'El Aaiun',
      utcOffset: 60,
    },
    {
      value: 'Africa/Johannesburg',
      display: 'Johannesburg',
      utcOffset: 120,
    },
    {
      value: 'Africa/Juba',
      display: 'Juba',
      utcOffset: 180,
    },
    {
      value: 'Africa/Khartoum',
      display: 'Khartoum',
      utcOffset: 120,
    },
    {
      value: 'Africa/Monrovia',
      display: 'Monrovia',
      utcOffset: 0,
    },
    {
      value: 'Africa/Ndjamena',
      display: 'Ndjamena',
      utcOffset: 60,
    },
    {
      value: 'Africa/Sao_Tome',
      display: 'Sao Tome',
      utcOffset: 0,
    },
    {
      value: 'Africa/Tripoli',
      display: 'Tripoli',
      utcOffset: 120,
    },
    {
      value: 'Africa/Tunis',
      display: 'Tunis',
      utcOffset: 60,
    },
    {
      value: 'Africa/Windhoek',
      display: 'Windhoek',
      utcOffset: 120,
    },
  ],
  America: [
    {
      value: 'America/Adak',
      display: 'Adak',
      utcOffset: -540,
    },
    {
      value: 'America/Anchorage',
      display: 'Anchorage',
      utcOffset: -480,
    },
    {
      value: 'America/Port_of_Spain',
      display: 'Port of Spain',
      utcOffset: -240,
    },
    {
      value: 'America/Araguaina',
      display: 'Araguaina',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Buenos_Aires',
      display: 'Argentina Buenos Aires',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Catamarca',
      display: 'Argentina Catamarca',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Cordoba',
      display: 'Argentina Cordoba',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Jujuy',
      display: 'Argentina Jujuy',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/La_Rioja',
      display: 'Argentina La Rioja',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Mendoza',
      display: 'Argentina Mendoza',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Rio_Gallegos',
      display: 'Argentina Rio Gallegos',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Salta',
      display: 'Argentina Salta',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/San_Juan',
      display: 'Argentina San Juan',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/San_Luis',
      display: 'Argentina San Luis',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Tucuman',
      display: 'Argentina Tucuman',
      utcOffset: -180,
    },
    {
      value: 'America/Argentina/Ushuaia',
      display: 'Argentina Ushuaia',
      utcOffset: -180,
    },
    {
      value: 'America/Curacao',
      display: 'Curacao',
      utcOffset: -240,
    },
    {
      value: 'America/Asuncion',
      display: 'Asuncion',
      utcOffset: -240,
    },
    {
      value: 'America/Atikokan',
      display: 'Atikokan',
      utcOffset: -300,
    },
    {
      value: 'America/Bahia_Banderas',
      display: 'Bahia Banderas',
      utcOffset: -360,
    },
    {
      value: 'America/Bahia',
      display: 'Bahia',
      utcOffset: -180,
    },
    {
      value: 'America/Barbados',
      display: 'Barbados',
      utcOffset: -240,
    },
    {
      value: 'America/Belem',
      display: 'Belem',
      utcOffset: -180,
    },
    {
      value: 'America/Belize',
      display: 'Belize',
      utcOffset: -360,
    },
    {
      value: 'America/Blanc-Sablon',
      display: 'Blanc-Sablon',
      utcOffset: -240,
    },
    {
      value: 'America/Boa_Vista',
      display: 'Boa Vista',
      utcOffset: -240,
    },
    {
      value: 'America/Bogota',
      display: 'Bogota',
      utcOffset: -300,
    },
    {
      value: 'America/Boise',
      display: 'Boise',
      utcOffset: -360,
    },
    {
      value: 'America/Cambridge_Bay',
      display: 'Cambridge Bay',
      utcOffset: -360,
    },
    {
      value: 'America/Campo_Grande',
      display: 'Campo Grande',
      utcOffset: -240,
    },
    {
      value: 'America/Cancun',
      display: 'Cancun',
      utcOffset: -300,
    },
    {
      value: 'America/Caracas',
      display: 'Caracas',
      utcOffset: -240,
    },
    {
      value: 'America/Cayenne',
      display: 'Cayenne',
      utcOffset: -180,
    },
    {
      value: 'America/Panama',
      display: 'Panama',
      utcOffset: -300,
    },
    {
      value: 'America/Chicago',
      display: 'Chicago',
      utcOffset: -300,
    },
    {
      value: 'America/Chihuahua',
      display: 'Chihuahua',
      utcOffset: -420,
    },
    {
      value: 'America/Costa_Rica',
      display: 'Costa Rica',
      utcOffset: -360,
    },
    {
      value: 'America/Creston',
      display: 'Creston',
      utcOffset: -420,
    },
    {
      value: 'America/Cuiaba',
      display: 'Cuiaba',
      utcOffset: -240,
    },
    {
      value: 'America/Danmarkshavn',
      display: 'Danmarkshavn',
      utcOffset: 0,
    },
    {
      value: 'America/Dawson_Creek',
      display: 'Dawson Creek',
      utcOffset: -420,
    },
    {
      value: 'America/Dawson',
      display: 'Dawson',
      utcOffset: -420,
    },
    {
      value: 'America/Denver',
      display: 'Denver',
      utcOffset: -360,
    },
    {
      value: 'America/Detroit',
      display: 'Detroit',
      utcOffset: -240,
    },
    {
      value: 'America/Edmonton',
      display: 'Edmonton',
      utcOffset: -360,
    },
    {
      value: 'America/Eirunepe',
      display: 'Eirunepe',
      utcOffset: -300,
    },
    {
      value: 'America/El_Salvador',
      display: 'El Salvador',
      utcOffset: -360,
    },
    {
      value: 'America/Tijuana',
      display: 'Tijuana',
      utcOffset: -420,
    },
    {
      value: 'America/Fort_Nelson',
      display: 'Fort Nelson',
      utcOffset: -420,
    },
    {
      value: 'America/Fort_Wayne',
      display: 'Fort Wayne',
      utcOffset: -240,
    },
    {
      value: 'America/Fortaleza',
      display: 'Fortaleza',
      utcOffset: -180,
    },
    {
      value: 'America/Glace_Bay',
      display: 'Glace Bay',
      utcOffset: -180,
    },
    {
      value: 'America/Godthab',
      display: 'Godthab',
      utcOffset: -180,
    },
    {
      value: 'America/Goose_Bay',
      display: 'Goose Bay',
      utcOffset: -180,
    },
    {
      value: 'America/Grand_Turk',
      display: 'Grand Turk',
      utcOffset: -240,
    },
    {
      value: 'America/Guatemala',
      display: 'Guatemala',
      utcOffset: -360,
    },
    {
      value: 'America/Guayaquil',
      display: 'Guayaquil',
      utcOffset: -300,
    },
    {
      value: 'America/Guyana',
      display: 'Guyana',
      utcOffset: -240,
    },
    {
      value: 'America/Halifax',
      display: 'Halifax',
      utcOffset: -180,
    },
    {
      value: 'America/Havana',
      display: 'Havana',
      utcOffset: -240,
    },
    {
      value: 'America/Hermosillo',
      display: 'Hermosillo',
      utcOffset: -420,
    },
    {
      value: 'America/Indiana/Knox',
      display: 'Indiana Knox',
      utcOffset: -300,
    },
    {
      value: 'America/Indiana/Marengo',
      display: 'Indiana Marengo',
      utcOffset: -240,
    },
    {
      value: 'America/Indiana/Petersburg',
      display: 'Indiana Petersburg',
      utcOffset: -240,
    },
    {
      value: 'America/Indiana/Tell_City',
      display: 'Indiana Tell City',
      utcOffset: -300,
    },
    {
      value: 'America/Indiana/Vevay',
      display: 'Indiana Vevay',
      utcOffset: -240,
    },
    {
      value: 'America/Indiana/Vincennes',
      display: 'Indiana Vincennes',
      utcOffset: -240,
    },
    {
      value: 'America/Indiana/Winamac',
      display: 'Indiana Winamac',
      utcOffset: -240,
    },
    {
      value: 'America/Inuvik',
      display: 'Inuvik',
      utcOffset: -360,
    },
    {
      value: 'America/Iqaluit',
      display: 'Iqaluit',
      utcOffset: -240,
    },
    {
      value: 'America/Jamaica',
      display: 'Jamaica',
      utcOffset: -300,
    },
    {
      value: 'America/Juneau',
      display: 'Juneau',
      utcOffset: -480,
    },
    {
      value: 'America/Kentucky/Louisville',
      display: 'Kentucky Louisville',
      utcOffset: -240,
    },
    {
      value: 'America/Kentucky/Monticello',
      display: 'Kentucky Monticello',
      utcOffset: -240,
    },
    {
      value: 'America/La_Paz',
      display: 'La Paz',
      utcOffset: -240,
    },
    {
      value: 'America/Lima',
      display: 'Lima',
      utcOffset: -300,
    },
    {
      value: 'America/Los_Angeles',
      display: 'Los Angeles',
      utcOffset: -420,
    },
    {
      value: 'America/Maceio',
      display: 'Maceio',
      utcOffset: -180,
    },
    {
      value: 'America/Managua',
      display: 'Managua',
      utcOffset: -360,
    },
    {
      value: 'America/Manaus',
      display: 'Manaus',
      utcOffset: -240,
    },
    {
      value: 'America/Martinique',
      display: 'Martinique',
      utcOffset: -240,
    },
    {
      value: 'America/Matamoros',
      display: 'Matamoros',
      utcOffset: -300,
    },
    {
      value: 'America/Mazatlan',
      display: 'Mazatlan',
      utcOffset: -420,
    },
    {
      value: 'America/Menominee',
      display: 'Menominee',
      utcOffset: -300,
    },
    {
      value: 'America/Merida',
      display: 'Merida',
      utcOffset: -360,
    },
    {
      value: 'America/Metlakatla',
      display: 'Metlakatla',
      utcOffset: -480,
    },
    {
      value: 'America/Mexico_City',
      display: 'Mexico City',
      utcOffset: -360,
    },
    {
      value: 'America/Miquelon',
      display: 'Miquelon',
      utcOffset: -120,
    },
    {
      value: 'America/Moncton',
      display: 'Moncton',
      utcOffset: -180,
    },
    {
      value: 'America/Monterrey',
      display: 'Monterrey',
      utcOffset: -360,
    },
    {
      value: 'America/Montevideo',
      display: 'Montevideo',
      utcOffset: -180,
    },
    {
      value: 'America/Toronto',
      display: 'Toronto',
      utcOffset: -240,
    },
    {
      value: 'America/Nassau',
      display: 'Nassau',
      utcOffset: -240,
    },
    {
      value: 'America/New_York',
      display: 'New York',
      utcOffset: -240,
    },
    {
      value: 'America/Nipigon',
      display: 'Nipigon',
      utcOffset: -240,
    },
    {
      value: 'America/Nome',
      display: 'Nome',
      utcOffset: -480,
    },
    {
      value: 'America/Noronha',
      display: 'Noronha',
      utcOffset: -120,
    },
    {
      value: 'America/North_Dakota/Beulah',
      display: 'North Dakota Beulah',
      utcOffset: -300,
    },
    {
      value: 'America/North_Dakota/Center',
      display: 'North Dakota Center',
      utcOffset: -300,
    },
    {
      value: 'America/North_Dakota/New_Salem',
      display: 'North Dakota New Salem',
      utcOffset: -300,
    },
    {
      value: 'America/Ojinaga',
      display: 'Ojinaga',
      utcOffset: -360,
    },
    {
      value: 'America/Pangnirtung',
      display: 'Pangnirtung',
      utcOffset: -240,
    },
    {
      value: 'America/Paramaribo',
      display: 'Paramaribo',
      utcOffset: -180,
    },
    {
      value: 'America/Phoenix',
      display: 'Phoenix',
      utcOffset: -420,
    },
    {
      value: 'America/Port-au-Prince',
      display: 'Port-au-Prince',
      utcOffset: -240,
    },
    {
      value: 'America/Rio_Branco',
      display: 'Rio Branco',
      utcOffset: -300,
    },
    {
      value: 'America/Porto_Velho',
      display: 'Porto Velho',
      utcOffset: -240,
    },
    {
      value: 'America/Puerto_Rico',
      display: 'Puerto Rico',
      utcOffset: -240,
    },
    {
      value: 'America/Punta_Arenas',
      display: 'Punta Arenas',
      utcOffset: -180,
    },
    {
      value: 'America/Rainy_River',
      display: 'Rainy River',
      utcOffset: -300,
    },
    {
      value: 'America/Rankin_Inlet',
      display: 'Rankin Inlet',
      utcOffset: -300,
    },
    {
      value: 'America/Recife',
      display: 'Recife',
      utcOffset: -180,
    },
    {
      value: 'America/Regina',
      display: 'Regina',
      utcOffset: -360,
    },
    {
      value: 'America/Resolute',
      display: 'Resolute',
      utcOffset: -300,
    },
    {
      value: 'America/Santarem',
      display: 'Santarem',
      utcOffset: -180,
    },
    {
      value: 'America/Santiago',
      display: 'Santiago',
      utcOffset: -180,
    },
    {
      value: 'America/Santo_Domingo',
      display: 'Santo Domingo',
      utcOffset: -240,
    },
    {
      value: 'America/Sao_Paulo',
      display: 'Sao Paulo',
      utcOffset: -180,
    },
    {
      value: 'America/Scoresbysund',
      display: 'Scoresbysund',
      utcOffset: -60,
    },
    {
      value: 'America/Sitka',
      display: 'Sitka',
      utcOffset: -480,
    },
    {
      value: 'America/St_Johns',
      display: 'St Johns',
      utcOffset: -150,
    },
    {
      value: 'America/Swift_Current',
      display: 'Swift Current',
      utcOffset: -360,
    },
    {
      value: 'America/Tegucigalpa',
      display: 'Tegucigalpa',
      utcOffset: -360,
    },
    {
      value: 'America/Thule',
      display: 'Thule',
      utcOffset: -180,
    },
    {
      value: 'America/Thunder_Bay',
      display: 'Thunder Bay',
      utcOffset: -240,
    },
    {
      value: 'America/Vancouver',
      display: 'Vancouver',
      utcOffset: -420,
    },
    {
      value: 'America/Whitehorse',
      display: 'Whitehorse',
      utcOffset: -420,
    },
    {
      value: 'America/Winnipeg',
      display: 'Winnipeg',
      utcOffset: -300,
    },
    {
      value: 'America/Yakutat',
      display: 'Yakutat',
      utcOffset: -480,
    },
    {
      value: 'America/Yellowknife',
      display: 'Yellowknife',
      utcOffset: -360,
    },
  ],
  Antarctica: [
    {
      value: 'Antarctica/Casey',
      display: 'Casey',
      utcOffset: 480,
    },
    {
      value: 'Antarctica/Davis',
      display: 'Davis',
      utcOffset: 420,
    },
    {
      value: 'Antarctica/DumontDUrville',
      display: 'DumontDUrville',
      utcOffset: 600,
    },
    {
      value: 'Antarctica/Macquarie',
      display: 'Macquarie',
      utcOffset: 660,
    },
    {
      value: 'Antarctica/Mawson',
      display: 'Mawson',
      utcOffset: 300,
    },
    {
      value: 'Antarctica/Palmer',
      display: 'Palmer',
      utcOffset: -180,
    },
    {
      value: 'Antarctica/Rothera',
      display: 'Rothera',
      utcOffset: -180,
    },
    {
      value: 'Antarctica/Syowa',
      display: 'Syowa',
      utcOffset: 180,
    },
    {
      value: 'Antarctica/Troll',
      display: 'Troll',
      utcOffset: 0,
    },
    {
      value: 'Antarctica/Vostok',
      display: 'Vostok',
      utcOffset: 360,
    },
  ],
  Pacific: [
    {
      value: 'Pacific/Auckland',
      display: 'Auckland',
      utcOffset: 780,
    },
    {
      value: 'Pacific/Easter',
      display: 'Easter',
      utcOffset: -300,
    },
    {
      value: 'Pacific/Kwajalein',
      display: 'Kwajalein',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Chatham',
      display: 'Chatham',
      utcOffset: 825,
    },
    {
      value: 'Pacific/Apia',
      display: 'Apia',
      utcOffset: 840,
    },
    {
      value: 'Pacific/Bougainville',
      display: 'Bougainville',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Chuuk',
      display: 'Chuuk',
      utcOffset: 600,
    },
    {
      value: 'Pacific/Efate',
      display: 'Efate',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Enderbury',
      display: 'Enderbury',
      utcOffset: 780,
    },
    {
      value: 'Pacific/Fakaofo',
      display: 'Fakaofo',
      utcOffset: 780,
    },
    {
      value: 'Pacific/Fiji',
      display: 'Fiji',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Funafuti',
      display: 'Funafuti',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Galapagos',
      display: 'Galapagos',
      utcOffset: -360,
    },
    {
      value: 'Pacific/Gambier',
      display: 'Gambier',
      utcOffset: -540,
    },
    {
      value: 'Pacific/Guadalcanal',
      display: 'Guadalcanal',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Guam',
      display: 'Guam',
      utcOffset: 600,
    },
    {
      value: 'Pacific/Honolulu',
      display: 'Honolulu',
      utcOffset: -600,
    },
    {
      value: 'Pacific/Kiritimati',
      display: 'Kiritimati',
      utcOffset: 840,
    },
    {
      value: 'Pacific/Kosrae',
      display: 'Kosrae',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Majuro',
      display: 'Majuro',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Marquesas',
      display: 'Marquesas',
      utcOffset: -570,
    },
    {
      value: 'Pacific/Pago_Pago',
      display: 'Pago Pago',
      utcOffset: -660,
    },
    {
      value: 'Pacific/Nauru',
      display: 'Nauru',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Niue',
      display: 'Niue',
      utcOffset: -660,
    },
    {
      value: 'Pacific/Norfolk',
      display: 'Norfolk',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Noumea',
      display: 'Noumea',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Palau',
      display: 'Palau',
      utcOffset: 540,
    },
    {
      value: 'Pacific/Pitcairn',
      display: 'Pitcairn',
      utcOffset: -480,
    },
    {
      value: 'Pacific/Pohnpei',
      display: 'Pohnpei',
      utcOffset: 660,
    },
    {
      value: 'Pacific/Port_Moresby',
      display: 'Port Moresby',
      utcOffset: 600,
    },
    {
      value: 'Pacific/Rarotonga',
      display: 'Rarotonga',
      utcOffset: -600,
    },
    {
      value: 'Pacific/Tahiti',
      display: 'Tahiti',
      utcOffset: -600,
    },
    {
      value: 'Pacific/Tarawa',
      display: 'Tarawa',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Tongatapu',
      display: 'Tongatapu',
      utcOffset: 780,
    },
    {
      value: 'Pacific/Wake',
      display: 'Wake',
      utcOffset: 720,
    },
    {
      value: 'Pacific/Wallis',
      display: 'Wallis',
      utcOffset: 720,
    },
  ],
  Europe: [
    {
      value: 'Europe/Oslo',
      display: 'Oslo',
      utcOffset: 60,
    },
    {
      value: 'Europe/Istanbul',
      display: 'Istanbul',
      utcOffset: 180,
    },
    {
      value: 'Europe/Dublin',
      display: 'Dublin',
      utcOffset: 0,
    },
    {
      value: 'Europe/Amsterdam',
      display: 'Amsterdam',
      utcOffset: 60,
    },
    {
      value: 'Europe/Andorra',
      display: 'Andorra',
      utcOffset: 60,
    },
    {
      value: 'Europe/Astrakhan',
      display: 'Astrakhan',
      utcOffset: 240,
    },
    {
      value: 'Europe/Athens',
      display: 'Athens',
      utcOffset: 120,
    },
    {
      value: 'Europe/London',
      display: 'London',
      utcOffset: 0,
    },
    {
      value: 'Europe/Belgrade',
      display: 'Belgrade',
      utcOffset: 60,
    },
    {
      value: 'Europe/Berlin',
      display: 'Berlin',
      utcOffset: 60,
    },
    {
      value: 'Europe/Prague',
      display: 'Prague',
      utcOffset: 60,
    },
    {
      value: 'Europe/Brussels',
      display: 'Brussels',
      utcOffset: 60,
    },
    {
      value: 'Europe/Bucharest',
      display: 'Bucharest',
      utcOffset: 120,
    },
    {
      value: 'Europe/Budapest',
      display: 'Budapest',
      utcOffset: 60,
    },
    {
      value: 'Europe/Zurich',
      display: 'Zurich',
      utcOffset: 60,
    },
    {
      value: 'Europe/Chisinau',
      display: 'Chisinau',
      utcOffset: 120,
    },
    {
      value: 'Europe/Copenhagen',
      display: 'Copenhagen',
      utcOffset: 60,
    },
    {
      value: 'Europe/Gibraltar',
      display: 'Gibraltar',
      utcOffset: 60,
    },
    {
      value: 'Europe/Helsinki',
      display: 'Helsinki',
      utcOffset: 120,
    },
    {
      value: 'Europe/Kaliningrad',
      display: 'Kaliningrad',
      utcOffset: 120,
    },
    {
      value: 'Europe/Kiev',
      display: 'Kiev',
      utcOffset: 120,
    },
    {
      value: 'Europe/Kirov',
      display: 'Kirov',
      utcOffset: 180,
    },
    {
      value: 'Europe/Lisbon',
      display: 'Lisbon',
      utcOffset: 0,
    },
    {
      value: 'Europe/Luxembourg',
      display: 'Luxembourg',
      utcOffset: 60,
    },
    {
      value: 'Europe/Madrid',
      display: 'Madrid',
      utcOffset: 60,
    },
    {
      value: 'Europe/Malta',
      display: 'Malta',
      utcOffset: 60,
    },
    {
      value: 'Europe/Minsk',
      display: 'Minsk',
      utcOffset: 180,
    },
    {
      value: 'Europe/Monaco',
      display: 'Monaco',
      utcOffset: 60,
    },
    {
      value: 'Europe/Moscow',
      display: 'Moscow',
      utcOffset: 180,
    },
    {
      value: 'Europe/Paris',
      display: 'Paris',
      utcOffset: 60,
    },
    {
      value: 'Europe/Riga',
      display: 'Riga',
      utcOffset: 120,
    },
    {
      value: 'Europe/Rome',
      display: 'Rome',
      utcOffset: 60,
    },
    {
      value: 'Europe/Samara',
      display: 'Samara',
      utcOffset: 240,
    },
    {
      value: 'Europe/Saratov',
      display: 'Saratov',
      utcOffset: 240,
    },
    {
      value: 'Europe/Simferopol',
      display: 'Simferopol',
      utcOffset: 180,
    },
    {
      value: 'Europe/Sofia',
      display: 'Sofia',
      utcOffset: 120,
    },
    {
      value: 'Europe/Stockholm',
      display: 'Stockholm',
      utcOffset: 60,
    },
    {
      value: 'Europe/Tallinn',
      display: 'Tallinn',
      utcOffset: 120,
    },
    {
      value: 'Europe/Tirane',
      display: 'Tirane',
      utcOffset: 60,
    },
    {
      value: 'Europe/Ulyanovsk',
      display: 'Ulyanovsk',
      utcOffset: 240,
    },
    {
      value: 'Europe/Uzhgorod',
      display: 'Uzhgorod',
      utcOffset: 120,
    },
    {
      value: 'Europe/Vienna',
      display: 'Vienna',
      utcOffset: 60,
    },
    {
      value: 'Europe/Vilnius',
      display: 'Vilnius',
      utcOffset: 120,
    },
    {
      value: 'Europe/Volgograd',
      display: 'Volgograd',
      utcOffset: 240,
    },
    {
      value: 'Europe/Warsaw',
      display: 'Warsaw',
      utcOffset: 60,
    },
    {
      value: 'Europe/Zaporozhye',
      display: 'Zaporozhye',
      utcOffset: 120,
    },
  ],
  Asia: [
    {
      value: 'Asia/Riyadh',
      display: 'Riyadh',
      utcOffset: 180,
    },
    {
      value: 'Asia/Almaty',
      display: 'Almaty',
      utcOffset: 360,
    },
    {
      value: 'Asia/Amman',
      display: 'Amman',
      utcOffset: 180,
    },
    {
      value: 'Asia/Anadyr',
      display: 'Anadyr',
      utcOffset: 720,
    },
    {
      value: 'Asia/Aqtau',
      display: 'Aqtau',
      utcOffset: 300,
    },
    {
      value: 'Asia/Aqtobe',
      display: 'Aqtobe',
      utcOffset: 300,
    },
    {
      value: 'Asia/Ashgabat',
      display: 'Ashgabat',
      utcOffset: 300,
    },
    {
      value: 'Asia/Atyrau',
      display: 'Atyrau',
      utcOffset: 300,
    },
    {
      value: 'Asia/Baghdad',
      display: 'Baghdad',
      utcOffset: 180,
    },
    {
      value: 'Asia/Qatar',
      display: 'Qatar',
      utcOffset: 180,
    },
    {
      value: 'Asia/Baku',
      display: 'Baku',
      utcOffset: 240,
    },
    {
      value: 'Asia/Bangkok',
      display: 'Bangkok',
      utcOffset: 420,
    },
    {
      value: 'Asia/Barnaul',
      display: 'Barnaul',
      utcOffset: 420,
    },
    {
      value: 'Asia/Beirut',
      display: 'Beirut',
      utcOffset: 120,
    },
    {
      value: 'Asia/Bishkek',
      display: 'Bishkek',
      utcOffset: 360,
    },
    {
      value: 'Asia/Brunei',
      display: 'Brunei',
      utcOffset: 480,
    },
    {
      value: 'Asia/Kolkata',
      display: 'Kolkata',
      utcOffset: 330,
    },
    {
      value: 'Asia/Chita',
      display: 'Chita',
      utcOffset: 540,
    },
    {
      value: 'Asia/Choibalsan',
      display: 'Choibalsan',
      utcOffset: 480,
    },
    {
      value: 'Asia/Shanghai',
      display: 'Shanghai',
      utcOffset: 480,
    },
    {
      value: 'Asia/Colombo',
      display: 'Colombo',
      utcOffset: 330,
    },
    {
      value: 'Asia/Dhaka',
      display: 'Dhaka',
      utcOffset: 360,
    },
    {
      value: 'Asia/Damascus',
      display: 'Damascus',
      utcOffset: 180,
    },
    {
      value: 'Asia/Dili',
      display: 'Dili',
      utcOffset: 540,
    },
    {
      value: 'Asia/Dubai',
      display: 'Dubai',
      utcOffset: 240,
    },
    {
      value: 'Asia/Dushanbe',
      display: 'Dushanbe',
      utcOffset: 300,
    },
    {
      value: 'Asia/Famagusta',
      display: 'Famagusta',
      utcOffset: 120,
    },
    {
      value: 'Asia/Gaza',
      display: 'Gaza',
      utcOffset: 180,
    },
    {
      value: 'Asia/Hebron',
      display: 'Hebron',
      utcOffset: 180,
    },
    {
      value: 'Asia/Ho_Chi_Minh',
      display: 'Ho Chi Minh',
      utcOffset: 420,
    },
    {
      value: 'Asia/Hong_Kong',
      display: 'Hong Kong',
      utcOffset: 480,
    },
    {
      value: 'Asia/Hovd',
      display: 'Hovd',
      utcOffset: 420,
    },
    {
      value: 'Asia/Irkutsk',
      display: 'Irkutsk',
      utcOffset: 480,
    },
    {
      value: 'Asia/Jakarta',
      display: 'Jakarta',
      utcOffset: 420,
    },
    {
      value: 'Asia/Jayapura',
      display: 'Jayapura',
      utcOffset: 540,
    },
    {
      value: 'Asia/Jerusalem',
      display: 'Jerusalem',
      utcOffset: 180,
    },
    {
      value: 'Asia/Kabul',
      display: 'Kabul',
      utcOffset: 270,
    },
    {
      value: 'Asia/Kamchatka',
      display: 'Kamchatka',
      utcOffset: 720,
    },
    {
      value: 'Asia/Karachi',
      display: 'Karachi',
      utcOffset: 300,
    },
    {
      value: 'Asia/Urumqi',
      display: 'Urumqi',
      utcOffset: 360,
    },
    {
      value: 'Asia/Kathmandu',
      display: 'Kathmandu',
      utcOffset: 345,
    },
    {
      value: 'Asia/Khandyga',
      display: 'Khandyga',
      utcOffset: 540,
    },
    {
      value: 'Asia/Krasnoyarsk',
      display: 'Krasnoyarsk',
      utcOffset: 420,
    },
    {
      value: 'Asia/Kuala_Lumpur',
      display: 'Kuala Lumpur',
      utcOffset: 480,
    },
    {
      value: 'Asia/Kuching',
      display: 'Kuching',
      utcOffset: 480,
    },
    {
      value: 'Asia/Macau',
      display: 'Macau',
      utcOffset: 480,
    },
    {
      value: 'Asia/Magadan',
      display: 'Magadan',
      utcOffset: 660,
    },
    {
      value: 'Asia/Makassar',
      display: 'Makassar',
      utcOffset: 480,
    },
    {
      value: 'Asia/Manila',
      display: 'Manila',
      utcOffset: 480,
    },
    {
      value: 'Asia/Nicosia',
      display: 'Nicosia',
      utcOffset: 120,
    },
    {
      value: 'Asia/Novokuznetsk',
      display: 'Novokuznetsk',
      utcOffset: 420,
    },
    {
      value: 'Asia/Novosibirsk',
      display: 'Novosibirsk',
      utcOffset: 420,
    },
    {
      value: 'Asia/Omsk',
      display: 'Omsk',
      utcOffset: 360,
    },
    {
      value: 'Asia/Oral',
      display: 'Oral',
      utcOffset: 300,
    },
    {
      value: 'Asia/Pontianak',
      display: 'Pontianak',
      utcOffset: 420,
    },
    {
      value: 'Asia/Pyongyang',
      display: 'Pyongyang',
      utcOffset: 540,
    },
    {
      value: 'Asia/Qostanay',
      display: 'Qostanay',
      utcOffset: 360,
    },
    {
      value: 'Asia/Qyzylorda',
      display: 'Qyzylorda',
      utcOffset: 300,
    },
    {
      value: 'Asia/Rangoon',
      display: 'Rangoon',
      utcOffset: 390,
    },
    {
      value: 'Asia/Sakhalin',
      display: 'Sakhalin',
      utcOffset: 660,
    },
    {
      value: 'Asia/Samarkand',
      display: 'Samarkand',
      utcOffset: 300,
    },
    {
      value: 'Asia/Seoul',
      display: 'Seoul',
      utcOffset: 540,
    },
    {
      value: 'Asia/Singapore',
      display: 'Singapore',
      utcOffset: 480,
    },
    {
      value: 'Asia/Srednekolymsk',
      display: 'Srednekolymsk',
      utcOffset: 660,
    },
    {
      value: 'Asia/Taipei',
      display: 'Taipei',
      utcOffset: 480,
    },
    {
      value: 'Asia/Tashkent',
      display: 'Tashkent',
      utcOffset: 300,
    },
    {
      value: 'Asia/Tbilisi',
      display: 'Tbilisi',
      utcOffset: 240,
    },
    {
      value: 'Asia/Tehran',
      display: 'Tehran',
      utcOffset: 270,
    },
    {
      value: 'Asia/Thimphu',
      display: 'Thimphu',
      utcOffset: 360,
    },
    {
      value: 'Asia/Tokyo',
      display: 'Tokyo',
      utcOffset: 540,
    },
    {
      value: 'Asia/Tomsk',
      display: 'Tomsk',
      utcOffset: 420,
    },
    {
      value: 'Asia/Ulaanbaatar',
      display: 'Ulaanbaatar',
      utcOffset: 480,
    },
    {
      value: 'Asia/Ust-Nera',
      display: 'Ust-Nera',
      utcOffset: 600,
    },
    {
      value: 'Asia/Vladivostok',
      display: 'Vladivostok',
      utcOffset: 600,
    },
    {
      value: 'Asia/Yakutsk',
      display: 'Yakutsk',
      utcOffset: 540,
    },
    {
      value: 'Asia/Yekaterinburg',
      display: 'Yekaterinburg',
      utcOffset: 300,
    },
    {
      value: 'Asia/Yerevan',
      display: 'Yerevan',
      utcOffset: 240,
    },
  ],
  Atlantic: [
    {
      value: 'Atlantic/Azores',
      display: 'Azores',
      utcOffset: -60,
    },
    {
      value: 'Atlantic/Bermuda',
      display: 'Bermuda',
      utcOffset: -180,
    },
    {
      value: 'Atlantic/Canary',
      display: 'Canary',
      utcOffset: 0,
    },
    {
      value: 'Atlantic/Cape_Verde',
      display: 'Cape Verde',
      utcOffset: -60,
    },
    {
      value: 'Atlantic/Faroe',
      display: 'Faroe',
      utcOffset: 0,
    },
    {
      value: 'Atlantic/Madeira',
      display: 'Madeira',
      utcOffset: 0,
    },
    {
      value: 'Atlantic/Reykjavik',
      display: 'Reykjavik',
      utcOffset: 0,
    },
    {
      value: 'Atlantic/South_Georgia',
      display: 'South Georgia',
      utcOffset: -120,
    },
    {
      value: 'Atlantic/Stanley',
      display: 'Stanley',
      utcOffset: -180,
    },
  ],
  Australia: [
    {
      value: 'Australia/Sydney',
      display: 'Sydney',
      utcOffset: 660,
    },
    {
      value: 'Australia/Adelaide',
      display: 'Adelaide',
      utcOffset: 630,
    },
    {
      value: 'Australia/Brisbane',
      display: 'Brisbane',
      utcOffset: 600,
    },
    {
      value: 'Australia/Broken_Hill',
      display: 'Broken Hill',
      utcOffset: 630,
    },
    {
      value: 'Australia/Currie',
      display: 'Currie',
      utcOffset: 660,
    },
    {
      value: 'Australia/Darwin',
      display: 'Darwin',
      utcOffset: 570,
    },
    {
      value: 'Australia/Eucla',
      display: 'Eucla',
      utcOffset: 525,
    },
    {
      value: 'Australia/Hobart',
      display: 'Hobart',
      utcOffset: 660,
    },
    {
      value: 'Australia/Lord_Howe',
      display: 'Lord Howe',
      utcOffset: 660,
    },
    {
      value: 'Australia/Lindeman',
      display: 'Lindeman',
      utcOffset: 600,
    },
    {
      value: 'Australia/Melbourne',
      display: 'Melbourne',
      utcOffset: 660,
    },
    {
      value: 'Australia/Perth',
      display: 'Perth',
      utcOffset: 480,
    },
  ],
  CET: [
    {
      value: 'CET',
      display: 'CET',
      utcOffset: 60,
    },
  ],
  CST6CDT: [
    {
      value: 'CST6CDT',
      display: 'CST6CDT',
      utcOffset: -300,
    },
  ],
  EET: [
    {
      value: 'EET',
      display: 'EET',
      utcOffset: 120,
    },
  ],
  EST: [
    {
      value: 'EST',
      display: 'EST',
      utcOffset: -300,
    },
  ],
  EST5EDT: [
    {
      value: 'EST5EDT',
      display: 'EST5EDT',
      utcOffset: -240,
    },
  ],
  Etc: [
    {
      value: 'Etc/GMT-0',
      display: 'GMT-0',
      utcOffset: 0,
    },
    {
      value: 'Etc/GMT-1',
      display: 'GMT-1',
      utcOffset: 60,
    },
    {
      value: 'Etc/GMT-10',
      display: 'GMT-10',
      utcOffset: 600,
    },
    {
      value: 'Etc/GMT-11',
      display: 'GMT-11',
      utcOffset: 660,
    },
    {
      value: 'Etc/GMT-12',
      display: 'GMT-12',
      utcOffset: 720,
    },
    {
      value: 'Etc/GMT-13',
      display: 'GMT-13',
      utcOffset: 780,
    },
    {
      value: 'Etc/GMT-14',
      display: 'GMT-14',
      utcOffset: 840,
    },
    {
      value: 'Etc/GMT-2',
      display: 'GMT-2',
      utcOffset: 120,
    },
    {
      value: 'Etc/GMT-3',
      display: 'GMT-3',
      utcOffset: 180,
    },
    {
      value: 'Etc/GMT-4',
      display: 'GMT-4',
      utcOffset: 240,
    },
    {
      value: 'Etc/GMT-5',
      display: 'GMT-5',
      utcOffset: 300,
    },
    {
      value: 'Etc/GMT-6',
      display: 'GMT-6',
      utcOffset: 360,
    },
    {
      value: 'Etc/GMT-7',
      display: 'GMT-7',
      utcOffset: 420,
    },
    {
      value: 'Etc/GMT-8',
      display: 'GMT-8',
      utcOffset: 480,
    },
    {
      value: 'Etc/GMT-9',
      display: 'GMT-9',
      utcOffset: 540,
    },
    {
      value: 'Etc/GMT+1',
      display: 'GMT+1',
      utcOffset: -60,
    },
    {
      value: 'Etc/GMT+10',
      display: 'GMT+10',
      utcOffset: -600,
    },
    {
      value: 'Etc/GMT+11',
      display: 'GMT+11',
      utcOffset: -660,
    },
    {
      value: 'Etc/GMT+12',
      display: 'GMT+12',
      utcOffset: -720,
    },
    {
      value: 'Etc/GMT+2',
      display: 'GMT+2',
      utcOffset: -120,
    },
    {
      value: 'Etc/GMT+3',
      display: 'GMT+3',
      utcOffset: -180,
    },
    {
      value: 'Etc/GMT+4',
      display: 'GMT+4',
      utcOffset: -240,
    },
    {
      value: 'Etc/GMT+5',
      display: 'GMT+5',
      utcOffset: -300,
    },
    {
      value: 'Etc/GMT+6',
      display: 'GMT+6',
      utcOffset: -360,
    },
    {
      value: 'Etc/GMT+7',
      display: 'GMT+7',
      utcOffset: -420,
    },
    {
      value: 'Etc/GMT+8',
      display: 'GMT+8',
      utcOffset: -480,
    },
    {
      value: 'Etc/GMT+9',
      display: 'GMT+9',
      utcOffset: -540,
    },
    {
      value: 'Etc/UTC',
      display: 'UTC',
      utcOffset: 0,
    },
  ],
  HST: [
    {
      value: 'HST',
      display: 'HST',
      utcOffset: -600,
    },
  ],
  Indian: [
    {
      value: 'Indian/Chagos',
      display: 'Chagos',
      utcOffset: 360,
    },
    {
      value: 'Indian/Christmas',
      display: 'Christmas',
      utcOffset: 420,
    },
    {
      value: 'Indian/Cocos',
      display: 'Cocos',
      utcOffset: 390,
    },
    {
      value: 'Indian/Kerguelen',
      display: 'Kerguelen',
      utcOffset: 300,
    },
    {
      value: 'Indian/Mahe',
      display: 'Mahe',
      utcOffset: 240,
    },
    {
      value: 'Indian/Maldives',
      display: 'Maldives',
      utcOffset: 300,
    },
    {
      value: 'Indian/Mauritius',
      display: 'Mauritius',
      utcOffset: 240,
    },
    {
      value: 'Indian/Reunion',
      display: 'Reunion',
      utcOffset: 240,
    },
  ],
  MET: [
    {
      value: 'MET',
      display: 'MET',
      utcOffset: 60,
    },
  ],
  MST: [
    {
      value: 'MST',
      display: 'MST',
      utcOffset: -420,
    },
  ],
  MST7MDT: [
    {
      value: 'MST7MDT',
      display: 'MST7MDT',
      utcOffset: -360,
    },
  ],
  PST8PDT: [
    {
      value: 'PST8PDT',
      display: 'PST8PDT',
      utcOffset: -420,
    },
  ],
  WET: [
    {
      value: 'WET',
      display: 'WET',
      utcOffset: 0,
    },
  ],
});
