import React, { FunctionComponent } from 'react';
import ChatHeader from './chatHeader';
import DirectChatHeader, { DirectChatHeaderProps } from './directChatHeader';
import TabHeader from './tabHeader';
import ScheduleHeader from './scheduleHeader';
import MomentHeader from './momentHeader';
import HostChatHeader from './hostChatHeader';
import { MomentSchedule } from '@io/__generated__/MomentSchedule';

export enum PaneHeaderType {
  CHAT = 'CHAT',
  DEFAULT = 'DEFAULT',
  DIRECT_CHAT = 'DIRECT_CHAT',
  HOST_CHAT = 'HOST_CHAT',
  SCHEDULE = 'SCHEDULE',
  MOMENT = 'MOMENT',
}

export interface BasePayload {
  title: string;
  subtitle?: string | number;
}

export interface IHostChatHeader {
  type: PaneHeaderType.HOST_CHAT;
  payload: BasePayload;
}

export interface IChatHeader {
  type: PaneHeaderType.CHAT;
  payload: BasePayload;
}

export interface IDirectChatHeader {
  type: PaneHeaderType.DIRECT_CHAT;
  payload: DirectChatHeaderProps;
}

export interface IScheduleHeader {
  type: PaneHeaderType.SCHEDULE;
  payload: BasePayload;
}

export interface IDefaultHeader {
  type: PaneHeaderType.DEFAULT;
  payload: BasePayload;
}

export interface IMomentHeader {
  type: PaneHeaderType.MOMENT;
  payload: BasePayload;
}

export interface HeaderMeta {
  data?: MomentSchedule;
  momentSchedulingEnabled: boolean;
  showAutoPostMoment: boolean;
  toggleMomentScheduling?: () => void;
}

interface Props {
  header:
    | IChatHeader
    | IDirectChatHeader
    | IScheduleHeader
    | IDefaultHeader
    | IHostChatHeader
    | IMomentHeader;
  guestMediumUp?: boolean;
  meta?: HeaderMeta;
}

const PaneHeader: FunctionComponent<Props> = ({ header, guestMediumUp, meta }) => {
  switch (header.type) {
    case PaneHeaderType.CHAT: {
      if (!guestMediumUp) {
        return <ChatHeader title={header.payload.title} subtitle={header.payload.subtitle} />;
      } else {
        return null;
      }
    }
    case PaneHeaderType.DIRECT_CHAT:
      return (
        <DirectChatHeader
          title={guestMediumUp ? '' : header.payload.title}
          leaveChannel={header.payload.leaveChannel}
          inviteHost={header.payload.inviteHost}
          isGuest={header.payload.isGuest}
        />
      );
    case PaneHeaderType.HOST_CHAT: {
      return !guestMediumUp ? (
        <HostChatHeader title={header.payload.title} subtitle={header.payload.subtitle} />
      ) : null;
    }
    case PaneHeaderType.MOMENT:
      if (!guestMediumUp) {
        const { momentSchedulingEnabled, toggleMomentScheduling, showAutoPostMoment } = meta || {};
        if (
          momentSchedulingEnabled === undefined ||
          showAutoPostMoment === undefined ||
          !toggleMomentScheduling
        )
          return null;

        return (
          <MomentHeader
            title={header.payload.title}
            momentSchedulingEnabled={momentSchedulingEnabled}
            toggleMomentScheduling={toggleMomentScheduling}
            showAutoPostToggle={showAutoPostMoment}
          />
        );
      } else {
        return null;
      }
    case PaneHeaderType.SCHEDULE:
      if (!guestMediumUp) {
        return <ScheduleHeader title={header.payload.title} />;
      } else {
        return null;
      }
    case PaneHeaderType.DEFAULT:
      if (!guestMediumUp) {
        return <TabHeader title={header.payload.title} />;
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default PaneHeader;
