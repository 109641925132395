import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const SpeakerOffIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='M6 8L13 1V23L6 16H1V8H6Z' size={size} mirrorForRTL {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
  }
};
