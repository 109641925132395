import styled, { css } from 'styled-components';
import { compose, space } from 'styled-system';
import { IconProps } from 'icons/types';
import React, { FunctionComponent } from 'react';
import { getDefaultTheme } from '../theme';
import { Flex } from '../Flex';
import { logical } from '../logical';

interface StyledSVGProps {
  hoverColor: string;
  isMirrored: boolean;
}

const StyledSVG = styled.svg<StyledSVGProps>`
  &:hover path {
    fill: ${props => props.hoverColor};
  }

  ${props =>
    props.isMirrored &&
    css`
      html[dir='rtl'] &&& {
        transform: scale(-1, 1);
      }
    `}

  ${compose(space, logical)}
`;

interface BaseIconProps extends IconProps {
  ariaLabelledBy?: string;
  draw: string;
  mirrorForRTL?: boolean;
}

export const BaseIcon: FunctionComponent<BaseIconProps> = ({
  ariaLabelledBy,
  color = getDefaultTheme().colors.gray50,
  draw,
  hoverColor = color,
  mirrorForRTL = false,
  size,
  ...rest
}) => (
  <StyledSVG
    aria-labelledby={ariaLabelledBy}
    fill='none'
    height={size}
    hoverColor={hoverColor}
    isMirrored={mirrorForRTL}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path d={draw} fill={color} fillRule='evenodd' clipRule='evenodd' />
  </StyledSVG>
);

interface IconButtonProps {
  onClick: () => void;
  opacity?: number;
}
export const IconButton: FunctionComponent<IconButtonProps> = ({
  children,
  onClick,
  opacity = 1,
}) => (
  <Flex
    opacity={opacity}
    alignItems='center'
    justifyContent='center'
    width='48px'
    height='48px'
    onClick={onClick}
    cursor='pointer'
  >
    {children}
  </Flex>
);
