import styled, { css } from 'styled-components';
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  compose,
  LayoutProps,
  layout,
  variant,
} from 'styled-system';
import { logical, LogicalProps } from './logical';
import { ButtonSize } from './Button';

export type BaseComboButtonProps = SpaceProps &
  TypographyProps &
  BorderProps &
  LogicalProps &
  LayoutProps & {
    size?: ButtonSize;
    pulsing?: boolean;
  };

const comboButtonSizeVariant = variant({
  prop: 'size',
  variants: {
    xs: {
      fontSize: 1,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 5,
      paddingBlockEnd: 4,
    },
    sm: {
      fontSize: 2,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 16,
      paddingInlineEnd: 16,
      paddingBlockStart: 7,
      paddingBlockEnd: 7,
    },
    md: {
      fontSize: 3,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 24,
      paddingInlineEnd: 24,
      paddingBlockStart: 9,
      paddingBlockEnd: 9,
    },
    lg: {
      fontSize: 4,
      lineHeight: 2,
      paddingInlineStart: 32,
      paddingInlineEnd: 32,
      paddingBlockStart: 14,
      paddingBlockEnd: 15,
    },
    xl: {
      fontSize: 5,
      lineHeight: 2,
      paddingInlineStart: 40,
      paddingInlineEnd: 40,
      paddingBlockStart: 16,
      paddingBlockEnd: 15,
    },
  },
});

const ComboButtonItem = styled.button<BaseComboButtonProps>`
  --pulse-color: ${props => props.theme.colors.action.default};
  box-sizing: border-box;
  outline: none;
  background-color: ${props => props.theme.colors.action.default};
  border: 1px solid transparent;
  color: white;
  border-radius: 0px;
  position: relative;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  user-select: none;

  &:hover {
    background-color: ${props => props.theme.colors.action.hover};
    box-shadow: ${props => props.theme.shadows[2]};
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    props.pulsing &&
    !props.disabled &&
    css`
      animation: pulse-animation 1.5s infinite;
      box-shadow: 0px 0px 1px 1px var(--pulse-color);

      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px var(--pulse-color);
        }
        50% {
          box-shadow: 0 0 0 20px transparent;
        }
        100% {
          box-shadow: none;
        }
      }
    `}

  ${compose(space, typography, border, logical, layout, comboButtonSizeVariant)}
`;

ComboButtonItem.defaultProps = {
  size: ButtonSize.MD,
  type: 'button',
};

export { ComboButtonItem };
