import { connect } from 'react-redux';
import ServiceInfo from './serviceInfo';
import { RootState } from '@store/rootReducer';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { isOffline } from '@store/serviceSlice/selectors';

interface StateProps {
  isGuest: boolean;
  hasPrayer: boolean;
  isOffline: boolean;
}

interface OwnProps {
  hasMedia: boolean;
  isDuringService: boolean;
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps & OwnProps => {
  const hasOfflinePrayerRecipients =
    getCurrentOrganization(state).offlinePrayerRecipients.length > 0;
  const offline = isOffline(state);

  return {
    isGuest: !isHostViewingAsHost(state),
    hasPrayer: offline && hasOfflinePrayerRecipients,
    isOffline: offline,
    ...props,
  };
};

export type Props = StateProps & OwnProps;
export default connect(mapStateToProps)(ServiceInfo);
