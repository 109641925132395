import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 0;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;

  svg {
    transition: all 0.09s ease-in-out;
  }

  &:hover svg {
    transform: scale(1.2);
  }

  &:active svg {
    transform: scale(0.8);
  }
`;

export { Container, Button };
