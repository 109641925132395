import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CommentStarIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M1 3H14V12H11.5L9 14.5V12H1V3ZM8.2858 6.41844L7.5 4L6.7142 6.41844H4.1713L6.22855 7.91312L5.44275 10.3316L7.5 8.83688L9.55725 10.3316L8.77145 7.91312L10.8287 6.41844H8.2858Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M2 4H18V16H15L12 19V16H2V4ZM11.1226 9.00492L10 5.55L8.87743 9.00492H5.24472L8.18364 11.1402L7.06107 14.5951L10 12.4598L12.9389 14.5951L11.8164 11.1402L14.7553 9.00492H11.1226Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M2 4H22V20H18L14 24V20H2V4ZM13.4593 10.2414L12 5.75L10.5407 10.2414H5.81813L9.63874 13.0172L8.1794 17.5086L12 14.7328L15.8206 17.5086L14.3613 13.0172L18.1819 10.2414H13.4593Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M2 6H30V26H24L19 31V26H2V6ZM17.5716 14.3369L16 9.5L14.4284 14.3369H9.3426L13.4571 17.3262L11.8855 22.1631L16 19.1738L20.1145 22.1631L18.5429 17.3262L22.6574 14.3369H17.5716Z'
          size={size}
          {...rest}
        />
      );
  }
};
