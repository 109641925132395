import React, { FunctionComponent, MouseEvent, KeyboardEvent } from 'react';

interface Props {
  onClick: (e: MouseEvent<unknown>, ctx: unknown) => void;
  keepFocus?: boolean;
  tabable?: boolean;
  children: any;
  role?: string;
}

const preventDefault = (event: MouseEvent | KeyboardEvent) => event.preventDefault();

const determineRole = (role: string, addKeyboardSupport: boolean) => {
  if (role !== '') {
    return role;
  }
  if (addKeyboardSupport) {
    return 'button';
  }
  return null;
};

const Actionable: FunctionComponent<Props> = ({
  onClick,
  keepFocus = false,
  tabable = true,
  children,
  role = '',
}) => {
  const { type: nodeName } = children;
  const interactiveElement = ['button', 'a', 'input', 'select', 'textarea', 'details'].includes(
    nodeName
  );
  const addKeyboardSupport = !interactiveElement && tabable;

  const newProps = {
    onMouseDown: keepFocus ? preventDefault : null,
    onClick: onClick,
    onKeyPress: addKeyboardSupport ? onClick : null,
    tabIndex: addKeyboardSupport ? '0' : -1,
    role: determineRole(role, addKeyboardSupport),
  };

  return React.cloneElement(children, newProps);
};

export default Actionable;
