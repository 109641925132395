import React, { useContext } from 'react';
import { Fuse, IconSize, TimesIcon } from '@lifechurch/react-ion';
import { ThemeContext } from 'styled-components';
import { CTAWrapper, DismissButton } from './styles';
const CTAMoment = ({ ariaLabel, children, fuse = false, hideDismiss = false, isPinned, onDismiss, }) => {
    const { colors } = useContext(ThemeContext);
    return (React.createElement(CTAWrapper, { tabIndex: 0, isPinned: isPinned, "aria-label": ariaLabel },
        isPinned && !hideDismiss && (React.createElement(DismissButton, { "data-testid": 'dismissButton', position: 'absolute', top: 0, padding: '8px', lineHeight: 0, onClick: onDismiss, marginBlockStart: fuse ? '0' : '0', marginInlineEnd: fuse ? '0' : '0' }, fuse ? (React.createElement(Fuse, { duration: 20e3, onEnded: onDismiss, color: colors.gray50, size: IconSize.SM, dismissible: true })) : (React.createElement(TimesIcon, { size: IconSize.SM, hoverColor: colors.gray100 })))),
        children));
};
export default CTAMoment;
