import React from 'react';
import { Box, Flex, OParagraph, OSmallParagraph } from '@lifechurch/react-ion';
import { ShareSheet as ShareSheetWrapper } from '../index';
import { TwitterShareButton, EmailShareButton, CopyShareButton } from '../sharing-buttons';
import { QuoteWrapper } from './styles';
export const QuoteMomentShareSheet = ({ open, handleModalClose, text, attribution, church, }) => {
    return (React.createElement(ShareSheetWrapper, { open: open, handleModalClose: handleModalClose },
        React.createElement(QuoteSharesheet, { text: text, attribution: attribution, church: church })));
};
export const QuoteSharesheet = ({ text, attribution, church, }) => {
    return (React.createElement("div", null,
        React.createElement(Box, { marginBlockStart: 5 },
            React.createElement(QuoteWrapper, { tabIndex: 0, "aria-label": text },
                React.createElement(Flex, { flexDirection: 'column' },
                    React.createElement(OParagraph, null, text),
                    attribution && (React.createElement(OSmallParagraph, { marginBlockStart: '2px', color: 'gray50' }, attribution))))),
        React.createElement(Box, { marginBlockStart: 5 },
            React.createElement(Flex, { flexDirection: 'row', justifyContent: 'center' },
                React.createElement(TwitterShareButton, { content: `"${text}" —${attribution}`, url: window.location.href }),
                React.createElement(EmailShareButton, { subject: `Great quote from ${church}`, content: `"${text}" —${attribution}\n\n${window.location.origin}` }),
                React.createElement(CopyShareButton, { content: `"${text}" —${attribution}`, buttonText: 'Copy\xa0Quote' })))));
};
