export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

const http = <T>(request: RequestInfo): Promise<HttpResponse<T>> => {
  return new Promise((resolve, reject): void => {
    let response: HttpResponse<T>;
    fetch(request)
      .then(res => {
        response = res;
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('json') > -1) {
          return res.json();
        } else {
          return res.text();
        }
      })
      .then(body => {
        response.parsedBody = body;
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const get = async <T>(
  path: string,
  args: RequestInit = {
    method: 'GET',
    credentials: 'same-origin',
    headers: path?.startsWith('/')
      ? {
          'Application-Domain': window.location.hostname,
        }
      : {},
  }
): Promise<HttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};

export const post = async <T>(
  path: string,
  body?: unknown,
  args: RequestInit = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Application-Domain': window.location.hostname,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};
