import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ExitFullscreenIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18.2071 3.20718L14.4142 7.00008H17V9.00008L13 9.00007H11V7.00008V3.00008H13V5.58586L16.7928 1.79297L18.2071 3.20718ZM2.99989 11H6.99989H8.99989L8.99989 13L8.99989 17H6.99989V14.4142L3.207 18.2071L1.79279 16.7929L5.58568 13H2.99989V11Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22.207 3.20718L16.4141 9.00008H18.9999V11.0001H14.9999H12.9999V9.00008V5.00008H14.9999V7.58586L20.7928 1.79297L22.207 3.20718ZM4.99983 13H8.99983H10.9998V15V19H8.99983V16.4143L3.20693 22.2071L1.79272 20.7929L7.58571 15H4.99983V13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
