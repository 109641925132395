import { connect } from 'react-redux';
import Notification from './notification';
import { Notification as NotificationInterface } from './dux';
import { FeedObjectMetaData } from '../dux';
import { getNotificationById } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';

interface StateProps {
  notification?: NotificationInterface;
}

interface OwnProps {
  channel: string;
  metaData: FeedObjectMetaData;
  isCompact: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { channel, metaData } = ownProps;
  return {
    notification: getNotificationById(state, channel, metaData.id),
    ...ownProps,
  };
};

const VisibleNotification = connect(mapStateToProps)(Notification);

export type Props = StateProps & OwnProps;
export default VisibleNotification;
