import { ModalType } from '../dux';

export interface QuoteMomentShareSheet {
  type: ModalType.QUOTE_MOMENT_SHARE_SHEET;
  text: string;
  attribution: string | null;
}

export const quoteMomentShareSheet = (
  text: string,
  attribution: string | null
): QuoteMomentShareSheet => ({
  type: ModalType.QUOTE_MOMENT_SHARE_SHEET,
  text,
  attribution,
});
