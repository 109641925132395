import styled, { css } from 'styled-components';
import { getAvatarColor } from '@utils/core';

interface DirectChatAvatarProps {
  nickname: string;
  isCurrent: boolean;
  pending?: boolean;
  groupChat?: boolean;
  pulsing?: boolean;
}

const DirectChatAvatar = styled.div<DirectChatAvatarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 10.72px;
  color: ${props => props.theme.colors.white};
  text-align: center;
  line-height: 20px;
  background-color: ${props =>
    getAvatarColor(props.nickname, props.isCurrent ? 1.0 : 0.5, props.pending, props.groupChat)};

  &:hover {
    background-color: ${props =>
      getAvatarColor(props.nickname, 1.0, props.pending, props.groupChat)};
  }

  ${({ pulsing }) =>
    pulsing &&
    css`
      animation: pulse-animation 2s infinite;
      box-shadow: 0px 0px 1px 1px ${props => props.theme.colors.action.default};

      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px ${props => props.theme.colors.action.default};
        }
        100% {
          box-shadow: 0 0 0 20px rgba(41, 147, 229, 0);
        }
      }
    `}
`;

export { DirectChatAvatar };
