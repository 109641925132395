import { useState, useEffect, useRef, RefObject } from 'react';

interface State {
  scrollLeft: number;
  scrollTop: number;
  scrollWidth: number;
}

export const useScroll = (ref: RefObject<HTMLElement>): State => {
  const frame = useRef<number>();
  const [state, setState] = useState<State>({
    scrollLeft: 0,
    scrollTop: 0,
    scrollWidth: 0,
  });

  useEffect(() => {
    const handler = () => {
      if (frame.current) {
        cancelAnimationFrame(frame.current);
      }

      frame.current = requestAnimationFrame(() => {
        if (ref.current) {
          const { scrollLeft, scrollTop, scrollWidth } = ref.current;
          setState({
            scrollLeft,
            scrollTop,
            scrollWidth,
          });
        }
      });
    };

    if (ref.current) {
      ref.current.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (frame.current) {
        cancelAnimationFrame(frame.current);
      }

      if (ref.current) {
        ref.current.removeEventListener('scroll', handler);
      }
    };
  }, [ref.current]);

  return state;
};
