import { AvatarSizeType } from '../Avatar/types';
import styled from 'styled-components';

type AvatarContainer = { top: string; left: string; size: AvatarSizeType };

export const AvatarContainer = styled.div<AvatarContainer>`
  left: ${({ left }) => left};
  position: absolute;
  top: ${({ top }) => top};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;
