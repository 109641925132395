import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TetrisIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return null;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M3 3H10V9H7V16H3V9V8V3ZM10 10H11V8V6V4H13H15H17V6V10V14V15V17H15H10H8V15V12V10H9H10ZM13 12V10V6H15V10V14V15H10V12H11H13Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return null;
    case IconSize.XL:
      return null;
  }
};
