import React from 'react';
import styled from 'styled-components';
import { Flex } from '@lifechurch/react-ion';
const ShareBtnContainer = styled(Flex) `
  width: 64px;
  margin: 0 4px;
`;
const ShareButtonContainer = ({ children, flexDirection = 'column', alignItems = 'center' }, props) => {
    return (React.createElement(ShareBtnContainer, { flexDirection: flexDirection, alignItems: alignItems, ...props }, children));
};
export { ShareButtonContainer };
