import React, { useCallback, useContext, useState } from 'react';
import { useMutationObserver } from '@churchonline/hooks';
import BaseContent from '../content';
import { Flex, getInset, LeftQuotationIcon, OParagraph, OSmallParagraph, RightQuotationIcon, } from '@lifechurch/react-ion';
import styled, { ThemeContext } from 'styled-components';
const IconWrapperLeft = styled.div `
  position: absolute;
  ${getInset('insetBlockStart', '8px')};
  ${getInset('insetInlineStart', '8px')};
`;
const StyledRightQuotationIcon = styled(RightQuotationIcon) `
  position: absolute;
  display: inline-block;
  margin-inline-start: 4px;
`;
const StyledLeftQuotationIcon = styled(LeftQuotationIcon) `
  position: absolute;
  display: inline-block;
  margin-inline-start: 4px;
`;
const QuoteMoment = ({ attribution, hasLiked: defaultHasLiked, isPinned = false, isPlaceholder, likeButtonLabel, likeCount, mutationObserverTarget, onDismiss, onLike, onShare, shareButtonLabel, text, }) => {
    const { colors } = useContext(ThemeContext);
    const [hasLiked, setHasLiked] = useState(defaultHasLiked || false);
    const theDirectionTarget = mutationObserverTarget || document.documentElement;
    const defaultRTL = theDirectionTarget.dir === 'rtl';
    const [isRTL, setIsRTL] = useState(defaultRTL);
    const mutationObserverCallback = useCallback(mutations => {
        mutations.forEach(({ attributeName, target, type, }) => {
            if (type === 'attributes' && target) {
                return setIsRTL(target.getAttribute(attributeName) === 'rtl');
            }
        });
    }, []);
    useMutationObserver({
        target: mutationObserverTarget,
        options: { attributes: true, attributeFilter: ['dir'] },
        callback: mutationObserverCallback,
    });
    const handleLike = () => {
        if (!hasLiked) {
            setHasLiked(true);
            onLike();
        }
    };
    return (React.createElement(BaseContent, { fuse: true, hasLiked: hasLiked, isPinned: isPinned, likeButtonLabel: likeButtonLabel, likeCount: likeCount, onDismiss: onDismiss, onLike: handleLike, onShare: onShare, paddingBlockStart: '8px', paddingBlockEnd: '12px', paddingInlineStart: '32px', paddingInlineEnd: '12px', shareButtonLabel: shareButtonLabel, ariaLabel: text },
        React.createElement(Flex, { flexDirection: 'column', paddingBlockStart: 0, paddingBlockEnd: 0, paddingInlineStart: 0, paddingInlineEnd: isPinned ? '32px' : '0' },
            React.createElement(IconWrapperLeft, null, isRTL ? (React.createElement(RightQuotationIcon, { color: colors.gray30 })) : (React.createElement(LeftQuotationIcon, { color: colors.gray30 }))),
            React.createElement(OParagraph, { color: isPlaceholder && 'gray50' },
                text,
                isRTL ? (React.createElement(StyledLeftQuotationIcon, { color: colors.gray30 })) : (React.createElement(StyledRightQuotationIcon, { color: colors.gray30 }))),
            attribution && (React.createElement(OSmallParagraph, { marginBlockStart: '2px', color: 'gray50' }, attribution)))));
};
export { QuoteMoment };
