import React, { FunctionComponent, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { BarsIcon, TimesIcon } from './icons';
import { Flex } from './Flex';

interface MovableListItemProps {
  index: number | undefined;
  removeItem: (index: number) => void;
  enableEvents?: boolean;
}

const CloseWrapper = styled.div`
  flex-shrink: 0;
  margin-inline-start: auto;
  padding-inline-start: 16px;
  width: 36px;
  height: 20px;
  margin-inline-end: 16px;
  cursor: default;
`;

interface FlexProps {
  enableEvents?: boolean;
}

const Wrapper = styled(Flex)<FlexProps>`
  &:hover {
    cursor: ${props => (props.enableEvents ? 'grab' : 'inherit')};
    background-color: ${props => (props.enableEvents ? props.theme.colors.gray5 : 'inherit')};
    z-index: 10;

    ${CloseWrapper} {
      svg {
        display: ${props => (props.enableEvents ? 'block' : 'none')};
      }
    }
  }

  &:active {
    cursor: ${props => (props.enableEvents ? 'grabbing' : 'inherit')};
  }

  &:last-child {
    border-block-end: 1px solid ${props => props.theme.colors.gray10};
  }

  user-select: none;
  border-block-start: 1px solid ${props => props.theme.colors.gray10};
  background-color: white;

  ${CloseWrapper} {
    svg {
      display: none;
    }
  }
`;

const DragWrapper = styled.div`
  padding: 0 8px;
`;

const MovableListItem: FunctionComponent<MovableListItemProps> = ({
  children,
  removeItem,
  index,
  enableEvents = true,
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const exit = React.createRef<HTMLDivElement>();
  return (
    <Wrapper
      ref={ref}
      paddingBlockStart={16}
      paddingBlockEnd={16}
      alignItems='center'
      enableEvents={enableEvents}
    >
      <DragWrapper>
        <BarsIcon color={theme.colors.gray30} />
      </DragWrapper>
      {children}
      <CloseWrapper
        ref={exit}
        // Library does not recognize mouse clicks on non button elements within dnd component
        role='button'
        onClick={() => enableEvents && index !== undefined && removeItem(index)}
      >
        <TimesIcon color={theme.colors.gray30} hoverColor={theme.colors.gray100} />
      </CloseWrapper>
    </Wrapper>
  );
};

export { MovableListItem };
