import React, { FC } from 'react';
import {
  ButtonSize,
  CaretDownIcon,
  defaultColors,
  Dropdown,
  DropdownItem,
  Flex,
  IconSize,
  OParagraph,
  PrimaryButton,
  TimesCircleIcon,
} from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks/redux/hooks';
import { setAudiencePaneFilter } from '@store/uiSlice';

export enum FILTERS {
  HOSTS = 'hosts',
  MUTED = 'muted',
  NONE = 'none',
}

interface Props {
  onChange: (option: FILTERS) => void;
  filter: FILTERS;
}

const Filter: FC<Props> = ({ onChange, filter }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClick = (filter: FILTERS) => {
    onChange(filter);
  };

  const handleFilterClick = () => {
    dispatch(setAudiencePaneFilter(null));
    onChange(FILTERS.NONE);
  };

  if (filter !== FILTERS.NONE) {
    return (
      <PrimaryButton onClick={handleFilterClick} marginInlineStart='auto' size={ButtonSize.SM}>
        <OParagraph marginInlineEnd={3} color='white'>
          {t(filter)}
        </OParagraph>{' '}
        <TimesCircleIcon size={IconSize.MD} color={defaultColors.white} />
      </PrimaryButton>
    );
  }

  return (
    <Flex marginInlineStart='auto'>
      <Dropdown
        popperContent={
          <>
            <DropdownItem onClick={() => handleClick(FILTERS.HOSTS)}>{t('hosts')}</DropdownItem>
            <DropdownItem
              onClick={() => handleClick(FILTERS.MUTED)}
              data-testid='audience-filter-muted'
            >
              {t('muted')}
            </DropdownItem>
          </>
        }
        referenceContent={
          <Flex alignItems='center'>
            <OParagraph color={defaultColors.gray50}>{t('filter')}</OParagraph>
            <CaretDownIcon size={IconSize.SM} color={defaultColors.gray50} />
          </Flex>
        }
      />
    </Flex>
  );
};

export default Filter;
