import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';
import {
  Box,
  FancyInput,
  FormBanner,
  FormBannerType,
  Modal,
  ModalSize,
  OHeaderThree,
  OParagraph,
  PrimaryButton,
  ModalButtons,
} from '@lifechurch/react-ion';
import PasswordStrength from '../../PasswordStrength';

type ResetPasswordState = {
  password: string;
  passwordConfirmation: string;
  passwordBlank: boolean;
  passwordConfirmationBlank: boolean;
  match: boolean;
};

const ResetPassword = ({ closeModal, resetPassword, currentSubscriber }: Props) => {
  const { t } = useTranslation('forms');

  const [values, setValues] = useState<ResetPasswordState>({
    password: '',
    passwordConfirmation: '',
    passwordBlank: false,
    passwordConfirmationBlank: false,
    match: true,
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const passwordBlank = `${name}Blank`;
    setValues({ ...values, [name]: value, [passwordBlank]: false, match: true });
  };

  const handleReset = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (values.password !== '' && values.password === values.passwordConfirmation) {
      resetPassword(currentSubscriber?.id, values.password);
    } else {
      setValues({
        ...values,
        passwordBlank: values.password === '',
        passwordConfirmationBlank: values.passwordConfirmation === '',
        match:
          values.password === '' ||
          values.passwordConfirmation === '' ||
          values.password === values.passwordConfirmation,
      });
    }
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='resetPassword-modal'>
      <OHeaderThree marginBlockEnd={5}>{t('reset_password.title')}</OHeaderThree>
      {!values.match && (
        <FormBanner type={FormBannerType.Error}>
          <OParagraph>{t('reset_password.passwords_dont_match')}</OParagraph>
        </FormBanner>
      )}
      <form onSubmit={handleReset}>
        <Box marginBlockStart={5} marginBlockEnd={5}>
          <FancyInput
            type='password'
            name='password'
            id='password'
            value={values.password}
            label={t('reset_password.password')}
            onChange={onChange}
            error={values.passwordBlank || !values.match}
            hint={values.passwordBlank ? t('reset_password.blank_password') : null}
          />
          <PasswordStrength password={values.password} />
        </Box>
        <Box>
          <FancyInput
            type='password'
            name='passwordConfirmation'
            id='passwordConfirmation'
            value={values.passwordConfirmation}
            label={t('reset_password.confirm_password')}
            onChange={onChange}
            error={values.passwordConfirmationBlank || !values.match}
            hint={values.passwordConfirmationBlank ? t('reset_password.blank_password') : null}
          />
        </Box>
        <ModalButtons marginBlockStart={6}>
          <PrimaryButton onClick={handleReset} type='submit'>
            {t('reset_password.save')}
          </PrimaryButton>
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default ResetPassword;
