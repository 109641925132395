import styled, { css } from 'styled-components';
import { NavigationLinkOption } from '../../../../__generated__/globalTypes';

interface NavHeaderLinksProps {
  linkTextCase: NavigationLinkOption;
  color?: string;
}

const AccessibileLink = styled.a`
  outline: none;
  padding: 8px;
  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover} inset;
    text-decoration: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const LinksWrapper = styled.nav<NavHeaderLinksProps>`
  display: flex;
  justify-content: flex-end;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: auto;
  margin-inline-end: 12px;
  padding-inline-start: 16px;
  overflow: hidden;
  text-align: end;
  width: 100%;
  align-items: center;

  &&& > a {
    margin: 0 12px;
    white-space: nowrap;
    color: ${props => (props.color ? props.color : props.theme.colors.gray100)};
    ${props =>
      props.linkTextCase &&
      css`
        text-transform: ${props.linkTextCase === NavigationLinkOption.TITLE
          ? 'capitalize'
          : 'uppercase'};
      `}
  }

  &&& > button {
    margin-inline-start: 12px;
  }

  &:focus {
    box-shadow: inset 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const ScreenReaderText = styled.span`
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
`;

export { AccessibileLink, LinksWrapper, ScreenReaderText };
