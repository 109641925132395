import { Box, Flex, getInset, PrimaryButton } from '@lifechurch/react-ion';
import styled from 'styled-components';
export const UnavailableForPreview = styled(Flex) `
  background-color: rgba(255, 255, 255, 0.9);
  flex-direction: row;
  justify-content: center;
  padding: 30px;
  position: absolute;
  width: 100%;
  ${getInset('insetInlineStart', 0)};
  display: none;
`;
export const InfoBox = styled(Box) `
  margin-block-start: 8px;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 4px;
  background-color: #ececec;
  padding: 14px;
`;
export const ModalWrapper = styled(Flex) `
  flex-direction: column;
  position: absolute;
  padding: 79px 16px;
  background-color: rgba(30, 31, 35, 0.5);
  z-index: 1000;
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  margin-block-end: -56px;
`;
export const Modal = styled(Box) `
  position: relative;
  width: 100%;
  border-radius: ${props => props.theme.radii[2]}px;
  background-color: ${props => props.theme.colors.white};
  margin: auto;
  padding: ${props => props.theme.space[5]}px;
  text-align: start;
`;
export const DismissButton = styled.button `
  position: absolute;
  background: none;
  border: none;
  outline: none;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  padding-block-start: 16px;
  padding-block-end: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 16px

  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.gray100};
      }
    }
  }
`;
export const FacebookButton = styled(PrimaryButton) `
  background-color: ${props => props.theme.colors.brands.facebook};

  &:hover {
    background-color: ${props => props.theme.colors.brands.facebook};
  }
`;
const colorTwitterBlue = '#1DA1F2';
export const TwitterButton = styled(PrimaryButton) `
  background-color: ${colorTwitterBlue};

  &:hover {
    background-color: ${colorTwitterBlue};
  }
`;
export const DownloadButton = styled(PrimaryButton) `
  background-color: ${props => props.theme.colors.gray50};

  &:hover {
    background-color: ${props => props.theme.colors.gray50};
  }
`;
