import { ButtonSize, PrimaryOnDarkButton } from '@lifechurch/react-ion';
import React, { createRef, FunctionComponent, useState } from 'react';
import ReactPlayer from 'react-player';
import { UnmuteButtonWrapper } from '../styles';
import IframeEmbedPlayer from './iframeEmbedPlayer';
import { useTranslation } from 'react-i18next';
import { transformUrl } from '@store/videoSlice/selectors';

interface Props {
  autoplay: boolean;
  loop: boolean;
  url?: string | null;
}

const OfflinePlayer: FunctionComponent<Props> = ({ url, autoplay, loop }) => {
  const [isMuted, setIsMuted] = useState(true);
  const [hasStarted, setHasStarted] = useState(false);
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const ref = createRef<ReactPlayer>();

  if (!url) {
    return null;
  }

  const isEmbed = url.startsWith('/video/');
  if (isEmbed) {
    return <IframeEmbedPlayer url={url} />;
  }

  const formattedUrl = transformUrl(url);

  const onUnmute = () => setIsMuted(false);
  const onStart = () => setHasStarted(true);
  const onProgress = () => {
    if (ref.current) {
      setIsMuted(ref.current.getInternalPlayer().isMuted());
    }
  };

  const shouldShowUnmuteButton = hasStarted && isMuted;

  return (
    <>
      {shouldShowUnmuteButton && (
        <UnmuteButtonWrapper>
          <PrimaryOnDarkButton
            data-testid='tapToUnmuteButton'
            onClick={onUnmute}
            size={ButtonSize.SM}
            style={{ pointerEvents: 'auto' }}
          >
            {t('tap_to_unmute')}
          </PrimaryOnDarkButton>
        </UnmuteButtonWrapper>
      )}
      <ReactPlayer
        ref={ref}
        data-testid='offlinePlayer'
        url={formattedUrl}
        style={{
          position: 'absolute',
          top: 0,
          left: !isRTL ? 0 : undefined,
          right: isRTL ? 0 : undefined,
        }}
        onStart={onStart}
        onProgress={onProgress}
        volume={1}
        muted={isMuted} // This option only works if volume is set
        playing={autoplay}
        loop={loop}
        width='100%'
        height='100%'
        frameBorder='0'
        controls
        playsinline
        config={{
          youtube: {
            playerVars: {
              autoplay: autoplay ? 1 : 0,
              disablekb: 1,
              modestbranding: 1,
              rel: 0,
              showinfo: 1, // This moves the volume control to the bottom bar on iOS (why? I dunno)
              loop: loop ? 1 : 0,
            },
          },
          vimeo: {
            playerOptions: {
              autoplay: autoplay,
              portrait: false,
              title: false,
              loop: loop,
            },
          },
          wistia: {
            options: {
              silentAutoPlay: 'allow',
              endVideoBehavior: loop ? 'loop' : 'default',
            },
          },
        }}
      />
    </>
  );
};

export default React.memo(OfflinePlayer);
