import React, { createRef, useState } from 'react';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { useOnClickOutside } from '@churchonline/hooks';
import { Box } from './Box';
import { Flex } from './Flex';
import { IconSize, TimesCircleIcon } from './icons';
import { getInset } from './logical';
import { Spinner } from './Spinner';

interface Props {
  dismiss: () => void;
  src: string;
}

const FreezeBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalWrapper = styled(Flex)`
  position: fixed;
  padding: 48px 0;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.overlay50};
  width: 100vw;
  min-height: 100%;
  z-index: 1000;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};

  @media (max-width: 639px) {
    padding-block-start: 48px;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
`;

const Modal = styled(Box)`
  position: relative;
  width: 460px;
  height: 640px;
  margin: auto;

  @media (max-width: 639px) {
    margin: 0 auto;
    height: 100%;
  }
`;

const IframeWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;

  @media (max-width: 639px) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
`;

const DismissButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  outline: none;
  ${getInset('insetBlockStart', '-40px')};
  ${getInset('insetInlineEnd', '-40px')};
  padding: 0;
  height: 32px;
  opacity: 0.5;

  @media (max-width: 639px) {
    ${getInset('insetInlineEnd', '8px')};
  }

  &:hover {
    opacity: 1;
  }
`;

const SpinnerContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const IframeModal: React.FunctionComponent<Props> = ({ dismiss, src }: Props) => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const modalRef = createRef<HTMLDivElement>();
  useOnClickOutside(modalRef, () => dismiss());

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <ModalWrapper>
      <Modal ref={modalRef}>
        <DismissButton onClick={dismiss} type='button'>
          <TimesCircleIcon size={IconSize.XL} color='white' />
        </DismissButton>
        <IframeWrapper>
          {loading && (
            <SpinnerContainer>
              <Spinner size={IconSize.LG} color={theme.colors.gray50} />
            </SpinnerContainer>
          )}
          <Iframe src={src} onLoad={onLoad}></Iframe>
        </IframeWrapper>
      </Modal>
      <FreezeBody />
    </ModalWrapper>
  );
};

export { IframeModal };
