import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const PlayArrowIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='M17 9.99998L5 16.9282L5 3.07178L17 9.99998Z' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='M21 12.0001L6 20.6604L6 3.33984L21 12.0001Z' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
