import { SaveSubscriberMutation_saveSubscriber_errors } from '@io/__generated__/SaveSubscriberMutation';

/**
 * checks errors to see if error type indicates a duplicate value
 * @param type either 'nickname' or 'email'
 * @param errors array of errors passed as payload in saveSubscriber failed action
 * @returns boolean
 */
export const checkForDuplicate = (
  type: 'nickname' | 'email',
  errors: SaveSubscriberMutation_saveSubscriber_errors[]
): boolean => {
  return errors?.some(e => e.code === 'profile-002' && e.property === type);
};
