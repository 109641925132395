import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

interface Props {
  url?: string | null;
}

const IFrame = styled.iframe`
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineStart', 0)};
  width: 100%;
  height: 100%;
`;

const IframeEmbedPlayer: FunctionComponent<Props> = ({ url }) => {
  if (!url) {
    return null;
  }

  return (
    <IFrame
      src={url}
      frameBorder={0}
      marginWidth={0}
      marginHeight={0}
      title='iframePlayer'
      data-testid='iframePlayer'
      allowFullScreen
    />
  );
};

export default React.memo<Props>(IframeEmbedPlayer);
