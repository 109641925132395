import Tab from './tab';
import { PaneType } from '../../dux';
import { MomentSchedule } from '@io/__generated__/MomentSchedule';
import { errorAlert } from '@components/Alert/dux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '@store/rootReducer';
import { getCurrentService, isOnline } from '@store/serviceSlice/selectors';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { toggleMomentsAutopost } from './dux';

export interface TabMeta {
  data?: MomentSchedule;
  loading?: boolean;
  momentSchedulingEnabled?: boolean;
  showAutoPostMoment?: boolean;
}

interface StateProps {
  momentSchedulingEnabled: boolean;
  nextServiceId: string | null;
  isOnline: boolean;
}

interface OwnProps {
  type: PaneType;
  isGuest: boolean;
  guestMediumUp?: boolean;
  meta?: TabMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): OwnProps & StateProps => {
  const currentService = getCurrentService(state);
  return {
    ...ownProps,
    momentSchedulingEnabled: currentService.momentSchedulingEnabled,
    nextServiceId: getNextServiceData(state)?.id,
    isOnline: isOnline(state),
  };
};

interface DispatchProps {
  toggleMomentSchedulingError: () => void;
  sendToggleMomentsAutopostMetric: (autopost: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  toggleMomentSchedulingError: () => dispatch(errorAlert('toggle_automatic_moments_post_error')),
  sendToggleMomentsAutopostMetric: (autopost: boolean) => dispatch(toggleMomentsAutopost(autopost)),
});

const VisibleTab = connect(mapStateToProps, mapDispatchToProps)(Tab);

export type Props = OwnProps & StateProps & DispatchProps;
export default VisibleTab;
