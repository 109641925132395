import React, { FunctionComponent } from 'react';
import SideMenu from '@features/sideMenu';
import Pane from '@features/pane';
import NavMenu from '@features/navMenu';
import { Container, CellContainerTop, CellContainerBottom } from './styles';
import { Grid, Cell } from 'styled-css-grid';
import { Props } from './index';
import ServiceWindow from '../../ServiceWindow';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const Medium: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.SIDE_MENU}
        altComponentName={t('side_menu')}
      >
        <SideMenu />
      </ErrorBoundary>
      <Grid
        columns={'max-content 1fr'}
        rows={'max-content 1fr'}
        areas={['menu video', 'menu chat']}
        height='100%'
        columnGap='0px'
      >
        <Cell area='menu'>
          <ErrorBoundary>
            <NavMenu />
          </ErrorBoundary>
        </Cell>
        <Cell area='video'>
          <CellContainerTop>
            <ServiceWindow />
          </CellContainerTop>
        </Cell>
        <Cell area='chat'>
          <CellContainerBottom>
            <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE}>
              <Pane />
            </ErrorBoundary>
          </CellContainerBottom>
        </Cell>
      </Grid>
    </Container>
  );
};

export default React.memo(Medium);
