import React, { FC, KeyboardEventHandler } from 'react';
import { Avatar } from '@churchonline/core';
import { useTranslation } from 'react-i18next';
import { PublicSubscriber } from '../../../../../../../types';
import { AvatarWrapper, Wrapper } from './styles';

export interface Props {
  closeReactionBanner: () => void;
  hasSso: boolean;
  isSignedIn: boolean;
  openProfileModal: () => void;
  openSignUpModal: () => void;
  openSsoValuePropModal: () => void;
  show: boolean;
  subscriber: PublicSubscriber;
}

const InputAvatar: FC<Props> = ({
  closeReactionBanner,
  hasSso,
  isSignedIn,
  openProfileModal,
  openSignUpModal,
  openSsoValuePropModal,
  show,
  subscriber: currentSubscriber,
}) => {
  const { t } = useTranslation();
  const avatar: string | undefined = currentSubscriber.avatar ?? undefined;
  const nickname: string = currentSubscriber.nickname ?? '';
  const id: string = currentSubscriber.id ?? undefined;

  const onClick = () => {
    closeReactionBanner();
    if (isSignedIn) openProfileModal();
    else if (hasSso) openSsoValuePropModal();
    else openSignUpModal();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <Wrapper show={show}>
      {show && (
        <AvatarWrapper
          aria-label={t('aria.avatar')}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          role='button'
          tabIndex={0}
        >
          <Avatar
            subscriber={{ id, avatar, nickname }}
            size={32}
            testId='avatar'
            isActionable={true}
          />
        </AvatarWrapper>
      )}
    </Wrapper>
  );
};

export default InputAvatar;
