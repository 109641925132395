import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TimesIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.50001 6.7929L11.6465 2.64645L12.3536 3.35356L8.20711 7.50001L12.3536 11.6465L11.6465 12.3536L7.50001 8.20711L3.35356 12.3536L2.64645 11.6465L6.7929 7.50001L2.64645 3.35356L3.35356 2.64645L7.50001 6.7929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 8.58579L15.2929 3.29289L16.7071 4.70711L11.4142 10L16.7071 15.2929L15.2929 16.7071L10 11.4142L4.70712 16.7071L3.29291 15.2929L8.5858 10L3.29291 4.70711L4.70712 3.29289L10 8.58579Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 10.5858L19.2929 3.29289L20.7071 4.70711L13.4142 12L20.7071 19.2929L19.2929 20.7071L12 13.4142L4.70712 20.7071L3.29291 19.2929L10.5858 12L3.29291 4.70711L4.70712 3.29289L12 10.5858Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 14.5858L25.2929 5.29289L26.7071 6.70711L17.4142 16L26.7071 25.2929L25.2929 26.7071L16 17.4142L6.70712 26.7071L5.29291 25.2929L14.5858 16L5.29291 6.70711L6.70712 5.29289L16 14.5858Z'
          size={size}
          {...rest}
        />
      );
  }
};
