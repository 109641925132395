import React, { FunctionComponent } from 'react';
import { ResponseMoment as BaseResponseMoment } from '@churchonline/moments';
import { useTranslation } from 'react-i18next';
import { MomentActionType, MomentType } from '../../../../../../__generated__/globalTypes';
import { Props } from './index';

const ResponseMoment: FunctionComponent<Props> = ({
  moment,
  onDismiss,
  isPinned,
  submitMomentAction,
  hasSubmitted,
}) => {
  const { t } = useTranslation('moments');
  const text =
    moment.momentTemplate.options.text &&
    moment.momentTemplate.options.text !== `Who's excited to worship today?`
      ? moment.momentTemplate.options.text
      : t('response.text');
  const buttonText =
    moment.momentTemplate.options.buttonText &&
    moment.momentTemplate.options.buttonText !== 'Me! Glad to be here'
      ? moment.momentTemplate.options.buttonText
      : t('response.button');

  const handleClick = () => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.RESPONSE,
      momentActionType: MomentActionType.SUBMIT,
    });
  };

  return (
    <BaseResponseMoment
      count={moment.count}
      hasSubmitted={hasSubmitted}
      text={text}
      buttonText={buttonText}
      symbol={moment.momentTemplate.options.symbol}
      onResponse={handleClick}
      onDismiss={onDismiss}
      isPinned={isPinned}
    />
  );
};

export default ResponseMoment;
