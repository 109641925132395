import {
  salvationMomentConnect as salvationMomentConnectAction,
  salvationMomentFollowUpDismiss as salvationMomentFollowUpDismissAction,
} from '@features/moments/dux';
import { PaneType } from '@features/pane/dux';
import type { OrganizationState } from '@store/organizationSlice';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { ServiceState } from '@store/serviceSlice';
import { getCurrentService } from '@store/serviceSlice/selectors';
import { basicAuthLogin, handleExternalAuth } from '@store/subscriberSlice';
import { getCurrentSubscriberAsPublicSubscriber } from '@store/subscriberSlice/selectors';
import {
  GeoData,
  heartbeat as dispatchHeartbeat,
  sendResizeHostVideoMetric as sendResizeHostVideoMetricAction,
  sendPaneViewMetric as sendPaneViewMetricAction,
  sendSeeOriginalTranslationMetric,
  sendWebVitalMetric as sendWebVitatlMetricAction,
  setGeoData,
  startHeartbeat,
} from '@store/uiSlice';
import { getGeoData, isHeartbeatStarted } from '@store/uiSlice/selectors';
import { sendFullscreenMetricsEvent } from '@store/videoSlice';
import { isProduction } from '@utils/core';
import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';
import { v4 } from 'uuid';
import { Metric } from 'web-vitals';
import {
  MessageActionType,
  MomentActionType,
  MomentType,
} from '../../../__generated__/globalTypes';
import { METRICS_HOST } from '../../constants';
import { FieldErrorType, PublicSubscriber } from '../../types';
import { get } from '../API';
import { HttpResponse } from '@churchonline/core';

export enum EventType {
  ACCEPT_PRAYER = 'church.life.chop.accept_prayer_v2_0',
  ACCEPTED_INVITE_TO_CHANNEL = 'church.life.chop.accepted_invite_to_channel_v1_0',
  DELETE_MESSAGE = 'church.life.chop.delete_message_v2_1',
  DELETE_MOMENT = 'church.life.chop.moment_deleted_v1_0',
  FULLSCREEN = 'church.life.chop.fullscreen_v1_0',
  HEARTBEAT = 'church.life.chop.heartbeat_v2_5',
  INVITE_TO_CHANNEL = 'church.life.chop.invite_to_channel_v1_0',
  LANGUAGE_CHANGE = 'church.life.chop.language_change_v2_0',
  LOGIN = 'church.life.chop.login_v2_0',
  MOMENT_ACTION = 'church.life.chop.moment_action_v1_0',
  MESSAGE_ACTION = 'church.life.chop.message_action_v1_0',
  MOMENT_SUBMISSION = 'church.life.chop.moment_submission_v2_1',
  MOMENT_VIEW = 'church.life.chop.moment_view_v3_0',
  MUTE_SUBSCRIBER = 'church.life.chop.mute_subscriber_v2_2',
  NOTES_EDITED = 'church.life.chop.notes_edited_v1_0',
  NOTES_EXPORTED = 'church.life.chop.notes_exported_v1_0',
  OFFLINE_PRAYER_REQUEST = 'church.life.chop.offline_prayer_request_v2_1',
  PANE_VIEW = 'church.life.chop.pane_view_v1_0',
  POST_MOMENT = 'church.life.chop.moment_post_v3_0',
  PUBLISH_MESSAGE = 'church.life.chop.chat_message_publish_v2_1',
  REACTION = 'church.life.chop.reaction_v1_0',
  REQUEST_PRAYER = 'church.life.chop.request_prayer_v2_1',
  RESIZE_VIDEO = 'church.life.chop.resize_video_v1_0',
  SALVATION_MOMENT_CONNECT = 'church.life.chop.salvation_moment_connect_v1_0',
  SALVATION_MOMENT_FOLLOW_UP_DISMISS = 'church.life.chop.salvation_moment_follow_up_dismiss_v1_0',
  SEE_ORIGINAL_TRANSLATION = 'church.life.chop.see_original_translation_v1_0',
  SIGN_UP = 'church.life.chop.sign_up_v2_1',
  SIGN_UP_CANCEL = 'church.life.chop.sign_up_cancel_v2_0',
  SIGN_UP_ERROR = 'church.life.chop.sign_up_error_v2_0',
  SUBSCRIBER_DELETED = 'church.life.chop.subscriber_deleted_v1_0',
  SUBSCRIBER_PROFILE_UPDATED = 'church.life.chop.subscriber_profile_updated_v1_0',
  TOGGLE_MOMENTS_AUTOPOST = 'church.life.chop.toggle_moments_autopost_v1_0',
  UNMUTE_SUBSCRIBER = 'church.life.chop.unmute_subscriber_v1_0',
  WEB_VITAL = 'church.life.chop.web_vital_v1_0',
}

const GEO_HOST = 'https://geo.life.church/where';

interface BaseData {
  session_id: string;
  subscriber_id: string;
  organization_id: string;
  referrer: string;
  user_agent: string;
  location: string;
  client: string;
  service_id: string;
  reporting_lag: number;
}

interface MetricsSchema<
  T extends EventType,
  D extends Record<string, any> = Record<string, unknown>
> {
  id: string;
  type: T;
  source: string;
  specversion: string;
  contentType: string;
  time: null;
  data: BaseData & D;
}

interface HeartbeatData {
  interval: number;
  geo: GeoData;
}
type HeartbeatEvent = MetricsSchema<EventType.HEARTBEAT, HeartbeatData>;

interface LoginData {
  previous_subscriber_id: string;
}
type LoginEvent = MetricsSchema<EventType.LOGIN, LoginData>;

interface DeleteMomentData {
  moment_instance_id: string;
}
type DeleteMomentEvent = MetricsSchema<EventType.DELETE_MOMENT, DeleteMomentData>;

interface RequestPrayerData {
  channel_id: string;
  moment_id?: string;
}
type RequestPrayerEvent = MetricsSchema<EventType.REQUEST_PRAYER, RequestPrayerData>;

interface AcceptPrayerData {
  channel_id: string;
}
type AcceptPrayerEvent = MetricsSchema<EventType.ACCEPT_PRAYER, AcceptPrayerData>;

interface PostMomentData {
  moment_instance_id: string;
}
type PostMomentEvent = MetricsSchema<EventType.POST_MOMENT, PostMomentData>;

interface MuteSubscriberData {
  target_subscriber_id: string;
  canceled: boolean;
}
type MuteSubscriberEvent = MetricsSchema<EventType.MUTE_SUBSCRIBER, MuteSubscriberData>;

interface UnmuteSubscriberData {
  target_subscriber_id: string;
}
type UnmuteSubscriberEvent = MetricsSchema<EventType.UNMUTE_SUBSCRIBER, UnmuteSubscriberData>;

interface FullscreenData {
  entering_fullscreen: boolean;
}
type FullscreenEvent = MetricsSchema<EventType.FULLSCREEN, FullscreenData>;

interface DeleteMessageData {
  message_id: string;
  message_subscriber_id: string;
  channel_type: string;
}
type DeleteMessageEvent = MetricsSchema<EventType.DELETE_MESSAGE, DeleteMessageData>;

interface LanguageChangeData {
  old_language: string;
  new_language: string;
  browser_language: string;
}
type LanguageChangeEvent = MetricsSchema<EventType.LANGUAGE_CHANGE, LanguageChangeData>;

interface PublishMessageData {
  channel_id: string;
  message_id: string;
}
type PublishMessageEvent = MetricsSchema<EventType.PUBLISH_MESSAGE, PublishMessageData>;

interface SeeOriginalTranslation {
  message_id: string;
  original_language: string;
}
type SeeOriginalTranslationEvent = MetricsSchema<
  EventType.SEE_ORIGINAL_TRANSLATION,
  SeeOriginalTranslation
>;

interface SignUpData {
  sign_up_id: string;
  sign_up_source: string;
}
type SignUpEvent = MetricsSchema<EventType.SIGN_UP, SignUpData>;

interface SignUpCancelData {
  sign_up_id: string;
}
type SignUpCancelEvent = MetricsSchema<EventType.SIGN_UP_CANCEL, SignUpCancelData>;

interface SignUpErrorData {
  sign_up_id: string;
  nickname_error: FieldErrorType | null;
  email_error: FieldErrorType | null;
  password_error: FieldErrorType | null;
  terms_error: boolean;
}
type SignUpErrorEvent = MetricsSchema<EventType.SIGN_UP_ERROR, SignUpErrorData>;

interface MomentViewData {
  moment_instance_id: string;
}
type MomentViewEvent = MetricsSchema<EventType.MOMENT_VIEW, MomentViewData>;

interface MomentSubmissionData {
  moment_id: string;
  moment_type: MomentType;
}
type MomentSubmissionEvent = MetricsSchema<EventType.MOMENT_SUBMISSION, MomentSubmissionData>;

interface MomentActionData {
  id: string;
  moment_instance_id: string;
  action_type: MomentActionType;
}

interface MessageActionData {
  id: string;
  message_id: string;
  action_type: MessageActionType;
}

type MomentActionEvent = MetricsSchema<EventType.MOMENT_ACTION, MomentActionData>;
type MessageActionEvent = MetricsSchema<EventType.MESSAGE_ACTION, MessageActionData>;

interface OfflinePrayerRequestData {
  canceled: boolean;
  prayer_request_id: string | null;
}
type OfflinePrayerRequestEvent = MetricsSchema<
  EventType.OFFLINE_PRAYER_REQUEST,
  OfflinePrayerRequestData
>;

interface SalvationMomentConnectData {
  moment_instance_id: string;
}
type SalvationMomentConnectEvent = MetricsSchema<
  EventType.SALVATION_MOMENT_CONNECT,
  SalvationMomentConnectData
>;

interface SalvationMomentFollowUpDismissData {
  moment_instance_id: string;
}
type SalvationMomentFollowUpDismissEvent = MetricsSchema<
  EventType.SALVATION_MOMENT_FOLLOW_UP_DISMISS,
  SalvationMomentFollowUpDismissData
>;

interface InviteToChannelData {
  channel_id: string;
  requester_subscriber_id: string;
}
type InviteToChannelEvent = MetricsSchema<EventType.INVITE_TO_CHANNEL, InviteToChannelData>;

interface AcceptedInviteToChannelData {
  channel_id: string;
  accepter_subscriber_id: string;
}
type AcceptedInviteToChannelEvent = MetricsSchema<
  EventType.ACCEPTED_INVITE_TO_CHANNEL,
  AcceptedInviteToChannelData
>;

interface ReactionData {
  value: string;
}
type ReactionEvent = MetricsSchema<EventType.REACTION, ReactionData>;

interface ResizeVideoData {
  subscriber_id: string;
  video_size: 'regular' | 'large';
}
type ResizeVideoEvent = MetricsSchema<EventType.RESIZE_VIDEO, ResizeVideoData>;

interface SubscriberProfileUpdatedData {
  target_subscriber_id: string;
}

type SubscriberProfileUpdatedEvent = MetricsSchema<
  EventType.SUBSCRIBER_PROFILE_UPDATED,
  SubscriberProfileUpdatedData
>;

interface SubscriberDeletedData {
  target_subscriber_id: string;
}

type SubscriberDeletedEvent = MetricsSchema<EventType.SUBSCRIBER_DELETED, SubscriberDeletedData>;

interface ToggleMomentsAutopostData {
  subscriber_id: string;
  autopost: boolean;
}
type ToggleMomentsAutopostEvent = MetricsSchema<
  EventType.TOGGLE_MOMENTS_AUTOPOST,
  ToggleMomentsAutopostData
>;

interface SendWebVitalData {
  web_vital: Pick<Metric, 'id' | 'name' | 'value'> & { label: 'web-vital'; startTime: number };
}
type SendWebVitalEvent = MetricsSchema<EventType.WEB_VITAL, SendWebVitalData>;

type NotesEditedEvent = MetricsSchema<EventType.NOTES_EDITED>;
type NotesExportedEvent = MetricsSchema<EventType.NOTES_EXPORTED>;

interface PaneViewData {
  pane_id: number;
  pane_type: PaneType;
  channel_id: string;
}
type PaneViewEvent = MetricsSchema<EventType.PANE_VIEW, PaneViewData>;

type MetricEvent =
  | AcceptedInviteToChannelEvent
  | AcceptPrayerEvent
  | DeleteMessageEvent
  | DeleteMomentEvent
  | FullscreenEvent
  | HeartbeatEvent
  | InviteToChannelEvent
  | LanguageChangeEvent
  | LoginEvent
  | MomentActionEvent
  | MomentSubmissionEvent
  | MomentViewEvent
  | MuteSubscriberEvent
  | NotesEditedEvent
  | NotesExportedEvent
  | OfflinePrayerRequestEvent
  | PaneViewEvent
  | PostMomentEvent
  | PublishMessageEvent
  | ReactionEvent
  | RequestPrayerEvent
  | ResizeVideoEvent
  | SalvationMomentConnectEvent
  | SalvationMomentFollowUpDismissEvent
  | SeeOriginalTranslationEvent
  | SendWebVitalEvent
  | SignUpCancelEvent
  | SignUpErrorEvent
  | SignUpEvent
  | SubscriberDeletedEvent
  | SubscriberProfileUpdatedEvent
  | ToggleMomentsAutopostEvent
  | UnmuteSubscriberEvent
  | MessageActionEvent;

export const getStagingEvent = (event: EventType): string => {
  const eventParts = event.split('.');
  eventParts[2] = `${eventParts[2]}_staging`;
  return eventParts.join('.');
};

function send(event: MetricEvent): void {
  const url = METRICS_HOST as string;
  const body = JSON.stringify({
    ...event,
    type: isProduction() ? event.type : getStagingEvent(event.type),
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
}

const getBaseWrapper = () => ({
  id: v4(),
  source: window.location.origin,
  specversion: '0.2',
  contentType: 'application/json',
  time: null,
});

function* getBaseData(reportingLag = 0): SagaIterator<BaseData> {
  const sessionId: string = Cookie.get('SESSIONID') ?? '';
  const organization: OrganizationState = yield select(getCurrentOrganization);
  const subscriber: PublicSubscriber = yield select(getCurrentSubscriberAsPublicSubscriber);
  const service: ServiceState = yield select(getCurrentService);

  return {
    session_id: sessionId,
    subscriber_id: subscriber.id,
    organization_id: organization.id,
    referrer: document.referrer,
    user_agent: navigator.userAgent,
    location: window.location.href,
    client: 'chop-web-client',
    service_id: service.id ?? '',
    reporting_lag: reportingLag,
  };
}

// Event Creators

export function* createHeartbeatEvent(
  interval: number,
  pageLoadOffset = 0
): SagaIterator<HeartbeatEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData, pageLoadOffset);
  const geoData: GeoData = yield select(getGeoData);

  const event: HeartbeatEvent = {
    ...baseWrapper,
    type: EventType.HEARTBEAT,
    data: {
      ...baseData,
      interval,
      geo: geoData,
    },
  };

  return event;
}

function* createLoginEvent(previousSubscriberId: string): SagaIterator<LoginEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.LOGIN as EventType.LOGIN,
    data: {
      ...baseData,
      previous_subscriber_id: previousSubscriberId,
    },
  };
}

function* createRequestPrayerEvent(
  channelId: string,
  momentId?: string
): SagaIterator<RequestPrayerEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.REQUEST_PRAYER as EventType.REQUEST_PRAYER,
    data: {
      ...baseData,
      channel_id: channelId,
      moment_id: momentId,
    },
  };
}

function* createAcceptPrayerEvent(channelId: string): SagaIterator<AcceptPrayerEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.ACCEPT_PRAYER as EventType.ACCEPT_PRAYER,
    data: {
      ...baseData,
      channel_id: channelId,
    },
  };
}

function* createPostMomentEvent(momentInstanceId: string): SagaIterator<PostMomentEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.POST_MOMENT as EventType.POST_MOMENT,
    data: {
      ...baseData,
      moment_instance_id: momentInstanceId,
    },
  };
}

function* createMuteSubscriberEvent(
  subscriberId: string,
  canceled: boolean
): SagaIterator<MuteSubscriberEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.MUTE_SUBSCRIBER as EventType.MUTE_SUBSCRIBER,
    data: {
      ...baseData,
      target_subscriber_id: subscriberId,
      canceled: canceled,
    },
  };
}

function* createUnmuteSubscriberEvent(subscriberId: string): SagaIterator<UnmuteSubscriberEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.UNMUTE_SUBSCRIBER as EventType.UNMUTE_SUBSCRIBER,
    data: { ...baseData, target_subscriber_id: subscriberId },
  };
}

function* createDeleteMessageEvent(
  messageId: string,
  messageSubscriberId: string,
  channelType: string
): SagaIterator<DeleteMessageEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.DELETE_MESSAGE as EventType.DELETE_MESSAGE,
    data: {
      ...baseData,
      message_id: messageId,
      message_subscriber_id: messageSubscriberId,
      channel_type: channelType,
    },
  };
}

function* createLanguageChangeEvent(
  newLanguage: string,
  oldLanguage: string,
  browserLanguage: string
): SagaIterator<LanguageChangeEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.LANGUAGE_CHANGE as EventType.LANGUAGE_CHANGE,
    data: {
      ...baseData,
      new_language: newLanguage,
      old_language: oldLanguage,
      browser_language: browserLanguage,
    },
  };
}

function* createPublishMessageEvent(
  channelId: string,
  messageId: string
): SagaIterator<PublishMessageEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.PUBLISH_MESSAGE as EventType.PUBLISH_MESSAGE,
    data: {
      ...baseData,
      channel_id: channelId,
      message_id: messageId,
    },
  };
}

function* createSignUpEvent(signUpId: string, signUpSource: string): SagaIterator<SignUpEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SIGN_UP as EventType.SIGN_UP,
    data: {
      ...baseData,
      sign_up_id: signUpId,
      sign_up_source: signUpSource,
    },
  };
}

function* deleteMomentEvent(id: string): SagaIterator<DeleteMomentEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.DELETE_MOMENT as EventType.DELETE_MOMENT,
    data: {
      ...baseData,
      moment_instance_id: id,
    },
  };
}

function* createSignUpCancelEvent(signUpId: string): SagaIterator<SignUpCancelEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SIGN_UP_CANCEL as EventType.SIGN_UP_CANCEL,
    data: {
      ...baseData,
      sign_up_id: signUpId,
    },
  };
}

function* createSignUpErrorEvent(
  signUpId: string,
  nicknameError: FieldErrorType | null,
  emailError: FieldErrorType | null,
  passwordError: FieldErrorType | null,
  termsError: boolean
): SagaIterator<SignUpErrorEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SIGN_UP_ERROR as EventType.SIGN_UP_ERROR,
    data: {
      ...baseData,
      sign_up_id: signUpId,
      nickname_error: nicknameError,
      email_error: emailError,
      password_error: passwordError,
      terms_error: termsError,
    },
  };
}

function* createMomentViewEvent(momentInstanceId: string): SagaIterator<MomentViewEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.MOMENT_VIEW as EventType.MOMENT_VIEW,
    data: {
      ...baseData,
      moment_instance_id: momentInstanceId,
    },
  };
}

function* createMomentSubmissionEvent(
  id: string,
  type: MomentType
): SagaIterator<MomentSubmissionEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.MOMENT_SUBMISSION as EventType.MOMENT_SUBMISSION,
    data: {
      ...baseData,
      moment_id: id,
      moment_type: type,
    },
  };
}

function* createMomentActionEvent(
  id: string,
  momentInstanceId: string,
  actionType: MomentActionType
): SagaIterator<MomentActionEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.MOMENT_ACTION as EventType.MOMENT_ACTION,
    data: {
      ...baseData,
      id,
      moment_instance_id: momentInstanceId,
      action_type: actionType,
    },
  };
}

function* createMessageActionEvent(
  id: string,
  messageInstanceId: string,
  actionType: MessageActionType
): SagaIterator<MessageActionEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.MESSAGE_ACTION as EventType.MESSAGE_ACTION,
    data: {
      ...baseData,
      id,
      message_id: messageInstanceId,
      action_type: actionType,
    },
  };
}

function* createOfflinePrayerRequestEvent(
  id: string | null,
  canceled: boolean
): SagaIterator<OfflinePrayerRequestEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.OFFLINE_PRAYER_REQUEST as EventType.OFFLINE_PRAYER_REQUEST,
    data: {
      ...baseData,
      prayer_request_id: id,
      canceled,
    },
  };
}

function* createSalvationMomentConnectEvent(
  momentInstanceId: string
): SagaIterator<SalvationMomentConnectEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SALVATION_MOMENT_CONNECT as EventType.SALVATION_MOMENT_CONNECT,
    data: {
      ...baseData,
      moment_instance_id: momentInstanceId,
    },
  };
}

function* createSalvationMomentFollowUpDismissEvent(
  momentInstanceId: string
): SagaIterator<SalvationMomentFollowUpDismissEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SALVATION_MOMENT_FOLLOW_UP_DISMISS as EventType.SALVATION_MOMENT_FOLLOW_UP_DISMISS,
    data: {
      ...baseData,
      moment_instance_id: momentInstanceId,
    },
  };
}

function* createInviteToChannelEvent(
  channelId: string,
  requesterSubscriberId: string
): SagaIterator<InviteToChannelEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.INVITE_TO_CHANNEL as EventType.INVITE_TO_CHANNEL,
    data: {
      ...baseData,
      channel_id: channelId,
      requester_subscriber_id: requesterSubscriberId,
    },
  };
}

function* createAcceptedInviteToChannelEvent(
  channelId: string,
  accepterSubscriberId: string
): SagaIterator<AcceptedInviteToChannelEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.ACCEPTED_INVITE_TO_CHANNEL as EventType.ACCEPTED_INVITE_TO_CHANNEL,
    data: {
      ...baseData,
      channel_id: channelId,
      accepter_subscriber_id: accepterSubscriberId,
    },
  };
}

function* createReactionEvent(value: string): SagaIterator<ReactionEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.REACTION as EventType.REACTION,
    data: {
      ...baseData,
      value,
    },
  };
}

function* createResizeVideoEvent(
  subscriberId: string,
  videoSize: 'regular' | 'large'
): SagaIterator<ResizeVideoEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.RESIZE_VIDEO as EventType.RESIZE_VIDEO,
    data: {
      ...baseData,
      subscriber_id: subscriberId,
      video_size: videoSize,
    },
  };
}

function* createSubscriberProfileUpdatedEvent(): SagaIterator<SubscriberProfileUpdatedEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SUBSCRIBER_PROFILE_UPDATED as EventType.SUBSCRIBER_PROFILE_UPDATED,
    data: {
      ...baseData,
      target_subscriber_id: baseData.subscriber_id,
    },
  };
}

function* createSubscriberDeletedEvent(): SagaIterator<SubscriberDeletedEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SUBSCRIBER_DELETED as EventType.SUBSCRIBER_DELETED,
    data: {
      ...baseData,
      target_subscriber_id: baseData.subscriber_id,
    },
  };
}

export function* createFullscreenEvent(
  entering_fullscreen: boolean
): SagaIterator<FullscreenEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.FULLSCREEN as EventType.FULLSCREEN,
    data: {
      ...baseData,
      entering_fullscreen,
    },
  };
}

export function* createSeeTranslationEvent(values: {
  message_id: string;
  original_language: string;
}): SagaIterator<SeeOriginalTranslationEvent> {
  const { message_id, original_language } = values;
  const baseWrapper = getBaseWrapper();
  const baseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.SEE_ORIGINAL_TRANSLATION as EventType.SEE_ORIGINAL_TRANSLATION,
    data: {
      ...baseData,
      target_subscriber_id: baseData.subscriber_id,
      message_id,
      original_language,
    },
  };
}

function* createNotesEditedEvent(): SagaIterator<NotesEditedEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.NOTES_EDITED as EventType.NOTES_EDITED,
    data: {
      ...baseData,
    },
  };
}

function* createNotesExportedEvent(): SagaIterator<NotesExportedEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.NOTES_EXPORTED as EventType.NOTES_EXPORTED,
    data: {
      ...baseData,
    },
  };
}

function* createPaneViewEvent(
  pane_id: number,
  pane_type: PaneType,
  channel_id: string
): SagaIterator<PaneViewEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.PANE_VIEW as EventType.PANE_VIEW,
    data: {
      ...baseData,
      pane_id,
      pane_type,
      channel_id,
    },
  };
}

function* toggleMomentsAutopostEvent(autopost: boolean): SagaIterator<ToggleMomentsAutopostEvent> {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  return {
    ...baseWrapper,
    type: EventType.TOGGLE_MOMENTS_AUTOPOST as EventType.TOGGLE_MOMENTS_AUTOPOST,
    data: {
      ...baseData,
      subscriber_id: baseData.subscriber_id,
      autopost,
    },
  };
}

// Event Emitters

function* heartbeat() {
  const pageLoadOffset: number = window.PAGE_LOAD_TIME
    ? dayjs().diff(window.PAGE_LOAD_TIME, 'ms')
    : 0;
  const hasHeartbeatStarted: boolean = yield select(isHeartbeatStarted);

  if (hasHeartbeatStarted) {
    return;
  }

  yield put(startHeartbeat());

  // load
  const heartbeat0: HeartbeatEvent = yield call(createHeartbeatEvent, 0, pageLoadOffset);
  yield call(send, heartbeat0);

  // 3 sec
  const delay3s = Math.max(0, 3000 - pageLoadOffset);
  const offset3s = Math.max(0, pageLoadOffset - 3000);
  yield delay(delay3s);
  const heartbeat3: HeartbeatEvent = yield call(createHeartbeatEvent, 3, offset3s);
  yield call(send, heartbeat3);
  yield put(dispatchHeartbeat(3));

  // 10 sec (3 + 7 = 10)
  const delay10s = Math.max(0, 7000 - pageLoadOffset);
  const offset10s = Math.max(0, pageLoadOffset - 10000);
  yield delay(delay10s);
  const heartbeat10: HeartbeatEvent = yield call(createHeartbeatEvent, 10, offset10s);
  yield call(send, heartbeat10);
  yield put(dispatchHeartbeat(10));

  // 30 sec (3 + 7 + 20 = 30)
  yield delay(20000);
  const heartbeat30: HeartbeatEvent = yield call(createHeartbeatEvent, 30);
  yield call(send, heartbeat30);
  yield put(dispatchHeartbeat(30));

  // 60 sec (3 + 7 + 20 + 30 = 60)
  yield delay(30000);
  const heartbeat60: HeartbeatEvent = yield call(createHeartbeatEvent, 60);
  yield call(send, heartbeat60);

  // 1 minute intervals
  while (true) {
    yield delay(60000);
    const heartbeatInterval: HeartbeatEvent = yield call(createHeartbeatEvent, 60);
    yield call(send, heartbeatInterval);
  }
}

function* geoData() {
  const geoResponse: HttpResponse<GeoData> = yield call(get, GEO_HOST);

  const {
    country_code = null,
    region = null,
    city = null,
    latitude = null,
    longitude = null,
  } = geoResponse.parsedBody || {};

  yield put(
    setGeoData({
      country_code,
      region,
      city,
      latitude: latitude ? parseFloat(latitude as unknown as string) : null,
      longitude: longitude ? parseFloat(longitude as unknown as string) : null,
    })
  );
}

function* enterFullscreen(action: ReturnType<typeof sendFullscreenMetricsEvent>) {
  const event: FullscreenEvent = yield call(createFullscreenEvent, action.payload);
  yield call(send, event);
}

function* login(action: ReturnType<typeof basicAuthLogin> | ReturnType<typeof handleExternalAuth>) {
  const event: LoginEvent = yield call(createLoginEvent, action.meta.currentSubscriberId);
  yield call(send, event);
}

function* requestPrayer(channelId: string, momentId?: string) {
  const event: RequestPrayerEvent = yield call(createRequestPrayerEvent, channelId, momentId);
  yield call(send, event);
}

function* acceptPrayer(channelId: string) {
  const event: AcceptPrayerEvent = yield call(createAcceptPrayerEvent, channelId);
  yield call(send, event);
}

function* postMoment(momentInstanceId: string) {
  const event: PostMomentEvent = yield call(createPostMomentEvent, momentInstanceId);
  yield call(send, event);
}

function* muteSubscriber(subscriberId: string, canceled: boolean) {
  const event: MuteSubscriberEvent = yield call(createMuteSubscriberEvent, subscriberId, canceled);
  yield call(send, event);
}

function* unmuteSubscriber(subscriberId: string) {
  const event: UnmuteSubscriberEvent = yield call(createUnmuteSubscriberEvent, subscriberId);
  yield call(send, event);
}

function* deleteMessage(messageId: string, messageSubscriberId: string, channelType: string) {
  const event: DeleteMessageEvent = yield call(
    createDeleteMessageEvent,
    messageId,
    messageSubscriberId,
    channelType
  );
  yield call(send, event);
}

function* languageChange(newLanguage: string, oldLanguage: string, browserLanguage: string) {
  const event: LanguageChangeEvent = yield call(
    createLanguageChangeEvent,
    newLanguage,
    oldLanguage,
    browserLanguage
  );
  yield call(send, event);
}

function* publishMessage(channelId: string, messageId: string) {
  const event: PublishMessageEvent = yield call(createPublishMessageEvent, channelId, messageId);
  yield call(send, event);
}

function* seeOriginalTranslation(action: ReturnType<typeof sendSeeOriginalTranslationMetric>) {
  const { messageId, originalLanguage } = action.payload;

  const event: SeeOriginalTranslationEvent = yield call(createSeeTranslationEvent, {
    message_id: messageId,
    original_language: originalLanguage,
  });

  yield call(send, event);
}

function* signUp(signUpId: string, signUpSource: string) {
  const event: SignUpEvent = yield call(createSignUpEvent, signUpId, signUpSource);
  yield call(send, event);
}

function* signUpCancel(signUpId: string) {
  const event: SignUpCancelEvent = yield call(createSignUpCancelEvent, signUpId);
  yield call(send, event);
}

function* signUpError(
  signUpId: string,
  nicknameError: FieldErrorType | null,
  emailError: FieldErrorType | null,
  passwordError: FieldErrorType | null,
  termsError: boolean
) {
  const event: SignUpErrorEvent = yield call(
    createSignUpErrorEvent,
    signUpId,
    nicknameError,
    emailError,
    passwordError,
    termsError
  );
  yield call(send, event);
}

function* momentView(momentInstanceId: string) {
  const event: MomentViewEvent = yield call(createMomentViewEvent, momentInstanceId);
  yield call(send, event);
}

function* momentSubmission(id: string, type: MomentType) {
  const event: MomentSubmissionEvent = yield call(createMomentSubmissionEvent, id, type);
  yield call(send, event);
}

function* deleteMomentMetric(id: string) {
  const event: DeleteMomentEvent = yield call(deleteMomentEvent, id);
  yield call(send, event);
}

function* momentAction(id: string, momentInstanceId: string, actionType: MomentActionType) {
  const event: MomentActionEvent = yield call(
    createMomentActionEvent,
    id,
    momentInstanceId,
    actionType
  );
  yield call(send, event);
}

function* messageAction(id: string, messageInstanceId: string, actionType: MessageActionType) {
  const event: MessageActionEvent = yield call(
    createMessageActionEvent,
    id,
    messageInstanceId,
    actionType
  );
  yield call(send, event);
}

function* offlinePrayerRequest(id: string | null, canceled: boolean) {
  const event: OfflinePrayerRequestEvent = yield call(
    createOfflinePrayerRequestEvent,
    id,
    canceled
  );
  yield call(send, event);
}

function* salvationMomentConnect(action: ReturnType<typeof salvationMomentConnectAction>) {
  const event: SalvationMomentConnectEvent = yield call(
    createSalvationMomentConnectEvent,
    action.payload.momentInstanceId
  );
  yield call(send, event);
}

function* salvationMomentFollowUpDismiss(
  action: ReturnType<typeof salvationMomentFollowUpDismissAction>
) {
  const event: SalvationMomentFollowUpDismissEvent = yield call(
    createSalvationMomentFollowUpDismissEvent,
    action.payload.momentInstanceId
  );
  yield call(send, event);
}

function* inviteToChannel(channelId: string, requesterSubscriberId: string) {
  const event: InviteToChannelEvent = yield call(
    createInviteToChannelEvent,
    channelId,
    requesterSubscriberId
  );
  yield call(send, event);
}

function* acceptedInviteToChannel(channelId: string, accepterSubscriberId: string) {
  const event: AcceptedInviteToChannelEvent = yield call(
    createAcceptedInviteToChannelEvent,
    channelId,
    accepterSubscriberId
  );
  yield call(send, event);
}

function* reaction(value: string) {
  const event: ReactionEvent = yield call(createReactionEvent, value);
  yield call(send, event);
}

function* subscriberProfileUpdated() {
  const event: SubscriberProfileUpdatedEvent = yield call(createSubscriberProfileUpdatedEvent);
  yield call(send, event);
}

function* subscriberDeleted() {
  const event: SubscriberDeletedEvent = yield call(createSubscriberDeletedEvent);
  yield call(send, event);
}

function* toggleMomentsAutopost(autopost: boolean) {
  const event: ToggleMomentsAutopostEvent = yield call(toggleMomentsAutopostEvent, autopost);
  yield call(send, event);
}

function* sendWebVitalMetric(action: ReturnType<typeof sendWebVitatlMetricAction>) {
  const baseWrapper = getBaseWrapper();
  const baseData: BaseData = yield call(getBaseData);

  const event: SendWebVitalEvent = {
    ...baseWrapper,
    type: EventType.WEB_VITAL,
    data: {
      ...baseData,
      subscriber_id: baseData.subscriber_id,
      web_vital: {
        ...action.payload,
        startTime: 0,
        label: 'web-vital',
      },
    },
  };

  yield call(send, event);
}

function* sendNotesEditedMetric() {
  const event: NotesEditedEvent = yield call(createNotesEditedEvent);
  yield call(send, event);
}

function* sendNotesExportedMetric() {
  const event: NotesExportedEvent = yield call(createNotesExportedEvent);
  yield call(send, event);
}

function* sendPaneViewMetric(action: ReturnType<typeof sendPaneViewMetricAction>) {
  const { pane_id, pane_type, channel_id } = action.payload;
  const event: PaneViewEvent = yield call(createPaneViewEvent, pane_id, pane_type, channel_id);
  yield call(send, event);
}

function* sendResizeHostVideoMetric(action: ReturnType<typeof sendResizeHostVideoMetricAction>) {
  const { subscriber_id, video_size } = action.payload;
  const event: ResizeVideoEvent = yield call(createResizeVideoEvent, subscriber_id, video_size);
  yield call(send, event);
}

export {
  acceptedInviteToChannel,
  acceptPrayer,
  deleteMessage,
  deleteMomentMetric,
  enterFullscreen,
  geoData,
  heartbeat,
  inviteToChannel,
  languageChange,
  login,
  momentAction,
  momentSubmission,
  momentView,
  messageAction,
  muteSubscriber,
  offlinePrayerRequest,
  postMoment,
  publishMessage,
  reaction,
  requestPrayer,
  salvationMomentConnect,
  salvationMomentFollowUpDismiss,
  seeOriginalTranslation,
  send,
  sendNotesEditedMetric,
  sendNotesExportedMetric,
  sendPaneViewMetric,
  sendResizeHostVideoMetric,
  sendWebVitalMetric,
  signUp,
  signUpCancel,
  signUpError,
  subscriberDeleted,
  subscriberProfileUpdated,
  toggleMomentsAutopost,
  unmuteSubscriber,
};
