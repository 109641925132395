import React from 'react';
import Imgix from 'react-imgix';
import { IMAGE_PREFIX } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@lifechurch/react-ion';
import { Wrapper, Title, SupportText } from './styles';

const FiveHundredError = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Imgix src={`${IMAGE_PREFIX}/static/500_comp.png`} width={112} height={122} />
      <Title>{t('internal_server_error')}</Title>
      <SupportText>{t('check_browser_version')}</SupportText>
      <LinkButton href={window.location.href}>{t('refresh')}</LinkButton>
    </Wrapper>
  );
};

export default FiveHundredError;
