import React from 'react';
import { Flex } from '@lifechurch/react-ion';
import { WrapperDiv } from './components/styles';
import Header from './components/header';
import PinnedEngagement from './components/pinned-engagement';
import Engagement from './components/engagement';
const BaseContent = ({ ariaLabel, buttonAlignment, children, fuse = false, hasLiked, hideDismiss = false, icon, isPinned = false, likeButtonLabel = 'Like', likeCount = 0, onDismiss, onLike, onShare, paddingBlockStart = '8px', paddingBlockEnd = '12px', paddingInlineStart = '32px', paddingInlineEnd = '12px', role, shareButtonLabel = 'Share', }) => {
    return (React.createElement(WrapperDiv, { isPinned: isPinned, "aria-label": ariaLabel, role: role, tabIndex: 0 },
        React.createElement(Flex, { position: 'relative', flexDirection: 'column', paddingBlockStart: paddingBlockStart, paddingBlockEnd: paddingBlockEnd, paddingInlineStart: paddingInlineStart, paddingInlineEnd: paddingInlineEnd, backgroundColor: 'gray5', borderRadius: 4, boxShadow: isPinned ? 1 : 0 },
            React.createElement(Header, { icon: icon, fuse: fuse, onDismiss: onDismiss, showDismiss: isPinned && !hideDismiss }),
            children,
            isPinned && (React.createElement(PinnedEngagement, { buttonAlignment: buttonAlignment, hasLiked: hasLiked, likeCount: likeCount, onLike: onLike, onShare: onShare, shareButtonLabel: shareButtonLabel }))),
        !isPinned && (React.createElement(Engagement, { hasLiked: hasLiked, likeButtonLabel: likeButtonLabel, likeCount: likeCount, onLike: onLike, onShare: onShare, shareButtonLabel: shareButtonLabel }))));
};
export default BaseContent;
