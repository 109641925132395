import React from 'react';
import Imgix from 'react-imgix';
import * as Sentry from '@sentry/browser';
import { Component, ErrorInfo } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OParagraph, defaultColors } from '@lifechurch/react-ion';
import { IMAGE_PREFIX } from '../../constants';

export enum ErrorBoundaryDisplayType {
  NONE = 'NONE',
  PANE = 'PANE',
  SIDE_MENU = 'SIDE_MENU',
  HEADER = 'HEADER',
  VIDEO = 'VIDEO',
  VIDEO_EMPTY = 'VIDEO_EMPTY',
  VIDEO_META = 'VIDEO_META',
}

interface Props extends WithTranslation {
  displayType?: ErrorBoundaryDisplayType;
  altComponentName?: string;
}

interface State {
  eventId: string | null;
  hasError: boolean;
}

const VideoMetaErrorBoundary = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-inline-start: 16px;
  min-height: 50px;

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

const EmptyVideoErrorBoundary = styled.div`
  background-color: ${props => props.theme.colors.black};
  flex: 1;
`;

const VideoErrorBoundary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${props => props.theme.colors.black};

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

const HeaderErrorBoundary = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-inline-start: 16px;
  min-height: 64px;

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

const SideMenuErrorBoundary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 240px;

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

const PaneErrorBoundary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: ${props => props.theme.colors.gray30};
  }
`;

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo as unknown as Record<string, unknown>);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId,
      });
    });
  }

  altText = this.props.altComponentName
    ? `${this.props.altComponentName} ${this.props.t('failed_to_load')}`
    : this.props.t('failed_to_load');

  render() {
    if (this.state.hasError) {
      switch (this.props.displayType) {
        case ErrorBoundaryDisplayType.PANE:
          return (
            <PaneErrorBoundary data-testid='pane-error-boundary'>
              <Imgix
                src={`${IMAGE_PREFIX}/static/web-client/component_error_block.png`}
                width={64}
                height={64}
                htmlAttributes={{ alt: this.altText }}
              />
            </PaneErrorBoundary>
          );
        case ErrorBoundaryDisplayType.SIDE_MENU:
          return (
            <SideMenuErrorBoundary data-testid='side-menu-error-boundary'>
              <Imgix
                src={`${IMAGE_PREFIX}/static/web-client/component_error_block.png`}
                width={64}
                height={64}
                htmlAttributes={{ alt: this.altText }}
              />
            </SideMenuErrorBoundary>
          );
        case ErrorBoundaryDisplayType.HEADER:
          return (
            <HeaderErrorBoundary data-testid='header-error-boundary'>
              <Imgix
                src={`${IMAGE_PREFIX}/static/web-client/title_placeholder.png`}
                width={160}
                height={24}
                htmlAttributes={{ alt: this.altText }}
              />
            </HeaderErrorBoundary>
          );
        case ErrorBoundaryDisplayType.VIDEO:
        case ErrorBoundaryDisplayType.VIDEO_EMPTY:
          if (this.props.displayType === ErrorBoundaryDisplayType.VIDEO_EMPTY) {
            return <EmptyVideoErrorBoundary data-testid='video-empty-error-boundary' />;
          }

          return (
            <VideoErrorBoundary data-testid='video-error-boundary'>
              <Imgix
                src={`${IMAGE_PREFIX}/static/web-client/media_component_placeholder.png`}
                width={80}
                height={56}
                htmlAttributes={{ alt: this.altText }}
              />
              <OParagraph color={defaultColors.gray10} marginBlockStart={4}>
                {this.props.t('media_error')}
              </OParagraph>
            </VideoErrorBoundary>
          );
        case ErrorBoundaryDisplayType.VIDEO_META:
          return (
            <VideoMetaErrorBoundary data-testid='video-meta-error-boundary'>
              <Imgix
                src={`${IMAGE_PREFIX}/static/web-client/title_placeholder.png`}
                width={160}
                height={24}
                htmlAttributes={{ alt: this.altText }}
              />
            </VideoMetaErrorBoundary>
          );
        case ErrorBoundaryDisplayType.NONE:
          return null;
        default:
          return <div>There was a problem</div>;
      }
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
