import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowLeftIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M6.64645 11.8535L2.29289 7.49996L6.64645 3.14641L7.35355 3.85352L4.20711 6.99996L12 6.99996V7.99996L4.20711 7.99996L7.35355 11.1464L6.64645 11.8535Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M8.29289 16.707L1.58579 9.99992L8.29289 3.29282L9.70711 4.70703L5.41421 8.99992L18 8.99992V10.9999L5.41421 10.9999L9.70711 15.2928L8.29289 16.707Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M10.2929 20.707L1.58579 11.9999L10.2929 3.29282L11.7071 4.70703L5.41421 10.9999L22 10.9999V12.9999L5.41421 12.9999L11.7071 19.2928L10.2929 20.707Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M12.2929 26.707L1.58579 15.9999L12.2929 5.29282L13.7071 6.70703L5.41422 14.9999L30 14.9999V16.9999L5.41422 16.9999L13.7071 25.2928L12.2929 26.707Z'
          size={size}
          {...rest}
        />
      );
  }
};
