import NavHeaderLinks from './navHeaderLinks';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { CurrentState_currentOrganization_links } from '@io/__generated__/CurrentState';
import { NavigationLinkOption } from '../../../../__generated__/globalTypes';

interface StateProps {
  links: Array<CurrentState_currentOrganization_links>;
  navigationLinkCase: NavigationLinkOption;
  headerTextColor: string;
}

const mapStateToProps = (state: RootState): StateProps => ({
  links: state.organization.links,
  navigationLinkCase: state.organization.appearance.navigationLinkCase,
  headerTextColor: state.organization.appearance.headerTextColor,
});

const VisibleNavHeaderLinks = connect(mapStateToProps)(NavHeaderLinks);

export type Props = StateProps;
export default VisibleNavHeaderLinks;
