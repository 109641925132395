import React, { useState } from 'react';
import Imgix from 'react-imgix';
import styled, { css } from 'styled-components';
import { Box, Flex, getInset, IconSize, Spinner, TimesCircleIcon } from '@lifechurch/react-ion';
export * from './connect';
export * from './giving';
export * from './image';
export * from './invite';
export * from './prayer';
export * from './quote';
export * from './response';
export * from './salvation';
const PreviewWrapper = styled.div `
  position: absolute;

  ${props => props.withMask &&
    css `
      ${getInset('insetBlockEnd', 0)};
    `}
`;
const PreviewContainer = styled.div `
  position: relative;
  overflow: hidden;
  height: ${props => (props.withMask ? '384px' : '674px')};
`;
const FeedWrapper = styled.div `
  position: absolute;
  ${getInset('insetInlineStart', '32px')};
  ${getInset('insetBlockEnd', '133px')};
  width: 328px;
`;
const assetPrefix = 'https://chop.imgix.net/static';
export const Preview = ({ withMask = false, children }) => {
    return (React.createElement(PreviewContainer, { withMask: withMask },
        React.createElement(PreviewWrapper, { withMask: withMask },
            React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/device.png`, width: 392 }),
            React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/chop_top.png`, width: 328, htmlAttributes: { style: { top: '71px', left: '32px', zIndex: 1, position: 'absolute' } } }),
            React.createElement(FeedWrapper, null,
                React.createElement(Flex, { flexDirection: 'column' },
                    React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/chop_chat_messages.png`, width: 328 }),
                    children)),
            React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/chop_bottom.png`, width: 328, htmlAttributes: { style: { top: '541.5px', left: '32px', position: 'absolute' } } }))));
};
const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
`;
const InnerContainer = styled.div `
  bottom: 70px;
  display: flex;
  flex: 1;
  left: 32px;
  position: absolute;
  top: 75px;
  width: 328px;
`;
const FadedBackground = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  padding: 48px 0px 0px;
  position: absolute;
  width: 100%;
`;
const IframeContainer = styled.div `
  align-items: center;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;
const Iframe = styled.iframe `
  height: 100%;
  position: ${({ frameLoading }) => (frameLoading ? 'absolute' : undefined)};
  visibility: ${({ frameLoading }) => (frameLoading ? 'hidden' : undefined)};
  width: 100%;
`;
const IconContainer = styled.div `
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 10;
  cursor: pointer;
`;
export const PreviewWithModal = ({ url, urlTestText }) => {
    const [loading, setLoading] = useState(true);
    const handleIframeLoaded = () => setLoading(false);
    return (React.createElement(Container, null,
        React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/device.png`, width: 392 }),
        React.createElement(Imgix, { src: `${assetPrefix}/moment-preview/chop_top.png`, width: 328, htmlAttributes: { style: { top: '71px', left: '32px', position: 'absolute' } } }),
        React.createElement(InnerContainer, null,
            React.createElement(IconContainer, null,
                React.createElement(TimesCircleIcon, { size: IconSize.XL, color: 'rgba(255, 255, 255, 0.7)', hoverColor: 'rgba(255, 255, 255, 1.000)' })),
            React.createElement(FadedBackground, null,
                React.createElement(IframeContainer, null,
                    loading && !!url && React.createElement(Spinner, { color: 'gray', size: IconSize.XL }),
                    url ? (React.createElement(Iframe, { src: url, frameBorder: '0', onLoad: handleIframeLoaded, frameLoading: loading })) : (React.createElement(Box, { textAlign: 'center', padding: '30px', lineHeight: '1.2rem' },
                        React.createElement("span", null, urlTestText || ''))))))));
};
