import React, { FunctionComponent, useState } from 'react';
import {
  ButtonSize,
  CaretDownIcon,
  CaretUpIcon,
  Flex,
  LinkButton,
  LinkButtonType,
  Input,
  InputSize,
  SecondaryButton,
} from '@lifechurch/react-ion';
import { StyledButton, StyledFlex, Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import { viewingAsUser } from '@store/uiSlice';
import { useAppDispatch, useAppSelector } from '@hooks/redux/hooks';
import { isHost } from '@store/subscriberSlice/selectors';

/**
 * The PreviewService component displays above the service video window and
 * serves two purposes. First, it displays a URL to your preview service that
 * you can copy and share with others. Second, it serves as a marker that
 * helps to distinguish that you are in a preview service.
 * @param param0
 * @returns
 */
const PreviewService: FunctionComponent = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const dispatch = useAppDispatch();
  // @ts-expect-error
  const viewAsUser = useAppSelector(state => state.ui.viewingAsUser);
  // @ts-ignore
  const isAHost = useAppSelector(isHost);

  const handleLeaveService = () => {
    Cookie.remove('preview_service');
    window.location.href = window.location.href.split('?')[0];
  };

  const changeView = () => {
    dispatch(viewingAsUser(!viewAsUser));
  };

  return (
    <StyledFlex expanded={expanded}>
      <StyledButton data-testid='preview-toggle' onClick={() => setExpanded(!expanded)}>
        {t('preview.preview')}
        {expanded ? <CaretUpIcon /> : <CaretDownIcon />}
      </StyledButton>
      {expanded && (
        <Wrapper>
          <Input
            size={InputSize.MD}
            value={window.location.href}
            readOnly={true}
            hasCopy
            data-testid='url-input'
          />
          {isAHost && (
            <Flex>
              <SecondaryButton
                size={ButtonSize.MD}
                onClick={changeView}
                mx='auto'
                mt={5}
                data-testid='change-view-button'
              >
                {viewAsUser ? t('preview.view_host_tools') : t('preview.view_guest_experience')}
              </SecondaryButton>
            </Flex>
          )}
          <Flex>
            <LinkButton
              onClick={handleLeaveService}
              mx='auto'
              my={5}
              buttonType={LinkButtonType.SECONDARY}
            >
              {t('preview.leave_preview_service')}
            </LinkButton>
          </Flex>
        </Wrapper>
      )}
    </StyledFlex>
  );
};

export default PreviewService;
