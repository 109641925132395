import React, { createRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '@churchonline/hooks';
import { Box, ButtonSize, defaultColors, Flex, getInset, IconSize, InfoCircleIcon, OHeaderThree, TimesIcon, } from '@lifechurch/react-ion';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, CopyShareButton, } from '../../sharesheet/sharing-buttons';
const ModalWrapper = styled(Flex) `
  flex-direction: column;
  position: absolute;
  padding: 151px 16px;
  background-color: rgba(30, 31, 35, 0.5);
  z-index: 1000;
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  margin-block-end: -56px;
`;
const StyledModal = styled(Box) `
  position: relative;
  width: 100%;
  border-radius: ${props => props.theme.radii[2]}px;
  background-color: ${props => props.theme.colors.white};
  margin: auto;
  padding: ${props => props.theme.space[5]}px;
  text-align: start;
`;
const InfoBox = styled(Box) `
  margin-block-start: 8px;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 4px;
  background-color: #ececec;
  padding: 14px;
`;
const DismissButton = styled.button `
  position: absolute;
  background: none;
  border: none;
  outline: none;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  padding-block-start: 16px;
  padding-block-end: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 16px;

  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.gray100};
      }
    }
  }
`;
export const Modal = ({ dismiss, open, }) => {
    const modalRef = createRef();
    useOnClickOutside(modalRef, () => dismiss());
    if (!open)
        return null;
    return (React.createElement(ModalWrapper, { "data-testid": 'modal-wrapper' },
        React.createElement(StyledModal, { ref: modalRef, "data-testid": 'invite-preview-modal' },
            React.createElement(DismissButton, { onClick: dismiss, type: 'button', "data-testid": 'invite-preview-dismiss-id' },
                React.createElement(TimesIcon, { color: defaultColors.gray50 })),
            React.createElement(Box, { marginBlockStart: 4 },
                React.createElement(Box, { paddingInlineEnd: 7 },
                    React.createElement(OHeaderThree, { paddingBlockEnd: 5 }, "Share")),
                React.createElement(Flex, { flexDirection: 'row', justifyContent: 'center' },
                    React.createElement(FacebookShareButton, { size: ButtonSize.XS, url: null }),
                    React.createElement(TwitterShareButton, { content: null, size: ButtonSize.XS, url: null }),
                    React.createElement(EmailShareButton, { content: '', size: ButtonSize.XS, subject: null }),
                    React.createElement(CopyShareButton, { content: null, buttonText: 'Copy\xa0URL', size: ButtonSize.XS })))),
        React.createElement(InfoBox, { marginBlockStart: 3, ref: modalRef, "data-testid": 'testId' },
            React.createElement(Box, null,
                React.createElement(InfoCircleIcon, { size: IconSize.SM, color: defaultColors.gray100 })),
            React.createElement(Box, { marginInlineStart: 3 }, "If supported, the device\u2019s native sharing method will be utilized."))));
};
