import { createSelector } from 'reselect';
import { AuthProvider, PublicSubscriber, Subscriber } from '../../types';
import { SubscriberState } from '@store/subscriberSlice';
import { TextMode, RoleIdentifierType } from '../../../__generated__/globalTypes';
import { RootState } from '@store/rootReducer';

const local = (state: RootState): SubscriberState => state.subscriber;

export const getCurrentSubscriberAvatar = (state: RootState): string | null =>
  local(state).currentSubscriber.avatar;

export const getCurrentSubscriber = (state: RootState): Subscriber =>
  local(state).currentSubscriber;

export const getAuthProvider = (state: RootState): AuthProvider | null => local(state).provider;

export const checkForPermissions = (
  subscriber: Subscriber,
  requiredPermissions: Array<string>,
  requireAll: boolean
): boolean => {
  if (requireAll) {
    return requiredPermissions.every(
      permission => subscriber.role?.permissions?.includes(permission) || false
    );
  } else {
    return (
      subscriber.role?.permissions?.some(
        permission => requiredPermissions.includes(permission) || false
      ) || false
    );
  }
};

export const hasPermissions = createSelector(
  getCurrentSubscriber,
  (_state: RootState, requiredPermissions: Array<string>, _requireAll: boolean) =>
    requiredPermissions,
  (_state, _requiredPermissions, requireAll) => requireAll,
  checkForPermissions
);

export const subscriberToPublicSubscriber = (subscriber: Subscriber): PublicSubscriber => {
  const { id, nickname, avatar, roleIdentifier } = subscriber;
  const roleIdentifierType = roleIdentifier?.type ? roleIdentifier.type : RoleIdentifierType.TEXT;
  return {
    id,
    nickname,
    avatar,
    roleIdentifier: {
      label: roleIdentifier?.label || '',
      key: roleIdentifier?.key || '',
      type: roleIdentifierType,
    },
  };
};

export const getCurrentSubscriberAsPublicSubscriber = createSelector<
  RootState,
  Subscriber,
  PublicSubscriber
>([getCurrentSubscriber], subscriberToPublicSubscriber);

export const getTextMode = createSelector<RootState, Subscriber, TextMode>(
  [getCurrentSubscriber],
  subscriber => subscriber.preferences.textMode || TextMode.COMPACT
);

export const isHost = createSelector<RootState, Subscriber, boolean>(
  [getCurrentSubscriber],
  subscriber => !(subscriber.role?.label === '' || subscriber.role === undefined)
);

export const getSkinTone = createSelector<RootState, Subscriber, number>(
  [getCurrentSubscriber],
  subscriber => subscriber.preferences.skinTone || 1
);
