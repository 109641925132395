import React, { useState } from 'react';
import { BallConfetti, Box, ButtonSize, ChatPaneModal, IDetail, OHeaderThree, PrimaryButton, SecondaryTextOnlyButton, } from '@lifechurch/react-ion';
import styled from 'styled-components';
const ModalContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;
function NewTab({ expandToFillArea = false, isPreview = false, onFollowUpResponse, maxWindowHeight, labels, onDismiss, selector, }) {
    const [visible, setVisible] = useState(true);
    const { celebrationMessage, buttonText } = labels;
    const handleDismiss = () => {
        if (isPreview)
            return;
        setVisible(false);
        setTimeout(() => onDismiss(), 350);
    };
    return (React.createElement(ChatPaneModal, { containerStyle: isPreview ? { marginRight: '0px' } : undefined, expandToFillArea: expandToFillArea, maxHeight: maxWindowHeight, visible: visible, selector: selector },
        React.createElement(ModalContainer, null,
            React.createElement(Box, { mb: '20px' },
                React.createElement(BallConfetti, null)),
            React.createElement(OHeaderThree, { style: { marginBottom: '15px' } }, labels.followUpHeader || 'Congratulations!'),
            React.createElement(IDetail, { style: { marginBottom: '15px', textAlign: 'center' } }, celebrationMessage),
            React.createElement(Box, { mb: '15px' },
                React.createElement(PrimaryButton, { size: ButtonSize.SM, onClick: onFollowUpResponse, textIsOpaque: buttonText === '' && true }, buttonText || 'Button Text')),
            React.createElement(SecondaryTextOnlyButton, { size: ButtonSize.SM, onClick: handleDismiss }, labels.dismiss || 'Dismiss'))));
}
export { NewTab };
