const http = (url, options) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(url, options)
            .then(res => {
            response = res;
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
                return res.json();
            }
            else {
                return res.text();
            }
        })
            .then(body => {
            if (response.ok) {
                response.parsedBody = body;
                resolve(response);
            }
            else {
                reject(response);
            }
        })
            .catch(err => {
            reject(err);
        });
    });
};
export const get = async (url, args) => {
    return await http(url, {
        method: 'GET',
        credentials: 'include',
        ...args,
    });
};
export const post = async (url, body, args = {
    method: 'POST',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
    },
}) => {
    return await http(url, {
        method: 'POST',
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
        ...args,
    });
};
