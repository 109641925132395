import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const DocumentIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M12 1H3V14H10L12 12V1ZM9 11H11V11.5858L9.58579 13H9V11Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16 2H4V18H13L16 15V2ZM12 14H14V14.1716L12.1716 16H12V14Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20 2H4V22H16L20 18V2ZM15 17H18L18 17.1716L15.1716 20H15V17Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M26 2H6V30H21L26 25V2ZM20 24H24L24 24.1716L20.1716 28H20V24Z'
          size={size}
          {...rest}
        />
      );
  }
};
