import React from 'react';
import Responsive, { toQuery } from 'react-responsive';

const LandscapeMobile =
  'only screen and (max-height: 450px) and (min-width: 450px) and (max-width: 1200px)';

const PortraitKeyboardOpen = 'only screen and (max-height: 450px) and (max-width: 450px)';
const PortraitKeyboardOpenSmall = 'only screen and (max-height: 275px) and (max-width: 450px)'; //Small 4.4" Phones
const PortraitKeyboardOpenMedium =
  'only screen and (min-height: 276px) and (max-height: 310px) and (max-width: 450px)'; //Medium 5" - 6"
const PortraitKeyboardOpenLarge =
  'only screen and (min-height: 311px) and (max-height: 398px) and (max-width: 450px)'; //Large 7" - 9"
const PortraitKeyboardOpenXLarge =
  '(min-height: 399px) and (max-height: 460px) and (max-width: 450px)'; //XLarge 10" - 11"
const PortraitKeyboardOpenXLargeDown = '(max-height: 460px) and (max-width: 450px)'; //XLarge 10" - 11"

const MobileHeightWithKeyboardOpen = 'only screen and (max-height: 350px)';

const Small = React.memo(props => <Responsive {...props} maxWidth={639} />);
Small.displayName = 'Small';
export const SmallQuery = toQuery({ maxWidth: 639 });

const Medium = React.memo(props => <Responsive {...props} minWidth={640} maxWidth={767} />);
Medium.displayName = 'Medium';
const MediumQuery = toQuery({ minWidth: 640, maxWidth: 1023 });

const MediumDown = React.memo(props => <Responsive {...props} maxWidth={767} />);
MediumDown.displayName = 'MediumDown';

const MediumUp = React.memo(props => <Responsive {...props} minWidth={640} />);
MediumUp.displayName = 'MediumUp';
export const MediumUpQuery = toQuery({ minWidth: 640 });

const MediumPlus = React.memo(props => <Responsive {...props} minWidth={768} maxWidth={1023} />);
MediumPlus.displayName = 'MediumPlus';
export const MediumPlusQuery = toQuery({ minWidth: 758, maxWidth: 1023 });

const MediumPlusDown = React.memo(props => <Responsive {...props} maxWidth={1023} />);
MediumPlusDown.displayName = 'MediumPlusDown';

const MediumPlusUp = React.memo(props => <Responsive {...props} minWidth={768} />);
MediumPlusUp.displayName = 'MediumPlusUp';
export const MediumPlusUpQuery = toQuery({ minWidth: 768 });

const Large = React.memo(props => <Responsive {...props} minWidth={1024} maxWidth={1199} />);
Large.displayName = 'Large';
export const LargeQuery = toQuery({ minWidth: 1024, maxWidth: 1119 });

const LargeDown = React.memo(props => <Responsive {...props} maxWidth={1199} />);
LargeDown.displayName = 'LargeDown';

const LargeUp = React.memo(props => <Responsive {...props} minWidth={1024} />);
LargeUp.displayName = 'LargeUp';
export const LargeUpQuery = toQuery({ minWidth: 1024 });

const Xlarge = React.memo(props => <Responsive {...props} minWidth={1200} maxWidth={1399} />);
Xlarge.displayName = 'Xlarge';
export const XlargeQuery = toQuery({ minWidth: 1200, maxWidth: 1399 });

const XlargeUp = React.memo(props => <Responsive {...props} minWidth={1200} />);
XlargeUp.displayName = 'XlargeUp';
export const XlargeUpQuery = toQuery({ minWidth: 1200 });

const XXlarge = React.memo(props => <Responsive {...props} minWidth={1400} />);
XXlarge.displayName = 'XXlarge';
export const XXlargeQuery = toQuery({ minWidth: 1400 });

export {
  LandscapeMobile,
  PortraitKeyboardOpen,
  PortraitKeyboardOpenSmall,
  PortraitKeyboardOpenMedium,
  PortraitKeyboardOpenLarge,
  PortraitKeyboardOpenXLarge,
  PortraitKeyboardOpenXLargeDown,
  MobileHeightWithKeyboardOpen,
  Small,
  Medium,
  MediumQuery,
  MediumDown,
  MediumUp,
  MediumPlus,
  MediumPlusDown,
  MediumPlusUp,
  Large,
  LargeDown,
  LargeUp,
  Xlarge,
  XlargeUp,
  XXlarge,
};
