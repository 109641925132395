import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { IntegrationType } from '../../../../__generated__/globalTypes';
import { getIntegrationTypes } from '@store/organizationSlice/selectors';
import SsoValueProp from './ssoValueProp';

interface StateProps {
  externalLoginUrl?: string;
}

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const integrationTypes = getIntegrationTypes(state);

  let externalLoginUrl;
  if (integrationTypes.includes(IntegrationType.OPEN_ID_CONNECT)) {
    externalLoginUrl = '/auth/openid_connect';
  } else if (integrationTypes.includes(IntegrationType.LEGACY_AUTH)) {
    externalLoginUrl = '/auth/legacy';
  }

  return {
    externalLoginUrl: externalLoginUrl,
    ...ownProps,
  };
};

const VisibleLeaveChat = connect(mapStateToProps)(SsoValueProp);

export type Props = StateProps & OwnProps;
export default VisibleLeaveChat;
