import { call, put } from 'redux-saga/effects';
import { FetchResult } from '@apollo/client';
import queries from '../queries';
import * as Sentry from '@sentry/browser';
import { PublishRequestPrayer } from '@components/modal/requestPrayer/dux';
import { RequestPrayerMutation } from '../__generated__/RequestPrayerMutation';
import { errorAlert, successAlert } from '@components/Alert/dux';
import { offlinePrayerRequest as offlinePrayerRequestMetric } from './metrics';
import { clearModal } from '@store/uiSlice';

function* requestPrayer(action: PublishRequestPrayer) {
  try {
    const result: FetchResult<RequestPrayerMutation> = yield call(
      [queries, queries.requestPrayer],
      action.payload
    );
    if (result.data?.requestPrayer?.errors.length === 0) {
      yield put(clearModal());
      yield put(successAlert('request_prayer_success'));
      yield call(offlinePrayerRequestMetric, action.payload.id, false);
    } else {
      Sentry.captureMessage('Server returned false for requestPrayer');
      yield put(errorAlert('request_prayer_error'));
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(errorAlert('request_prayer_error'));
  }
}

export { requestPrayer };
