import React, { ChangeEventHandler, forwardRef, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';

interface CheckboxProps {
  name: string;
  checked?: boolean;
  onChange?: ChangeEventHandler;
  hint?: ReactNode;
  error?: boolean;
  disabled?: boolean;
}

interface ContextProps {
  error: boolean;
}

const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes[3]};
  line-height: ${props => props.theme.lineHeights[2]};
  display: inline-block;
`;

const StyledCheckbox = styled.input`
  margin-block-start: 3px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 4px;
  flex-shrink: 0;
`;

const Context = styled.div<ContextProps>`
  font-size: ${props => props.theme.fontSizes[1]};
  line-height: ${props => props.theme.lineHeights[2]};
  color: ${props => (props.error ? props.theme.colors.error.default : props.theme.colors.gray50)};
  margin-block-start: 2px;
  margin-block-end: 0;
  margin-inline-start: 17px;
  margin-inline-end: 0;
`;

const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
  (
    {
      children,
      name,
      checked,
      onChange,
      hint,
      error = false,
      disabled,
    }: PropsWithChildren<CheckboxProps>,
    ref
  ) => (
    <>
      <Flex alignItems='flex-start'>
        <StyledCheckbox
          type='checkbox'
          id={name}
          ref={ref}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <StyledLabel htmlFor={name}>{children}</StyledLabel>
      </Flex>
      {hint && <Context error={error}>{hint}</Context>}
    </>
  )
);

Checkbox.displayName = 'ForwardedCheckbox';

export { Checkbox, CheckboxProps };
