import React, { createRef, FunctionComponent, useState, ReactNode } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import styled from 'styled-components';
import { useOnClickOutside } from '@churchonline/hooks';
import { Portal } from './Portal';

interface PopOverTrayProps {
  zIndex?: number;
}

const PopOverTray = styled.div<PopOverTrayProps>`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radii[1]}px;
  box-shadow: ${props => props.theme.shadows[1]};
  z-index: ${props => (props.zIndex ? props.zIndex : 'auto')};
`;

interface ButtonWrapperProps {
  isDropdownOpen: boolean;
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  line-height: 0;

  svg {
    path {
      opacity: ${props => (props.isDropdownOpen ? 1.0 : 0.5)};
    }
  }

  &:hover {
    svg {
      path {
        opacity: 1;
      }
    }
  }
`;

interface Props {
  content: ReactNode;
  selector?: string;
  zIndex?: number;
}

export const PopOver: FunctionComponent<Props> = ({
  content,
  selector = '#popover',
  zIndex,
  children,
}) => {
  const outerRef = createRef<HTMLDivElement>();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const toggleShowDropdown = () => setShowDropdown(!showDropdown);

  useOnClickOutside(outerRef, () => showDropdown && toggleShowDropdown());

  return (
    <Manager>
      <Reference>
        {({ ref: referenceRef }) => {
          return (
            <ButtonWrapper
              ref={referenceRef}
              isDropdownOpen={showDropdown}
              onClick={toggleShowDropdown}
            >
              {children}
            </ButtonWrapper>
          );
        }}
      </Reference>
      {showDropdown && (
        <Portal selector={selector}>
          <Popper placement='bottom'>
            {({ placement, ref: popperRef, style }) => (
              <PopOverTray ref={popperRef} style={style} data-placement={placement} zIndex={zIndex}>
                <div ref={outerRef} onClick={toggleShowDropdown}>
                  {content}
                </div>
              </PopOverTray>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  );
};
