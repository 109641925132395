import { connect } from 'react-redux';

import UploadAvatar from './uploadAvatar';
import { uploadAvatar } from '@store/subscriberSlice';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { Subscriber } from '../../../../types';
import { ModalType, removeAvatar } from '../../dux';
import { errorAlert } from '../../../Alert/dux';
import { setModal } from '@store/uiSlice';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface StateProps {
  currentSubscriber: Subscriber;
}

interface OwnProps {
  nickname: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  currentSubscriber: getCurrentSubscriber(state),
  ...ownProps,
});

interface DispatchProps {
  uploadAvatar: (id: string, formData: FormData) => void;
  promptRemoveAvatar: () => void;
  imageTooLargeError: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadAvatar: (id: string, formData: FormData) =>
    dispatch(uploadAvatar({ id, formData, keepSubmissionFormAlive: true })),
  promptRemoveAvatar: () => dispatch(setModal(removeAvatar({ modal: ModalType.ACCEPT_INVITE }))),
  imageTooLargeError: () => dispatch(errorAlert('max_file_size')),
});

const VisibleUploadAvatar = connect(mapStateToProps, mapDispatchToProps)(UploadAvatar);

export type Props = StateProps & DispatchProps & OwnProps;
export default VisibleUploadAvatar;
