import React, { FunctionComponent } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { CountdownWrapper } from './styles';
import { ClockIcon, IconSize } from '@lifechurch/react-ion';

interface CountdownRendererProps {
  hours: number;
  minutes: number;
  seconds: number;
}

interface CountdownProps {
  date: string;
}

const countdownWithHoursStyle = {
  minWidth: '45px',
};

export const CountdownRenderer: FunctionComponent<CountdownRendererProps> = ({
  hours,
  minutes,
  seconds,
}) => (
  <>
    {hours > 0 ? (
      <span data-testid='mediaHeader-countdown' style={countdownWithHoursStyle}>
        {hours > 0 ? `${hours}:` : ''}
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    ) : (
      <span data-testid='mediaHeader-countdown'>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )}
  </>
);

const WrappedCountdown: FunctionComponent<CountdownProps> = ({ date }) => (
  <CountdownWrapper>
    <ClockIcon size={IconSize.SM} />
    <Countdown date={date} renderer={CountdownRenderer} />
  </CountdownWrapper>
);

export default React.memo<CountdownProps>(WrappedCountdown);
