import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ExitIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M8.00003 15V17H15L17 17L17 3L15 3H8.00003V5L15 5V15H8.00003ZM3.29292 9.2929L2.58582 10L3.29292 10.7071L7.29292 14.7071L8.70714 13.2929L6.41424 11H13V9L6.41424 9L8.70714 6.70711L7.29292 5.2929L3.29292 9.2929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M9.00003 5V3H19H21V5V19V21H19H9.00003V19H19V5L9.00003 5ZM3.29292 11.2929L2.58582 12L3.29292 12.7071L8.29292 17.7071L9.70714 16.2929L6.41424 13L16 13V11L6.41424 11L9.70714 7.70711L8.29292 6.29289L3.29292 11.2929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M11 28H25H26L26 27V5V4H25H11V6L24 6V26H11V28ZM4.29292 15.2929L3.58582 16L4.29292 16.7071L10.2929 22.7071L11.7071 21.2929L7.41424 17H21V15H7.41424L11.7071 10.7071L10.2929 9.29289L4.29292 15.2929Z'
          size={size}
          {...rest}
        />
      );
  }
};
