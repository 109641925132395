import styled from 'styled-components';
import { LargeUpQuery, MediumPlusQuery, MediumQuery } from '@utils/responsive';

export const ImageWrapper = styled.div`
  background-color: ${props => props.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ImageContainer = styled.div<{
  isDuringService: boolean;
}>`
  background-color: ${props => props.theme.colors.black};
  display: flex;
  flex-grow: 1;

  @media ${MediumQuery} {
    max-width: 460px;
  }

  @media ${MediumPlusQuery} {
    max-width: 640px;
  }

  @media ${LargeUpQuery} {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
    // formula calc ((16/9)*100) - ([height of elements] * (16/9))
    max-width: ${props => (props.isDuringService ? 'calc(177vh - 238px)' : 'calc(177vh - 347px)')};
  }
`;

export const Image = styled.div<{ url: string }>`
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  width: 100%;
  flex: 1;

  padding-block-end: 56.25%;
  margin: auto 0px;
`;
