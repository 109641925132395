import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const UsersIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M8 5C8 5.25665 7.95166 5.50201 7.86358 5.72747C7.50249 6.00613 7.19735 6.35376 6.96788 6.75062C6.68108 6.90953 6.35111 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5ZM12.1364 5.72747C12.4975 6.00613 12.8027 6.35376 13.0321 6.75062C13.3189 6.90953 13.6489 7 14 7C15.1046 7 16 6.10457 16 5C16 3.89543 15.1046 3 14 3C12.8954 3 12 3.89543 12 5C12 5.25665 12.0483 5.50201 12.1364 5.72747ZM7.55001 8C7.78164 6.85888 8.79052 6 10 6C11.2095 6 12.2184 6.85888 12.45 8C12.4828 8.16155 12.5 8.32877 12.5 8.5C12.5 9.88071 11.3807 11 10 11C8.61929 11 7.5 9.88071 7.5 8.5C7.5 8.32877 7.51722 8.16155 7.55001 8ZM4 8H6.53544C6.51209 8.1633 6.5 8.33024 6.5 8.5C6.5 9.47934 6.90223 10.3647 7.55051 11H7C6.1115 11 5.31321 11.3863 4.76389 12H2V10C2 8.89543 2.89543 8 4 8ZM13 11C13.8885 11 14.6868 11.3863 15.2361 12H18V10C18 8.89543 17.1046 8 16 8H13.4646C13.4879 8.1633 13.5 8.33024 13.5 8.5C13.5 9.47934 13.0978 10.3647 12.4495 11H13ZM5 14C5 12.8954 5.89543 12 7 12H13C14.1046 12 15 12.8954 15 14V16H5V14Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M6.54237 8.81396C6.71564 7.42142 7.40948 6.19045 8.42304 5.3219C8.11569 3.99166 6.92365 3 5.5 3C3.84315 3 2.5 4.34315 2.5 6C2.5 7.65685 3.84315 9 5.5 9C5.86652 9 6.2177 8.93427 6.54237 8.81396ZM15.577 5.3219C16.5905 6.19045 17.2844 7.42142 17.4576 8.81396C17.7823 8.93427 18.1335 9 18.5 9C20.1569 9 21.5 7.65685 21.5 6C21.5 4.34315 20.1569 3 18.5 3C17.0763 3 15.8843 3.99166 15.577 5.3219ZM4 10H6.52242C6.58946 10.744 6.80464 11.4452 7.13824 12.074C5.11374 12.4257 3.50701 13.995 3.10002 16H1V13C1 11.3431 2.34315 10 4 10ZM16.8618 12.074C18.8863 12.4257 20.493 13.995 20.9 16H23V13C23 11.3431 21.6569 10 20 10H17.4776C17.4105 10.744 17.1954 11.4452 16.8618 12.074ZM18.8293 16C18.4175 14.8348 17.3062 14 16 14H15.1631H14H10H8.83692H8C6.69378 14 5.58254 14.8348 5.17071 16C5.06015 16.3128 5 16.6494 5 17V20H19V17C19 16.6494 18.9398 16.3128 18.8293 16ZM9.55051 12C9.66667 12.1138 9.79073 12.2196 9.92179 12.3165C10.503 12.746 11.2218 13 12 13C12.7782 13 13.497 12.746 14.0782 12.3165C14.2093 12.2196 14.3333 12.1138 14.4495 12C14.8705 11.5874 15.1878 11.0694 15.3584 10.4886C15.4506 10.1751 15.5 9.84331 15.5 9.5C15.5 7.567 13.933 6 12 6C10.067 6 8.5 7.567 8.5 9.5C8.5 9.84331 8.54943 10.1751 8.64156 10.4886C8.81224 11.0694 9.12949 11.5874 9.55051 12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M13.9323 5.3104C11.1094 6.18194 9.04916 8.79114 9.00087 11.8887C6.99644 11.4343 5.5 9.6419 5.5 7.5C5.5 5.01472 7.51472 3 10 3C11.6904 3 13.1632 3.93211 13.9323 5.3104ZM6 14H9.28988C9.63282 15.1523 10.2645 16.1803 11.101 17H11C8.38756 17 6.16508 18.6696 5.34141 21H2V18C2 15.7909 3.79086 14 6 14ZM24.4649 21C23.7733 19.8044 22.4806 19 21 19H16H11C9.51944 19 8.22675 19.8044 7.53513 21C7.19479 21.5883 7 22.2714 7 23V27H25V23C25 22.2714 24.8052 21.5883 24.4649 21ZM30 21H26.6586C25.8349 18.6696 23.6124 17 21 17H20.899C21.7355 16.1803 22.3672 15.1523 22.7101 14H26C28.2091 14 30 15.7909 30 18V21ZM26.5 7.5C26.5 9.6419 25.0036 11.4343 22.9991 11.8887C22.9508 8.79114 20.8906 6.18194 18.0677 5.3104C18.8368 3.93211 20.3096 3 22 3C24.4853 3 26.5 5.01472 26.5 7.5ZM20.9988 11.8882C20.9508 9.70117 19.4985 7.86026 17.5079 7.23139C17.0321 7.08107 16.5255 7 16 7C15.4745 7 14.9679 7.08107 14.4921 7.23139C12.5015 7.86026 11.0492 9.70117 11.0012 11.8882C11.0004 11.9254 11 11.9626 11 12C11 12.7111 11.1484 13.3875 11.416 14C12.1876 15.7659 13.9497 17 16 17C18.0503 17 19.8124 15.7659 20.584 14C20.8516 13.3875 21 12.7111 21 12C21 11.9626 20.9996 11.9254 20.9988 11.8882Z'
          size={size}
          {...rest}
        />
      );
  }
};
