import { connect } from 'react-redux';
import SelectChatName from './selectChatName';
import { login } from '../dux';
import { updateGuestNickname } from '@store/subscriberSlice';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { RootState } from '@store/rootReducer';
import { SelectChatNameMeta, SelectChatNameSubtype } from './dux';
import { setModal } from '@store/uiSlice';
import { Dispatch } from 'redux';

interface StateProps {
  subscriberId: string;
  nicknameUnavailable: boolean;
}

interface OwnProps {
  closeModal: () => void;
  subtype: SelectChatNameSubtype;
  meta?: SelectChatNameMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  subscriberId: getCurrentSubscriber(state).id,
  nicknameUnavailable: ownProps.meta?.nicknameUnavailable || false,
  ...ownProps,
});

interface DispatchProps {
  openLogin: () => void;
  updateAndDoAction: (
    id: string,
    nickname: string,
    action: 'prayer' | 'chat',
    momentId?: string
  ) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openLogin: () => dispatch(setModal(login())),
  updateAndDoAction: (id, nickname, action, momentId) =>
    dispatch(updateGuestNickname({ id, nickname, action, momentId })),
});

const VisibleSelectChatName = connect(mapStateToProps, mapDispatchToProps)(SelectChatName);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleSelectChatName;
