import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Props } from './index';
import {
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  ModalText,
  OParagraph,
} from '@lifechurch/react-ion';

const LeaveChat = ({
  closeModal,
  leaveChannel,
  otherSubscriber,
  hasOtherSubscribers,
  channel,
}: Props) => {
  const { t } = useTranslation('forms');
  const callLeaveChannel = () => {
    closeModal();
    leaveChannel(channel);
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='leaveChat-modal'>
      {hasOtherSubscribers && (
        <ModalText>
          <OParagraph marginBlockEnd={5} data-testid='leaveChat-confirmText'>
            <Trans ns='forms' i18nKey='leave_chat.confirm_user'>
              Are you sure you want to end your chat with{' '}
              <strong>{{ name: otherSubscriber.nickname }}</strong>?
            </Trans>
          </OParagraph>
        </ModalText>
      )}
      {!hasOtherSubscribers && (
        <ModalText>
          <OParagraph marginBlockEnd={5}>{t('leave_chat.confirm')}</OParagraph>
        </ModalText>
      )}
      <ModalButtons>
        <LinkButton
          buttonType={LinkButtonType.SECONDARY}
          onClick={closeModal}
          data-testid='leaveChat-keepChatting'
          marginInlineEnd={5}
        >
          {t('leave_chat.keep_chatting')}
        </LinkButton>
        <LinkButton onClick={callLeaveChannel} data-testid='leaveChat-leaveButton'>
          {t('leave_chat.leave')}
        </LinkButton>
      </ModalButtons>
    </Modal>
  );
};

export default LeaveChat;
