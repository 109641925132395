import { useEffect, RefObject } from 'react';
import useLatest from 'use-latest';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event: PossibleEvent) => void;

const events: HandledEvents = [MOUSEDOWN, TOUCHSTART];

const getOptions = (event: HandledEventsType) => {
  if (event !== TOUCHSTART) {
    return;
  }

  return { passive: true };
};

export function useOnClickOutside(
  refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: Handler
) {
  const handlerRef = useLatest(handler);

  useEffect(() => {
    if (!handler) {
      return;
    }

    const listener = (event: PossibleEvent) => {
      const shouldTrigger = !Array.of(refs)
        .flat()
        .some(
          ref => !ref.current || ref.current.contains(event.target as Node)
        );

      if (shouldTrigger) {
        handlerRef.current(event);
      }
    };

    events.forEach(event => {
      document.addEventListener(event, listener, getOptions(event));
    });

    return () => {
      events.forEach(event => {
        document.removeEventListener(
          event,
          listener,
          getOptions(event) as EventListenerOptions
        );
      });
    };
  }, [!handler]);
}
