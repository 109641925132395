import Cookies from 'js-cookie';
import queries from '../queries';
import { call, delay, put, select } from 'redux-saga/effects';
import { errorAlert, infoAlert } from '@components/Alert/dux';
import * as Sentry from '@sentry/browser';
import { newMessageLiveObject } from '@features/feed/objects/message/dux';
import { newMomentLiveObject, SignupMoment } from '@features/feed/objects/moment/dux';
import { publishLiveObject } from '../../pubnub/liveObject';
import {
  saveSubscriberSuccess as saveSubscriberSuccessAction,
  saveSubscriber as saveSubscriberAction,
  publishRequestPasswordReset as publishRequestPasswordResetAction,
  uploadAvatar as uploadAvatarAction,
  updateGuestNickname as updateGuestNicknameAction,
  saveSubscriberFailed as saveSubscriberFailedAction,
  revertSubscriber as revertSubscriberAction,
} from '@store/subscriberSlice';
import { getCurrentSubscriberAsPublicSubscriber } from '@store/subscriberSlice/selectors';
import {
  getPublicChannel,
  getPublicChannelMessage,
  getTranslateLanguage,
  getChannelAsPublicChannel,
} from '@store/feedSlice/channelSelectors';
import {
  Channel,
  requestLivePrayer,
  saveChannelMessage,
  saveMessage,
  saveMoment,
} from '@store/feedSlice';
import { resetApp } from '@store/rootReducer';
import { HttpResponse, post } from '../API';
import {
  subscriberProfileUpdated as subscriberProfileUpdatedMetric,
  signUp as signUpMetric,
  signUpCancel as signUpCancelMetric,
  signUpError as signUpErrorMetric,
  subscriberDeleted,
} from './metrics';
import { FieldErrorType, PublicSubscriber, Subscriber } from '../../types';
import {
  signUpCancel as signUpCancelAction,
  signUpError as signUpErrorAction,
  signUpSuccess,
} from '@components/modal/signup/dux';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { FetchResult } from '@apollo/client';
import { AcceptInviteMutation } from '../__generated__/AcceptInviteMutation';
import {
  SaveSubscriberMutation,
  SaveSubscriberMutation_saveSubscriber_errors,
} from '../__generated__/SaveSubscriberMutation';
import { DeleteSelfMutation } from '../__generated__/DeleteSelfMutation';
import { RevertSubscriberMutation } from '../__generated__/RevertSubscriberMutation';
import { getCurrentServiceId, isChatEnabled } from '@store/serviceSlice/selectors';
import { sendIntegrationsEvent } from './integrations';
import { clearModal } from '@store/uiSlice';
import { MomentTriggerType, MomentType } from '../../../__generated__/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from '../../lib/i18n';

const findError = (
  errors: SaveSubscriberMutation_saveSubscriber_errors[] | void,
  errorType: 'email' | 'nickname'
): FieldErrorType.DUPLICATE | null => {
  if (!errors) return null;

  if (errors.find(e => e.code === 'profile-002' && e.property === errorType)) {
    return FieldErrorType.DUPLICATE;
  }
  return null;
};

function* acceptInvite(id: string) {
  try {
    const input = {
      subscriberId: id,
    };
    const result: FetchResult<AcceptInviteMutation> = yield call(
      [queries, queries.acceptInvite],
      input
    );

    if (result?.data?.acceptInvite?.errors.length) {
      throw result?.data?.acceptInvite?.errors;
    }
  } catch (error) {
    yield put(saveSubscriberFailedAction(error as SaveSubscriberMutation_saveSubscriber_errors[]));
  }
}

function* revertSubscriber(action: ReturnType<typeof revertSubscriberAction>) {
  try {
    const result: FetchResult<RevertSubscriberMutation> = yield call(
      [queries, queries.revertSubscriber],
      action.payload.input
    );

    if (result?.data?.revertSubscriber?.errors.length) {
      throw result?.data?.revertSubscriber?.errors;
    }
    const email = result?.data?.revertSubscriber?.subscriber?.email;
    yield put(saveSubscriberSuccessAction({ email }, {}));
  } catch (error) {
    yield put(saveSubscriberFailedAction(error as SaveSubscriberMutation_saveSubscriber_errors[]));
  }
}

function* saveSubscriber(action: ReturnType<typeof saveSubscriberAction>) {
  const { id, input, signUpId, signUpSource, successCallback } = action.payload;
  const { acceptedInvite, keepSubmissionFormAlive = false, profileSettingsUpdated } = action.meta;

  try {
    const result: FetchResult<SaveSubscriberMutation> = yield call(
      [queries, queries.saveSubscriber],
      id,
      input
    );
    const nicknameError = findError(result.data?.saveSubscriber?.errors, 'nickname');
    const emailError = findError(result.data?.saveSubscriber?.errors, 'email');

    if (result.data?.saveSubscriber?.errors.length === 0 && input) {
      yield put(saveSubscriberSuccessAction(input, { keepSubmissionFormAlive }));

      if (signUpId) {
        yield call(signUpMetric, signUpId, signUpSource ?? 'unknown');
        yield call(sendIntegrationsEvent, signUpSuccess());

        if (acceptedInvite) {
          yield acceptInvite(id);
        }
      }

      if (profileSettingsUpdated) {
        yield call(subscriberProfileUpdatedMetric);
      }
    } else {
      if (signUpId) {
        yield call(signUpErrorMetric, signUpId, nicknameError, emailError, null, false);
      }
      throw result.data?.saveSubscriber?.errors;
    }
    if (successCallback) successCallback();
  } catch (error) {
    yield put(saveSubscriberFailedAction(error as SaveSubscriberMutation_saveSubscriber_errors[]));
  }
}

function* updateGuestNickname(action: ReturnType<typeof updateGuestNicknameAction>) {
  try {
    const {
      payload: { id, nickname, action: guestAction },
    } = action;
    const response: FetchResult<SaveSubscriberMutation> = yield call(
      [queries, queries.saveSubscriber],
      id,
      { nickname }
    );
    if (response.data?.saveSubscriber?.errors.length === 0) {
      yield put(saveSubscriberSuccessAction({ nickname }, { guestNickname: true }));
      const serviceId: string | null = yield select(getCurrentServiceId);
      Cookies.set(`${serviceId}:nickname`, nickname, { expires: 1 });

      yield put(clearModal());
      const subscriber: PublicSubscriber = yield select(getCurrentSubscriberAsPublicSubscriber);

      if (guestAction === 'chat') {
        const language: string = yield select(getTranslateLanguage);
        const channel: Channel = yield select(getPublicChannel);
        const message: string = yield select(getPublicChannelMessage);
        const object = newMessageLiveObject(
          message,
          subscriber,
          language,
          getChannelAsPublicChannel(channel)
        );
        yield put(saveMessage(object));
        yield put(publishLiveObject(object));
        yield put(saveChannelMessage({ channelKey: channel?.key, message: '' }));

        const signupMoment: SignupMoment = {
          type: MomentType.SIGNUP,
          id: uuidv4(),
          postTime: new Date().toISOString(),
          channel: getChannelAsPublicChannel(channel),
          trigger: MomentTriggerType.USER,
          likes: 0,
          count: 0,
          momentTemplate: {
            type: MomentType.SIGNUP,
            options: {
              url: 'https://chop.imgix.net/static/web-client/profile_photo_examples.png',
              text: i18n.t('moments:signup_moment.text'),
              buttonText: i18n.t('moments:signup_moment.sign_up'),
            },
          },
        };
        const momentObject = newMomentLiveObject(signupMoment);
        yield delay(1000);
        yield put(
          saveMoment(
            { ...momentObject, data: signupMoment },
            { isChatEnabled: yield select(isChatEnabled) }
          )
        );
      } else if (guestAction === 'prayer') {
        yield put(requestLivePrayer({ momentId: action.payload.momentId }));
      }
    } else {
      if (response.data?.saveSubscriber) {
        yield put(saveSubscriberFailedAction(response.data.saveSubscriber?.errors));
      }
    }
  } catch (error) {
    yield put(errorAlert('update_settings_error'));
    Sentry.captureException(error);
  }
}

function* requestPasswordReset(action: ReturnType<typeof publishRequestPasswordResetAction>) {
  try {
    yield call([queries, queries.requestPasswordReset], action.payload);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* uploadAvatar(action: ReturnType<typeof uploadAvatarAction>) {
  try {
    const { formData, id, keepSubmissionFormAlive } = action.payload;
    const { parsedBody: avatar }: HttpResponse<string> = yield call(
      post,
      '/upload/subscriber/avatar',
      undefined,
      { method: 'POST', body: formData }
    );
    const result: FetchResult<SaveSubscriberMutation> = yield call(
      [queries, queries.saveSubscriber],
      id,
      { avatar }
    );
    if (result.data?.saveSubscriber) {
      yield put(saveSubscriberSuccessAction({ avatar }, { keepSubmissionFormAlive }));
    } else {
      throw new Error(`Server returned false for saveSubscriber: ${id}`);
    }
  } catch (error) {
    yield put(errorAlert('update_settings_error'));
    Sentry.captureException(error);
  }
}

function* deleteSelf() {
  try {
    const subscriber: Subscriber = yield select(getCurrentSubscriber);
    const result: FetchResult<DeleteSelfMutation> = yield call(
      [queries, queries.deleteSelf],
      subscriber.id
    );
    if (result && result.data?.deleteSubscriber?.errors.length === 0) {
      yield call(subscriberDeleted);
      yield put(resetApp());
      yield put(infoAlert('delete_self_success'));
    } else {
      throw new Error('Server returned false for deleteSelf');
    }
  } catch (error) {
    yield put(errorAlert('delete_self_error'));
    Sentry.captureException(error);
  }
}

function* signUpCancel(action: ReturnType<typeof signUpCancelAction>) {
  yield call(signUpCancelMetric, action.payload);
}

function* signUpError(action: ReturnType<typeof signUpErrorAction>) {
  const { payload } = action;
  yield call(
    signUpErrorMetric,
    payload.signUpId,
    payload.nicknameError,
    payload.emailError,
    payload.passwordError,
    payload.termsError
  );
}

export {
  saveSubscriber,
  requestPasswordReset,
  uploadAvatar,
  updateGuestNickname,
  deleteSelf,
  signUpCancel,
  signUpError,
  revertSubscriber,
};
