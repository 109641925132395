import React, { FC } from 'react';
import Imgix from 'react-imgix';
import { IMAGE_PREFIX } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title } from './styles';

const FourZeroFourError: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Imgix src={`${IMAGE_PREFIX}/static/404.png`} width={200} height={268} />
      <Title>{t('page_not_found')}</Title>
    </Wrapper>
  );
};

export default FourZeroFourError;
