import React from 'react';
import { PrimaryButton, ButtonSize, IconSize, OSmallParagraph, EnvelopeIcon, } from '@lifechurch/react-ion';
import styled from 'styled-components';
import { ShareButtonContainer } from '../share-button-container';
const EmailButton = styled(PrimaryButton) `
  padding: ${props => props.padding};
  border-radius: 50%;
`;
const EmailShareButton = ({ subject, content, size = ButtonSize.SM, padding = '8px !important', iconSize = IconSize.XL }, props) => {
    const shareEmailUrl = () => {
        const body = encodeURI(content);
        const emailUrl = `mailto:?subject=${subject}&body=${body}`;
        return emailUrl;
    };
    return (React.createElement(ShareButtonContainer, { flexDirection: 'column', alignItems: 'center' },
        React.createElement(EmailButton, { "aria-label": 'Email', as: 'a', "data-testid": 'email', href: subject ? shareEmailUrl() : null, padding: padding, size: size, ...props },
            React.createElement(EnvelopeIcon, { size: iconSize, color: 'white' })),
        React.createElement(OSmallParagraph, { paddingBlockStart: 2, color: 'gray50' }, "Email")));
};
export { EmailShareButton };
