import React from 'react';
import { Flex } from '@lifechurch/react-ion';
import Imgix from 'react-imgix';
import styled, { css } from 'styled-components';
import { ImageModal } from './imageModal';
import BaseContent from '../content';
const ImageMomentWrapper = styled.div `
  display: flex;
  flex-direction: row;
  background-color: ${props => (props.isPinned ? props.theme.colors.gray10 : undefined)};
  justify-content: center;
  ${props => props.isPinned
    ? css `
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 7px;
          margin-inline-end: 27px;
        `
    : css `
          margin: 0;
        `}
  padding: ${props => (!props.isPinned ? '0' : null)};
  border: ${props => (!props.isPinned ? `1px solid ${props.theme.colors.gray10}` : null)};
  border-radius: 4px;
  overflow: hidden;
`;
const ImageMoment = ({ altText, hasLiked, image, isPinned = false, isPlaceholder, likeButtonLabel, likeCount, onDismiss, onImageClick, onLike, onShare, shareButtonLabel, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseContent, { buttonAlignment: 'center', fuse: true, hasLiked: hasLiked, isPinned: isPinned, likeButtonLabel: likeButtonLabel, likeCount: likeCount, onDismiss: onDismiss, onLike: onLike, onShare: onShare, paddingBlockStart: isPinned ? '8px' : '0', paddingBlockEnd: isPinned ? '12px' : '0', paddingInlineStart: isPinned ? '32px' : '0', paddingInlineEnd: isPinned ? '12px' : '0', shareButtonLabel: shareButtonLabel, ariaLabel: altText, role: 'img' },
            React.createElement(ImageMomentWrapper, { isPinned: isPinned },
                React.createElement(Flex, { onClick: onImageClick },
                    React.createElement(Imgix, { src: isPlaceholder
                            ? 'https://chop.imgix.net/static/placeholders/image_moment_empty_glyph.png'
                            : image, height: isPlaceholder ? 64 : 100, htmlAttributes: {
                            style: {
                                position: 'relative',
                                margin: isPlaceholder ? '18px' : '0',
                            },
                            alt: altText,
                            title: altText,
                        }, imgixParams: {
                            auto: 'format',
                            fit: 'clip',
                        } }))))));
};
export { ImageMoment, ImageModal };
