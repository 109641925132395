import styled from 'styled-components';
import { LinkButton } from '@lifechurch/react-ion';

export const RoleTag = styled.span`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.action.default};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[1]};
  text-transform: capitalize;
  border-radius: 11px;
  display: inline-block;
  padding: 1px 6px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 3px;
  margin-inline-end: 6px;
  position: relative;
  top: -1px;
`;

export const Spacer = styled.div`
  padding-inline-end: 4px;
  display: inline;
`;

export const IconWrapper = styled.span`
  display: inline-block;
  position: relative;
  top: 1px;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 2px;
  padding-inline-end: 4px;
`;

export const ActionableAlertCallToAction = styled(LinkButton)`
  cursor: default;
  white-space: nowrap;
  position: relative;
  color: white;
  align-self: flex-start;
  margin-inline-start: 16px;
  &:hover,
  &:focus {
    color: white;
  }
`;
