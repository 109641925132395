import React, { FunctionComponent, useCallback, useContext } from 'react';
import {
  SalvationMoment as SalvationMomentComponent,
  SalvationMomentLabels,
  SalvationMomentMode,
} from '@churchonline/moments';
import { SelectChatNameSubtype } from '@components/modal/selectChatName/dux';
import { MediumPlusUpQuery } from '@utils/responsive';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  MomentActionType,
  MomentType,
  SalvationMomentFollowUpType,
} from '../../../../../../__generated__/globalTypes';
import { iframeModal, requestPrayer, selectChatName } from '../../../../../components/modal/dux';
import { useHostCount } from '../../../../../hooks/useHostCount';
import { Props } from './index';
import { FeedBoundaryContext } from '../../../../../context/FeedBoundaryContext';

const SalvationMoment: FunctionComponent<Props> = ({
  moment,
  onDismiss,
  isPinned,
  hasSubmitted,
  submitMomentAction,
  currentSubscriberNickname,
  isLivePrayerEnabled,
  requestLivePrayer,
  salvationMomentConnect,
  salvationMomentFollowUpDismiss,
  setModal,
}) => {
  const { feedHeight } = useContext(FeedBoundaryContext);
  const { hosts } = useHostCount();
  const { labels } = moment.momentTemplate.options;
  const followUpType = moment.momentTemplate?.options?.salvationMomentFollowUpType;
  const { t } = useTranslation('moments');
  const isLargeScreen = useMediaQuery({ query: MediumPlusUpQuery });

  const getLabels = useCallback((): SalvationMomentLabels => {
    const prompt = t('salvation.text');
    const response = t('salvation.raise_hand');
    const subtitle = t('salvation.subtext');
    const dismiss = t('salvation.dismiss');
    const celebrationMessage = t('salvation.celebration_message');
    const buttonText =
      followUpType === SalvationMomentFollowUpType.REQUEST_PRAYER
        ? t('salvation.button_text_request_prayer')
        : t('salvation.button_text_other');
    const shouldUseDefault = !labels?.prompt && !labels?.response;

    if (shouldUseDefault) {
      return { ...labels, prompt, response, subtitle, dismiss, celebrationMessage, buttonText };
    }

    return labels;
  }, [labels, t]);

  const handleDismiss = (mode: SalvationMomentMode) => {
    if (mode === SalvationMomentMode.FOLLOW_UP) salvationMomentFollowUpDismiss(moment.id);
    onDismiss();
  };

  const handleResponse = () => {
    salvationMomentConnect(moment.id);

    const { salvationMomentFollowUpType, url } = moment.momentTemplate.options;

    if (url && salvationMomentFollowUpType === SalvationMomentFollowUpType.URL_MODAL) {
      setModal(iframeModal(url));
    }

    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.SALVATION,
      momentActionType: MomentActionType.SUBMIT,
    });
  };

  const handleFollowUpResponse = (mode?: SalvationMomentMode) => {
    const { salvationMomentFollowUpType, url } = moment.momentTemplate.options;
    const { REQUEST_PRAYER, URL_NEW_TAB, URL_MODAL } = SalvationMomentFollowUpType;

    switch (salvationMomentFollowUpType) {
      case REQUEST_PRAYER: {
        handlePrayerFollowUp();
        break;
      }
      case URL_NEW_TAB: {
        if (url) window.open(url, '_blank');
        break;
      }
      case URL_MODAL: {
        if (url) setModal(iframeModal(url));
        break;
      }
    }

    if (mode) handleDismiss(mode);
  };

  const handleRequestLivePrayer = debounce(
    () => requestLivePrayer({ momentId: moment.id }, { raisedHand: true }),
    500
  );

  const handlePrayerFollowUp = () => {
    if (isLivePrayerEnabled && currentSubscriberNickname) {
      handleRequestLivePrayer();
    } else if (!isLivePrayerEnabled) {
      setModal(requestPrayer());
    } else {
      setModal(selectChatName(SelectChatNameSubtype.PRAYER, { momentId: moment.id }));
    }
  };

  return (
    <SalvationMomentComponent
      count={moment.count}
      expandToFillArea={isLargeScreen}
      // @ts-expect-error TS is complaining because this is matching to separately defined but equal types
      followUpType={moment.momentTemplate.options.salvationMomentFollowUpType}
      hasSubmitted={hasSubmitted}
      isCustom={!!moment?.momentTemplate?.options?.labels?.prompt}
      isPinned={isPinned}
      labels={getLabels()}
      onDismiss={handleDismiss}
      onFollowUpResponse={handleFollowUpResponse}
      onResponse={handleResponse}
      url={moment.momentTemplate.options.url ?? ''}
      hosts={hosts}
      feedHeight={feedHeight}
      selector={'#publicchat'}
    />
  );
};

export default SalvationMoment;
