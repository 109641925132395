import { getInset } from '@lifechurch/react-ion';
import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  height: 100%;

  &:focus {
    &:after {
      width: 100%;
      height: 100%;
      content: '';
      outline: 3px solid ${props => props.theme.colors.action.hover};
      outline-offset: -3px;
      z-index: 11; //High z-index due to the pane header having a z-index of 10
      position: absolute;
      ${getInset('insetBlockStart', 0)};
      ${getInset('insetInlineStart', 0)};
      background: transparent;
      pointer-events: none;
    }
  }
`;
