import { RootState } from '@store/rootReducer';
import { createSelector } from 'reselect';
import { ServiceState } from './';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { getNextStep } from '@store/sequenceSlice/selectors';
import { OfflineContentType } from '../../../__generated__/globalTypes';

const ID = 'service';
const local = (state: RootState) => state[ID];

const currentServiceExists = createSelector<RootState, ServiceState, boolean>(
  local,
  service => !!service?.id
);

export const getCurrentService = createSelector<RootState, ServiceState, ServiceState>(
  local,
  local => local
);

export const getCurrentServiceId = createSelector<RootState, ServiceState, string | null>(
  [getCurrentService],
  service => service?.id
);

export const isOffline = createSelector<RootState, boolean, boolean>(
  currentServiceExists,
  result => !result
);

export const isOnline = createSelector<RootState, boolean, boolean>(
  currentServiceExists,
  result => result
);

export const isChatEnabled = createSelector<RootState, ServiceState, boolean>(
  local,
  service => service?.content?.features?.publicChat === true || false
);

export const isLivePrayerEnabled = createSelector<RootState, ServiceState, boolean>(
  local,
  service => service?.content?.features?.livePrayer === true || false
);

export const shouldShowVideo = createSelector(
  [local, isOffline, getCurrentOrganization, getNextStep],
  (service, offline, organization) =>
    offline
      ? organization.offlineContent?.type === OfflineContentType.VIDEO &&
        !!organization.offlineContent.url
      : !!service?.content?.hasVideo || false
);

export const currentServiceHasVideo = createSelector<RootState, ServiceState, boolean>(
  [local],
  service => !!service?.content?.hasVideo
);

export const isMomentSchedulingEnabled = createSelector<RootState, ServiceState, boolean>(
  local,
  service => service.momentSchedulingEnabled
);
