import { connect } from 'react-redux';

import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';
import { RootState } from '@store/rootReducer';
import ServiceMeta from './serviceMeta';
import { getCurrentService, isOnline as isOnlineSelector } from '@store/serviceSlice/selectors';
import { isHostViewingAsHost, isLargeHostVideo } from '@store/uiSlice/selectors';
import { Dispatch } from 'redux';
import { expandHostVideo, sendResizeHostVideoMetric, shrinkHostVideo } from '@store/uiSlice';

interface StateProps {
  isGuest: boolean;
  isLargeHostVideo: boolean;
  isOnline: boolean;
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
  subscriberId: string;
  timezone: string;
  title: string;
}

export enum Alignment {
  CENTER = 'center',
  START = 'inline-start',
}

interface OwnProps {
  alignment?: Alignment;
  hasMedia: boolean;
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps & OwnProps => {
  return {
    isGuest: !isHostViewingAsHost(state),
    isLargeHostVideo: isLargeHostVideo(state),
    isOnline: isOnlineSelector(state),
    nextService: getNextServiceData(state),
    subscriberId: state.subscriber.currentSubscriber.id,
    timezone: state.schedule.timezone,
    title: getCurrentService(state)?.content?.title || '',
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  expandHostVideo: () => dispatch(expandHostVideo()),
  sendResizeHostVideoMetric: (subscriber_id: string, video_size: 'regular' | 'large') =>
    dispatch(sendResizeHostVideoMetric({ subscriber_id, video_size })),
  shrinkHostVideo: () => dispatch(shrinkHostVideo()),
});

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(ServiceMeta);
