import { connect } from 'react-redux';
import Login from './login';
import { basicAuthLogin } from '@store/subscriberSlice';
import { requestPasswordReset, signupModal } from '../dux';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { LoginMeta } from './dux';
import { AuthError } from '@io/sagas/auth';
import { getIntegrationTypes } from '@store/organizationSlice/selectors';
import { IntegrationType } from '../../../../__generated__/globalTypes';
import { setModal } from '@store/uiSlice';

interface StateProps {
  error: AuthError | null;
  currentSubscriberId: string;
  hasFacebookLogin: boolean;
}

interface OwnProps {
  closeModal: () => void;
  meta?: LoginMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const integrations = getIntegrationTypes(state);
  return {
    error: ownProps.meta?.error || null,
    currentSubscriberId: state.subscriber.currentSubscriber.id,
    hasFacebookLogin: integrations.includes(IntegrationType.FACEBOOK_APP),
    ...ownProps,
  };
};

interface DispatchProps {
  basicAuthLogin: (email: string, password: string, currentSubscriberId: string) => void;
  resetPassword: () => void;
  signup: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  basicAuthLogin: (email, password, currentSubscriberId) =>
    dispatch(basicAuthLogin({ email, password }, { currentSubscriberId })),
  resetPassword: () => dispatch(setModal(requestPasswordReset())),
  signup: () => dispatch(setModal(signupModal('login_modal'))),
});

const VisibleLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleLogin;
