import { ModalType } from '../dux';
import { PublicSubscriber as Subscriber } from '../../../types';

export interface MuteSubscriberMeta {
  subscriber: Subscriber;
  onSuccess?: () => void;
}

export interface MuteSubscriber {
  type: ModalType.MUTE_SUBSCRIBER;
  meta: MuteSubscriberMeta;
}

export const muteSubscriber = (meta: MuteSubscriberMeta): MuteSubscriber => ({
  type: ModalType.MUTE_SUBSCRIBER,
  meta,
});
