import { connect } from 'react-redux';
import DeleteAccount from './deleteAccount';
import { deleteSelf } from '@store/subscriberSlice';
import { profileSettings } from '../dux';
import { RootState } from '@store/rootReducer';
import { clearModal, setModal } from '@store/uiSlice';
import { Dispatch } from 'redux';

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (_state: RootState, ownProps: OwnProps) => ({
  ...ownProps,
});

interface DispatchProps {
  deleteAccount: () => void;
  openProfileSettings: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteAccount: () => {
    dispatch(deleteSelf());
    dispatch(clearModal());
  },
  openProfileSettings: () => dispatch(setModal(profileSettings())),
});

const VisibleDeleteAccount = connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);

export type Props = OwnProps & DispatchProps;
export default VisibleDeleteAccount;
