import { connect } from 'react-redux';
import Medium from './medium';
import { RootState } from '@store/rootReducer';
import { shouldShowVideo } from '@store/serviceSlice/selectors';

interface StateProps {
  hasVideo: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const hasVideo = shouldShowVideo(state);
  return {
    hasVideo,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Medium);
