import React from 'react';
import { Box, Flex } from '@lifechurch/react-ion';
import { ShareSheet as ShareSheetWrapper } from '../index';
import { DownloadShareButton, FacebookShareButton, TwitterShareButton } from '../sharing-buttons';
import Imgix from 'react-imgix';
import { ImageWrapper } from './styles';
export const getImageShareUrl = (image, title, description) => {
    let url = encodeURI(window.location.origin + '/image-sharer?image=' + image);
    if (title) {
        url = url + encodeURIComponent('&title=') + encodeURIComponent(encodeURIComponent(title));
    }
    if (description) {
        url =
            url +
                encodeURIComponent('&description=') +
                encodeURIComponent(encodeURIComponent(description));
    }
    return url;
};
const ImageMomentShareSheet = ({ altText, handleModalClose, image, open, shareText, title, }) => {
    return (React.createElement(ShareSheetWrapper, { open: open, handleModalClose: handleModalClose },
        React.createElement(Box, { marginBlockStart: 5 },
            React.createElement(ImageWrapper, { tabIndex: 0, "aria-label": altText, role: 'img' },
                React.createElement(Imgix, { src: image, htmlAttributes: {
                        style: {
                            maxWidth: '100%',
                            maxHeight: '240px',
                        },
                        alt: altText,
                        title: altText,
                    }, imgixParams: {
                        auto: 'format',
                        fit: 'clip',
                    } }))),
        React.createElement(Box, { marginBlockStart: 5 },
            React.createElement(Flex, { flexDirection: 'row', justifyContent: 'center' },
                React.createElement(FacebookShareButton, { url: getImageShareUrl(image, title, shareText) }),
                React.createElement(TwitterShareButton, { imageShareUrl: getImageShareUrl(image, title, shareText) }),
                React.createElement(DownloadShareButton, { fileURL: image })))));
};
export { ImageMomentShareSheet };
