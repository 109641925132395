import styled from 'styled-components';
import { OParagraph } from '@lifechurch/react-ion';

export const StyledOParagraph = styled(OParagraph)`
  justify-self: flex-start;
  color: ${props => props.theme.colors.gray50};
`;

export const InlineLink = styled.a`
  color: ${props => props.theme.colors.gray100};
  text-decoration: underline;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-block-end: 15px;
`;

export const FileInputText = styled.label`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[3]};
  font-weight: ${props => props.theme.fontWeights[2]};
  color: ${props => props.theme.colors.action.active};
  cursor: pointer;
  margin-inline-start: 8px;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.action.hover};
  }
`;

export const FileInputLabel = styled.label`
  display: inline-block;
  margin-block-end: 2px;
  font-size: ${props => props.theme.fontSizes[2]};
  font-weight: ${props => props.theme.fontWeights[2]};
  color: ${props => props.theme.colors.gray100};
`;
