import { Avatar, AvatarSizeType } from '@churchonline/core';
import { LinkButtonType, ButtonSize, Box, LinkButton } from '@lifechurch/react-ion';
import React, { ChangeEvent, FunctionComponent, KeyboardEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileInput, FileInputLabel } from './styles';

export interface Props {
  subscriber: {
    avatar?: string;
    nickname?: string;
    id?: string;
  };
  avatarLoading: boolean;
  handleAvatarUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  removeAvatar: () => void;
}

const AvatarPicker: FunctionComponent<Props> = ({
  subscriber,
  avatarLoading,
  handleAvatarUpload,
  removeAvatar,
}) => {
  const { t } = useTranslation('forms');
  const hasAvatar = subscriber.avatar && subscriber.avatar.indexOf('missing.png') === -1;
  const avatar = subscriber.avatar ?? '';
  const nickname = subscriber.nickname ?? '';
  const id = subscriber.id ?? undefined;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const enterHandler: KeyboardEventHandler<HTMLLabelElement> = e => {
    if (e.key === 'Enter') {
      const fileUploader = fileInputRef.current;
      e.preventDefault();
      fileUploader?.click();
    }
  };

  const handleRemoveAvatar: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      removeAvatar();
    }
  };

  return (
    <>
      <Avatar
        subscriber={{ id, avatar, nickname }}
        size={AvatarSizeType.XXLARGE}
        testId='avatar'
        loading={avatarLoading}
      />
      <FileInput
        type='file'
        id='avatar'
        data-testid='avatar-upload'
        onChange={handleAvatarUpload}
        ref={fileInputRef}
        tabIndex={-1}
      />
      <Box marginBlockStart={3}>
        {hasAvatar ? (
          <>
            <FileInputLabel htmlFor='avatar' tabIndex={0} onKeyDown={enterHandler} role='link'>
              {t('profile.photo_change')}
            </FileInputLabel>
            <LinkButton
              onClick={removeAvatar}
              onKeyDown={handleRemoveAvatar}
              marginInlineStart={5}
              buttonType={LinkButtonType.SECONDARY}
              size={ButtonSize.SM}
              data-testid='removeAvatar'
              tabIndex={0}
            >
              {t('profile.photo_remove')}
            </LinkButton>
          </>
        ) : (
          <FileInputLabel htmlFor='avatar' tabIndex={0} onKeyDown={enterHandler} role='link'>
            {t('profile.photo_add')}
          </FileInputLabel>
        )}
      </Box>
    </>
  );
};

export default AvatarPicker;
