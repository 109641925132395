import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { CheckIcon, CommentIcon, IconSize, RaisedHandIcon, TimesIcon } from '@lifechurch/react-ion';
import { localizedTimeReference } from '@utils/core';
import SenderName from '../senderName';
import { Props } from './index';
import styles from './prayerRequest.module.css';
import clsx from 'clsx';
import { PublicSubscriber } from '../../../../types';

const PrayerRequest: React.FunctionComponent<Props> = (props: Props) => {
  const { prayerRequest, acceptLivePrayer, isCompact } = props;

  const { t } = useTranslation(['common', 'moments']);
  const theme = useContext(ThemeContext);

  if (!prayerRequest) return null;

  const { subscriber, host, open, raisedHand, requestedTimestamp } = prayerRequest;

  function getAcceptedText(host?: PublicSubscriber) {
    if (!host) {
      return (
        <p className='text-sm'>
          <TimesIcon size={IconSize.SM} color={theme.colors.gray100} />
          {t('moments:actionable.cancelled')}
        </p>
      );
    }
    return (
      <>
        <CheckIcon size={IconSize.SM} color={theme.colors.gray50} />
        <>
          <SenderName roleIdentifier={host.roleIdentifier} name={host.nickname} />{' '}
          {t('moments:actionable.accepted')}
          {'.'}
        </>
      </>
    );
  }

  const callAcceptPrayerRequest = () => {
    if (props.prayerRequest) {
      acceptLivePrayer(props.prayerRequest);
    }
  };

  return (
    <div
      className={clsx(
        open ? [styles.actionableWrapper] : [styles.notificationWrapper],
        isCompact ? [styles.compact] : [styles.expand]
      )}
      data-testid='prayerRequest'
    >
      <div className={styles.wrapper}>
        <div data-testid={'prayerRequest-icon'} className={styles.icon}>
          {raisedHand ? (
            <RaisedHandIcon color={theme.colors.gray50} size={IconSize.SM} />
          ) : (
            <CommentIcon size={IconSize.SM} color={theme.colors.gray50} />
          )}
        </div>
        <div>
          <div data-testid={'prayerRequest-message'}>
            <p className={styles.notice}>
              {raisedHand ? (
                <Trans
                  ns='moments'
                  i18nKey='prayer.raised_hand'
                  data-testid='prayerRequest-text-raisedHand'
                >
                  <strong>{{ nickname: subscriber?.nickname }}</strong> has just committed their
                  life to Christ and would like to pray with someone.
                </Trans>
              ) : (
                <Trans ns='moments' i18nKey='prayer.request' data-testid='prayerRequest-text'>
                  <strong>{{ nickname: subscriber?.nickname }}</strong> has requested prayer.
                </Trans>
              )}
              <span className={styles.timestamp} data-testid='prayerRequest-requestedTimestamp'>
                {' '}
                {localizedTimeReference(requestedTimestamp, t)}
              </span>
            </p>
          </div>
          <p
            className={clsx([styles.acceptedText], {
              [styles.hide]: open,
            })}
            data-testid='prayerRequest-accepted'
          >
            {getAcceptedText(host)}
          </p>
        </div>
      </div>
      {open && (
        <>
          <button
            className={styles.acceptBtn}
            data-testid='prayerRequest-accept'
            onClick={callAcceptPrayerRequest}
          >
            {t('moments:actionable.accept')}
          </button>
        </>
      )}
    </div>
  );
};

export default React.memo<Props>(PrayerRequest);
