import React, { createRef, useEffect, useRef, useState } from 'react';
import { Box, ButtonSize, Flex, OParagraph, OSmallParagraph } from '@lifechurch/react-ion';
import styled, { keyframes } from 'styled-components';
import { FollowUpType, SalvationMomentMode } from '..';
import CTAMoment from '../cta';
import { HasText } from '../preview/components';
import { AnimatedCircle, AnimatedRaisedHandContainer } from './animationDefinitions';
import { CTAButton, SalvationSecondaryButton } from './Button';
import { Count } from './Count';
const defaultSymbol = {
    id: 'hand',
    name: 'Raised Hand',
    unified: '',
    native: '✋',
};
export const ANIMATION_DURATION = 350;
const TRANSLATE_Y = '24px';
const disappearAnimation = keyframes `
  0% { opacity: 1; transform: translateY(0)}
  100% { opacity: 0; transform: translateY(${TRANSLATE_Y})}
`;
const AnimatedContainer = styled.div `
  animation-name: ${props => (props.disappearing ? disappearAnimation : undefined)};
  animation-duration: ${ANIMATION_DURATION}ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;
function CallToAction({ count: countFromProps, followUpType, isCustom = false, isPinned: defaultIsPinned = false, isPreview = false, hasSubmitted: defaultHasSubmitted = false, labels, mode, onDismiss, onResponse, tabIndex = 0, }) {
    const [hasSubmitted, setHasSubmitted] = useState(defaultHasSubmitted);
    const [isPinned, setIsPinned] = useState(defaultIsPinned);
    const [animating, setAnimating] = useState(false);
    const [disappearing, setDisappearing] = useState(false);
    const hasCount = countFromProps > 0;
    const count = useRef(countFromProps);
    const { CTA, FOLLOW_UP } = SalvationMomentMode;
    const prevModeRef = useRef();
    useEffect(() => {
        // @ts-expect-error typescript is just wanting to make sure this is defined
        prevModeRef.current = mode;
    });
    const prevMode = prevModeRef.current;
    const [animatedCircleCoords, setAnimatedCircleCoords] = useState({ x: 0, y: 0 });
    const [animatedHandCoords, setAnimatedHandCoords] = useState({ x: 0, y: 0 });
    const responseButtonRef = createRef();
    const containerRef = createRef();
    useEffect(() => setButtonCoordinates(), []);
    useEffect(() => {
        if (isPreview && mode === CTA && prevMode === FOLLOW_UP) {
            resetComponent();
        }
    }, [mode]);
    const resetComponent = () => {
        setHasSubmitted(false);
        setIsPinned(defaultIsPinned);
        setAnimating(false);
        setDisappearing(false);
    };
    const setButtonCoordinates = () => {
        if (containerRef.current && responseButtonRef.current) {
            const buttonCoordinates = responseButtonRef.current.getBoundingClientRect();
            const containerCoordinates = containerRef.current.getBoundingClientRect();
            const centerOfButton = {
                x: buttonCoordinates.x + buttonCoordinates.width / 2,
                y: buttonCoordinates.y + buttonCoordinates.height / 2,
            };
            // this calculates the center of the response button relative to the
            // salvation moment container
            const centerOfAnimatedCircle = {
                x: buttonCoordinates.x - containerCoordinates.left + buttonCoordinates.width / 2,
                y: buttonCoordinates.y - containerCoordinates.top + buttonCoordinates.height / 2,
            };
            setAnimatedCircleCoords(centerOfAnimatedCircle);
            setAnimatedHandCoords(centerOfButton);
        }
    };
    const handleDismiss = () => {
        setAnimating(false);
        setIsPinned(false);
        onDismiss(mode);
    };
    const handleResponse = () => {
        setButtonCoordinates();
        setHasSubmitted(true);
        setAnimating(true);
        count.current += 1;
        setTimeout(() => {
            onResponse();
            if (followUpType === FollowUpType.URL_MODAL)
                onDismiss(mode);
        }, 1750);
    };
    return (React.createElement(AnimatedContainer, { disappearing: disappearing },
        React.createElement(HasText, { hasButtonText: !!labels.response, hasText: !!labels.prompt, ref: containerRef },
            React.createElement(CTAMoment, { onDismiss: handleDismiss, isPinned: isPinned, hideDismiss: isPinned && !hasSubmitted, ariaLabel: `${hasSubmitted ? labels.response : labels.prompt} ${!hasSubmitted && !isCustom && labels.subtitle ? labels.subtitle : ''}` },
                React.createElement(Flex, { flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, textAlign: 'center', margionTop: '10px' },
                    React.createElement(Box, { p: '0px 10px' },
                        React.createElement(OParagraph, null, labels.prompt)),
                    !hasSubmitted && !isCustom && labels.subtitle && (React.createElement(OSmallParagraph, { color: 'gray50' }, labels.subtitle)),
                    React.createElement(Box, { marginBlockStart: 3, marginBlockEnd: 2 },
                        React.createElement(Flex, { alignItems: 'center' },
                            React.createElement(AnimatedCircle, { animatedCircleCoords: animatedCircleCoords, animating: animating }),
                            !isCustom && (React.createElement(AnimatedRaisedHandContainer, { animatedHandCoords: animatedHandCoords, animating: animating }, defaultSymbol.native)),
                            React.createElement(Box, { marginInlineEnd: 3, ref: responseButtonRef },
                                React.createElement(CTAButton, { hasSubmitted: hasSubmitted, isCustom: isCustom, isPinned: isPinned, marginInlineEnd: hasCount ? 2 : undefined, marginInlineStart: hasCount ? 2 : undefined, onClick: handleResponse, paddingBlockEnd: 0, paddingBlockStart: 0, paddingInlineEnd: hasCount ? 0 : 5, response: labels.response || '', tabIndex: tabIndex },
                                    !isCustom && (React.createElement(OParagraph, { "aria-label": 'raise hand', fontFamily: '"Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"', marginBlockEnd: '5px', marginBlockStart: '5px', role: 'img', textAlign: 'start', width: '20px' }, defaultSymbol.native)),
                                    !hasSubmitted && (React.createElement(Box, { as: 'span', marginBlockEnd: '5px', marginBlockStart: '5px', textAlign: 'start' }, labels.response === '' ? 'Response' : labels.response)),
                                    (count.current > 0 || countFromProps > 0) && (React.createElement(Count, { count: countFromProps >= count.current ? countFromProps : count.current, hasSubmitted: hasSubmitted, isPinned: isPinned })))),
                            isPinned && !hasSubmitted && (React.createElement(SalvationSecondaryButton, { isPinned: isPinned, onClick: handleDismiss, size: ButtonSize.SM, tabIndex: tabIndex }, labels.dismiss || 'Dismiss')))))))));
}
export { CallToAction };
