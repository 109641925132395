import {
  ErrorAlert,
  InfoAlert,
  InfoDarkAlert,
  PrimaryAlert,
  SuccessAlert,
} from '@lifechurch/react-ion';
import React, { FC } from 'react';
import { AlertType } from './dux';

interface BaseAlertProps {
  uniqueKey: string;
  type: AlertType;
  onDismiss: () => void;
  fuseDisabled?: boolean;
  fuseTimer?: number;
  animationDisabled?: boolean;
}

export const BaseAlert: FC<BaseAlertProps> = ({
  uniqueKey,
  type,
  onDismiss,
  fuseDisabled,
  fuseTimer,
  animationDisabled,
  children,
}) => {
  switch (type) {
    case AlertType.INFO:
      return (
        <InfoAlert
          key={uniqueKey}
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </InfoAlert>
      );
    case AlertType.SUCCESS:
      return (
        <SuccessAlert
          key={uniqueKey}
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </SuccessAlert>
      );
    case AlertType.ERROR:
      return (
        <ErrorAlert
          key={uniqueKey}
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </ErrorAlert>
      );
    case AlertType.PRIMARY:
      return (
        <PrimaryAlert
          key={uniqueKey}
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </PrimaryAlert>
      );
    case AlertType.INFO_DARK:
      return (
        <InfoDarkAlert
          key={uniqueKey}
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
          animationDisabled={animationDisabled}
        >
          {children}
        </InfoDarkAlert>
      );
  }
};
