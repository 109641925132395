import React, { FunctionComponent } from 'react';
import { Wrapper, Title } from '../styles';
import { MediumUp } from '@utils/responsive';
import { BasePayload } from '../index';

const TabHeader: FunctionComponent<BasePayload> = ({ title }) => (
  <MediumUp>
    {title !== 'BIBLE' && (
      <Wrapper data-testid='tabHeader' height={48}>
        <Title data-testid='tabHeader-title'>{title}</Title>
      </Wrapper>
    )}
  </MediumUp>
);

export default TabHeader;
