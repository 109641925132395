import styled, { css } from 'styled-components';
import { MediumUpQuery, SmallQuery } from '@utils/responsive';
import { Alignment } from './index';
import { defaultColors, OHeaderFive } from '@lifechurch/react-ion';

interface ContainerProps {
  hasMedia: boolean;
  alignment: Alignment;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: ${props => (props.alignment === Alignment.START ? 'flex-start' : 'center')};
  justify-content: ${props => (props.alignment === Alignment.START ? 'flex-start' : 'center')};
  overflow: hidden;

  @media ${SmallQuery} {
    align-items: ${props => (props.hasMedia ? 'flex-start' : 'center')};
    justify-content: ${props => (props.hasMedia ? 'flex-start' : 'center')};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

interface TitleProps {
  isGuest: boolean;
}

export const Title = styled.h2<TitleProps>`
  font-weight: 700;
  color: ${props => props.theme.colors.white};
  line-height: 1.25;
  font-size: 16px;
  ${props =>
    props.isGuest &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-inline-end: 8px;
    `}

  @media ${SmallQuery} {
    display: none;
  }

  @media ${MediumUpQuery} {
    display: block;
  }
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.gray10};

  &:hover {
    color: ${props => props.theme.colors.gray10};
  }

  &:focus {
    color: ${props => props.theme.colors.gray10};
  }
`;

interface NextServiceProps {
  isGuest: boolean;
  hasMedia: boolean;
  alignment?: Alignment;
}

export const NextServiceHeader = styled(OHeaderFive)`
  color: ${props => props.theme.colors.gray50};

  @media ${SmallQuery} {
    color: ${props => (props.hasMedia ? defaultColors.gray80 : defaultColors.gray50)};
  }
`;

export const NextServiceTitle = styled.h1<NextServiceProps>`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes[3]};
  font-weight: ${props => props.theme.fontWeights[3]};
  line-height: 1.25;

  @media ${SmallQuery} {
    text-align: ${props => (!props.hasMedia ? 'center' : 'start')};
    color: ${props => (props.hasMedia ? defaultColors.gray100 : defaultColors.white)};
  }

  @media ${MediumUpQuery} {
    font-size: ${props => props.isGuest && !props.hasMedia && props.theme.fontSizes[4]};
    margin-block-start: ${props => props.isGuest && !props.hasMedia && '8px'};
    text-align: ${props => !props.hasMedia && props.isGuest && 'center'};

    ${props =>
      props.isGuest &&
      props.hasMedia &&
      css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      `}
  }
`;

export const NextServiceTime = styled.p<NextServiceProps>`
  color: ${props => props.theme.colors.gray10};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: 1.25;

  @media ${SmallQuery} {
    color: ${props => (props.hasMedia ? defaultColors.gray80 : defaultColors.gray10)};
  }

  @media ${MediumUpQuery} {
    font-size: ${props => props.isGuest && !props.hasMedia && props.theme.fontSizes[3]};
    margin-block-start: ${props => props.isGuest && !props.hasMedia && '4px'};
  }
`;
