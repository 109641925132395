import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const BarsIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon draw='M2 3H13V4H2V3ZM2 11H13V12H2V11ZM13 7H2V8H13V7Z' size={size} {...rest} />
      );
    case IconSize.MD:
      return (
        <BaseIcon draw='M3 4H17V6H3V4ZM3 14H17V16H3V14ZM17 9H3V11H17V9Z' size={size} {...rest} />
      );
    case IconSize.LG:
      return (
        <BaseIcon draw='M3 5H21V7H3V5ZM3 17H21V19H3V17ZM21 11H3V13H21V11Z' size={size} {...rest} />
      );
    case IconSize.XL:
      return (
        <BaseIcon draw='M5 8H27V10H5V8ZM5 22H27V24H5V22ZM27 15H5V17H27V15Z' size={size} {...rest} />
      );
  }
};
