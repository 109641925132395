import styled from 'styled-components';
import { SmallQuery } from '@utils/responsive';

export const HEADER_HEIGHT = 48;

const Wrapper = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.95);
  height: ${({ height }) => `${height}px`};
  width: 100%;
  position: absolute;
  z-index: 10;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  line-height: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.gray100};
  font-size: 16px;
  font-weight: 600;

  //Small Screens
  @media screen and ${SmallQuery} {
    visibility: hidden;
  }
`;

export const Subtitle = styled.div`
  color: ${props => props.theme.colors.gray50};
  font-size: 13.44px;
  padding-inline-start: 4px;
`;

export { Wrapper, Title };
