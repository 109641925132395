import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const PencilIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18.4254 4.45511L15.597 1.62668L13.4757 3.748L16.3041 6.57643L18.4254 4.45511ZM12.7686 4.45511L15.597 7.28353L7.32924 15.5513L4.50081 12.7229L12.7686 4.45511ZM3.76859 13.4049L2.00082 18.0011L6.59701 16.2333L3.76859 13.4049Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22.1066 5.42884L18.5711 1.89331L16.4498 4.01463L19.9853 7.55016L22.1066 5.42884ZM15.7426 4.72174L19.2782 8.25727L8.67158 18.8639L5.13605 15.3283L15.7426 4.72174ZM4.42894 16.0354L2.00005 22L7.96447 19.571L4.42894 16.0354Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
