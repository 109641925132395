import React, { FunctionComponent, ReactElement, ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import { FormBanner, FormBannerType } from './FormBanner';
import { OParagraph } from './Typography';

export interface FormErrorsProps {
  errors?: Record<string, string>;
  inputs?: InputProp[];
  header?: ReactNode;
}

export interface InputProp {
  key: string;
  ref: RefObject<HTMLElement>;
}

const ErrorTextContainer = styled.li`
  margin: 0px 0px 5px;
`;

const matchingInput = (errorKey: string, inputs: InputProp[]): InputProp | undefined => {
  if (!inputs) return;
  return inputs.find(i => i.key === errorKey);
};

const jumpToError = (
  e: React.MouseEvent<HTMLAnchorElement>,
  key: string,
  inputs: InputProp[]
): void => {
  e.preventDefault();

  const input = matchingInput(key, inputs);
  if (input && input.ref.current) {
    input.ref.current.focus();
    input.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

const renderErrorText = (key: string, error: string, inputs: InputProp[]): ReactElement => {
  if (matchingInput(key, inputs)) {
    return (
      <a href={'#'} onClick={e => jumpToError(e, key, inputs)}>
        {error}
      </a>
    );
  }

  return <>{error}</>;
};

export const FormErrors: FunctionComponent<FormErrorsProps> = ({
  errors = {},
  inputs = [],
  header = "There's a problem.",
}) => {
  if (Object.keys(errors).length === 0) return null;

  return (
    <FormBanner type={FormBannerType.Error}>
      {header && (
        <OParagraph marginBlockEnd={4} fontWeight={3}>
          {header}
        </OParagraph>
      )}
      <ul>
        {Object.keys(errors).map((error, index) => (
          <ErrorTextContainer data-testid={`error-list-item-${index}`} key={error[0]}>
            {renderErrorText(error, errors[error], inputs)}
          </ErrorTextContainer>
        ))}
      </ul>
    </FormBanner>
  );
};
