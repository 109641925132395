import styled from 'styled-components';

const Container = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  height: 100%;
  box-sizing: border-box;
`;

interface CellContainerProps {
  topCell?: boolean;
  bottomCell?: boolean;
  staticCell?: boolean;
}

const CellContainer = styled.div<CellContainerProps>`
  height: calc(100% - ${props => (props.bottomCell || props.topCell ? '8px' : '16px')});
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  margin-block-start: ${props => (props.bottomCell ? '0' : '8px')};
  margin-inline-end: 8px;
  position: relative;
`;
export { Container, CellContainer };
