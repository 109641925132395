import React, { useState } from 'react';
import { Box, ButtonSize, ChatPaneModal, HostsPreview, IDetail, OHeaderThree, PrimaryButton, SecondaryTextOnlyButton, } from '@lifechurch/react-ion';
import styled from 'styled-components';
const ModalContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;
const getButtonText = (buttonText, isCustom) => {
    if (buttonText === '') {
        return 'Button Text';
    }
    else if (!isCustom) {
        return 'Talk With A Host';
    }
    else {
        return buttonText;
    }
};
function RequestPrayer({ expandToFillArea = false, hosts, isCustom, isPreview = false, labels, maxWindowHeight, onDismiss, onFollowUpResponse, selector, }) {
    const [visible, setVisible] = useState(true);
    const { buttonText, celebrationMessage, dismiss, followUpHeader } = labels;
    const handleDismiss = () => {
        if (isPreview)
            return;
        setVisible(false);
        setTimeout(() => onDismiss(), 350);
    };
    return (React.createElement(ChatPaneModal, { containerStyle: isPreview ? { marginRight: '0px' } : undefined, expandToFillArea: expandToFillArea, maxHeight: maxWindowHeight, visible: visible, selector: selector },
        React.createElement(ModalContainer, null,
            React.createElement(Box, { mb: '16px' },
                React.createElement(HostsPreview, { subscribers: hosts || [] })),
            React.createElement(OHeaderThree, { style: { marginBottom: '15px' } }, followUpHeader || 'Congratulations!'),
            React.createElement(IDetail, { style: { marginBottom: '15px', textAlign: 'center' } }, celebrationMessage),
            React.createElement(Box, { mb: '15px' },
                React.createElement(PrimaryButton, { onClick: onFollowUpResponse, size: ButtonSize.SM, style: {
                        marginRight: '5px',
                        color: buttonText === '' ? 'rgba(255,255,255,0.5)' : 'white',
                    } }, getButtonText(buttonText, isCustom))),
            React.createElement(SecondaryTextOnlyButton, { size: ButtonSize.SM, onClick: handleDismiss }, dismiss || 'Dismiss'))));
}
export { RequestPrayer };
