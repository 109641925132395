import styled, { css } from 'styled-components';
import { ReactionType } from '../dux';

interface WrapperProps {
  type: ReactionType;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  right: 0;
  transform: translateY(calc(-100% - 6px));
  padding: 6px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  z-index: 1;
  ${props =>
    props.type === ReactionType.RAISED_HANDS &&
    css`
      right: -10px;
    `}
  ${props =>
    props.type === ReactionType.CLAP &&
    css`
      right: -50px;
    `}
`;

const Divider = styled.div`
  border-left: 2px solid ${(props): string => props.theme.colors.gray10};
  margin: 0 5px;
  height: 25px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 0;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 2px;

  svg {
    transition: all 0.09s ease-in-out;
  }

  &:hover {
    background: ${(props): string => props.theme.colors.action.light};
  }

  &:active svg {
    transform: scale(0.8);
  }
`;

export { Wrapper, Divider, Button };
