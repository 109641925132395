import React, {
  FocusEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { MOMENTS_SCHEDULE_QUERY, TOGGLE_MOMENT_SCHEDULING } from '@io/queries';
import { MomentSchedule } from '@io/__generated__/MomentSchedule';
import { ToggleMomentScheduling } from '@io/__generated__/ToggleMomentScheduling';
import { useTranslation } from 'react-i18next';
import { PaneType } from '../../dux';
import PaneHeader, {
  IDefaultHeader,
  IMomentHeader,
  IScheduleHeader,
  PaneHeaderType,
} from '../../paneHeader';
import { Props, TabMeta } from './index';
import { TabWrapper } from './styles';

const Bible = React.lazy(() => import('../../../bible'));
const HostInfo = React.lazy(() => import('../../../hostInfo'));
const Moments = React.lazy(() => import('../../../moments'));
const Notes = React.lazy(() => import('../../../notes'));
const Schedule = React.lazy(() => import('../../../schedule'));

interface TabContentProps {
  isGuest: boolean;
  meta?: TabMeta;
  type: PaneType;
}

const TabContent: FunctionComponent<TabContentProps> = ({ isGuest, meta, type }) => {
  const { data, loading, momentSchedulingEnabled, showAutoPostMoment } = meta || {};

  switch (type) {
    case PaneType.HOST_INFO:
      return <HostInfo />;
    case PaneType.SCHEDULE:
      return <Schedule />;
    case PaneType.NOTES:
      return <Notes disabledClass={'ck-disabled'} isGuest={isGuest} />;
    case PaneType.MOMENTS:
      return (
        <Moments
          data={data}
          loading={loading}
          momentSchedulingEnabled={momentSchedulingEnabled}
          showAutoPostMoment={showAutoPostMoment}
        />
      );
    case PaneType.BIBLE:
      return <Bible />;
    default:
      return null;
  }
};

const Tab: FunctionComponent<Props> = ({
  guestMediumUp = false,
  isGuest,
  isOnline,
  momentSchedulingEnabled: defaultMomentSchedulingEnabled,
  sendToggleMomentsAutopostMetric,
  toggleMomentSchedulingError,
  type,
}) => {
  const { t } = useTranslation(['common', 'moments']);
  const tabRef = useRef<HTMLDivElement>(null);

  const [queryMomentsSchedule, { data, loading }] = useLazyQuery<MomentSchedule>(
    MOMENTS_SCHEDULE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (type === PaneType.MOMENTS) {
      queryMomentsSchedule();
    }
  }, [type]);

  const [momentSchedulingEnabled, setMomentSchedulingEnabled] = useState<boolean>(
    defaultMomentSchedulingEnabled
  );

  const hasMomentWithStartTimeOffset = () =>
    data?.currentService?.content?.momentScheduleItems?.some(
      item => item.startTimeOffset !== null
    ) || false;

  useEffect(() => {
    const autoPost = hasMomentWithStartTimeOffset() && defaultMomentSchedulingEnabled === true;
    setMomentSchedulingEnabled(autoPost);
  }, [data, defaultMomentSchedulingEnabled]);

  const [toggleMomentScheduling] = useMutation<ToggleMomentScheduling>(TOGGLE_MOMENT_SCHEDULING, {
    onCompleted(result) {
      if (!result.toggleMomentScheduling?.success) {
        setMomentSchedulingEnabled(!momentSchedulingEnabled);
        toggleMomentSchedulingError();
      } else {
        sendToggleMomentsAutopostMetric(momentSchedulingEnabled);
      }
    },
    onError() {
      setMomentSchedulingEnabled(!momentSchedulingEnabled);
      toggleMomentSchedulingError();
    },
  });

  const callToggleMomentScheduling = () => {
    setMomentSchedulingEnabled(!momentSchedulingEnabled);
    toggleMomentScheduling({
      variables: { serviceId: data?.currentService?.id, enabled: !momentSchedulingEnabled },
    });
  };

  const meta = () => {
    if (type !== PaneType.MOMENTS) return;

    return {
      data: data,
      loading,
      momentSchedulingEnabled: momentSchedulingEnabled,
      showAutoPostMoment: hasMomentWithStartTimeOffset() && isOnline,
      toggleMomentScheduling: callToggleMomentScheduling,
    };
  };

  const headerType = ():
    | PaneHeaderType.SCHEDULE
    | PaneHeaderType.MOMENT
    | PaneHeaderType.DEFAULT => {
    switch (type) {
      case PaneType.SCHEDULE:
        return PaneHeaderType.SCHEDULE;
      case PaneType.MOMENTS:
        return PaneHeaderType.MOMENT;
      default:
        return PaneHeaderType.DEFAULT;
    }
  };

  const header: IScheduleHeader | IDefaultHeader | IMomentHeader = {
    type: headerType(),
    payload: {
      title: t(type.toLowerCase()).toUpperCase(),
    },
  };

  const handleFocus = useCallback(() => {
    data && !loading && tabRef.current?.focus();
  }, [data, loading]);

  useLayoutEffect(() => {
    handleFocus();
  }, [handleFocus]);

  const focusFirstChild = () => {
    const childElements = tabRef.current?.querySelectorAll('.tabbable');
    childElements?.forEach((element, index) => {
      const el = element as HTMLElement;
      el.tabIndex = 0;
      index === 0 && el.focus();
    });
  };

  const onBlurHandler: FocusEventHandler<Node> = e => {
    e.preventDefault();
    const relatedTarget = e.relatedTarget as Node;
    if (e.relatedTarget === tabRef.current || !e.currentTarget.contains(relatedTarget)) {
      const childElements = tabRef.current?.querySelectorAll('.tabbable');
      childElements?.forEach(element => {
        const el = element as HTMLElement;
        el.tabIndex = -1;
      });
    }
  };

  const onKeyDownHandler: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.shiftKey && e.altKey && e.code === 'KeyE') {
      e.preventDefault();
      e.target === tabRef.current ? focusFirstChild() : handleFocus();
    }
  };

  const getAriaLabel = () => {
    switch (type) {
      case PaneType.BIBLE:
        return t('aria.pane_bible');
      case PaneType.HOST_INFO:
        return t('aria.pane_host_info');
      case PaneType.MOMENTS:
        return t('moments:aria.moments_pane');
      case PaneType.NOTES:
        return t('aria.pane_notes');
      case PaneType.SCHEDULE:
        return t('aria.pane_schedule');
    }
  };

  return (
    <TabWrapper
      aria-label={getAriaLabel()}
      onBlur={onBlurHandler}
      onKeyDown={onKeyDownHandler}
      ref={tabRef}
      tabIndex={0}
    >
      <PaneHeader header={header} guestMediumUp={guestMediumUp} meta={meta()} />
      <ErrorBoundary
        altComponentName={header.payload.title}
        displayType={ErrorBoundaryDisplayType.PANE}
      >
        <Suspense fallback={<></>}>
          <TabContent isGuest={isGuest} meta={meta()} type={type} />
        </Suspense>
      </ErrorBoundary>
    </TabWrapper>
  );
};

export default React.memo<Props>(Tab);
