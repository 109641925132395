import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const VerticalEllipses: FunctionComponent<Props> = ({ className: inClassName }) => {
  const className = clsx('fill-current', inClassName);
  return (
    <svg
      className={className}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 11C8.19036 11 8.75 11.5596 8.75 12.25C8.75 12.9404 8.19036 13.5 7.5 13.5C6.80964 13.5 6.25 12.9404 6.25 12.25C6.25 11.5596 6.80964 11 7.5 11ZM7.5 6.25C8.19036 6.25 8.75 6.80964 8.75 7.5C8.75 8.19036 8.19036 8.75 7.5 8.75C6.80964 8.75 6.25 8.19036 6.25 7.5C6.25 6.80964 6.80964 6.25 7.5 6.25ZM8.75 2.75C8.75 2.05964 8.19036 1.5 7.5 1.5C6.80964 1.5 6.25 2.05964 6.25 2.75C6.25 3.44036 6.80964 4 7.5 4C8.19036 4 8.75 3.44036 8.75 2.75Z'
      />
    </svg>
  );
};

export default VerticalEllipses;
