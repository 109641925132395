import { connect } from 'react-redux';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';
import { RootState } from '@store/rootReducer';
import AudienceOffline from '@features/audience/components/audienceOffline/audienceOffline';

interface StateProps {
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
}

const mapStateToProps = (state: RootState): StateProps => ({
  nextService: getNextServiceData(state),
});

const VisibleAudienceOffline = connect(mapStateToProps)(AudienceOffline);

export type Props = StateProps;
export default VisibleAudienceOffline;
