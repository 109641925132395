import { FieldErrorType } from '../../../types';
import { ModalType } from '../dux';
import { createAction } from '@reduxjs/toolkit';

export interface SignUpErrorArgs {
  signUpId: string;
  nicknameError: FieldErrorType | null;
  emailError: FieldErrorType | null;
  passwordError: FieldErrorType | null;
  termsError: boolean;
}

export interface SignupMeta {
  error?: boolean;
  nicknameUnavailable?: boolean;
  emailUnavailable?: boolean;
}

export interface SignupModal {
  type: ModalType.SIGNUP;
  signUpSource: string;
  meta: SignupMeta;
}

export const signupModal = (signUpSource: string, meta: SignupMeta = {}): SignupModal => ({
  type: ModalType.SIGNUP,
  signUpSource,
  meta,
});

export const signUpCancel = createAction<string>('signUp/cancel');
export const signUpError = createAction<SignUpErrorArgs>('signUp/error');
export const signUpSuccess = createAction('signUp/success');
