import React, { FunctionComponent, ReactNode } from 'react';
import { getDefaultTheme, getOpenNetworkTheme } from './theme';
import reset from 'styled-reset';
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
  DefaultTheme,
} from 'styled-components';

interface ThemeProviderProps {
  theme?: DefaultTheme;
  openNetwork?: boolean;
  children: ReactNode;
}

const GlobalStyles = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff2') format('woff2'),
         url('https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
  }

  body {
    color: ${(props): string => props.theme.colors.gray100};
  }

  body,
  div,
  input,
  button {
    font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  }
  
  ol, ul {
    margin-inline-start: 1.5rem;
    color: inherit;
  }
  
  ol li {
    list-style-type: decimal;
    color: inherit;
  }
  
  ul li {
    list-style-type: disc;
    color: inherit;
  }
  
  strong {
    font-weight: bold;
  }
  
  a {
    color: ${(props): string => props.theme.colors.action.default};
    text-decoration: none;
  }
  
  a:hover, a:focus {
    color: ${(props): string => props.theme.colors.action.hover};
    text-decoration: underline;
    outline: none;
  }
  
  hr {
    margin: 1rem 0;
    border: none;
    border-block-end: 1px solid ${(props): string => props.theme.colors.gray10};
  }


  /* Remove when datepicker styles can be import into NextJS */

  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-block-end: 1em;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin-block-start: 1em;
    margin-block-end: 0;
    margin-inline-start: 1em;
    margin-inline-end: 1em;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-block-end: 0.5em;
    padding: 0 0.5em;
    text-align: start;
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-block-start: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8B9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-block-end: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-inline-end: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: end;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-block-start: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4A90E2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: #D0021B;
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: #F7F8F8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: #4A90E2;
    color: #F0F8FF;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51A0FA;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F0F8FF;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    [dir='rtl'] {
      left: unset;
      right: 0;
    }
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  button { 
    margin: 0; 
  }
`;

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  theme,
  openNetwork = false,
  children,
}: ThemeProviderProps) => {
  let myTheme;
  if (theme) {
    myTheme = theme;
  } else {
    myTheme = openNetwork ? getOpenNetworkTheme() : getDefaultTheme();
  }
  return (
    <StyledThemeProvider theme={myTheme}>
      <>
        <GlobalStyles />
        {children}
      </>
    </StyledThemeProvider>
  );
};
