import styled, { css } from 'styled-components';
import { NavigationLinkOption } from '../../../__generated__/globalTypes';
import { getInset } from '@lifechurch/react-ion';

interface LinkProps {
  linkTextCase: NavigationLinkOption;
}

const SpacedLink = styled.a<LinkProps>`
  &&& {
    margin-block-start: 24px;
    text-decoration: none;
    text-transform: ${props =>
      props.linkTextCase === NavigationLinkOption.TITLE ? 'capitalize' : 'uppercase'};
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const OrganizationTitle = styled.div`
  font-weight: bold;
  line-height: 25px;
  font-size: 20px;
  margin-block-end: 2px;
  color: ${props => props.theme.colors.black};
`;

const EventTitle = styled.div`
  line-height: 20px;
  font-size: 16px;
  color: ${props => props.theme.colors.gray100};
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.gray5};
  border-radius: 2px;
  padding-block-end: 1.5rem;
  margin-block-start: 40px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > div:first-child,
  & > img:first-child {
    margin-block-start: -1.5rem;
  }
`;

const Nickname = styled.div`
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  margin-block-start: 0.5rem;
  color: ${props => props.theme.colors.gray100};
`;

const ProfileActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-block-start: 1rem;
`;

interface OverlayProps {
  visible: boolean;
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineStart', 0)};
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.overlay50};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  transition: ${props =>
    props.visible
      ? 'opacity 300ms cubic-bezier(0.2,0.7,0.4,1)'
      : 'opacity 300ms cubic-bezier(0.6,0,0.8,0.3),transform 300ms step-end'};
  transform: translateX(${props => (props.visible ? '0px' : '-100%')});
  opacity: ${props => (props.visible ? 1 : 0)};
`;

interface MenuProps {
  open: boolean;
}

const handleTranslate = (open: boolean, openX: string) => {
  if (open) {
    return css`
      transform: translateX(${openX});
      [dir='rtl'] &&& {
        transform: translateX(-${openX});
      }
    `;
  } else {
    return css`
      transform: translateX(-100%);
      [dir='rtl'] &&& {
        transform: translateX(100%);
      }
    `;
  }
};

const Menu = styled.div<MenuProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineStart', 0)};
  height: 100%;
  max-height: 100%;
  width: calc(100% - 56px);
  max-width: 400px;
  overflow-y: auto;
  color: ${props => props.theme.colors.gray100};
  z-index: 1001;
  box-sizing: border-box;
  padding-block-start: 24px;
  padding-block-end: 0;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  background-color: ${props => props.theme.colors.white};
  transition: transform 300ms;
  will-change: transform;
  ${props => handleTranslate(props.open, '0px')};
  transition-timing-function: ${props =>
    props.open ? 'cubic-bezier(0.2, 0.7, 0.4, 1)' : 'cubic-bezier(0.6, 0, 0.8, 0.3)'};

  @media (min-width: 640px) {
    ${getInset('insetBlockStart', '4px')};
    height: auto;
    border-radius: 4px;
    max-height: calc(100% - 8px);
    ${props => handleTranslate(props.open, '4px')};
  }

  > * {
    flex-shrink: 0;
  }
`;

interface LinkWrapperProps {
  linkTextCase?: NavigationLinkOption;
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 8px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  flex-grow: 1;

  hr {
    margin-block-start: 32px;
    margin-block-end: 8px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 240px;
  }
`;

const LoginWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray5};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 2px;
  margin-block-start: 24px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 16px 8px;
  align-items: center;
`;

const PoweredByLink = styled.a`
  border: 3px solid transparent;
  box-sizing: content-box;
  margin-block-start: 32px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-end: 32px;
  width: 186px;
  height: 42px;
  display: block;
  text-align: center;
  background: transparent
    url('https://chop.imgix.net/static/logos/powered_by_church_online_platform.png?w=186&h=42')
    no-repeat top;

  &:focus {
    border: 3px solid ${props => props.theme.colors.action.hover};
  }
`;

const ScreenReaderText = styled.span`
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
`;

export {
  EventTitle,
  LinkWrapper,
  LoginWrapper,
  Menu,
  Nickname,
  OrganizationTitle,
  Overlay,
  PoweredByLink,
  Profile,
  ProfileActions,
  ScreenReaderText,
  SpacedLink,
};
