import React from 'react';
import { Box, Flex } from '@lifechurch/react-ion';
import { ShareSheet as ShareSheetWrapper } from '../index';
import { TwitterShareButton, FacebookShareButton, EmailShareButton, CopyShareButton, } from '../sharing-buttons';
const InviteMomentShareSheet = ({ open, handleModalClose, church, }) => {
    return (React.createElement(ShareSheetWrapper, { open: open, handleModalClose: handleModalClose },
        React.createElement(Box, { marginBlockStart: 4 },
            React.createElement(Flex, { flexDirection: 'row', justifyContent: 'center' },
                React.createElement(FacebookShareButton, { url: encodeURI(window.location.origin) }),
                React.createElement(TwitterShareButton, { content: `Service is live at ${church}. Join me!`, url: window.location.href }),
                React.createElement(EmailShareButton, { subject: `Join me at ${church}`, content: `Service is live at ${church}. Join me!\n\n${window.location.origin}` }),
                React.createElement(CopyShareButton, { content: window.location.origin, buttonText: 'Copy\xa0URL' })))));
};
export { InviteMomentShareSheet };
