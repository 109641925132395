import PartyJoinedChannel from 'url:/assets/audio/party_joined_private_channel.mp3';
import PartyLeftChannel from 'url:/assets/audio/party_left_private_channel.mp3';
import InitialDirectChatMessage from 'url:/assets/audio/initial_direct_chat_message.mp3';
import PrivateMessageReceived from 'url:/assets/audio/private_message_received.mp3';
import PrayerRequest from 'url:/assets/audio/prayer_request.mp3';
import PrivateChannelInvite from 'url:/assets/audio/private_channel_invite.mp3';

export enum AudioType {
  JOINED_CHANNEL = 'JOINED_CHANNEL',
  LEFT_CHANNEL = 'LEFT_CHANNEL',
  INITIAL_DIRECT_CHAT = 'INITIAL_DIRECT_CHAT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  PRAYER_REQUEST = 'PRAYER_REQUEST',
  PRIVATE_CHANNEL_INVITE = 'PRIVATE_CHANNEL_INVITE',
}

const AUDIO_FILES = {
  [AudioType.JOINED_CHANNEL]: PartyJoinedChannel,
  [AudioType.LEFT_CHANNEL]: PartyLeftChannel,
  [AudioType.INITIAL_DIRECT_CHAT]: InitialDirectChatMessage,
  [AudioType.NEW_MESSAGE]: PrivateMessageReceived,
  [AudioType.PRAYER_REQUEST]: PrayerRequest,
  [AudioType.PRIVATE_CHANNEL_INVITE]: PrivateChannelInvite,
};

export const playAudio = (type: AudioType): void => {
  const audio = new Audio(AUDIO_FILES[type]);
  audio.play();
};
