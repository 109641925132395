import VisibleResponseMoment from './responseMoment';
import { connect } from 'react-redux';
import { ResponseMoment as ResponseMomentInterface } from '../dux';
import { submitMomentAction } from '@store/feedSlice';

interface OwnProps {
  moment: ResponseMomentInterface;
  onDismiss: () => void;
  isPinned: boolean;
  hasSubmitted: boolean;
}

const mapDispatch = { submitMomentAction };

export type Props = typeof mapDispatch & OwnProps;
export default connect(null, mapDispatch)(VisibleResponseMoment);
