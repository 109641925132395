import styled from 'styled-components';
import { Box, Flex } from '@lifechurch/react-ion';
export const DismissButton = styled(Box) `
  &:hover {
    svg circle,
    svg line {
      stroke: ${props => props.theme.colors.gray100};
    }
  }
  right: 0;
  [dir='rtl'] &&& {
    right: unset;
    left: 0;
  }
`;
export const CTAWrapper = styled(Flex) `
  position: relative;
  flex-direction: row;
  padding: 12px;
  background-color: ${props => props.theme.colors.gray5};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: ${props => (props.isPinned ? '0px 1px 3px rgba(0, 0, 0, 0.25)' : 'none')};

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
    outline: none;
  }
`;
