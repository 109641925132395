import React, { FC } from 'react';
import {
  defaultColors,
  Flex,
  IconSize,
  InfoCircleIcon,
  OXSmallParagraph,
} from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';

const ConnectionLost: FC = () => {
  const { t } = useTranslation('notifications');
  return (
    <Flex justifyContent={'center'}>
      <InfoCircleIcon color={defaultColors.gray100} size={IconSize.SM} />
      <OXSmallParagraph marginBlockStart={1} marginInlineStart={1}>
        {t('connection_lost')}
      </OXSmallParagraph>
    </Flex>
  );
};

export default ConnectionLost;
