import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const EyeIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M0.424805 7.5C1.4544 4.58702 4.23249 2.5 7.49804 2.5C10.7636 2.5 13.5417 4.58702 14.5713 7.5C13.5417 10.413 10.7636 12.5 7.49804 12.5C4.23249 12.5 1.4544 10.413 0.424805 7.5ZM10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5ZM7.5 9.5C8.60457 9.5 9.5 8.60457 9.5 7.5C9.5 6.39543 8.60457 5.5 7.5 5.5C6.39543 5.5 5.5 6.39543 5.5 7.5C5.5 8.60457 6.39543 9.5 7.5 9.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M1.51221 10C2.74772 6.50442 6.08143 4 10.0001 4C13.9187 4 17.2525 6.50443 18.488 10C17.2525 13.4956 13.9187 16 10.0001 16C6.08143 16 2.74772 13.4956 1.51221 10ZM14.0001 10C14.0001 12.2091 12.2093 14 10.0001 14C7.79098 14 6.00012 12.2091 6.00012 10C6.00012 7.79086 7.79098 6 10.0001 6C12.2093 6 14.0001 7.79086 14.0001 10ZM9.99811 12C11.1027 12 11.9981 11.1046 11.9981 10C11.9981 8.89543 11.1027 8 9.99811 8C8.89354 8 7.99811 8.89543 7.99811 10C7.99811 11.1046 8.89354 12 9.99811 12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M0.679932 12C2.32728 7.33923 6.77223 4 11.9971 4C17.222 4 21.6669 7.33924 23.3143 12C21.6669 16.6608 17.222 20 11.9971 20C6.77223 20 2.32728 16.6608 0.679932 12ZM16.9999 12C16.9999 14.7614 14.7614 17 11.9999 17C9.23851 17 6.99994 14.7614 6.99994 12C6.99994 9.23858 9.23851 7 11.9999 7C14.7614 7 16.9999 9.23858 16.9999 12ZM11.9999 15C13.6568 15 14.9999 13.6569 14.9999 12C14.9999 10.3431 13.6568 9 11.9999 9C10.3431 9 8.99994 10.3431 8.99994 12C8.99994 13.6569 10.3431 15 11.9999 15Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M0.439941 16C2.70504 9.59144 8.81686 5 16.0011 5C23.1853 5 29.2971 9.59144 31.5622 16C29.2971 22.4086 23.1853 27 16.0011 27C8.81686 27 2.70504 22.4086 0.439941 16ZM22.9999 16C22.9999 19.866 19.8659 23 15.9999 23C12.1339 23 8.99994 19.866 8.99994 16C8.99994 12.134 12.1339 9 15.9999 9C19.8659 9 22.9999 12.134 22.9999 16ZM15.9999 21C18.7614 21 20.9999 18.7614 20.9999 16C20.9999 13.2386 18.7614 11 15.9999 11C13.2385 11 10.9999 13.2386 10.9999 16C10.9999 18.7614 13.2385 21 15.9999 21Z'
          size={size}
          {...rest}
        />
      );
  }
};
