import styled from 'styled-components';

interface DotPropsType {
  hasActions: boolean;
}

const Dot = styled.div<DotPropsType>`
  height: ${props => (props.hasActions ? '8px' : '6px')};
  width: ${props => (props.hasActions ? '8px' : '6px')};
  border-radius: 50%;
  background-color: ${props =>
    props.hasActions ? props.theme.colors.action.default : props.theme.colors.gray50};
  margin: 0 auto;
`;

export { Dot };
