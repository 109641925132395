import { HelmetProvider } from 'react-helmet-async';

window.PAGE_LOAD_TIME = new Date();
window.ga =
  window.ga ||
  function () {
    // @ts-expect-error
    // eslint-disable-next-line prefer-rest-params
    (ga.q = ga.q || []).push(arguments);
  };
// @ts-expect-error
ga.l = +new Date();

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './lib/i18n';
import './lib/sentry';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from '@app/index';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from '@lifechurch/react-ion';
import { AppStyles } from './styles';
import Cookie from 'js-cookie';
import { successAlert } from '@components/Alert/dux';
import { ApolloProvider } from '@apollo/client';
import { client } from '@io/queries';
import { store } from '@store/store';
import { v4 as uuidv4 } from 'uuid';
import Modal from '@components/modal';
import { FeedBoundaryProvider } from './context/FeedBoundaryContext';
import { viewingAsUser } from '@store/uiSlice';
import './index.module.css';

Cookie.set('SESSIONID', uuidv4());

const persistor = persistStore(store);

const content = document.getElementById('content');

if (Cookie.get('email_verified')) {
  store.dispatch(successAlert('email_verified'));
  Cookie.remove('email_verified');
}

const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('previewServiceId')) {
  const previewServiceId = searchParams.get('previewServiceId');

  if (previewServiceId) {
    Cookie.set('preview_service', previewServiceId);
    store.dispatch(viewingAsUser(true));
  }
}

window.addEventListener('unload', () => {
  Cookie.remove('preview_service');
});

if (content) {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback=''>
        <HelmetProvider>
          <ApolloProvider client={client}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary>
                  <ThemeProvider openNetwork>
                    <FeedBoundaryProvider>
                      <AppStyles />
                      <App />
                      <Modal />
                    </FeedBoundaryProvider>
                  </ThemeProvider>
                </ErrorBoundary>
              </PersistGate>
            </Provider>
          </ApolloProvider>
        </HelmetProvider>
      </React.Suspense>
    </React.StrictMode>,
    content
  );
}
