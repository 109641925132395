import React, { FunctionComponent } from 'react';
import { BaseAlert } from './BaseAlert';
import { AlertInterface, AlertSubtype } from './dux';
import { Trans, useTranslation } from 'react-i18next';
import { AlertContainer, Flex } from '@lifechurch/react-ion';
import { Props } from './index';
import PrayerAlert from './PrayerAlert';
import DirectChatAlert from './DirectChatAlert';
import WelcomeAlert from './WelcomeAlert';
import styled from 'styled-components';

interface AlertProps {
  alert: AlertInterface;
  dismissAlert: () => void;
  currentSubscriberNickname: string;
  currentOrganizationName: string;
}

const Alert: FunctionComponent<AlertProps> = ({
  alert,
  dismissAlert,
  currentSubscriberNickname,
  currentOrganizationName,
}) => {
  const { t } = useTranslation('notifications');

  if (alert === undefined) {
    return null;
  }

  switch (alert.subtype) {
    case AlertSubtype.DEFAULT:
      return (
        <BaseAlert
          uniqueKey={alert.uniqueKey}
          type={alert.type}
          onDismiss={dismissAlert}
          fuseDisabled={alert.fuseDisabled}
          fuseTimer={alert.fuseTimer}
          animationDisabled={alert.animationDisabled}
        >
          {t(alert.key, { ...alert.variables })}
        </BaseAlert>
      );
    case AlertSubtype.MUTED: {
      if (alert?.variables?.name) {
        return (
          <BaseAlert
            uniqueKey={alert.uniqueKey}
            type={alert.type}
            onDismiss={dismissAlert}
            fuseDisabled={alert.fuseDisabled}
            fuseTimer={alert.fuseTimer}
          >
            <Trans ns='notifications' i18nKey={alert.key}>
              <strong>{{ name: alert.variables.name }}</strong> was muted.
            </Trans>
          </BaseAlert>
        );
      } else {
        return null;
      }
    }
    case AlertSubtype.UNMUTED: {
      if (alert?.variables?.name) {
        return (
          <BaseAlert
            uniqueKey={alert.uniqueKey}
            type={alert.type}
            onDismiss={dismissAlert}
            fuseDisabled={alert.fuseDisabled}
            fuseTimer={alert.fuseTimer}
          >
            <Trans ns='notifications' i18nKey={alert.key}>
              <strong>{{ name: alert.variables.name }}</strong> was unmuted.
            </Trans>
          </BaseAlert>
        );
      } else {
        return null;
      }
    }
    case AlertSubtype.LOGGED_IN: {
      if (currentSubscriberNickname) {
        return (
          <BaseAlert
            uniqueKey={alert.uniqueKey}
            type={alert.type}
            onDismiss={dismissAlert}
            fuseDisabled={alert.fuseDisabled}
            fuseTimer={alert.fuseTimer}
          >
            <Trans ns='notifications' i18nKey={alert.key}>
              Logged in as <strong>{{ name: currentSubscriberNickname }}</strong>
            </Trans>
          </BaseAlert>
        );
      } else {
        return null;
      }
    }
    case AlertSubtype.DIRECT_CHAT: {
      return <DirectChatAlert alert={alert} dismissAlert={dismissAlert} />;
    }
    case AlertSubtype.PRAYER: {
      return <PrayerAlert alert={alert} dismissAlert={dismissAlert} />;
    }
    case AlertSubtype.SIGN_UP: {
      if (currentSubscriberNickname && currentOrganizationName) {
        return (
          <BaseAlert
            uniqueKey={alert.uniqueKey}
            type={alert.type}
            onDismiss={dismissAlert}
            fuseDisabled={alert.fuseDisabled}
            fuseTimer={alert.fuseTimer}
          >
            <Trans ns='notifications' i18nKey={alert.key}>
              Welcome to {{ currentOrganizationName }}! You are logged in as{' '}
              {{ currentSubscriberNickname }}.
            </Trans>
          </BaseAlert>
        );
      } else {
        return null;
      }
    }
    case AlertSubtype.WELCOME: {
      return <WelcomeAlert alert={alert} dismissAlert={dismissAlert} />;
    }
    default:
      return null;
  }
};

const AlertWrapper = styled(Flex)`
  [dir='rtl'] &&& {
    left: 4px;
    @media (max-width: 639px) {
      left: 0;
    }
  }
  [dir='ltr'] &&& {
    right: 4px;
    @media (max-width: 639px) {
      right: 0;
    }
  }
`;

const Alerts: FunctionComponent<Props> = ({
  alerts,
  dismissAlert,
  currentSubscriberNickname,
  currentOrganizationName,
  containerId,
}) => {
  const dismiss = (uniqueKey: string): void => {
    dismissAlert(uniqueKey);
  };

  if (containerId === 'chat') {
    const alert = alerts[0] ?? undefined;
    return (
      // this is a custom alert container
      <AlertWrapper
        flexDirection='column'
        position='absolute'
        zIndex={9999}
        bottom={'54px'}
        maxWidth={['100%', '800px']}
        width='calc(100% - 16px)'
        marginBlockStart={0}
        marginBlockEnd={0}
        marginInlineStart={0}
        marginInlineEnd={2}
        height='auto'
      >
        {alert && (
          <Alert
            key={alert.uniqueKey}
            alert={alert}
            dismissAlert={() => alerts.map(a => dismiss(a.uniqueKey))}
            currentOrganizationName={currentOrganizationName}
            currentSubscriberNickname={currentSubscriberNickname}
          />
        )}
      </AlertWrapper>
    );
  } else {
    return (
      <AlertContainer>
        {alerts?.map(alert => (
          <Alert
            key={alert.uniqueKey}
            alert={alert}
            dismissAlert={() => dismiss(alert.uniqueKey)}
            currentOrganizationName={currentOrganizationName}
            currentSubscriberNickname={currentSubscriberNickname}
          />
        ))}
      </AlertContainer>
    );
  }
};

export default Alerts;
