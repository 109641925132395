import { ModalType } from '../dux';

export enum SelectChatNameSubtype {
  CHAT = 'CHAT',
  PRAYER = 'PRAYER',
}

export interface SelectChatNameMeta {
  nicknameUnavailable?: boolean;
  momentId?: string;
}

export interface SelectChatName {
  type: ModalType.SELECT_CHAT_NAME;
  subtype: SelectChatNameSubtype;
  meta?: SelectChatNameMeta;
}

export const selectChatName = (
  subtype: SelectChatNameSubtype,
  meta?: SelectChatNameMeta
): SelectChatName => ({
  type: ModalType.SELECT_CHAT_NAME,
  subtype,
  meta,
});
