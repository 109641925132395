import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { CommentIcon, IconSize } from '@lifechurch/react-ion';
import { Notification as NotificationInterface } from '../dux';
import SenderName from '../../senderName';
import { localizedTimeReference } from '@utils/core';
import styles from '../notification.module.css';
import clsx from 'clsx';

interface Props {
  notification: NotificationInterface;
  isCompact: boolean;
}

export const JoinedChannelNotification: React.FunctionComponent<Props> = (props: Props) => {
  const {
    notification: {
      subscriber: { nickname, roleIdentifier },
      timestamp,
    },
    isCompact,
  } = props;

  const { t } = useTranslation();
  const { t: nt } = useTranslation('moments');
  const theme = useContext(ThemeContext);

  return (
    <div
      className={clsx([styles.wrapper], {
        [styles.compactWrapperMarginInline]: isCompact,
      })}
      data-testid={'notification'}
    >
      <span className={styles.icon} data-testid={'notification-icon'}>
        <CommentIcon size={IconSize.SM} color={theme.colors.gray50} />
      </span>
      <p className={styles.text} data-testid={'notification-message'}>
        <SenderName name={nickname || ''} roleIdentifier={roleIdentifier} />{' '}
        {nt('joined_chat.notification')}
        <span className={styles.timestamp} data-testid='notification-timestamp'>
          {localizedTimeReference(timestamp, t)}
        </span>
      </p>
    </div>
  );
};

export const JoinedChannel: React.FunctionComponent<Props> =
  React.memo<Props>(JoinedChannelNotification);
