import { getChannelByKey } from './channelSelectors';
import { getCurrentSubscriber } from '../subscriberSlice/selectors';
import { i18n } from '../../lib/i18n';
import { PublicSubscriber, PublicSubscriber as Subscriber } from '../../types';
import { ChannelType } from '../../../__generated__/globalTypes';
import {
  RequestChannelQuery_requestChannel_DirectChannel_subscribers_nodes,
  RequestChannelQuery_requestChannel_PrayerChannel_subscribers_nodes,
} from '@io/__generated__/RequestChannelQuery';
import { RootState } from '@store/rootReducer';

const getOtherSubscribers = (state: RootState, key: string): Array<Subscriber> => {
  const channel = getChannelByKey(state, key);
  return channel && channel?.subscribers
    ? channel.subscribers.filter(
        (subscriber: Subscriber) =>
          subscriber?.id !== state.subscriber.currentSubscriber?.id && subscriber?.id !== ''
      )
    : [];
};

const hasOtherSubscribers = (state: RootState, key: string): boolean => {
  const subscribers = getOtherSubscribers(state, key);
  return subscribers.length > 0;
};

const getOtherSubscribersNames = (state: RootState, key: string): Array<string> => {
  const subscribers = getOtherSubscribers(state, key);
  return subscribers.map(subscriber => subscriber.nickname || '');
};

const getPlaceholder = (state: RootState, key: string): string => {
  const channel = getChannelByKey(state, key);
  const subscriberNames = getOtherSubscribersNames(state, key);

  switch (channel?.type) {
    case ChannelType.host: {
      const nickName = i18n.t('hosts').toLocaleLowerCase();
      return i18n.t('chat_with', { nickname: nickName });
    }
    case ChannelType.direct: {
      if (subscriberNames.length === 0) {
        return i18n.t('chat');
      } else {
        return `${i18n.t('chat_with')}${subscriberNames
          .join(', ')
          .replace(/,\\s([^,]*)$/, ' and $1')}`;
      }
    }
    case ChannelType.public:
    default:
      return i18n.t('chat');
  }
};

const requestChannelSubscribersToPublicSubscribers = (
  nodes:
    | Array<
        | RequestChannelQuery_requestChannel_DirectChannel_subscribers_nodes
        | RequestChannelQuery_requestChannel_PrayerChannel_subscribers_nodes
        | null
      >
    | null
    | undefined
): Array<PublicSubscriber> => {
  const subscribers: Array<PublicSubscriber> = [];

  nodes?.forEach(subscriber => {
    if (subscriber) {
      subscribers.push({
        id: subscriber.id,
        nickname: subscriber.nickname,
        roleIdentifier: subscriber.roleIdentifier,
        avatar: subscriber.avatar,
      });
    }
  });

  return subscribers;
};

export {
  getPlaceholder,
  getOtherSubscribers,
  hasOtherSubscribers,
  getCurrentSubscriber,
  requestChannelSubscribersToPublicSubscribers,
  getOtherSubscribersNames,
};
