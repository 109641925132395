import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const MicrophoneSlashIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.50004 1C6.11933 1 5.00004 2.11929 5.00004 3.5V7.5C5.00004 7.62014 5.00851 7.73831 5.0249 7.85392L9.9374 2.94142C9.68366 1.82961 8.68873 1 7.50004 1ZM7.00004 10.9646C6.76644 10.9311 6.54029 10.8747 6.32396 10.7975L5.55999 11.5615C6.00451 11.7742 6.4894 11.9161 7.00004 11.9725V13H5.00004V14H7.00004H8.00004H10V13H8.00004V11.9725C10.25 11.7238 12 9.81628 12 7.5V7H11V7.5C11 9.26324 9.69619 10.7219 8.00004 10.9646V9.94999C9.14116 9.71836 10 8.70948 10 7.5V7.12142L7.00004 10.1214V10.9646ZM4.00004 7.5C4.00004 7.91265 4.07145 8.30862 4.20259 8.67623L3.43863 9.44019C3.15748 8.85272 3.00004 8.19475 3.00004 7.5V7H4.00004V7.5ZM2.35359 13.3536L13.3536 2.35355L12.6465 1.64645L1.64648 12.6464L2.35359 13.3536Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
