import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
  /**
   * The width of the placeholder.
   */
  width: number;
  /**
   * The height of the placeholder.
   */
  height: number;
  /**
   * The color of the background.
   */
  backgroundColor?: string;
  /**
   * The color of the "wave" that moves over the background.
   */
  foregroundColor?: string;
  uniqueKey?: string;
}

export function ContentPlaceholder({
  height,
  width,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  uniqueKey,
}: Props) {
  return (
    <ContentLoader
      uniqueKey={uniqueKey}
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x='0' y='0' rx='3' ry='3' width={width} height={height} />
    </ContentLoader>
  );
}
