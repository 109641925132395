import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const EnvelopeOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M2 3.99994H13V4.00304L7.5 7.44054L2 4.00304V3.99994ZM2 5.18228V10.9999H13V5.18228L7.765 8.45416L7.5 8.61978L7.235 8.45416L2 5.18228ZM1 2.99994H2H13H14V3.99994V10.9999V11.9999H13H2H1V10.9999V3.99994V2.99994Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M4 6.07075V6H16V6.07075L10 9.82075L4 6.07075ZM4 8.42925V14H16V8.42925L10.53 11.848L10 12.1792L9.47 11.848L4 8.42925ZM2 4H4H16H18V6V14V16H16H4H2V14V6V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M4 6H20V6.17933L12 11.7793L4 6.17933V6ZM4 8.62064V18H20V8.62064L12.5735 13.8192L12 14.2206L11.4265 13.8192L4 8.62064ZM2 4H4H20H22V6V18V20H20H4H2V18V6V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M4 8H28V8.0969L16 15.8112L4 8.0969V8ZM4 10.4745V24H28V10.4745L16.5408 17.8412L16 18.1888L15.4592 17.8412L4 10.4745ZM2 6H4H28H30V8V24V26H28H4H2V24V8V6Z'
          size={size}
          {...rest}
        />
      );
  }
};
