import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CountDownNumber, TimeBoxContainer, TypeText } from '../styles';
import { Color, Mode, Width } from '../';

interface props {
  increment: number | null;
  type: string;
  settings: {
    color: Color;
    mode: Mode;
    width: Width;
  };
}

const TimeBox: FC<props> = ({ increment, type, settings }) => {
  const { color, mode, width } = settings;
  const { t } = useTranslation();

  if (!increment && type !== 'minutes' && type !== 'seconds') return null;

  const singularize = (input: string): string => input.slice(0, -1);
  const resolvedType = increment === 1 ? singularize(type) : type;

  return (
    <TimeBoxContainer color={color} mode={mode} width={width}>
      <CountDownNumber color={color} mode={mode}>
        {increment}
      </CountDownNumber>
      <TypeText color={color} mode={mode}>
        {t(`time.${resolvedType}`)}
      </TypeText>
    </TimeBoxContainer>
  );
};

export default React.memo(TimeBox);
