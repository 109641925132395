import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import queries from '../queries';
import { call, put, select } from 'redux-saga/effects';
import { isOffline } from '@store/serviceSlice/selectors';
import { setSchedule } from '@store/scheduleSlice';
import { sequence } from './currentService';
import { FetchResult } from '@apollo/client';
import { ServicesConnectionQuery } from '../__generated__/ServicesConnectionQuery';
import { SequenceQuery } from '../__generated__/SequenceQuery';
import { setServiceFetched } from '@store/uiSlice';

function* schedule() {
  try {
    const result: FetchResult<ServicesConnectionQuery> = yield call([queries, queries.schedule]);
    if (result?.data?.currentOrganization?.servicesConnection) {
      const { services } = result.data.currentOrganization.servicesConnection;
      const isBetweenServices: boolean = yield select(isOffline);
      const currentTime = dayjs();
      const futureScheduleServices = services.filter(service => {
        if (service?.endTime || service?.scheduleTime) {
          return dayjs(service?.endTime || service?.scheduleTime).isAfter(currentTime);
        } else {
          Sentry.withScope(scope => {
            if (service.id) {
              scope.setTag('service_id', service.id);
            }
            Sentry.captureMessage(
              'Scheduled service has either, startTime, endTime or scheduleTime as null'
            );
          });
          return false;
        }
      });

      yield put(setSchedule(futureScheduleServices));
      yield put(setServiceFetched());

      if (isBetweenServices) {
        const [nextService] = futureScheduleServices;
        if (nextService && nextService.startTime) {
          const result: FetchResult<SequenceQuery> = yield call(
            [queries, queries.sequence],
            nextService.startTime
          );
          if (result.data?.serviceAt) {
            yield* sequence(result.data.serviceAt.sequence);
          }
        }
      }
    }
  } catch (error) {
    //
  }
}

export default schedule;
