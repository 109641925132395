import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CheckCircleIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.49998 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.49998 1.5C4.18628 1.5 1.49998 4.18629 1.49998 7.5C1.49998 10.8137 4.18628 13.5 7.49998 13.5ZM6.85355 10.3536L11.3536 5.85355L10.6464 5.14645L6.5 9.29289L4.35355 7.14645L3.64645 7.85355L6.14645 10.3536L6.5 10.7071L6.85355 10.3536Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.20711 13.2071L14.7071 7.70711L13.2929 6.29289L8.5 11.0858L6.20711 8.79289L4.79289 10.2071L7.79289 13.2071L8.5 13.9142L9.20711 13.2071Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.9571 16.2071L17.9571 9.20711L16.5429 7.79289L10.25 14.0858L6.95711 10.7929L5.54289 12.2071L9.54289 16.2071L10.25 16.9142L10.9571 16.2071Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM14.7071 21.7071L24.2071 12.2071L22.7929 10.7929L14 19.5858L8.70711 14.2929L7.29289 15.7071L13.2929 21.7071L14 22.4142L14.7071 21.7071Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
  }
};
