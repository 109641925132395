import { RootState } from '@store/rootReducer';
import VisibleSalvationMoment from './salvationMoment';
import { SalvationMoment } from '../dux';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { requestLivePrayer, submitMomentAction } from '@store/feedSlice';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { isLivePrayerEnabled } from '@store/serviceSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { salvationMomentConnect, salvationMomentFollowUpDismiss } from '@features/moments/dux';
import { setModal } from '@store/uiSlice';

interface StateProps {
  currentSubscriberNickname: string | null;
  hasOfflinePrayerRecipients: boolean;
  isLivePrayerEnabled: boolean;
}

interface OwnProps {
  hasSubmitted: boolean;
  isPinned: boolean;
  onDismiss: () => void;
  moment: SalvationMoment;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const livePrayerEnabled = isLivePrayerEnabled(state);
  const hasOfflinePrayerRecipients =
    getCurrentOrganization(state).offlinePrayerRecipients.length > 0;
  const currentSubscriberNickname = getCurrentSubscriber(state).nickname;

  return {
    isLivePrayerEnabled: livePrayerEnabled,
    hasOfflinePrayerRecipients,
    currentSubscriberNickname,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      submitMomentAction,
      requestLivePrayer,
      setModal,
      salvationMomentConnect,
      salvationMomentFollowUpDismiss,
    },
    dispatch
  );

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(VisibleSalvationMoment);
