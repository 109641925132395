import { bindActionCreators, Dispatch } from 'redux';
import { submitMomentAction } from '@store/feedSlice';
import { connect } from 'react-redux';
import { ImageMoment as ImageMomentInterface } from '@features/feed/objects/moment/dux';
import { getCurrentService } from '@store/serviceSlice/selectors';
import { clearModal, setModal } from '@store/uiSlice';
import Modal from './modal';
import { momentLiked } from '@store/feedSlice/channelSelectors';
import { RootState } from '@store/rootReducer';

interface StateProps {
  serviceTitle: string;
  hasLiked: boolean;
}

interface OwnProps {
  moment: ImageMomentInterface;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const { moment } = ownProps;
  const hasLiked = Boolean(moment && momentLiked(state, moment.id));

  return {
    serviceTitle: getCurrentService(state).content.title,
    hasLiked,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ submitMomentAction, setModal, clearModal }, dispatch);

export type Props = OwnProps & ReturnType<typeof mapDispatchToProps> & StateProps;
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
