import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CogIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M4.01692 3.63818L7.48102 1.63818L8.91769 3.08319C9.27045 3.02843 9.63192 3.00002 10 3.00002C10.3674 3.00002 10.7281 3.02831 11.0802 3.08284L12.5169 1.63696L15.981 3.63696L15.4478 5.60403C15.9004 6.16423 16.2684 6.79565 16.5323 7.47886L18.5 8.00002V12L16.5325 12.5205C16.2687 13.2037 15.9009 13.8352 15.4483 14.3954L15.981 16.3606L12.5169 18.3606L11.0823 16.9169C10.7295 16.9716 10.3681 17 10 17C9.63118 17 9.269 16.9715 8.91556 16.9165L7.48102 18.3594L4.01691 16.3594L4.55031 14.3936C4.09844 13.8339 3.73106 13.203 3.46755 12.5205L1.50003 12V8.00002L3.46779 7.47886C3.73138 6.79636 4.09885 6.16554 4.55081 5.60577L4.01692 3.63818ZM10 13.0001C11.6569 13.0001 13 11.6569 13 10.0001C13 8.34321 11.6569 7.00006 10 7.00006C8.34316 7.00006 7.00001 8.34321 7.00001 10.0001C7.00001 11.6569 8.34316 13.0001 10 13.0001Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M15.4635 22.7024L19.5389 20.3494L18.8099 17.8845C19.5466 17.0326 20.1263 16.0411 20.5037 14.955L23 14.3546V9.64868L20.5047 9.04788C20.1274 7.96063 19.5474 6.9681 18.8101 6.11549L19.5389 3.65117L15.4635 1.29823L13.6951 3.15927C13.1462 3.05464 12.5795 2.99988 12 2.99988C11.4214 2.99988 10.8555 3.05448 10.3073 3.15881L8.53895 1.29688L4.46354 3.64982L5.1916 6.11364C4.45346 6.96666 3.87295 7.95983 3.49534 9.04787L1.00002 9.64868V14.3546L3.49641 14.955C3.87408 16.0419 4.45425 17.0341 5.19177 17.8863L4.46352 20.3508L8.53894 22.7037L10.308 20.8411C10.856 20.9453 11.4216 20.9999 12 20.9999C12.5793 20.9999 13.1457 20.9452 13.6945 20.8406L15.4635 22.7024ZM12 16.0001C14.2092 16.0001 16 14.2093 16 12.0001C16 9.79098 14.2092 8.00012 12 8.00012C9.79089 8.00012 8.00003 9.79098 8.00003 12.0001C8.00003 14.2093 9.79089 16.0001 12 16.0001Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M26.3546 27.3423L20.649 30.6365L17.9601 27.8408C17.3223 27.9455 16.6675 28 16 28C15.3338 28 14.6801 27.9457 14.0434 27.8413L11.3545 30.6384L5.64897 27.3442L6.72614 23.616C5.8989 22.6099 5.23221 21.4667 4.76522 20.2258L1 19.2961V12.7078L4.76406 11.7774C5.23111 10.5352 5.89824 9.391 6.72618 8.38401L5.64898 4.65569L11.3546 1.36157L14.0435 4.15874C14.6802 4.05433 15.3338 4.00004 16 4.00004C16.6675 4.00004 17.3222 4.05453 17.9599 4.1593L20.649 1.36348L26.3545 4.6576L25.2761 8.3867C26.103 9.39304 26.7694 10.5363 27.236 11.7774L31 12.7078V19.2961L27.2349 20.2258C26.7683 21.4656 26.1024 22.6078 25.2761 23.6133L26.3546 27.3423ZM16 21C18.7615 21 21 18.7614 21 16C21 13.2386 18.7615 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z'
          size={size}
          {...rest}
        />
      );
  }
};
