import Cookie from 'js-cookie';

const reactionSkinTones = {
  default: '#FFBA0C',
  light: '#FAE0C0',
  medium_light: '#E3C29C',
  medium: '#C1916A',
  medium_dark: '#A06940',
  dark: '#5C473C',
};

const getColorFromTone = (skinTone: number): string => {
  switch (skinTone) {
    case 1:
      return reactionSkinTones.default;
    case 2:
      return reactionSkinTones.light;
    case 3:
      return reactionSkinTones.medium_light;
    case 4:
      return reactionSkinTones.medium;
    case 5:
      return reactionSkinTones.medium_dark;
    case 6:
      return reactionSkinTones.dark;
    default:
      return reactionSkinTones.default;
  }
};

const setReactionBannerStatus = (hasAccount: boolean) => {
  return new Promise<void>(resolve => {
    if (!hasAccount && !Cookie.get('reaction_banner')) {
      Cookie.set('reaction_banner', 'true', { expires: 1000 });
      resolve();
    } else if (hasAccount && !localStorage.getItem('reaction_banner')) {
      localStorage.setItem('reaction_banner', 'true');
      resolve();
    }
  });
};

export { getColorFromTone, reactionSkinTones, setReactionBannerStatus };
