import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from '../../../types';
import { AvatarWrapper, FieldWrapper } from './styles';
import { subscriberToPublicSubscriber } from '@store/subscriberSlice/selectors';
import { Props } from './index';
import {
  Flex,
  FormErrors,
  getInset,
  Grid,
  GridItem,
  Input,
  LinkButton,
  Modal,
  ModalSize,
  PrimaryButton,
  PrimaryOnDarkButton,
  SecondaryRiskButton,
} from '@lifechurch/react-ion';
import AvatarPicker from '../../AvatarPicker/AvatarPicker';
import styled from 'styled-components';
import { nicknameIsDuplicate } from './profileSettings.utils';
import { useFormik, FormikErrors } from 'formik';

const DISABLE_PROVIDERS = [
  AuthProvider.FACEBOOK_AUTH,
  AuthProvider.LEGACY_SSO_AUTH,
  AuthProvider.OPEN_ID_CONNECT_AUTH,
];

const FormBannerContainer = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const PasswordItem = styled(GridItem)`
  position: relative;
`;

const ChangeButton = styled(PrimaryOnDarkButton)`
  display: flex;
  position: absolute;
  ${getInset('insetInlineEnd', '8px')};
  ${getInset('insetBlockStart', '26px')};
`;

const ProfileSettings: FC<Props> = ({
  closeModal,
  imageTooLargeError,
  logout,
  globalModal,
  promptChangePassword,
  promptDeleteAccount,
  promptRemoveAvatar,
  provider,
  saveSubscriber,
  subscriber,
  uploadAvatar,
}) => {
  const { t } = useTranslation(['forms', 'common']);
  const [avatarLoading, setAvatarLoading] = useState<boolean>(false);
  const sharedSubscriber = subscriberToPublicSubscriber(subscriber);

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      // Max file size of 10 MB
      if (event.target.files[0].size / 1000000 > 10) {
        imageTooLargeError();
      } else {
        const formData = new FormData();
        formData.append('avatar', event.target.files[0]);
        uploadAvatar(subscriber.id, formData);
        setAvatarLoading(true);
      }
    }
  };

  useEffect(() => {
    setAvatarLoading(false);
  }, [subscriber.avatar]);

  const isDisabled = DISABLE_PROVIDERS.includes(provider as AuthProvider);
  const avatar: string | undefined = sharedSubscriber.avatar ?? undefined;
  const nickname: string = sharedSubscriber.nickname ?? '';
  const id: string = sharedSubscriber.id ?? undefined;

  const duplicateNickname = globalModal ? nicknameIsDuplicate(globalModal) : false;

  const formik = useFormik({
    initialValues: {
      nickname: subscriber.nickname ?? '',
      firstName: subscriber.firstName,
      lastName: subscriber.lastName,
      email: subscriber.email,
    },
    onSubmit: values => {
      const { nickname, ...rest } = values;
      saveSubscriber(subscriber.id, { nickname: nickname?.trim(), ...rest });
    },
    validate: values => {
      const errors: FormikErrors<typeof values> = {};

      if (values.nickname.trim().length <= 2) {
        errors.nickname = t('signup.short_chat_name');
      }

      if (values.nickname.trim().length >= 29) {
        errors.nickname = t('signup.long_chat_name');
      }

      return errors;
    },
    validateOnChange: false,
  });

  return (
    <Modal
      testId='profileSettings'
      size={ModalSize.NARROW}
      dismiss={closeModal}
      includePadding={false}
    >
      <AvatarWrapper>
        {duplicateNickname && (
          <FormBannerContainer>
            <FormErrors
              errors={{ nickname: t('forms:profile.chat_name_taken') }}
              header={t('common:theres_a_problem')}
            />
          </FormBannerContainer>
        )}

        <AvatarPicker
          subscriber={{ id, avatar, nickname }}
          avatarLoading={avatarLoading}
          handleAvatarUpload={handleAvatarUpload}
          removeAvatar={promptRemoveAvatar}
        />
        <Input
          type='text'
          name='nickname'
          onChange={formik.handleChange}
          value={formik.values.nickname || ''}
          autoComplete='nickname'
          data-testid='nickname'
          aria-label='Edit UserName'
          error={!!formik.errors.nickname || duplicateNickname}
          hint={duplicateNickname ? t('signup.unavailable_chat_name') : formik.errors.nickname}
        />
      </AvatarWrapper>

      <FieldWrapper>
        <Grid gridRowGap={5} width='100%'>
          <GridItem>
            <Input
              type='text'
              name='firstName'
              label={t('profile.first_name')}
              onChange={formik.handleChange}
              value={formik.values.firstName || ''}
              autoComplete='given-name'
              disabled={isDisabled}
            />
          </GridItem>

          <GridItem>
            <Input
              type='text'
              name='lastName'
              label={t('profile.last_name')}
              onChange={formik.handleChange}
              value={formik.values.lastName || ''}
              autoComplete='family-name'
              disabled={isDisabled}
            />
          </GridItem>

          <GridItem>
            <Input
              type='email'
              name='email'
              label={t('profile.email')}
              onChange={formik.handleChange}
              value={formik.values.email || ''}
              autoComplete='email'
              disabled={isDisabled}
            />
          </GridItem>

          {!isDisabled && (
            <PasswordItem>
              <Input
                type='password'
                name='password'
                label={t('profile.password')}
                onChange={formik.handleChange}
                value={'********'}
                disabled={true}
              />
              <ChangeButton aria-label='Edit Password' onClick={promptChangePassword} size='xs'>
                {t('profile.change_password')}
              </ChangeButton>
            </PasswordItem>
          )}

          <GridItem marginBlockStart={3}>
            <Flex justifyContent='space-between'>
              <LinkButton tabIndex={0} onClick={logout} role='button'>
                {t('common:log_out')}
              </LinkButton>
              <PrimaryButton onClick={formik.handleSubmit}>{t('profile.save')}</PrimaryButton>
            </Flex>
          </GridItem>
          <hr />
          <GridItem justifySelf='end'>
            <SecondaryRiskButton onClick={promptDeleteAccount}>
              {t('profile.delete_account')}
            </SecondaryRiskButton>
          </GridItem>
        </Grid>
      </FieldWrapper>
    </Modal>
  );
};

export default React.memo<Props>(ProfileSettings);
