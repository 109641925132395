import styled from 'styled-components';
import { Box } from '@lifechurch/react-ion';
export const HasText = styled(Box) `
  p {
    color: ${props => !props.hasText && props.theme.colors.gray50};
  }
  button {
    opacity: ${props => !props.hasButtonText && 0.5};
  }
`;
