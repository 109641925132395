import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { State } from './index';
import {
  Box,
  Checkbox,
  Input,
  Flex,
  Modal,
  ModalSize,
  OHeaderThree,
  PrimaryButton,
  FormErrors,
} from '@lifechurch/react-ion';
import PasswordStrength from '../../PasswordStrength';
import { InlineLink, StyledOParagraph } from './styles';
import { FormikProps } from 'formik';
import { EmailConsentType } from '../../../../__generated__/globalTypes';
import UploadAvatar from './uploadAvatar';

const AcceptInvite: FC<FormikProps<State>> = ({ values, handleSubmit, handleChange, errors }) => {
  const { t } = useTranslation('forms');
  const { termsUrl, privacyUrl } = values.organization;

  const hasEmailConsent =
    values.organization.emailConsent === EmailConsentType.ALL ||
    (values.currentSubscriber.gdpr && values.organization.emailConsent === EmailConsentType.EU);

  const errorMessages = Object.keys(errors).reduce((obj, key) => {
    return {
      ...obj,
      // @ts-expect-error
      [errors[key]]: t(errors[key]),
    };
  }, {});

  if (!values.currentSubscriber.email) {
    return null;
  }

  return (
    <Modal dismiss={values.closeModal} size={ModalSize.NARROW} testId='acceptInvite-modal'>
      <OHeaderThree marginBlockEnd={5}>{t('accept_invite.title')}</OHeaderThree>
      {Object.keys(errors).length > 0 && <FormErrors errors={errorMessages} />}
      <form onSubmit={handleSubmit} noValidate>
        <UploadAvatar nickname={values.nickname} />
        <Box marginBlockEnd={5}>
          <Input
            type='text'
            name='nickname'
            id='nickname'
            data-testid='accept_invite-nicknameField'
            value={values.nickname}
            required
            label={t('accept_invite.nickname')}
            onChange={handleChange}
            error={!!errors.nickname}
            hint={errors.nickname && t(errors.nickname)}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            type='text'
            name='firstName'
            id='firstName'
            data-testid='accept_invite-firstNameField'
            value={values.firstName}
            label={t('accept_invite.firstname')}
            onChange={handleChange}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            type='text'
            name='lastName'
            id='lastName'
            data-testid='accept_invite-lastNameField'
            value={values.lastName}
            label={t('accept_invite.lastname')}
            onChange={handleChange}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            type='email'
            name='email'
            id='email'
            data-testid='accept_invite-emailField'
            value={values.email}
            required
            disabled
            label={t('accept_invite.email')}
            onChange={handleChange}
            error={!!errors.email}
            hint={errors.email && t(errors.email)}
          />
        </Box>
        <Box marginBlockEnd={5}>
          <Input
            type='password'
            name='password'
            id='password'
            data-testid='accept_invite-passwordField'
            value={values.password}
            required
            label={t('accept_invite.password')}
            onChange={handleChange}
            error={!!errors.password}
          />
          <PasswordStrength password={values.password} />
        </Box>
        {hasEmailConsent && (
          <Box marginBlockEnd={5}>
            <Checkbox
              name='emailConsent'
              data-testid='accept_invite-emailConsent'
              onChange={handleChange}
            >
              <Trans ns='forms' i18nKey='singup.gdpr'>
                {{ churchName: values.organization.name }} can send me occasional emails.
              </Trans>
            </Checkbox>
          </Box>
        )}
        {(termsUrl || privacyUrl) && (
          <Checkbox
            name='acceptTermsAndPrivacy'
            onChange={handleChange}
            data-testid='privacyTermsCheckBox'
            error={!!errors.acceptTermsAndPrivacy}
            hint={errors.acceptTermsAndPrivacy && t(errors.acceptTermsAndPrivacy)}
          >
            <Trans i18nKey='accept_invite.tos_and_pp'>
              I agree to the{' '}
              {termsUrl && (
                <InlineLink href={termsUrl} target='_blank' rel='noopener noreferrer'>
                  Terms of Service
                </InlineLink>
              )}
              {termsUrl && privacyUrl && ' and '}
              {privacyUrl && (
                <InlineLink href={privacyUrl} target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </InlineLink>
              )}
              .*
            </Trans>
          </Checkbox>
        )}
        <Flex alignItems='center' justifyContent='space-between' marginBlockStart={6}>
          <StyledOParagraph>* {t('accept_invite.required')}</StyledOParagraph>
          <PrimaryButton type='submit' data-testid='acceptInvite-signUp'>
            {t('accept_invite.sign_up')}
          </PrimaryButton>
        </Flex>
      </form>
    </Modal>
  );
};

export default AcceptInvite;
