import { Dispatch, useEffect, useState } from 'react';

interface PasswordOutput {
  id: number | null;
  strength: string | null;
  contains: Array<string>;
  length: number | null;
  updatePassword?: Dispatch<React.SetStateAction<string>>;
}

const checkPasswordStrength = (password: string): PasswordOutput => {
  let output: PasswordOutput = {
    id: null,
    strength: null,
    length: null,
    contains: [],
  };

  if (!password) {
    return output;
  }

  const lowerCaseRegex = '(?=.*[a-z])';
  const upperCaseRegex = '(?=.*[A-Z])';
  const symbolsRegex = '(?=.*[!@#$%^&*])';
  const numericRegex = '(?=.*[0-9])';

  let passwordContains: Array<string> = [];

  if (new RegExp(`^${lowerCaseRegex}`).test(password)) {
    passwordContains = [...passwordContains, 'lowercase'];
  }

  if (new RegExp(`^${upperCaseRegex}`).test(password)) {
    passwordContains = [...passwordContains, 'uppercase'];
  }

  if (new RegExp(`^${symbolsRegex}`).test(password)) {
    passwordContains = [...passwordContains, 'symbol'];
  }

  if (new RegExp(`^${numericRegex}`).test(password)) {
    passwordContains = [...passwordContains, 'number'];
  }

  output.length = password.length;
  output.contains = passwordContains;

  const strongRegex = new RegExp(
    `^${lowerCaseRegex}${upperCaseRegex}${numericRegex}${symbolsRegex}(?=.{8,})`
  );
  const mediumRegex = new RegExp(
    `^((${lowerCaseRegex}${upperCaseRegex})|(${lowerCaseRegex}${numericRegex})|(${upperCaseRegex}${numericRegex})|(${upperCaseRegex}${symbolsRegex})|(${lowerCaseRegex}${symbolsRegex})|(${numericRegex}${symbolsRegex}))(?=.{6,})`
  );

  if (strongRegex.test(password)) {
    return (output = {
      ...output,
      id: 2,
      strength: 'Strong',
    });
  } else if (mediumRegex.test(password)) {
    return (output = {
      ...output,
      id: 1,
      strength: 'Medium',
    });
  } else {
    return (output = {
      ...output,
      id: 0,
      strength: 'Weak',
    });
  }
};

export const usePasswordStrength = (
  input: string = ''
): [PasswordOutput, Dispatch<React.SetStateAction<string>>] => {
  const [password, updatePassword] = useState(input);
  const [passwordStrengthOutput, setPasswordStrengthOutput] = useState<
    PasswordOutput
  >({
    id: null,
    strength: null,
    length: null,
    contains: [],
  });

  useEffect(() => {
    const passwordStrength: PasswordOutput = checkPasswordStrength(password);
    setPasswordStrengthOutput(passwordStrength);
  }, [password]);

  return [passwordStrengthOutput, updatePassword];
};
