import React, { ForwardRefRenderFunction, MouseEvent } from 'react';
import NavbarPip from './navbarPip';
import DirectChatIcon, { emptyNickname } from './directChatIcon';
import Actionable from '@components/Actionable';
import { NavbarItemWrapper, TypingIndicatorIconContainer } from './styles';
import { NavbarItem as INavbarItem } from './selectors';
import TypingIndicatorIcon from '@components/icons/tpyingIndicator';
import { PaneType } from '../pane/dux';

export interface Props {
  index: number;
  item: INavbarItem;
  mediumGuest: boolean;
  handleItemClick: (event: MouseEvent<HTMLButtonElement>, item: INavbarItem) => void;
  children: string;
}

const NavbarItem: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { item, children, handleItemClick, index },
  ref
) => {
  const { hasActions, hasNewMessages, hasSubscribersTyping, otherSubscribersNickname, isCurrent } =
    item;
  const isDirect = item.type === PaneType.CHAT;
  const isPulsing = hasNewMessages && !isCurrent;
  const showTyping = hasSubscribersTyping && !isCurrent;
  // CHOP-3149: Only showPip for host chat
  const showPip = (hasActions || hasNewMessages) && item.type === PaneType.HOST_CHAT;
  const numberOfSubscribers =
    otherSubscribersNickname && otherSubscribersNickname.length > 1
      ? otherSubscribersNickname.length
      : undefined;

  return (
    <>
      {/*
      // @ts-expect-error */}
      <Actionable onClick={handleItemClick} role='tab'>
        <NavbarItemWrapper
          data-testid={'nav-' + children.replace(/ /g, '')}
          data-index={index}
          data-direct={isDirect}
          isCurrent={isCurrent}
          isDirect={isDirect}
        >
          {showPip && <NavbarPip ref={ref} hasActions={hasActions} />}
          {isDirect ? (
            <DirectChatIcon
              isCurrent={isCurrent}
              nickname={numberOfSubscribers?.toString() || children || emptyNickname}
              groupChat={!!numberOfSubscribers}
              pulsing={isPulsing}
            />
          ) : (
            children
          )}
          {showTyping && (
            <TypingIndicatorIconContainer>
              <TypingIndicatorIcon />
            </TypingIndicatorIconContainer>
          )}
        </NavbarItemWrapper>
      </Actionable>
    </>
  );
};

export default React.forwardRef(NavbarItem);
