import React, { FC } from 'react';
import { useAppDispatch } from '@hooks/redux/hooks';
import {
  LinkButton,
  LinkButtonType,
  Modal,
  ModalButtons,
  ModalSize,
  ModalText,
  OParagraph,
} from '@lifechurch/react-ion';
import { DeleteMomentActionArgs } from '@store/feedSlice';
import { clearModal } from '@store/uiSlice';
import { useTranslation } from 'react-i18next';
import { deleteMomentRequest } from './dux';

type Props = { moment: DeleteMomentActionArgs };

const DeleteMoment: FC<Props> = ({ moment }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('forms');

  const closeModal = () => dispatch(clearModal());

  const handleDeleteMoment = () => {
    closeModal();
    dispatch(deleteMomentRequest(moment));
  };

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='deleteMoment-modal'>
      <ModalText>
        <OParagraph marginBlockEnd={5} data-testid='deleteMoment-confirmText'>
          {t('delete_moment.confirm_message')}
        </OParagraph>
      </ModalText>
      <ModalButtons>
        <LinkButton
          buttonType={LinkButtonType.SECONDARY}
          onClick={closeModal}
          data-testid='deleteMoment-cancel'
          marginInlineEnd={5}
        >
          {t('delete_moment.cancel')}
        </LinkButton>
        <LinkButton
          buttonType={LinkButtonType.ERROR}
          onClick={handleDeleteMoment}
          data-testid='deleteMoment-deleteButton'
        >
          {t('delete_moment.delete')}
        </LinkButton>
      </ModalButtons>
    </Modal>
  );
};

export default DeleteMoment;
