import styled from 'styled-components';

const Container = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  height: 100%;
  box-sizing: border-box;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 8px;
`;

const CellContainer = styled.div`
  height: calc(100% - 8px);
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  position: relative;
`;

const CellContainerTop = styled(CellContainer)`
  margin-block-start: 8px;
  background-color: transparent;
`;

const CellContainerBottom = styled(CellContainer)`
  margin-block-end: 8px;
`;

export { Container, CellContainer, CellContainerTop, CellContainerBottom };
