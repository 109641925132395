import { ModalType } from '../dux';
import { ImageMoment as ImageMomentInterface } from '@features/feed/objects/moment/dux';

interface ImageMomentMeta {
  moment: ImageMomentInterface;
}

export interface ImageMoment {
  type: ModalType.IMAGE_MOMENT;
  meta: ImageMomentMeta;
}

export const imageMomentFocusView = ({ meta }: { meta: ImageMomentMeta }): ImageMoment => ({
  type: ModalType.IMAGE_MOMENT,
  meta,
});
