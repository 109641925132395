import React, { FunctionComponent } from 'react';
import SideMenu from '@features/sideMenu';
import Pane from '@features/pane';
import styled from 'styled-components';
import NavMenu from '@features/navMenu';
import Service from '@features/pane/types/service';
import Chat from '@features/pane/types/chat';
import { Container, CellContainer } from '../large/styles';
import { Grid, Cell } from 'styled-css-grid';
import { PaneWrapper, PaneContentWrapper } from '@features/pane/styles';
import { Props } from './index';
import ServiceWindow from '../../ServiceWindow';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

// For some reason the height: 100% doesn't take the height: calc() into account
const GridCell = styled(Cell)`
  height: initial;
`;

const Xlarge: FunctionComponent<Props> = ({ hostChannel, largeHostVideo }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.SIDE_MENU}
        altComponentName={t('side_menu')}
      >
        <SideMenu />
      </ErrorBoundary>
      <Grid
        columns={'auto 1fr 1fr 1fr'}
        rows={'auto 1fr'}
        areas={
          largeHostVideo
            ? ['menu video video public', 'menu chat host public']
            : ['menu video host public', 'menu chat host public']
        }
        height='100%'
        columnGap='0'
      >
        <GridCell area='menu'>
          <ErrorBoundary>
            <NavMenu />
          </ErrorBoundary>
        </GridCell>

        <GridCell area='video'>
          <CellContainer topCell>
            <ServiceWindow />
          </CellContainer>
        </GridCell>

        <GridCell area='chat'>
          <CellContainer bottomCell>
            <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE}>
              <Pane />
            </ErrorBoundary>
          </CellContainer>
        </GridCell>
        <GridCell area='host'>
          <CellContainer
            staticCell={largeHostVideo ? false : true}
            bottomCell={largeHostVideo ? true : false}
          >
            <PaneWrapper>
              <PaneContentWrapper>
                <ErrorBoundary
                  displayType={ErrorBoundaryDisplayType.PANE}
                  altComponentName={t('chat')}
                >
                  <Chat channelKey={hostChannel} isGuest={false} paneId={2} />
                </ErrorBoundary>
              </PaneContentWrapper>
            </PaneWrapper>
          </CellContainer>
        </GridCell>
        <GridCell area='public'>
          <CellContainer id='publicchat' staticCell>
            <PaneWrapper>
              <PaneContentWrapper>
                <Service isGuest={false} paneId={3} />
              </PaneContentWrapper>
            </PaneWrapper>
          </CellContainer>
        </GridCell>
      </Grid>
    </Container>
  );
};

export default React.memo(Xlarge);
