import React, { FC } from 'react';
import { OXSmallParagraph } from '@lifechurch/react-ion';
import { useTranslation } from 'react-i18next';
import RoleIdentifierIcon from '@components/icons/roleIdentifier';
import { RoleLabel } from './styles';
import { AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node_roleIdentifier } from '@io/__generated__/AudienceTab';
import { RoleIdentifierType } from '../../../../../__generated__/globalTypes';

interface Props {
  roleIdentifier:
    | AudienceTab_currentService_feed_PublicChannel_subscribers_edges_node_roleIdentifier
    | null
    | undefined;
  connected: boolean;
}

const Role: FC<Props> = ({ roleIdentifier, connected }) => {
  const { t } = useTranslation(['common', 'roleIdentifiers']);

  if (roleIdentifier?.type === RoleIdentifierType.SYMBOL) {
    return <RoleIdentifierIcon />;
  }

  if (roleIdentifier?.type === RoleIdentifierType.TEXT && roleIdentifier?.key) {
    return (
      <RoleLabel data-testid='senderName-label' connected={connected}>
        <OXSmallParagraph>
          {t(roleIdentifier.key, roleIdentifier.label || undefined)}
        </OXSmallParagraph>
      </RoleLabel>
    );
  }

  return null;
};

export default Role;
