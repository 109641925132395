import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, Wrapper } from '../styles';
import { BasePayload } from '../index';

const Title = styled.div`
  color: ${props => props.theme.colors.gray100};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.gray50};
  font-size: 13.44px;
  padding-inline-start: 4px;
`;

const ChatHeader: FunctionComponent<BasePayload> = ({ title, subtitle }) => (
  <Wrapper data-testid='chatHeader' height={HEADER_HEIGHT}>
    <Title data-testid='chatHeader-title'>{title}</Title>
    {subtitle && <Subtitle data-testid='chatHeader-subtitle'>{subtitle}</Subtitle>}
  </Wrapper>
);

export default ChatHeader;
