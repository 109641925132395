import { useEffect, useRef } from 'react';

interface ReturnType {
  stop: () => void;
}

export function useTimeout(
  callback: () => void,
  delay: number | null
): ReturnType {
  const savedCallback = useRef<typeof callback>();
  const refTimer = useRef<number>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const timerId = window.setTimeout(savedCallback.current!, delay);
      refTimer.current = timerId;

      return () => window.clearTimeout(timerId);
    }
    return;
  }, [delay]);

  function stop(): void {
    return window.clearTimeout(refTimer.current);
  }

  return {
    stop,
  };
}
