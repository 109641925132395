import styled from 'styled-components';
import {
  LargeUpQuery,
  MediumUpQuery,
  SmallQuery,
  MobileHeightWithKeyboardOpen,
} from '@utils/responsive';

interface ButtonProps {
  inChat: boolean;
  large?: boolean;
  isLive?: boolean;
}

const Text = styled.div`
  font-weight: 600;
  padding-inline-start: 4px;
  line-height: 1.25;
  user-select: none;
`;

const Icon = styled.span<ButtonProps>`
  line-height: 0;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${props => {
        if (props.isLive && !props.inChat) {
          return props.theme.colors.white;
        } else {
          return props.theme.colors.action.default;
        }
      }};
    }
  }
`;

const Button = styled.button<ButtonProps>`
  color: ${props => {
    if (props.isLive && !props.inChat) {
      return props.theme.colors.white;
    } else {
      return props.theme.colors.action.default;
    }
  }};
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: ${props => {
    const {
      theme: { colors },
      isLive,
      inChat,
    } = props;
    return isLive && !inChat ? colors.gray130 : colors.white;
  }};
  border: none;
  border-radius: 20px;
  outline: none;
  height: 40px;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 16px;
  padding-inline-end: 18px;
  white-space: nowrap;
  margin: 0;
  z-index: 10;

  @media ${SmallQuery} {
    font-size: 13.44px;
    height: 32px;
    border-radius: 16px;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 12px;
    padding-inline-end: 14px;
  }

  @media ${MediumUpQuery} {
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 16px;
    padding-inline-end: 18px;
  }

  @media ${LargeUpQuery} {
    height: 40px;
  }

  @media ${MobileHeightWithKeyboardOpen} {
    display: none;
  }

  &:focus,
  &:hover {
    border: none;
    background-color: ${props =>
      props.inChat || !props.isLive ? props.theme.colors.action.hover : props.theme.colors.white};

    div {
      color: ${props =>
        props.inChat || !props.isLive
          ? props.theme.colors.white
          : props.theme.colors.action.default};
    }

    svg {
      path {
        fill: ${props =>
          props.inChat || !props.isLive
            ? props.theme.colors.white
            : props.theme.colors.action.default};
      }
    }
  }
`;

export { Button, Icon, Text };
