import React, { FunctionComponent, useEffect } from 'react';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { MediumUp } from '@utils/responsive';
import dayjs from 'dayjs';
import { VideoType } from '../../../__generated__/globalTypes';
import MediaHeader from '../media/mediaHeader';
import { Props } from './index';
import NoVideo from './NoVideo';
import IframeEmbedPlayer from './players/iframeEmbedPlayer';
import OfflinePlayer from './players/offlinePlayer';
import SimulatedLivePlayer from './players/simulatedLivePlayer';
import { Container, VideoInnerWrapper, VideoWrapper } from './styles';
import { addFullscreenEventListener, removeFullscreenEventListener } from './video.utils';
import Cookie from 'js-cookie';
import PreviewService from '@components/PreviewService/PreviewService';
import { XlargeUpQuery } from '@utils/responsive';
import { useMediaQuery } from 'react-responsive';

const Video: FunctionComponent<Props> = ({
  type,
  url,
  playVideo,
  pauseVideo,
  videoStartTime,
  isVideoPlaying,
  showLiveVideo,
  showOfflineVideo,
  isOffline,
  nextService,
  offlineContent,
  sendFullscreenEvent,
  largeHostVideo,
}) => {
  const isXLargeUp = useMediaQuery({ query: XlargeUpQuery });
  useEffect(() => {
    addFullscreenEventListener(sendFullscreenEvent);
    return () => {
      removeFullscreenEventListener();
    };
  }, []);

  const isDuringService =
    dayjs().isBefore(nextService?.endTime) && dayjs().isAfter(nextService?.scheduleTime);
  const showVideo = showLiveVideo || showOfflineVideo;
  const autoplay = !!(
    offlineContent?.__typename === 'OfflineVideo' && offlineContent.options.autoplay
  );
  const loop = !!(offlineContent?.__typename === 'OfflineVideo' && offlineContent.options.loop);
  const isPreviewService = Cookie.get('preview_service') ? true : false;

  // OFFLINE - with no offline url
  if (isOffline && !url) return null;
  // ONLINE - before video start with no url
  if (!isOffline && videoStartTime && dayjs().isBefore(videoStartTime) && !url) return null;

  const renderLiveVideo = (type: VideoType) => {
    switch (type) {
      case VideoType.SIMULATED:
        return (
          <SimulatedLivePlayer
            url={url}
            videoStartTime={videoStartTime}
            onPlay={playVideo}
            onPause={pauseVideo}
            isVideoPlaying={isVideoPlaying}
          />
        );
      case VideoType.LIVE:
        return <IframeEmbedPlayer url={url} />;
      case VideoType.NONE:
        return <NoVideo />;
    }
  };

  // OFFLINE, ONLINE - with url
  return (
    <ErrorBoundary displayType={ErrorBoundaryDisplayType.VIDEO}>
      <Container>
        <MediumUp>{!isOffline && <MediaHeader />}</MediumUp>
        {isPreviewService && <PreviewService />}
        <VideoWrapper data-testid='video' isDuringService={isDuringService}>
          <VideoInnerWrapper largeHostVideo={largeHostVideo} isXLargeUp={isXLargeUp}>
            {showVideo && (
              <>
                {showLiveVideo && renderLiveVideo(type)}
                {showOfflineVideo && <OfflinePlayer url={url} autoplay={autoplay} loop={loop} />}
              </>
            )}
          </VideoInnerWrapper>
        </VideoWrapper>
      </Container>
    </ErrorBoundary>
  );
};

export default React.memo<Props>(Video);
