import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const CycleBackwardIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M1 1V5.5V6H1.17393H1.5H2.20703H6V5H2.59971C3.51007 3.21916 5.36266 2 7.5 2C10.5376 2 13 4.46243 13 7.5C13 10.5376 10.5376 13 7.5 13C4.63098 13 2.27504 10.8033 2.02242 8H1.01894C1.27426 11.3562 4.07839 14 7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C5.18423 1 3.15132 2.21102 2 4.03431V1H1Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M5.52756 6C6.62685 4.77174 8.22385 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10H2C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C7.60963 2 5.46524 3.0484 4 4.70861V2H2V7V8H3H8V6H5.52756Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
