import React, { FunctionComponent } from 'react';
import { GivingMoment as BaseGivingMoment } from '@churchonline/moments';
import {
  MomentActionType,
  MomentType,
  WindowType,
} from '../../../../../../__generated__/globalTypes';
import { iframeModal } from '@components/modal/iframe/dux';
import { Props } from './index';
import { useTranslation } from 'react-i18next';

const GivingMoment: FunctionComponent<Props> = ({
  moment,
  onDismiss,
  isPinned,
  setModal,
  submitMomentAction,
}) => {
  const { t } = useTranslation('moments');
  const handleClick = () => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.GIVING,
      momentActionType: MomentActionType.SUBMIT,
    });
    if (moment.momentTemplate.options.windowType === WindowType.NEW_TAB) {
      window.open(moment.momentTemplate.options.src, '_blank');
    } else {
      setModal(iframeModal(moment.momentTemplate.options.src));
    }
    onDismiss();
  };

  return (
    <BaseGivingMoment
      text={moment.momentTemplate.options.text}
      buttonText={t('giving.button')}
      onClick={handleClick}
      onDismiss={onDismiss}
      isPinned={isPinned}
    />
  );
};

export default GivingMoment;
