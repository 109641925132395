import VisibleImageMoment from './imageMoment';
import { connect } from 'react-redux';
import { ImageMoment as ImageMomentInterface } from '../dux';
import { submitMomentAction } from '@store/feedSlice';
import { setModal, clearModal } from '@store/uiSlice';
import { RootState } from '@store/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { getCurrentService } from '@store/serviceSlice/selectors';

interface StateProps {
  serviceTitle: string;
}
interface OwnProps {
  moment: ImageMomentInterface;
  hasLiked?: boolean;
  isPinned: boolean;
  onDismiss: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => ({
  serviceTitle: getCurrentService(state).content.title,
  ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ submitMomentAction, setModal, clearModal }, dispatch);

export type Props = OwnProps & ReturnType<typeof mapDispatchToProps> & StateProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisibleImageMoment);
