const avatarColors = [
    '41, 147, 229',
    '9, 193, 161',
    '24, 195, 95',
    '76, 178, 57',
    '140, 200, 30',
    '197, 210, 26',
    '245, 208, 10',
    '255, 186, 12',
    '255, 144, 0',
    '255, 98, 26',
    '227, 51, 0',
    '232, 54, 101',
    '230, 61, 213',
    '165, 61, 235',
    '118, 74, 255',
];
const getFirstInitial = (name) => name.charAt(0).toUpperCase();
const djb2Hash = (str) => {
    let hash = 5381;
    const charList = Array.from(str);
    charList.forEach(char => {
        const charCode = char.charCodeAt(0);
        hash = (hash << 5) + hash + charCode;
    });
    return hash;
};
const getAvatarColor = (nickname, opacity, pending) => {
    const op = opacity === undefined ? '1.0' : opacity;
    if (!pending) {
        const numberDigest = djb2Hash(nickname);
        const numberOfColors = avatarColors.length;
        const index = Math.abs(numberDigest % numberOfColors);
        const color = avatarColors[index];
        return `rgba(${color}, ${op})`;
    }
    else {
        return `rgba(64, 64, 65, ${op})`;
    }
};
export { getFirstInitial, getAvatarColor };
