import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: 24px;
  padding-block-end: 16px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  border-radius: 4px 4px 0px 0px;
`;

export const ScreenReaderText = styled.span`
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
`;
