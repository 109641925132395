/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CurrentState_currentService_feed,
  CurrentState_currentSubscriber,
  CurrentState_currentSubscriber_role,
  CurrentState_currentSubscriber_roleIdentifier,
} from '@io/__generated__/CurrentState';
import { HubSpotEvent } from '../lib/ReactHubSpot';

export type Role = CurrentState_currentSubscriber_role;
export type RoleIdentifier = CurrentState_currentSubscriber_roleIdentifier;

export type Subscriber = Merge<
  CurrentState_currentSubscriber,
  { role?: Merge<Role, { permissions: string[] }> }
>;

export type PublicSubscriber = Required<Pick<CurrentState_currentSubscriber, 'id' | 'nickname'>> & {
  roleIdentifier?: RoleIdentifier | null;
  avatar?: string | null;
};

export type Channel = CurrentState_currentService_feed;
export type PublicChannel = Omit<Channel, 'subscribers'>;

export enum FieldErrorType {
  BLANK = 'BLANK',
  INVALID = 'INVALID',
  DUPLICATE = 'DUPLICATE',
}

// Action Types
export interface Action<Type extends string = string, Payload = undefined, Meta = undefined> {
  type: Type;
  payload?: Payload;
  meta?: Meta;
}

export interface ActionWithError<
  Type extends string = string,
  CustomError extends Error = Error,
  Meta = undefined
> extends Action<Type, CustomError, Meta> {
  error: CustomError;
}

// @ts-expect-error - Remove once everything is RTK
export interface ActionWithPayload<Type extends string = string, Payload, Meta = undefined>
  extends Action<Type, Payload, Meta> {
  payload: Payload;
}

export interface ActionWithPayloadAndMeta<
  Type extends string = string,
  Payload = undefined,
  Meta = undefined
> extends Action<Type, Payload, Meta> {
  payload: Payload;
  meta: Meta;
}

export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

interface NewRelic {
  setCustomAttribute: (name: string, value: string | number) => void;
}

declare global {
  interface Window {
    PAGE_LOAD_TIME: Date;
    _hsq: Array<HubSpotEvent>;
    ga: unknown;
    Osano?: {
      cm: {
        mode: 'debug' | 'permissive' | 'production';
        showDrawer(value: string): void;
      };
    };
    newrelic?: NewRelic;
    analytics: {
      identify(args: Record<string, unknown>): void;
    };
  }
}

// https://github.com/zsajjad/react-facebook-pixel/pull/47 - merged, but not published
declare module 'react-facebook-pixel' {
  export function revokeConsent(): void;
  export function grantConsent(): void;
  export function fbq(...args: Array<unknown>): void;
}
/* eslint-enable @typescript-eslint/no-unused-vars */

export enum AuthProvider {
  BASIC_AUTH = 'BasicAuth',
  FACEBOOK_AUTH = 'FacebookAuth',
  LEGACY_SSO_AUTH = 'LegacySsoAuth',
  OPEN_ID_CONNECT_AUTH = 'OpenIdConnectAuth',
}
