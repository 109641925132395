import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const SlashCircleIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM7.85355 7.85355L10.8536 4.85355L10.1464 4.14645L7.14645 7.14645L4.14645 10.1464L4.85355 10.8536L7.85355 7.85355Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.7071 10.7071L14.7071 6.70711L13.2929 5.29289L9.29289 9.29289L5.29289 13.2929L6.70711 14.7071L10.7071 10.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.7071 12.7071L17.7071 7.70711L16.2929 6.29289L11.2929 11.2929L6.29289 16.2929L7.70711 17.7071L12.7071 12.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM16.7071 16.7071L23.2071 10.2071L21.7929 8.79289L15.2929 15.2929L8.79289 21.7929L10.2071 23.2071L16.7071 16.7071Z'
          size={size}
          {...rest}
        />
      );
  }
};
