import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const MicrophoneIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.5 1C6.11929 1 5 2.11929 5 3.5V7.5C5 8.70948 5.85888 9.71836 7 9.94999V10.9646C5.30385 10.7219 4 9.26324 4 7.5V7H3V7.5C3 9.81628 4.75002 11.7238 7 11.9725V13H5V14H7H8H10V13H8V11.9725C10.25 11.7238 12 9.81628 12 7.5V7H11V7.5C11 9.26324 9.69615 10.7219 8 10.9646V9.94999C9.14112 9.71836 10 8.70948 10 7.5V3.5C10 2.11929 8.88071 1 7.5 1Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
