import {
  requestLivePrayer as requestLivePrayerAction,
  submitMessageAction,
  submitMomentAction,
} from '@store/feedSlice';
import { call, fork, select } from '@redux-saga/core/effects';
import {
  GAEventCategory,
  initGoogleAnalytics,
  sendGoogleAnalyticsEvent,
  setGoogleAnalyticsUser,
} from './googleAnalytics';
import { initFacebookPixel, setFacebookPixelUser } from './facebookPixel';
import { loginSuccess, setSubscriber } from '@store/subscriberSlice';
import {
  CurrentState_currentOrganization_integrations,
  CurrentState_currentOrganization_integrations_GoogleAnalytics,
  CurrentState_currentOrganization_integrations_GoogleAnalytics4,
} from '@io/__generated__/CurrentState';
import { getIntegrations, getIntegrationTypes } from '@store/organizationSlice/selectors';
import { IntegrationType } from '../../../../__generated__/globalTypes';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { Subscriber } from '../../../types';
import { initHubSpot, setHubSpotUser } from './hubSpot';
import { heartbeat } from '@store/uiSlice';
import { LiveObjectType, publishLiveObject } from '../../../pubnub/liveObject';
import ReactHubSpot from '../../../lib/ReactHubSpot';
import ReactPixel from 'react-facebook-pixel';
import { signUpSuccess } from '@components/modal/signup/dux';
import { capitalize } from '@utils/core';
import { setSegmentUser } from '@io/sagas/integrations/segment';
import {
  GA4EventCategory,
  initGoogleAnalytics4,
  sendGoogleAnalytics4Event,
  setGoogleAnalytics4User,
} from './googleAnalytics4';

type Actions =
  | ReturnType<typeof requestLivePrayerAction>
  | ReturnType<typeof publishLiveObject>
  | ReturnType<typeof signUpSuccess>
  | ReturnType<typeof loginSuccess>;

export function* initIntegrations() {
  const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
  const integrations: Array<CurrentState_currentOrganization_integrations> = yield select(
    getIntegrations
  );
  yield call(
    initGoogleAnalytics,
    currentSubscriber,
    integrations.find(
      integration => integration.__typename === 'GoogleAnalytics'
    ) as CurrentState_currentOrganization_integrations_GoogleAnalytics
  );

  yield call(
    initGoogleAnalytics4,
    currentSubscriber,
    integrations.find(
      integration => integration.__typename === 'GoogleAnalytics4'
    ) as CurrentState_currentOrganization_integrations_GoogleAnalytics4
  );

  for (const integration of integrations) {
    switch (integration.__typename) {
      case 'FacebookPixel': {
        yield call(initFacebookPixel, currentSubscriber, integration);
        break;
      }
      case 'GoogleAnalytics': {
        break; // we handle this manually
      }
      case 'HubSpot': {
        yield call(initHubSpot, integration);
        break;
      }
    }
  }
}

export function* sendIntegrationsEvent(action: Actions) {
  const uniqueIntegrations: Array<IntegrationType> = yield select(getIntegrationTypes);

  if (submitMomentAction.match(action)) {
    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.MOMENTS,
      action: action.payload.momentActionType.toLowerCase(),
      label: action.payload.momentType.toLowerCase(),
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.MOMENTS,
      action: action.payload.momentActionType.toLowerCase(),
      label: action.payload.momentType.toLowerCase(),
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'MomentInteraction', {
            type: capitalize(action.payload.momentType.toLowerCase()),
            action: capitalize(action.payload.momentActionType.toLowerCase()),
          });
          break;
        case IntegrationType.HUBSPOT:
          yield call(
            ReactHubSpot.trackEvent,
            `${capitalize(action.payload.momentType.toLowerCase())}Moment${capitalize(
              action.payload.momentActionType.toLowerCase()
            )}`
          );
          break;
      }
    }
  }

  if (submitMessageAction.match(action)) {
    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.CHAT_MESSAGE,
      action: action.payload.messageActionType.toLowerCase(),
      label: action.payload.messageActionType.toLowerCase(),
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.CHAT_MESSAGE,
      action: action.payload.messageActionType.toLowerCase(),
      label: action.payload.messageActionType.toLowerCase(),
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'MessageInteraction', {
            type: capitalize(action.payload.messageActionType.toLowerCase()),
            action: capitalize(action.payload.messageActionType.toLowerCase()),
          });
          break;
        case IntegrationType.HUBSPOT:
          yield call(
            ReactHubSpot.trackEvent,
            `${capitalize(action.payload.messageActionType.toLowerCase())}Message${capitalize(
              action.payload.messageActionType.toLowerCase()
            )}`
          );
          break;
      }
    }
  }

  if (heartbeat.match(action)) {
    yield call(
      sendGoogleAnalyticsEvent,
      {
        category: GAEventCategory.USERS,
        action: 'heartbeat',
        label: action.payload.toString(),
      },
      ['platform_sampled']
    );

    yield call(
      sendGoogleAnalytics4Event,
      {
        category: GA4EventCategory.USERS,
        action: 'heartbeat',
        label: action.payload.toString(),
      },
      ['platform_sampled']
    );

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.GOOGLE_ANALYTICS:
          yield call(
            sendGoogleAnalyticsEvent,
            {
              category: GAEventCategory.USERS,
              action: 'heartbeat',
              label: action.payload.toString(),
            },
            ['organization']
          );
          break;
        case IntegrationType.GOOGLE_ANALYTICS_4:
          yield call(
            sendGoogleAnalytics4Event,
            {
              category: GA4EventCategory.USERS,
              action: 'heartbeat',
              label: action.payload.toString(),
            },
            ['organization']
          );
          break;
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'Heartbeat', { interval: action.payload });
          break;
        case IntegrationType.HUBSPOT:
          yield call(ReactHubSpot.trackEvent, `Heartbeat${action.payload}`);
          break;
      }
    }
  }

  if (requestLivePrayerAction.match(action)) {
    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.PRAYER,
      action: 'requested',
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.PRAYER,
      action: 'requested',
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'LivePrayerRequested', {});
          break;
        case IntegrationType.HUBSPOT:
          yield call(ReactHubSpot.trackEvent, 'LivePrayerRequested');
          break;
      }
    }
  }

  if (loginSuccess.match(action)) {
    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.USERS,
      action: 'login',
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.USERS,
      action: 'login',
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'Login');
          break;
        case IntegrationType.HUBSPOT:
          yield call(ReactHubSpot.trackEvent, 'Login');
          break;
      }
    }
  }

  if (signUpSuccess.match(action)) {
    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.USERS,
      action: 'registration',
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.USERS,
      action: 'registration',
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.fbq, 'track', 'CompleteRegistration');
          break;
        case IntegrationType.HUBSPOT:
          yield call(ReactHubSpot.trackEvent, `UserRegistration`);
          break;
      }
    }
  }

  if (publishLiveObject.match(action)) {
    if (action.payload.type !== LiveObjectType.MESSAGE) return;

    yield call(sendGoogleAnalyticsEvent, {
      category: GAEventCategory.CHAT_MESSAGE,
      action: 'published',
      label: action.payload.data.channel.type,
    });

    yield call(sendGoogleAnalytics4Event, {
      category: GA4EventCategory.CHAT_MESSAGE,
      action: 'published',
      label: action.payload.data.channel.type,
    });

    for (const type of uniqueIntegrations) {
      switch (type) {
        case IntegrationType.FACEBOOK_PIXEL:
          yield call(ReactPixel.trackCustom, 'ChatMessagePublished', {
            type: action.payload.data.channel.type,
          });
          break;
        case IntegrationType.HUBSPOT:
          yield call(
            ReactHubSpot.trackEvent,
            `${capitalize(action.payload.data.channel.type)}ChatMessagePublished`
          );
          break;
      }
    }
  }
}

export function* setIntegrationsUser(action: ReturnType<typeof setSubscriber>) {
  const uniqueIntegrations: Array<IntegrationType> = yield select(getIntegrationTypes);

  yield call(setGoogleAnalyticsUser, action);
  yield call(setGoogleAnalytics4User, action);

  for (const type of uniqueIntegrations) {
    switch (type) {
      case IntegrationType.FACEBOOK_PIXEL: {
        yield fork(setFacebookPixelUser, action);
        break;
      }
      case IntegrationType.HUBSPOT: {
        yield call(setHubSpotUser, action);
        break;
      }
      case IntegrationType.SEGMENT: {
        yield call(setSegmentUser, action);
        break;
      }
    }
  }
}

export * from './facebookPixel';
export * from './googleAnalytics';
export * from './googleAnalytics4';
export * from './hubSpot';
