import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleDownIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.49996 9.04293L11.6464 4.89648L12.3535 5.60359L7.49996 10.4571L2.64641 5.60359L3.35352 4.89648L7.49996 9.04293Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16.707 7.70718L9.99992 14.4143L3.29282 7.70718L4.70703 6.29297L9.99992 11.5859L15.2928 6.29297L16.707 7.70718Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20.707 8.70718L11.9999 17.4143L3.29282 8.70718L4.70703 7.29297L11.9999 14.5859L19.2928 7.29297L20.707 8.70718Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M28.207 11.2072L16.4999 22.9143L4.79282 11.2072L6.20703 9.79297L16.4999 20.0859L26.7928 9.79297L28.207 11.2072Z'
          size={size}
          {...rest}
        />
      );
  }
};
