import React, { ForwardRefRenderFunction } from 'react';
import { PipWrapper } from './styles';
import Pip from '@components/Pip';

interface Props {
  hasActions?: boolean;
}

const NavbarPip: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { hasActions = false },
  ref
) => (
  <PipWrapper ref={ref}>
    <Pip hasActions={hasActions} />
  </PipWrapper>
);

export default React.forwardRef(NavbarPip);
