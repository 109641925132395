import React, { FunctionComponent } from 'react';
import { useTimezoneData } from './useTimezoneData';

export const TimezoneOptions: FunctionComponent = () => {
  const data = useTimezoneData();

  return (
    <>
      {Object.keys(data).map(key => (
        <optgroup label={key} key={key}>
          {data[key].map(tz => {
            const minutes = Math.abs(tz.utcOffset % 60);
            const hour = Math.floor(tz.utcOffset / 60);
            const utcOffset = minutes > 0 ? `${hour}:${minutes}` : `${hour}:00`;
            return (
              <option value={tz.value} key={tz.value}>
                {key.toLowerCase() !== 'etc'
                  ? `(GMT${hour < 0 ? utcOffset : '+' + utcOffset}) ${tz.display}`
                  : tz.display}
              </option>
            );
          })}
        </optgroup>
      ))}
    </>
  );
};

export { useTimezoneData };
