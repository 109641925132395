import React, { useState } from 'react';
import { ImageMoment } from '../../image';
import { Preview } from '../index';
import { Box } from '@lifechurch/react-ion';
import { ImagePreviewModal } from './ImagePreviewModal';
export const ImageMomentPreview = ({ altText, image, isPlaceholder, likeButtonLabel, onImageClick, shareButtonLabel, }) => {
    const [isPinned, setIsPinned] = useState(true);
    const [likeCount, setLikeCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (React.createElement(Preview, null,
        isModalOpen && (React.createElement(ImagePreviewModal, { dismiss: () => setIsModalOpen(false), image: image, isPlaceholder: isPlaceholder, open: isModalOpen, altText: altText })),
        React.createElement(Box, { p: isPinned ? 0 : 3, paddingBlockStart: 0, marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0 },
            React.createElement(ImageMoment, { altText: altText, image: image, isPinned: isPinned, isPlaceholder: isPlaceholder, likeButtonLabel: likeButtonLabel, likeCount: likeCount, onDismiss: () => setIsPinned(false), onImageClick: onImageClick, onLike: () => setLikeCount(count => count + 1), onShare: () => setIsModalOpen(!isModalOpen), shareButtonLabel: shareButtonLabel }))));
};
