import React from 'react';
import InitialAvatar from './initialAvatar';
import ImageAvatar from './imageAvatar';
import AvatarLoader from './avatarLoader';
import GuestAvatar from './guestAvatar';
import { AvatarWrapper } from './styles';
export var AvatarSizeType;
(function (AvatarSizeType) {
    AvatarSizeType[AvatarSizeType["XSMALL"] = 24] = "XSMALL";
    AvatarSizeType[AvatarSizeType["SMALL"] = 32] = "SMALL";
    AvatarSizeType[AvatarSizeType["MEDIUM"] = 40] = "MEDIUM";
    AvatarSizeType[AvatarSizeType["LARGE"] = 56] = "LARGE";
    AvatarSizeType[AvatarSizeType["XLARGE"] = 64] = "XLARGE";
    AvatarSizeType[AvatarSizeType["XXLARGE"] = 96] = "XXLARGE";
    AvatarSizeType[AvatarSizeType["XXXLARGE"] = 160] = "XXXLARGE";
})(AvatarSizeType || (AvatarSizeType = {}));
export const Avatar = ({ disabled = false, isActionable = false, loading = false, size = AvatarSizeType.MEDIUM, subscriber, tabIndex = -1, testId = 'avatar', }) => {
    if (subscriber.avatar && subscriber.avatar.indexOf('missing.png') === -1) {
        return (React.createElement(AvatarWrapper, null,
            loading && React.createElement(AvatarLoader, { size: size }),
            React.createElement(ImageAvatar, { "data-testid": testId, disabled: disabled, isActionable: isActionable, nickname: subscriber.nickname, size: size, tabIndex: tabIndex, url: subscriber.avatar })));
    }
    else if (subscriber.nickname && subscriber.id) {
        return (React.createElement(AvatarWrapper, null,
            loading && React.createElement(AvatarLoader, { size: size }),
            React.createElement(InitialAvatar, { "data-testid": testId, disabled: disabled, isActionable: isActionable, nickname: subscriber.nickname || '', subscriberId: subscriber.id, size: size, tabIndex: tabIndex })));
    }
    else {
        return (React.createElement(AvatarWrapper, null,
            loading && React.createElement(AvatarLoader, { size: size }),
            React.createElement(GuestAvatar, { isActionable: isActionable, size: size, tabIndex: tabIndex })));
    }
};
