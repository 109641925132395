import React from 'react';
import { Flex } from '@lifechurch/react-ion';
export const PinnedLikeCount = ({ count, hasLiked }) => {
    const digits = count.toString().length;
    const size = digits > 1 ? 8 * digits + 12 : 24;
    const backgroundColor = hasLiked ? 'gray5' : 'action.light';
    if (count <= 0)
        return null;
    return (React.createElement(Flex, { alignItems: 'center', justifyContent: 'center', borderRadius: '24px', backgroundColor: backgroundColor, color: hasLiked ? 'gray100' : undefined, minWidth: size, height: 24, fontWeight: 1, marginBlockStart: 2, marginBlockEnd: 2, marginInlineStart: 0, marginInlineEnd: 2, style: { transition: 'min-width 200ms ease-in-out' } }, count));
};
