import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PaneType } from '@features/pane/dux';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import Audience from '@features/audience';
import Service from '@features/pane/types/service';
import Chat from '@features/pane/types/chat';
import Tab from '@features/pane/types/tab';
import Error from '@features/pane/types/error';
import { useMediaQuery } from 'react-responsive';
import { MediumUpQuery } from '@utils/responsive';
import { Props } from './index';

const PaneContent: FunctionComponent<Props> = ({ pane, hostChannel = '', isGuest = false }) => {
  const isMediumUp = useMediaQuery({ query: MediumUpQuery });
  const guestMediumUp = isMediumUp && isGuest;

  const { t } = useTranslation();

  switch (pane.type) {
    case PaneType.AUDIENCE:
      return (
        <ErrorBoundary
          displayType={ErrorBoundaryDisplayType.PANE}
          altComponentName={t('audience.title')}
        >
          <Audience />
        </ErrorBoundary>
      );
    case PaneType.SERVICE:
      return (
        <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE} altComponentName={t('service')}>
          <Service guestMediumUp={guestMediumUp} isGuest={isGuest} />
        </ErrorBoundary>
      );
    case PaneType.CHAT:
      if (pane?.meta?.channelKey) {
        return (
          <ErrorBoundary displayType={ErrorBoundaryDisplayType.PANE} altComponentName={t('chat')}>
            <Chat
              key={pane.meta.channelKey}
              channelKey={pane.meta.channelKey}
              guestMediumUp={guestMediumUp}
              isGuest={isGuest}
            />
          </ErrorBoundary>
        );
      } else {
        return null;
      }
    case PaneType.HOST_CHAT:
      return (
        <ErrorBoundary
          displayType={ErrorBoundaryDisplayType.PANE}
          altComponentName={t('host_chat')}
        >
          <Chat
            key={hostChannel}
            channelKey={hostChannel}
            guestMediumUp={guestMediumUp}
            isGuest={isGuest}
          />
        </ErrorBoundary>
      );
    case PaneType.BIBLE:
    case PaneType.HOST_INFO:
    case PaneType.NOTES:
    case PaneType.SCHEDULE:
    case PaneType.MOMENTS:
      return <Tab type={pane.type} guestMediumUp={guestMediumUp} isGuest={isGuest} />;
    case PaneType.ERROR:
      return <Error code={pane.meta.code} />;
    default:
      return null;
  }
};

export default PaneContent;
