import { defaultColors, Flex, OSmallParagraph } from '@lifechurch/react-ion';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './index';

const AdditionalGuests: FC<Props> = ({ count = 0 }) => {
  const { t } = useTranslation();

  if (count <= 0) {
    return null;
  }

  return (
    <Flex
      marginBlockStart={3}
      py={5}
      paddingBlockStart={5}
      fontSize={2}
      justifyContent='center'
      alignItems='center'
      borderTop='1px solid'
      borderColor={defaultColors.gray10}
      data-testid='audience-additional-guest'
    >
      <OSmallParagraph color={defaultColors.gray80}>
        {t('audience.additional_guest', { count })}
      </OSmallParagraph>
    </Flex>
  );
};

export default AdditionalGuests;
