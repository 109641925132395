import React, { FunctionComponent } from 'react';
import PrayerButton from '@components/PrayerButton';
import { MediumUpQuery, Small, MediumUp } from '@utils/responsive';
import { LivePrayerWrapper } from './styles';
import { Props } from './index';
import { ChatFeed, ChatInput } from './components';
import { ChannelType } from '../../../../../__generated__/globalTypes';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import PaneHeader, {
  PaneHeaderType,
  IDirectChatHeader,
  IChatHeader,
  IHostChatHeader,
} from '../../paneHeader';
import { HostCount } from '@features/pane/paneHeader/hostChatHeader/HostCount';
import { useMediaQuery } from 'react-responsive';

const Chat: FunctionComponent<Props> = props => {
  const {
    otherSubscribersNickname,
    leaveChannel,
    inviteHost,
    pendingPrayer,
    pendingInvite,
    guestMediumUp,
    isChatEnabled,
    isGuest,
    channel,
    hostViewingAsHost,
    isLoading,
    isConnected,
    connectionStatusChangedAt,
  } = props;

  const { t } = useTranslation();
  const isMediumUp = useMediaQuery({ query: MediumUpQuery });

  const directChatHeader: IDirectChatHeader = {
    type: PaneHeaderType.DIRECT_CHAT,
    payload: {
      title: otherSubscribersNickname,
      leaveChannel,
      inviteHost:
        isGuest || channel.placeholderChannel
          ? undefined
          : { invite: () => inviteHost(channel.id), pendingInvite },
      isGuest,
    },
  };

  const chatHeader = (): IChatHeader | IHostChatHeader => {
    if (channel.type === ChannelType.public) {
      return {
        type: PaneHeaderType.CHAT,
        payload: { title: !isChatEnabled ? t('feed') : t('public_chat') },
      };
    }

    return {
      type: PaneHeaderType.HOST_CHAT,
      payload: { title: t('host_chat') },
    };
  };

  const header = channel.direct || channel.placeholderChannel ? directChatHeader : chatHeader();

  return (
    <>
      {channel.direct || channel.placeholderChannel ? (
        <PaneHeader header={header} guestMediumUp={guestMediumUp} />
      ) : (
        <MediumUp>
          <PaneHeader header={header} guestMediumUp={guestMediumUp} />
        </MediumUp>
      )}
      <ErrorBoundary
        displayType={ErrorBoundaryDisplayType.PANE}
        altComponentName={header.payload.title}
      >
        {channel.type === ChannelType.host && !isMediumUp && <HostCount mobile />}
        <ChatFeed {...props} />
        <ChatInput
          pendingPrayer={pendingPrayer}
          isChatEnabled={isChatEnabled}
          channel={channel}
          hostViewingAsHost={hostViewingAsHost}
          isLoading={isLoading}
          connectionStatusChangedAt={connectionStatusChangedAt}
          isConnected={isConnected}
        />
      </ErrorBoundary>
      {channel.type === ChannelType.public && (
        <Small>
          <LivePrayerWrapper>
            <PrayerButton inChat />
          </LivePrayerWrapper>
        </Small>
      )}
    </>
  );
};

export default React.memo(Chat);
