import { ModalType } from '../dux';

export interface AcceptInviteMeta {
  nicknameUnavailable?: boolean;
  emailUnavailable?: boolean;
}

export interface AcceptInvite {
  type: ModalType.ACCEPT_INVITE;
  meta?: AcceptInviteMeta;
}

export const acceptInvite = (meta?: AcceptInviteMeta): AcceptInvite => ({
  type: ModalType.ACCEPT_INVITE,
  meta,
});
