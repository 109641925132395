import { v4 } from 'uuid';

export enum ReactionType {
  HEART = 'heart',
  CLAP = 'clap',
  RAISED_HANDS = 'raised_hands',
}

export interface Reaction {
  id: string;
  subscriberId?: string;
  type: ReactionType | string;
  timestamp: string;
  channelKey?: string;
}

export const createReaction = ({
  channelKey,
  subscriberId,
  type,
}: Omit<Reaction, 'id' | 'timestamp'>): Reaction => {
  const id = v4();
  return {
    id,
    type,
    subscriberId,
    channelKey,
    timestamp: new Date().toISOString(),
  };
};
