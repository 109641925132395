import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, ButtonSize, IconSize, OSmallParagraph, LinkIcon, CheckIcon, } from '@lifechurch/react-ion';
import { ShareButtonContainer } from '../share-button-container';
var copyState;
(function (copyState) {
    copyState[copyState["IDLE"] = 0] = "IDLE";
    copyState[copyState["COPIED"] = 1] = "COPIED";
    copyState[copyState["ERROR"] = 2] = "ERROR";
})(copyState || (copyState = {}));
const CopyButton = styled(PrimaryButton) `
  background-color: ${props => {
    switch (props.copyStatus) {
        case copyState.IDLE:
            return props.theme.colors.gray50;
        case copyState.COPIED:
            return props.theme.colors.success.default;
        default:
            return props.theme.colors.gray50;
    }
}};

  &:hover {
    background-color: ${props => {
    switch (props.copyStatus) {
        case copyState.IDLE:
            return props.theme.colors.gray50;
        case copyState.COPIED:
            return props.theme.colors.success.default;
        default:
            return props.theme.colors.gray50;
    }
}};
    box-shadow: ${props => (props.copyStatus === copyState.COPIED ? 'none' : null)};
  }
`;
const CopyText = styled(OSmallParagraph) `
  color: ${props => {
    switch (props.copyStatus) {
        case copyState.IDLE:
            return props.theme.colors.gray50;
        case copyState.COPIED:
            return props.theme.colors.success.default;
        default:
            return props.theme.colors.gray50;
    }
}};
`;
const CopyShareButton = ({ content, buttonText, size = ButtonSize.SM, padding = '8px !important', iconSize = IconSize.XL }, props) => {
    const [copyStatus, setCopyStatus] = React.useState(copyState.IDLE);
    // copyToClipboard function from HackerNoon. Slightly edited for specific use.
    // source: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
    const copyToClipboard = () => {
        if (!content)
            return;
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(content)
                .then(() => setCopyStatus(copyState.COPIED))
                .catch(() => setCopyStatus(copyState.ERROR));
        }
    };
    return (React.createElement(ShareButtonContainer, { flexDirection: 'column', alignItems: 'center' },
        React.createElement(CopyButton, { "aria-label": buttonText, copyStatus: copyStatus, "data-testid": 'copy', onClick: content ? copyToClipboard : null, padding: padding, size: size, ...props },
            copyStatus === copyState.IDLE ? React.createElement(LinkIcon, { size: iconSize, color: 'white' }) : null,
            copyStatus === copyState.COPIED ? React.createElement(CheckIcon, { size: iconSize, color: 'white' }) : null),
        React.createElement(CopyText, { copyStatus: copyStatus, paddingBlockStart: 2, color: 'gray50' },
            copyStatus === copyState.IDLE ? buttonText : null,
            copyStatus === copyState.COPIED ? 'Copied' : null)));
};
export { CopyShareButton };
