import React, { FunctionComponent } from 'react';
import { Props } from '../index';
import Feed from '../../../../feed';
import ChatPlaceholder from './ChatPlaceholder';

const ChatFeed: FunctionComponent<Props> = ({
  otherSubscribersNickname,
  pendingPrayer,
  isGuest,
  channel,
  hostViewingAsHost,
  isLoading,
}) => (
  <>
    {channel.placeholderChannel || pendingPrayer ? (
      <ChatPlaceholder
        otherSubscribersNickname={otherSubscribersNickname}
        pendingPrayer={pendingPrayer}
        hostViewingAsHost={hostViewingAsHost}
      />
    ) : (
      <Feed
        channelKey={channel.key}
        isDirect={channel.direct}
        isGuest={isGuest}
        isLoading={isLoading}
      />
    )}
  </>
);

export default ChatFeed;
