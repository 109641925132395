import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { CurrentState_currentOrganization_offlineContent } from '@io/__generated__/CurrentState';
import { connect } from 'react-redux';
import Media from './media';
import { RootState } from '@store/rootReducer';
import { isOffline } from '@store/serviceSlice/selectors';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';

interface StateProps {
  hasPrayer: boolean;
  isOffline: boolean;
  offlineContent: CurrentState_currentOrganization_offlineContent | null;
  url: string | null;
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
}

interface OwnProps {
  offlineContent?: CurrentState_currentOrganization_offlineContent | null;
  url: string | null;
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
  const { offlineContent } = getCurrentOrganization(state);
  const hasOfflinePrayerRecipients =
    getCurrentOrganization(state).offlinePrayerRecipients.length > 0;
  const offline = isOffline(state);

  return {
    hasPrayer: offline && hasOfflinePrayerRecipients,
    isOffline: offline,
    offlineContent,
    url: props.url,
    nextService: getNextServiceData(state),
  };
};

const VisibleMedia = connect(mapStateToProps)(Media);

export type Props = StateProps & OwnProps;
export default VisibleMedia;
