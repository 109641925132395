import styled, { css } from 'styled-components';
import { LandscapeMobile, MediumQuery, MediumPlusQuery } from '@utils/responsive';
import { getInset } from '@lifechurch/react-ion';

interface ItemWrapperProps {
  isCurrent: boolean;
  isDirect?: boolean;
}

const NavbarWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  position: relative;
  @media ${LandscapeMobile} {
    display: none;
  }
`;

const Navbar = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  position: relative;
  display: flex;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 24px;
  width: 100%;
  height: 32px;
  max-width: none;
  margin: 0 auto;
  padding-inline-end: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${MediumQuery} {
    height: 40px;
    max-width: 460px;
  }

  @media ${MediumPlusQuery} {
    max-width: 640px;
  }
`;

const NavbarItemWrapper = styled.button<ItemWrapperProps>`
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: ${props => (props.isCurrent ? props.theme.colors.gray100 : props.theme.colors.gray50)};
  font-size: 13.44px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  font-weight: 600;
  border: none;
  background-color: ${props => (props.isCurrent ? props.theme.colors.white : 'transparent')};
  outline: none;
  scroll-snap-align: end;
  overflow: visible; // Collapases tab if set to hidden
  margin: 0;
  height: 100%;

  &:hover {
    color: ${props => props.theme.colors.gray100};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover} inset;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  @media ${MediumQuery} {
    padding: 0 16px;
  }

  ${({ isDirect }) =>
    isDirect &&
    css`
      overflow: hidden;
      min-width: 44px; // padding + width of content
    `}
`;

const PipWrapper = styled.div`
  position: absolute;
  ${getInset('insetBlockStart', '4px')};
  ${getInset('insetInlineEnd', '2px')};
  height: 8px;
  width: 8px;
`;

const TabOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${props => props.theme.colors.gray10};
  width: 24px;
  z-index: 2;
  height: 32px;

  &:hover {
    svg {
      path {
        stroke: ${props => props.theme.colors.gray100};
      }
    }
  }

  @media ${MediumQuery} {
    height: 40px;
  }
`;

const TabOverflowWrapper = styled(TabOverflow)`
  ${getInset('insetInlineEnd', 0)};
`;

const InvertedTabOverflowWrapper = styled(TabOverflow)`
  transform: scale(-1, 1);
`;

const TypingIndicatorIconContainer = styled.div`
  display: flex;
  z-index: 10;
  position: absolute;
  ${getInset('insetBlockStart', '2px')};
  ${getInset('insetInlineEnd', '2px')};
  background-color: ${props => props.theme.colors.white};
  border-radius: 15px;
  padding: 1.5px 3px;
  width: 21px;
  height: 10px;
`;

export {
  NavbarWrapper,
  Navbar,
  NavbarItemWrapper,
  PipWrapper,
  TabOverflow,
  TabOverflowWrapper,
  InvertedTabOverflowWrapper,
  TypingIndicatorIconContainer,
};
