import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ArrowDownIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M11.8535 8.35355L7.49996 12.7071L3.14641 8.35355L3.85352 7.64645L6.99996 10.7929L6.99996 3H7.99996V10.7929L11.1464 7.64645L11.8535 8.35355Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16.707 11.7071L9.99992 18.4142L3.29282 11.7071L4.70703 10.2929L8.99992 14.5858V2H10.9999V14.5858L15.2928 10.2929L16.707 11.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20.707 13.7071L11.9999 22.4142L3.29282 13.7071L4.70703 12.2929L10.9999 18.5858L10.9999 2H12.9999V18.5858L19.2928 12.2929L20.707 13.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M26.707 19.7071L15.9999 30.4142L5.29282 19.7071L6.70703 18.2929L14.9999 26.5858L14.9999 2L16.9999 2V26.5858L25.2928 18.2929L26.707 19.7071Z'
          size={size}
          {...rest}
        />
      );
  }
};
