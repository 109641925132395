import React, { FunctionComponent } from 'react';
import { ErrorCode } from '../../dux';
import FiveHundredError from './500';
import FourZeroFourError from './404';

interface Props {
  code: ErrorCode;
}

const Error: FunctionComponent<Props> = ({ code }) => {
  switch (code) {
    case ErrorCode.FIVE_HUNDRED:
      return <FiveHundredError />;
    case ErrorCode.FOUR_ZERO_FOUR:
      return <FourZeroFourError />;
    default:
      return null;
  }
};

export default Error;
