import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const VideoIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M14 3H1V12H14V3ZM6.00501 9.44856L9.38 7.5L6.00501 5.55144V9.44856Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M19 4H1V16H19V4ZM8 12.5981L12.5 10L8 7.40192L8 12.5981Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22 4H2V20H22V4ZM9.5 15.4641L15.5 12L9.5 8.5359L9.5 15.4641Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M30 6H2V26H30V6ZM13 20.3301L20.5 16L13 11.6699V20.3301Z'
          size={size}
          {...rest}
        />
      );
  }
};
