import { AlertSubtype } from '@components/Alert/dux';

const getAlert = (): AlertSubtype | null => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('alert')) {
    return searchParams.get('alert') as AlertSubtype;
  } else {
    return null;
  }
};

export { getAlert };
