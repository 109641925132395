import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { call, put, select } from 'redux-saga/effects';
import { setPaneToChat, setPaneToType } from '@store/uiSlice';
import queries from '../queries';
import {
  saveChannel,
  removeChannel,
  RequestPlaceholderChannel,
  saveMessage,
  savePrayerRequest,
  LeaveChannel,
  JoinChannel,
  RequestInviteToChannel,
  AcceptInviteToChannel,
  requestChannel as requestChannelAction,
  joinChannel as joinChannelAction,
  acceptLivePrayer as acceptLivePrayerAction,
  requestLivePrayer as requestLivePrayerAction,
  instantiatePlaceholderChannel,
  newPlaceholderChannel,
  ClientChannel,
  Channel,
} from '@store/feedSlice';
import { PaneType } from '@features/pane/dux';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import {
  getChannelAsPublicChannel,
  hasPendingPrayerChannel,
} from '@store/feedSlice/channelSelectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { getCurrentServiceId } from '@store/serviceSlice/selectors';
import { publishLiveObject, LiveObjectType } from '../../pubnub/liveObject';
import { newMessageLiveObject } from '@features/feed/objects/message/dux';
import { subscribeToChannel, unsubscribeFromChannel } from './pubnub';
import {
  acceptPrayer as acceptPrayerMetric,
  requestPrayer as requestPrayerMetric,
  inviteToChannel as inviteToChannelMetric,
  acceptedInviteToChannel as acceptedInviteToChannelMetric,
} from './metrics';
import { infoAlert } from '@components/Alert/dux';
import {
  JoinChannelMutation,
  JoinChannelMutation_joinChannel,
} from '../__generated__/JoinChannelMutation';
import { FetchResult } from '@apollo/client';
import { SagaIterator } from 'redux-saga';
import { LeaveChannelMutation } from '../__generated__/LeaveChannelMutation';
import {
  RequestChannelQuery,
  RequestChannelQuery_requestChannel,
} from '../__generated__/RequestChannelQuery';
import { ChannelType } from '../../../__generated__/globalTypes';
import { requestChannelSubscribersToPublicSubscribers } from '@store/feedSlice/chatSelectors';
import { RequestInviteToChannel as RequestInviteToChannelQuery } from '../__generated__/RequestInviteToChannel';
import { AcceptInviteToChannel as AcceptInviteToChannelMutation } from '../__generated__/AcceptInviteToChannel';
import { v4 as uuidv4 } from 'uuid';
import { Subscriber } from '../../types';
import { OrganizationState } from '@store/organizationSlice';

function* joinChannel(
  action: JoinChannel
): SagaIterator<JoinChannelMutation_joinChannel | undefined> {
  try {
    const {
      payload: { id },
    } = action;
    const result: FetchResult<JoinChannelMutation> = yield call([queries, queries.joinChannel], id);
    return result.data?.joinChannel;
  } catch (error) {
    const castError = error as any;
    Sentry.withScope(scope => {
      scope.setExtra('Error Message', castError.message);
      Sentry.captureMessage('Failed to join channel.', Severity.Error);
    });
  }
}

function* leaveChannel(action: LeaveChannel) {
  try {
    const { payload } = action;
    const { key, id, placeholderChannel } = payload || {};
    if (placeholderChannel) {
      yield put(removeChannel({ key }));
      yield put(setPaneToType(PaneType.SERVICE));
    } else {
      const result: FetchResult<LeaveChannelMutation> = yield call(
        [queries, queries.leaveChannel],
        id
      );
      if (result.data?.leaveChannel.success) {
        yield call(unsubscribeFromChannel, key);
        yield put(removeChannel({ key }));
        yield put(setPaneToType(PaneType.SERVICE));
      } else {
        Sentry.captureMessage('Server returned false for leaveChannel');
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* requestChannel(
  action: ReturnType<typeof requestChannelAction>
): SagaIterator<RequestChannelQuery_requestChannel | null | undefined> {
  try {
    const { payload } = action;
    const result: FetchResult<RequestChannelQuery> = yield call(
      [queries, queries.requestChannel],
      payload.id,
      payload.input,
      payload.with || ''
    );
    return result.data?.requestChannel;
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* requestPlaceholderChannel(action: RequestPlaceholderChannel) {
  const {
    payload: { text, subscriber, lang, channel: placeholder },
  } = action;
  const serviceId: string = yield select(getCurrentServiceId);
  const request = requestChannelAction({
    id: placeholder.id,
    input: {
      id: placeholder.id,
      serviceId,
      type: placeholder.type,
    },
    with: placeholder?.subscribers?.find(s => s.id !== subscriber.id)?.id || '',
  });
  const channel: RequestChannelQuery_requestChannel & { subscribersTyping: [] } = yield call(
    requestChannel,
    request
  );
  if (channel) {
    const subscribers = 'subscribers' in channel ? channel.subscribers?.nodes : [];
    yield put(
      instantiatePlaceholderChannel({
        ...channel,
        subscribers: requestChannelSubscribersToPublicSubscribers(subscribers),
      })
    );
    yield put(setPaneToChat(channel.key));
    const object = newMessageLiveObject(
      text,
      subscriber,
      lang,
      getChannelAsPublicChannel({
        ...channel,
      })
    );
    yield put(saveMessage(object));
    yield put(publishLiveObject(object));
    yield call(subscribeToChannel, channel);
  }
}

function* acceptLivePrayer(action: ReturnType<typeof acceptLivePrayerAction>) {
  try {
    const { channel, subscriber } = action.payload;
    const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
    yield put(
      saveChannel({
        ...channel,
        subscribers: subscriber ? [currentSubscriber, subscriber] : [currentSubscriber],
      })
    );

    yield put(
      savePrayerRequest({
        id: uuidv4(),
        type: LiveObjectType.PRAYER_REQUEST,
        channel: channel,
        data: {
          ...action.payload,
          open: false,
          host: currentSubscriber,
        },
        timestamp: new Date().toISOString(),
      })
    );

    const response: JoinChannelMutation_joinChannel = yield call(
      joinChannel,
      joinChannelAction(channel.id)
    );

    if (response?.success) {
      yield call(subscribeToChannel, channel);
      yield put(setPaneToChat(channel.key));
      yield call(acceptPrayerMetric, channel.id);
    }
  } catch (error) {
    const castError = error as any;
    // yield put({type: PUBLISH_ACCEPTED_PRAYER_REQUEST_FAILED, error: error.message});
    Sentry.withScope(scope => {
      scope.setExtra('Error Message', castError.message);
      Sentry.captureMessage('Failed to accept live prayer.', Severity.Error);
    });
  }
}

function* requestLivePrayer(action: ReturnType<typeof requestLivePrayerAction>) {
  try {
    const serviceId: string = yield select(getCurrentServiceId);
    const pendingPrayerChannel: Channel | undefined = yield select(hasPendingPrayerChannel);

    if (pendingPrayerChannel) {
      const request = requestChannelAction({
        id: pendingPrayerChannel.id,
        input: {
          id: pendingPrayerChannel.id,
          serviceId,
          type: pendingPrayerChannel.type,
          meta: {
            raisedHand: action.meta.raisedHand ?? false,
          },
        },
      });

      yield put(infoAlert('prayer_requested'));
      yield call(requestChannel, request);
    } else {
      const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
      const currentOrganization: OrganizationState = yield select(getCurrentOrganization);

      const newChannel = newPlaceholderChannel(
        [currentSubscriber],
        ChannelType.prayer,
        currentOrganization.id
      );

      const request = requestChannelAction({
        id: newChannel.id,
        input: {
          id: newChannel.id,
          serviceId,
          type: newChannel.type,
          meta: {
            raisedHand: action.meta.raisedHand ?? false,
          },
        },
      });

      yield put(saveChannel(newChannel));
      yield put(setPaneToChat(newChannel.key));

      const channel: RequestChannelQuery_requestChannel | null | undefined = yield call(
        requestChannel,
        request
      );

      if (channel) {
        const subscribers = 'subscribers' in channel ? channel.subscribers?.nodes : [];
        const clientChannel: ClientChannel = {
          ...channel,
          subscribers: requestChannelSubscribersToPublicSubscribers(subscribers),
        };

        yield put(instantiatePlaceholderChannel(clientChannel));
        yield put(setPaneToChat(clientChannel.key));
        yield call(subscribeToChannel, clientChannel);
        yield call(requestPrayerMetric, clientChannel.id, action.payload.momentId);
      } else {
        yield put(infoAlert('request_prayer_error'));
      }
    }
  } catch (error) {
    const castError = error as any;
    Sentry.withScope(scope => {
      scope.setExtra('Error Message', castError.message);
      Sentry.captureMessage('Failed to request live prayer.', Severity.Error);
    });
  }
}

function* requestInviteToChannel(action: RequestInviteToChannel) {
  try {
    const { payload } = action;
    const result: FetchResult<RequestInviteToChannelQuery> = yield call(
      [queries, queries.requestInviteToChannel],
      payload
    );

    if (result.data?.requestInviteToChannel) {
      yield call(
        inviteToChannelMetric,
        result.data.requestInviteToChannel.channel.id,
        result.data.requestInviteToChannel.requesterSubscriberId
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* acceptInviteToChannel(action: AcceptInviteToChannel) {
  try {
    const { payload } = action;
    const result: FetchResult<AcceptInviteToChannelMutation> = yield call(
      [queries, queries.acceptInviteToChannel],
      payload
    );

    if (result.data?.acceptInviteToChannel) {
      yield call(
        acceptedInviteToChannelMetric,
        result.data.acceptInviteToChannel.channel.id,
        result.data.acceptInviteToChannel.accepterSubscriberId || ''
      );
      yield put(setPaneToChat(result.data.acceptInviteToChannel.channel.key));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export {
  joinChannel,
  leaveChannel,
  requestChannel,
  requestPlaceholderChannel,
  requestLivePrayer,
  acceptLivePrayer,
  requestInviteToChannel,
  acceptInviteToChannel,
};
