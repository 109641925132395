import { RoleIdentifier } from '../../types';
import { TextMode } from '../../../__generated__/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import { pushAlert } from '@store/uiSlice';

export enum AlertType {
  INFO = 'Info',
  ERROR = 'Error',
  SUCCESS = 'Success',
  PRIMARY = 'Primary',
  INFO_DARK = 'InfoDark',
}

export enum AlertSubtype {
  DEFAULT = 'Default',
  MUTED = 'Muted',
  UNMUTED = 'Unmuted',
  LOGGED_IN = 'Logged In',
  SIGN_UP = 'Sign Up',
  DIRECT_CHAT = 'Direct Chat',
  PRAYER = 'Prayer',
  EXPIRED = 'EXPIRED',
  WELCOME = 'Welcome',
}

export interface AlertInterface {
  type: AlertType;
  subtype: AlertSubtype;
  key: string;
  uniqueKey: string;
  fuseDisabled?: boolean;
  fuseTimer?: number;
  variables?: {
    [name: string]: string | null | undefined;
  };
  meta?: {
    [name: string]: string;
  };
  animationDisabled?: boolean;
  containerId?: string;
}

export const infoAlert = (key: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.INFO,
    subtype: AlertSubtype.DEFAULT,
    key,
    uniqueKey: uuidv4(),
  },
});

export const successAlert = (key: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.SUCCESS,
    subtype: AlertSubtype.DEFAULT,
    key,
    uniqueKey: uuidv4(),
  },
});

export const errorAlert = (key: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.ERROR,
    subtype: AlertSubtype.DEFAULT,
    key,
    uniqueKey: uuidv4(),
  },
});

export const mutedAlert = (name: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.SUCCESS,
    subtype: AlertSubtype.MUTED,
    key: 'subscriber_muted',
    uniqueKey: uuidv4(),
    variables: {
      name,
    },
  },
});

export const unmutedAlert = (name: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.SUCCESS,
    subtype: AlertSubtype.UNMUTED,
    key: 'subscriber_unmuted',
    uniqueKey: uuidv4(),
    variables: { name },
  },
});

export const textModeAlert = (mode: TextMode): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.INFO,
    subtype: AlertSubtype.DEFAULT,
    key: mode === TextMode.COMPACT ? 'text_mode_decreased' : 'text_mode_increased',
    uniqueKey: uuidv4(),
  },
});

export const loggedInAlert = (): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.SUCCESS,
    subtype: AlertSubtype.LOGGED_IN,
    key: 'logged_in',
    uniqueKey: uuidv4(),
  },
});

export const signupInAlert = (): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.SUCCESS,
    subtype: AlertSubtype.SIGN_UP,
    key: 'signup',
    uniqueKey: uuidv4(),
  },
});

export const directChatAlert = (
  {
    name,
    roleIdentifier,
  }: {
    name: string;
    roleIdentifier?: RoleIdentifier;
  },
  channelKey: string
): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.PRIMARY,
    subtype: AlertSubtype.DIRECT_CHAT,
    key: 'direct_chat',
    uniqueKey: uuidv4(),
    fuseDisabled: true,
    variables: {
      name,
      roleIdentifierKey: roleIdentifier?.key,
      roleIdentifierLabel: roleIdentifier?.label,
      roleIdentifierType: roleIdentifier?.type,
    },
    meta: {
      channelKey,
    },
  },
});

export const prayerAlert = (
  {
    name,
    roleIdentifier,
  }: {
    name: string;
    roleIdentifier?: RoleIdentifier;
  },
  channelKey: string
): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.PRIMARY,
    subtype: AlertSubtype.PRAYER,
    key: 'prayer',
    uniqueKey: uuidv4(),
    variables: {
      name,
      roleIdentifierKey: roleIdentifier?.key,
      roleIdentifierLabel: roleIdentifier?.label ?? '',
      roleIdentifierType: roleIdentifier?.type,
    },
    fuseDisabled: true,
    meta: {
      channelKey,
    },
  },
});

export const rapidMessageAlert = (
  key: string,
  fuseTimer?: number
): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.INFO_DARK,
    subtype: AlertSubtype.DEFAULT,
    key,
    uniqueKey: uuidv4(),
    fuseTimer,
    animationDisabled: true,
    containerId: 'chat',
  },
});

export const welcomeAlert = (currentOrganizationName: string): ReturnType<typeof pushAlert> => ({
  type: pushAlert.type,
  payload: {
    type: AlertType.INFO,
    subtype: AlertSubtype.WELCOME,
    key: 'welcome',
    uniqueKey: uuidv4(),
    variables: {
      currentOrganizationName,
    },
    fuseTimer: 15000,
  },
});

export const setAlertFromUrl = (type: AlertSubtype): ReturnType<typeof pushAlert> => {
  switch (type) {
    case AlertSubtype.EXPIRED:
      return errorAlert('expired_invitation');
    default:
      return errorAlert('error');
  }
};
