import React, { FunctionComponent } from 'react';
import { IconSize } from './icons';

export interface SpinnerProps {
  /**
   * Sets the size of the spinner
   */
  size?: IconSize;
  /**
   * Sets the color of the spinner
   */
  color?: string;
}
/**
 * The Spinner component spins while an unquantifiable task is loading
 *
 *
 * ###When To Use
 *
 * - When you need to indicate the loading state of a component or page to the user
 *
 */
export const Spinner: FunctionComponent<SpinnerProps> = ({
  size = IconSize.MD,
  color = 'white',
}) => {
  switch (size) {
    case IconSize.SM:
      return (
        <svg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13 7.5C13 4.46243 10.5376 2 7.5 2' stroke={color} strokeWidth='1' fill='none'>
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              from='0 7.5 7.5'
              to='360 7.5 7.5'
              dur='1s'
              repeatCount='indefinite'
            />
          </path>
        </svg>
      );
    case IconSize.MD:
      return (
        <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
          <path d='M17 10C17 6.13401 13.866 3 10 3' stroke={color} strokeWidth='2' fill='none'>
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              from='0 10 10'
              to='360 10 10'
              dur='0.75s'
              repeatCount='indefinite'
            />
          </path>
        </svg>
      );
    case IconSize.LG:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M21 12C21 7.02944 16.9706 3 12 3' stroke={color} strokeWidth='2' fill='none'>
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              from='0 12 12'
              to='360 12 12'
              dur='0.75s'
              repeatCount='indefinite'
            />
          </path>
        </svg>
      );
    case IconSize.XL:
      return (
        <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
          <path d='M28 16C28 9.37258 22.6274 4 16 4' stroke={color} strokeWidth='2' fill='none'>
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              from='0 16 16'
              to='360 16 16'
              dur='0.9s'
              repeatCount='indefinite'
            />
          </path>
        </svg>
      );
  }
};
