import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, Dropdown, DropdownItemType } from '@lifechurch/react-ion';
import VerticalEllipses from '@components/icons/verticalEllipses';
import clsx from 'clsx';
import styles from './feedObjectMenu.module.css';

export interface Props {
  chatPermissions?: boolean;
  deleteMessage?: () => void;
  deleteMoment?: () => void;
  directChat?: () => void;
  hideMessageMenu?: () => void;
  isCompact?: boolean;
  isMessage?: boolean;
  isMoment?: boolean;
  menuPlacement: 'top' | 'auto';
  moderationPermissions: boolean;
  muteSubscriber?: () => void;
  nickname?: string;
  self?: boolean;
  showMessageMenu?: () => void;
}

export const MESSAGE_MENU_MAX_HEIGHT = 150;

const FeedObjectMenu: FunctionComponent<Props> = ({
  chatPermissions,
  deleteMessage = null,
  deleteMoment = null,
  directChat = null,
  hideMessageMenu,
  isMessage = false,
  isMoment = false,
  menuPlacement = 'top',
  moderationPermissions,
  muteSubscriber = null,
  nickname,
  self,
  showMessageMenu,
}) => {
  const { t } = useTranslation();

  const onHide = () => {
    !!hideMessageMenu && hideMessageMenu();
  };
  const onShow = () => {
    !!showMessageMenu && showMessageMenu();
  };

  return (
    <div
      className={clsx([styles.wrapper], { [styles.isMessage]: isMessage })}
      data-testid='feedObjectMenuWrapper'
    >
      <Dropdown
        popperOptions={{ placement: menuPlacement }}
        popperContent={
          <>
            {chatPermissions && !self && isMessage && (
              <DropdownItem
                onClick={directChat}
                data-testid='directChatButton'
                variant={DropdownItemType.PRIMARY}
              >
                {t('buttons.direct_chat')}
              </DropdownItem>
            )}
            {moderationPermissions && isMessage && (
              <DropdownItem
                variant={DropdownItemType.RISK}
                onClick={deleteMessage}
                data-testid='deleteButtonMessage'
              >
                {t('buttons.delete_message')}
              </DropdownItem>
            )}
            {moderationPermissions && isMessage && !self && (
              <DropdownItem
                variant={DropdownItemType.RISK}
                onClick={muteSubscriber}
                data-testid='muteButton'
              >
                {t('buttons.mute_subscriber')} {nickname}
              </DropdownItem>
            )}
            {moderationPermissions && isMoment && (
              <DropdownItem
                variant={DropdownItemType.RISK}
                onClick={deleteMoment}
                data-testid='deleteButtonMoment'
              >
                {t('buttons.delete_moment')}
              </DropdownItem>
            )}
          </>
        }
        onHide={onHide}
        onShow={onShow}
        referenceContent={
          <div className={styles.ellipsisWrapper} data-testid='feedObjectMenu-action'>
            <VerticalEllipses className={styles.ellipsis} />
          </div>
        }
      />
    </div>
  );
};

export default FeedObjectMenu;
