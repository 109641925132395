import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Props } from './index';
import {
  Box,
  FormBanner,
  FormBannerType,
  LinkButton,
  Modal,
  ModalSize,
  OHeaderThree,
  OParagraph,
} from '@lifechurch/react-ion';

const RevertChangeEmail = ({
  closeModal,
  revertSubscriber,
  openChangePassword,
  subscriber,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    revertSubscriber();
  }, []);

  return (
    <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='revertEmailChange-modal'>
      <OHeaderThree marginBlockEnd={5}>{t('revert_email_change.title')}</OHeaderThree>
      {subscriber.email && (
        <FormBanner type={FormBannerType.Success} withIcon>
          <OParagraph>
            <Trans i18nKey='revert_email_change.success_message'>
              {{ email: subscriber.email }}.
            </Trans>
          </OParagraph>
        </FormBanner>
      )}
      <Box marginBlockStart={6}>
        <OParagraph>{t('revert_email_change.disclaimer')}</OParagraph>
      </Box>
      <Box marginBlockStart={3}>
        <LinkButton onClick={openChangePassword}>
          {t('revert_email_change.change_password_link')}
        </LinkButton>
      </Box>
    </Modal>
  );
};

export default RevertChangeEmail;
