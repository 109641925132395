import React, { FunctionComponent } from 'react';
import { QuoteMomentShareSheet as ShareSheet } from '@churchonline/moments';
import { Props } from './index';

const QuoteMomentShareSheet: FunctionComponent<Props> = ({
  text,
  attribution,
  organizationName,
  closeModal,
}) => {
  return (
    <ShareSheet
      open={true}
      handleModalClose={closeModal}
      text={text}
      attribution={attribution || ''}
      church={organizationName}
    />
  );
};

export default QuoteMomentShareSheet;
