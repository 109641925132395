import { OXSmallParagraph } from '@lifechurch/react-ion';
import React, { FunctionComponent } from 'react';
import { Wrapper, HorizontalLine } from './styles';

export interface Props {
  count: number;
  show: boolean;
}

export const CHARACTER_LIMIT = 700;
export const SHOW_CHARACTER_COUNT_AT = 650;

const CharacterCount: FunctionComponent<Props> = ({ count, show }) => {
  const overLimit = count > CHARACTER_LIMIT;
  if (count < SHOW_CHARACTER_COUNT_AT || !show) return null;

  return (
    <Wrapper overLimit={overLimit}>
      <OXSmallParagraph>{count}</OXSmallParagraph>
      <HorizontalLine overLimit={overLimit} />
      <OXSmallParagraph>{CHARACTER_LIMIT}</OXSmallParagraph>
    </Wrapper>
  );
};

export default CharacterCount;
