import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const EyeOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M7.49804 11.5C4.79549 11.5 2.47593 9.85014 1.49591 7.5C2.47593 5.14986 4.79549 3.5 7.49804 3.5C10.2006 3.5 12.5201 5.14986 13.5002 7.5C12.5201 9.85015 10.2006 11.5 7.49804 11.5ZM7.49804 2.5C10.7636 2.5 13.5417 4.58702 14.5713 7.5C13.5417 10.413 10.7636 12.5 7.49804 12.5C4.23249 12.5 1.4544 10.413 0.424805 7.5C1.4544 4.58702 4.23249 2.5 7.49804 2.5ZM7.5 9.5C8.60457 9.5 9.5 8.60457 9.5 7.5C9.5 6.39543 8.60457 5.5 7.5 5.5C6.39543 5.5 5.5 6.39543 5.5 7.5C5.5 8.60457 6.39543 9.5 7.5 9.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10.0001 14C7.21027 14 4.79753 12.367 3.67312 10C4.79753 7.63298 7.21027 6 10.0001 6C12.7899 6 15.2026 7.63298 16.3271 10C15.2026 12.367 12.7899 14 10.0001 14ZM10.0001 4C13.9187 4 17.2525 6.50443 18.488 10C17.2525 13.4956 13.9187 16 10.0001 16C6.08143 16 2.74772 13.4956 1.51221 10C2.74772 6.50442 6.08143 4 10.0001 4ZM10.0001 12.5C11.3808 12.5 12.5001 11.3807 12.5001 10C12.5001 8.61929 11.3808 7.5 10.0001 7.5C8.61941 7.5 7.50012 8.61929 7.50012 10C7.50012 11.3807 8.61941 12.5 10.0001 12.5Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M11.9971 18C7.89919 18 4.37265 15.5341 2.82861 12C4.37265 8.46592 7.89919 6 11.9971 6C16.095 6 19.6216 8.46592 21.1656 12C19.6216 15.5341 16.095 18 11.9971 18ZM11.9971 4C17.222 4 21.6669 7.33924 23.3143 12C21.6669 16.6608 17.222 20 11.9971 20C6.77223 20 2.32728 16.6608 0.679932 12C2.32728 7.33923 6.77223 4 11.9971 4ZM11.9999 16C14.2091 16 15.9999 14.2091 15.9999 12C15.9999 9.79086 14.2091 8 11.9999 8C9.7908 8 7.99994 9.79086 7.99994 12C7.99994 14.2091 9.7908 16 11.9999 16Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16.0011 25C9.94252 25 4.74733 21.2832 2.57994 16C4.74733 10.7168 9.94252 7 16.0011 7C22.0596 7 27.2548 10.7168 29.4222 16C27.2548 21.2832 22.0596 25 16.0011 25ZM16.0011 5C23.1853 5 29.2971 9.59144 31.5622 16C29.2971 22.4086 23.1853 27 16.0011 27C8.81686 27 2.70504 22.4086 0.439941 16C2.70504 9.59144 8.81686 5 16.0011 5ZM15.9999 22C19.3136 22 21.9999 19.3137 21.9999 16C21.9999 12.6863 19.3136 10 15.9999 10C12.6862 10 9.99994 12.6863 9.99994 16C9.99994 19.3137 12.6862 22 15.9999 22Z'
          size={size}
          {...rest}
        />
      );
  }
};
