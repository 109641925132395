import { useEffect, useState } from 'react';

const useSpamDetection = () => {
  const [isSpam, setIsSpam] = useState(false);
  const [trigger, setTrigger] = useState(0);

  const [lastMessageMillis, setLastMessageMillis] = useState(0);
  const [delay, setDelay] = useState(0); // keep track of delay penalty
  const [forgive, setForgive] = useState(1.25); // how fast to forgive, reduced when hitting wall.
  const maxInstantMsgs = 5; // number of instant messages allowed, min 3 for wall to be > 1
  const wall = maxInstantMsgs - 2; // minus 2 (behavior of algorithm with first/last messages)
  const noPenaltyDelay = 5; // seconds between messages with no penalty
  const wait = 5; // seconds to wait after hitting the wall
  const smooth = 2; // bigger = penalty increases faster

  function checkSpam(nowMillis: number) {
    const reduceDelay = (lastMessageMillis: number, nowMillis: number) => {
      const timeBetween = (forgive * (nowMillis - lastMessageMillis)) / 1000;
      return timeBetween;
    };

    const calcDelay = (lastMessageMillis: number, nowMillis: number) => {
      const timeBetween = (nowMillis - lastMessageMillis) / 1000;
      const d = 1 + Math.pow(smooth, -noPenaltyDelay);
      if (timeBetween >= noPenaltyDelay) return 0;
      else return -Math.pow(smooth, timeBetween - noPenaltyDelay) + d;
    };

    const reduce = reduceDelay(lastMessageMillis, nowMillis);
    const increase = calcDelay(lastMessageMillis, nowMillis);
    let newDelay = delay - reduce + (delay >= wall ? wait : 0);

    if (newDelay - increase < wall) {
      if (newDelay < 0) newDelay = 0;
      newDelay += increase;
      if (newDelay > wall) {
        const newForgive = forgive > 0.25 ? forgive - 0.25 : forgive;
        setForgive(newForgive);
        setDelay(wall);
      } else {
        setDelay(newDelay);
      }
      setLastMessageMillis(nowMillis);
      return false;
    } else {
      return true;
    }
  }

  function wantToSendMessage() {
    setTrigger(trigger => trigger + 1);
  }

  useEffect(() => {
    const spam = checkSpam(new Date().getTime());
    setIsSpam(spam);
  }, [trigger]);

  return {
    isSpam,
    wantToSendMessage,
  };
};

export { useSpamDetection };
