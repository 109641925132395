import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleLeftCircleOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M2.5 7.5C2.5 4.73858 4.73858 2.5 7.5 2.5C10.2614 2.5 12.5 4.73858 12.5 7.5C12.5 10.2614 10.2614 12.5 7.5 12.5C4.73858 12.5 2.5 10.2614 2.5 7.5ZM1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5C13.5 10.8137 10.8137 13.5 7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5ZM5.14645 7.85355L8.14645 10.8536L8.85355 10.1464L6.20711 7.5L8.85355 4.85355L8.14645 4.14645L5.14645 7.14645L4.79289 7.5L5.14645 7.85355Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM7.29289 10.7071L10.7929 14.2071L12.2071 12.7929L9.41421 10L12.2071 7.20711L10.7929 5.79289L7.29289 9.29289L6.58579 10L7.29289 10.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM8.29289 12.7071L13.2929 17.7071L14.7071 16.2929L10.4142 12L14.7071 7.70711L13.2929 6.29289L8.29289 11.2929L7.58579 12L8.29289 12.7071Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16ZM3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16ZM10.7929 16.7071L18.2929 24.2071L19.7071 22.7929L12.9142 16L19.7071 9.20711L18.2929 7.79289L10.7929 15.2929L10.0858 16L10.7929 16.7071Z'
          size={size}
          {...rest}
        />
      );
  }
};
