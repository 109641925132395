import ReactionButtons from './reactionButtons';
import { connect } from 'react-redux';
import { PublicChannel as Channel } from '../../../types';
import { getChannelAsPublicChannel, getReactionChannel } from '@store/feedSlice/channelSelectors';
import { publishReaction } from '@store/feedSlice';
import { saveReaction } from '@store/feedSlice';
import { bindActionCreators, Dispatch } from 'redux';
import { getCurrentSubscriber } from '@store/feedSlice/chatSelectors';
import { isChatEnabled } from '@store/serviceSlice/selectors';
import { RootState } from '@store/rootReducer';
import { openReactionBanner, closeReactionBanner } from '@store/uiSlice';
import { isReactionBannerVisible } from '@store/uiSlice/selectors';

interface StateProps {
  channel: Channel;
  subscriberId: string;
  isChatEnabled: boolean;
  isBannerVisible: boolean;
  subscriberHasAccount: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    subscriberId: getCurrentSubscriber(state).id,
    channel: getChannelAsPublicChannel(getReactionChannel(state)),
    isChatEnabled: isChatEnabled(state),
    isBannerVisible: isReactionBannerVisible(state),
    subscriberHasAccount: getCurrentSubscriber(state).email ? true : false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { publishReaction, saveReaction, openReactionBanner, closeReactionBanner },
    dispatch
  );

const VisibleReactionButton = connect(mapStateToProps, mapDispatchToProps)(ReactionButtons);

export type Props = StateProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleReactionButton;
