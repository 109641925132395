import styled from 'styled-components';
import { isChromeForIPad } from '@utils/core';

interface ContainerProps {
  chatIsFocused?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${props => (!!props.chatIsFocused && isChromeForIPad() ? '42' : '0')}px);
  -webkit-text-size-adjust: 100%;
`;

export { Container };
