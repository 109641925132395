import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const TextDocumentIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M3 1H12V12L10 14H3V1ZM9 11V13H9.58579L11 11.5858V11H9ZM11 3H4V4H11V3ZM4 5H10V6H4V5ZM11 7H4V8H11V7Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16 2H4V18H13L16 15V2ZM14 4H6V6H14V4ZM6 8H13V10H6V8ZM12 16V14H14V14.1716L12.1716 16H12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M4 2H20V18L16 22H4V2ZM6 4H11V6H6V4ZM18 9H6V11H18V9ZM6 13H15V15H6V13ZM15 17H18L18 17.1716L15.1716 20H15V17Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M26 2H6V30H21L26 25V2ZM24 24L24 24.1716L20.1716 28H20V24H24ZM8 5H14V7H8V5ZM24 10H8V12H24V10ZM8 14H22V16H8V14ZM24 18H8V20H24V18Z'
          size={size}
          {...rest}
        />
      );
  }
};
