import RemoveAvatar from './removeAvatar';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { saveSubscriber } from '@store/subscriberSlice';
import { getCurrentSubscriberAsPublicSubscriber } from '@store/subscriberSlice/selectors';
import { ModalType, setModalFromType, Modal } from '../dux';
import { getPreviousModal } from '@store/uiSlice/selectors';
import { setModal } from '@store/uiSlice';
import { Dispatch } from 'redux';

interface StateProps {
  subscriberId: string;
  modal: Modal | undefined;
}

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  subscriberId: getCurrentSubscriberAsPublicSubscriber(state).id,
  modal: getPreviousModal(state),
  ...ownProps,
});

interface DispatchProps {
  removeAvatar: (id: string, modal: ModalType) => void;
  openModal: (modal: ModalType) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeAvatar: (id: string, modal: ModalType) => {
    dispatch(saveSubscriber({ id, input: { avatar: '' } }, { keepSubmissionFormAlive: true }));
    dispatch(setModal(setModalFromType(modal)));
  },
  openModal: (modal: ModalType) => dispatch(setModal(setModalFromType(modal))),
});

const VisibleRemoveAvatar = connect(mapStateToProps, mapDispatchToProps)(RemoveAvatar);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleRemoveAvatar;
