import { Meta } from './Meta';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { getCurrentLanguage } from '@store/uiSlice/selectors';

interface StateProps {
  language: string;
}

const mapStateToProps = (state: RootState): StateProps => {
  const language = getCurrentLanguage(state);

  return {
    language,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Meta);
