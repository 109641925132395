import { Flex, getInset } from '@lifechurch/react-ion';
import styled from 'styled-components';

interface Props {
  expanded: boolean;
}

const StyledFlex = styled(Flex)<Props>`
  flex-direction: column;
  position: absolute;
  ${getInset('insetBlockStart', '4px')};
  ${getInset('insetInlineStart', '4px')};
  z-index: 1;
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  width: ${props => (props.expanded ? '320px' : '110px')};
`;

const StyledButton = styled.button`
  width: 110px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
`;

const Wrapper = styled.div`
  padding-inline-start: 16px;
  padding-inline-end: 16px;
`;

export { StyledButton, StyledFlex, Wrapper };
