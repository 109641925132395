import React, { FC } from 'react';
import { Props } from './index';
import { PrayerMoment as BasePrayerMoment } from '@churchonline/moments';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { MomentActionType, MomentType } from '../../../../../../__generated__/globalTypes';
import { selectChatName } from '../../../../../components/modal/dux';
import { SelectChatNameSubtype } from '@components/modal/selectChatName/dux';

const PrayerMoment: FC<Props> = ({
  moment,
  onDismiss,
  isPinned,
  submitMomentAction,
  currentSubscriber,
  isLivePrayerEnabled,
  requestLivePrayer,
  setModal,
}) => {
  const { t } = useTranslation('moments');
  const text =
    moment.momentTemplate.options.text &&
    moment.momentTemplate.options.text !== 'Would you like to pray with someone?'
      ? moment.momentTemplate.options.text
      : t('prayer.text');
  const buttonText =
    moment.momentTemplate.options.buttonText &&
    moment.momentTemplate.options.buttonText !== 'Request Prayer'
      ? moment.momentTemplate.options.buttonText
      : t('prayer.button');

  const handleRequestLivePrayer = debounce(() => requestLivePrayer({ momentId: moment.id }), 500);

  const handleClick = () => {
    submitMomentAction({
      channelKey: moment.channel.key,
      momentInstanceId: moment.id,
      momentType: MomentType.PRAYER,
      momentActionType: MomentActionType.SUBMIT,
    });
    if (isLivePrayerEnabled && currentSubscriber.nickname) {
      handleRequestLivePrayer();
    } else if (!currentSubscriber.nickname) {
      setModal(selectChatName(SelectChatNameSubtype.PRAYER, { momentId: moment.id }));
    }
  };

  return (
    <BasePrayerMoment
      buttonText={buttonText}
      onClick={handleClick}
      text={text}
      onDismiss={onDismiss}
      isPinned={isPinned}
    />
  );
};

export default PrayerMoment;
