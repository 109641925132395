import { connect } from 'react-redux';
import Video from './video';
import { playVideo, pauseVideo, sendFullscreenMetricsEvent } from '@store/videoSlice';
import {
  getVideo,
  showLiveVideo,
  videoStartTime as videoStartTimeSelector,
} from '@store/videoSlice/selectors';
import { RootState } from '@store/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { isOffline } from '@store/serviceSlice/selectors';
import { VideoType } from '../../../__generated__/globalTypes';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { getNextServiceData } from '@store/scheduleSlice/selectors';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';
import { OrganizationFields_offlineContent } from '@io/__generated__/OrganizationFields';
import { isLargeHostVideo } from '@store/uiSlice/selectors';

interface StateProps {
  type: VideoType;
  url: string | null;
  videoStartTime: string | undefined;
  isVideoPlaying: boolean;
  isOffline: boolean;
  showLiveVideo: boolean;
  showOfflineVideo: boolean;
  nextService: ServicesConnectionQuery_currentOrganization_servicesConnection_services;
  offlineContent: OrganizationFields_offlineContent | null;
  largeHostVideo: boolean;
}

interface OwnProps {
  url: string | null;
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
  const { type, isVideoPlaying } = getVideo(state);
  const videoStartTime = videoStartTimeSelector(state);
  const { offlineContent } = getCurrentOrganization(state);

  const isLiveVideo = showLiveVideo(state);
  const showOfflineVideo = !!offlineContent?.url && !isLiveVideo;
  const url = showOfflineVideo ? offlineContent?.url : props.url;

  return {
    type,
    isVideoPlaying,
    videoStartTime,
    url: url ?? null,
    showLiveVideo: isLiveVideo,
    showOfflineVideo,
    isOffline: isOffline(state),
    nextService: getNextServiceData(state),
    offlineContent: offlineContent,
    largeHostVideo: isLargeHostVideo(state),
  };
};

interface DispatchProps {
  sendFullscreenEvent: (enteringFullscreen: boolean) => void;
  pauseVideo: () => void;
  playVideo: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendFullscreenEvent: enteringFullscreen =>
    dispatch(sendFullscreenMetricsEvent(enteringFullscreen)),
  ...bindActionCreators(
    {
      pauseVideo,
      playVideo,
    },
    dispatch
  ),
});

const VisibleVideoFeed = connect(mapStateToProps, mapDispatchToProps)(Video);

export type Props = StateProps & DispatchProps & OwnProps;
export default VisibleVideoFeed;
