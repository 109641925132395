import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthProvider, Subscriber } from '../../types';
import {
  SubscriberInput,
  RevertSubscriberInput,
  TextMode,
} from '../../../__generated__/globalTypes';
import {
  SaveSubscriberMutation_saveSubscriber_errors,
  SaveSubscriberMutationVariables,
} from '@io/__generated__/SaveSubscriberMutation';
import { AuthStatus } from '@io/auth';

interface AcceptInvite {
  token: string;
}

interface UpdateGuestNickname {
  id: string;
  nickname: string;
  action: 'prayer' | 'chat';
  momentId?: string;
}

interface UploadAvatar {
  id: string;
  formData: FormData;
  keepSubmissionFormAlive?: boolean;
}

export interface MuteSubscriberArgs {
  subscriberId: string;
  canceled: boolean;
}

export type SaveSubscriberArgs = Required<SaveSubscriberMutationVariables> & {
  signUpId?: string;
  signUpSource?: string;
  successCallback?: () => void;
};

interface SaveSubscriberMeta {
  guestNickname?: boolean;
  keepSubmissionFormAlive?: boolean;
  profileSettingsUpdated?: boolean;
  acceptedInvite?: boolean;
}

export interface SaveSubscriberSuccessArgs {
  input: SubscriberInput;
  meta?: SaveSubscriberMeta;
}

interface MuteSubscriber {
  subscriberId: string;
  canceled: boolean;
  onSuccess: () => void;
}

interface UnmuteSubscriber {
  subscriberId: string;
  subscriberName: string;
  onSuccess: () => void;
}

interface RevertSubscriber {
  input: RevertSubscriberInput;
}

interface InitialState {
  currentSubscriber: Subscriber;
  provider: AuthProvider | null;
}

export const initialState: InitialState = {
  currentSubscriber: {
    id: '',
    nickname: '',
    firstName: '',
    lastName: '',
    email: '',
    gdpr: false,
    emailConsent: false,
    avatar: '',
    pubnubAuthKey: '',
    role: undefined,
    roleIdentifier: null,
    preferences: {
      textMode: TextMode.COMPACT,
      skinTone: 1,
    },
  },
  provider: null,
};

const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {
    setSubscriber(state, action: PayloadAction<Subscriber>) {
      state.currentSubscriber = action.payload;
    },
    saveSubscriberSuccess: {
      reducer(state, action: PayloadAction<SubscriberInput, string, SaveSubscriberMeta>) {
        state.currentSubscriber = {
          ...state.currentSubscriber,
          ...action.payload,
          id: state.currentSubscriber.id,
          preferences: {
            ...state.currentSubscriber.preferences,
            textMode:
              action.payload.preferences?.textMode || state.currentSubscriber.preferences.textMode,
            skinTone:
              action.payload.preferences?.skinTone || state.currentSubscriber.preferences.skinTone,
          },
        };
      },
      prepare(payload: SubscriberInput, meta: SaveSubscriberMeta) {
        return {
          payload,
          meta,
        };
      },
    },
    setAuthProvider(state, action: PayloadAction<AuthProvider>) {
      state.provider = action.payload;
    },
    setAvatar(state, action: PayloadAction<string>) {
      state.currentSubscriber.avatar = action.payload;
    },
  },
});

export const saveSubscriber = createAction(
  'subscriber/saveSubscriber',
  (payload: SaveSubscriberArgs, meta: SaveSubscriberMeta = {}, successCallback?: () => void) => ({
    payload: { ...payload, successCallback },
    meta,
  })
);

export const revertSubscriber = createAction<RevertSubscriber>('subscriber/revertSubscriber');

export const muteSubscriber = createAction<MuteSubscriber>('subscriber/muteSubscriber');
export const unmuteSubscriber = createAction<UnmuteSubscriber>('subscriber/unmuteSubscriber');
export const uploadAvatar = createAction<UploadAvatar>('subscriber/uploadAvatar');
export const saveSubscriberFailed = createAction<SaveSubscriberMutation_saveSubscriber_errors[]>(
  'subscriber/saveSubscriberFailed'
);
export const updateGuestNickname = createAction<UpdateGuestNickname>(
  'subscriber/updateGuestNickname'
);
export const deleteSelf = createAction('subscriber/deleteSelf');
export const publishRequestPasswordReset = createAction<string>(
  'subscriber/publishRequestPasswordReset'
);
export const publishAcceptInvite = createAction<AcceptInvite>('subscriber/publishAcceptInvite');

// Auth
interface BasicAuth {
  email: string;
  password: string;
}

interface BasicAuthMeta {
  currentSubscriberId: string;
}

export const basicAuthLogin = createAction(
  'subscriber/basicAuthLogin',
  function prepare(payload: BasicAuth, meta: BasicAuthMeta) {
    return {
      payload,
      meta,
    };
  }
);

interface HandleExternalAuth {
  status: AuthStatus;
}

type HandleExternalAuthMeta = BasicAuthMeta;

export const handleExternalAuth = createAction(
  'subscriber/handleExternalAuth',
  function prepare(payload: HandleExternalAuth, meta: HandleExternalAuthMeta) {
    return {
      payload,
      meta,
    };
  }
);

export const loginSuccess = createAction('subscriber/loginSuccess');

export const { setSubscriber, saveSubscriberSuccess, setAuthProvider, setAvatar } =
  subscriberSlice.actions;
export type SubscriberState = ReturnType<typeof subscriberSlice.reducer>;
export default subscriberSlice.reducer;
