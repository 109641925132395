import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Actionable from '@components/Actionable';
import {
  ChurchLogoWrapper,
  ChurchNameWrapper,
  HiddenChurchNameWrapper,
  NavHeaderHamburgerWrapper,
  NavHeaderWrapper,
  ViewerCount,
} from './styles';
import { Props } from './index';
import { MediumUp, Small } from '@utils/responsive';
import NavHeaderLinks from './navHeaderLinks';
import { Background } from 'react-imgix';
import ErrorBoundary, { ErrorBoundaryDisplayType } from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { openSideMenu } from '@store/uiSlice';
import { BarsIcon, EyeIcon, IconSize } from '@lifechurch/react-ion';

interface BrandProps {
  logo: string | null;
  organizationName?: string;
  headerTextColor: string;
  isGuest: boolean;
}

const Brand: FunctionComponent<BrandProps> = ({
  logo,
  organizationName,
  headerTextColor,
  isGuest,
}): ReactElement => {
  const { i18n } = useTranslation();
  if (logo) {
    return (
      <>
        <Background
          src={logo}
          imgixParams={{ fit: 'max', h: 52, auto: 'compress' }}
          htmlAttributes={{
            'data-testid': 'navHeader-logo',
            style: {
              backgroundSize: 'contain',
              backgroundPosition: i18n.dir() === 'rtl' ? 'right center' : 'left center',
              backgroundRepeat: 'no-repeat',
            },
          }}
        >
          <ChurchLogoWrapper isGuest={isGuest} />
        </Background>
        <HiddenChurchNameWrapper>{organizationName}</HiddenChurchNameWrapper>
      </>
    );
  } else {
    return (
      <ChurchNameWrapper customTextColor={headerTextColor} isGuest={isGuest}>
        {organizationName}
      </ChurchNameWrapper>
    );
  }
};

const NavHeader: FunctionComponent<Props> = ({
  logo,
  headerBackgroundColor,
  headerTextColor,
  organizationName,
  channelOccupancy,
  hasLinks,
  isOffline,
  isGuest,
  hideViewershipMetric,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openMenu = useCallback(() => dispatch(openSideMenu()), [dispatch]);

  // The channel occupancy should be shown if:
  // - the organization is online
  // AND
  // - the channel occupancy is non-zero
  // AND either:
  // - the user is not a guest, or
  // - hideViewershipMetric is false
  const shouldShowViewershipMetric =
    !isOffline && channelOccupancy !== 0 && (!isGuest || !hideViewershipMetric);

  return (
    <NavHeaderWrapper
      data-testid='navHeader'
      backgroundColor={headerBackgroundColor}
      isGuest={isGuest}
    >
      <ErrorBoundary displayType={ErrorBoundaryDisplayType.HEADER} altComponentName={t('header')}>
        <Actionable onClick={openMenu}>
          <NavHeaderHamburgerWrapper
            isGuest={isGuest}
            data-testid='navHeader-openMenu'
            aria-label={t('aria.menu')}
            aria-haspopup={true}
          >
            <BarsIcon size={IconSize.XL} color={headerTextColor} />
          </NavHeaderHamburgerWrapper>
        </Actionable>
        <Brand
          headerTextColor={headerTextColor}
          isGuest={isGuest}
          logo={logo}
          organizationName={organizationName}
        />
        {shouldShowViewershipMetric && (
          <Small>
            <ViewerCount headerTextColor={headerTextColor} data-testid='navHeader-occupancy'>
              <EyeIcon />
              {channelOccupancy}
            </ViewerCount>
          </Small>
        )}
        {hasLinks && (
          <MediumUp>
            <NavHeaderLinks />
          </MediumUp>
        )}
      </ErrorBoundary>
    </NavHeaderWrapper>
  );
};

export default NavHeader;
