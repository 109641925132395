import queries from '../queries';
import { call, select } from 'redux-saga/effects';
import { languageChange as languageChangeMetric } from './metrics';
import { i18n } from '../../lib/i18n';
import { SagaIterator } from 'redux-saga';
import { setLanguage as setLanguageAction } from '@store/uiSlice';
import { getChatChannels } from '@store/feedSlice/channelSelectors';

export function* addLanguageToChannels(languageCode: string, channels: string[]): SagaIterator {
  if (channels.length > 0) {
    yield call([queries, queries.addLanguageToChannels], { languageCode, channels });
  }
}

export function* setLanguage(action: ReturnType<typeof setLanguageAction>) {
  if (action.payload.newLanguage !== action.payload.oldLanguage) {
    const channels: string[] = yield select(getChatChannels);
    yield call(addLanguageToChannels, action.payload.newLanguage, channels);
    yield call(
      languageChangeMetric,
      action.payload.newLanguage,
      action.payload.oldLanguage || '',
      i18n.language
    );
  }
}
