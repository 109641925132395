import styled from 'styled-components';
import { variant, space, compose, SpaceProps, TypographyProps, typography } from 'styled-system';
import { logical, LogicalProps } from './logical';

export enum BadgeType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFORMATION = 'INFORMATION',
}

export enum BadgeSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
}

const badgeSizeVariant = variant({
  prop: 'size',
  variants: {
    xs: {
      fontSize: 1,
      padding: '0px, 4px',
    },
    sm: {
      fontSize: 2,
      padding: '3px, 8px',
    },
    md: {
      fontSize: 3,
      padding: '3px, 12px',
    },
  },
});

const badgeTypeVariant = variant({
  prop: 'type',
  variants: {
    ERROR: {
      backgroundColor: 'error.light',
      color: 'error.dark',
    },
    WARNING: {
      backgroundColor: 'warning.light',
      color: 'warning.dark',
    },
    SUCCESS: {
      backgroundColor: 'success.light',
      color: 'success.dark',
    },
    INFORMATION: {
      backgroundColor: 'information.light',
      color: 'information.dark',
    },
  },
});

type BadgeProps = SpaceProps &
  LogicalProps &
  TypographyProps & {
    type: BadgeType;
    size?: BadgeSize;
  };

const Badge = styled.div<BadgeProps>`
  padding: 3px 8px;
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[2]};
  width: fit-content;
  border-radius: ${props => props.theme.radii[1]}px;
  ${compose(space, logical, typography, badgeTypeVariant, badgeSizeVariant)}
`;

export { Badge };
