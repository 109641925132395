import React, { useMemo } from 'react';
import { Wrapper } from './styles';
import CircleHeart from '../icons/CircleHeart';
import CircleClap from '../icons/CircleClap';
import CircleRaisedHands from '../icons/CircleRaisedHands';
import { Props } from './index';
import { ReactionType } from '../dux';
import Heart from '../icons/Heart';
import Clap from '../icons/Clap';
import RaisedHands from '../icons/RaisedHands';

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function renderReaction(
  reactionType: ReactionType,
  skinTone: number,
  removeReaction: () => void
): React.ReactElement {
  switch (reactionType) {
    case ReactionType.HEART:
      return (
        <>
          <CircleHeart />
          <Heart onAnimationEnd={removeReaction} />
        </>
      );
    case ReactionType.CLAP:
      return (
        <>
          <CircleClap />
          <Clap tone={skinTone} onAnimationEnd={removeReaction} />
        </>
      );
    case ReactionType.RAISED_HANDS:
      return (
        <>
          <CircleRaisedHands />
          <RaisedHands tone={skinTone} onAnimationEnd={removeReaction} />
        </>
      );
  }
}

const SelfReaction: React.FunctionComponent<Props> = ({ reaction, removeReaction }) => {
  const [reactionType, tone = '1'] = reaction.type.split('::') as [ReactionType, string];
  const skinTone = parseInt(tone);

  const startY = getRandomInt(-100, -140);
  const {
    firstStageEndingPosition,
    firstStageDuration,
    explodeRadius,
    secondStageEndingPosition,
    secondStageDuration,
    insetInlineEnd,
  } = useMemo(
    () => ({
      firstStageEndingPosition: startY,
      firstStageDuration: getRandomInt(750, 900),
      explodeRadius: getRandomInt(45, 60),
      secondStageEndingPosition: startY + getRandomInt(-200, -250),
      secondStageDuration: getRandomInt(1700, 2000),
      insetInlineEnd: Math.floor(Math.random() * 16) + 35,
    }),
    []
  );

  return (
    <>
      <Wrapper
        firstStageEndingPosition={firstStageEndingPosition}
        firstStageDuration={firstStageDuration}
        explodeRadius={explodeRadius}
        secondStageEndingPosition={secondStageEndingPosition}
        secondStageDuration={secondStageDuration}
        insetInlineEnd={insetInlineEnd}
        data-testid='self-reaction'
      >
        {renderReaction(reactionType, skinTone, () => removeReaction(reaction.id))}
      </Wrapper>
    </>
  );
};

export default React.memo<Props>(SelfReaction);
