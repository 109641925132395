import { createAction } from '@reduxjs/toolkit';
import { MomentInstanceInput } from '../../../__generated__/globalTypes';

export const postMoment = createAction('metrics/postMoment', (payload: MomentInstanceInput) => ({
  payload,
}));

export const salvationMomentConnect = createAction(
  'metrics/salvationMomentConnect',
  (momentInstanceId: string) => ({ payload: { momentInstanceId } })
);

export const salvationMomentFollowUpDismiss = createAction(
  'metrics/dismissSalvationMomentFollowUp',
  (momentInstanceId: string) => ({ payload: { momentInstanceId } })
);
