import { ActionWithPayload, ActionWithPayloadAndMeta } from '../types';
import { MessageEvent, HistoryMessage, SignalEvent } from 'pubnub';
import { PublicChannel as Channel } from '../types';

export interface State {
  language: string;
  applicationDomain: string;
}

export interface LoadHistoryProps {
  channel: Channel;
  messages: Array<HistoryMessage>;
}

const PUBNUB_PUBLISH_FAILED = 'PUBNUB_PUBLISH:FAILED';
const LOAD_HISTORY = 'LOAD_HISTORY';
const ON_OBJECT = 'ON_OBJECT';
const ON_SIGNAL = 'ON_SIGNAL';

export type PubnubPublishFailed = ActionWithPayload<typeof PUBNUB_PUBLISH_FAILED, string>;
export type LoadHistory = ActionWithPayload<typeof LOAD_HISTORY, LoadHistoryProps>;
export type OnObject = ActionWithPayloadAndMeta<
  typeof ON_OBJECT,
  MessageEvent,
  { fromHistory: boolean }
>;
export type OnSignal = ActionWithPayload<typeof ON_SIGNAL, SignalEvent>;

// Action Creators
const loadHistory = (payload: LoadHistoryProps): LoadHistory => ({
  type: LOAD_HISTORY,
  payload,
});

const onObject = (payload: MessageEvent, fromHistory: boolean): OnObject => ({
  type: ON_OBJECT,
  payload,
  meta: {
    fromHistory,
  },
});

const onSignal = (payload: SignalEvent): OnSignal => ({
  type: ON_SIGNAL,
  payload,
});

export { PUBNUB_PUBLISH_FAILED, LOAD_HISTORY, ON_OBJECT, ON_SIGNAL };

export { loadHistory, onObject, onSignal };
