import React, { FunctionComponent } from 'react';

const Globe: FunctionComponent = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11.5 15.811C12.4364 15.57 13.2844 15.1085 13.9853 14.4853L13 13.5H11.5L9.5 12.5L9 11.5L9.5 11H10.5L11 12H11.5V9.5L13.9592 7.45067C14.2236 7.23031 14.1915 6.8149 13.8964 6.63781L12 5.5L11 7.5L9.5 6.5L10.5 5L9.23165 4.04874C7.38671 4.28457 5.80368 5.35831 4.87627 6.87627L5.5 7.5V10L8.5 14L11.5 14.5V15.811Z'
      fill='#9F9FA0'
    />
  </svg>
);

export default Globe;
