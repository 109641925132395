import ReactGA4 from 'react-ga4';
import { InitOptions, UaEventOptions } from 'react-ga4/types/ga4';
import { CurrentState_currentOrganization_integrations_GoogleAnalytics4 } from '@io/__generated__/CurrentState';
import { setSubscriber } from '@store/subscriberSlice';
import { PLATFORM_GA_4_TRACKING_ID } from '../../../constants';
import { Subscriber } from '../../../types';
// import { InitOptions } from 'i18next';

// platform tracker is always included
const trackerNames: Array<string> = ['platform'];

export function initGoogleAnalytics4(
  currentSubscriber: Subscriber,
  integration?: CurrentState_currentOrganization_integrations_GoogleAnalytics4
) {
  const trackers: Array<InitOptions> = [
    {
      trackingId: PLATFORM_GA_4_TRACKING_ID as string,
      gaOptions: {
        name: 'platform',
        userId: currentSubscriber.id,
      },
      gtagOptions: {
        groups: 'platform',
      },
    },
    {
      trackingId: PLATFORM_GA_4_TRACKING_ID as string,
      gaOptions: {
        name: 'platform_sampled',
        userId: currentSubscriber.id,
        sampleRate: 10,
      },
      gtagOptions: {
        groups: 'platform_sampled',
      },
    },
  ];

  if (integration) {
    trackers.push({
      trackingId: integration.config.trackingId,
      gaOptions: {
        name: 'organization',
        userId: currentSubscriber.id,
      },
      gtagOptions: {
        groups: 'organization',
      },
    });

    if (!trackerNames.includes('organization')) {
      trackerNames.push('organization');
    }
  }

  if (currentSubscriber.emailConsent === false) {
    ReactGA4.set({
      anonymizeIp: true,
    });
  }

  ReactGA4.initialize(trackers);
}

export function setGoogleAnalytics4User(action: ReturnType<typeof setSubscriber>) {
  ReactGA4.ga((tracker: { get: (key: string) => any }) => {
    const userId = tracker.get('userId');
    if (action.payload.id !== userId) {
      const anonymizeIp = action.payload.emailConsent === false;
      ReactGA4.set({ userId: action.payload.id, anonymizeIp });
    }
  });
}

export enum GA4EventCategory {
  MOMENTS = 'Moments',
  USERS = 'Users',
  PRAYER = 'Live Prayer',
  CHAT_MESSAGE = 'Chat Message',
}

interface GA4EventArgs extends UaEventOptions {
  category: GA4EventCategory;
}

export function sendGoogleAnalytics4Event(args: GA4EventArgs, names: Array<string> = trackerNames) {
  ReactGA4.event(args.category, { ...args, send_to: names });
}
