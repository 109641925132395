import { connect } from 'react-redux';
import ResetPassword from './resetPassword';
import { saveSubscriber } from '@store/subscriberSlice';
import { Subscriber } from '../../../types';
import { getCurrentSubscriber } from '@store/feedSlice/chatSelectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface StateProps {
  currentSubscriber: Subscriber;
}
interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  ...ownProps,
  currentSubscriber: getCurrentSubscriber(state),
});

interface DispatchProps {
  resetPassword: (id: string, password: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPassword: (id: string, password: string) =>
    dispatch(saveSubscriber({ id, input: { password } })),
});

const VisibleResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export type Props = OwnProps & DispatchProps & StateProps;
export default VisibleResetPassword;
