import InviteMomentShareSheet from './inviteMomentShareSheet';
import { connect } from 'react-redux';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';

interface StateProps {
  organizationName: string;
}

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  organizationName: getCurrentOrganization(state).name,
  ...ownProps,
});

const VisibleInviteMomentShareSheet = connect(mapStateToProps)(InviteMomentShareSheet);

export type Props = StateProps & OwnProps;
export default VisibleInviteMomentShareSheet;
