import dayjs from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServicesConnectionQuery_currentOrganization_servicesConnection_services } from '@io/__generated__/ServicesConnectionQuery';

interface InitialState {
  items: Array<ServicesConnectionQuery_currentOrganization_servicesConnection_services>;
  timezone: string;
}

export const initialState: InitialState = {
  items: [],
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedule(
      state,
      action: PayloadAction<
        ServicesConnectionQuery_currentOrganization_servicesConnection_services[]
      >
    ) {
      state.items = action.payload;
    },
    popSchedule(state) {
      const now = Date.now();
      state.items = state.items.filter(item => dayjs(item.endTime).isAfter(dayjs(now)));
    },
    setScheduleTimezone(state, action: PayloadAction<string>) {
      state.timezone = action.payload;
    },
  },
});

export const { setSchedule, popSchedule, setScheduleTimezone } = scheduleSlice.actions;
export type ScheduleState = ReturnType<typeof scheduleSlice.reducer>;
export default scheduleSlice.reducer;
