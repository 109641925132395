import React, { FC, MouseEvent, ReactNode, useRef, useState, useEffect } from 'react';
import { useOnClickOutside } from '@churchonline/hooks';
import { usePopper } from 'react-popper';
import { ButtonWrapper } from './ButtonWrapper';
import { PopperTray } from './PopperTray';
import { DropdownList } from './DropdownList';

export type PopperOptions = Parameters<typeof usePopper>[2];

interface Props {
  referenceContent: ReactNode;
  popperContent: ReactNode;
  popperOptions?: PopperOptions;
  onShow?: () => void;
  onHide?: () => void;
}

export const Dropdown: FC<Props> = ({
  referenceContent,
  popperContent,
  popperOptions,
  onShow,
  onHide,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const referenceElement = useRef<HTMLElement | null>(null);
  const popperElement = useRef<HTMLElement | null>(null);
  const onShowCallback = useRef<typeof onShow>();
  const onHideCallback = useRef<typeof onHide>();

  useEffect(() => {
    onShowCallback.current = onShow;
  }, [onShow]);

  useEffect(() => {
    onHideCallback.current = onHide;
  }, [onHide]);

  const hide = () => setVisible(false);
  const toggle = () => setVisible(v => !v);

  const handleDropdownClick = (e: MouseEvent) => {
    e.preventDefault();
    toggle();
  };

  useOnClickOutside([popperElement, referenceElement], () => {
    if (visible) {
      hide();
    }
  });

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    popperOptions
  );

  const popoverStyles: React.CSSProperties = {
    ...styles.popper,
    visibility: visible ? 'visible' : 'hidden',
    pointerEvents: visible ? 'auto' : 'none',
    zIndex: 999,
  };

  useEffect(() => {
    if (visible) {
      onShowCallback.current?.();
    } else {
      onHideCallback.current?.();
    }
  }, [visible]);

  return (
    <>
      <PopperTray
        ref={ref => (popperElement.current = ref)}
        style={popoverStyles}
        {...attributes.popper}
      >
        <DropdownList>{popperContent}</DropdownList>
      </PopperTray>

      <ButtonWrapper
        ref={ref => (referenceElement.current = ref)}
        isDropdownOpen={visible}
        onClick={handleDropdownClick}
      >
        {referenceContent}
      </ButtonWrapper>
    </>
  );
};
