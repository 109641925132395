import React, { useState } from 'react';
import { Preview } from '../index';
import { Box } from '@lifechurch/react-ion';
import { ContainedModal } from './ContainedModal';
import { QuoteSharesheet } from '../../sharesheet/quote-moment';
import { QuoteMoment } from '../../quote';
export const QuoteMomentPreview = ({ text, attribution, isPlaceholder, }) => {
    const [isPinned, setIsPinned] = useState(true);
    const [likeCount, setLikeCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (React.createElement(Preview, null,
        isModalOpen && (React.createElement(ContainedModal, { "data-testid": 'quote-preview-modal', handleModalClose: () => setIsModalOpen(false) },
            React.createElement(QuoteSharesheet, { text: text, attribution: attribution, church: '' }))),
        React.createElement(Box, { p: isPinned ? 0 : 3, paddingBlockStart: 0, marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0 },
            React.createElement(QuoteMoment, { isPinned: isPinned, text: text, attribution: attribution, onLike: () => setLikeCount(count => count + 1), onShare: () => setIsModalOpen(true), onDismiss: () => setIsPinned(false), isPlaceholder: isPlaceholder, likeCount: likeCount, mutationObserverTarget: document.body }))));
};
