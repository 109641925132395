import { createGlobalStyle } from 'styled-components';
import { LandscapeMobile } from '@utils/responsive';

const AppStyles = createGlobalStyle`
  body,html {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    webkit-overflow-scrolling: touch; /* momentum scrolling */
    touch-action: manipulation;
    overscroll-behavior: none;
    -ms-scroll-chaining: none;

    @media ${LandscapeMobile} {
      background-color: black;
    }
  }

  // TODO: Move this to react-ion or find a better practice.
  div,
  button,
  input, textarea {
    font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif;
  }

  /* Hide the default Osano widget */
  .osano-cm-widget { display: none; }
`;

export { AppStyles };
