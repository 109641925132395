import Cookies from 'js-cookie';
import { call, delay, fork, put, select } from 'redux-saga/effects';
import queries, { client } from '../queries';
import { ChannelGroup, setChannels, setPubnubKeys } from '@store/feedSlice';
import { setOrganization } from '@store/organizationSlice';
import { ServiceState, setService } from '@store/serviceSlice';
import { getCurrentService } from '@store/serviceSlice/selectors';
import * as Sentry from '@sentry/browser';
import { setVideo } from '@store/videoSlice';
import { setSubscriber } from '@store/subscriberSlice';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { heartbeat, geoData } from './metrics';
import dayjs from 'dayjs';
import { setSequence, startSequence } from '@store/sequenceSlice';
import schedule from './schedule';
import { FetchResult } from '@apollo/client';
import { SaveSubscriberMutation } from '../__generated__/SaveSubscriberMutation';
import {
  CurrentState,
  CurrentState_currentService,
  CurrentState_currentService_sequence,
} from '../__generated__/CurrentState';
import {
  NavigationLinkOption,
  TextMode,
  VideoType,
  RoleIdentifierType,
} from '../../../__generated__/globalTypes';
import { Channel, PublicSubscriber, Subscriber } from '../../types';
import { ErrorCode } from '@features/pane/dux';
import { getOpenNetworkTheme } from '@lifechurch/react-ion';
import { hasServiceBeenFetched } from '@store/uiSlice/selectors';
import { initIntegrations } from './integrations';
import { getSubscriptions, getDirectChannels } from '@store/feedSlice/channelSelectors';
import {
  addChannel as addChannelAction,
  addChannelGroup as addChannelGroupAction,
  removeChannel as removeChannelAction,
  removeChannelGroup as removeChannelGroupAction,
} from '@store/feedSlice';
import { AlertSubtype, welcomeAlert } from '@components/Alert/dux';
import { clearAlertBySubtype, setPaneToError, setShowError } from '@store/uiSlice';
import { getDefaultFontSize } from '@utils/core';

const mapChannel = (channel: Channel) => {
  const subscribers: Array<PublicSubscriber> = [];
  if ('subscribers' in channel) {
    channel.subscribers?.nodes?.forEach(subscriber => {
      if (subscriber) {
        subscribers.push({
          id: subscriber.id,
          nickname: subscriber.nickname || '',
          avatar: subscriber.avatar || null,
          roleIdentifier: {
            label: subscriber.roleIdentifier?.label || '',
            type: subscriber.roleIdentifier?.type || RoleIdentifierType.TEXT,
            key: subscriber.roleIdentifier?.key || '',
          },
        });
      }
    });
  }
  return {
    ...channel,
    messages: {
      ids: [],
      entities: {},
    },
    notifications: {
      ids: [],
      entities: {},
    },
    prayerRequests: {
      ids: [],
      entities: {},
    },
    privateChannelInvites: {
      ids: [],
      entities: {},
    },
    moments: {
      ids: [],
      entities: {},
    },
    objectOrder: [],
    subscribers: subscribers ?? [],
    subscribersTyping: [],
    sawLastMessageAt: new Date().toISOString(),
    statusChangedAt: '',
  };
};

const mapChannelGroup = (channelGroup: Channel): ChannelGroup => ({
  id: channelGroup.id,
  key: channelGroup.key,
  type: channelGroup.type,
  loading: true,
});

function* pubnubKeys(data: CurrentState) {
  if (data.pubnubKeys) {
    const {
      pubnubKeys: { publishKey, subscribeKey },
    } = data;
    yield put(setPubnubKeys({ publishKey, subscribeKey }));
  }
}

function* currentSubscriber(data: CurrentState) {
  const { currentSubscriber } = data;
  let guestNicknameReclaimFailed = false;
  if (currentSubscriber) {
    Sentry.setUser({
      id: currentSubscriber.id,
      email: currentSubscriber.email || undefined,
      username: currentSubscriber.nickname || undefined,
    });

    if (!currentSubscriber.email && currentSubscriber.nickname?.length) {
      // We store a cookie in the format ServiceID:nickname with the nickname as the value.
      // As a performance optimization, we only try to re-claim the guest nickname if there is not
      // a cookie created with the current service ID.
      const currentServiceId = data.currentService.id;
      const serviceNickname = Cookies.get(`${currentServiceId}:nickname`);
      // Make sure that a current service is happening so we don't try to reclaim a guest nickname
      // outside of a service.
      if (!serviceNickname && currentServiceId) {
        // Subscriber is a guest with a nickname, ensure the nickname is still available to them.
        const response: FetchResult<SaveSubscriberMutation> = yield call(
          [queries, queries.saveSubscriber],
          currentSubscriber.id,
          {
            nickname: currentSubscriber.nickname,
          }
        );

        if (response.data?.saveSubscriber?.errors.length !== 0) {
          // If the reclaim fails clear the guests nickname.
          guestNicknameReclaimFailed = true;
        }
      }
    }

    const roleIdentifierType = currentSubscriber?.roleIdentifier?.type
      ? currentSubscriber.roleIdentifier.type
      : RoleIdentifierType.TEXT;
    // use the user's chosen font size if it's a saved preference,
    // otherwise pick the larger font size if the user has increased their default
    const textMode =
      currentSubscriber.email && currentSubscriber?.preferences?.textMode
        ? currentSubscriber?.preferences?.textMode
        : getDefaultFontSize() > 16 // most browsers default font size
        ? TextMode.COMFORTABLE
        : TextMode.COMPACT;
    yield put(
      setSubscriber({
        id: currentSubscriber.id,
        nickname: guestNicknameReclaimFailed ? '' : currentSubscriber.nickname || '',
        avatar: currentSubscriber.avatar || '',
        firstName: currentSubscriber.firstName || '',
        lastName: currentSubscriber.lastName || '',
        email: currentSubscriber.email || '',
        gdpr: currentSubscriber.gdpr || false,
        pubnubAuthKey: currentSubscriber.pubnubAuthKey || '',
        roleIdentifier: currentSubscriber.roleIdentifier
          ? {
              label: currentSubscriber.roleIdentifier.label,
              key: currentSubscriber.roleIdentifier.key,
              type: roleIdentifierType,
            }
          : null,
        role: currentSubscriber.role
          ? {
              label: currentSubscriber.role.label,
              permissions: currentSubscriber.role.permissions.map(permission => permission.key),
              type: currentSubscriber.role.type,
            }
          : undefined,
        preferences: {
          textMode,
          skinTone: currentSubscriber?.preferences?.skinTone,
        },
        emailConsent: currentSubscriber?.emailConsent ?? false,
      })
    );
  }
}

function* organization(data: CurrentState) {
  if (data.currentOrganization) {
    const {
      currentOrganization: {
        id,
        name,
        website,
        termsUrl,
        privacyUrl,
        emailConsent,
        appearance: apiAppearance,
        links,
        offlineContent,
        offlinePrayerRecipients,
        status,
        integrations,
      },
    } = data;
    const theme = getOpenNetworkTheme();
    const appearance = {
      headerBackgroundColor: apiAppearance.headerBackgroundColor || theme.colors.gray5,
      headerTextColor: apiAppearance.headerTextColor || theme.colors.gray50,
      logo: apiAppearance.logo || '',
      hideViewershipMetric: apiAppearance.hideViewershipMetric,
      navigationLinkCase: apiAppearance.navigationLinkCase || NavigationLinkOption.TITLE,
      favicon: apiAppearance.favicon,
    };

    yield put(
      setOrganization({
        id,
        name,
        website,
        appearance,
        links,
        termsUrl,
        privacyUrl,
        emailConsent,
        offlineContent,
        offlinePrayerRecipients,
        status,
        integrations,
      })
    );
  }
}

function* serviceMain(service: CurrentState_currentService) {
  const currentService: ServiceState = yield select(getCurrentService);
  const currentSubscriber: Subscriber = yield select(getCurrentSubscriber);
  const serviceFetched: boolean = yield select(hasServiceBeenFetched);

  // If transitioning to new service and guest attempt nickname reclaim
  if (
    serviceFetched &&
    !currentService.id &&
    !!service?.id &&
    !currentSubscriber.email &&
    currentSubscriber.nickname?.length
  ) {
    // Subscriber is a guest with a nickname, ensure the nickname is still available to them.
    const response: FetchResult<SaveSubscriberMutation> = yield call(
      [queries, queries.saveSubscriber],
      currentSubscriber.id,
      {
        nickname: currentSubscriber.nickname,
      }
    );

    if (response.data?.saveSubscriber?.errors.length === 0) {
      Cookies.set(`${service.id}:nickname`, currentSubscriber.nickname, {
        expires: 1,
      });
    } else {
      // If the reclaim fails clear the guests nickname.
      yield put(
        setSubscriber({
          ...currentSubscriber,
          nickname: '',
        })
      );
    }
  }

  yield put(
    setService({
      id: service?.id || '',
      startTime: service?.startTime || '',
      scheduleTime: service?.scheduleTime || '',
      endTime: service?.endTime || '',
      momentSchedulingEnabled: true,
      content: {
        id: service?.content?.id || '',
        title: service?.content?.title || '',
        hostInfo: service?.content?.hostInfo || '',
        notes: service?.content?.notes || '',
        features: service?.content?.features || {
          publicChat: false,
          livePrayer: false,
        },
        video: service?.content?.video || null,
        videoStartTime: service?.content?.videoStartTime || '',
        hasVideo: service?.content?.hasVideo || false,
      },
    })
  );
}

export function* sequence(sequence: CurrentState_currentService_sequence | null) {
  if (sequence && sequence?.steps?.length > 0) {
    const updatedSequence = {
      ...sequence,
      steps: sequence.steps.filter(step => dayjs(step.transitionTime).isAfter(dayjs())),
    };
    yield put(setSequence(updatedSequence));
    yield put(startSequence());
  }
}

function* channels(channels: Array<Channel> | null) {
  if (channels) {
    yield put(
      setChannels({
        channels: channels.filter(channel => !channel.group).map(channel => mapChannel(channel)),
        channelGroups: channels
          .filter(channel => channel.group)
          .map(channel => mapChannelGroup(channel)),
      })
    );
  }
}

function* video(service: CurrentState_currentService) {
  yield put(
    setVideo({
      type: service?.content?.video?.type || VideoType.NONE,
      url: service?.content?.video?.url || '',
      source: service?.content?.video?.source || '',
    })
  );
}

export function* service(data: CurrentState) {
  const { currentService: service } = data;
  if (service) {
    const { sequence: serviceSequence, feed: serviceFeed } = service;
    yield* serviceMain(service);
    yield* sequence(serviceSequence);
    yield* channels(serviceFeed);
    yield* video(service);
  }
}

function* dispatchData(data: CurrentState) {
  yield* pubnubKeys(data);
  yield* currentSubscriber(data);
  yield* organization(data);
  yield* initIntegrations();
  yield* service(data);
}

function* currentService() {
  try {
    if (client) {
      client.cache.reset();
    }
    yield fork(geoData);
    const result: FetchResult<CurrentState> = yield call([queries, queries.currentState]);
    if (result?.data) {
      yield* dispatchData(result.data);
    }
    if (result.data?.currentOrganization && result.data.currentService.id) {
      const organizationName = result.data.currentOrganization.name;
      if (!result.data?.currentSubscriber?.email) {
        yield delay(2000);
        yield put(welcomeAlert(organizationName));
      } else {
        yield put(clearAlertBySubtype(AlertSubtype.WELCOME));
      }
    }
    yield fork(schedule);
    yield fork(heartbeat);
    yield put(startSequence());
  } catch (error) {
    yield put(setShowError(true));
    yield put(setPaneToError(ErrorCode.FIVE_HUNDRED));
    Sentry.captureException(error);
  }
}

export function* serviceAt(service: CurrentState_currentService) {
  if (service) {
    const { feed: serviceFeed } = service;
    yield* serviceMain(service);
    yield* video(service);

    const subscribedChannels: { key: string; group: boolean }[] = yield select(getSubscriptions);
    const subscribedChannelsKeys = subscribedChannels.map(channel => channel.key);

    if (serviceFeed) {
      const nonDirectChannels = serviceFeed.filter(channel => !channel.direct);
      const directChannels: Array<Channel> = yield select(getDirectChannels);
      const newFeedKeys = [...nonDirectChannels, ...directChannels].map(channel => channel.key);
      // 1. Check for channels we are subscribed to that we no longer need to be
      for (const channel of subscribedChannels) {
        if (channel.key && !newFeedKeys.includes(channel.key)) {
          const removeAction = channel.group
            ? removeChannelGroupAction({ key: channel.key })
            : removeChannelAction({ key: channel.key });
          yield put(removeAction);
        }
      }

      // 2. Add new channels we should subscribe to
      for (const channel of serviceFeed) {
        if (channel.key && !subscribedChannelsKeys.includes(channel.key)) {
          const addAction = channel.group
            ? // Since this is a new channel group, we can default loading to false
              addChannelGroupAction({
                ...mapChannelGroup(channel),
                loading: false,
              })
            : addChannelAction(mapChannel(channel));

          yield put(addAction);
        }
      }
    }
  }
}

export { currentService, dispatchData };
