import { createAction } from '@reduxjs/toolkit';
import { ChangePasswordInput } from '../../../../__generated__/globalTypes';
import { ChangePasswordMutation_changePassword_errors } from '@io/__generated__/ChangePasswordMutation';
import { ModalType } from '../dux';
import { profileSettings } from '../profileSettings/dux';
import { setModal } from '@store/uiSlice';

export interface ChangePasswordMeta {
  errors: ChangePasswordMutation_changePassword_errors[] | null;
}

// Modal
export interface ChangePassword {
  type: ModalType.CHANGE_PASSWORD;
  meta?: ChangePasswordMeta;
}

export const changePassword = (meta?: ChangePasswordMeta): ChangePassword => ({
  type: ModalType.CHANGE_PASSWORD,
  meta,
});

// Redux
// changePasswordFailed is in ui/dux since it actually updates the Store
export const publishChangePassword = createAction<ChangePasswordInput>('ui/publishChangePassword');
export const cancelChangePassword = createAction('ui/cancelChangePassword');
export const openProfileSettings = () => ({
  type: setModal.type,
  payload: profileSettings(),
});
