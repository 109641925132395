import styled from 'styled-components';

export const NoAudience = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 16px;

  p {
    color: ${props => props.theme.colors.gray50};
  }
`;
