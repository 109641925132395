import ResizeObserver from 'resize-observer-polyfill';
import { useEffect, useState, createRef, RefObject } from 'react';

export const useResizeObserver = <T extends Element>({
  defaultWidth = 1,
  defaultHeight = 1,
} = {}): [RefObject<T>, number, number] => {
  const ref = createRef<T>();
  const [width, changeWidth] = useState(defaultWidth);
  const [height, changeHeight] = useState(defaultHeight);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        const entry = entries[0];

        changeWidth(entry.contentRect.width);
        changeHeight(entry.contentRect.height);
      });

      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, []);

  return [ref, width, height];
};
