import { createSelector } from 'reselect';
import {
  Channel,
  ChannelGroup,
  FeedState,
  messagesAdapter,
  momentsAdapter,
  notificationsAdapter,
  PrayerRequest,
  prayerRequestsAdapter,
  privateChannelInvitesAdapter,
} from '@store/feedSlice';
import { RootState } from '@store/rootReducer';
import { RequestChannelQuery_requestChannel } from '@io/__generated__/RequestChannelQuery';
import { Dictionary } from '@reduxjs/toolkit';
import { Message } from '@features/feed/objects/message/dux';
import { PrivateChannelInvite } from '@features/feed/objects/privateChannelInvite/dux';
import { ChannelType } from '../../../__generated__/globalTypes';
import { getCurrentLanguage, getPane } from '../uiSlice/selectors';
import { PaneType } from '@features/pane/dux';
import { Moment } from '@features/feed/objects/moment/dux';
import { Notification } from '@features/feed/objects/notification/dux';
import { FeedObjectMetaData } from '@features/feed/objects/dux';
import { PublicChannel } from '../../types';

const local = (state: RootState) => state.feed || state;

const getChannels = (state: RootState): Record<string, Channel> => local(state).channels || [];

const getChannelGroups = (state: RootState): Record<string, ChannelGroup> =>
  local(state).channelGroups;

const getChannelByKey = (state: RootState, key: string): Channel => getChannels(state)[key];

const getMessageById = (state: RootState, key: string, id: string): Message | undefined =>
  messagesAdapter.getSelectors().selectById(state.feed.channels[key]?.messages, id);

const getPrayerRequestById = (
  state: RootState,
  key: string,
  id: string
): PrayerRequest | undefined =>
  prayerRequestsAdapter.getSelectors().selectById(state.feed.channels[key]?.prayerRequests, id);

const getPrivateChannelInviteById = (
  state: RootState,
  key: string,
  id: string
): PrivateChannelInvite | undefined =>
  privateChannelInvitesAdapter
    .getSelectors()
    .selectById(state.feed.channels[key]?.privateChannelInvites, id);

const getNotificationById = (state: RootState, key: string, id: string): Notification | undefined =>
  notificationsAdapter.getSelectors().selectById(state.feed.channels[key]?.notifications, id);

const momentSubmitted = (state: RootState, id: string): boolean =>
  local(state).momentSubmissions.includes(id);

const momentLiked = (state: RootState, id: string): boolean =>
  local(state).momentLikes.includes(id);

const messageLiked = (state: RootState, id: string): boolean =>
  local(state).messageLikes.includes(id);

const isPendingPrayer = (channel: Channel): boolean =>
  channel.type === ChannelType.prayer && channel?.objectOrder.length === 0;

const hasPendingPrivateChannelInvite = (
  invites: Dictionary<PrivateChannelInvite>,
  channelId: string
): boolean => {
  let hasPending = false;
  for (const key in invites) {
    const value = invites[key];
    if (value?.channel.id === channelId && value.pending) {
      hasPending = true;
    }
  }
  return hasPending;
};

const channelFilter = (
  obj: Record<string, Channel>,
  predicate: (key: string, channel: Channel) => boolean | undefined
): { [key: string]: Channel } => {
  const result = {};
  Object.keys(obj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !predicate(key, obj[key])) {
      // @ts-expect-error
      result[key] = obj[key];
    }
  });
  return result;
};

export const channelSelector = (
  obj: Record<string, Channel>,
  predicate: (key: string, channel: Channel) => boolean
): Channel | undefined => {
  let result: Channel | undefined = undefined;
  Object.keys(obj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(key, obj[key])) {
      result = obj[key];
    }
  });
  return result;
};

const translateMessage = (currentLanguage: string, message: Message): Message => {
  if (!currentLanguage.includes(message.lang) && message.translations) {
    const translation = message.translations.find(translation =>
      currentLanguage.includes(translation.languageCode)
    );
    if (translation && translation.text) {
      return {
        ...message,
        text: translation.text,
      };
    }
  }
  return message;
};

const getChatMessage = (state: RootState, key: string): string => {
  const channel = getChannelByKey(state, key);
  return channel?.chatMessage || '';
};

const getDirectChannels = createSelector<RootState, Record<string, Channel>, Array<Channel>>(
  [getChannels],
  channels => {
    if (channels) {
      const channelsObj = channelFilter(
        channels,
        key => !channels[key].direct || channels[key].placeholderChannel
      );
      return Object.keys(channelsObj).map(channel => channelsObj[channel]);
    }
    return [];
  }
);

const getCurrentChannel = (state: RootState): Channel => {
  const pane = getPane(state);
  return pane.type === PaneType.CHAT
    ? getChannelByKey(state, pane.meta.channelKey)
    : getDirectChannels(state)[0];
};

const getAnchoredMoment = (state: RootState, key: string): Moment | undefined => {
  const channel = getChannelByKey(state, key);
  return channel?.anchoredMoment
    ? momentsAdapter.getSelectors().selectById(channel.moments, channel.anchoredMoment)
    : undefined;
};

const getMomentById = (state: RootState, key: string, id: string): Moment | undefined => {
  const channel = getChannelByKey(state, key);
  return momentsAdapter.getSelectors().selectById(channel?.moments, id);
};

const getObjectOrder = (state: RootState, key: string): Array<FeedObjectMetaData> => {
  const channel = getChannelByKey(state, key);
  return channel?.objectOrder || [];
};

const getMessage = (state: RootState, key: string, id: string): Message | undefined => {
  const message = getMessageById(state, key, id);
  if (message === undefined) {
    return undefined;
  }
  const lang = getCurrentLanguage(state);
  return translateMessage(lang, message);
};

const getChannelAsPublicChannel = (
  channel: Channel | RequestChannelQuery_requestChannel
): PublicChannel => ({
  id: channel?.id,
  key: channel?.key,
  type: channel?.type,
  name: channel?.name,
  direct: channel?.direct,
  group: channel?.group,
});

const pendingPrayer = (state: RootState, key: string): boolean => {
  const channel = getChannelByKey(state, key);
  return channel && isPendingPrayer(channel);
};

const hasPendingPrayerChannel = createSelector<
  RootState,
  Record<string, Channel>,
  Channel | undefined
>([getChannels], channels => {
  const channelsObj = channelFilter(channels, key => channels[key].type !== ChannelType.prayer);
  const channelsArray = Object.keys(channelsObj).map(channel => channelsObj[channel]);
  return channelsArray.find(channel => isPendingPrayer(channel));
});

const getPlaceholderChannels = createSelector<RootState, Record<string, Channel>, Array<Channel>>(
  [getChannels],
  channels => {
    if (channels) {
      const channelsObj = channelFilter(channels, key => !channels[key].placeholderChannel);
      return Object.keys(channelsObj).map(channel => channelsObj[channel]);
    }
    return [];
  }
);

const getTranslateLanguage = createSelector<RootState, string, string>(
  getCurrentLanguage,
  // Google Translate requires ISO 639 format except for Chinese where they need BCP 47
  // https://cloud.google.com/translate/docs/languages
  language => {
    const substringEnd = language.indexOf('-') > -1 ? language.indexOf('-') : language.length;
    return language.includes('zh') ? language : language.substring(0, substringEnd);
  }
);

const getHostChannel = createSelector<RootState, Record<string, Channel>, Channel | undefined>(
  [getChannels],
  channels => channelSelector(channels, key => channels[key].type === ChannelType.host)
);

const getPublicChannel = createSelector<RootState, Record<string, Channel>, Channel | undefined>(
  [getChannels],
  channels => channelSelector(channels, key => channels[key].type === ChannelType.public)
);

const getReactionChannel = createSelector<RootState, Record<string, Channel>, Channel>(
  [getChannels],
  channels =>
    channelSelector(channels, key => channels[key].type === ChannelType.reaction) || channels[0]
);

const getPublicChannelMessage = createSelector<RootState, Channel | undefined, string>(
  [getPublicChannel],
  channel => channel?.chatMessage || ''
);

export const getPublicChannelOccupancy = createSelector<RootState, Channel | undefined, number>(
  [getPublicChannel],
  channel => channel?.occupancy || 0
);

export const getMomentChannelGroup = createSelector<
  RootState,
  Record<string, ChannelGroup>,
  ChannelGroup | undefined
>([getChannelGroups], channelGroups => {
  const [momentChannelGroup] = Object.keys(channelGroups).filter(channelGroup =>
    channelGroup.startsWith('moment')
  );
  return channelGroups[momentChannelGroup];
});

const pendingPrivateChannelInvite = (state: RootState, channelKey: string): boolean => {
  const channelId = getChannelByKey(state, channelKey)?.id;
  const invites = getHostChannel(state)?.privateChannelInvites;
  return invites && channelId ? hasPendingPrivateChannelInvite(invites.entities, channelId) : false;
};

const getChatChannels = createSelector<RootState, Record<string, Channel>, string[]>(
  [getChannels],
  channels => Object.keys(channels).filter(key => key.startsWith('chat'))
);

const getSubscriptions = createSelector<RootState, FeedState, { key: string; group: boolean }[]>(
  [local],
  state =>
    Object.keys(state.channels)
      .map(key => ({ key, group: false }))
      .concat(Object.keys(state.channelGroups).map(key => ({ key, group: true })))
);

export {
  getAnchoredMoment,
  getChannelAsPublicChannel,
  getChannelByKey,
  getSubscriptions,
  getChannels,
  getChatChannels,
  getChatMessage,
  getCurrentChannel,
  getDirectChannels,
  getHostChannel,
  getMessage,
  getMomentById,
  getNotificationById,
  getObjectOrder,
  getPlaceholderChannels,
  getPrayerRequestById,
  getPrivateChannelInviteById,
  getPublicChannel,
  getPublicChannelMessage,
  getReactionChannel,
  getTranslateLanguage,
  hasPendingPrayerChannel,
  momentLiked,
  messageLiked,
  momentSubmitted,
  pendingPrayer,
  pendingPrivateChannelInvite,
  translateMessage,
};
