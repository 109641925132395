import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { defaultColors } from './theme';
import { OXSmallParagraph } from './Typography';

interface Props {
  percentComplete: number;
  color?: string;
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: end;
`;

const BackgroundBar = styled.div`
  background-color: ${defaultColors.gray30};
  height: 8px;
  width: calc(100% - 4px);
  border-radius: 4px;
`;

interface ProgressProps {
  percentageComplete: number;
  color?: string;
}

const Progress = styled.div<ProgressProps>`
  background-color: ${props => (props.color ? props.color : defaultColors.success.default)};
  height: 8px;
  width: calc(${props => props.percentageComplete}% - 4px);
  transition: width 250ms ease-out;
  border-radius: 4px;
  position: absolute;
`;

const ProgressBar: FunctionComponent<Props> = ({ percentComplete, color }) => {
  return (
    <Wrapper>
      <Progress percentageComplete={percentComplete} color={color} />
      <BackgroundBar />
      <OXSmallParagraph marginBlockStart={4} color={defaultColors.gray100}>
        {percentComplete}%
      </OXSmallParagraph>
    </Wrapper>
  );
};

export { ProgressBar };
