import { getInset, OParagraph } from '@lifechurch/react-ion';
import { PortraitKeyboardOpenXLargeDown } from '@utils/responsive';
import styled from 'styled-components';

const ChatInputs = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  width: 100%;
  padding: 8px;
  transition: width 300ms ease-out;
`;

const ChatInputPlaceholder = styled.div`
  height: 58px;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 0 16px;
`;

const PlaceholderText = styled(OParagraph)`
  margin-block-start: 8px;
  color: ${props => props.theme.colors.gray50};
`;

const LivePrayerWrapper = styled.div`
  position: absolute;
  ${getInset('insetBlockStart', '8px')};
  ${getInset('insetInlineEnd', '8px')};
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: 16px;
  z-index: 5;

  @media ${PortraitKeyboardOpenXLargeDown} {
    display: none;
  }
`;

export const ChatInputSkeletonWrapper = styled.div<{ isPublic: boolean }>`
  width: ${props => (props.isPublic ? 'calc(100% - 144px)' : '100%')};
`;

export const ChatInputSkeleton = styled.div`
  height: 40px;
  border-radius: 24px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.gray5};
  border: 1px solid transparent;
`;

export const ReactionSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(246, 246, 246);
  border-radius: 24px;
  width: 136px;
  height: 40px;
  position: absolute;
  ${getInset('insetInlineEnd', '8px')};
`;

export { ChatInputs, ChatInputPlaceholder, PlaceholderWrapper, PlaceholderText, LivePrayerWrapper };
