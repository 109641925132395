import React, { FunctionComponent } from 'react';
import HostMediumLayout from './layouts/host/medium';
import HostLargeLayout from './layouts/host/large';
import HostXlargeLayout from './layouts/host/xlarge';
import GuestLargeUpLayout from './layouts/guest/largeUp';
import BaseLayout from './layouts';
import {
  Small,
  Medium,
  MediumPlus,
  MediumPlusDown,
  Large,
  XlargeUp,
  LargeUp,
} from '@utils/responsive';
import { getModal } from '@io/modal';
import { OrganizationStatus } from '../../__generated__/globalTypes';
import FourZeroFourPage from './FourZeroFourPage';
import { Props } from './index';
import Alerts from '@components/Alert';
import Meta from './Meta';
import { getAuthStatus } from '@io/auth';
import { ModalType } from '@components/modal/dux';
import { getAlert } from '@io/alert';
import { AlertSubtype } from '@components/Alert/dux';
import { useWebVitals } from '@hooks/useWebVitals';
import { useAppDispatch } from '@hooks/redux/hooks';
import { sendWebVitalMetric } from '@store/uiSlice';
import styles from './app.module.css';

const ChopContainer: FunctionComponent<Props> = ({
  setModalFromUrl,
  setAlertFromUrl,
  handleAuth,
  organizationName,
  organizationStatus,
  organizationExists,
  organizationFavicon,
  hostViewingAsHost,
  currentSubscriberId,
}) => {
  const dispatch = useAppDispatch();

  useWebVitals(data =>
    dispatch(
      sendWebVitalMetric({
        id: data.id,
        name: data.name,
        value: data.value,
      })
    )
  );

  const modal: ModalType | null = getModal();
  const authStatus = getAuthStatus();
  const alert: AlertSubtype | null = getAlert();

  if (alert) {
    setAlertFromUrl(alert);
  } else if (modal) {
    setModalFromUrl(modal);
  } else if (authStatus) {
    // Consume the query param and the hash
    history.pushState('', document.title, window.location.pathname);
    handleAuth(authStatus, currentSubscriberId);
  }

  if (
    !organizationExists ||
    organizationStatus === OrganizationStatus.REJECTED ||
    organizationStatus === OrganizationStatus.DEACTIVATED
  ) {
    return (
      <>
        <Meta name={organizationName} faviconUrl={organizationFavicon} />
        <FourZeroFourPage />
      </>
    );
  } else if (hostViewingAsHost) {
    return (
      <div className={styles.wrapper} id='wrapper' data-testid='chop'>
        <Meta name={organizationName} faviconUrl={organizationFavicon} />
        <Alerts />
        <Small>
          <BaseLayout />
        </Small>
        <Medium>
          <HostMediumLayout />
        </Medium>
        <MediumPlus>
          <HostLargeLayout />
        </MediumPlus>
        <Large>
          <HostLargeLayout />
        </Large>
        <XlargeUp>
          <HostXlargeLayout />
        </XlargeUp>
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper} id='wrapper' data-testid='chop'>
        <Meta name={organizationName} faviconUrl={organizationFavicon} />
        <Alerts />
        <MediumPlusDown>
          <BaseLayout />
        </MediumPlusDown>
        <LargeUp>
          <GuestLargeUpLayout />
        </LargeUp>
      </div>
    );
  }
};

export default React.memo(ChopContainer);
