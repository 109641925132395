import Alert from './Alerts';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AlertInterface } from './dux';
import { getCurrentSubscriber } from '@store/subscriberSlice/selectors';
import { getCurrentOrganization } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';
import { getAlerts } from '@store/uiSlice/selectors';
import { clearAlert } from '@store/uiSlice';

interface StateProps {
  alerts: AlertInterface[];
  currentSubscriberNickname: string;
  currentOrganizationName: string;
}

interface OwnProps {
  fullWidth?: boolean;
  containerId?: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const { fullWidth = false, containerId } = ownProps;

  return {
    alerts: getAlerts(state).filter(alert => alert.containerId === containerId),
    currentSubscriberNickname: getCurrentSubscriber(state).nickname || '',
    currentOrganizationName: getCurrentOrganization(state).name,
    fullWidth,
  };
};

interface DispatchProps {
  dismissAlert: (uniqueKey: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissAlert: (uniqueKey: string) => dispatch(clearAlert(uniqueKey)),
});

const VisibleAlerts = connect(mapStateToProps, mapDispatchToProps)(Alert);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleAlerts;
