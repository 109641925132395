import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentState_currentService_content_video } from '@io/__generated__/CurrentState';
import { VideoType } from '../../../__generated__/globalTypes';

interface InitialState extends CurrentState_currentService_content_video {
  isVideoPlaying: boolean;
}

export const initialState: InitialState = {
  type: VideoType.NONE,
  url: '',
  isVideoPlaying: false,
  source: '',
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideo(_state, action: PayloadAction<CurrentState_currentService_content_video>) {
      return { ...action.payload, isVideoPlaying: false };
    },
    playVideo(state) {
      state.isVideoPlaying = true;
    },
    pauseVideo(state) {
      state.isVideoPlaying = false;
    },
  },
});

export const sendFullscreenMetricsEvent = createAction<boolean>('sendFullscreenEvent');

export const { setVideo, playVideo, pauseVideo } = videoSlice.actions;
export type VideoState = ReturnType<typeof videoSlice.reducer>;
export default videoSlice.reducer;
