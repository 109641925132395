import React from 'react';
import { Avatar, Subscriber } from '../Avatar/Avatar';
import { AvatarSizeType } from '../Avatar/types';
import { AvatarContainer } from './AvatarContainer';
import { Container } from './Container';

type Props = { subscribers: Subscriber[] };

function TwoHostLayout({ subscribers }: Props) {
  return (
    <Container height='56px' width='104px'>
      <AvatarContainer top='0px' left='0px' size={AvatarSizeType.LARGE}>
        <Avatar size={AvatarSizeType.LARGE} subscriber={subscribers[0]} />
      </AvatarContainer>

      <AvatarContainer top='3px' left='64px' size={AvatarSizeType.MEDIUM}>
        <Avatar size={AvatarSizeType.MEDIUM} subscriber={subscribers[1]} />
      </AvatarContainer>
    </Container>
  );
}

export { TwoHostLayout };
