import React, { useEffect, useState } from 'react';
import { CallToAction } from './CallToAction';
import { NewTab } from './NewTab';
import { RequestPrayer } from './RequestPrayer';
export var FollowUpType;
(function (FollowUpType) {
    FollowUpType["NONE"] = "NONE";
    FollowUpType["REQUEST_PRAYER"] = "REQUEST_PRAYER";
    FollowUpType["URL_MODAL"] = "URL_MODAL";
    FollowUpType["URL_NEW_TAB"] = "URL_NEW_TAB";
})(FollowUpType || (FollowUpType = {}));
export var SalvationMomentMode;
(function (SalvationMomentMode) {
    SalvationMomentMode["CTA"] = "CTA";
    SalvationMomentMode["FOLLOW_UP"] = "FOLLOW_UP";
})(SalvationMomentMode || (SalvationMomentMode = {}));
function SalvationMoment({ count, expandToFillArea = false, feedHeight, followUpType, hasSubmitted, hosts, isCustom, isPinned, isPreview, labels, mode: modeFromProps = SalvationMomentMode.CTA, onDismiss, onResponse, onFollowUpResponse, selector, setMode: setModeFromProps, tabIndex, }) {
    const [mode, setMode] = useState(modeFromProps);
    const { CTA, FOLLOW_UP } = SalvationMomentMode;
    const { NONE, REQUEST_PRAYER, URL_NEW_TAB } = FollowUpType;
    const maxWindowHeight = expandToFillArea ? feedHeight - 30 : undefined;
    useEffect(() => {
        if (modeFromProps)
            setMode(modeFromProps);
    }, [modeFromProps]);
    const handleResponse = () => {
        setMode(FOLLOW_UP);
        if (setModeFromProps)
            setModeFromProps(FOLLOW_UP);
        onResponse();
    };
    const handleFollowUpResponse = () => {
        if (isPreview && followUpType === REQUEST_PRAYER)
            return null;
        onFollowUpResponse(mode);
    };
    const handleDismiss = () => {
        setMode(CTA);
        onDismiss(mode);
    };
    if (mode === FOLLOW_UP && followUpType === NONE)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(CallToAction, { count: count, followUpType: followUpType, hasSubmitted: hasSubmitted, isCustom: isCustom, isPinned: isPinned, isPreview: isPreview, labels: labels, mode: mode, onDismiss: handleDismiss, onResponse: handleResponse, tabIndex: tabIndex }),
        mode === FOLLOW_UP && followUpType === REQUEST_PRAYER ? (React.createElement(RequestPrayer, { expandToFillArea: expandToFillArea, hosts: hosts || [], isCustom: isCustom, isPreview: isPreview, labels: labels, maxWindowHeight: maxWindowHeight, onDismiss: handleDismiss, onFollowUpResponse: handleFollowUpResponse, selector: selector })) : null,
        mode === FOLLOW_UP && followUpType === URL_NEW_TAB ? (React.createElement(NewTab, { expandToFillArea: expandToFillArea, isPreview: isPreview, labels: labels, maxWindowHeight: maxWindowHeight, onDismiss: handleDismiss, onFollowUpResponse: handleFollowUpResponse, selector: selector })) : null));
}
export { SalvationMoment };
