import React, { FC } from 'react';
import {
  Box,
  defaultColors,
  Flex,
  FormBanner,
  FormBannerType,
  InfoCircleIcon,
  OParagraph,
} from '@lifechurch/react-ion';
import { AuthCodes, AuthError } from '@io/sagas/auth';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  error: AuthError;
  email: string;
  otherProviders: boolean;
}

const LoginErrors: FC<Props> = ({ error, email, otherProviders }) => {
  const { t } = useTranslation('forms');

  if (error.code === AuthCodes.PROVIDER_DISABLED) {
    return (
      <Box marginBlockEnd={3}>
        <FormBanner type={FormBannerType.Information}>
          <Flex>
            <Box marginInlineEnd={3}>
              <InfoCircleIcon color={defaultColors.gray100} />
            </Box>
            <OParagraph>
              <Trans ns='forms' i18nKey='login.disabled_provider'>
                We&apos;ve made some updates—please reset your password via the instructions sent to{' '}
                <strong>{{ email }}</strong>.
              </Trans>
            </OParagraph>
          </Flex>
        </FormBanner>
      </Box>
    );
  }

  return (
    <Box marginBlockEnd={otherProviders ? 3 : 0}>
      <FormBanner type={FormBannerType.Error}>
        <OParagraph>{t('login.error')}</OParagraph>
      </FormBanner>
    </Box>
  );
};

export default LoginErrors;
