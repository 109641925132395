import React, { FunctionComponent, KeyboardEventHandler } from 'react';
import TextSizeLarge from '@components/icons/textSizeLarge';
import TextSizeSmall from '@components/icons/textSizeSmall';
import { useTranslation } from 'react-i18next';
import { TextMode } from '../../../../__generated__/globalTypes';
import { Props } from './index';
import { Container, HiddenCheckbox, Toggle, ToggleContent, ToggleWrapper } from './styles';

const TextModeSelector: FunctionComponent<Props> = ({
  currentSubscriberId,
  isOpen,
  textMode,
  toggleTextMode,
}) => {
  const { t } = useTranslation('common');
  const checked = textMode === TextMode.COMFORTABLE;
  const toggle = checked ? TextMode.COMPACT : TextMode.COMFORTABLE;
  const handleOnKeyDown: KeyboardEventHandler<HTMLLabelElement> = e => {
    if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
      toggleIt();
    }
  };
  const toggleIt = () => {
    toggleTextMode(currentSubscriberId, toggle);
  };
  return (
    <Container>
      <ToggleWrapper>
        <HiddenCheckbox
          type='checkbox'
          checked={checked}
          id='checkbox'
          onChange={toggleIt}
          aria-checked={checked}
        />
        <Toggle
          htmlFor='checkbox'
          onKeyDown={handleOnKeyDown}
          tabIndex={isOpen ? 0 : -1}
          aria-label={t('aria.text_mode_toggle', {
            checkStatus: checked ? 'checked' : 'not checked',
          })}
          role='switch'
        >
          <ToggleContent checked={checked}>
            {checked ? <TextSizeLarge /> : <TextSizeSmall />}
          </ToggleContent>
        </Toggle>
      </ToggleWrapper>
    </Container>
  );
};

export default React.memo(TextModeSelector);
