import { IconSize } from './icons/types';
import React, { ButtonHTMLAttributes, FC, forwardRef } from 'react';
import { Spinner } from './Spinner';
import styled, { css, useTheme } from 'styled-components';
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  compose,
  LayoutProps,
  layout,
  variant,
} from 'styled-system';
import { logical, LogicalProps } from './logical';
import { Box } from './Box';

export interface ButtonProps {
  size?: ButtonSize;
  pulsing: boolean;
  loading?: boolean;
}

export type BaseButtonProps = SpaceProps &
  TypographyProps &
  BorderProps &
  LogicalProps &
  LayoutProps & {
    prop?: ButtonSize;
    pulsing?: boolean;
    loading?: boolean;
  };

enum ButtonSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const buttonSizeVariant = variant({
  prop: 'size',
  variants: {
    xs: {
      fontSize: 1,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 5,
      paddingBlockEnd: 4,
    },
    sm: {
      fontSize: 2,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 16,
      paddingInlineEnd: 16,
      paddingBlockStart: 7,
      paddingBlockEnd: 7,
    },
    md: {
      fontSize: 3,
      fontWeight: 2,
      lineHeight: 2,
      paddingInlineStart: 24,
      paddingInlineEnd: 24,
      paddingBlockStart: 9,
      paddingBlockEnd: 9,
    },
    lg: {
      fontSize: 4,
      lineHeight: 2,
      paddingInlineStart: 32,
      paddingInlineEnd: 32,
      paddingBlockStart: 14,
      paddingBlockEnd: 15,
    },
    xl: {
      fontSize: 5,
      lineHeight: 2,
      paddingInlineStart: 40,
      paddingInlineEnd: 40,
      paddingBlockStart: 16,
      paddingBlockEnd: 15,
    },
  },
});

type Loader = Pick<LayoutProps, 'size'>;

const getSpinnerSize = ({ size }: Loader): IconSize => {
  switch (size) {
    case ButtonSize.XS:
      return IconSize.SM;
    case ButtonSize.SM:
      return IconSize.SM;
    case ButtonSize.MD:
      return IconSize.MD;
    case ButtonSize.LG:
      return IconSize.LG;
    case ButtonSize.XL:
      return IconSize.XL;
    default:
      return IconSize.MD;
  }
};

const LoaderComponent: FC<Loader> = ({ size }) => {
  const theme = useTheme();
  const spinnerSize = getSpinnerSize({ size });
  return (
    <Box px={7}>
      <Spinner size={spinnerSize} color={theme.colors.gray50} />
    </Box>
  );
};

const BaseButtonComponent = forwardRef<
  HTMLButtonElement,
  BaseButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, loading, size, ...props }, ref) => {
  return (
    <BaseButton ref={ref} disabled={loading} size={size} {...props}>
      {loading ? <LoaderComponent size={size} /> : children}
    </BaseButton>
  );
});
BaseButtonComponent.displayName = 'ForwardedButton';

const BaseButton = styled.button<BaseButtonProps>`
  box-sizing: border-box;
  outline: none;
  border-radius: 999px;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  user-select: none;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    props.pulsing &&
    !props.disabled &&
    css`
      animation: pulse-animation 1.5s infinite;
      box-shadow: 0px 0px 1px 1px var(--pulse-color);

      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px var(--pulse-color);
        }
        50% {
          box-shadow: 0 0 0 20px transparent;
        }
        100% {
          box-shadow: none;
        }
      }
    `}

  ${compose(space, typography, border, logical, layout, buttonSizeVariant)}
`;

BaseButton.defaultProps = {
  size: ButtonSize.MD,
  type: 'button',
};

const FacebookButton = styled(BaseButtonComponent)`
  color: white;
  background-color: ${props => props.theme.colors.brands.facebook};
  border: 1px solid transparent;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding-block-start: 0;
  padding-block-end: 0;
  align-items: center;
  &:focus {
    box-shadow: 0 0 0 1px white inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    opacity: 0.9;
  }
`;

const PrimaryButton = styled(BaseButtonComponent)`
  color: white;
  background-color: ${props => props.theme.colors.action.default};
  border: 1px solid transparent;

  &:focus {
    box-shadow: 0 0 0 1px white inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.action.hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.action.active};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  --pulse-color: ${props => props.theme.colors.action.default};
`;

const PrimaryOnDarkButton = styled(BaseButtonComponent)`
  color: ${props => props.theme.colors.action.default};
  background-color: white;
  border: 1px solid transparent;

  &:focus {
    box-shadow: 0 0 0 1px ${props => props.theme.colors.action.default} inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    color: ${props => props.theme.colors.action.hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.gray10};
    border: 1px solid transparent;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  --pulse-color: white;
`;

const SecondaryButton = styled(BaseButtonComponent)`
  color: ${props => props.theme.colors.action.default};
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.action.defaultTransparent20};

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.action.defaultTransparent20} inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: white;
    border: 1px solid transparent;
  }

  &:active {
    background-color: ${props => props.theme.colors.gray10};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }
`;

const SecondaryOnDarkButton = styled(BaseButtonComponent)`
  color: ${props => props.theme.colors.action.default};
  background-color: white;
  border: 1px solid transparent;

  &:focus {
    box-shadow: 0 0 0 1px ${props => props.theme.colors.action.default} inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    color: ${props => props.theme.colors.action.hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.action.active};
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray80};
    color: ${props => props.theme.colors.gray50};
    box-shadow: ${props => props.theme.shadows[0]};
  }

  --pulse-color: white;
`;

const SecondaryTextOnlyButton = styled(BaseButtonComponent)`
  color: ${({ theme }) => theme.colors.gray50};
  background-color: transparent;
  border: none;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const TertiaryButton = styled(BaseButtonComponent)`
  color: white;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.whiteTransparent20};

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.whiteTransparent20} inset;
    color: white;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: white;
    color: ${props => props.theme.colors.action.hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.action.active};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }
`;

const RiskButton = styled(BaseButtonComponent)`
  color: white;
  background-color: ${props => props.theme.colors.error.default};
  border: 1px solid transparent;

  &:focus {
    box-shadow: 0 0 0 1px white inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.error.hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.error.active};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  --pulse-color: ${props => props.theme.colors.error.default};
`;

const SecondaryRiskButton = styled(BaseButtonComponent)`
  color: ${props => props.theme.colors.error.default};
  background-color: white;
  border: 1px solid
    ${props => (props.pulsing ? 'white' : props.theme.colors.error.defaultTransparent20)};

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.error.defaultTransparent20} inset;
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.error.hover};
    color: white;
    border: 1px solid transparent;
  }

  &:active {
    background-color: ${props => props.theme.colors.error.active};
    color: white;
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.gray50};
    border: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows[0]};
  }

  --pulse-color: white;
`;

export {
  BaseButton,
  FacebookButton,
  PrimaryButton,
  PrimaryOnDarkButton,
  SecondaryButton,
  SecondaryOnDarkButton,
  TertiaryButton,
  SecondaryTextOnlyButton,
  RiskButton,
  SecondaryRiskButton,
  ButtonSize,
};
