import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styled, { Keyframes, keyframes } from 'styled-components';
import { Portal } from '../Portal';

export interface ChatPaneModalProps {
  containerStyle?: CSSProperties;
  expandToFillArea?: boolean;
  maxHeight?: number;
  selector: string;
  visible: boolean;
}

type OuterContainerProps = Pick<ChatPaneModalProps, 'expandToFillArea'>;
enum Fade {
  in = 'in',
  out = 'out',
}

const ANIMATION_DURATION = 350;
const TRANSLATE_Y = '24px';

const generateAnimation = (fadeStyle: Fade): Keyframes => {
  const fadeIn = fadeStyle === Fade.in;
  const startOpacity = fadeIn ? '0%' : '100%';
  const finalOpacity = fadeIn ? '100%' : '0%';
  const startTranslation = fadeIn ? TRANSLATE_Y : '0px';
  const finalTranslation = fadeIn ? '0px' : TRANSLATE_Y;

  return keyframes`
  0% { opacity: ${startOpacity}; transform: translateY(${startTranslation})}
  100% { opacity:${finalOpacity}; transform: translateY(${finalTranslation})}
  `;
};

const OuterContainer = styled.div<OuterContainerProps>`
  position: absolute;
  top: ${props => (props.expandToFillArea ? '0' : undefined)};
  bottom: 0;
  right: 0;
  left: 0;
  padding: 8px;
  z-index: 999;
`;

const Container = styled.div<
  Pick<ChatPaneModalProps, 'expandToFillArea' | 'maxHeight'> & { fade: Fade }
>`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px #00000040;
  padding: 24px;
  width: 100%;

  max-height: ${props => (props.expandToFillArea ? undefined : `${props.maxHeight}px`)};
  height: ${props => (props.expandToFillArea ? '100%' : `${props.maxHeight}px`)};

  animation-name: ${({ fade }) => generateAnimation(fade)};
  animation-duration: ${ANIMATION_DURATION}ms;
  animation-iteration-count: 1;
`;

export const ChatPaneModal: FC<ChatPaneModalProps> = ({
  children,
  containerStyle,
  expandToFillArea = false,
  maxHeight = 500,
  selector,
  visible,
}) => {
  const [showModal, setShowModal] = useState(visible);
  const [fade, setFade] = useState<Fade>(Fade.in);

  useEffect(() => {
    if (visible === true) {
      setFade(Fade.in);
      setShowModal(true);
    } else if (visible === false) {
      setFade(Fade.out);
      setTimeout(() => setShowModal(false), ANIMATION_DURATION);
    }
    return clearTimeout();
  }, [visible]);

  if (!showModal) return null;

  return (
    <Portal selector={selector}>
      <OuterContainer expandToFillArea={expandToFillArea}>
        <Container
          expandToFillArea={expandToFillArea}
          maxHeight={maxHeight}
          fade={fade}
          style={containerStyle ?? undefined}
        >
          {children}
        </Container>
      </OuterContainer>
    </Portal>
  );
};
