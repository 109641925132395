import React from 'react';
import { FunctionComponent } from 'react';

export interface IconProps {
  size?: number;
  height: number;
  width: number;
  color?: string;
  large?: boolean;
}

const LikeMessageIcon: FunctionComponent<IconProps> = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0553 5.94681L10.0367 5.96524L9.59983 6.40211L9.59989 6.40217L5.50192 10.5001L0.948619 5.94684L0.948686 5.94677C-0.1829 4.81515 -0.182887 2.98045 0.948723 1.84884C2.08035 0.717219 3.91507 0.717219 5.04669 1.84884L5.06334 1.86562L5.50186 2.30414L5.95728 1.84872L5.95736 1.8488C7.08899 0.717216 8.92369 0.717228 10.0553 1.84884C11.1869 2.98046 11.1869 4.81519 10.0553 5.94681Z'
        fill={color}
      />
    </svg>
  );
};
export default LikeMessageIcon;
