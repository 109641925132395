import { DefaultTheme } from 'styled-components';
import { Colors } from './';

// Open Network has slightly different action colors, so there are two exports in this file.
// Default Export contains ION Design System blue, named export has the Open Network flavor.

export const defaultColors: Colors = {
  white: '#FFFFFF',
  gray5: '#F6F6F6',
  gray10: '#ECECEC',
  gray30: '#C6C6C6',
  gray50: '#9F9FA0',
  gray80: '#666667',
  gray100: '#404041',
  gray130: '#2D2D2E',
  black: '#000000',
  whiteTransparent20: 'rgba(255, 255, 255, 0.2)',
  overlay50: 'rgba(0, 0, 0, 0.5)',
  overlay90: 'rgba(0, 0, 0, 0.9)',
  action: {
    default: '#009ECC',
    hover: '#00B3E5',
    active: '#008BB2',
    light: '#E6F5FA',
    dark: '#12718D',
    defaultTransparent20: 'rgba(0, 158, 204, 0.2)',
  },
  success: {
    default: '#09C1A1',
    hover: '#23E0BF',
    active: '#01AD8F',
    light: '#E4F8F5',
    dark: '#1B7768',
  },
  error: {
    default: '#E33300',
    hover: '#F5441D',
    active: '#CC2E00',
    light: '#FCEDE8',
    dark: '#B6310C',
    defaultTransparent20: 'rgba(227, 51, 0, 0.2)',
  },
  warning: {
    default: '#FFBA0C',
    hover: '#FFCD4F',
    active: '#EDAA00',
    light: '#FFF6DF',
    dark: '#816520',
  },
  information: {
    default: '#404041',
    hover: '#404041',
    active: '#404041',
    light: '#ECECEC',
    dark: '#404041',
  },
  pink: {
    default: '#E9417C',
    hover: '#EF719D',
    active: '#E9417C',
    light: '#EF719D',
    dark: '#E9417C',
  },
  brands: {
    lifeChurch: '#A6192E',
    open: '#2993E5',
    churchMetrics: '#4CB239',
    chop: '#FF9000',
    developMe: '#764AFF',
    facebook: '#1877F2',
  },
};

const openNetworkColors: Colors = {
  ...defaultColors,
  action: {
    default: '#2993E5',
    hover: '#4FACF4',
    active: '#1C80CE',
    light: '#E5F2FC',
    dark: '#2B6A9C',
    defaultTransparent20: 'rgba(41, 147, 229, 0.2)',
  },
};

const theme = (colors: Colors): DefaultTheme => ({
  colors,
  shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.25)',
    '0px 2px 6px rgba(0, 0, 0, 0.25)',
    '0px 4px 12px rgba(0, 0, 0, 0.25)',
    '0px 8px 24px rgba(0, 0, 0, 0.25)',
    '0px 12px 36px rgba(0, 0, 0, 0.25)',
  ],
  breakpoints: ['640px', '1024px', '1200px'],
  radii: [0, 2, 4, 8],
  fontSizes: [
    '0rem',
    '0.67rem',
    '0.84rem',
    '1rem',
    '1.25rem',
    '1.56rem',
    '1.95rem',
    '2.44rem',
    '3.05rem',
    '3.81rem',
  ],
  fontWeights: [0, 400, 600, 700, 900],
  lineHeights: [0, 1, 1.25, 1.56, 1.95, 2.44],
  space: [0, 2, 4, 8, 12, 16, 24, 32, 48, 64, 80, 96, 128, 160],
});

export const getDefaultTheme = (): DefaultTheme => theme(defaultColors);
export const getOpenNetworkTheme = (): DefaultTheme => theme(openNetworkColors);
