import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const OpenLockIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M7 6C7 4.34315 8.34315 3 10 3C11.3062 3 12.4175 3.83481 12.8293 5H14.9C14.4367 2.71776 12.419 1 10 1C7.23858 1 5 3.23858 5 6V8H3V18H17V8H16H15H13H12H7V6ZM11.5 12C11.5 12.8284 10.8284 13.5 10 13.5C9.17157 13.5 8.5 12.8284 8.5 12C8.5 11.1716 9.17157 10.5 10 10.5C10.8284 10.5 11.5 11.1716 11.5 12Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M12 3C9.79086 3 8 4.79086 8 7V9H15H16H18H19H21V22H3V9H6V7C6 3.68629 8.68629 1 12 1C14.973 1 17.441 3.16229 17.917 6H15.874C15.4299 4.27477 13.8638 3 12 3ZM14 14C14 14.7403 13.5978 15.3866 13 15.7324V18H11V15.7324C10.4022 15.3866 10 14.7403 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M16 4C12.134 4 9 7.13401 9 11V13H22H23H25H26H28V29H4V13H7V11C7 6.02944 11.0294 2 16 2C20.2832 2 23.8675 4.99202 24.777 9H22.7101C21.8496 6.10851 19.171 4 16 4ZM18.5 19C18.5 20.0252 17.883 20.9062 17 21.292V25H15V21.292C14.117 20.9062 13.5 20.0252 13.5 19C13.5 17.6193 14.6193 16.5 16 16.5C17.3807 16.5 18.5 17.6193 18.5 19Z'
          size={size}
          {...rest}
        />
      );
  }
};
