import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import AvatarPicker from '../../AvatarPicker/AvatarPicker';
import { HttpResponse, post } from '@io/API';

export interface Props {
  subscriber: {
    avatar?: string;
    nickname?: string;
    id?: string;
  };
  onImageUpload: (avatar?: string) => void;
  imageTooLargeError: () => void;
  updateSettingsError: () => void;
}

const TemporaryAvatar: FunctionComponent<Props> = ({
  subscriber,
  onImageUpload,
  imageTooLargeError,
  updateSettingsError,
}) => {
  const [avatarLoading, setAvatarLoading] = useState<boolean>(false);
  const avatar = subscriber.avatar ?? '';

  const nickname: string = subscriber.nickname ?? '';
  const id: string | undefined = subscriber.id ?? undefined;

  const uploadAvatar = async (formData: FormData) => {
    try {
      const { parsedBody: avatar }: HttpResponse<string> = await post(
        '/upload/subscriber/avatar',
        undefined,
        { method: 'POST', body: formData }
      );
      onImageUpload(avatar);
    } catch (error) {
      updateSettingsError();
    }
  };

  const removeAvatar = () => {
    onImageUpload('');
  };

  useEffect(() => {
    setAvatarLoading(false);
  }, [avatar]);

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      // Max file size of 10 MB
      if (event.target.files[0].size / 1000000 > 10) {
        imageTooLargeError();
      } else {
        const formData = new FormData();
        formData.append('avatar', event.target.files[0]);
        uploadAvatar(formData);
        setAvatarLoading(true);
      }
    }
  };

  return (
    <AvatarPicker
      subscriber={{ id, avatar, nickname }}
      avatarLoading={avatarLoading}
      handleAvatarUpload={handleAvatarUpload}
      removeAvatar={removeAvatar}
    />
  );
};

export default TemporaryAvatar;
