import React, { FunctionComponent } from 'react';
import { Wrapper, Title } from './styles';
import { MediumUp } from '@utils/responsive';
import { useTranslation } from 'react-i18next';
import { LinkButton, LinkButtonType, Box, UserPlus } from '@lifechurch/react-ion';
import debounce from 'lodash.debounce';

interface InviteHost {
  invite: () => void;
  pendingInvite: boolean;
}

export interface DirectChatHeaderProps {
  title: string;
  leaveChannel: () => void;
  inviteHost?: InviteHost;
  isGuest: boolean;
}

const DirectChatHeader: FunctionComponent<DirectChatHeaderProps> = ({
  title,
  leaveChannel,
  inviteHost,
  isGuest,
}) => {
  const { t } = useTranslation();
  const handleInviteHost = inviteHost ? debounce(() => inviteHost.invite(), 500) : undefined;
  return (
    <Wrapper data-testid='directChatHeader' isGuest={isGuest}>
      {!isGuest && (
        <MediumUp>
          <Title data-testid='otherSubscribersName'>{title}</Title>
        </MediumUp>
      )}
      <Box marginInlineStart={'auto'} display='flex' flex-direction='row' flexShrink='0'>
        {inviteHost && (
          <LinkButton
            onClick={handleInviteHost}
            keepFocus={false}
            data-testid='invite_host'
            buttonType={
              inviteHost.pendingInvite ? LinkButtonType.SECONDARY : LinkButtonType.PRIMARY
            }
            withIcon
            disabled={inviteHost.pendingInvite}
            marginInlineEnd={6}
          >
            <Box marginInlineEnd={2}>
              <UserPlus />
            </Box>
            {inviteHost.pendingInvite ? t('pending') : t('invite_host')}
          </LinkButton>
        )}
        <LinkButton
          onClick={leaveChannel}
          keepFocus={false}
          data-testid='leave'
          buttonType={LinkButtonType.PRIMARY}
        >
          {t('leave')}
        </LinkButton>
      </Box>
    </Wrapper>
  );
};

export default DirectChatHeader;
