import React, { FC, useRef, useState } from 'react';
import { useLongPress, useOnClickOutside } from '@churchonline/hooks';
import { ReactionType } from '../dux';
import Clap from '../icons/Clap';
import RaisedHands from '../icons/RaisedHands';
import { AccessibilityWrapper, Button } from '../reactionButtons/styles';
import { setReactionBannerStatus } from '../reactions.utils';
import ReactionTray from '../reactionTray';
import { Props } from './index';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';

const ReactionTrayButton: FC<Props> = ({
  changeReactionSkinTone,
  closeReactionBanner,
  currentSubscriberId,
  handleReaction,
  isBannerVisible,
  subscriberHasAccount,
  subscriberSkinTone,
  type,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  //Handles Long Presses to open the tray & clicking to post a reaction
  const onLongPress = (): void => {
    setIsVisible(true);
    isBannerVisible && closeReactionBanner();
  };
  const onClick = (): void => {
    handleReaction(`${type}::${subscriberSkinTone}`);
    setIsVisible(false);
  };
  const longPress = useLongPress({ onLongPress, onClick }, { delay: 400 });

  //Handles Outside Clicks to close the tray
  const handleClickOutside = () => {
    if (isVisible) setIsVisible(false);
  };
  useOnClickOutside(containerRef, handleClickOutside);

  //Changes Skintone for reaction
  const handleChangeSkinTone = (skinTone: number) => {
    changeReactionSkinTone(currentSubscriberId, skinTone);
    setIsVisible(false);
    setReactionBannerStatus(subscriberHasAccount);
  };

  return (
    <Container ref={containerRef}>
      {isVisible && <ReactionTray type={type} changeSkinTone={handleChangeSkinTone} />}
      {type === ReactionType.RAISED_HANDS && (
        <AccessibilityWrapper
          aria-label={t('aria.reaction_raised_hands')}
          onKeyPress={onClick}
          role={'button'}
          tabIndex={0}
          {...longPress}
        >
          <Button data-testid={'reactionButtonRaisedHands'} tabIndex={-1}>
            <RaisedHands tone={subscriberSkinTone} />
          </Button>
        </AccessibilityWrapper>
      )}
      {type === ReactionType.CLAP && (
        <AccessibilityWrapper
          aria-label={t('aria.reaction_clap')}
          onKeyPress={onClick}
          role={'button'}
          tabIndex={0}
          {...longPress}
        >
          <Button data-testid={'reactionButtonClap'} tabIndex={-1}>
            <Clap tone={subscriberSkinTone} />
          </Button>
        </AccessibilityWrapper>
      )}
    </Container>
  );
};

export default React.memo<Props>(ReactionTrayButton);
