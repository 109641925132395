import styled, { css } from 'styled-components';
import { DirectChatAvatar } from '@components/DirectChatAvatar';
import { getAvatarColor } from '@utils/core';
import { getInset } from '@lifechurch/react-ion';

interface WrapperProps {
  expanded: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${props => props.theme.colors.gray10};
  height: 100%;
  position: relative;
  width: ${props => (props.expanded ? '200' : '64')}px;
  box-sizing: border-box;
  transition: width 300ms cubic-bezier(0.7, 0, 0.3, 1);
  overflow: hidden;
`;

const InnerWrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  height: 100%;
  width: ${props => (props.expanded ? '200' : '64')}px;
  padding: 64px 0;
  overflow-y: auto;
`;
const NavMenuHeader = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  opacity: 0.95;
  display: flex;
  position: absolute;
  ${getInset('insetBlockStart', 0)};
  width: 100%;
  z-index: 1;
  align-items: center;
`;
const NavMenuBody = styled.div``;
const NavMenuBodySection = styled.div`
  margin-block-start: 16px;
  margin-block-end: 0;
  margin-inline-start: 4px;
  margin-inline-end: 4px;
`;
const NavMenuFooter = styled.div`
  opacity: 0.95;
  background-color: ${props => props.theme.colors.gray10};
  display: flex;
  position: absolute;
  ${getInset('insetBlockEnd', 0)};
  width: 100%;
`;
const NavMenuAdminLink = styled.a`
  background: #e0e0e0;
  padding-block-start: 7px;
  padding-block-end: 8px;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  font-weight: ${props => props.theme.fontWeights[2]};
  color: ${props => props.theme.colors.gray50};
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 16px;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  display: block;
  border-radius: 23px;
  font-size: ${props => props.theme.fontSizes[2]};

  &:focus,
  &:hover {
    color: ${props => props.theme.colors.gray100};
    text-decoration: none;
    background: ${props => props.theme.colors.gray30};
  }
`;

interface NavMenuIconWrapperProps {
  expanded: boolean;
}

const NavMenuIconWrapper = styled.div<NavMenuIconWrapperProps>`
  width: ${props => (props.expanded ? '52' : '64')}px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover svg {
    path {
      fill: ${props => props.theme.colors.gray100};
    }
  }
`;

interface NavMenuTextWrapperProps {
  selected: boolean;
  uppercase: boolean;
  pipPresent: boolean;
}

const NavMenuTextWrapper = styled.div<NavMenuTextWrapperProps>`
  flex: 1;
  display: flex;
  ${props =>
    props.pipPresent
      ? 'margin: auto 0'
      : 'margin-block-start: auto; margin-block-end: auto; margin-inline-start: 0; margin-inline-end: 15px;'};
  color: ${props => (props.selected ? props.theme.colors.gray100 : props.theme.colors.gray50)};
  font-size: ${props => props.theme.fontSizes[2]};
  font-weight: ${props => props.theme.fontWeights[2]};
  text-align: start;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  word-break: break-word;

  min-width: 0;
  justify-content: space-between;
  align-items: center;
`;

const NavMenuChurchName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray100};
  font-size: ${props => props.theme.fontSizes[2]};
  font-weight: ${props => props.theme.fontWeights[3]};
  padding-inline-end: 16px;
  height: 64px;
  overflow: hidden;
`;

const IconButton = styled.button`
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  line-height: 0;
  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover} inset;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

interface NavMenuButtonProps {
  text: string;
  groupChat?: boolean;
}

const NavMenuButton = styled.button<NavMenuButtonProps>`
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;

  &:focus,
  &:hover {
    background: ${props => props.theme.colors.gray30};
    border-radius: 4px;

    ${NavMenuTextWrapper} {
      color: ${props => props.theme.colors.gray100};
    }

    ${DirectChatAvatar} {
      background-color: ${props =>
        props.text === 'Direct' || props.text === 'Prayer'
          ? getAvatarColor(props.text, 1.0, true)
          : getAvatarColor(props.text, 1.0, false, props.groupChat)};
    }

    svg {
      path {
        fill: ${props => props.theme.colors.gray100};
      }
    }
  }
`;

const PipWrapper = styled.div<{ expanded: boolean }>`
  ${({ expanded }) =>
    expanded
      ? css`
          margin: 12px;
          align-self: center;
        `
      : css`
          position: absolute;
          ${getInset('insetBlockStart', '9px')};
          ${getInset('insetInlineEnd', '8px')};
        `}
`;

const TypingIndicatorIconContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  border-radius: 15px;
  padding: 1.5px 3px;
  width: 21px;
  height: 10px;
  position: absolute;
  ${getInset('insetBlockStart', '28px')};
  ${getInset('insetInlineEnd', '12px')};
`;

const TypingIndicatorIconContainerCompact = styled(TypingIndicatorIconContainer)`
  position: absolute;
  ${getInset('insetBlockStart', '8px')};
  ${getInset('insetInlineEnd', '8px')};
`;

const TextWrapper = styled.div`
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export {
  Wrapper,
  InnerWrapper,
  IconButton,
  NavMenuAdminLink,
  NavMenuButton,
  NavMenuHeader,
  NavMenuBody,
  NavMenuBodySection,
  NavMenuFooter,
  NavMenuIconWrapper,
  NavMenuTextWrapper,
  NavMenuChurchName,
  PipWrapper,
  TypingIndicatorIconContainerCompact,
  TypingIndicatorIconContainer,
  TextWrapper,
};
