import styled, { css } from 'styled-components';

interface ContainerProps {
  isChatEnabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${(props): string => props.theme.colors.gray5};
  border-radius: 24px;
  padding: 0 8px;
  margin-inline-start: ${(props): string => (props.isChatEnabled ? '8px' : 'auto')};
  ${props =>
    !props.isChatEnabled &&
    css`
      margin-block-start: 20px;
    `}
  z-index: 5;
  margin-block-start: auto;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 0;
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.09s ease-in-out;
  }

  &:hover svg {
    transform: scale(1.2);
  }

  &:active svg {
    transform: scale(0.8);
  }
`;

export const AccessibilityWrapper = styled.div`
  &:focus {
    outline: none;
    &:before {
      box-shadow: inset 0 0 0 3px ${props => props.theme.colors.action.hover};
      content: '';
      position: absolute;
      border-radius: 22px;
      width: 40px;
      height: 40px;
    }
  }
`;
