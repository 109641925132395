import React from 'react';
import { Icon } from './styles';
import { getFirstInitial, getAvatarColor } from './util';
import AvatarOverlay from './avatarOverlay';
import { useTheme } from 'styled-components';
const InitialAvatar = ({ disabled, isActionable, nickname, size, tabIndex, subscriberId, }) => {
    const theme = useTheme();
    return (React.createElement(React.Fragment, null,
        isActionable && React.createElement(AvatarOverlay, { size: size }),
        React.createElement(Icon, { "data-testid": `avatar-${nickname}`, isActionable: isActionable, role: 'img', size: size, style: { backgroundColor: disabled ? theme.colors.gray30 : getAvatarColor(subscriberId) }, tabIndex: tabIndex }, getFirstInitial(nickname))));
};
export default React.memo(InitialAvatar);
