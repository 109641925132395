import Service from './service';
import { connect } from 'react-redux';
import { isOffline } from '@store/serviceSlice/selectors';
import { getPublicChannel } from '@store/feedSlice/channelSelectors';
import { Subscriber } from '../../../../types';
import { getReactions } from '../../../reactions/selectors';
import { Reaction } from '../../../reactions/dux';
import { getCurrentSubscriber } from '@store/feedSlice/chatSelectors';
import { RootState } from '@store/rootReducer';
import { Pane as IPane, PaneType } from '../../dux';
import { getPane } from '@store/uiSlice/selectors';
import { sendPaneViewMetric } from '@store/uiSlice';
import { Dispatch } from 'redux';

interface StateProps {
  isOffline: boolean;
  channelKey: string;
  reactions: Array<Reaction>;
  subscriber: Subscriber;
  pane: IPane;
}

interface OwnProps {
  isGuest: boolean;
  guestMediumUp?: boolean;
  paneId?: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const channelKey = getPublicChannel(state)?.key || '';
  const pane = getPane(state);
  return {
    isOffline: isOffline(state),
    channelKey,
    reactions: getReactions(state),
    subscriber: getCurrentSubscriber(state),
    pane,
    ...ownProps,
  };
};

interface DispatchProps {
  sendPaneViewMetricEvent: (pane_id: number, pane_type: PaneType, channel_id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendPaneViewMetricEvent: (pane_id, pane_type, channel_id) =>
    dispatch(sendPaneViewMetric({ pane_id, pane_type, channel_id })),
});

const VisibleEvent = connect(mapStateToProps, mapDispatchToProps)(Service);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleEvent;
