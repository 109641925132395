import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { setLanguage, setModal } from '@store/uiSlice';
import SideMenu from './sideMenu';
import {
  login as loginModal,
  profileSettings as profileSettingsModal,
  signupModal,
} from '@components/modal/dux';
import { getCurrentSubscriber, hasPermissions } from '@store/subscriberSlice/selectors';
import { isHostViewingAsHost } from '@store/uiSlice/selectors';
import { infoAlert } from '@components/Alert/dux';
import { deleteAllCookies } from '@utils/core';
import { Subscriber } from '../../types';
import { getCurrentService } from '@store/serviceSlice/selectors';
import { getCurrentLanguage, getLanguages, isSideMenuOpen } from '@store/uiSlice/selectors';
import { CurrentState_currentOrganization_links } from '@io/__generated__/CurrentState';
import { LanguageOption, closeSideMenu } from '@store/uiSlice';
import { LoginMeta } from '@components/modal/login/dux';
import { SignupMeta } from '@components/modal/signup/dux';
import { getIntegrationTypes } from '@store/organizationSlice/selectors';
import { IntegrationType } from '../../../__generated__/globalTypes';
import { NavigationLinkOption } from '../../../__generated__/globalTypes';
import { resetApp, RootState } from '@store/rootReducer';

interface StateProps {
  isOpen: boolean;
  languageOptions: Array<LanguageOption>;
  organizationName: string;
  links: Array<CurrentState_currentOrganization_links>;
  serviceTitle: string;
  currentSubscriber: Subscriber;
  currentLanguage: string;
  isAuthenticated: boolean;
  hostViewingAsHost: boolean;
  externalLoginUrl?: string;
  linkTextCase: NavigationLinkOption;
  osanoEnabled: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const { name: organizationName } = state.organization;
  const links = state.organization.links;
  const serviceTitle = getCurrentService(state)?.content?.title || '';
  const integrationTypes = getIntegrationTypes(state);
  const linkTextCase = state.organization.appearance.navigationLinkCase;
  const osanoEnabled = state.organization.integrations.find(
    integration => integration.__typename === 'Osano'
  );
  let externalLoginUrl;
  if (integrationTypes.includes(IntegrationType.OPEN_ID_CONNECT)) {
    externalLoginUrl = '/auth/openid_connect';
  } else if (integrationTypes.includes(IntegrationType.LEGACY_AUTH)) {
    externalLoginUrl = '/auth/legacy';
  }

  return {
    isOpen: isSideMenuOpen(state),
    languageOptions: getLanguages(state),
    organizationName,
    links,
    serviceTitle,
    currentSubscriber: getCurrentSubscriber(state),
    currentLanguage: getCurrentLanguage(state),
    isAuthenticated: state.subscriber.currentSubscriber.email !== '',
    hostViewingAsHost:
      hasPermissions(state, ['feed.host.read', 'feed.host.write']) && isHostViewingAsHost(state),
    externalLoginUrl,
    linkTextCase,
    osanoEnabled: Boolean(osanoEnabled),
  };
};

interface DispatchProps {
  close: () => void;
  logout: () => void;
  onSwipe: (direction: string) => void;
  setLanguage: (newLanguage: string, oldLanguage: string) => void;
  login: (meta: LoginMeta) => void;
  signup: (meta?: SignupMeta) => void;
  openProfileModal: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  close: () => dispatch(closeSideMenu()),
  logout: async () => {
    await deleteAllCookies();
    dispatch(resetApp());
    dispatch(infoAlert('logged_out'));
  },
  onSwipe: direction => {
    if (direction === 'left') return dispatch(closeSideMenu());
  },
  setLanguage: (newLanguage, oldLanguage) => dispatch(setLanguage({ newLanguage, oldLanguage })),
  login: meta => dispatch(setModal(loginModal(meta))),
  signup: () => dispatch(setModal(signupModal('side_menu'))),
  openProfileModal: () => {
    dispatch(closeSideMenu());
    dispatch(setModal(profileSettingsModal()));
  },
});

const VisibleSideMenu = connect(mapStateToProps, mapDispatchToProps)(SideMenu);

export type Props = StateProps & DispatchProps;
export default VisibleSideMenu;
