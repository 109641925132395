import { ModalType } from '../dux';

export interface ImageMomentShareSheet {
  type: ModalType.IMAGE_MOMENT_SHARE_SHEET;
  altText: string | null;
  imageUrl: string;
  shareText: string | null;
  title: string;
}

export const imageMomentShareSheet = (
  altText: string | null,
  imageUrl: string,
  shareText: string | null,
  title: string
): ImageMomentShareSheet => ({
  type: ModalType.IMAGE_MOMENT_SHARE_SHEET,
  altText,
  imageUrl,
  shareText,
  title,
});
