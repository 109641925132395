import styled from 'styled-components';
import { ViewerCount as BaseViewerCount } from '../media/mediaHeader/styles';
import { LandscapeMobile, LargeUpQuery, PortraitKeyboardOpen } from '@utils/responsive';
import { getInset } from '@lifechurch/react-ion';

interface WrapperProps {
  backgroundColor: string;
  isGuest: boolean;
}

const NavHeaderWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-shrink: 0;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.gray5};
  height: 48px;
  width: 100%;
  z-index: 1;
  align-items: center;

  @media ${LargeUpQuery} {
    height: ${props => (props.isGuest ? '64px' : '48px')};
  }

  @media ${LandscapeMobile} {
    display: none;
  }

  @media ${PortraitKeyboardOpen} {
    display: none;
  }
`;

interface ChurchNameWrapperProps {
  customTextColor: string;
  isGuest: boolean;
}

const ChurchNameWrapper = styled.h1<ChurchNameWrapperProps>`
  color: ${props => (props.customTextColor ? props.customTextColor : props.theme.colors.gray100)};
  font-weight: ${props => props.theme.fontWeights[3]};
  font-size: ${props => props.theme.fontSizes[3]};
  line-height: ${props => props.theme.lineHeights[2]};
  width: 240px;

  @media ${LargeUpQuery} {
    width: ${props => (props.isGuest ? '320px' : '240px')};
  }
`;

interface ChurchLogoWrapperProps {
  isGuest: boolean;
}

const ChurchLogoWrapper = styled.div<ChurchLogoWrapperProps>`
  height: 40px;
  width: 240px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  @media ${LargeUpQuery} {
    height: ${props => (props.isGuest ? '52px' : '40px')};
    width: ${props => (props.isGuest ? '320px' : '240px')};
  }
`;

interface NavHeaderHamburgerWrapperProps {
  isGuest: boolean;
}

const NavHeaderHamburgerWrapper = styled.button<NavHeaderHamburgerWrapperProps>`
  color: ${props => props.theme.colors.gray100};
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  margin: 0;
  border: none;
  background: transparent;
  cursor: default;
  outline: none;
  height: inherit;
  padding: 1px 8px;

  @media ${LargeUpQuery} {
    padding: ${props => (props.isGuest ? '16px' : '8px')};
  }

  svg {
    path {
      opacity: 0.5;
    }
  }

  &:hover,
  &:focus {
    svg {
      path {
        opacity: 1;
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover} inset;
    outline: none;
    align-items: center;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

interface ViewerCountProps {
  headerTextColor: string;
}

const ViewerCount = styled(BaseViewerCount)<ViewerCountProps>`
  margin-inline-start: auto;
  margin-inline-end: 1rem;
  color: ${props => props.headerTextColor};
  opacity: 0.5;

  svg path {
    fill: ${props => props.headerTextColor};
  }
`;

const HiddenChurchNameWrapper = styled.h1`
  position: absolute;
  ${getInset('insetBlockStart', '-9999px')};
`;

export {
  ChurchLogoWrapper,
  ChurchNameWrapper,
  HiddenChurchNameWrapper,
  NavHeaderHamburgerWrapper,
  NavHeaderWrapper,
  ViewerCount,
};
