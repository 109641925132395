import React, { ChangeEvent, ReactNode, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { InputSize } from './Input';
import { Context } from './FancyInput';
import { Box } from './Box';
import { getInset } from './logical';

interface BaseSelectProps {
  error: boolean;
  valid: boolean;
  fieldSize: InputSize;
}

interface SelectProps {
  size: InputSize;
}

interface LabelProps {
  size: InputSize;
  value: string;
  required: boolean;
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectFieldWrapper = styled.div<SelectProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-inline-start: ${(props): number => (props.size === InputSize.SM ? 5 : 7)}px solid
      transparent;
    border-inline-end: ${(props): number => (props.size === InputSize.SM ? 5 : 7)}px solid
      transparent;
    border-block-start: ${(props): number => (props.size === InputSize.SM ? 5 : 7)}px solid
      ${(props): string => props.theme.colors.gray50};
    ${getInset('insetInlineEnd', '5px')}
    ${props =>
      props.size === InputSize.SM
        ? getInset('insetBlockStart', '26px')
        : getInset('insetBlockStart', '33px')};
    position: absolute;
    pointer-events: none;
  }
`;

const BaseSelect = styled.select<BaseSelectProps>`
  color: ${props => props.theme.colors.gray100};
  flex-grow: 2;
  border: 0;
  background: none;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, 'Arial Nova', Arial, sans-serif !important;
  border-block-end: 1px solid
    ${props => {
      if (props.error) {
        return props.theme.colors.error.default;
      } else if (props.valid) {
        return props.theme.colors.success.default;
      } else {
        return props.theme.colors.gray30;
      }
    }};
  padding-block-start: ${props => (props.fieldSize === InputSize.SM ? '18px' : '21px')};
  padding-block-end: ${props => (props.fieldSize === InputSize.SM ? '9px' : '8px')};
  border-radius: 0;
  font-size: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.fontSizes[3] : props.theme.fontSizes[4]};
  line-height: ${props =>
    props.fieldSize === InputSize.SM ? props.theme.lineHeights[2] : props.theme.lineHeights[3]};
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  width: 100%;

  &:focus {
    outline: none;
    border-block-end: 1px solid ${props => props.theme.colors.action.default};
  }
`;

const InputLabel = styled.label<LabelProps>`
  position: absolute;
  pointer-events: none;
  color: ${props => props.theme.colors.gray50};
  transition: 0.1s ease-out;
  font-size: ${props => {
    if (props.size === InputSize.SM) {
      if (props.value === '') {
        return props.theme.fontSizes[3];
      } else {
        return props.theme.fontSizes[2];
      }
    }
    if (props.size === InputSize.MD) {
      if (props.value === '') {
        return props.theme.fontSizes[4];
      } else {
        return props.theme.fontSizes[3];
      }
    }
    return null;
  }};

  line-height: ${props =>
    props.size === InputSize.SM ? props.theme.lineHeights[2] : props.theme.lineHeights[3]};
  ${props => {
    if (props.value === '' && props.size === InputSize.SM) {
      return getInset('insetBlockStart', '18px');
    }
    if (props.value === '' && props.size !== InputSize.SM) {
      return getInset('insetBlockStart', '21px');
    }
    return null;
  }};

  &[required]::after {
    content: '*';
  }

  ${BaseSelect}:focus ~ & {
    font-size: ${props =>
      props.size === InputSize.SM ? props.theme.fontSizes[2] : props.theme.fontSizes[3]};
    ${getInset('insetBlockStart', 0)};
  }
`;

// There are conflicting TS types, so we need to omit some to pass typechecking
type NativeInput = Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'size' | 'aria-relevant'>;

export interface FancySelectProps extends NativeInput {
  label: string;
  size?: InputSize;
  name?: string;
  value?: string;
  error?: boolean;
  valid?: boolean;
  hint?: ReactNode;
  context?: ReactNode;
  required?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const FancySelect = forwardRef<HTMLSelectElement, PropsWithChildren<FancySelectProps>>(
  (
    {
      size = InputSize.SM,
      name = 'fancySelect',
      value = '',
      label,
      onChange,
      error = false,
      valid = false,
      hint,
      context,
      required = false,
      children,
      ...props
    },
    ref
  ) => (
    <SelectWrapper>
      <SelectFieldWrapper size={size}>
        <BaseSelect
          ref={ref}
          name={name}
          id={name}
          fieldSize={size}
          value={value}
          onChange={onChange}
          error={error}
          valid={valid}
          required={required}
          {...props}
        >
          <option value='' disabled />
          {children}
        </BaseSelect>
        <InputLabel htmlFor={name} size={size} required={required} value={value}>
          {label}
        </InputLabel>
      </SelectFieldWrapper>
      {(hint || context) && (
        <Box marginBlockStart={2}>
          {hint && (
            <Context size={size} error={error} valid={valid}>
              {hint}
            </Context>
          )}
          {context && <Context size={size}>{context}</Context>}
        </Box>
      )}
    </SelectWrapper>
  )
);

FancySelect.displayName = 'ForwardedFancySelect';

export { FancySelect };
