import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleLeftIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M5.95707 7.49996L10.1035 11.6464L9.39641 12.3535L4.54286 7.49996L9.39641 2.64641L10.1035 3.35352L5.95707 7.49996Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M12.2928 16.707L5.58571 9.99992L12.2928 3.29282L13.707 4.70703L8.41414 9.99992L13.707 15.2928L12.2928 16.707Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M15.2928 20.707L6.58571 11.9999L15.2928 3.29282L16.707 4.70703L9.41414 11.9999L16.707 19.2928L15.2928 20.707Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M21.2928 27.707L9.58571 15.9999L21.2928 4.29282L22.707 5.70703L12.4141 15.9999L22.707 26.2928L21.2928 27.707Z'
          size={size}
          {...rest}
        />
      );
  }
};
