import styled from 'styled-components';

export const DropdownList = styled.ul`
  padding: 4px 0;
  margin: 0;

  li {
    list-style-type: none;
  }
`;
