import {
  PublicSubscriber as Subscriber,
  PublicChannel as Channel,
  ActionWithPayload,
} from '../../../../types';
import { MessageLiveObject, LiveObjectType } from '../../../../pubnub/liveObject';
import { v4 as uuidv4 } from 'uuid';

interface Translation {
  languageCode: string;
  text: string;
}

export interface Message {
  id: string;
  channel: Channel;
  timestamp: string;
  lang: string;
  text: string;
  translations: Array<Translation>;
  deleted: boolean;
  subscriber: Subscriber;
  originalText: string;
  likeCount: number;
}

export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export type DeleteMessage = ActionWithPayload<typeof DELETE_MESSAGE, Message>;

export const newMessageLiveObject = (
  text: string,
  subscriber: Subscriber,
  lang: string,
  channel: Channel,
  likeCount = 0
): MessageLiveObject => {
  const id = uuidv4();
  const timestamp = new Date().toISOString();
  return {
    type: LiveObjectType.MESSAGE,
    id,
    timestamp,
    channel,
    data: {
      id,
      channel,
      timestamp,
      lang,
      text,
      translations: [],
      deleted: false,
      subscriber,
      originalText: text,
      likeCount: likeCount,
    },
  };
};

export const deleteMessage = (payload: Message): DeleteMessage => ({
  type: DELETE_MESSAGE,
  payload,
});
