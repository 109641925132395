import styled from 'styled-components';
import { getInset } from '@lifechurch/react-ion';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  ${getInset('insetBlockEnd', '20px')};
`;
