import styled, { keyframes } from 'styled-components';
import { OXSmallParagraph } from '@lifechurch/react-ion';

export const EngagementWrapper = styled.span`
  display: flex;
  flex-direction: row;
  padding-inline-end: 8px;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;

const EngagementButton = styled.span`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 999px;
  padding: 1px 4px;
`;

interface ReactionButtonProps {
  hasLiked: boolean;
}

export const ReactionButton = styled(EngagementButton)<ReactionButtonProps>`
  svg path {
    fill: ${props =>
      props.hasLiked ? props.theme.colors.pink.default : props.theme.colors.gray50};
  }
`;

export const LikeCount = styled(OXSmallParagraph)`
  color: ${props => props.theme.colors.gray50};
  user-select: none;
  font-size: ${(props): string => props.theme.fontSizes[1]};
  font-weight: 400;
  padding-inline-start: 1px;
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px) scale(1.5);
  }

  to {
    opacity: 0;
    transform: translateY(-80px);
  }
`;
interface AnimationProps {
  onAnimationEnd?: () => void;
}
export const HeartAnimation = styled.div<AnimationProps>`
  position: absolute;
  margin-inline-start: 4px;
  animation: ${slideUp} 1000ms ease-out;
  z-index: 11;
`;
