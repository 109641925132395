import React, { FC, useContext, useRef, useState } from 'react';
import { Avatar, AvatarSizeType } from '@churchonline/core';
import FeedObjectMenu, { MESSAGE_MENU_MAX_HEIGHT } from '../feedObjectMenu';
import { Props } from './index';
import styles from './message.module.css';
import { MessageBody } from './messageBody';
import { FeedBoundaryContext } from '../../../../context/FeedBoundaryContext';
import { useAppSelector } from '@hooks/redux/hooks';
import clsx from 'clsx';

const Message: FC<Props> = ({
  chatPermissions,
  currentLanguage,
  currentSubscriber,
  deleteMessage,
  directChat,
  fromSelf,
  isCompact,
  message,
  hasLiked,
  likeMessage,
  moderationPermissions,
  muteSubscriber,
  organizationId,
  sendSeeOriginalTranslationEvent,
}) => {
  // @ts-expect-error
  const viewAsUser = useAppSelector(state => state.ui.viewingAsUser);

  const { topBoundary } = useContext(FeedBoundaryContext);
  const [menuPlacement, setMenuPlacement] = useState<'top' | 'auto'>('top');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [messageMenuVisible, setMessageMenuVisible] = useState(false);

  if (!message) return null;
  const { subscriber } = message;

  const onDelete = () => deleteMessage(message);
  const onMute = () => muteSubscriber(subscriber);
  const onDirectChat = () => directChat(currentSubscriber, subscriber, organizationId);
  const onShowMessageMenu = () => {
    const topBorderPositionOfMessage = wrapperRef.current
      ? wrapperRef.current?.getBoundingClientRect().top - MESSAGE_MENU_MAX_HEIGHT
      : null;

    const menuGoesBelow =
      !!topBorderPositionOfMessage && !!topBoundary && topBorderPositionOfMessage < topBoundary;

    setMenuPlacement(menuGoesBelow ? 'auto' : 'top');
    setMessageMenuVisible(true);
  };

  const avatar: string | undefined = subscriber.avatar ?? undefined;
  const avatarSize = isCompact ? AvatarSizeType.XSMALL : 36;

  const hasPermissions = (chatPermissions && !fromSelf) || moderationPermissions;
  const hasTranslation = message.lang !== currentLanguage?.split('-')[0];
  const id: string | undefined = subscriber.id ?? undefined;
  const nickname: string = subscriber.nickname ?? '';
  const isHighlighted: boolean = hasPermissions && messageMenuVisible;

  const messageContainerStyles = clsx([styles.wrapper], {
    [styles.overflowHidden]: !(!hasPermissions || messageMenuVisible),
    [styles.compact]: hasPermissions && isCompact,
    [styles.expandedWithPermissions]: hasPermissions && !isCompact,
    [styles.expandedWithoutPermissions]: !hasPermissions && !isCompact,
  });

  if (message.deleted) return null;

  return (
    <div ref={wrapperRef} data-testid='messageContainer' className={messageContainerStyles}>
      <div className={clsx(styles.backgroundWrapper, { [styles.highlighted]: isHighlighted })}>
        <Avatar subscriber={{ id, avatar, nickname }} size={avatarSize} testId='avatar' />
        <MessageBody
          hasTranslation={hasTranslation}
          isCompact={isCompact}
          message={message}
          likeMessage={likeMessage}
          hasLiked={hasLiked}
          sendSeeOriginalTranslationEvent={sendSeeOriginalTranslationEvent}
        />
      </div>
      {hasPermissions && !viewAsUser && (
        <FeedObjectMenu
          chatPermissions={chatPermissions}
          data-testid='message-feed-object-menu'
          deleteMessage={onDelete}
          directChat={onDirectChat}
          hideMessageMenu={() => setMessageMenuVisible(false)}
          isMessage
          menuPlacement={menuPlacement}
          moderationPermissions={moderationPermissions}
          muteSubscriber={onMute}
          self={fromSelf}
          showMessageMenu={onShowMessageMenu}
        />
      )}
    </div>
  );
};

export default React.memo<Props>(Message);
