import styled from 'styled-components';
import { ButtonSize, PrimaryButton, PrimaryOnDarkButton, SecondaryButton, } from '@lifechurch/react-ion';
import React from 'react';
const SalvationPrimaryButton = styled(PrimaryButton) `
  border-radius: 16px;
  color: ${props => (props.response === '' ? 'rgba(255,255,255,0.5)' : 'white')};
  height: 32px;
  z-index: 1;
`;
const SalvationPrimaryOnDarkButton = styled(PrimaryOnDarkButton) `
  z-index: 1;
  border-radius: 16px;

  &:disabled {
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.gray30};
    box-shadow: none;
    cursor: default;
    padding-inline-end: ${props => (props.isCustom ? 0 : '4px')};
    padding-inline-start: ${props => (props.isCustom ? '4px' : '12px')};
  }
`;
export const SalvationSecondaryButton = styled(SecondaryButton) `
  z-index: 1;
  border-radius: 16px;
  height: 32px;

  &:hover:not(:disabled) {
    box-shadow: ${props => props.theme.shadows[2]};
    background-color: ${props => props.theme.colors.action.hover};
    color: white;

    div:last-child {
      background-color: ${props => props.theme.colors.whiteTransparent20};
    }
  }

  &:disabled {
    background-color: transparent;
    cursor: default;
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.gray30};
    padding-inline-start: ${props => (props.isCustom ? '4px' : '12px')};
    padding-inline-end: ${props => (props.isCustom ? 0 : '4px')};
  }
`;
export const CTAButton = ({ children, hasSubmitted, isPinned, onClick, tabIndex, ...props }) => {
    let Button;
    if (isPinned && !hasSubmitted) {
        Button = SalvationPrimaryButton;
    }
    else if (isPinned && hasSubmitted) {
        Button = SalvationPrimaryOnDarkButton;
    }
    else {
        Button = SalvationSecondaryButton;
    }
    return (React.createElement(Button, { size: ButtonSize.SM, onClick: onClick, disabled: hasSubmitted, tabIndex: tabIndex, ...props }, children));
};
