import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const EnvelopeIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M14 2.99994H1V11.9999H14V2.99994ZM7.235 8.45416L2 5.18228V4.00304L7.5 7.44054L13 4.00304V5.18228L7.765 8.45416L7.5 8.61978L7.235 8.45416Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M18 4H2V16H18V4ZM4 6.07074V8.42923L9.47 11.848L10 12.1792L10.53 11.848L16 8.42923V6.07074L10 9.82074L4 6.07074Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M22 4H2V20H22V4ZM4 6.17933V8.62064L11.4265 13.8192L12 14.2206L12.5735 13.8192L20 8.62064V6.17933L12 11.7793L4 6.17933Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M30 6H2V26H30V6ZM15.4592 17.8412L4 10.4745V8.0969L16 15.8112L28 8.0969V10.4745L16.5408 17.8412L16 18.1888L15.4592 17.8412Z'
          size={size}
          {...rest}
        />
      );
  }
};
