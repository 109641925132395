import { connect } from 'react-redux';
import { mutedAlert } from '../../Alert/dux';
import { muteSubscriber } from '@store/subscriberSlice';
import MuteSubscriber from './muteSubscriber';
import { PublicSubscriber as Subscriber } from '../../../types';
import { MuteSubscriberMeta } from './dux';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface StateProps {
  subscriber: Subscriber;
}

interface OwnProps {
  closeModal: () => void;
  meta: MuteSubscriberMeta;
}

const mapStateToProps = (_state: RootState, ownProps: OwnProps) => ({
  subscriber: ownProps.meta.subscriber,
  ...ownProps,
});

interface DispatchProps {
  muteSubscriber: (subscriberId: string, canceled: boolean, onSuccess: VoidFunction) => void;
  mutedAlert: (guestName: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  muteSubscriber: (subscriberId: string, canceled: boolean, onSuccess: VoidFunction) =>
    dispatch(muteSubscriber({ subscriberId, canceled, onSuccess })),
  mutedAlert: (guestName: string) => dispatch(mutedAlert(guestName)),
});

const VisibleMuteSubscriber = connect(mapStateToProps, mapDispatchToProps)(MuteSubscriber);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleMuteSubscriber;
