import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const LineChartIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M2 4H4V14L18 14V16H2V14V4ZM18 4H16.5858L12 8.58579L10.2071 6.79289L9.5 6.08579L8.79289 6.79289L5 10.5858V13.4142L9.5 8.91421L11.2929 10.7071L12 11.4142L12.7071 10.7071L18 5.41421V4Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M2 4H4V18H22V20H2V18V4ZM10.7929 8.79289L6 13.5858V16.4142L11.5 10.9142L13.2929 12.7071L14 13.4142L14.7071 12.7071L22 5.41421V4.00005H20.5857L14 10.5858L12.2071 8.79289L11.5 8.08579L10.7929 8.79289Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
