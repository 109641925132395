import { connect } from 'react-redux';
import LargeUp from './largeUp';
import { RootState } from '@store/rootReducer';
import { getScheduleItems } from '@store/scheduleSlice/selectors';
import { isOffline } from '@store/serviceSlice/selectors';

interface StateProps {
  isOffline: boolean;
  hasUpcomingServices: boolean;
  chatIsFocused: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isOffline: isOffline(state),
    hasUpcomingServices: getScheduleItems(state).length > 0,
    chatIsFocused: !!state.feed.focusedChannel,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(LargeUp);
