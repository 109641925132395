// IMPORTANT: These words get combined into a regex, so all regex special characters must be escaped.
const BADWORDS_EN = [
  '@rsehol',
  '@unt',
  'a-ss',
  'a\\.rse',
  'a\\.s\\.s',
  'a55',
  'a55hole',
  'a_s_s',
  'ama10',
  'anal',
  'analprobe',
  'anilingus',
  'anul',
  'anus',
  'apeshit',
  'ar5e',
  'ar5h0le',
  'ar5h0les',
  'areola',
  'areole',
  'arrse',
  'ars3',
  'arse',
  'arseh0le',
  'arseh0les',
  'arseho',
  'arsehol',
  'arsehole',
  'arseholes',
  'arsewipe',
  'arsh0le',
  'arshole',
  'arsholes',
  'as-s',
  'ashole',
  'ass',
  'ass hole',
  'ass-fucker',
  'ass\\.',
  'assbang',
  'assbanged',
  'assbangs',
  'asses',
  'assfuck',
  'assfucker',
  'assfukka',
  'assh0le',
  'assh0les',
  'asshat',
  'assho1e',
  'asshole',
  'assholes',
  'asskisser',
  'asslick',
  'asslicker',
  'assmaster',
  'assmunch',
  'asswhole',
  'asswipe',
  'asswipes',
  'auto erotic',
  'autoerotic',
  'azz',
  'b!tch',
  'baby batter',
  'ball gag',
  'ball licking',
  'ball sack',
  'ball sucking',
  'ballbag',
  'balls',
  'ballsack',
  'banchoot',
  'bangbros',
  'barely legal',
  'bastard',
  'bastardo',
  'bastards',
  'basterd',
  'bastinado',
  'basyard',
  'basyards',
  'bdsm',
  'beaner',
  'beastiality',
  'beatch',
  'beeeeutch',
  'beefcurtains',
  'beeyotch',
  'beotch',
  'bestiality',
  'biatch',
  'bich',
  'biener',
  'big breasts',
  'big knockers',
  'big tits',
  'bigtits',
  'biitch',
  'bimbo',
  'bimbos',
  'bitch',
  'bitched',
  'bitcher',
  'bitchers',
  'bitches',
  'bitchin',
  'bitching',
  'bitchy',
  'biyatch',
  'blow j',
  'blow job',
  'blow-job',
  'blowher',
  'blowhim',
  'blowjob',
  'blowjobs',
  'blowme',
  'blumpkin',
  'bo11ocks',
  'boabiesooking',
  'boabysooking',
  'boiolas',
  'boll0cks',
  'bollock',
  'bollocks',
  'bollok',
  'bollox',
  'bolocks',
  'bolox',
  'boner',
  'boners',
  'boob',
  'boobies',
  'boobs',
  'booby',
  'bookie',
  'booobs',
  'boooobs',
  'booooobs',
  'booooooobs',
  'bootee',
  'bootha',
  'boothas',
  'bootie',
  'booty',
  'bosedeeke',
  'bosomy',
  'brown showers',
  'browneye',
  'browntown',
  'buceta',
  'bucketcunt',
  'bugger',
  'bugger off',
  'bukkake',
  'bull shit',
  'bulldager',
  'bulldyke',
  'bullhell',
  'bullshit',
  'bullshits',
  'bullshitted',
  'bullturds',
  'bumbandit',
  'bumhole',
  'bung',
  'bung hole',
  'bunghole',
  'bunny fucker',
  'bunnyfucker',
  'butt fuck',
  'buttbreath',
  'buttcheeks',
  'buttface',
  'buttfuck',
  'buttfucker',
  'butthole',
  'buttholesweat',
  'buttmuch',
  'buttmunch',
  'buttpicker',
  'buttplug',
  'c#nt',
  'c-0-c-k',
  'c-o-c-k',
  'c-u-n-t',
  'c\\.0\\.c\\.k',
  'c\\.o\\.c\\.k\\.',
  'c\\.u\\.n\\.t',
  'c00n',
  'c0ck',
  'c0cksucka',
  'c0cksucker',
  'c_u_n_t',
  'cahnts',
  'camel toe',
  'cameltoe',
  'camgirl',
  'camslut',
  'camwhore',
  'candy-ass',
  'carpet muncher',
  'carpetmuncher',
  'catshit',
  'cawk',
  'chav',
  'chinc',
  'chinchin',
  'chincs',
  'chink',
  'chode',
  'chodes',
  'chowhai',
  'chuffnuts',
  'chutiya',
  'cialis',
  'cipa',
  'circle jerk',
  'circlejerk',
  'cl1t',
  'clit',
  'clitoff',
  'cliton',
  'clitoris',
  'clitorus',
  'clits',
  'clitty',
  'clover clamps',
  'clunge',
  'clusterfuck',
  'cnut',
  'cnuts',
  'cock',
  'cock sucker',
  'cock-sucker',
  'cockblock',
  'cockblocker',
  'cockface',
  'cockhead',
  'cockholster',
  'cockknocker',
  'cockmunch',
  'cockmuncher',
  'cocks',
  'cocksmoker',
  'cocksuck',
  'cocksucka',
  'cocksucked',
  'cocksucker',
  'cocksuckers',
  'cocksucking',
  'cocksucks',
  'cocksuka',
  'cocksukka',
  'coital',
  'cojones',
  'cok',
  'cokmuncher',
  'coksucka',
  'cooch',
  'coochie',
  'coons',
  'cooter',
  'coprolagnia',
  'coprophilia',
  'copulate',
  'corksucker',
  'cornholed',
  'cossorali',
  'crackwhore',
  'critest',
  'crustycrotch',
  'cuck',
  'cucked',
  'cuckhold',
  'cuckold',
  'cucks',
  'cum',
  'cummer',
  'cummin',
  'cumming',
  'cumn',
  'cums',
  'cumshot',
  'cumshots',
  'cumslut',
  'cumstain',
  'cun\\$',
  'cunilingus',
  'cunillingus',
  'cunnilingus',
  'cunny',
  'cunt',
  "cunt's",
  'cunt\\.',
  'cuntbreath',
  'cuntdog',
  'cuntface',
  'cunthunter',
  'cunting',
  'cuntlick',
  'cuntlicker',
  'cuntlicking',
  'cuntlip',
  'cunton',
  'cunts',
  'cuntsucker',
  'cuunt',
  'cvnt',
  'cvnts',
  'cyberfuc',
  'cyberfuck',
  'cyberfucked',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking',
  'd a m n',
  'd-ick',
  'd-ong',
  'd\\.i\\.c\\.k',
  'd0ng',
  'd0uch3',
  'd0uche',
  'd1ck',
  'd1ck!',
  'd1ckh@ed',
  'd1ld0',
  'd1ldo',
  'daggummit',
  'dammit',
  'damn',
  'damned',
  'damnit',
  'damnmit',
  'darkie',
  'darky',
  'date rape',
  'daterape',
  'dawgie-style',
  'dbqkd',
  'dck',
  'deeepthroat',
  'deep throat',
  'deepthroat',
  'dendrophilia',
  'dick-ish',
  'dickbag',
  'dickbrain',
  'dickbreath',
  'dickcheese',
  'dickdipper',
  'dickface',
  'dickflipper',
  'dickhead',
  'dickheads',
  'dickish',
  'dickless',
  'dickripper',
  'dicks',
  'dicksipper',
  'dicksmack',
  'dicksucker',
  'dickweed',
  'dickwhipper',
  'dickwrinkle',
  'dickzipper',
  'diddle',
  'diehd',
  'dike',
  'dil\\.do',
  'dildo',
  'dildos',
  'diligaf',
  'dilldoe',
  'dilldos',
  'dillweed',
  'dingle',
  'dipship',
  'dipshit',
  'dipstick',
  'dirsa',
  'dirty pillows',
  'dirty sanchez',
  'dlck',
  'do-ong',
  'dobber',
  'dog style',
  'dog-fucker',
  'doggie style',
  'doggie-style',
  'doggiestyle',
  'doggy style',
  'doggy-style',
  'doggystyle',
  'dolcett',
  'dominatrix',
  'dommes',
  'dong',
  'donkey punch',
  'donkeypunch',
  'donkeyribber',
  'doosh',
  'double dong',
  'double penetration',
  'douch3',
  'douche',
  'douchebag',
  'douchebags',
  'douchejuice',
  'douchenozzle',
  'douchenugget',
  'douchey',
  'duche',
  'dumass',
  'dumbass',
  'dumbasses',
  'dumbbell',
  'dumbfuck',
  'dumbfucker',
  'dxxkhead',
  'dyke',
  'eat my ass',
  'ecchi',
  'eff',
  'effin',
  'ejaculate',
  'ejaculated',
  'ejaculates',
  'ejaculating',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'erection',
  'erotic',
  'erotism',
  'ethical slut',
  'f  u  c  k',
  'f u c k',
  'f u c k e r',
  'f up',
  'f###',
  'f##k',
  'f##king',
  'f#cked',
  "f'ck",
  'f-----g',
  'f---ed',
  'f---ing',
  'f--k',
  'f-o-a-d',
  'f-u-<-k',
  'f-u-c-k',
  'f-uck',
  'f\\.a\\.g',
  'f\\.o\\.a\\.d',
  'f\\.o\\.a\\.d\\.',
  'f\\.u\\.c\\.k',
  'f\\.u\\.c\\.k\\.',
  'f\\.uck',
  'f00k',
  'f00ked',
  'f0cker',
  'f0oked',
  'f4cker',
  'f4g',
  'f4nny',
  'f<uk',
  'f>>k',
  'f@@@in',
  'f@@@ing',
  'f@ck',
  'f@g',
  'f@gs',
  'f__kin',
  'f__king',
  'f_u_c_k',
  'fack',
  'fackin',
  'facking',
  'fag',
  'fagg',
  'fagg0t',
  'fagged',
  'fagget',
  'fagging',
  'faggit',
  'faggits',
  'faggitt',
  'faggot',
  'faggotass',
  'faggotry',
  'faggs',
  'fagit',
  'fagits',
  'fagot',
  'fagots',
  'fags',
  'faig',
  'faigt',
  'fannybandit',
  'fannyflaps',
  'fannyfucker',
  'fartknocker',
  'fatass',
  'fatso',
  'fbuddy',
  'fck',
  'fck1ng',
  'fckeud',
  'fckin',
  'fcking',
  'fcks',
  'fckw1t',
  'fckwit',
  'fcuk',
  'fcuked',
  'fcuker',
  'fcukin',
  'fcuking',
  'fcuks',
  'feak',
  'feck',
  'fecker',
  'feckin',
  'fecking',
  'fekking',
  'felch',
  'felched',
  'felcher',
  'felching',
  'fellate',
  'fellatio',
  'feltch',
  'feltcher',
  'feltching',
  'femdom',
  'femsub',
  'fggt',
  'fgt',
  'figging',
  'fingerbang',
  'fingerfuck',
  'fingerfucked',
  'fingerfucker',
  'fingerfuckers',
  'fingerfucking',
  'fingerfucks',
  'fingering',
  'fistfuck',
  'fistfucked',
  'fistfucker',
  'fistfuckers',
  'fistfucking',
  'fistfuckings',
  'fistfucks',
  'fisting',
  'fisty',
  'fker',
  'fkin',
  'fking',
  'flaccid',
  'flacid',
  'flamer',
  'flammer',
  'fleshflute',
  'floozy',
  'fluck',
  'flucknuts',
  'fo0ked',
  'foad',
  'focker',
  'foff',
  'fondle',
  'foobar',
  'fook',
  'fookd',
  'fooked',
  'fooker',
  'fookin',
  "fookin'",
  'fooking',
  'foot fetish',
  'footjob',
  'foreskin',
  'freex',
  'frig',
  'frigg',
  'frigga',
  'friggin',
  'frigging',
  'frigin',
  'friging',
  'frotting',
  'fu',
  'fu&kin',
  'fu&king',
  'fu\\.ck',
  'fu<k',
  'fu<ked',
  'fu<ker',
  'fu<kin',
  'fu<king',
  'fu<kker',
  'fu<kr',
  'fu<ks',
  'fu@k',
  'fu@ker',
  'fubar',
  'fuck',
  'fuck buttons',
  'fuck shit motherfucker fucker fucking asshole',
  'fuck-tard',
  'fuck-wit',
  'fucka',
  'fuckass',
  'fuckbah',
  'fuckbuddy',
  'fuckbutt',
  'fucked',
  'fucker',
  'fuckers',
  'fuckface',
  'fuckhead',
  'fuckheads',
  'fuckin',
  "fuckin'",
  'fucking',
  'fuckingbitch',
  'fuckinghell',
  'fuckings',
  'fuckingshit',
  'fuckingshitmotherfucker',
  'fuckk',
  'fuckme',
  'fuckn',
  'fucknugget',
  'fucknut',
  'fuckoff',
  'fuckout',
  'fucks',
  'fucktard',
  'fucktards',
  'fucku',
  'fuckup',
  'fuckw1t',
  'fuckwad',
  'fuckwhit',
  'fuckwit',
  'fuckwits',
  'fuckyou',
  'fuct',
  'fucw1t',
  'fucwit',
  'fudge packer',
  'fudge-p@cker',
  'fudge-packer',
  'fudgep@cker',
  'fudgepacker',
  'fudgpacker',
  'fuk',
  'fukced',
  'fuked',
  'fuker',
  'fukin',
  'fuking',
  'fukk',
  'fukked',
  'fukker',
  'fukkin',
  'fukking',
  'fuks',
  'fukwhit',
  'fukwit',
  'furbox',
  'furburger',
  'futanari',
  'fuuck',
  'fuukn',
  'fuuuck',
  'fuuuuck',
  'fuuuuuck',
  'fuuuuuuck',
  'fux',
  'fux0r',
  'fvck',
  'fvck-up',
  'fvcking',
  'fvckup',
  'fvckw1t',
  'fvckwit',
  'fxck',
  'f��ck',
  'f��ck',
  'f��cking',
  'f����k',
  'f��ck',
  'f��ck',
  'g-ay',
  'g-spot',
  'g@y',
  'gae',
  'gai',
  'gang bang',
  'gang-bang',
  'gangbang',
  'gangbanged',
  'gangbangs',
  'ganja',
  'gay sex',
  'gayboy',
  'gayhole',
  'gaysex',
  'gazongers',
  'genitals',
  'gey',
  'gfy',
  'ghay',
  'ghb',
  'ghey',
  'giant cock',
  'gigolo',
  'gilf',
  'gimp',
  'ginch',
  'girl on top',
  'girlie-gardening',
  'girls gone wild',
  'glans',
  'glory hole',
  'gloryhole',
  'gnikcuf',
  'goatcx',
  'goatfucker',
  'goatfuckers',
  'goatse',
  'god damn',
  'god-dam',
  'god-damned',
  'godamn',
  'godamnit',
  'goddam',
  'goddammit',
  'goddamn',
  'goddamned',
  'goddamnit',
  'goddamnwhore',
  'gokkun',
  'golden shower',
  'goldenshower',
  'gonad',
  'gonads',
  'goo girl',
  'goodpoop',
  'gook',
  'gooks',
  'goregasm',
  'goris',
  'gotdamn',
  'gotdamnit',
  'gringo',
  'grope',
  'group sex',
  'gspot',
  'gtfo',
  'guido',
  'guro',
  'gutterslutbitchfromhell',
  'gypo',
  'gypos',
  'gyppo',
  'gyppos',
  'h0m0',
  'h0mo',
  'h1tl3r',
  'h1tler',
  'hand job',
  'handjob',
  'harami',
  'hard on',
  'hardcoresex',
  'hardon',
  'he11',
  'hebe',
  'heeb',
  'heil',
  'hentai',
  'herp',
  'herpes',
  'herpy',
  'heshe',
  'hoar',
  'hoare',
  'hobag',
  'hoer',
  'hom0',
  'homoerotic',
  'homoey',
  'honger',
  'honkers',
  'honkey',
  'honky',
  'hooch',
  'hookah',
  'hooker',
  'hootchie',
  'hooter',
  'hooters',
  'hore',
  'horniest',
  'horny',
  'hot chick',
  'hotsex',
  'how to kill',
  'how to murder',
  'hrny',
  'humped',
  'humping',
  'hussy',
  'hvns',
  'hymen',
  'inbred',
  'incest',
  'injun',
  'intercourse',
  'ities',
  'j1zz',
  'j3rk0ff',
  'jack off',
  'jack-off',
  'jackass',
  'jackhole',
  'jackingoff',
  'jackoff',
  'jail bait',
  'jailbait',
  'japs',
  'jasal',
  'jerk off',
  'jerk-off',
  'jerk0ff',
  'jerked',
  'jerkoff',
  'jewed',
  'jigaboo',
  'jiggaboo',
  'jiggerboo',
  'jihad',
  'jism',
  'jiz',
  'jizm',
  'jizz',
  'jizzed',
  'juggs',
  'junglebunny',
  'junkie',
  'junky',
  'k@ffir',
  'k@ffirs',
  'k@fir',
  'k@firs',
  'kaf1r',
  'kaff1r',
  'kaffir',
  'kaffirs',
  'kafir',
  'kafirs',
  'kafr',
  'kants',
  'kashit',
  'kawk',
  'keaster',
  'khunt',
  'kiddie-fiddler',
  'kiddie-fiddling',
  'kiddiefiddler',
  'kiddiefiddling',
  'kiddy-fiddler',
  'kiddyfiddler',
  'kike',
  'kikes',
  'kinbaku',
  'kinkster',
  'kinky',
  'kn0b',
  'knobber',
  'knobbing',
  'knobead',
  'knobed',
  'knobend',
  'knobhead',
  'knobjocky',
  'knobjokey',
  'kock',
  'kondum',
  'kondums',
  'kooch',
  'kooches',
  'kootch',
  'kratom',
  'kraut',
  'kuffar',
  'kum',
  'kummer',
  'kumming',
  'kums',
  'kunilingus',
  'kuran',
  'kyke',
  'l3i\\+ch',
  'l3itch',
  'labia',
  'leather restraint',
  'leather straight jacket',
  'lech',
  'lemon party',
  'lemonparty',
  'lesbian',
  'lesbians',
  'lesbo',
  'lesbos',
  'lesbyterian',
  'lessy',
  'levitra',
  'lez',
  'lezbian',
  'lezbians',
  'lezbo',
  'lezbos',
  'lezzie',
  'lezzies',
  'lezzo',
  'lezzy',
  'licker',
  'lickmycunt',
  'lmao',
  'lmfao',
  'lolita',
  'lousy hosts of',
  'lovemaking',
  'lube',
  'm-fucking',
  'm\\.inge',
  'm\\.otherf\\.ucker',
  'm0f0',
  'm0fo',
  'm0nkey',
  'm1nge',
  'm45terbate',
  'ma5terb8',
  'ma5terbate',
  'maderchood',
  'make me come',
  'maledom',
  'malesub',
  'mams',
  'man-root',
  'marijuana',
  'masochist',
  'massa',
  'master-bate',
  'masterb8',
  'masterbat',
  'masterbat3',
  'masterbate',
  'masterbating',
  'masterbation',
  'masterbations',
  'masturbate',
  'masturbating',
  'masturbation',
  'meldom',
  'menage a trois',
  'menses',
  'menstruate',
  'menstruation',
  'merde',
  'meth',
  'mierda',
  'milf',
  'minge',
  'missionary position',
  'mo-fo',
  'mof0',
  'mofo',
  'mofuccer',
  'mofucker',
  'mofuckers',
  'mofucking',
  'mofukcer',
  'mohterfuccer',
  'mohterfuccers',
  'mohterfuck',
  'mohterfucker',
  'mohterfuckers',
  'mohterfucking',
  'mohterfucks',
  'mohterfuk',
  'mohterfukcer',
  'mohterfukcers',
  'mohterfuking',
  'mohterfuks',
  'molca',
  'molest',
  'molestation',
  'monkey boi',
  'monkey boy',
  'moolie',
  'moron',
  'moterfuccer',
  'moterfuck',
  'moterfucker',
  'moterfuckers',
  'moterfucking',
  'moterfucks',
  'motha-fucka',
  'mothafuccer',
  'mothafuck',
  'mothafucka',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking',
  'mothafuckings',
  'mothafucks',
  'mother fucker',
  'motherf---ers',
  'motherfuccer',
  'motherfuccers',
  'motherfuck',
  'motherfucka',
  'motherfucked',
  'motherfucker',
  'motherfuckers',
  'motherfuckin',
  'motherfucking',
  'motherfuckings',
  'motherfuckka',
  'motherfucks',
  'motherfukkker',
  'mound of venus',
  'mr hands',
  'mthafucca',
  'mthafuccas',
  'mthafucka',
  'mthafuckas',
  'mthafukca',
  'mthafukcas',
  'mtherfucker',
  'mthrfucker',
  'mthrfucking',
  'muff',
  'muff diver',
  'muff-diver',
  'muff-diving',
  'muffdiver',
  'muffdiving',
  'muffs',
  'muth@fucker',
  'mutha',
  'muthafecker',
  'muthafuccer',
  'muthafuck',
  'muthafuck@',
  'muthafucka',
  'muthafuckaz',
  'muthafucker',
  'muthafuckers',
  'muthafucking',
  'muthafuckker',
  'muthafucks',
  'muthafukas',
  'muther',
  'mutherfucker',
  'mutherfucking',
  'muthrfucking',
  'n i g g e r',
  'n1gga',
  'n1gger',
  'nad',
  'nads',
  'nambla',
  'nappy',
  'nawashi',
  'nestlecock',
  'ni66ers',
  'nig',
  'nig nog',
  'nig-nog',
  'niga',
  'nigg',
  'nigg3r',
  'nigg4h',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'niggerlover',
  'niggers',
  'niggershit',
  'niggle',
  'niggs',
  'niglet',
  'nignog',
  'nigs',
  'nigz',
  'nimphomania',
  'nimrod',
  'ninny',
  'nipple',
  'nipples',
  'nob jokey',
  'nobhead',
  'nobjocky',
  'nobjokey',
  'nonce',
  'nooky',
  'numbnuts',
  'nuse',
  'nuthuggers',
  'nutsack',
  'nympho',
  'nymphomania',
  'o m f g',
  'o m f g !',
  'o m f g!',
  'o m g',
  'octopussy',
  'omfg',
  'omorashi',
  'on9',
  'onanism',
  'one cup two girls',
  'one guy one jar',
  'one night stand',
  'oneeyedbastard',
  'onenightstand',
  'orgasmic',
  'orgasms',
  'orgies',
  'orgy',
  'ovary',
  'ovum',
  'ovums',
  'p\\.iss-flaps',
  'p\\.u\\.s\\.s\\.y',
  'p\\.u\\.s\\.s\\.y\\.',
  'p00f',
  'p00fs',
  'p00fter',
  'p00fters',
  'p0of',
  'p0rn',
  'p@ki',
  'p@kis',
  'paedo',
  'paedophile',
  'paedophiles',
  'pantie',
  'panties',
  'panty',
  'pastie',
  'pasty',
  'peado',
  'peadofile',
  'peadofiles',
  'pecker',
  'peckerhead',
  'pedo',
  'pedobear',
  'pedofile',
  'pedophile',
  'pedophilia',
  'pedophiliac',
  'peedo',
  'peedofile',
  'peedofiles',
  'peedophile',
  'peedophiles',
  'peedos',
  'peepee',
  'pegging',
  'pench0d',
  'pench0ds',
  'penchod',
  'penchods',
  'penial',
  'penile',
  'penis',
  'penisbreath',
  'penisfucker',
  'penishead',
  'peniswrinkle',
  'perversion',
  'phalli',
  'phanny',
  'phanny\\.',
  'pheck',
  'phecking',
  'phelching',
  'pheque',
  'phequing',
  'phone sex',
  'phonesex',
  'phuck',
  'phucker',
  'phuckin',
  'phucking',
  'phucks',
  'phuk',
  'phuked',
  'phuking',
  'phukked',
  'phukking',
  'phuks',
  'phuq',
  'piece of shit',
  'pigfucker',
  'pighead',
  'pikey',
  'pillow-biter',
  'pillowbiter',
  'pimp',
  'pimpis',
  'pinko',
  'piss pig',
  'piss-off',
  'pissant',
  'pissedoff',
  'pisser',
  'pissers',
  'pisses',
  'pissflaps',
  'pissin',
  'pissing',
  'pissoff',
  'pisspig',
  'plastered',
  'pleasure chest',
  'pms',
  'po0f',
  'poff',
  'polack',
  'pole smoker',
  'ponyplay',
  'poofter',
  'poon',
  'poonani',
  'poontang',
  'poop chute',
  'poopchute',
  'poopoo',
  'poostabber',
  'poostabbers',
  'pr!ck',
  'pr!ck\\.',
  'pr1ck',
  'pr1ck!',
  'pr1cks',
  'pr1cks!',
  'prick',
  'pricks',
  'prig',
  'prik',
  'prince albert piercing',
  'pron',
  'prophit',
  'prude',
  'psytea',
  'pthc',
  'pube',
  'pubes',
  'pubic',
  'pubic hair',
  'pubis',
  'punany',
  'punkass',
  'punky',
  'puntang',
  'puss',
  'pusse',
  'pussi',
  'pussies',
  'pussy',
  'pussydick',
  'pussylicker',
  'pussylip',
  'pussylips',
  'pussypounder',
  'pussys',
  'pussywhip',
  'pusy',
  'puta',
  'puto',
  'puzzy',
  'qhwl',
  'queaf',
  'queef',
  'queero',
  'queers',
  'quefe',
  'queve',
  'quicky',
  'quief',
  'quim',
  'quimsteak',
  "qur'an",
  'qveer',
  'r-tard',
  'r@pe',
  'racy',
  'raghead',
  'ragheads',
  'raging boner',
  'rally stream',
  'rally streamming',
  'raunch',
  'reacharound',
  'rectal',
  'rectum',
  'rectus',
  'redtube',
  'reetard',
  'ret@rd',
  'retard',
  'retarded',
  'reverse cowgirl',
  'revue',
  'ricer',
  'right side broadcast',
  'rimadonna',
  'rimjaw',
  'rimjob',
  'rimming',
  'ritard',
  'rootle',
  'rosy palm',
  'rosy palm and her 5 sisters',
  'rsbn',
  'rtard',
  'rum',
  'rump',
  'rumprammer',
  'rumpranger',
  'ruski',
  'rusty trombone',
  's&m',
  's-h-1-t',
  's-h-i-t',
  's-o-b',
  's\\.e\\.x',
  's\\.h\\.i\\.t',
  's\\.h\\.i\\.t\\.',
  's\\.hit',
  's\\.o\\.b\\.',
  's0b',
  's1ut',
  's2x',
  's_h_i_t',
  'sappho',
  'scag',
  'schizo',
  'schlong',
  'schmeka',
  'scroat',
  'scrog',
  'scrot',
  'scrote',
  'scrotum',
  'scrud',
  'scumbag',
  'scumber',
  'sexo',
  'sexpot',
  'sh!\\+',
  'sh!t',
  'sh!te',
  'sh!tes',
  'sh1t',
  'sh1te',
  'shagbucket',
  'shagger',
  'shaggin',
  'shagging',
  'shagstress',
  'shamedame',
  'shaved beaver',
  'shaved pussy',
  'sheister',
  'shemale',
  'shhit',
  'shi\\+',
  'shibari',
  'shirtlifter',
  'shirtlifters',
  'shit',
  'shitass',
  'shitblimp',
  'shitdick',
  'shite',
  'shiteater',
  'shited',
  'shitey',
  'shitface',
  'shitforbrains',
  'shitfuck',
  'shitfull',
  'shithead',
  'shithloe',
  'shithole',
  'shithouse',
  'shiting',
  'shitings',
  'shits',
  'shitstabber',
  'shitstabbers',
  'shitt',
  'shitted',
  'shitter',
  'shitters',
  'shitting',
  'shittings',
  'shitty',
  'shiz',
  'shlong',
  'shmegma',
  'shootingawad',
  'shota',
  'shrimping',
  'shutup',
  'side broadcasting',
  'sissy',
  'skag',
  'skank',
  'skaw',
  'skeet',
  'slackass',
  'slanteye',
  'sleaze',
  'sleazy',
  'sleeze',
  'slut',
  'slutdumper',
  'slutkiss',
  'sluts',
  'slutty',
  'sluttybitch',
  'smagna',
  'small hands',
  'smeg',
  'smegma',
  'smut',
  'smutty',
  'snarf',
  'snowballing',
  'snuff',
  'sodoff',
  'sodomite',
  'sodomize',
  'sodomy',
  'son-of-a-bitch',
  'sonofabitch',
  'sonofawhore',
  'souse',
  'soused',
  'spastic',
  'spaz\\.',
  'sperm',
  'spic',
  'spick',
  'spik',
  'spiks',
  'spit-roast',
  'spit-roasting',
  'spitroast',
  'spitroasting',
  'splooge',
  'splooge moose',
  'spooge',
  'spread legs',
  'spunk',
  'spunking',
  'squaw',
  'stfu',
  'stiffy',
  'strap on',
  'strapon',
  'strappado',
  'strollop',
  'strumpet',
  'style doggy',
  'suckmydick',
  'suckster',
  'suicide girls',
  'sultry women',
  'sumofabiatch',
  'surah',
  'swinger',
  't\\.wat',
  't0sser',
  't0ssers',
  't1t',
  't1tt1e5',
  't1tties',
  'taint',
  'tainted love',
  'tawdry',
  'teabagged',
  'teabagger',
  'teabagging',
  'teat',
  'teets',
  'the blacks',
  'the whites',
  'tickedoff',
  'tight white',
  'tit',
  'titfuck',
  'tits',
  'titt',
  'tittie5',
  'tittiefucker',
  'titties',
  'titty',
  'tittyfuck',
  'tittyfucker',
  'tittysucker',
  'tittywank',
  'titwank',
  'tity',
  'tnuc',
  'to55er',
  'to55ers',
  'toejam',
  'toggaf',
  'toke',
  'tongue in a',
  'toots',
  'tosser',
  'tossers',
  'tossurs',
  'towelhead',
  'tprtm',
  'trallop',
  'tramp',
  'tranny',
  'transsexual',
  'tribadism',
  'tribadist',
  'trollop',
  'trump train',
  'tub girl',
  'tubgirl',
  'tush',
  'tushy',
  'tvvat',
  'tvvats',
  'tw4t',
  'tw@',
  'tw@t',
  'tw@ts',
  'tw_t',
  'twank',
  'twat',
  'twathead',
  'twats',
  'twatt',
  'twattish',
  'twatty',
  'two girls one cup',
  'twunt',
  'twunter',
  'twunts',
  'tyt',
  'undies',
  'undressing',
  'unsuitable',
  'unwed',
  'upskirt',
  'upthebutt',
  'urethra play',
  'urophilia',
  'uterus',
  'v14gra',
  'v1gra',
  'vag',
  'vagina',
  'valium',
  'venus mound',
  'verticaltaco',
  'violet blue',
  'violet wand',
  'vixen',
  'vorarephilia',
  'voyeur',
  'vp-rx',
  'vprx',
  'vulgar',
  'vulva',
  'w#nker',
  'w#nkers',
  'w\\.ank',
  'w00se',
  'w0g',
  'w0gs',
  'w4nker!',
  'w4nkers!',
  'w@nk',
  'w@nker',
  'w@nkers',
  'w@nks',
  'wank',
  "wank's",
  'wanka',
  'wanke\\$',
  'wanked',
  'wanker',
  'wankers',
  'wanking',
  'wanks',
  'wanky',
  'warez',
  'wazoo',
  'weatback',
  'weenie',
  'weewee',
  'weiner',
  'wench',
  'wet dream',
  'wetback',
  'wetspam',
  'wh0re',
  'wh0reface',
  'whanker',
  'whankers',
  'white power',
  'whiz',
  'whoar',
  'whoralicious',
  'whorealicious',
  'whored',
  'whoredog',
  'whoreface',
  'whorehopper',
  'whorehouse',
  'whores',
  'whoring',
  'wigger',
  'willies',
  'wiseass',
  'wizzer',
  'wktkf',
  'wkwl',
  'women rapping',
  'wop',
  'wrapping men',
  'wrinkled starfish',
  'wwwhack',
  'xrse',
  'xrseh',
  'xrsehol',
  'xrsehole',
  'xxxhole',
  'y!ddo!',
  'y!ddo!!',
  'yadong',
  'yaoi',
  'yarichin',
  'yariman',
  'yeasty',
  'yellow showers',
  'yellowratbastard',
  'yid',
  'yido',
  'yiffy',
  'yobbo',
  'young turks',
  'zachariahbishop',
  'zasal',
  'zoophile',
  'zoophilia',
].join('|');

const BADEMOJI = [
  '\\u{1F4A9}', // pile of poo
  '\\u{1F595}', // middle finger
].join('|');

const BADWORDS = new RegExp(`(\\b(${BADWORDS_EN})\\b)|(${BADEMOJI})`, 'igu');

function filterProfanity(text: string | null | undefined) {
  return text?.replace(BADWORDS, '****');
}

export { filterProfanity };
