import storage from 'redux-persist/lib/storage';
import feed from '@store/feedSlice';
import schedule from '@store/scheduleSlice';
import sequence from '@store/sequenceSlice';
import subscriber from '@store/subscriberSlice';
import service from '@store/serviceSlice';
import video from '@store/videoSlice';
import organization from '@store/organizationSlice';
import ui from '@store/uiSlice';
import { combineReducers, createAction } from '@reduxjs/toolkit';

export const resetApp = createAction('root/resetApp');

// Combine Reducers
const appReducer = combineReducers({
  feed,
  organization,
  schedule,
  sequence,
  subscriber,
  service,
  video,
  ui,
});

// @ts-expect-error
const rootReducer = (state, action) => {
  if (resetApp.match(action)) {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
