import styled from 'styled-components';
import { Flex, Input } from '@lifechurch/react-ion';
import { MediumUpQuery } from '@utils/responsive';

export const AudienceRoot = styled.div`
  margin-block-start: 24px;
  height: 100%;
  word-break: break-word;

  @media ${MediumUpQuery} {
    margin-block-start: 48px;
  }
`;

interface SearchProps {
  hasFilter: boolean;
}

export const Search = styled.div<SearchProps>`
  width: 150px;
  transition: width 250ms ease-out;

  &:focus-within {
    width: ${props => (props.hasFilter ? 'calc(100% - 120px)' : '100%')};
  }

  &:focus-within + div {
    display: none;
  }
`;

export const SearchInput = styled(Input)``;

export const ScrollUpButton = styled(Flex)<{ newSubscribers: boolean }>`
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  right: 8px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: ${props => (props.newSubscribers ? '16px' : '50%')};
  padding: ${props =>
    props.newSubscribers
      ? `${props.theme.space[3]}px ${props.theme.space[4]}px`
      : `${props.theme.space[4]}px`};
`;

export const NewViewersButton = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  right: 8px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: 16px;
`;
