import React, { ChangeEventHandler } from 'react';
import Globe from '@components/icons/globe';
import { useTranslation } from 'react-i18next';
import { IconSelect, Box } from '@lifechurch/react-ion';
import { LanguageOption } from '@store/uiSlice';

interface Props {
  setLanguage: (newLanguage: string, oldLanguage: string) => void;
  languageOptions: Array<LanguageOption>;
  currentLanguage: string;
  isOpen?: boolean;
}

const LanguageSelector: React.FunctionComponent<Props> = ({
  setLanguage,
  languageOptions,
  currentLanguage,
  isOpen = false,
}: Props) => {
  const { i18n } = useTranslation();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
    const { value } = event.target;
    if (currentLanguage !== value) {
      setLanguage(value, currentLanguage);
      i18n.changeLanguage(value);
    }
  };

  const fallbackCodes = i18n.services.languageUtils.getFallbackCodes(
    i18n.options.fallbackLng,
    currentLanguage
  );
  const selectedLanguage = languageOptions.find(({ code }) => code === currentLanguage)
    ? currentLanguage
    : fallbackCodes.find((fallback: string) =>
        languageOptions.find(({ code }) => code === fallback)
      );

  return (
    <Box m={'32px auto 16px'}>
      <IconSelect
        onChange={handleChange}
        value={selectedLanguage}
        icon={<Globe />}
        name='languageSelector-select'
        tabIndex={isOpen ? 0 : -1}
      >
        {languageOptions.map(language => {
          if (language) {
            return (
              <option key={language.code || undefined} value={language.code || undefined}>
                {language.name}
              </option>
            );
          } else {
            return null;
          }
        })}
      </IconSelect>
    </Box>
  );
};

export default React.memo<Props>(LanguageSelector);
