import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const RaisedHandIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M5.5 1H4V8H3.5V3H2V9H3V9.00007H2V11.0001C2 12.6569 3.34315 14.0001 5 14.0001H8C9.3988 14.0001 10.574 13.0427 10.9061 11.7475C11.2151 10.7745 11.6259 10.3501 12.1789 9.77894L12.1791 9.7787C12.2499 9.70562 12.323 9.63013 12.3984 9.55078L12.3982 9.55078L13.7518 8.19718C14.0772 7.87175 14.0772 7.34411 13.7518 7.01867C13.1009 6.3678 12.0456 6.3678 11.3947 7.01867L10.4567 7.95674C10.1036 8.30977 9.5 8.05974 9.5 7.56047V7.00007V2H8V7.00007H7.5V1H6V7.00007H5.5V1Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
