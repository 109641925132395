import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const AngleRightCircleOIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}) => {
  switch (size) {
    case IconSize.SM:
      return (
        <BaseIcon
          draw='M12.5 7.49998C12.5 10.2614 10.2614 12.5 7.5 12.5C4.73858 12.5 2.5 10.2614 2.5 7.49998C2.5 4.73856 4.73858 2.49998 7.5 2.49998C10.2614 2.49998 12.5 4.73856 12.5 7.49998ZM13.5 7.49998C13.5 10.8137 10.8137 13.5 7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.49998C1.5 4.18628 4.18629 1.49998 7.5 1.49998C10.8137 1.49998 13.5 4.18628 13.5 7.49998ZM9.85355 7.14643L6.85355 4.14643L6.14645 4.85354L8.79289 7.49998L6.14645 10.1464L6.85355 10.8535L9.85355 7.85354L10.2071 7.49998L9.85355 7.14643Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12.7071 9.29289L9.20711 5.79289L7.79289 7.20711L10.5858 10L7.79289 12.7929L9.20711 14.2071L12.7071 10.7071L13.4142 10L12.7071 9.29289Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.7071 11.2929L10.7071 6.29289L9.29289 7.70711L13.5858 12L9.29289 16.2929L10.7071 17.7071L15.7071 12.7071L16.4142 12L15.7071 11.2929Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.XL:
      return (
        <BaseIcon
          draw='M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16ZM21.2071 15.2929L13.7071 7.79289L12.2929 9.20711L19.0858 16L12.2929 22.7929L13.7071 24.2071L21.2071 16.7071L21.9142 16L21.2071 15.2929Z'
          size={size}
          {...rest}
        />
      );
  }
};
