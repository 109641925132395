import VisibleSignupMoment from './signupMoment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SignupMoment as SignupMomentInterface } from '../dux';
import { getCurrentOrganization, getIntegrationTypes } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';
import { setModal, clearAlertBySubtype } from '@store/uiSlice';
import { IntegrationType } from '../../../../../../__generated__/globalTypes';

interface StateProps {
  organizationName: string;
}

interface OwnProps {
  moment: SignupMomentInterface;
  onDismiss: () => void;
  isPinned: boolean;
  externalLoginUrl?: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const integrationTypes = getIntegrationTypes(state);
  let externalLoginUrl;
  if (integrationTypes.includes(IntegrationType.OPEN_ID_CONNECT)) {
    externalLoginUrl = '/auth/openid_connect';
  } else if (integrationTypes.includes(IntegrationType.LEGACY_AUTH)) {
    externalLoginUrl = '/auth/legacy';
  }

  return {
    organizationName: getCurrentOrganization(state).name,
    externalLoginUrl,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setModal, clearAlertBySubtype }, dispatch);

export type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisibleSignupMoment);
