import React, { useState } from 'react';
import { PrayerMoment } from '../../prayer';
import { Preview } from '../index';
import { HasText } from '../components';
const defaultButtonText = 'Button Text';
const defaultText = 'Your text goes here';
export const PrayerMomentPreview = ({ buttonText, text }) => {
    const [isPinned, setIsPinned] = useState(true);
    const onClick = () => setIsPinned(false);
    return (React.createElement(Preview, null,
        React.createElement(HasText, { marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0, p: isPinned ? 0 : 3, paddingBlockStart: 0, hasText: !!text, hasButtonText: !!buttonText },
            React.createElement(PrayerMoment, { text: text || defaultButtonText, buttonText: buttonText || defaultText, isPinned: isPinned, onDismiss: () => setIsPinned(false), onClick: onClick }))));
};
