import {
  border,
  BorderProps,
  compose,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import styled, { css } from 'styled-components';
import { InputSize } from './Input';
import { logical, LogicalProps } from './logical';

type AffixInputProps = {
  valid?: boolean;
  error?: boolean;
  focused?: boolean;
  inputSize: InputSize;
} & BorderProps &
  SpaceProps &
  LogicalProps &
  TypographyProps;

const affixSizeVariant = variant({
  prop: 'inputSize',
  variants: {
    sm: {
      fontSize: 2,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 7,
      paddingBlockEnd: 7,
    },
    md: {
      fontSize: 3,
      lineHeight: 2,
      paddingInlineStart: 12,
      paddingInlineEnd: 12,
      paddingBlockStart: 9,
      paddingBlockEnd: 9,
    },
    lg: {
      fontSize: 4,
      lineHeight: 3,
      paddingInlineStart: 5,
      paddingInlineEnd: 16,
      paddingBlockStart: 11,
      paddingBlockEnd: 12,
    },
  },
});

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const Affix = styled.div<AffixInputProps>`
  background: ${props => props.theme.colors.gray10};
  border: 1px solid ${props => props.theme.colors.gray30};
  user-select: none;
  ${compose(border, space, logical, typography, affixSizeVariant)};
`;

export const Prefix = styled(Affix)`
  border-inline-end: 0;
  ${props =>
    props.theme &&
    css`
      border-top-left-radius: ${props => props.theme.radii[2]}px;
      border-bottom-left-radius: ${props => props.theme.radii[2]}px;
      [dir='rtl'] &&& {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: ${props => props.theme.radii[2]}px;
        border-bottom-right-radius: ${props => props.theme.radii[2]}px;
      }
    `}
`;
export const Suffix = styled(Affix)`
  border-inline-start: 0;
  ${props =>
    props.theme &&
    css`
      border-top-right-radius: ${props => props.theme.radii[2]}px;
      border-bottom-right-radius: ${props => props.theme.radii[2]}px;
      [dir='rtl'] &&& {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
        border-top-left-radius: ${props => props.theme.radii[2]}px;
        border-bottom-left-radius: ${props => props.theme.radii[2]}px;
      }
    `}
`;
