import React from 'react';
import { Button, Icon, Text } from './styles';
import ChatNotification from '../icons/chatNotification';
import { Props } from './';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

const PrayerButton: React.FunctionComponent<Props> = ({
  currentSubscriber,
  inChat = false,
  setNickname,
  momentId,
  requestOfflinePrayer,
  requestLivePrayer,
  isLive,
  isLivePrayerEnabled,
  hasOfflinePrayerRecipients,
  isGuest,
}) => {
  const { t } = useTranslation();

  if ((!isLivePrayerEnabled && !hasOfflinePrayerRecipients) || !isGuest) {
    return null;
  }

  const handleRequestLivePrayer = debounce(() => {
    requestLivePrayer({ momentId });
  }, 500);

  const handleRequest = () => {
    if (isLivePrayerEnabled && currentSubscriber.nickname) {
      handleRequestLivePrayer();
    } else if (!isLivePrayerEnabled) {
      requestOfflinePrayer();
    } else {
      setNickname(momentId);
    }
  };

  return (
    <Button onClick={handleRequest} data-testid='livePrayer-button' inChat={inChat} isLive={isLive}>
      <Icon isLive={isLive} inChat={inChat}>
        <ChatNotification />
      </Icon>
      <Text>{t('buttons.request_prayer')}</Text>
    </Button>
  );
};

export default PrayerButton;
