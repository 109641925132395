import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const WestGlobeIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM5.99999 8.00009L4.87625 6.87636C5.80367 5.3584 7.38669 4.28466 9.23164 4.04883L10.5 5.00009L9.49999 6.50009L11 7.50009L12 5.50009L13.9509 6.67061C14.2298 6.83797 14.2772 7.2229 14.0472 7.45291L12 9.50009V12.0001H11.5L11 11.0001H9.49999L8.99999 11.5001L9.49999 12.5001L11.5 13.5001H13L13.9853 14.4854C13.1616 15.2178 12.1348 15.7267 11 15.9171V14.0001H8.49999L5.99999 10.5001V8.00009Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
