import { ReactionType } from '../dux';
import ReactionTray from './reactionTray';

interface StateProps {
  type: ReactionType;
  changeSkinTone: (skinTone: number) => void;
}

export type Props = StateProps;
export default ReactionTray;
