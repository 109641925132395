import { Box, Flex, getInset } from '@lifechurch/react-ion';
import styled from 'styled-components';
export const ModalWrapper = styled(Flex) `
  flex-direction: column;
  position: absolute;
  padding: 140px 16px;
  background-color: rgba(30, 31, 35, 0.5);
  z-index: 1000;
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  margin-block-end: -56px;
`;
export const Modal = styled(Box) `
  position: relative;
  width: 100%;
  border-radius: ${props => props.theme.radii[2]}px;
  background-color: ${props => props.theme.colors.white};
  margin: auto;
  padding: ${props => props.theme.space[5]}px;
  text-align: start;
`;
