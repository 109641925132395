import { createSelector } from 'reselect';
import { RootState } from '@store/rootReducer';
import { UIState, LanguageOption, GeoData, RefetchRequestInterface } from '@store/uiSlice';
import { Pane, PaneType } from '@features/pane/dux';
import { AlertInterface } from '@components/Alert/dux';
import { Modal } from '@components/modal/dux';
import { isOffline } from '@store/serviceSlice/selectors';
import { isHost } from '@store/subscriberSlice/selectors';

const ID = 'ui';
const local = (state: RootState) => state[ID];

export const getGeoData = (state: RootState): GeoData => local(state).geo;

export const getLanguages = (state: RootState): Array<LanguageOption> =>
  local(state).languageOptions;

export const getCurrentLanguage = (state: RootState): string => local(state).currentLanguage;

export const isLargeHostVideo = (state: RootState): boolean => local(state).largeHostVideo;

export const isSideMenuOpen = (state: RootState): boolean => local(state).sideMenuOpen;

export const isModalOpen = (state: RootState): boolean => !!local(state).modal;

export const isHeartbeatStarted = createSelector<RootState, UIState, boolean>(
  local,
  state => state.isHeartbeatStarted
);

export const isNavMenuExpanded = createSelector<RootState, UIState, boolean>(
  local,
  state => state.nav.navMenuExpanded
);

export const hasServiceBeenFetched = createSelector<RootState, UIState, boolean>(
  local,
  state => state.serviceFetched
);

export const getAlerts = createSelector<RootState, UIState, AlertInterface[]>(
  local,
  state => state.alerts
);

export const getModal = createSelector<RootState, UIState, Modal | undefined>(
  local,
  state => state.modal
);

export const getPreviousModal = createSelector<RootState, UIState, Modal | undefined>(
  local,
  state => state.previousModal
);

export const isReactionBannerVisible = createSelector<RootState, UIState, boolean>(
  local,
  state => state.reactionBanner.isVisible
);

export const getRequestedRefetches = createSelector<RootState, UIState, RefetchRequestInterface[]>(
  local,
  state => state.requestedRefetches
);
export const previewAsUser = createSelector<RootState, UIState, boolean | undefined>(
  local,
  state => state.viewingAsUser
);

export const isHostViewingAsHost = (state: RootState): boolean =>
  isHost(state) && !previewAsUser(state);

export const shouldShowError = (state: RootState): boolean => state.ui.showError;

export const getPane = (state: RootState): Pane =>
  !isHostViewingAsHost(state) && isOffline(state) && local(state).pane.type === PaneType.SERVICE
    ? { type: PaneType.SCHEDULE }
    : local(state).pane;
