import { PublicSubscriber as Subscriber, PublicChannel as Channel } from '../../../../types';

export enum NotificationType {
  JOINED_CHANNEL = 'JOINED_CHANNEL',
  LEFT_CHANNEL = 'LEFT_CHANNEL',
  SUBSCRIBER_MUTED = 'SUBSCRIBER_MUTED',
}

export interface Notification {
  id: string;
  type: NotificationType;
  channel: Channel;
  subscriber: Subscriber;
  host: Subscriber | null;
  timestamp: string;
}
