import React from 'react';
import { Flex } from '@lifechurch/react-ion';
export const ResponseCount = ({ count, hasSubmitted, isPinned }) => {
    const digits = count.toString().length;
    const size = digits > 1 ? 8 * digits + 12 : 24;
    let backgroundColor = 'gray5';
    if (!hasSubmitted) {
        backgroundColor = isPinned ? 'whiteTransparent20' : 'action.light';
    }
    return (React.createElement(Flex, { alignItems: 'center', justifyContent: 'center', borderRadius: '24px', backgroundColor: backgroundColor, color: hasSubmitted ? 'gray100' : undefined, minWidth: size, height: 24, fontWeight: 1, m: '3px', marginInlineStart: 3, style: { transition: 'min-width 200ms ease-in-out' } }, count));
};
