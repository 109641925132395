import React, { createRef, KeyboardEventHandler, PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useOnClickOutside } from '@churchonline/hooks';
import { TimesIcon } from './icons';
import { Box, BoxProps } from './Box';
import { Flex } from './Flex';
import { defaultColors } from './theme';
import { FlexProps, variant } from 'styled-system';
import FocusTrap from 'focus-trap-react';
import { getInset } from './logical';

interface ModalProps {
  ariaLabel?: string;
  dismiss: () => void;
  dismissible?: boolean;
  dismissTestId?: string;
  includePadding?: boolean;
  open?: boolean;
  size?: ModalSize;
  testId?: string;
}

interface StyledModalProps {
  includePadding: boolean;
  size: ModalSize;
}

export enum ModalSize {
  NARROW = 'narrow',
  DEFAULT = 'default',
  WIDE = 'wide',
}

const modalSizeVariant = variant({
  prop: 'size',
  variants: {
    narrow: {
      maxWidth: '460px',
    },
    default: {
      maxWidth: '768px',
    },
    wide: {
      maxWidth: '1024px',
    },
  },
});

const FreezeBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalWrapper = styled(Flex)`
  padding: ${props => props.theme.space[9]}px ${props => props.theme.space[5]}px;
  position: fixed;
  overflow-y: auto;
  background-color: rgba(30, 31, 35, 0.5);
  width: 100vw;
  z-index: 1000;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetBlockEnd', 0)};
  ${getInset('insetInlineStart', 0)};
  ${getInset('insetInlineEnd', 0)};
`;

const StyledModal = styled(Box)<StyledModalProps>`
  position: relative;
  width: 100%;
  border-radius: ${props => props.theme.radii[2]}px;
  background-color: ${props => props.theme.colors.white};
  margin: auto;
  padding: ${props => (props.includePadding ? props.theme.space[6] : 0)}px;
  text-align: start;
  ${modalSizeVariant}
`;

const DismissButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  outline: none;
  ${getInset('insetBlockStart', 0)};
  ${getInset('insetInlineEnd', 0)};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 6px;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 6px;

  &:hover {
    svg {
      path {
        fill: ${(props): string => props.theme.colors.gray100};
      }
    }
  }

  &:focus {
    svg {
      path {
        fill: ${(props): string => props.theme.colors.gray100};
      }
    }
  }
`;

const ModalText: React.FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box paddingInlineEnd={32} {...props}>
    {children}
  </Box>
);

const ModalButtons: React.FunctionComponent<FlexProps> = ({ children, ...props }) => (
  <Flex justifyContent='flex-end' {...props}>
    {children}
  </Flex>
);

const Modal: React.FunctionComponent<ModalProps> = ({
  ariaLabel,
  children,
  dismiss,
  dismissible = true,
  dismissTestId,
  includePadding = true,
  open = true,
  size = ModalSize.DEFAULT,
  testId,
}: PropsWithChildren<ModalProps>) => {
  const modalRef = createRef<HTMLDivElement>();
  useOnClickOutside(modalRef, () => dismiss());

  if (!open) return null;

  const handleModalKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      dismiss();
    }
  };

  return (
    <ModalWrapper>
      <FocusTrap>
        <StyledModal
          ref={modalRef}
          size={size}
          includePadding={includePadding}
          data-testid={testId}
          onKeyDown={handleModalKeyDown}
        >
          {dismissible && (
            <DismissButton
              onClick={dismiss}
              type='button'
              aria-label={ariaLabel ? `${ariaLabel} Close` : 'Close'}
              data-testid={dismissTestId}
            >
              <TimesIcon color={defaultColors.gray50} />
            </DismissButton>
          )}
          {children}
        </StyledModal>
      </FocusTrap>
      {open && <FreezeBody />}
    </ModalWrapper>
  );
};

export { Modal, ModalText, ModalButtons };
