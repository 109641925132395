import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { validEmail } from '@utils/core';
import { Props } from './index';
import {
  FancyInput,
  FormBanner,
  FormBannerType,
  LinkButton,
  Modal,
  ModalButtons,
  ModalSize,
  OHeaderThree,
  OParagraph,
  PrimaryButton,
} from '@lifechurch/react-ion';

type RequestPasswordResetState = {
  email: string;
  emailBlank: boolean;
  emailInvalid: boolean;
  sent: boolean;
};

const RequestPasswordReset = ({ closeModal, requestPasswordReset }: Props) => {
  const { t } = useTranslation('forms');

  const [values, setValues] = useState<RequestPasswordResetState>({
    email: '',
    emailBlank: false,
    emailInvalid: false,
    sent: false,
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues({ ...values, [name]: value, emailBlank: false, emailInvalid: false });
  };

  const handleSend = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (values.email !== '' && validEmail(values.email)) {
      requestPasswordReset(values.email);
      setValues({ ...values, sent: true });
    } else {
      setValues({
        ...values,
        emailBlank: values.email === '',
        emailInvalid: !validEmail(values.email) && values.email !== '',
      });
    }
  };

  if (values.sent) {
    return (
      <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='requestPasswordResetAffirm-modal'>
        <OHeaderThree marginBlockEnd={5}>{t('request_password_reset.title')}</OHeaderThree>
        <FormBanner type={FormBannerType.Success} withIcon>
          <OParagraph>
            <Trans ns='forms' i18nKey='request_password_reset.confirmation'>
              If the email exists, we&apos;ve sent the instructions to {{ email: values.email }}.
            </Trans>
          </OParagraph>
        </FormBanner>
        <ModalButtons marginBlockStart={6}>
          <LinkButton onClick={closeModal}>{t('request_password_reset.close')}</LinkButton>
        </ModalButtons>
      </Modal>
    );
  } else {
    return (
      <Modal dismiss={closeModal} size={ModalSize.NARROW} testId='requestPasswordReset-modal'>
        <OHeaderThree marginBlockEnd={3}>{t('request_password_reset.title')}</OHeaderThree>
        <OParagraph marginBlockEnd={5}>{t('request_password_reset.description')}</OParagraph>
        <form onSubmit={handleSend}>
          <FancyInput
            type='email'
            name='email'
            id='email'
            value={values.email}
            label={t('request_password_reset.email')}
            onChange={onChange}
            error={values.emailBlank || values.emailInvalid}
            hint={(() => {
              if (values.emailBlank) {
                return t('request_password_reset.blank_email');
              }
              if (values.emailInvalid) {
                return t('request_password_reset.invalid_email');
              }
              return null;
            })()}
          />
          <ModalButtons marginBlockStart={6}>
            <PrimaryButton onClick={handleSend} type='submit'>
              {t('request_password_reset.send')}
            </PrimaryButton>
          </ModalButtons>
        </form>
      </Modal>
    );
  }
};

export default RequestPasswordReset;
