import { setPaneToChat } from '@store/uiSlice';
import { AlertInterface } from '../dux';
import { Dispatch } from 'redux';
import DirectChatAlert from './DirectChatAlert';
import { connect } from 'react-redux';

interface OwnProps {
  alert: AlertInterface;
  dismissAlert: () => void;
}

interface DispatchProps {
  setPaneToChat: (channelKey: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPaneToChat: (channelKey: string) => dispatch(setPaneToChat(channelKey)),
});

const VisiblePrayerAlert = connect(null, mapDispatchToProps)(DirectChatAlert);

export type Props = OwnProps & DispatchProps;
export default VisiblePrayerAlert;
