import { useQuery } from '@apollo/client';
import { HostChatOccupancy } from '@io/__generated__/HostChatOccupancy';
import { hostChatOccupancy } from '@io/queries';
import { SubscriberType } from '../../__generated__/globalTypes';
import { Host } from '@lifechurch/react-ion';

interface HookReturn {
  numberOfHosts: number | null;
  hosts: Host[];
}

export const useHostCount = (): HookReturn => {
  const { data } = useQuery<HostChatOccupancy>(hostChatOccupancy, {
    pollInterval: 30000,
    variables: {
      filter: {
        subscriberTypeIn: [SubscriberType.HOST, SubscriberType.ADMIN, SubscriberType.OWNER],
      },
    },
  });

  const feed = data?.currentService.feed ?? [];
  const hostChannelFeed = feed.find(channel => channel.type === 'public');

  // @ts-expect-error typescript is being too strict here
  const numberOfHosts = hostChannelFeed ? hostChannelFeed.subscribers.totalConnected : null;
  const publicFeed = data?.currentService.feed?.find(feed => feed.type === 'public');

  // @ts-expect-error typescript is being too strict here
  const hosts = publicFeed?.subscribers.nodes.reduce((acc, subscriber) => {
    if (subscriber.avatar || subscriber.firstName) {
      acc.push({ avatar: subscriber.avatar, nickname: `${subscriber.firstName}` });
    }
    return acc;
  }, []);

  return { numberOfHosts, hosts };
};
