import React from 'react';
import { IconProps, IconSize } from './icons';
import { useTimeout } from '@churchonline/hooks';

export interface FuseProps extends IconProps {
  /**
   * The color of the fuse.
   * @type string
   */
  color?: string;
  /**
   * The time it takes for the fuse to "burn out".
   * @type number
   */
  duration: number;
  /**
   * Controls whether or not the fuse "re-lights" after it has finished.
   * @type boolean
   */
  repeat?: boolean;
  /**
   * If true, allows the user to prematurely end the fuse.
   * @type boolean
   */
  dismissible?: boolean;
  /**
   * Function that will be executed when the fuse burns out.
   */
  onEnded?: () => void;
  /**
   * The size of the fuse.
   * @type IconSize
   */
  size: IconSize;
}

export function Fuse({
  duration,
  onEnded = () => {},
  dismissible = false,
  size = IconSize.MD,
  color = 'white',
  repeat = false,
}: FuseProps) {
  useTimeout(onEnded, duration);

  const createFuse = (height: number, strokeWidth: number) => {
    const circumference = (height - strokeWidth) * 3.14;
    const radius = height / 2;

    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={height}
        height={height}
        viewBox={`0 0 ${height} ${height}`}
        fill='none'
      >
        <circle
          strokeDashoffset={circumference}
          strokeDasharray={circumference}
          fill='none'
          stroke={color}
          strokeWidth={strokeWidth}
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          transform={`rotate(-90 ${radius} ${radius})`}
        >
          <animate
            attributeType='CSS'
            attributeName='stroke-dashoffset'
            from='0'
            to={circumference}
            dur={`${duration}ms`}
            repeatCount={repeat ? 'indefinite' : undefined}
          />
        </circle>
      </svg>
    );
  };

  const createDismissibleFuse = (
    height: number,
    strokeWidth: number,
    lineStart: number,
    lineEnd: number
  ) => {
    const circumference = (height - strokeWidth) * 3.14;
    const radius = height / 2;

    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={height}
        height={height}
        viewBox={`0 0 ${height} ${height}`}
        fill='none'
      >
        <circle
          strokeDashoffset={circumference}
          strokeDasharray={circumference}
          fill='none'
          stroke={color}
          strokeWidth={strokeWidth}
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          transform={`rotate(-90 ${radius} ${radius})`}
        >
          <animate
            attributeType='CSS'
            attributeName='stroke-dashoffset'
            from='0'
            to={circumference}
            dur={`${duration}ms`}
            repeatCount={repeat ? 'indefinite' : undefined}
          />
        </circle>
        <line
          fill='none'
          stroke={color}
          strokeWidth={strokeWidth}
          x1={lineStart}
          y1={lineStart}
          x2={lineEnd}
          y2={lineEnd}
        />
        <line
          fill='none'
          stroke={color}
          strokeWidth={strokeWidth}
          x1={lineStart}
          y1={lineEnd}
          x2={lineEnd}
          y2={lineStart}
        />
      </svg>
    );
  };

  const renderFuse = () => {
    if (dismissible === true) {
      switch (size) {
        case IconSize.SM:
          return createDismissibleFuse(23, 1, 7, 16);
        case IconSize.MD:
          return createDismissibleFuse(32, 2, 10, 22);
        case IconSize.LG:
          return createDismissibleFuse(38, 2, 11, 27);
        case IconSize.XL:
          return createDismissibleFuse(44, 2, 12, 32);
      }
    } else {
      switch (size) {
        case IconSize.SM:
          return createFuse(15, 1);
        case IconSize.MD:
          return createFuse(20, 2);
        case IconSize.LG:
          return createFuse(24, 2);
        case IconSize.XL:
          return createFuse(32, 2);
      }
    }
  };

  return renderFuse();
}
