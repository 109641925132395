import { connect } from 'react-redux';
import { getIntegrations } from '@store/organizationSlice/selectors';
import { RootState } from '@store/rootReducer';
import Pixels from './pixels';
import { CurrentState_currentOrganization_integrations } from '@io/__generated__/CurrentState';

interface StateProps {
  integrations: CurrentState_currentOrganization_integrations[];
}

const mapStateToProps = (state: RootState): StateProps => {
  const integrations = getIntegrations(state);

  return {
    integrations,
  };
};

export type Props = StateProps;
export default connect(mapStateToProps)(Pixels);
