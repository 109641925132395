import React, { CSSProperties, FC, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { ROW_HEIGHT } from '@features/audience/constants';
import { SmallQuery } from '@utils/responsive';

const Scroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 100px); // -50 px accounts for the search and filter components

  @media ${SmallQuery} {
    height: calc(100% - 70px);
  }
`;

interface Props {
  onReachBottom: () => void;
  onReachTop: () => void;
  onScroll?: (scrollTop: number, previousScrollTop: number) => void;
  position?: number | null | undefined;
  onSetScrollPosition: () => void;
  style?: CSSProperties | undefined;
}

const BiDirectionalScroll: FC<Props> = ({
  children,
  onReachBottom,
  onReachTop,
  onScroll,
  onSetScrollPosition,
  position = null,
  style,
}) => {
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const previousScrollTop = React.useRef(0);

  useLayoutEffect(() => {
    if (position !== null) {
      setScrollPosition(position);
    }
  }, [position]);

  const setScrollPosition = (position: number) => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTo(0, position);
      previousScrollTop.current = position;
      onSetScrollPosition();
    }
  };

  const handleScroll = () => {
    if (!scrollerRef.current) return;

    const { firstChild, lastChild, scrollTop, offsetTop, offsetHeight } = scrollerRef.current;

    const topEdge = (firstChild as HTMLElement)?.offsetTop + ROW_HEIGHT;
    const bottomEdge =
      (lastChild as HTMLElement)?.offsetTop + (lastChild as HTMLElement)?.offsetHeight - ROW_HEIGHT;
    const scrolledUp = scrollTop + offsetTop;
    const scrolledDown = scrolledUp + offsetHeight;

    if (scrolledDown >= bottomEdge) {
      onReachBottom();
    } else if (scrolledUp <= topEdge) {
      onReachTop();
    }

    if (onScroll) {
      onScroll(scrollTop, previousScrollTop.current);
    }
    previousScrollTop.current = scrollTop;
  };

  return (
    <Scroll ref={scrollerRef} onScroll={handleScroll} style={style}>
      {children}
    </Scroll>
  );
};

export default BiDirectionalScroll;
