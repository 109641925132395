import { connect } from 'react-redux';
import RequestPasswordReset from './requestPasswordReset';
import { publishRequestPasswordReset } from '@store/subscriberSlice';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';

interface OwnProps {
  closeModal: () => void;
}

const mapStateToProps = (_state: RootState, ownProps: OwnProps) => ({
  ...ownProps,
});

interface DispatchProps {
  requestPasswordReset: (email: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestPasswordReset: (email: string) => dispatch(publishRequestPasswordReset(email)),
});

const VisibleRequestPasswordReset = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordReset);

export type Props = OwnProps & DispatchProps;
export default VisibleRequestPasswordReset;
