import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const SpeakerFullIcon: FunctionComponent<IconProps> = ({ size = IconSize.MD, ...rest }) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
    case IconSize.MD:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
    case IconSize.LG:
      return (
        <BaseIcon
          draw='M13 1L6 8H1V16H6L13 23V1ZM23 12C23 16.8379 19.5645 20.8734 15 21.8V19.748C18.4505 18.8599 21 15.7277 21 12C21 8.27235 18.4505 5.14015 15 4.25206V2.20006C19.5645 3.1266 23 7.16211 23 12ZM19 12C19 14.6125 17.3304 16.8349 15 17.6586V15.4649C16.1956 14.7733 17 13.4806 17 12C17 10.5195 16.1956 9.22678 15 8.53516V6.34144C17.3304 7.16511 19 9.38759 19 12Z'
          size={size}
          mirrorForRTL
          {...rest}
        />
      );
    case IconSize.XL:
      return <BaseIcon draw='' size={size} mirrorForRTL {...rest} />;
  }
};
