import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 24px;
  flex-shrink: 0;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  display: inline-block;
`;

const Toggle = styled.label`
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 56px;
  height: 24px;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.gray30};
  box-sizing: border-box;
  display: block;
  &:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.action.hover};
  }
  &:focus:not(:focus-visible) {
    border: 1px solid ${props => props.theme.colors.gray30};
    box-shadow: none;
  }

  ::after {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    top: 1px;
    ${css`
      left: 0;
      [dir='rtl'] & {
        left: unset;
        right: 0;
      }
    `}
    background-color: ${props => props.theme.colors.white};
    border-radius: 19px;
    border: 1px solid ${props => props.theme.colors.gray30};
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
`;

const HiddenCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
  ${css`
    left: -9999px;
    [dir='rtl'] &&& {
      left: unset;
      right: -9999px;
    }
  `}

  :checked + ${Toggle}::after {
    ${css`
      [dir='ltr'] & {
        transform: translateX(33px);
      }
      [dir='rtl'] & {
        transform: translateX(-33px);
      }
    `}
  }
`;

interface ToggleContentProps {
  checked?: boolean;
}

const ToggleContent = styled.span<ToggleContentProps>`
  position: absolute;
  top: 4px;
  ${props => {
    return css`
      [dir='rtl'] &&& {
        ${props.checked ? 'right: 9px' : 'right: 28px'}
      }
      [dir='ltr'] &&& {
        ${props.checked ? 'left: 9px' : 'left: 28px'}
      }
    `;
  }}
  height: 16px;
  width: 20px;
  display: inline-block;
`;

export { ToggleWrapper, HiddenCheckbox, Toggle, Container, ToggleContent };
