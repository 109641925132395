import { FollowUpType, SalvationMoment, SalvationMomentMode, } from '../../salvation/SalvationMoment';
import React from 'react';
import { Preview } from '../index';
import { PreviewWithModal } from '..';
function SalvationMomentPreview(props) {
    const { followUpType, mode, url, urlTestText } = props;
    if (mode === SalvationMomentMode.FOLLOW_UP && followUpType === FollowUpType.URL_MODAL) {
        return React.createElement(PreviewWithModal, { url: url, urlTestText: urlTestText || '' });
    }
    return (React.createElement(Preview, null,
        React.createElement("div", { style: { margin: '0 10px' } },
            React.createElement(SalvationMoment, { selector: '#moment-preview-modal', ...props })),
        React.createElement("div", { id: 'moment-preview-modal' })));
}
export { SalvationMomentPreview };
