import React, { forwardRef } from 'react';
import { Box, BoxProps } from './Box';

// Informational Typography
export const ITitle = forwardRef<HTMLHeadingElement, BoxProps>(
  ({ fontSize: _fontSize, fontWeight: _fontWeight, as = 'h1', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={[7, null, 9]} fontWeight={4} color='black' {...props} />
  )
);

export const ISubhead = forwardRef<HTMLHeadingElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'h2', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={[5, null, 6]} lineHeight={2} {...props} />
  )
);

export const ISectionHeader = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    { fontSize: _fontSize, fontWeight: _fontWeight, lineHeight: _lineHeight, as = 'h3', ...props },
    ref
  ) => (
    <Box
      ref={ref}
      as={as}
      fontSize={[6, null, 7]}
      fontWeight={3}
      color='black'
      lineHeight={2}
      {...props}
    />
  )
);

export const IFeatureParagraph = forwardRef<HTMLHeadingElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'h5', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={5} lineHeight={3} {...props} />
  )
);

export const IGroupHeader = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    { fontSize: _fontSize, fontWeight: _fontWeight, lineHeight: _lineHeight, as = 'h4', ...props },
    ref
  ) => (
    <Box
      ref={ref}
      as={as}
      fontSize={[5, null, 6]}
      fontWeight={3}
      lineHeight={2}
      color='black'
      {...props}
    />
  )
);

export const IBodyParagraph = forwardRef<HTMLParagraphElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'p', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={4} lineHeight={3} {...props} />
  )
);

export const IDetail = forwardRef<HTMLParagraphElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'p', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={3} lineHeight={3} {...props} />
  )
);

// Operational Typography
export const OTitle = forwardRef<HTMLHeadingElement, BoxProps>(
  ({ fontSize: _fontSize, fontWeight: _fontWeight, as = 'h1', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={7} fontWeight={4} color='black' {...props} />
  )
);

export const OHeaderOne = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    { fontSize: _fontSize, fontWeight: _fontWeight, lineHeight: _lineHeight, as = 'h1', ...props },
    ref
  ) => <Box ref={ref} as={as} fontSize={6} fontWeight={3} lineHeight={2} color='black' {...props} />
);

export const OHeaderTwo = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    { fontSize: _fontSize, fontWeight: _fontWeight, lineHeight: _lineHeight, as = 'h2', ...props },
    ref
  ) => <Box ref={ref} as={as} fontSize={5} fontWeight={3} lineHeight={2} color='black' {...props} />
);

export const OHeaderThree = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    { fontSize: _fontSize, fontWeight: _fontWeight, lineHeight: _lineHeight, as = 'h3', ...props },
    ref
  ) => <Box ref={ref} as={as} fontSize={4} fontWeight={3} lineHeight={2} color='black' {...props} />
);

export const OHeaderFour = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    {
      fontSize: _fontSize,
      lineHeight: _lineHeight,
      letterSpacing: _letterSpacing,
      textTransform: _textTransform,
      color = 'gray80',
      as = 'h4',
      ...props
    },
    ref
  ) => (
    <Box
      ref={ref}
      as={as}
      fontSize={3}
      lineHeight={2}
      letterSpacing='0.01875rem'
      textTransform='uppercase'
      color={color}
      {...props}
    />
  )
);

export const OHeaderFive = forwardRef<HTMLHeadingElement, BoxProps>(
  (
    {
      fontSize: _fontSize,
      lineHeight: _lineHeight,
      textTransform: _textTransform,
      color = 'gray80',
      as = 'h5',
      ...props
    },
    ref
  ) => (
    <Box
      ref={ref}
      as={as}
      fontSize={2}
      lineHeight={2}
      textTransform='uppercase'
      color={color}
      {...props}
    />
  )
);

export const OParagraph = forwardRef<HTMLParagraphElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'p', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={3} lineHeight={2} {...props} />
  )
);

export const OSmallParagraph = forwardRef<HTMLParagraphElement, BoxProps>(
  ({ fontSize: _fontSize, lineHeight: _lineHeight, as = 'p', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={2} lineHeight={2} {...props} />
  )
);

export const OXSmallParagraph = forwardRef<HTMLParagraphElement, BoxProps>(
  ({ fontSize: _fontsize, lineHeight: _lineHeight, as = 'p', ...props }, ref) => (
    <Box ref={ref} as={as} fontSize={1} lineHeight={2} {...props} />
  )
);
