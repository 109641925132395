import { Dispatch } from 'redux';
import { ReactionType } from '../dux';
import ReactionTrayButton from './reactionTrayButton';
import { closeReactionBanner } from '@store/uiSlice';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { isReactionBannerVisible } from '@store/uiSlice/selectors';
import { getCurrentSubscriber } from '@store/feedSlice/chatSelectors';
import { getSkinTone } from '@store/subscriberSlice/selectors';
import { saveSubscriber } from '@store/subscriberSlice';
interface OwnProps {
  type: ReactionType;
  handleReaction: (type: ReactionType | string) => void;
}
interface StateProps {
  subscriberSkinTone: number;
  currentSubscriberId: string;
  isBannerVisible: boolean;
  subscriberHasAccount: boolean;
}

interface DispatchProps {
  changeReactionSkinTone: (currentSubscriberId: string, skinTone: number) => void;
  closeReactionBanner: () => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    subscriberSkinTone: getSkinTone(state),
    currentSubscriberId: state.subscriber.currentSubscriber.id,
    isBannerVisible: isReactionBannerVisible(state),
    subscriberHasAccount: getCurrentSubscriber(state).email ? true : false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeReactionBanner: () => dispatch(closeReactionBanner()),
  changeReactionSkinTone: (currentSubscriberId, skinTone) => {
    dispatch(
      saveSubscriber({
        id: currentSubscriberId,
        input: {
          preferences: {
            skinTone,
          },
        },
      })
    );
  },
});

const VisibleReactionTrayButton = connect(mapStateToProps, mapDispatchToProps)(ReactionTrayButton);
export type Props = OwnProps & StateProps & DispatchProps;
export default VisibleReactionTrayButton;
