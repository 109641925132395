import { ButtonSize, HeartIcon, HeartOIcon, IconSize, LinkButton } from '@lifechurch/react-ion';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { EngagementWrapper, ReactionButton, LikeCount } from './styles';
const Engagement = ({ hasLiked = false, likeButtonLabel = 'Like', likeCount = 0, onLike, onShare, shareButtonLabel = 'Share', }) => {
    const { colors } = useContext(ThemeContext);
    const handleOnLike = e => {
        if (e.key === 'Enter') {
            onLike();
        }
    };
    const handleOnShare = e => {
        if (e.key === 'Enter') {
            onShare();
        }
    };
    return (React.createElement(EngagementWrapper, null,
        React.createElement(LinkButton, { buttonType: 'SECONDARY', size: ButtonSize.SM, marginInlineEnd: '16px', onClick: onShare, onKeyDown: handleOnShare, tabIndex: 0 }, shareButtonLabel),
        !hasLiked && (React.createElement(LinkButton, { buttonType: 'SECONDARY', hasLiked: hasLiked, marginInlineEnd: '4px', onClick: onLike, onKeyDown: handleOnLike, size: ButtonSize.SM, tabIndex: 0 }, likeButtonLabel)),
        React.createElement(ReactionButton, { "data-testid": 'like', hasLiked: hasLiked, onClick: onLike },
            likeCount > 0 ? (React.createElement(HeartIcon, { size: IconSize.SM, hoverColor: hasLiked ? colors.pink.default : colors.gray50 })) : (React.createElement(HeartOIcon, { size: IconSize.SM, hoverColor: hasLiked ? colors.pink.default : colors.gray50 })),
            likeCount > 0 && React.createElement(LikeCount, null, likeCount))));
};
export default Engagement;
