import React, { FC, KeyboardEventHandler } from 'react';
import { ButtonSize, Flex, LinkButton, LinkButtonType } from '@lifechurch/react-ion';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  onChat: () => void;
  onMute: () => void;
  onUnmute: () => void;
  isMuted: boolean;
}

const Menu = styled(Flex)`
  padding-inline-start: 36px;
  padding-block-end: ${props => props.theme.space[2]}px;
  padding-block-start: ${props => props.theme.space[2]}px;
  background-color: ${props => props.theme.colors.gray5};

  border-bottom-left-radius: ${props => props.theme.radii[1]}px;
  border-bottom-right-radius: ${props => props.theme.radii[1]}px;
`;

const Action = styled(LinkButton)`
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[3]}px;
  font-weight: ${props => props.theme.fontWeights[2]};
  cursor: default;

  &:focus {
    outline: none;
  }
`;

const ActionMenu: FC<Props> = ({ onChat, onMute, onUnmute, isMuted }) => {
  const { t } = useTranslation();

  const handleChatKeyPress: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      onChat();
    }
  };

  const handleMuteKeyPress: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter' && !isMuted) {
      onMute();
    }

    if (e.key === 'Enter' && isMuted) {
      onUnmute();
    }
  };

  return (
    <Menu>
      <Action onClick={onChat} onKeyPress={handleChatKeyPress} tabIndex={0} size={ButtonSize.SM}>
        {t('buttons.direct_chat')}
      </Action>

      <Action
        onClick={isMuted ? onUnmute : onMute}
        onKeyPress={handleMuteKeyPress}
        tabIndex={0}
        buttonType={LinkButtonType.ERROR}
        size={ButtonSize.SM}
      >
        {t(isMuted ? 'buttons.unmute_subscriber' : 'buttons.mute_subscriber')}
      </Action>
    </Menu>
  );
};

export default ActionMenu;
