import { connect } from 'react-redux';
import { FieldErrorType, Subscriber } from '../../../types';
import { Dispatch } from 'redux';
import { saveSubscriber, SaveSubscriberArgs } from '@store/subscriberSlice';
import { signUpCancel, signUpError, SignupMeta } from './dux';
import Signup from './signup';
import { getCurrentOrganization, getIntegrationTypes } from '@store/organizationSlice/selectors';
import { CurrentState_currentOrganization } from '@io/__generated__/CurrentState';
import { IntegrationType } from '../../../../__generated__/globalTypes';
import { login as loginModal } from '../dux';
import { errorAlert } from '../../Alert/dux';
import {
  setModal,
  updateSignupEmailAvailability,
  updateSignupNicknameAvailability,
} from '@store/uiSlice';
import { RootState } from '@store/rootReducer';

interface StateProps {
  error: boolean;
  currentSubscriber: Subscriber;
  nicknameUnavailable: boolean;
  emailUnavailable: boolean;
  organization: CurrentState_currentOrganization;
  hasFacebookLogin?: boolean;
}

interface OwnProps {
  closeModal: () => void;
  signUpSource: string;
  meta: SignupMeta;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps & OwnProps => {
  const integrations = getIntegrationTypes(state);
  return {
    error: ownProps.meta?.error || false,
    nicknameUnavailable: ownProps.meta?.nicknameUnavailable || false,
    emailUnavailable: ownProps.meta?.emailUnavailable || false,
    currentSubscriber: state.subscriber.currentSubscriber,
    organization: getCurrentOrganization(state),
    hasFacebookLogin: integrations.includes(IntegrationType.FACEBOOK_APP),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSubscriber: (payload: SaveSubscriberArgs) => dispatch(saveSubscriber(payload)),
  updateEmailAvailability: () => dispatch(updateSignupEmailAvailability()),
  updateNicknameAvailability: () => dispatch(updateSignupNicknameAvailability()),
  signUpCancel: (signUpId: string) => dispatch(signUpCancel(signUpId)),
  signUpError: (
    signUpId: string,
    nicknameError: FieldErrorType | null,
    emailError: FieldErrorType | null,
    passwordError: FieldErrorType | null,
    termsError: boolean
  ) => dispatch(signUpError({ signUpId, nicknameError, emailError, passwordError, termsError })),
  login: () => dispatch(setModal(loginModal())),
  imageTooLargeError: () => dispatch(errorAlert('max_file_size')),
  updateSettingsError: () => dispatch(errorAlert('update_settings_error')),
});

const VisibleSignup = connect(mapStateToProps, mapDispatchToProps)(Signup);

export type Props = StateProps & OwnProps & ReturnType<typeof mapDispatchToProps>;
export default VisibleSignup;
