import React, { FunctionComponent } from 'react';
import { BaseIcon } from './base';
import { IconProps, IconSize } from './types';

export const ImageIcon: FunctionComponent<IconProps> = ({
  size = IconSize.MD,
  ...rest
}: IconProps) => {
  switch (size) {
    case IconSize.SM:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.MD:
      return (
        <BaseIcon
          draw='M17 3.00003V1.75342L15.7831 2.02385L6.78307 4.02385L6 4.19786V5.00003V12.1707C5.68722 12.0602 5.35064 12 5 12C3.34315 12 2 13.3431 2 15C2 16.6569 3.34315 18 5 18C6.65684 18 7.99998 16.6569 8 15C8 15 8 15 8 15V5.80221L15 4.24665V10.1707C14.6872 10.0602 14.3506 10 14 10C12.3431 10 11 11.3431 11 13C11 14.6569 12.3431 16 14 16C15.6568 16 17 14.6569 17 13C17 13 17 13 17 13V3.00003Z'
          size={size}
          {...rest}
        />
      );
    case IconSize.LG:
      return <BaseIcon draw='' size={size} {...rest} />;
    case IconSize.XL:
      return <BaseIcon draw='' size={size} {...rest} />;
  }
};
