import dayjs from 'dayjs';
import { ApolloClient, ApolloLink, from, gql, HttpLink, InMemoryCache } from '@apollo/client';
import { CurrentState } from './__generated__/CurrentState';
import {
  MuteSubscriberMutation,
  MuteSubscriberMutationVariables,
} from './__generated__/MuteSubscriberMutation';
import {
  UnmuteSubscriberMutation,
  UnmuteSubscriberMutationVariables,
} from './__generated__/UnmuteSubscriberMutation';
import {
  SaveSubscriberMutation,
  SaveSubscriberMutationVariables,
} from './__generated__/SaveSubscriberMutation';
import {
  ChannelInput,
  MessageInput,
  RequestPrayerInput,
  SubscriberInput,
  MomentActionInput,
  MomentInstanceInput,
  AcceptInviteInput,
  ChangePasswordInput,
  RevertSubscriberInput,
  DeleteMomentInstanceInput,
  MessageActionInput,
} from '../../__generated__/globalTypes';
import {
  DeleteMessageMutation,
  DeleteMessageMutationVariables,
} from './__generated__/DeleteMessageMutation';
import {
  LeaveChannelMutation,
  LeaveChannelMutationVariables,
} from './__generated__/LeaveChannelMutation';
import {
  RequestInviteToChannel,
  RequestInviteToChannelVariables,
} from './__generated__/RequestInviteToChannel';
import {
  AcceptInviteToChannel,
  AcceptInviteToChannelVariables,
} from './__generated__/AcceptInviteToChannel';
import {
  ServicesConnectionQuery,
  ServicesConnectionQueryVariables,
} from './__generated__/ServicesConnectionQuery';
import { ServiceAtQuery, ServiceAtQueryVariables } from './__generated__/ServiceAtQuery';
import { SequenceQuery, SequenceQueryVariables } from './__generated__/SequenceQuery';
import {
  JoinChannelMutation,
  JoinChannelMutationVariables,
} from './__generated__/JoinChannelMutation';
import {
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from './__generated__/RequestPasswordResetMutation';
import {
  DeleteSelfMutation,
  DeleteSelfMutationVariables,
} from './__generated__/DeleteSelfMutation';
import {
  RequestChannelQuery,
  RequestChannelQueryVariables,
} from './__generated__/RequestChannelQuery';
import {
  RequestPrayerMutation,
  RequestPrayerMutationVariables,
} from './__generated__/RequestPrayerMutation';
import { v4 as uuidv4 } from 'uuid';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import possibleTypes from '../types/possibleTypes.json';
import {
  SubmitMomentActionMutation,
  SubmitMomentActionMutationVariables,
} from './__generated__/SubmitMomentActionMutation';
import {
  SaveMomentInstanceMutation,
  SaveMomentInstanceMutationVariables,
} from './__generated__/SaveMomentInstanceMutation';
import { DeleteMomentInstanceVariables } from './__generated__/DeleteMomentInstance';
import {
  AddLanguageToChannels,
  AddLanguageToChannelsVariables,
} from './__generated__/AddLanguageToChannels';
import {
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
} from './__generated__/AcceptInviteMutation';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from './__generated__/ChangePasswordMutation';
import {
  RevertSubscriberMutation,
  RevertSubscriberMutationVariables,
} from './__generated__/RevertSubscriberMutation';
import { CI_COMMIT_SHA } from '../constants';
import {
  SubmitMessageActionMutation,
  SubmitMessageActionMutationVariables,
} from './__generated__/SubmitMessageActionMutation';

const subscriberFields = gql`
  fragment SubscriberFields on Subscriber {
    id
    nickname
    avatar
    firstName
    lastName
    email
    gdpr
    emailConsent
    roleIdentifier {
      type
      key
      label
    }
    role {
      label
      type
      permissions {
        key
      }
    }
    pubnubAuthKey
    preferences {
      textMode
      skinTone
    }
  }
`;

const serviceFields = gql`
  fragment ServiceFields on Service {
    id
    startTime
    scheduleTime
    endTime
    content {
      id
      features {
        publicChat
        livePrayer
      }
      hostInfo
      notes
      title
      hasVideo
      video {
        type
        url
        source
      }
      videoStartTime
    }
    sequence {
      serverTime
      steps {
        fetchTime
        queries
        transitionTime
      }
    }
    feed {
      id
      key
      type
      name
      direct
      group
      ... on DirectChannel {
        subscribers(filter: { subscriberTypeIn: [HOST, USER, OWNER, GLOBAL, ALIAS_GUEST, ADMIN] }) {
          nodes {
            id
            nickname
            avatar
            roleIdentifier {
              label
              key
              type
            }
          }
        }
      }
      ... on PrayerChannel {
        subscribers(filter: { subscriberTypeIn: [HOST, USER, OWNER, GLOBAL, ALIAS_GUEST, ADMIN] }) {
          nodes {
            id
            nickname
            avatar
            roleIdentifier {
              label
              key
              type
            }
          }
        }
      }
    }
  }
`;

const organizationFields = gql`
  fragment OrganizationFields on Organization {
    id
    name
    website
    termsUrl
    privacyUrl
    emailConsent
    appearance {
      favicon
      headerBackgroundColor
      headerTextColor
      hideViewershipMetric
      logo
      navigationLinkCase
    }
    links {
      id
      key
      label
      url
    }
    offlineContent {
      __typename
      type
      ... on OfflineImage {
        url
      }
      ... on OfflineVideo {
        url
        options {
          loop
          autoplay
        }
      }
    }
    offlinePrayerRecipients
    status
    integrations(filter: { enabled: true }) {
      __typename
      id
      type
      ... on FacebookPixel {
        config {
          pixelId
        }
      }
      ... on Gloo {
        config {
          glooId
        }
      }
      ... on GoogleAnalytics {
        config {
          trackingId
        }
      }
      ... on HubSpot {
        config {
          trackingCodeId
        }
      }
      ... on Segment {
        config {
          segmentKey
          segmentDomain
        }
      }
      ... on GoogleAnalytics4 {
        config {
          trackingId
        }
      }

      ... on Osano {
        config {
          accountId
          configId
        }
      }
    }
  }
`;

const sequence = gql`
  query SequenceQuery($time: Timestamp!) {
    serviceAt(time: $time) {
      sequence {
        serverTime
        steps {
          fetchTime
          transitionTime
          queries
        }
      }
    }
  }
`;

const serviceAt = gql`
  query ServiceAtQuery($time: Timestamp!) {
    serviceAt(time: $time) {
      ...ServiceFields
    }
  }
  ${serviceFields}
`;

const servicesConnection = gql`
  query ServicesConnectionQuery($from: Timestamp, $to: Timestamp, $limit: Int = 0) {
    currentOrganization {
      id
      servicesConnection(from: $from, to: $to, limit: $limit) {
        services {
          id
          startTime
          scheduleTime
          endTime
          content {
            id
            title
            features {
              publicChat
            }
          }
        }
      }
    }
  }
`;

const muteSubscriber = gql`
  mutation MuteSubscriberMutation($subscriberId: ID!) {
    muteSubscriber(subscriberId: $subscriberId) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const unmuteSubscriber = gql`
  mutation UnmuteSubscriberMutation($subscriberId: ID!) {
    unmuteSubscriber(subscriberId: $subscriberId) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const leaveChannel = gql`
  mutation LeaveChannelMutation($id: ID!) {
    leaveChannel(id: $id) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const requestInviteToChannel = gql`
  query RequestInviteToChannel($id: ID!) {
    requestInviteToChannel(id: $id) {
      id
      channel {
        id
      }
      requesterSubscriberId
    }
  }
`;

const acceptInviteToChannel = gql`
  mutation AcceptInviteToChannel($id: ID!) {
    acceptInviteToChannel(id: $id) {
      id
      channel {
        id
        key
      }
      accepterSubscriberId
    }
  }
`;

const joinChannel = gql`
  mutation JoinChannelMutation($id: ID!) {
    joinChannel(id: $id) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const deleteMessage = gql`
  mutation DeleteMessageMutation($input: MessageInput!) {
    deleteMessage(input: $input) {
      success
      errors {
        code
      }
    }
  }
`;

export const requestChannel = gql`
  query RequestChannelQuery($id: ID!, $input: ChannelInput!, $with: ID) {
    requestChannel(id: $id, input: $input, with: $with) {
      id
      key
      name
      type
      direct
      group
      ... on DirectChannel {
        subscribers(filter: { subscriberTypeIn: [HOST, USER, OWNER, GLOBAL, ALIAS_GUEST, ADMIN] }) {
          nodes {
            id
            nickname
            avatar
            roleIdentifier {
              label
              key
              type
            }
          }
        }
      }
      ... on PrayerChannel {
        subscribers(filter: { subscriberTypeIn: [HOST, USER, OWNER, GLOBAL, ALIAS_GUEST, ADMIN] }) {
          nodes {
            id
            nickname
            avatar
            roleIdentifier {
              label
              key
              type
            }
          }
        }
      }
    }
  }
`;

export const hostChatOccupancy = gql`
  query HostChatOccupancy($filter: SubscribersFilter) {
    currentService {
      id
      feed {
        id
        type
        ... on PublicChannel {
          subscribers(filter: $filter) {
            nodes {
              id
              avatar
              firstName
              lastName
            }
            totalConnected
          }
        }
      }
    }
  }
`;

export const audienceTab = gql`
  query AudienceTab(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: SubscribersFilter
  ) {
    currentService {
      id
      feed {
        id
        type
        ... on PublicChannel {
          subscribers(first: $first, last: $last, after: $after, before: $before, filter: $filter) {
            edges {
              cursor
              node {
                id
                nickname
                avatar
                muted
                roleIdentifier {
                  type
                  key
                  label
                }
                stats {
                  connected
                  connectedAt
                  channels {
                    prayer
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              startCursor
              hasPreviousPage
            }
            totalConnected
            totalCount
          }
        }
      }
    }
  }
`;

const saveSubscriber = gql`
  mutation SaveSubscriberMutation($id: ID!, $input: SubscriberInput!) {
    saveSubscriber(id: $id, input: $input) {
      subscriber {
        id
      }
      errors {
        code
        property
      }
    }
  }
`;

const revertSubscriber = gql`
  mutation RevertSubscriberMutation($input: RevertSubscriberInput!) {
    revertSubscriber(input: $input) {
      subscriber {
        id
        email
        invitePending
        inviteExpiresAt
      }
      errors {
        code
        message
        property
      }
    }
  }
`;

const acceptInvite = gql`
  mutation AcceptInviteMutation($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      subscriber {
        id
        invitePending
        inviteExpiresAt
      }
      errors {
        code
        message
        property
      }
    }
  }
`;

const requestPasswordReset = gql`
  mutation RequestPasswordResetMutation($email: String!) {
    requestPasswordReset(email: $email) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const changePassword = gql`
  mutation ChangePasswordMutation($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
      errors {
        code
        message
      }
    }
  }
`;

const deleteSelf = gql`
  mutation DeleteSelfMutation($id: ID!) {
    deleteSubscriber(id: $id) {
      subscriber {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

const saveMomentInstance = gql`
  mutation SaveMomentInstanceMutation($input: MomentInstanceInput!) {
    saveMomentInstance(input: $input) {
      errors {
        code
        message
        property
      }
    }
  }
`;

const deleteMomentInstance = gql`
  mutation DeleteMomentInstance($input: DeleteMomentInstanceInput!) {
    deleteMomentInstance(input: $input) {
      errors {
        code
        message
        property
      }
    }
  }
`;

const submitMomentAction = gql`
  mutation SubmitMomentActionMutation($input: MomentActionInput!) {
    submitMomentAction(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

const submitMessageAction = gql`
  mutation SubmitMessageActionMutation($input: MessageActionInput!) {
    submitMessageAction(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

const currentState = gql`
  query CurrentState {
    currentService {
      ...ServiceFields
    }
    currentSubscriber {
      ...SubscriberFields
    }
    currentOrganization {
      ...OrganizationFields
    }
    pubnubKeys {
      publishKey
      subscribeKey
    }
  }
  ${serviceFields}
  ${subscriberFields}
  ${organizationFields}
`;

export const CURRENT_SERVICE_LOAD_NEXT = gql`
  query CurrentServiceLoadNext {
    currentService(onEmpty: LOAD_NEXT) {
      id
      content {
        id
        hostInfo
        notes
      }
    }
  }
`;

const requestPrayer = gql`
  mutation RequestPrayerMutation($input: RequestPrayerInput!) {
    requestPrayer(input: $input) {
      prayerRequest {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

// ConnectMomentOptions and SalvationMomentOptions are aliased to prevent type conflict
export const MOMENTS_SCHEDULE_QUERY = gql`
  query MomentSchedule {
    currentService(onEmpty: LOAD_NEXT) {
      id
      startTime
      content {
        id
        momentScheduleItems {
          id
          startTimeOffset
          momentTemplate {
            __typename
            ... on ConnectMoment {
              id
              type
              connectMomentOptions: options {
                text
                url
                windowType
              }
            }
            ... on GivingMoment {
              id
              type
              options {
                src
                text
                windowType
              }
            }
            ... on ImageMoment {
              id
              type
              options {
                altText
                imageUrl
                shareText
              }
            }
            ... on InviteMoment {
              id
              type
              inviteMomentOptions: options {
                text
                buttonText
              }
            }
            ... on PrayerMoment {
              id
              type
              options {
                text
                buttonText
              }
            }
            ... on QuoteMoment {
              id
              type
              options {
                text
                attribution
              }
            }
            ... on ResponseMoment {
              id
              type
              options {
                text
                buttonText
                symbol {
                  id
                  name
                  unified
                  native
                }
              }
            }
            ... on SalvationMoment {
              id
              type
              salvationMomentOptions: options {
                salvationMomentFollowUpType
                url
                labels {
                  prompt
                  response
                }
              }
            }
            ... on ImageMoment {
              id
              type
              options {
                altText
                imageUrl
                shareText
              }
            }
          }
        }
      }
    }
  }
`;

export const TOGGLE_MOMENT_SCHEDULING = gql`
  mutation ToggleMomentScheduling($serviceId: ID!, $enabled: Boolean!) {
    toggleMomentScheduling(serviceId: $serviceId, enabled: $enabled) {
      success
    }
  }
`;

export const ADD_LANGUAGE_TO_CHANNELS = gql`
  mutation AddLanguageToChannels($languageCode: String!, $channels: [String!]!) {
    addLanguageToChannels(languageCode: $languageCode, channels: $channels) {
      success
    }
  }
`;

export const GET_INTEGRATIONS = gql`
  query GetIntegrations($filter: IntegrationSearchFilter) {
    currentOrganization {
      integrations(filter: $filter) {
        enabled
        type
      }
    }
  }
`;

export const GENERATE_PDF = gql`
  mutation GeneratePdfMutation($html: String!) {
    generatePdf(html: $html)
  }
`;

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const omitTypename = (key: any, value: any): any => (key === '__typename' ? undefined : value);

  const def = getMainDefinition(operation.query);

  if (def && (def as OperationDefinitionNode).operation === 'mutation') {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward ? forward(operation) : null;
});

const addRequestIdLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
    headers: {
      'Request-Id': uuidv4(),
      ...headers,
    },
  }));
  return forward(operation);
});

const httpLink = new HttpLink();

export const client = new ApolloClient({
  name: 'chop-web-client',
  version: CI_COMMIT_SHA,
  credentials: 'same-origin',
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      PublicChannel: {
        fields: {
          subscribers: relayStylePagination(),
        },
      },
    },
  }),
  link: from([addRequestIdLink, cleanTypenameLink, httpLink]),
});

const queries = {
  addLanguageToChannels: async (variables: AddLanguageToChannelsVariables) =>
    await client.mutate<AddLanguageToChannels>({ mutation: ADD_LANGUAGE_TO_CHANNELS, variables }),

  currentState: async () =>
    await client.query<CurrentState>({ query: currentState, fetchPolicy: 'network-only' }),

  muteSubscriber: async (subscriberId: string) =>
    await client.mutate<MuteSubscriberMutation, MuteSubscriberMutationVariables>({
      mutation: muteSubscriber,
      variables: {
        subscriberId,
      },
    }),

  unmuteSubscriber: async (subscriberId: string) =>
    await client.mutate<UnmuteSubscriberMutation, UnmuteSubscriberMutationVariables>({
      mutation: unmuteSubscriber,
      variables: { subscriberId },
    }),

  saveSubscriber: async (id: string, input: SubscriberInput) =>
    await client.mutate<SaveSubscriberMutation, SaveSubscriberMutationVariables>({
      mutation: saveSubscriber,
      variables: {
        id,
        input,
      },
    }),

  revertSubscriber: async (input: RevertSubscriberInput) =>
    await client.mutate<RevertSubscriberMutation, RevertSubscriberMutationVariables>({
      mutation: revertSubscriber,
      variables: {
        input,
      },
    }),

  acceptInvite: async (input: AcceptInviteInput) =>
    await client.mutate<AcceptInviteMutation, AcceptInviteMutationVariables>({
      mutation: acceptInvite,
      variables: {
        input,
      },
    }),

  requestChannel: async (id: string, input: ChannelInput, withId: string) =>
    await client.query<RequestChannelQuery, RequestChannelQueryVariables>({
      query: requestChannel,
      variables: {
        id,
        input,
        with: withId,
      },
    }),

  deleteMessage: async (input: MessageInput) =>
    await client.mutate<DeleteMessageMutation, DeleteMessageMutationVariables>({
      mutation: deleteMessage,
      variables: {
        input,
      },
    }),

  leaveChannel: async (id: string) =>
    await client.mutate<LeaveChannelMutation, LeaveChannelMutationVariables>({
      mutation: leaveChannel,
      variables: {
        id,
      },
    }),

  requestInviteToChannel: async (id: string) =>
    await client.query<RequestInviteToChannel, RequestInviteToChannelVariables>({
      query: requestInviteToChannel,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    }),

  acceptInviteToChannel: async (id: string) =>
    await client.mutate<AcceptInviteToChannel, AcceptInviteToChannelVariables>({
      mutation: acceptInviteToChannel,
      variables: {
        id,
      },
    }),

  schedule: async () =>
    await client.query<ServicesConnectionQuery, ServicesConnectionQueryVariables>({
      query: servicesConnection,
      variables: {
        from: dayjs().subtract(1, 'day').toISOString(),
        to: dayjs().add(2, 'week').toISOString(),
        limit: 1000,
      },
    }),

  serviceAtTime: async (time: string) =>
    await client.query<ServiceAtQuery, ServiceAtQueryVariables>({
      query: serviceAt,
      variables: {
        time,
      },
    }),

  sequence: async (time: string) =>
    await client.query<SequenceQuery, SequenceQueryVariables>({
      query: sequence,
      variables: {
        time,
      },
    }),

  joinChannel: async (id: string) =>
    await client.mutate<JoinChannelMutation, JoinChannelMutationVariables>({
      mutation: joinChannel,
      variables: {
        id,
      },
    }),

  requestPasswordReset: async (email: string) =>
    await client.mutate<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>({
      mutation: requestPasswordReset,
      variables: {
        email,
      },
    }),

  changePassword: async (input: ChangePasswordInput) =>
    await client.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
      mutation: changePassword,
      variables: {
        input,
      },
    }),

  deleteSelf: async (id: string) =>
    await client.mutate<DeleteSelfMutation, DeleteSelfMutationVariables>({
      mutation: deleteSelf,
      variables: { id },
    }),

  saveMomentInstance: async (input: MomentInstanceInput) =>
    await client.mutate<SaveMomentInstanceMutation, SaveMomentInstanceMutationVariables>({
      mutation: saveMomentInstance,
      variables: {
        input,
      },
    }),

  deleteMomentInstance: async (input: DeleteMomentInstanceInput) =>
    await client.mutate<DeleteMomentInstanceInput, DeleteMomentInstanceVariables>({
      mutation: deleteMomentInstance,
      variables: {
        input,
      },
    }),

  submitMomentAction: async (input: MomentActionInput) =>
    await client.mutate<SubmitMomentActionMutation, SubmitMomentActionMutationVariables>({
      mutation: submitMomentAction,
      variables: {
        input,
      },
    }),

  submitMessageAction: async (input: MessageActionInput) =>
    await client.mutate<SubmitMessageActionMutation, SubmitMessageActionMutationVariables>({
      mutation: submitMessageAction,
      variables: {
        input,
      },
    }),

  requestPrayer: async (input: RequestPrayerInput) =>
    await client.mutate<RequestPrayerMutation, RequestPrayerMutationVariables>({
      mutation: requestPrayer,
      variables: {
        input,
      },
    }),
};

export default queries;
