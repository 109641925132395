import React from 'react';
import { List, OnChangeMeta } from 'react-movable';

type ListProps<ItemType> = {
  enableEvents?: boolean;
  items: ItemType[];
  renderItem: (value: ItemType, index: number | undefined) => React.ReactNode;
  onChange: (meta: OnChangeMeta) => void;
};

const MovableList: <T>(props: ListProps<T>) => React.ReactElement = ({
  items,
  enableEvents = true,
  renderItem,
  onChange,
  ...props
}) => {
  return (
    <div style={{ pointerEvents: enableEvents ? 'auto' : 'none' }}>
      <List
        values={items}
        {...props}
        onChange={onChange}
        renderList={({ children, props }) => <div {...props}>{children}</div>}
        lockVertically
        renderItem={({ value: item, props, index }) => {
          return <div {...props}>{renderItem(item, index)}</div>;
        }}
      />
    </div>
  );
};

export { MovableList };
