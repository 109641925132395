import TextModeToggle from './textModeToggle';
import { connect } from 'react-redux';
import { saveSubscriber } from '@store/subscriberSlice';
import { getTextMode } from '@store/subscriberSlice/selectors';
import { RootState } from '@store/rootReducer';
import { Dispatch } from 'redux';
import { TextMode } from '../../../../__generated__/globalTypes';

interface StateProps {
  textMode: TextMode;
  currentSubscriberId: string;
}

interface OwnProps {
  isOpen?: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  textMode: getTextMode(state),
  currentSubscriberId: state.subscriber.currentSubscriber.id,
});

interface DispatchProps {
  toggleTextMode: (currentSubscriberId: string, textMode: TextMode) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  toggleTextMode: (currentSubscriberId, textMode) => {
    dispatch(
      saveSubscriber({
        id: currentSubscriberId,
        input: {
          preferences: {
            textMode,
          },
        },
      })
    );
  },
});

const VisibleTextModeToggle = connect(mapStateToProps, mapDispatchToProps)(TextModeToggle);

export type Props = StateProps & OwnProps & DispatchProps;
export default VisibleTextModeToggle;
