import React, { useState } from 'react';
import { InviteMoment } from '../../invite';
import { Preview } from '../index';
import { Box } from '@lifechurch/react-ion';
import { Modal } from './InvitePreviewModal';
export const InviteMomentPreview = ({ text, buttonText }) => {
    const [isPinned, setIsPinned] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (React.createElement(Preview, null,
        isModalOpen && React.createElement(Modal, { open: isModalOpen, dismiss: () => setIsModalOpen(false) }),
        React.createElement(Box, { p: isPinned ? 0 : 3, paddingBlockStart: 0, marginBlockStart: 0, marginBlockEnd: isPinned ? '4px' : 0, marginInlineStart: isPinned ? '8px' : 0, marginInlineEnd: isPinned ? '8px' : 0 },
            React.createElement(InviteMoment, { text: text, buttonText: buttonText, isPinned: isPinned, onDismiss: () => setIsPinned(false), onClick: () => setIsModalOpen(!isModalOpen) }))));
};
